import { SxProps, Theme } from '@mui/material';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { z } from 'zod';
import {
  OutletsDropdownDocument,
  SortOrder,
} from '../../graphql/generated/types';

import { RequireKeys } from '../../types/UtilityTypes';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import { useAuthContext } from '../../contexts/authContext';

interface Props {
  label: string;
  schema: z.ZodTypeAny;
  sx?: SxProps<Theme> | undefined;
  readOnly?: boolean;
  onChange?: (value: any) => void;
  disabled?: boolean;
  filterOutlet?: boolean;
}

const OutletAutocomplete = <T extends FieldValues>({
  name,
  label,
  schema,
  control,
  defaultValue,
  sx,
  onChange,
  readOnly = false,
  disabled = false,
  filterOutlet = true,
}: Props & RequireKeys<UseControllerProps<T>, 'defaultValue' | 'control'>) => {
  const { currentUser } = useAuthContext();
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <SearchAsYouTypeAutocomplete
            sx={{ ...sx }}
            readOnly={readOnly}
            textFieldProps={{
              ...textFieldHelperProps(field, fieldState, schema),
              label,
            }}
            disabled={disabled}
            queryOptions={(value) => {
              return {
                query: OutletsDropdownDocument,
                variables: {
                  orderBy: {
                    name: SortOrder.Asc,
                  },
                  where: {
                    ...(filterOutlet && {
                      id: {
                        in: currentUser?.outlets.map(({ id }) => id),
                      },
                    }),

                    OR: [
                      {
                        name: {
                          contains: value,
                          mode: 'insensitive',
                        },
                      },
                    ],
                    status: {
                      equals: 'ACTIVE',
                    },
                  },
                },
              };
            }}
            getOptionLabel={(option: any) => option?.name}
            value={field.value}
            onChange={(e, value) => {
              onChange && onChange(value);
              field.onChange(value);
            }}
          />
        )}
      />
    </>
  );
};

export default OutletAutocomplete;
