import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateCrtDocument,
  PackagingCategory,
  QueryMode,
  RawMaterialCategory,
  SortOrder,
  useDeleteManyCrtMutation,
  useCrtsQuery,
  CrtsDocument,
  CrtWhereInput,
  DeliveryShiftSchedule,
  SalesCategory,
  UserGroup,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { useAuthContext } from '../../contexts/authContext';

const CrtsPage = ({ title = 'CRT Inputs' }) => {
  const { currentUser } = useAuthContext();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: CrtWhereInput = Object.assign(
    {},
    currentUser?.userGroups.includes(UserGroup['Area Supervisor']) ||
      currentUser?.userGroups.includes(UserGroup['Team Leader'])
      ? {
          outletId: {
            in: currentUser.outlets.map(({ id }) => id),
          },
        }
      : {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          date: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              outlet: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { crts } = {},
    loading,
    error,
  } = useCrtsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyCrt] = useDeleteManyCrtMutation({
    refetchQueries: [
      {
        query: AggregateCrtDocument,
        variables: countVariables,
      },
      {
        query: CrtsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<Paths<ObjectFromArray<NonNullable<typeof crts>>>> = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Outlet',
      accessor: 'outlet.name',
    },
    {
      header: 'Delivery Shift Schedule',
      accessor: 'deliveryShiftSchedule',
      Cell: (children) => getEnumKeyFromValue(DeliveryShiftSchedule, children),
    },
    {
      header: 'CML',
      accessor: 'crtCmlItems',
      Cell: (children: {}[], index: number) => {
        const crtCmlItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<NonNullable<typeof crts>>['crtCmlItems']
            >
          >
        > = [
          {
            header: 'Sales Category',
            accessor: 'salesCategory',
            Cell: (children) => getEnumKeyFromValue(SalesCategory, children),
          },

          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={crtCmlItemsColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material',
      accessor: 'crtRawMaterialItems',
      Cell: (children: {}[], index: number) => {
        const crtRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<NonNullable<typeof crts>>['crtRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Category',
            accessor: 'rawMaterialProduct.rawMaterial.rawMaterialCategory',
            Cell: (children) =>
              getEnumKeyFromValue(RawMaterialCategory, children),
          },
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={crtRawMaterialItemsColumns}
          />
        );
      },
    },
    {
      header: 'Packaging',
      accessor: 'crtPackagingItems',
      Cell: (children: {}[], index: number) => {
        const crtPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<NonNullable<typeof crts>>['crtPackagingItems']
            >
          >
        > = [
          {
            header: 'Category',
            accessor: 'packagingProduct.packaging.packagingCategory',
            Cell: (children) =>
              getEnumKeyFromValue(PackagingCategory, children),
          },
          {
            header: 'Packaging',
            accessor: 'packagingProduct.packaging.name',
          },
          {
            header: 'Unit',
            accessor: 'packagingProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={crtPackagingItemsColumns}
          />
        );
      },
    },
    {
      header: 'Beverage',
      accessor: 'crtBeverageItems',
      Cell: (children: {}[], index: number) => {
        const crtBeverageItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<NonNullable<typeof crts>>['crtBeverageItems']
            >
          >
        > = [
          {
            header: 'Beverage',
            accessor: 'beverage.name',
          },
          {
            header: 'Cups Sold in M',
            accessor: 'cupsSoldMedium',
          },
          {
            header: 'Cups Sold in L',
            accessor: 'cupsSoldLarge',
          },
          {
            header: 'Cups Sold in XL',
            accessor: 'cupsSoldXl',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={crtBeverageItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyCrt({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={<DataTable data={crts} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateCrtDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default CrtsPage;
