import { DeliveryShiftSchedule } from '../enums/DeliveryShiftSchedule';
import { CmlThrowAwayLocation } from '../enums/CmlThrowAwayLocation';
import { NumberOfShifts } from '../enums/NumberOfShifts';
import { PackagingCategory } from '../enums/PackagingCategory';
import { ProductCategory } from '../enums/ProductCategory';
import { RawMaterialCategory } from '../enums/RawMaterialCategory';
import { RawMaterialFinishingEndingLocation } from '../enums/RawMaterialFinishingEndingLocation';
import { SalesCategory } from '../enums/SalesCategory';
import { Status } from '../enums/Status';
import { UserGroup } from '../enums/UserGroup';
import { FormType } from '../enums/FormType';
import { TransferDirection } from '../enums/TransferDirection';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type AggregateAuditTrail = {
  __typename?: 'AggregateAuditTrail';
  _avg?: Maybe<AuditTrailAvgAggregate>;
  _count?: Maybe<AuditTrailCountAggregate>;
  _max?: Maybe<AuditTrailMaxAggregate>;
  _min?: Maybe<AuditTrailMinAggregate>;
  _sum?: Maybe<AuditTrailSumAggregate>;
};

export type AggregateBetweenEntityTransfer = {
  __typename?: 'AggregateBetweenEntityTransfer';
  _avg?: Maybe<BetweenEntityTransferAvgAggregate>;
  _count?: Maybe<BetweenEntityTransferCountAggregate>;
  _max?: Maybe<BetweenEntityTransferMaxAggregate>;
  _min?: Maybe<BetweenEntityTransferMinAggregate>;
  _sum?: Maybe<BetweenEntityTransferSumAggregate>;
};

export type AggregateBeverage = {
  __typename?: 'AggregateBeverage';
  _avg?: Maybe<BeverageAvgAggregate>;
  _count?: Maybe<BeverageCountAggregate>;
  _max?: Maybe<BeverageMaxAggregate>;
  _min?: Maybe<BeverageMinAggregate>;
  _sum?: Maybe<BeverageSumAggregate>;
};

export type AggregateCheatSheet = {
  __typename?: 'AggregateCheatSheet';
  _avg?: Maybe<CheatSheetAvgAggregate>;
  _count?: Maybe<CheatSheetCountAggregate>;
  _max?: Maybe<CheatSheetMaxAggregate>;
  _min?: Maybe<CheatSheetMinAggregate>;
  _sum?: Maybe<CheatSheetSumAggregate>;
};

export type AggregateCmlProduct = {
  __typename?: 'AggregateCmlProduct';
  _avg?: Maybe<CmlProductAvgAggregate>;
  _count?: Maybe<CmlProductCountAggregate>;
  _max?: Maybe<CmlProductMaxAggregate>;
  _min?: Maybe<CmlProductMinAggregate>;
  _sum?: Maybe<CmlProductSumAggregate>;
};

export type AggregateCmlProductRecipe = {
  __typename?: 'AggregateCmlProductRecipe';
  _avg?: Maybe<CmlProductRecipeAvgAggregate>;
  _count?: Maybe<CmlProductRecipeCountAggregate>;
  _max?: Maybe<CmlProductRecipeMaxAggregate>;
  _min?: Maybe<CmlProductRecipeMinAggregate>;
  _sum?: Maybe<CmlProductRecipeSumAggregate>;
};

export type AggregateCmlThrowAway = {
  __typename?: 'AggregateCmlThrowAway';
  _avg?: Maybe<CmlThrowAwayAvgAggregate>;
  _count?: Maybe<CmlThrowAwayCountAggregate>;
  _max?: Maybe<CmlThrowAwayMaxAggregate>;
  _min?: Maybe<CmlThrowAwayMinAggregate>;
  _sum?: Maybe<CmlThrowAwaySumAggregate>;
};

export type AggregateCrt = {
  __typename?: 'AggregateCrt';
  _avg?: Maybe<CrtAvgAggregate>;
  _count?: Maybe<CrtCountAggregate>;
  _max?: Maybe<CrtMaxAggregate>;
  _min?: Maybe<CrtMinAggregate>;
  _sum?: Maybe<CrtSumAggregate>;
};

export type AggregateDeliveryReceipt = {
  __typename?: 'AggregateDeliveryReceipt';
  _avg?: Maybe<DeliveryReceiptAvgAggregate>;
  _count?: Maybe<DeliveryReceiptCountAggregate>;
  _max?: Maybe<DeliveryReceiptMaxAggregate>;
  _min?: Maybe<DeliveryReceiptMinAggregate>;
  _sum?: Maybe<DeliveryReceiptSumAggregate>;
};

export type AggregateDeliveryReceiptItem = {
  __typename?: 'AggregateDeliveryReceiptItem';
  _avg?: Maybe<DeliveryReceiptItemAvgAggregate>;
  _count?: Maybe<DeliveryReceiptItemCountAggregate>;
  _max?: Maybe<DeliveryReceiptItemMaxAggregate>;
  _min?: Maybe<DeliveryReceiptItemMinAggregate>;
  _sum?: Maybe<DeliveryReceiptItemSumAggregate>;
};

export type AggregateDonutMixAndCut = {
  __typename?: 'AggregateDonutMixAndCut';
  _avg?: Maybe<DonutMixAndCutAvgAggregate>;
  _count?: Maybe<DonutMixAndCutCountAggregate>;
  _max?: Maybe<DonutMixAndCutMaxAggregate>;
  _min?: Maybe<DonutMixAndCutMinAggregate>;
  _sum?: Maybe<DonutMixAndCutSumAggregate>;
};

export type AggregateEndingSdEmTa = {
  __typename?: 'AggregateEndingSdEmTa';
  _avg?: Maybe<EndingSdEmTaAvgAggregate>;
  _count?: Maybe<EndingSdEmTaCountAggregate>;
  _max?: Maybe<EndingSdEmTaMaxAggregate>;
  _min?: Maybe<EndingSdEmTaMinAggregate>;
  _sum?: Maybe<EndingSdEmTaSumAggregate>;
};

export type AggregateMonthlyOutletPackagingInventory = {
  __typename?: 'AggregateMonthlyOutletPackagingInventory';
  _avg?: Maybe<MonthlyOutletPackagingInventoryAvgAggregate>;
  _count?: Maybe<MonthlyOutletPackagingInventoryCountAggregate>;
  _max?: Maybe<MonthlyOutletPackagingInventoryMaxAggregate>;
  _min?: Maybe<MonthlyOutletPackagingInventoryMinAggregate>;
  _sum?: Maybe<MonthlyOutletPackagingInventorySumAggregate>;
};

export type AggregateOutlet = {
  __typename?: 'AggregateOutlet';
  _avg?: Maybe<OutletAvgAggregate>;
  _count?: Maybe<OutletCountAggregate>;
  _max?: Maybe<OutletMaxAggregate>;
  _min?: Maybe<OutletMinAggregate>;
  _sum?: Maybe<OutletSumAggregate>;
};

export type AggregateOutletFinishing = {
  __typename?: 'AggregateOutletFinishing';
  _avg?: Maybe<OutletFinishingAvgAggregate>;
  _count?: Maybe<OutletFinishingCountAggregate>;
  _max?: Maybe<OutletFinishingMaxAggregate>;
  _min?: Maybe<OutletFinishingMinAggregate>;
  _sum?: Maybe<OutletFinishingSumAggregate>;
};

export type AggregatePackaging = {
  __typename?: 'AggregatePackaging';
  _avg?: Maybe<PackagingAvgAggregate>;
  _count?: Maybe<PackagingCountAggregate>;
  _max?: Maybe<PackagingMaxAggregate>;
  _min?: Maybe<PackagingMinAggregate>;
  _sum?: Maybe<PackagingSumAggregate>;
};

export type AggregatePackagingRequest = {
  __typename?: 'AggregatePackagingRequest';
  _avg?: Maybe<PackagingRequestAvgAggregate>;
  _count?: Maybe<PackagingRequestCountAggregate>;
  _max?: Maybe<PackagingRequestMaxAggregate>;
  _min?: Maybe<PackagingRequestMinAggregate>;
  _sum?: Maybe<PackagingRequestSumAggregate>;
};

export type AggregateProjection = {
  __typename?: 'AggregateProjection';
  _avg?: Maybe<ProjectionAvgAggregate>;
  _count?: Maybe<ProjectionCountAggregate>;
  _max?: Maybe<ProjectionMaxAggregate>;
  _min?: Maybe<ProjectionMinAggregate>;
  _sum?: Maybe<ProjectionSumAggregate>;
};

export type AggregatePurchaseOrder = {
  __typename?: 'AggregatePurchaseOrder';
  _avg?: Maybe<PurchaseOrderAvgAggregate>;
  _count?: Maybe<PurchaseOrderCountAggregate>;
  _max?: Maybe<PurchaseOrderMaxAggregate>;
  _min?: Maybe<PurchaseOrderMinAggregate>;
  _sum?: Maybe<PurchaseOrderSumAggregate>;
};

export type AggregateRawMaterial = {
  __typename?: 'AggregateRawMaterial';
  _avg?: Maybe<RawMaterialAvgAggregate>;
  _count?: Maybe<RawMaterialCountAggregate>;
  _max?: Maybe<RawMaterialMaxAggregate>;
  _min?: Maybe<RawMaterialMinAggregate>;
  _sum?: Maybe<RawMaterialSumAggregate>;
};

export type AggregateRawMaterialFinishingEnding = {
  __typename?: 'AggregateRawMaterialFinishingEnding';
  _avg?: Maybe<RawMaterialFinishingEndingAvgAggregate>;
  _count?: Maybe<RawMaterialFinishingEndingCountAggregate>;
  _max?: Maybe<RawMaterialFinishingEndingMaxAggregate>;
  _min?: Maybe<RawMaterialFinishingEndingMinAggregate>;
  _sum?: Maybe<RawMaterialFinishingEndingSumAggregate>;
};

export type AggregateRawMaterialProduct = {
  __typename?: 'AggregateRawMaterialProduct';
  _avg?: Maybe<RawMaterialProductAvgAggregate>;
  _count?: Maybe<RawMaterialProductCountAggregate>;
  _max?: Maybe<RawMaterialProductMaxAggregate>;
  _min?: Maybe<RawMaterialProductMinAggregate>;
  _sum?: Maybe<RawMaterialProductSumAggregate>;
};

export type AggregateRawMaterialProductRecipe = {
  __typename?: 'AggregateRawMaterialProductRecipe';
  _avg?: Maybe<RawMaterialProductRecipeAvgAggregate>;
  _count?: Maybe<RawMaterialProductRecipeCountAggregate>;
  _max?: Maybe<RawMaterialProductRecipeMaxAggregate>;
  _min?: Maybe<RawMaterialProductRecipeMinAggregate>;
  _sum?: Maybe<RawMaterialProductRecipeSumAggregate>;
};

export type AggregateRawMaterialRelease = {
  __typename?: 'AggregateRawMaterialRelease';
  _avg?: Maybe<RawMaterialReleaseAvgAggregate>;
  _count?: Maybe<RawMaterialReleaseCountAggregate>;
  _max?: Maybe<RawMaterialReleaseMaxAggregate>;
  _min?: Maybe<RawMaterialReleaseMinAggregate>;
  _sum?: Maybe<RawMaterialReleaseSumAggregate>;
};

export type AggregateRawMaterialRequest = {
  __typename?: 'AggregateRawMaterialRequest';
  _avg?: Maybe<RawMaterialRequestAvgAggregate>;
  _count?: Maybe<RawMaterialRequestCountAggregate>;
  _max?: Maybe<RawMaterialRequestMaxAggregate>;
  _min?: Maybe<RawMaterialRequestMinAggregate>;
  _sum?: Maybe<RawMaterialRequestSumAggregate>;
};

export type AggregateReceivedDeliveryReceipt = {
  __typename?: 'AggregateReceivedDeliveryReceipt';
  _avg?: Maybe<ReceivedDeliveryReceiptAvgAggregate>;
  _count?: Maybe<ReceivedDeliveryReceiptCountAggregate>;
  _max?: Maybe<ReceivedDeliveryReceiptMaxAggregate>;
  _min?: Maybe<ReceivedDeliveryReceiptMinAggregate>;
  _sum?: Maybe<ReceivedDeliveryReceiptSumAggregate>;
};

export type AggregateReceivedSdsDeliveryReceipt = {
  __typename?: 'AggregateReceivedSdsDeliveryReceipt';
  _avg?: Maybe<ReceivedSdsDeliveryReceiptAvgAggregate>;
  _count?: Maybe<ReceivedSdsDeliveryReceiptCountAggregate>;
  _max?: Maybe<ReceivedSdsDeliveryReceiptMaxAggregate>;
  _min?: Maybe<ReceivedSdsDeliveryReceiptMinAggregate>;
  _sum?: Maybe<ReceivedSdsDeliveryReceiptSumAggregate>;
};

export type AggregateReceivedTransfer = {
  __typename?: 'AggregateReceivedTransfer';
  _avg?: Maybe<ReceivedTransferAvgAggregate>;
  _count?: Maybe<ReceivedTransferCountAggregate>;
  _max?: Maybe<ReceivedTransferMaxAggregate>;
  _min?: Maybe<ReceivedTransferMinAggregate>;
  _sum?: Maybe<ReceivedTransferSumAggregate>;
};

export type AggregateSdsDeliveryReceipt = {
  __typename?: 'AggregateSdsDeliveryReceipt';
  _avg?: Maybe<SdsDeliveryReceiptAvgAggregate>;
  _count?: Maybe<SdsDeliveryReceiptCountAggregate>;
  _max?: Maybe<SdsDeliveryReceiptMaxAggregate>;
  _min?: Maybe<SdsDeliveryReceiptMinAggregate>;
  _sum?: Maybe<SdsDeliveryReceiptSumAggregate>;
};

export type AggregateSdsDeliveryReceiptPackagingItem = {
  __typename?: 'AggregateSdsDeliveryReceiptPackagingItem';
  _avg?: Maybe<SdsDeliveryReceiptPackagingItemAvgAggregate>;
  _count?: Maybe<SdsDeliveryReceiptPackagingItemCountAggregate>;
  _max?: Maybe<SdsDeliveryReceiptPackagingItemMaxAggregate>;
  _min?: Maybe<SdsDeliveryReceiptPackagingItemMinAggregate>;
  _sum?: Maybe<SdsDeliveryReceiptPackagingItemSumAggregate>;
};

export type AggregateSdsDeliveryReceiptRawMaterialItem = {
  __typename?: 'AggregateSdsDeliveryReceiptRawMaterialItem';
  _avg?: Maybe<SdsDeliveryReceiptRawMaterialItemAvgAggregate>;
  _count?: Maybe<SdsDeliveryReceiptRawMaterialItemCountAggregate>;
  _max?: Maybe<SdsDeliveryReceiptRawMaterialItemMaxAggregate>;
  _min?: Maybe<SdsDeliveryReceiptRawMaterialItemMinAggregate>;
  _sum?: Maybe<SdsDeliveryReceiptRawMaterialItemSumAggregate>;
};

export type AggregateTransfer = {
  __typename?: 'AggregateTransfer';
  _avg?: Maybe<TransferAvgAggregate>;
  _count?: Maybe<TransferCountAggregate>;
  _max?: Maybe<TransferMaxAggregate>;
  _min?: Maybe<TransferMinAggregate>;
  _sum?: Maybe<TransferSumAggregate>;
};

export type AggregateTransferCmlItem = {
  __typename?: 'AggregateTransferCmlItem';
  _avg?: Maybe<TransferCmlItemAvgAggregate>;
  _count?: Maybe<TransferCmlItemCountAggregate>;
  _max?: Maybe<TransferCmlItemMaxAggregate>;
  _min?: Maybe<TransferCmlItemMinAggregate>;
  _sum?: Maybe<TransferCmlItemSumAggregate>;
};

export type AggregateTransferPackagingItem = {
  __typename?: 'AggregateTransferPackagingItem';
  _avg?: Maybe<TransferPackagingItemAvgAggregate>;
  _count?: Maybe<TransferPackagingItemCountAggregate>;
  _max?: Maybe<TransferPackagingItemMaxAggregate>;
  _min?: Maybe<TransferPackagingItemMinAggregate>;
  _sum?: Maybe<TransferPackagingItemSumAggregate>;
};

export type AggregateTransferRawMaterialItem = {
  __typename?: 'AggregateTransferRawMaterialItem';
  _avg?: Maybe<TransferRawMaterialItemAvgAggregate>;
  _count?: Maybe<TransferRawMaterialItemCountAggregate>;
  _max?: Maybe<TransferRawMaterialItemMaxAggregate>;
  _min?: Maybe<TransferRawMaterialItemMinAggregate>;
  _sum?: Maybe<TransferRawMaterialItemSumAggregate>;
};

export type AggregateUnit = {
  __typename?: 'AggregateUnit';
  _avg?: Maybe<UnitAvgAggregate>;
  _count?: Maybe<UnitCountAggregate>;
  _max?: Maybe<UnitMaxAggregate>;
  _min?: Maybe<UnitMinAggregate>;
  _sum?: Maybe<UnitSumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
};

export type AggregateWarehouseInventory = {
  __typename?: 'AggregateWarehouseInventory';
  _avg?: Maybe<WarehouseInventoryAvgAggregate>;
  _count?: Maybe<WarehouseInventoryCountAggregate>;
  _max?: Maybe<WarehouseInventoryMaxAggregate>;
  _min?: Maybe<WarehouseInventoryMinAggregate>;
  _sum?: Maybe<WarehouseInventorySumAggregate>;
};

export type AuditTrail = {
  __typename?: 'AuditTrail';
  action: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  moduleForm: Scalars['String']['output'];
  nameOfUser: Scalars['String']['output'];
  newData?: Maybe<Scalars['JSON']['output']>;
  oldData?: Maybe<Scalars['JSON']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export type AuditTrailAvgAggregate = {
  __typename?: 'AuditTrailAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type AuditTrailCountAggregate = {
  __typename?: 'AuditTrailCountAggregate';
  _all: Scalars['Int']['output'];
  action: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  moduleForm: Scalars['Int']['output'];
  nameOfUser: Scalars['Int']['output'];
  newData: Scalars['Int']['output'];
  oldData: Scalars['Int']['output'];
  timestamp: Scalars['Int']['output'];
};

export type AuditTrailMaxAggregate = {
  __typename?: 'AuditTrailMaxAggregate';
  action?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  moduleForm?: Maybe<Scalars['String']['output']>;
  nameOfUser?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type AuditTrailMinAggregate = {
  __typename?: 'AuditTrailMinAggregate';
  action?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  moduleForm?: Maybe<Scalars['String']['output']>;
  nameOfUser?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type AuditTrailOrderByWithRelationInput = {
  action?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  moduleForm?: InputMaybe<SortOrder>;
  nameOfUser?: InputMaybe<SortOrder>;
  newData?: InputMaybe<SortOrder>;
  oldData?: InputMaybe<SortOrder>;
  timestamp?: InputMaybe<SortOrder>;
};

export enum AuditTrailScalarFieldEnum {
  Action = 'action',
  Id = 'id',
  ModuleForm = 'moduleForm',
  NameOfUser = 'nameOfUser',
  NewData = 'newData',
  OldData = 'oldData',
  Timestamp = 'timestamp'
}

export type AuditTrailSumAggregate = {
  __typename?: 'AuditTrailSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type AuditTrailWhereInput = {
  AND?: InputMaybe<Array<AuditTrailWhereInput>>;
  NOT?: InputMaybe<Array<AuditTrailWhereInput>>;
  OR?: InputMaybe<Array<AuditTrailWhereInput>>;
  action?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  moduleForm?: InputMaybe<StringFilter>;
  nameOfUser?: InputMaybe<StringFilter>;
  newData?: InputMaybe<JsonNullableFilter>;
  oldData?: InputMaybe<JsonNullableFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
};

export type AuditTrailWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type BetweenEntityTransfer = {
  __typename?: 'BetweenEntityTransfer';
  _count?: Maybe<BetweenEntityTransferCount>;
  betweenEntityTransferPackagingItems: Array<BetweenEntityTransferPackagingItem>;
  betweenEntityTransferRawMaterialItems: Array<BetweenEntityTransferRawMaterialItem>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  destination: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  origin: Scalars['String']['output'];
  receivedBy?: Maybe<Scalars['String']['output']>;
  transferDirection: TransferDirection;
};


export type BetweenEntityTransferBetweenEntityTransferPackagingItemsArgs = {
  cursor?: InputMaybe<BetweenEntityTransferPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BetweenEntityTransferPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BetweenEntityTransferPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BetweenEntityTransferPackagingItemWhereInput>;
};


export type BetweenEntityTransferBetweenEntityTransferRawMaterialItemsArgs = {
  cursor?: InputMaybe<BetweenEntityTransferRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BetweenEntityTransferRawMaterialItemWhereInput>;
};

export type BetweenEntityTransferAvgAggregate = {
  __typename?: 'BetweenEntityTransferAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type BetweenEntityTransferCount = {
  __typename?: 'BetweenEntityTransferCount';
  betweenEntityTransferPackagingItems: Scalars['Int']['output'];
  betweenEntityTransferRawMaterialItems: Scalars['Int']['output'];
};

export type BetweenEntityTransferCountAggregate = {
  __typename?: 'BetweenEntityTransferCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  destination: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  origin: Scalars['Int']['output'];
  receivedBy: Scalars['Int']['output'];
  transferDirection: Scalars['Int']['output'];
};

export type BetweenEntityTransferCreateInput = {
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutBetweenEntityTransferInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutBetweenEntityTransferInput>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  destination: Scalars['String']['input'];
  origin: Scalars['String']['input'];
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  transferDirection: TransferDirection;
};

export type BetweenEntityTransferCreateNestedOneWithoutBetweenEntityTransferPackagingItemsInput = {
  connect?: InputMaybe<BetweenEntityTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BetweenEntityTransferCreateOrConnectWithoutBetweenEntityTransferPackagingItemsInput>;
  create?: InputMaybe<BetweenEntityTransferCreateWithoutBetweenEntityTransferPackagingItemsInput>;
};

export type BetweenEntityTransferCreateNestedOneWithoutBetweenEntityTransferRawMaterialItemsInput = {
  connect?: InputMaybe<BetweenEntityTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BetweenEntityTransferCreateOrConnectWithoutBetweenEntityTransferRawMaterialItemsInput>;
  create?: InputMaybe<BetweenEntityTransferCreateWithoutBetweenEntityTransferRawMaterialItemsInput>;
};

export type BetweenEntityTransferCreateOrConnectWithoutBetweenEntityTransferPackagingItemsInput = {
  create: BetweenEntityTransferCreateWithoutBetweenEntityTransferPackagingItemsInput;
  where: BetweenEntityTransferWhereUniqueInput;
};

export type BetweenEntityTransferCreateOrConnectWithoutBetweenEntityTransferRawMaterialItemsInput = {
  create: BetweenEntityTransferCreateWithoutBetweenEntityTransferRawMaterialItemsInput;
  where: BetweenEntityTransferWhereUniqueInput;
};

export type BetweenEntityTransferCreateWithoutBetweenEntityTransferPackagingItemsInput = {
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutBetweenEntityTransferInput>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  destination: Scalars['String']['input'];
  origin: Scalars['String']['input'];
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  transferDirection: TransferDirection;
};

export type BetweenEntityTransferCreateWithoutBetweenEntityTransferRawMaterialItemsInput = {
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutBetweenEntityTransferInput>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  destination: Scalars['String']['input'];
  origin: Scalars['String']['input'];
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  transferDirection: TransferDirection;
};

export type BetweenEntityTransferMaxAggregate = {
  __typename?: 'BetweenEntityTransferMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  transferDirection?: Maybe<TransferDirection>;
};

export type BetweenEntityTransferMinAggregate = {
  __typename?: 'BetweenEntityTransferMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  transferDirection?: Maybe<TransferDirection>;
};

export type BetweenEntityTransferOrderByWithRelationInput = {
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemOrderByRelationAggregateInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemOrderByRelationAggregateInput>;
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  destination?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  origin?: InputMaybe<SortOrder>;
  receivedBy?: InputMaybe<SortOrder>;
  transferDirection?: InputMaybe<SortOrder>;
};

export type BetweenEntityTransferPackagingItem = {
  __typename?: 'BetweenEntityTransferPackagingItem';
  betweenEntityTransfer: BetweenEntityTransfer;
  betweenEntityTransferId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type BetweenEntityTransferPackagingItemCreateManyBetweenEntityTransferInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type BetweenEntityTransferPackagingItemCreateManyBetweenEntityTransferInputEnvelope = {
  data: Array<BetweenEntityTransferPackagingItemCreateManyBetweenEntityTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BetweenEntityTransferPackagingItemCreateManyPackagingProductInput = {
  betweenEntityTransferId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type BetweenEntityTransferPackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<BetweenEntityTransferPackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BetweenEntityTransferPackagingItemCreateNestedManyWithoutBetweenEntityTransferInput = {
  connect?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BetweenEntityTransferPackagingItemCreateOrConnectWithoutBetweenEntityTransferInput>>;
  create?: InputMaybe<Array<BetweenEntityTransferPackagingItemCreateWithoutBetweenEntityTransferInput>>;
  createMany?: InputMaybe<BetweenEntityTransferPackagingItemCreateManyBetweenEntityTransferInputEnvelope>;
};

export type BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BetweenEntityTransferPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<BetweenEntityTransferPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<BetweenEntityTransferPackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type BetweenEntityTransferPackagingItemCreateOrConnectWithoutBetweenEntityTransferInput = {
  create: BetweenEntityTransferPackagingItemCreateWithoutBetweenEntityTransferInput;
  where: BetweenEntityTransferPackagingItemWhereUniqueInput;
};

export type BetweenEntityTransferPackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: BetweenEntityTransferPackagingItemCreateWithoutPackagingProductInput;
  where: BetweenEntityTransferPackagingItemWhereUniqueInput;
};

export type BetweenEntityTransferPackagingItemCreateWithoutBetweenEntityTransferInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutBetweenEntityTransferPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type BetweenEntityTransferPackagingItemCreateWithoutPackagingProductInput = {
  betweenEntityTransfer: BetweenEntityTransferCreateNestedOneWithoutBetweenEntityTransferPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type BetweenEntityTransferPackagingItemListRelationFilter = {
  every?: InputMaybe<BetweenEntityTransferPackagingItemWhereInput>;
  none?: InputMaybe<BetweenEntityTransferPackagingItemWhereInput>;
  some?: InputMaybe<BetweenEntityTransferPackagingItemWhereInput>;
};

export type BetweenEntityTransferPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BetweenEntityTransferPackagingItemOrderByWithRelationInput = {
  betweenEntityTransfer?: InputMaybe<BetweenEntityTransferOrderByWithRelationInput>;
  betweenEntityTransferId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum BetweenEntityTransferPackagingItemScalarFieldEnum {
  BetweenEntityTransferId = 'betweenEntityTransferId',
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity'
}

export type BetweenEntityTransferPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<BetweenEntityTransferPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<BetweenEntityTransferPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<BetweenEntityTransferPackagingItemScalarWhereInput>>;
  betweenEntityTransferId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type BetweenEntityTransferPackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BetweenEntityTransferPackagingItemUpdateManyWithWhereWithoutBetweenEntityTransferInput = {
  data: BetweenEntityTransferPackagingItemUpdateManyMutationInput;
  where: BetweenEntityTransferPackagingItemScalarWhereInput;
};

export type BetweenEntityTransferPackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: BetweenEntityTransferPackagingItemUpdateManyMutationInput;
  where: BetweenEntityTransferPackagingItemScalarWhereInput;
};

export type BetweenEntityTransferPackagingItemUpdateManyWithoutBetweenEntityTransferNestedInput = {
  connect?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BetweenEntityTransferPackagingItemCreateOrConnectWithoutBetweenEntityTransferInput>>;
  create?: InputMaybe<Array<BetweenEntityTransferPackagingItemCreateWithoutBetweenEntityTransferInput>>;
  createMany?: InputMaybe<BetweenEntityTransferPackagingItemCreateManyBetweenEntityTransferInputEnvelope>;
  delete?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BetweenEntityTransferPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BetweenEntityTransferPackagingItemUpdateWithWhereUniqueWithoutBetweenEntityTransferInput>>;
  updateMany?: InputMaybe<Array<BetweenEntityTransferPackagingItemUpdateManyWithWhereWithoutBetweenEntityTransferInput>>;
  upsert?: InputMaybe<Array<BetweenEntityTransferPackagingItemUpsertWithWhereUniqueWithoutBetweenEntityTransferInput>>;
};

export type BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BetweenEntityTransferPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<BetweenEntityTransferPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<BetweenEntityTransferPackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BetweenEntityTransferPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BetweenEntityTransferPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<BetweenEntityTransferPackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<BetweenEntityTransferPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type BetweenEntityTransferPackagingItemUpdateWithWhereUniqueWithoutBetweenEntityTransferInput = {
  data: BetweenEntityTransferPackagingItemUpdateWithoutBetweenEntityTransferInput;
  where: BetweenEntityTransferPackagingItemWhereUniqueInput;
};

export type BetweenEntityTransferPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: BetweenEntityTransferPackagingItemUpdateWithoutPackagingProductInput;
  where: BetweenEntityTransferPackagingItemWhereUniqueInput;
};

export type BetweenEntityTransferPackagingItemUpdateWithoutBetweenEntityTransferInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutBetweenEntityTransferPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BetweenEntityTransferPackagingItemUpdateWithoutPackagingProductInput = {
  betweenEntityTransfer?: InputMaybe<BetweenEntityTransferUpdateOneRequiredWithoutBetweenEntityTransferPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BetweenEntityTransferPackagingItemUpsertWithWhereUniqueWithoutBetweenEntityTransferInput = {
  create: BetweenEntityTransferPackagingItemCreateWithoutBetweenEntityTransferInput;
  update: BetweenEntityTransferPackagingItemUpdateWithoutBetweenEntityTransferInput;
  where: BetweenEntityTransferPackagingItemWhereUniqueInput;
};

export type BetweenEntityTransferPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: BetweenEntityTransferPackagingItemCreateWithoutPackagingProductInput;
  update: BetweenEntityTransferPackagingItemUpdateWithoutPackagingProductInput;
  where: BetweenEntityTransferPackagingItemWhereUniqueInput;
};

export type BetweenEntityTransferPackagingItemWhereInput = {
  AND?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<BetweenEntityTransferPackagingItemWhereInput>>;
  betweenEntityTransfer?: InputMaybe<BetweenEntityTransferRelationFilter>;
  betweenEntityTransferId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type BetweenEntityTransferPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type BetweenEntityTransferRawMaterialItem = {
  __typename?: 'BetweenEntityTransferRawMaterialItem';
  betweenEntityTransfer: BetweenEntityTransfer;
  betweenEntityTransferId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
};

export type BetweenEntityTransferRawMaterialItemCreateManyBetweenEntityTransferInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type BetweenEntityTransferRawMaterialItemCreateManyBetweenEntityTransferInputEnvelope = {
  data: Array<BetweenEntityTransferRawMaterialItemCreateManyBetweenEntityTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BetweenEntityTransferRawMaterialItemCreateManyRawMaterialProductInput = {
  betweenEntityTransferId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
};

export type BetweenEntityTransferRawMaterialItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<BetweenEntityTransferRawMaterialItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutBetweenEntityTransferInput = {
  connect?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemCreateOrConnectWithoutBetweenEntityTransferInput>>;
  create?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemCreateWithoutBetweenEntityTransferInput>>;
  createMany?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateManyBetweenEntityTransferInputEnvelope>;
};

export type BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
};

export type BetweenEntityTransferRawMaterialItemCreateOrConnectWithoutBetweenEntityTransferInput = {
  create: BetweenEntityTransferRawMaterialItemCreateWithoutBetweenEntityTransferInput;
  where: BetweenEntityTransferRawMaterialItemWhereUniqueInput;
};

export type BetweenEntityTransferRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: BetweenEntityTransferRawMaterialItemCreateWithoutRawMaterialProductInput;
  where: BetweenEntityTransferRawMaterialItemWhereUniqueInput;
};

export type BetweenEntityTransferRawMaterialItemCreateWithoutBetweenEntityTransferInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutBetweenEntityTransferRawMaterialItemsInput;
};

export type BetweenEntityTransferRawMaterialItemCreateWithoutRawMaterialProductInput = {
  betweenEntityTransfer: BetweenEntityTransferCreateNestedOneWithoutBetweenEntityTransferRawMaterialItemsInput;
  quantity: Scalars['Float']['input'];
};

export type BetweenEntityTransferRawMaterialItemListRelationFilter = {
  every?: InputMaybe<BetweenEntityTransferRawMaterialItemWhereInput>;
  none?: InputMaybe<BetweenEntityTransferRawMaterialItemWhereInput>;
  some?: InputMaybe<BetweenEntityTransferRawMaterialItemWhereInput>;
};

export type BetweenEntityTransferRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BetweenEntityTransferRawMaterialItemOrderByWithRelationInput = {
  betweenEntityTransfer?: InputMaybe<BetweenEntityTransferOrderByWithRelationInput>;
  betweenEntityTransferId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
};

export enum BetweenEntityTransferRawMaterialItemScalarFieldEnum {
  BetweenEntityTransferId = 'betweenEntityTransferId',
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId'
}

export type BetweenEntityTransferRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemScalarWhereInput>>;
  betweenEntityTransferId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type BetweenEntityTransferRawMaterialItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BetweenEntityTransferRawMaterialItemUpdateManyWithWhereWithoutBetweenEntityTransferInput = {
  data: BetweenEntityTransferRawMaterialItemUpdateManyMutationInput;
  where: BetweenEntityTransferRawMaterialItemScalarWhereInput;
};

export type BetweenEntityTransferRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: BetweenEntityTransferRawMaterialItemUpdateManyMutationInput;
  where: BetweenEntityTransferRawMaterialItemScalarWhereInput;
};

export type BetweenEntityTransferRawMaterialItemUpdateManyWithoutBetweenEntityTransferNestedInput = {
  connect?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemCreateOrConnectWithoutBetweenEntityTransferInput>>;
  create?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemCreateWithoutBetweenEntityTransferInput>>;
  createMany?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateManyBetweenEntityTransferInputEnvelope>;
  delete?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemUpdateWithWhereUniqueWithoutBetweenEntityTransferInput>>;
  updateMany?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemUpdateManyWithWhereWithoutBetweenEntityTransferInput>>;
  upsert?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemUpsertWithWhereUniqueWithoutBetweenEntityTransferInput>>;
};

export type BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type BetweenEntityTransferRawMaterialItemUpdateWithWhereUniqueWithoutBetweenEntityTransferInput = {
  data: BetweenEntityTransferRawMaterialItemUpdateWithoutBetweenEntityTransferInput;
  where: BetweenEntityTransferRawMaterialItemWhereUniqueInput;
};

export type BetweenEntityTransferRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: BetweenEntityTransferRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: BetweenEntityTransferRawMaterialItemWhereUniqueInput;
};

export type BetweenEntityTransferRawMaterialItemUpdateWithoutBetweenEntityTransferInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutBetweenEntityTransferRawMaterialItemsNestedInput>;
};

export type BetweenEntityTransferRawMaterialItemUpdateWithoutRawMaterialProductInput = {
  betweenEntityTransfer?: InputMaybe<BetweenEntityTransferUpdateOneRequiredWithoutBetweenEntityTransferRawMaterialItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BetweenEntityTransferRawMaterialItemUpsertWithWhereUniqueWithoutBetweenEntityTransferInput = {
  create: BetweenEntityTransferRawMaterialItemCreateWithoutBetweenEntityTransferInput;
  update: BetweenEntityTransferRawMaterialItemUpdateWithoutBetweenEntityTransferInput;
  where: BetweenEntityTransferRawMaterialItemWhereUniqueInput;
};

export type BetweenEntityTransferRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: BetweenEntityTransferRawMaterialItemCreateWithoutRawMaterialProductInput;
  update: BetweenEntityTransferRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: BetweenEntityTransferRawMaterialItemWhereUniqueInput;
};

export type BetweenEntityTransferRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemWhereInput>>;
  betweenEntityTransfer?: InputMaybe<BetweenEntityTransferRelationFilter>;
  betweenEntityTransferId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type BetweenEntityTransferRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type BetweenEntityTransferRelationFilter = {
  is?: InputMaybe<BetweenEntityTransferWhereInput>;
  isNot?: InputMaybe<BetweenEntityTransferWhereInput>;
};

export enum BetweenEntityTransferScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Date = 'date',
  Destination = 'destination',
  Id = 'id',
  Origin = 'origin',
  ReceivedBy = 'receivedBy',
  TransferDirection = 'transferDirection'
}

export type BetweenEntityTransferSumAggregate = {
  __typename?: 'BetweenEntityTransferSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type BetweenEntityTransferUpdateInput = {
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutBetweenEntityTransferNestedInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutBetweenEntityTransferNestedInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destination?: InputMaybe<StringFieldUpdateOperationsInput>;
  origin?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transferDirection?: InputMaybe<EnumTransferDirectionFieldUpdateOperationsInput>;
};

export type BetweenEntityTransferUpdateOneRequiredWithoutBetweenEntityTransferPackagingItemsNestedInput = {
  connect?: InputMaybe<BetweenEntityTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BetweenEntityTransferCreateOrConnectWithoutBetweenEntityTransferPackagingItemsInput>;
  create?: InputMaybe<BetweenEntityTransferCreateWithoutBetweenEntityTransferPackagingItemsInput>;
  update?: InputMaybe<BetweenEntityTransferUpdateWithoutBetweenEntityTransferPackagingItemsInput>;
  upsert?: InputMaybe<BetweenEntityTransferUpsertWithoutBetweenEntityTransferPackagingItemsInput>;
};

export type BetweenEntityTransferUpdateOneRequiredWithoutBetweenEntityTransferRawMaterialItemsNestedInput = {
  connect?: InputMaybe<BetweenEntityTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BetweenEntityTransferCreateOrConnectWithoutBetweenEntityTransferRawMaterialItemsInput>;
  create?: InputMaybe<BetweenEntityTransferCreateWithoutBetweenEntityTransferRawMaterialItemsInput>;
  update?: InputMaybe<BetweenEntityTransferUpdateWithoutBetweenEntityTransferRawMaterialItemsInput>;
  upsert?: InputMaybe<BetweenEntityTransferUpsertWithoutBetweenEntityTransferRawMaterialItemsInput>;
};

export type BetweenEntityTransferUpdateWithoutBetweenEntityTransferPackagingItemsInput = {
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutBetweenEntityTransferNestedInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destination?: InputMaybe<StringFieldUpdateOperationsInput>;
  origin?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transferDirection?: InputMaybe<EnumTransferDirectionFieldUpdateOperationsInput>;
};

export type BetweenEntityTransferUpdateWithoutBetweenEntityTransferRawMaterialItemsInput = {
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutBetweenEntityTransferNestedInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destination?: InputMaybe<StringFieldUpdateOperationsInput>;
  origin?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transferDirection?: InputMaybe<EnumTransferDirectionFieldUpdateOperationsInput>;
};

export type BetweenEntityTransferUpsertWithoutBetweenEntityTransferPackagingItemsInput = {
  create: BetweenEntityTransferCreateWithoutBetweenEntityTransferPackagingItemsInput;
  update: BetweenEntityTransferUpdateWithoutBetweenEntityTransferPackagingItemsInput;
};

export type BetweenEntityTransferUpsertWithoutBetweenEntityTransferRawMaterialItemsInput = {
  create: BetweenEntityTransferCreateWithoutBetweenEntityTransferRawMaterialItemsInput;
  update: BetweenEntityTransferUpdateWithoutBetweenEntityTransferRawMaterialItemsInput;
};

export type BetweenEntityTransferWhereInput = {
  AND?: InputMaybe<Array<BetweenEntityTransferWhereInput>>;
  NOT?: InputMaybe<Array<BetweenEntityTransferWhereInput>>;
  OR?: InputMaybe<Array<BetweenEntityTransferWhereInput>>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemListRelationFilter>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemListRelationFilter>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  destination?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  origin?: InputMaybe<StringFilter>;
  receivedBy?: InputMaybe<StringNullableFilter>;
  transferDirection?: InputMaybe<EnumTransferDirectionFilter>;
};

export type BetweenEntityTransferWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Beverage = {
  __typename?: 'Beverage';
  _count?: Maybe<BeverageCount>;
  crtBeverageItems: Array<CrtBeverageItem>;
  endingSdEmTaBeverageItems: Array<EndingSdEmTaBeverageItem>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  ozInLarge?: Maybe<Scalars['Float']['output']>;
  ozInMedium?: Maybe<Scalars['Float']['output']>;
  ozInXl?: Maybe<Scalars['Float']['output']>;
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  status: Status;
  unit: Unit;
  unitId: Scalars['Int']['output'];
  yield: Scalars['Int']['output'];
};


export type BeverageCrtBeverageItemsArgs = {
  cursor?: InputMaybe<CrtBeverageItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtBeverageItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtBeverageItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtBeverageItemWhereInput>;
};


export type BeverageEndingSdEmTaBeverageItemsArgs = {
  cursor?: InputMaybe<EndingSdEmTaBeverageItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaBeverageItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaBeverageItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaBeverageItemWhereInput>;
};

export type BeverageAvgAggregate = {
  __typename?: 'BeverageAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  ozInLarge?: Maybe<Scalars['Float']['output']>;
  ozInMedium?: Maybe<Scalars['Float']['output']>;
  ozInXl?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Float']['output']>;
  unitId?: Maybe<Scalars['Float']['output']>;
  yield?: Maybe<Scalars['Float']['output']>;
};

export type BeverageCount = {
  __typename?: 'BeverageCount';
  crtBeverageItems: Scalars['Int']['output'];
  endingSdEmTaBeverageItems: Scalars['Int']['output'];
};

export type BeverageCountAggregate = {
  __typename?: 'BeverageCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  ozInLarge: Scalars['Int']['output'];
  ozInMedium: Scalars['Int']['output'];
  ozInXl: Scalars['Int']['output'];
  rawMaterialProductId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  unitId: Scalars['Int']['output'];
  yield: Scalars['Int']['output'];
};

export type BeverageCreateInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemCreateNestedManyWithoutBeverageInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutBeverageInput>;
  name: Scalars['String']['input'];
  ozInLarge?: InputMaybe<Scalars['Float']['input']>;
  ozInMedium?: InputMaybe<Scalars['Float']['input']>;
  ozInXl?: InputMaybe<Scalars['Float']['input']>;
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutBeveragesInput;
  status?: InputMaybe<Status>;
  unit: UnitCreateNestedOneWithoutBeveragesInput;
  yield: Scalars['Int']['input'];
};

export type BeverageCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  ozInLarge?: InputMaybe<Scalars['Float']['input']>;
  ozInMedium?: InputMaybe<Scalars['Float']['input']>;
  ozInXl?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Status>;
  unitId: Scalars['Int']['input'];
  yield: Scalars['Int']['input'];
};

export type BeverageCreateManyRawMaterialProductInputEnvelope = {
  data: Array<BeverageCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BeverageCreateManyUnitInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  ozInLarge?: InputMaybe<Scalars['Float']['input']>;
  ozInMedium?: InputMaybe<Scalars['Float']['input']>;
  ozInXl?: InputMaybe<Scalars['Float']['input']>;
  rawMaterialProductId: Scalars['Int']['input'];
  status?: InputMaybe<Status>;
  yield: Scalars['Int']['input'];
};

export type BeverageCreateManyUnitInputEnvelope = {
  data: Array<BeverageCreateManyUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BeverageCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeverageCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<BeverageCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<BeverageCreateManyRawMaterialProductInputEnvelope>;
};

export type BeverageCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeverageCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<BeverageCreateWithoutUnitInput>>;
  createMany?: InputMaybe<BeverageCreateManyUnitInputEnvelope>;
};

export type BeverageCreateNestedOneWithoutCrtBeverageItemsInput = {
  connect?: InputMaybe<BeverageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeverageCreateOrConnectWithoutCrtBeverageItemsInput>;
  create?: InputMaybe<BeverageCreateWithoutCrtBeverageItemsInput>;
};

export type BeverageCreateNestedOneWithoutEndingSdEmTaBeverageItemsInput = {
  connect?: InputMaybe<BeverageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeverageCreateOrConnectWithoutEndingSdEmTaBeverageItemsInput>;
  create?: InputMaybe<BeverageCreateWithoutEndingSdEmTaBeverageItemsInput>;
};

export type BeverageCreateOrConnectWithoutCrtBeverageItemsInput = {
  create: BeverageCreateWithoutCrtBeverageItemsInput;
  where: BeverageWhereUniqueInput;
};

export type BeverageCreateOrConnectWithoutEndingSdEmTaBeverageItemsInput = {
  create: BeverageCreateWithoutEndingSdEmTaBeverageItemsInput;
  where: BeverageWhereUniqueInput;
};

export type BeverageCreateOrConnectWithoutRawMaterialProductInput = {
  create: BeverageCreateWithoutRawMaterialProductInput;
  where: BeverageWhereUniqueInput;
};

export type BeverageCreateOrConnectWithoutUnitInput = {
  create: BeverageCreateWithoutUnitInput;
  where: BeverageWhereUniqueInput;
};

export type BeverageCreateWithoutCrtBeverageItemsInput = {
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutBeverageInput>;
  name: Scalars['String']['input'];
  ozInLarge?: InputMaybe<Scalars['Float']['input']>;
  ozInMedium?: InputMaybe<Scalars['Float']['input']>;
  ozInXl?: InputMaybe<Scalars['Float']['input']>;
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutBeveragesInput;
  status?: InputMaybe<Status>;
  unit: UnitCreateNestedOneWithoutBeveragesInput;
  yield: Scalars['Int']['input'];
};

export type BeverageCreateWithoutEndingSdEmTaBeverageItemsInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemCreateNestedManyWithoutBeverageInput>;
  name: Scalars['String']['input'];
  ozInLarge?: InputMaybe<Scalars['Float']['input']>;
  ozInMedium?: InputMaybe<Scalars['Float']['input']>;
  ozInXl?: InputMaybe<Scalars['Float']['input']>;
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutBeveragesInput;
  status?: InputMaybe<Status>;
  unit: UnitCreateNestedOneWithoutBeveragesInput;
  yield: Scalars['Int']['input'];
};

export type BeverageCreateWithoutRawMaterialProductInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemCreateNestedManyWithoutBeverageInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutBeverageInput>;
  name: Scalars['String']['input'];
  ozInLarge?: InputMaybe<Scalars['Float']['input']>;
  ozInMedium?: InputMaybe<Scalars['Float']['input']>;
  ozInXl?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Status>;
  unit: UnitCreateNestedOneWithoutBeveragesInput;
  yield: Scalars['Int']['input'];
};

export type BeverageCreateWithoutUnitInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemCreateNestedManyWithoutBeverageInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutBeverageInput>;
  name: Scalars['String']['input'];
  ozInLarge?: InputMaybe<Scalars['Float']['input']>;
  ozInMedium?: InputMaybe<Scalars['Float']['input']>;
  ozInXl?: InputMaybe<Scalars['Float']['input']>;
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutBeveragesInput;
  status?: InputMaybe<Status>;
  yield: Scalars['Int']['input'];
};

export type BeverageListRelationFilter = {
  every?: InputMaybe<BeverageWhereInput>;
  none?: InputMaybe<BeverageWhereInput>;
  some?: InputMaybe<BeverageWhereInput>;
};

export type BeverageMaxAggregate = {
  __typename?: 'BeverageMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ozInLarge?: Maybe<Scalars['Float']['output']>;
  ozInMedium?: Maybe<Scalars['Float']['output']>;
  ozInXl?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
  unitId?: Maybe<Scalars['Int']['output']>;
  yield?: Maybe<Scalars['Int']['output']>;
};

export type BeverageMinAggregate = {
  __typename?: 'BeverageMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ozInLarge?: Maybe<Scalars['Float']['output']>;
  ozInMedium?: Maybe<Scalars['Float']['output']>;
  ozInXl?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
  unitId?: Maybe<Scalars['Int']['output']>;
  yield?: Maybe<Scalars['Int']['output']>;
};

export type BeverageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeverageOrderByWithRelationInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemOrderByRelationAggregateInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ozInLarge?: InputMaybe<SortOrder>;
  ozInMedium?: InputMaybe<SortOrder>;
  ozInXl?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  unit?: InputMaybe<UnitOrderByWithRelationInput>;
  unitId?: InputMaybe<SortOrder>;
  yield?: InputMaybe<SortOrder>;
};

export type BeverageRelationFilter = {
  is?: InputMaybe<BeverageWhereInput>;
  isNot?: InputMaybe<BeverageWhereInput>;
};

export enum BeverageScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  OzInLarge = 'ozInLarge',
  OzInMedium = 'ozInMedium',
  OzInXl = 'ozInXl',
  RawMaterialProductId = 'rawMaterialProductId',
  Status = 'status',
  UnitId = 'unitId',
  Yield = 'yield'
}

export type BeverageScalarWhereInput = {
  AND?: InputMaybe<Array<BeverageScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeverageScalarWhereInput>>;
  OR?: InputMaybe<Array<BeverageScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  ozInLarge?: InputMaybe<FloatNullableFilter>;
  ozInMedium?: InputMaybe<FloatNullableFilter>;
  ozInXl?: InputMaybe<FloatNullableFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  unitId?: InputMaybe<IntFilter>;
  yield?: InputMaybe<IntFilter>;
};

export type BeverageSumAggregate = {
  __typename?: 'BeverageSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  ozInLarge?: Maybe<Scalars['Float']['output']>;
  ozInMedium?: Maybe<Scalars['Float']['output']>;
  ozInXl?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  unitId?: Maybe<Scalars['Int']['output']>;
  yield?: Maybe<Scalars['Int']['output']>;
};

export type BeverageThrowAway = {
  __typename?: 'BeverageThrowAway';
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type BeverageUpdateInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemUpdateManyWithoutBeverageNestedInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutBeverageNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ozInLarge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInMedium?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInXl?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutBeveragesNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutBeveragesNestedInput>;
  yield?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BeverageUpdateManyMutationInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ozInLarge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInMedium?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInXl?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  yield?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BeverageUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: BeverageUpdateManyMutationInput;
  where: BeverageScalarWhereInput;
};

export type BeverageUpdateManyWithWhereWithoutUnitInput = {
  data: BeverageUpdateManyMutationInput;
  where: BeverageScalarWhereInput;
};

export type BeverageUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeverageCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<BeverageCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<BeverageCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeverageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  set?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  update?: InputMaybe<Array<BeverageUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<BeverageUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<BeverageUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type BeverageUpdateManyWithoutUnitNestedInput = {
  connect?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeverageCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<BeverageCreateWithoutUnitInput>>;
  createMany?: InputMaybe<BeverageCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeverageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  set?: InputMaybe<Array<BeverageWhereUniqueInput>>;
  update?: InputMaybe<Array<BeverageUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<BeverageUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<BeverageUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type BeverageUpdateOneRequiredWithoutCrtBeverageItemsNestedInput = {
  connect?: InputMaybe<BeverageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeverageCreateOrConnectWithoutCrtBeverageItemsInput>;
  create?: InputMaybe<BeverageCreateWithoutCrtBeverageItemsInput>;
  update?: InputMaybe<BeverageUpdateWithoutCrtBeverageItemsInput>;
  upsert?: InputMaybe<BeverageUpsertWithoutCrtBeverageItemsInput>;
};

export type BeverageUpdateOneRequiredWithoutEndingSdEmTaBeverageItemsNestedInput = {
  connect?: InputMaybe<BeverageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeverageCreateOrConnectWithoutEndingSdEmTaBeverageItemsInput>;
  create?: InputMaybe<BeverageCreateWithoutEndingSdEmTaBeverageItemsInput>;
  update?: InputMaybe<BeverageUpdateWithoutEndingSdEmTaBeverageItemsInput>;
  upsert?: InputMaybe<BeverageUpsertWithoutEndingSdEmTaBeverageItemsInput>;
};

export type BeverageUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: BeverageUpdateWithoutRawMaterialProductInput;
  where: BeverageWhereUniqueInput;
};

export type BeverageUpdateWithWhereUniqueWithoutUnitInput = {
  data: BeverageUpdateWithoutUnitInput;
  where: BeverageWhereUniqueInput;
};

export type BeverageUpdateWithoutCrtBeverageItemsInput = {
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutBeverageNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ozInLarge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInMedium?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInXl?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutBeveragesNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutBeveragesNestedInput>;
  yield?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BeverageUpdateWithoutEndingSdEmTaBeverageItemsInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemUpdateManyWithoutBeverageNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ozInLarge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInMedium?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInXl?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutBeveragesNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutBeveragesNestedInput>;
  yield?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BeverageUpdateWithoutRawMaterialProductInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemUpdateManyWithoutBeverageNestedInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutBeverageNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ozInLarge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInMedium?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInXl?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutBeveragesNestedInput>;
  yield?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BeverageUpdateWithoutUnitInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemUpdateManyWithoutBeverageNestedInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutBeverageNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ozInLarge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInMedium?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ozInXl?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutBeveragesNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  yield?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type BeverageUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: BeverageCreateWithoutRawMaterialProductInput;
  update: BeverageUpdateWithoutRawMaterialProductInput;
  where: BeverageWhereUniqueInput;
};

export type BeverageUpsertWithWhereUniqueWithoutUnitInput = {
  create: BeverageCreateWithoutUnitInput;
  update: BeverageUpdateWithoutUnitInput;
  where: BeverageWhereUniqueInput;
};

export type BeverageUpsertWithoutCrtBeverageItemsInput = {
  create: BeverageCreateWithoutCrtBeverageItemsInput;
  update: BeverageUpdateWithoutCrtBeverageItemsInput;
};

export type BeverageUpsertWithoutEndingSdEmTaBeverageItemsInput = {
  create: BeverageCreateWithoutEndingSdEmTaBeverageItemsInput;
  update: BeverageUpdateWithoutEndingSdEmTaBeverageItemsInput;
};

export type BeverageWhereInput = {
  AND?: InputMaybe<Array<BeverageWhereInput>>;
  NOT?: InputMaybe<Array<BeverageWhereInput>>;
  OR?: InputMaybe<Array<BeverageWhereInput>>;
  crtBeverageItems?: InputMaybe<CrtBeverageItemListRelationFilter>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  ozInLarge?: InputMaybe<FloatNullableFilter>;
  ozInMedium?: InputMaybe<FloatNullableFilter>;
  ozInXl?: InputMaybe<FloatNullableFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  unit?: InputMaybe<UnitRelationFilter>;
  unitId?: InputMaybe<IntFilter>;
  yield?: InputMaybe<IntFilter>;
};

export type BeverageWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BeveragesConsolidatedThrowAwayReportItem = {
  __typename?: 'BeveragesConsolidatedThrowAwayReportItem';
  beverages?: Maybe<Array<BeverageThrowAway>>;
  date?: Maybe<Scalars['DateTime']['output']>;
};

export type BeveragesSalesProductControlReportItem = {
  __typename?: 'BeveragesSalesProductControlReportItem';
  beginningQuantity?: Maybe<Scalars['Float']['output']>;
  beverage?: Maybe<Scalars['String']['output']>;
  beverageYield?: Maybe<Scalars['Float']['output']>;
  cupsSoldLargeQuantity?: Maybe<Scalars['Float']['output']>;
  cupsSoldMediumQuantity?: Maybe<Scalars['Float']['output']>;
  cupsSoldXlQuantity?: Maybe<Scalars['Float']['output']>;
  emQuantity?: Maybe<Scalars['Float']['output']>;
  endingQuantity?: Maybe<Scalars['Float']['output']>;
  ozSold?: Maybe<Scalars['Float']['output']>;
  sdQuantity?: Maybe<Scalars['Float']['output']>;
  taQuantity?: Maybe<Scalars['Float']['output']>;
  usage?: Maybe<Scalars['Float']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type CheatSheet = {
  __typename?: 'CheatSheet';
  id: Scalars['Int']['output'];
  instructions: Scalars['String']['output'];
  userGroup: UserGroup;
};

export type CheatSheetAvgAggregate = {
  __typename?: 'CheatSheetAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type CheatSheetCountAggregate = {
  __typename?: 'CheatSheetCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  instructions: Scalars['Int']['output'];
  userGroup: Scalars['Int']['output'];
};

export type CheatSheetCreateInput = {
  instructions: Scalars['String']['input'];
  userGroup: UserGroup;
};

export type CheatSheetMaxAggregate = {
  __typename?: 'CheatSheetMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  userGroup?: Maybe<UserGroup>;
};

export type CheatSheetMinAggregate = {
  __typename?: 'CheatSheetMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  userGroup?: Maybe<UserGroup>;
};

export type CheatSheetOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  instructions?: InputMaybe<SortOrder>;
  userGroup?: InputMaybe<SortOrder>;
};

export enum CheatSheetScalarFieldEnum {
  Id = 'id',
  Instructions = 'instructions',
  UserGroup = 'userGroup'
}

export type CheatSheetSumAggregate = {
  __typename?: 'CheatSheetSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type CheatSheetUpdateInput = {
  instructions?: InputMaybe<StringFieldUpdateOperationsInput>;
  userGroup?: InputMaybe<EnumUserGroupFieldUpdateOperationsInput>;
};

export type CheatSheetWhereInput = {
  AND?: InputMaybe<Array<CheatSheetWhereInput>>;
  NOT?: InputMaybe<Array<CheatSheetWhereInput>>;
  OR?: InputMaybe<Array<CheatSheetWhereInput>>;
  id?: InputMaybe<IntFilter>;
  instructions?: InputMaybe<StringFilter>;
  userGroup?: InputMaybe<EnumUserGroupFilter>;
};

export type CheatSheetWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CmlProduct = {
  __typename?: 'CmlProduct';
  _count?: Maybe<CmlProductCount>;
  cmlProductRecipes: Array<CmlProductRecipe>;
  cmlThrowAwayCmlItems: Array<CmlThrowAwayCmlItem>;
  deliveryReceiptItems: Array<DeliveryReceiptItem>;
  donutMixAndCut: DonutMixAndCut;
  donutMixAndCutId: Scalars['Int']['output'];
  endingSdEmTaCmlItems: Array<EndingSdEmTaCmlItem>;
  id: Scalars['Int']['output'];
  latestCmlProductIngredients: Array<CmlProductIngredient>;
  name: Scalars['String']['output'];
  outletFinishingItems: Array<OutletFinishingItem>;
  productCategory: ProductCategory;
  projectionItems: Array<ProjectionItem>;
  salesCategory: SalesCategory;
  status: Status;
  transferCmlItems: Array<TransferCmlItem>;
  unit: Unit;
  unitId: Scalars['Int']['output'];
};


export type CmlProductCmlProductRecipesArgs = {
  cursor?: InputMaybe<CmlProductRecipeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductRecipeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductRecipeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductRecipeWhereInput>;
};


export type CmlProductCmlThrowAwayCmlItemsArgs = {
  cursor?: InputMaybe<CmlThrowAwayCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlThrowAwayCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlThrowAwayCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayCmlItemWhereInput>;
};


export type CmlProductDeliveryReceiptItemsArgs = {
  cursor?: InputMaybe<DeliveryReceiptItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryReceiptItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryReceiptItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptItemWhereInput>;
};


export type CmlProductEndingSdEmTaCmlItemsArgs = {
  cursor?: InputMaybe<EndingSdEmTaCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaCmlItemWhereInput>;
};


export type CmlProductOutletFinishingItemsArgs = {
  cursor?: InputMaybe<OutletFinishingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutletFinishingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutletFinishingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletFinishingItemWhereInput>;
};


export type CmlProductProjectionItemsArgs = {
  cursor?: InputMaybe<ProjectionItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectionItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectionItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectionItemWhereInput>;
};


export type CmlProductTransferCmlItemsArgs = {
  cursor?: InputMaybe<TransferCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferCmlItemWhereInput>;
};

export type CmlProductAvgAggregate = {
  __typename?: 'CmlProductAvgAggregate';
  donutMixAndCutId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  unitId?: Maybe<Scalars['Float']['output']>;
};

export type CmlProductCount = {
  __typename?: 'CmlProductCount';
  cmlProductRecipes: Scalars['Int']['output'];
  cmlThrowAwayCmlItems: Scalars['Int']['output'];
  deliveryReceiptItems: Scalars['Int']['output'];
  endingSdEmTaCmlItems: Scalars['Int']['output'];
  outletFinishingItems: Scalars['Int']['output'];
  projectionItems: Scalars['Int']['output'];
  transferCmlItems: Scalars['Int']['output'];
};

export type CmlProductCountAggregate = {
  __typename?: 'CmlProductCountAggregate';
  _all: Scalars['Int']['output'];
  donutMixAndCutId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  productCategory: Scalars['Int']['output'];
  salesCategory: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  unitId: Scalars['Int']['output'];
};

export type CmlProductCreateInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateManyDonutMixAndCutInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  productCategory: ProductCategory;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  unitId: Scalars['Int']['input'];
};

export type CmlProductCreateManyDonutMixAndCutInputEnvelope = {
  data: Array<CmlProductCreateManyDonutMixAndCutInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlProductCreateManyUnitInput = {
  donutMixAndCutId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  productCategory: ProductCategory;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
};

export type CmlProductCreateManyUnitInputEnvelope = {
  data: Array<CmlProductCreateManyUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlProductCreateNestedManyWithoutDonutMixAndCutInput = {
  connect?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductCreateOrConnectWithoutDonutMixAndCutInput>>;
  create?: InputMaybe<Array<CmlProductCreateWithoutDonutMixAndCutInput>>;
  createMany?: InputMaybe<CmlProductCreateManyDonutMixAndCutInputEnvelope>;
};

export type CmlProductCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<CmlProductCreateWithoutUnitInput>>;
  createMany?: InputMaybe<CmlProductCreateManyUnitInputEnvelope>;
};

export type CmlProductCreateNestedOneWithoutCmlProductRecipesInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutCmlProductRecipesInput>;
  create?: InputMaybe<CmlProductCreateWithoutCmlProductRecipesInput>;
};

export type CmlProductCreateNestedOneWithoutCmlThrowAwayCmlItemsInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutCmlThrowAwayCmlItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutCmlThrowAwayCmlItemsInput>;
};

export type CmlProductCreateNestedOneWithoutDeliveryReceiptItemsInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutDeliveryReceiptItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutDeliveryReceiptItemsInput>;
};

export type CmlProductCreateNestedOneWithoutEndingSdEmTaCmlItemsInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutEndingSdEmTaCmlItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutEndingSdEmTaCmlItemsInput>;
};

export type CmlProductCreateNestedOneWithoutOutletFinishingItemsInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutOutletFinishingItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutOutletFinishingItemsInput>;
};

export type CmlProductCreateNestedOneWithoutProjectionItemsInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutProjectionItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutProjectionItemsInput>;
};

export type CmlProductCreateNestedOneWithoutTransferCmlItemsInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutTransferCmlItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutTransferCmlItemsInput>;
};

export type CmlProductCreateOrConnectWithoutCmlProductRecipesInput = {
  create: CmlProductCreateWithoutCmlProductRecipesInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateOrConnectWithoutCmlThrowAwayCmlItemsInput = {
  create: CmlProductCreateWithoutCmlThrowAwayCmlItemsInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateOrConnectWithoutDeliveryReceiptItemsInput = {
  create: CmlProductCreateWithoutDeliveryReceiptItemsInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateOrConnectWithoutDonutMixAndCutInput = {
  create: CmlProductCreateWithoutDonutMixAndCutInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateOrConnectWithoutEndingSdEmTaCmlItemsInput = {
  create: CmlProductCreateWithoutEndingSdEmTaCmlItemsInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateOrConnectWithoutOutletFinishingItemsInput = {
  create: CmlProductCreateWithoutOutletFinishingItemsInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateOrConnectWithoutProjectionItemsInput = {
  create: CmlProductCreateWithoutProjectionItemsInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateOrConnectWithoutTransferCmlItemsInput = {
  create: CmlProductCreateWithoutTransferCmlItemsInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateOrConnectWithoutUnitInput = {
  create: CmlProductCreateWithoutUnitInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductCreateWithoutCmlProductRecipesInput = {
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateWithoutCmlThrowAwayCmlItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateWithoutDeliveryReceiptItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateWithoutDonutMixAndCutInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateWithoutEndingSdEmTaCmlItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateWithoutOutletFinishingItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateWithoutProjectionItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateWithoutTransferCmlItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  unit: UnitCreateNestedOneWithoutCmlProductsInput;
};

export type CmlProductCreateWithoutUnitInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeCreateNestedManyWithoutCmlProductInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput>;
  donutMixAndCut: DonutMixAndCutCreateNestedOneWithoutCmlProductsInput;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput>;
  name: Scalars['String']['input'];
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutCmlProductInput>;
  productCategory: ProductCategory;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutCmlProductInput>;
  salesCategory: SalesCategory;
  status?: InputMaybe<Status>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutCmlProductInput>;
};

export type CmlProductIngredient = {
  __typename?: 'CmlProductIngredient';
  cmlProductRecipe: CmlProductRecipe;
  cmlProductRecipeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
};

export type CmlProductIngredientCreateManyCmlProductRecipeInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type CmlProductIngredientCreateManyCmlProductRecipeInputEnvelope = {
  data: Array<CmlProductIngredientCreateManyCmlProductRecipeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlProductIngredientCreateManyRawMaterialProductInput = {
  cmlProductRecipeId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
};

export type CmlProductIngredientCreateManyRawMaterialProductInputEnvelope = {
  data: Array<CmlProductIngredientCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlProductIngredientCreateNestedManyWithoutCmlProductRecipeInput = {
  connect?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductIngredientCreateOrConnectWithoutCmlProductRecipeInput>>;
  create?: InputMaybe<Array<CmlProductIngredientCreateWithoutCmlProductRecipeInput>>;
  createMany?: InputMaybe<CmlProductIngredientCreateManyCmlProductRecipeInputEnvelope>;
};

export type CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductIngredientCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<CmlProductIngredientCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<CmlProductIngredientCreateManyRawMaterialProductInputEnvelope>;
};

export type CmlProductIngredientCreateOrConnectWithoutCmlProductRecipeInput = {
  create: CmlProductIngredientCreateWithoutCmlProductRecipeInput;
  where: CmlProductIngredientWhereUniqueInput;
};

export type CmlProductIngredientCreateOrConnectWithoutRawMaterialProductInput = {
  create: CmlProductIngredientCreateWithoutRawMaterialProductInput;
  where: CmlProductIngredientWhereUniqueInput;
};

export type CmlProductIngredientCreateWithoutCmlProductRecipeInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutCmlProductIngredientsInput;
};

export type CmlProductIngredientCreateWithoutRawMaterialProductInput = {
  cmlProductRecipe: CmlProductRecipeCreateNestedOneWithoutCmlProductIngredientsInput;
  quantity: Scalars['Float']['input'];
};

export type CmlProductIngredientListRelationFilter = {
  every?: InputMaybe<CmlProductIngredientWhereInput>;
  none?: InputMaybe<CmlProductIngredientWhereInput>;
  some?: InputMaybe<CmlProductIngredientWhereInput>;
};

export type CmlProductIngredientOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CmlProductIngredientOrderByWithRelationInput = {
  cmlProductRecipe?: InputMaybe<CmlProductRecipeOrderByWithRelationInput>;
  cmlProductRecipeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
};

export enum CmlProductIngredientScalarFieldEnum {
  CmlProductRecipeId = 'cmlProductRecipeId',
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId'
}

export type CmlProductIngredientScalarWhereInput = {
  AND?: InputMaybe<Array<CmlProductIngredientScalarWhereInput>>;
  NOT?: InputMaybe<Array<CmlProductIngredientScalarWhereInput>>;
  OR?: InputMaybe<Array<CmlProductIngredientScalarWhereInput>>;
  cmlProductRecipeId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type CmlProductIngredientUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CmlProductIngredientUpdateManyWithWhereWithoutCmlProductRecipeInput = {
  data: CmlProductIngredientUpdateManyMutationInput;
  where: CmlProductIngredientScalarWhereInput;
};

export type CmlProductIngredientUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: CmlProductIngredientUpdateManyMutationInput;
  where: CmlProductIngredientScalarWhereInput;
};

export type CmlProductIngredientUpdateManyWithoutCmlProductRecipeNestedInput = {
  connect?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductIngredientCreateOrConnectWithoutCmlProductRecipeInput>>;
  create?: InputMaybe<Array<CmlProductIngredientCreateWithoutCmlProductRecipeInput>>;
  createMany?: InputMaybe<CmlProductIngredientCreateManyCmlProductRecipeInputEnvelope>;
  delete?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlProductIngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlProductIngredientUpdateWithWhereUniqueWithoutCmlProductRecipeInput>>;
  updateMany?: InputMaybe<Array<CmlProductIngredientUpdateManyWithWhereWithoutCmlProductRecipeInput>>;
  upsert?: InputMaybe<Array<CmlProductIngredientUpsertWithWhereUniqueWithoutCmlProductRecipeInput>>;
};

export type CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductIngredientCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<CmlProductIngredientCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<CmlProductIngredientCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlProductIngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlProductIngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlProductIngredientUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<CmlProductIngredientUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<CmlProductIngredientUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type CmlProductIngredientUpdateWithWhereUniqueWithoutCmlProductRecipeInput = {
  data: CmlProductIngredientUpdateWithoutCmlProductRecipeInput;
  where: CmlProductIngredientWhereUniqueInput;
};

export type CmlProductIngredientUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: CmlProductIngredientUpdateWithoutRawMaterialProductInput;
  where: CmlProductIngredientWhereUniqueInput;
};

export type CmlProductIngredientUpdateWithoutCmlProductRecipeInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutCmlProductIngredientsNestedInput>;
};

export type CmlProductIngredientUpdateWithoutRawMaterialProductInput = {
  cmlProductRecipe?: InputMaybe<CmlProductRecipeUpdateOneRequiredWithoutCmlProductIngredientsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CmlProductIngredientUpsertWithWhereUniqueWithoutCmlProductRecipeInput = {
  create: CmlProductIngredientCreateWithoutCmlProductRecipeInput;
  update: CmlProductIngredientUpdateWithoutCmlProductRecipeInput;
  where: CmlProductIngredientWhereUniqueInput;
};

export type CmlProductIngredientUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: CmlProductIngredientCreateWithoutRawMaterialProductInput;
  update: CmlProductIngredientUpdateWithoutRawMaterialProductInput;
  where: CmlProductIngredientWhereUniqueInput;
};

export type CmlProductIngredientWhereInput = {
  AND?: InputMaybe<Array<CmlProductIngredientWhereInput>>;
  NOT?: InputMaybe<Array<CmlProductIngredientWhereInput>>;
  OR?: InputMaybe<Array<CmlProductIngredientWhereInput>>;
  cmlProductRecipe?: InputMaybe<CmlProductRecipeRelationFilter>;
  cmlProductRecipeId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type CmlProductIngredientWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CmlProductItem = {
  __typename?: 'CmlProductItem';
  cmlProduct: Scalars['String']['output'];
  perCmlProductDailyEndings: Array<PerCmlProductDailyEnding>;
};

export type CmlProductListRelationFilter = {
  every?: InputMaybe<CmlProductWhereInput>;
  none?: InputMaybe<CmlProductWhereInput>;
  some?: InputMaybe<CmlProductWhereInput>;
};

export type CmlProductMaxAggregate = {
  __typename?: 'CmlProductMaxAggregate';
  donutMixAndCutId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productCategory?: Maybe<ProductCategory>;
  salesCategory?: Maybe<SalesCategory>;
  status?: Maybe<Status>;
  unitId?: Maybe<Scalars['Int']['output']>;
};

export type CmlProductMinAggregate = {
  __typename?: 'CmlProductMinAggregate';
  donutMixAndCutId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productCategory?: Maybe<ProductCategory>;
  salesCategory?: Maybe<SalesCategory>;
  status?: Maybe<Status>;
  unitId?: Maybe<Scalars['Int']['output']>;
};

export type CmlProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CmlProductOrderByWithRelationInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeOrderByRelationAggregateInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemOrderByRelationAggregateInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemOrderByRelationAggregateInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutOrderByWithRelationInput>;
  donutMixAndCutId?: InputMaybe<SortOrder>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemOrderByRelationAggregateInput>;
  productCategory?: InputMaybe<SortOrder>;
  projectionItems?: InputMaybe<ProjectionItemOrderByRelationAggregateInput>;
  salesCategory?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transferCmlItems?: InputMaybe<TransferCmlItemOrderByRelationAggregateInput>;
  unit?: InputMaybe<UnitOrderByWithRelationInput>;
  unitId?: InputMaybe<SortOrder>;
};

export type CmlProductRecipe = {
  __typename?: 'CmlProductRecipe';
  _count?: Maybe<CmlProductRecipeCount>;
  cmlProduct: CmlProduct;
  cmlProductId: Scalars['Int']['output'];
  cmlProductIngredients: Array<CmlProductIngredient>;
  date: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
};


export type CmlProductRecipeCmlProductIngredientsArgs = {
  cursor?: InputMaybe<CmlProductIngredientWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductIngredientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductIngredientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductIngredientWhereInput>;
};

export type CmlProductRecipeAvgAggregate = {
  __typename?: 'CmlProductRecipeAvgAggregate';
  cmlProductId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type CmlProductRecipeCount = {
  __typename?: 'CmlProductRecipeCount';
  cmlProductIngredients: Scalars['Int']['output'];
};

export type CmlProductRecipeCountAggregate = {
  __typename?: 'CmlProductRecipeCountAggregate';
  _all: Scalars['Int']['output'];
  cmlProductId: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type CmlProductRecipeCreateInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutCmlProductRecipesInput;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutCmlProductRecipeInput>;
  date: Scalars['DateTime']['input'];
};

export type CmlProductRecipeCreateManyCmlProductInput = {
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CmlProductRecipeCreateManyCmlProductInputEnvelope = {
  data: Array<CmlProductRecipeCreateManyCmlProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlProductRecipeCreateNestedManyWithoutCmlProductInput = {
  connect?: InputMaybe<Array<CmlProductRecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductRecipeCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<CmlProductRecipeCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<CmlProductRecipeCreateManyCmlProductInputEnvelope>;
};

export type CmlProductRecipeCreateNestedOneWithoutCmlProductIngredientsInput = {
  connect?: InputMaybe<CmlProductRecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductRecipeCreateOrConnectWithoutCmlProductIngredientsInput>;
  create?: InputMaybe<CmlProductRecipeCreateWithoutCmlProductIngredientsInput>;
};

export type CmlProductRecipeCreateOrConnectWithoutCmlProductIngredientsInput = {
  create: CmlProductRecipeCreateWithoutCmlProductIngredientsInput;
  where: CmlProductRecipeWhereUniqueInput;
};

export type CmlProductRecipeCreateOrConnectWithoutCmlProductInput = {
  create: CmlProductRecipeCreateWithoutCmlProductInput;
  where: CmlProductRecipeWhereUniqueInput;
};

export type CmlProductRecipeCreateWithoutCmlProductIngredientsInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutCmlProductRecipesInput;
  date: Scalars['DateTime']['input'];
};

export type CmlProductRecipeCreateWithoutCmlProductInput = {
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutCmlProductRecipeInput>;
  date: Scalars['DateTime']['input'];
};

export type CmlProductRecipeDateCmlProductIdCompoundUniqueInput = {
  cmlProductId: Scalars['Int']['input'];
  date: Scalars['DateTime']['input'];
};

export type CmlProductRecipeListRelationFilter = {
  every?: InputMaybe<CmlProductRecipeWhereInput>;
  none?: InputMaybe<CmlProductRecipeWhereInput>;
  some?: InputMaybe<CmlProductRecipeWhereInput>;
};

export type CmlProductRecipeMaxAggregate = {
  __typename?: 'CmlProductRecipeMaxAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CmlProductRecipeMinAggregate = {
  __typename?: 'CmlProductRecipeMinAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CmlProductRecipeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CmlProductRecipeOrderByWithRelationInput = {
  cmlProduct?: InputMaybe<CmlProductOrderByWithRelationInput>;
  cmlProductId?: InputMaybe<SortOrder>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientOrderByRelationAggregateInput>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type CmlProductRecipeRelationFilter = {
  is?: InputMaybe<CmlProductRecipeWhereInput>;
  isNot?: InputMaybe<CmlProductRecipeWhereInput>;
};

export enum CmlProductRecipeScalarFieldEnum {
  CmlProductId = 'cmlProductId',
  Date = 'date',
  Id = 'id'
}

export type CmlProductRecipeScalarWhereInput = {
  AND?: InputMaybe<Array<CmlProductRecipeScalarWhereInput>>;
  NOT?: InputMaybe<Array<CmlProductRecipeScalarWhereInput>>;
  OR?: InputMaybe<Array<CmlProductRecipeScalarWhereInput>>;
  cmlProductId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
};

export type CmlProductRecipeSumAggregate = {
  __typename?: 'CmlProductRecipeSumAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CmlProductRecipeUpdateInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutCmlProductRecipesNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutCmlProductRecipeNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CmlProductRecipeUpdateManyMutationInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CmlProductRecipeUpdateManyWithWhereWithoutCmlProductInput = {
  data: CmlProductRecipeUpdateManyMutationInput;
  where: CmlProductRecipeScalarWhereInput;
};

export type CmlProductRecipeUpdateManyWithoutCmlProductNestedInput = {
  connect?: InputMaybe<Array<CmlProductRecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductRecipeCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<CmlProductRecipeCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<CmlProductRecipeCreateManyCmlProductInputEnvelope>;
  delete?: InputMaybe<Array<CmlProductRecipeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlProductRecipeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlProductRecipeWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlProductRecipeWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlProductRecipeUpdateWithWhereUniqueWithoutCmlProductInput>>;
  updateMany?: InputMaybe<Array<CmlProductRecipeUpdateManyWithWhereWithoutCmlProductInput>>;
  upsert?: InputMaybe<Array<CmlProductRecipeUpsertWithWhereUniqueWithoutCmlProductInput>>;
};

export type CmlProductRecipeUpdateOneRequiredWithoutCmlProductIngredientsNestedInput = {
  connect?: InputMaybe<CmlProductRecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductRecipeCreateOrConnectWithoutCmlProductIngredientsInput>;
  create?: InputMaybe<CmlProductRecipeCreateWithoutCmlProductIngredientsInput>;
  update?: InputMaybe<CmlProductRecipeUpdateWithoutCmlProductIngredientsInput>;
  upsert?: InputMaybe<CmlProductRecipeUpsertWithoutCmlProductIngredientsInput>;
};

export type CmlProductRecipeUpdateWithWhereUniqueWithoutCmlProductInput = {
  data: CmlProductRecipeUpdateWithoutCmlProductInput;
  where: CmlProductRecipeWhereUniqueInput;
};

export type CmlProductRecipeUpdateWithoutCmlProductIngredientsInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutCmlProductRecipesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CmlProductRecipeUpdateWithoutCmlProductInput = {
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutCmlProductRecipeNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CmlProductRecipeUpsertWithWhereUniqueWithoutCmlProductInput = {
  create: CmlProductRecipeCreateWithoutCmlProductInput;
  update: CmlProductRecipeUpdateWithoutCmlProductInput;
  where: CmlProductRecipeWhereUniqueInput;
};

export type CmlProductRecipeUpsertWithoutCmlProductIngredientsInput = {
  create: CmlProductRecipeCreateWithoutCmlProductIngredientsInput;
  update: CmlProductRecipeUpdateWithoutCmlProductIngredientsInput;
};

export type CmlProductRecipeWhereInput = {
  AND?: InputMaybe<Array<CmlProductRecipeWhereInput>>;
  NOT?: InputMaybe<Array<CmlProductRecipeWhereInput>>;
  OR?: InputMaybe<Array<CmlProductRecipeWhereInput>>;
  cmlProduct?: InputMaybe<CmlProductRelationFilter>;
  cmlProductId?: InputMaybe<IntFilter>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientListRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
};

export type CmlProductRecipeWhereUniqueInput = {
  date_cmlProductId?: InputMaybe<CmlProductRecipeDateCmlProductIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CmlProductRelationFilter = {
  is?: InputMaybe<CmlProductWhereInput>;
  isNot?: InputMaybe<CmlProductWhereInput>;
};

export enum CmlProductScalarFieldEnum {
  DonutMixAndCutId = 'donutMixAndCutId',
  Id = 'id',
  Name = 'name',
  ProductCategory = 'productCategory',
  SalesCategory = 'salesCategory',
  Status = 'status',
  UnitId = 'unitId'
}

export type CmlProductScalarWhereInput = {
  AND?: InputMaybe<Array<CmlProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<CmlProductScalarWhereInput>>;
  OR?: InputMaybe<Array<CmlProductScalarWhereInput>>;
  donutMixAndCutId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  productCategory?: InputMaybe<EnumProductCategoryFilter>;
  salesCategory?: InputMaybe<EnumSalesCategoryFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  unitId?: InputMaybe<IntFilter>;
};

export type CmlProductSumAggregate = {
  __typename?: 'CmlProductSumAggregate';
  donutMixAndCutId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  unitId?: Maybe<Scalars['Int']['output']>;
};

export type CmlProductUpdateInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateManyMutationInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type CmlProductUpdateManyWithWhereWithoutDonutMixAndCutInput = {
  data: CmlProductUpdateManyMutationInput;
  where: CmlProductScalarWhereInput;
};

export type CmlProductUpdateManyWithWhereWithoutUnitInput = {
  data: CmlProductUpdateManyMutationInput;
  where: CmlProductScalarWhereInput;
};

export type CmlProductUpdateManyWithoutDonutMixAndCutNestedInput = {
  connect?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductCreateOrConnectWithoutDonutMixAndCutInput>>;
  create?: InputMaybe<Array<CmlProductCreateWithoutDonutMixAndCutInput>>;
  createMany?: InputMaybe<CmlProductCreateManyDonutMixAndCutInputEnvelope>;
  delete?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlProductUpdateWithWhereUniqueWithoutDonutMixAndCutInput>>;
  updateMany?: InputMaybe<Array<CmlProductUpdateManyWithWhereWithoutDonutMixAndCutInput>>;
  upsert?: InputMaybe<Array<CmlProductUpsertWithWhereUniqueWithoutDonutMixAndCutInput>>;
};

export type CmlProductUpdateManyWithoutUnitNestedInput = {
  connect?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlProductCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<CmlProductCreateWithoutUnitInput>>;
  createMany?: InputMaybe<CmlProductCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlProductUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<CmlProductUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<CmlProductUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type CmlProductUpdateOneRequiredWithoutCmlProductRecipesNestedInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutCmlProductRecipesInput>;
  create?: InputMaybe<CmlProductCreateWithoutCmlProductRecipesInput>;
  update?: InputMaybe<CmlProductUpdateWithoutCmlProductRecipesInput>;
  upsert?: InputMaybe<CmlProductUpsertWithoutCmlProductRecipesInput>;
};

export type CmlProductUpdateOneRequiredWithoutCmlThrowAwayCmlItemsNestedInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutCmlThrowAwayCmlItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutCmlThrowAwayCmlItemsInput>;
  update?: InputMaybe<CmlProductUpdateWithoutCmlThrowAwayCmlItemsInput>;
  upsert?: InputMaybe<CmlProductUpsertWithoutCmlThrowAwayCmlItemsInput>;
};

export type CmlProductUpdateOneRequiredWithoutDeliveryReceiptItemsNestedInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutDeliveryReceiptItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutDeliveryReceiptItemsInput>;
  update?: InputMaybe<CmlProductUpdateWithoutDeliveryReceiptItemsInput>;
  upsert?: InputMaybe<CmlProductUpsertWithoutDeliveryReceiptItemsInput>;
};

export type CmlProductUpdateOneRequiredWithoutEndingSdEmTaCmlItemsNestedInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutEndingSdEmTaCmlItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutEndingSdEmTaCmlItemsInput>;
  update?: InputMaybe<CmlProductUpdateWithoutEndingSdEmTaCmlItemsInput>;
  upsert?: InputMaybe<CmlProductUpsertWithoutEndingSdEmTaCmlItemsInput>;
};

export type CmlProductUpdateOneRequiredWithoutOutletFinishingItemsNestedInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutOutletFinishingItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutOutletFinishingItemsInput>;
  update?: InputMaybe<CmlProductUpdateWithoutOutletFinishingItemsInput>;
  upsert?: InputMaybe<CmlProductUpsertWithoutOutletFinishingItemsInput>;
};

export type CmlProductUpdateOneRequiredWithoutProjectionItemsNestedInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutProjectionItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutProjectionItemsInput>;
  update?: InputMaybe<CmlProductUpdateWithoutProjectionItemsInput>;
  upsert?: InputMaybe<CmlProductUpsertWithoutProjectionItemsInput>;
};

export type CmlProductUpdateOneRequiredWithoutTransferCmlItemsNestedInput = {
  connect?: InputMaybe<CmlProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlProductCreateOrConnectWithoutTransferCmlItemsInput>;
  create?: InputMaybe<CmlProductCreateWithoutTransferCmlItemsInput>;
  update?: InputMaybe<CmlProductUpdateWithoutTransferCmlItemsInput>;
  upsert?: InputMaybe<CmlProductUpsertWithoutTransferCmlItemsInput>;
};

export type CmlProductUpdateWithWhereUniqueWithoutDonutMixAndCutInput = {
  data: CmlProductUpdateWithoutDonutMixAndCutInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductUpdateWithWhereUniqueWithoutUnitInput = {
  data: CmlProductUpdateWithoutUnitInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductUpdateWithoutCmlProductRecipesInput = {
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateWithoutCmlThrowAwayCmlItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateWithoutDeliveryReceiptItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateWithoutDonutMixAndCutInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateWithoutEndingSdEmTaCmlItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateWithoutOutletFinishingItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateWithoutProjectionItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateWithoutTransferCmlItemsInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutCmlProductsNestedInput>;
};

export type CmlProductUpdateWithoutUnitInput = {
  cmlProductRecipes?: InputMaybe<CmlProductRecipeUpdateManyWithoutCmlProductNestedInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutCmlProductNestedInput>;
  productCategory?: InputMaybe<EnumProductCategoryFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutCmlProductNestedInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutCmlProductNestedInput>;
};

export type CmlProductUpsertWithWhereUniqueWithoutDonutMixAndCutInput = {
  create: CmlProductCreateWithoutDonutMixAndCutInput;
  update: CmlProductUpdateWithoutDonutMixAndCutInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductUpsertWithWhereUniqueWithoutUnitInput = {
  create: CmlProductCreateWithoutUnitInput;
  update: CmlProductUpdateWithoutUnitInput;
  where: CmlProductWhereUniqueInput;
};

export type CmlProductUpsertWithoutCmlProductRecipesInput = {
  create: CmlProductCreateWithoutCmlProductRecipesInput;
  update: CmlProductUpdateWithoutCmlProductRecipesInput;
};

export type CmlProductUpsertWithoutCmlThrowAwayCmlItemsInput = {
  create: CmlProductCreateWithoutCmlThrowAwayCmlItemsInput;
  update: CmlProductUpdateWithoutCmlThrowAwayCmlItemsInput;
};

export type CmlProductUpsertWithoutDeliveryReceiptItemsInput = {
  create: CmlProductCreateWithoutDeliveryReceiptItemsInput;
  update: CmlProductUpdateWithoutDeliveryReceiptItemsInput;
};

export type CmlProductUpsertWithoutEndingSdEmTaCmlItemsInput = {
  create: CmlProductCreateWithoutEndingSdEmTaCmlItemsInput;
  update: CmlProductUpdateWithoutEndingSdEmTaCmlItemsInput;
};

export type CmlProductUpsertWithoutOutletFinishingItemsInput = {
  create: CmlProductCreateWithoutOutletFinishingItemsInput;
  update: CmlProductUpdateWithoutOutletFinishingItemsInput;
};

export type CmlProductUpsertWithoutProjectionItemsInput = {
  create: CmlProductCreateWithoutProjectionItemsInput;
  update: CmlProductUpdateWithoutProjectionItemsInput;
};

export type CmlProductUpsertWithoutTransferCmlItemsInput = {
  create: CmlProductCreateWithoutTransferCmlItemsInput;
  update: CmlProductUpdateWithoutTransferCmlItemsInput;
};

export type CmlProductWhereInput = {
  AND?: InputMaybe<Array<CmlProductWhereInput>>;
  NOT?: InputMaybe<Array<CmlProductWhereInput>>;
  OR?: InputMaybe<Array<CmlProductWhereInput>>;
  cmlProductRecipes?: InputMaybe<CmlProductRecipeListRelationFilter>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemListRelationFilter>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemListRelationFilter>;
  donutMixAndCut?: InputMaybe<DonutMixAndCutRelationFilter>;
  donutMixAndCutId?: InputMaybe<IntFilter>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemListRelationFilter>;
  productCategory?: InputMaybe<EnumProductCategoryFilter>;
  projectionItems?: InputMaybe<ProjectionItemListRelationFilter>;
  salesCategory?: InputMaybe<EnumSalesCategoryFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  transferCmlItems?: InputMaybe<TransferCmlItemListRelationFilter>;
  unit?: InputMaybe<UnitRelationFilter>;
  unitId?: InputMaybe<IntFilter>;
};

export type CmlProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CmlProductsSalesProductControlReportItem = {
  __typename?: 'CmlProductsSalesProductControlReportItem';
  beginningQuantity?: Maybe<Scalars['Float']['output']>;
  crtCmlItemQuantity?: Maybe<Scalars['Float']['output']>;
  deliveriesQuantity?: Maybe<Scalars['Float']['output']>;
  emQuantity?: Maybe<Scalars['Float']['output']>;
  endingQuantity?: Maybe<Scalars['Float']['output']>;
  salesCategory?: Maybe<Scalars['String']['output']>;
  sdQuantity?: Maybe<Scalars['Float']['output']>;
  taQuantity?: Maybe<Scalars['Float']['output']>;
  transferInQuantity?: Maybe<Scalars['Float']['output']>;
  transferOutQuantity?: Maybe<Scalars['Float']['output']>;
  usage?: Maybe<Scalars['Float']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type CmlThrowAway = {
  __typename?: 'CmlThrowAway';
  _count?: Maybe<CmlThrowAwayCount>;
  checkedBy?: Maybe<Scalars['String']['output']>;
  cmlThrowAwayCmlItems: Array<CmlThrowAwayCmlItem>;
  cmlThrowAwayLocation: CmlThrowAwayLocation;
  cmlThrowAwayPackagingItems: Array<CmlThrowAwayPackagingItem>;
  cmlThrowAwayRawMaterialItems: Array<CmlThrowAwayRawMaterialItem>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id: Scalars['Int']['output'];
};


export type CmlThrowAwayCmlThrowAwayCmlItemsArgs = {
  cursor?: InputMaybe<CmlThrowAwayCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlThrowAwayCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlThrowAwayCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayCmlItemWhereInput>;
};


export type CmlThrowAwayCmlThrowAwayPackagingItemsArgs = {
  cursor?: InputMaybe<CmlThrowAwayPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlThrowAwayPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlThrowAwayPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayPackagingItemWhereInput>;
};


export type CmlThrowAwayCmlThrowAwayRawMaterialItemsArgs = {
  cursor?: InputMaybe<CmlThrowAwayRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlThrowAwayRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlThrowAwayRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayRawMaterialItemWhereInput>;
};

export type CmlThrowAwayAvgAggregate = {
  __typename?: 'CmlThrowAwayAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type CmlThrowAwayCmlItem = {
  __typename?: 'CmlThrowAwayCmlItem';
  checkedQuantity?: Maybe<Scalars['Int']['output']>;
  cmlProduct: CmlProduct;
  cmlProductId: Scalars['Int']['output'];
  cmlThrowAway: CmlThrowAway;
  cmlThrowAwayId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type CmlThrowAwayCmlItemCreateManyCmlProductInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  cmlThrowAwayId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type CmlThrowAwayCmlItemCreateManyCmlProductInputEnvelope = {
  data: Array<CmlThrowAwayCmlItemCreateManyCmlProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlThrowAwayCmlItemCreateManyCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  cmlProductId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type CmlThrowAwayCmlItemCreateManyCmlThrowAwayInputEnvelope = {
  data: Array<CmlThrowAwayCmlItemCreateManyCmlThrowAwayInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlThrowAwayCmlItemCreateNestedManyWithoutCmlProductInput = {
  connect?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayCmlItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<CmlThrowAwayCmlItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<CmlThrowAwayCmlItemCreateManyCmlProductInputEnvelope>;
};

export type CmlThrowAwayCmlItemCreateNestedManyWithoutCmlThrowAwayInput = {
  connect?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayCmlItemCreateOrConnectWithoutCmlThrowAwayInput>>;
  create?: InputMaybe<Array<CmlThrowAwayCmlItemCreateWithoutCmlThrowAwayInput>>;
  createMany?: InputMaybe<CmlThrowAwayCmlItemCreateManyCmlThrowAwayInputEnvelope>;
};

export type CmlThrowAwayCmlItemCreateOrConnectWithoutCmlProductInput = {
  create: CmlThrowAwayCmlItemCreateWithoutCmlProductInput;
  where: CmlThrowAwayCmlItemWhereUniqueInput;
};

export type CmlThrowAwayCmlItemCreateOrConnectWithoutCmlThrowAwayInput = {
  create: CmlThrowAwayCmlItemCreateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayCmlItemWhereUniqueInput;
};

export type CmlThrowAwayCmlItemCreateWithoutCmlProductInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  cmlThrowAway: CmlThrowAwayCreateNestedOneWithoutCmlThrowAwayCmlItemsInput;
  quantity: Scalars['Int']['input'];
};

export type CmlThrowAwayCmlItemCreateWithoutCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  cmlProduct: CmlProductCreateNestedOneWithoutCmlThrowAwayCmlItemsInput;
  quantity: Scalars['Int']['input'];
};

export type CmlThrowAwayCmlItemListRelationFilter = {
  every?: InputMaybe<CmlThrowAwayCmlItemWhereInput>;
  none?: InputMaybe<CmlThrowAwayCmlItemWhereInput>;
  some?: InputMaybe<CmlThrowAwayCmlItemWhereInput>;
};

export type CmlThrowAwayCmlItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CmlThrowAwayCmlItemOrderByWithRelationInput = {
  checkedQuantity?: InputMaybe<SortOrder>;
  cmlProduct?: InputMaybe<CmlProductOrderByWithRelationInput>;
  cmlProductId?: InputMaybe<SortOrder>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayOrderByWithRelationInput>;
  cmlThrowAwayId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum CmlThrowAwayCmlItemScalarFieldEnum {
  CheckedQuantity = 'checkedQuantity',
  CmlProductId = 'cmlProductId',
  CmlThrowAwayId = 'cmlThrowAwayId',
  Id = 'id',
  Quantity = 'quantity'
}

export type CmlThrowAwayCmlItemScalarWhereInput = {
  AND?: InputMaybe<Array<CmlThrowAwayCmlItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CmlThrowAwayCmlItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CmlThrowAwayCmlItemScalarWhereInput>>;
  checkedQuantity?: InputMaybe<IntNullableFilter>;
  cmlProductId?: InputMaybe<IntFilter>;
  cmlThrowAwayId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type CmlThrowAwayCmlItemUpdateManyMutationInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CmlThrowAwayCmlItemUpdateManyWithWhereWithoutCmlProductInput = {
  data: CmlThrowAwayCmlItemUpdateManyMutationInput;
  where: CmlThrowAwayCmlItemScalarWhereInput;
};

export type CmlThrowAwayCmlItemUpdateManyWithWhereWithoutCmlThrowAwayInput = {
  data: CmlThrowAwayCmlItemUpdateManyMutationInput;
  where: CmlThrowAwayCmlItemScalarWhereInput;
};

export type CmlThrowAwayCmlItemUpdateManyWithoutCmlProductNestedInput = {
  connect?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayCmlItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<CmlThrowAwayCmlItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<CmlThrowAwayCmlItemCreateManyCmlProductInputEnvelope>;
  delete?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlThrowAwayCmlItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlThrowAwayCmlItemUpdateWithWhereUniqueWithoutCmlProductInput>>;
  updateMany?: InputMaybe<Array<CmlThrowAwayCmlItemUpdateManyWithWhereWithoutCmlProductInput>>;
  upsert?: InputMaybe<Array<CmlThrowAwayCmlItemUpsertWithWhereUniqueWithoutCmlProductInput>>;
};

export type CmlThrowAwayCmlItemUpdateManyWithoutCmlThrowAwayNestedInput = {
  connect?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayCmlItemCreateOrConnectWithoutCmlThrowAwayInput>>;
  create?: InputMaybe<Array<CmlThrowAwayCmlItemCreateWithoutCmlThrowAwayInput>>;
  createMany?: InputMaybe<CmlThrowAwayCmlItemCreateManyCmlThrowAwayInputEnvelope>;
  delete?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlThrowAwayCmlItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlThrowAwayCmlItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlThrowAwayCmlItemUpdateWithWhereUniqueWithoutCmlThrowAwayInput>>;
  updateMany?: InputMaybe<Array<CmlThrowAwayCmlItemUpdateManyWithWhereWithoutCmlThrowAwayInput>>;
  upsert?: InputMaybe<Array<CmlThrowAwayCmlItemUpsertWithWhereUniqueWithoutCmlThrowAwayInput>>;
};

export type CmlThrowAwayCmlItemUpdateWithWhereUniqueWithoutCmlProductInput = {
  data: CmlThrowAwayCmlItemUpdateWithoutCmlProductInput;
  where: CmlThrowAwayCmlItemWhereUniqueInput;
};

export type CmlThrowAwayCmlItemUpdateWithWhereUniqueWithoutCmlThrowAwayInput = {
  data: CmlThrowAwayCmlItemUpdateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayCmlItemWhereUniqueInput;
};

export type CmlThrowAwayCmlItemUpdateWithoutCmlProductInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayUpdateOneRequiredWithoutCmlThrowAwayCmlItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CmlThrowAwayCmlItemUpdateWithoutCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutCmlThrowAwayCmlItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CmlThrowAwayCmlItemUpsertWithWhereUniqueWithoutCmlProductInput = {
  create: CmlThrowAwayCmlItemCreateWithoutCmlProductInput;
  update: CmlThrowAwayCmlItemUpdateWithoutCmlProductInput;
  where: CmlThrowAwayCmlItemWhereUniqueInput;
};

export type CmlThrowAwayCmlItemUpsertWithWhereUniqueWithoutCmlThrowAwayInput = {
  create: CmlThrowAwayCmlItemCreateWithoutCmlThrowAwayInput;
  update: CmlThrowAwayCmlItemUpdateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayCmlItemWhereUniqueInput;
};

export type CmlThrowAwayCmlItemWhereInput = {
  AND?: InputMaybe<Array<CmlThrowAwayCmlItemWhereInput>>;
  NOT?: InputMaybe<Array<CmlThrowAwayCmlItemWhereInput>>;
  OR?: InputMaybe<Array<CmlThrowAwayCmlItemWhereInput>>;
  checkedQuantity?: InputMaybe<IntNullableFilter>;
  cmlProduct?: InputMaybe<CmlProductRelationFilter>;
  cmlProductId?: InputMaybe<IntFilter>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayRelationFilter>;
  cmlThrowAwayId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type CmlThrowAwayCmlItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CmlThrowAwayCount = {
  __typename?: 'CmlThrowAwayCount';
  cmlThrowAwayCmlItems: Scalars['Int']['output'];
  cmlThrowAwayPackagingItems: Scalars['Int']['output'];
  cmlThrowAwayRawMaterialItems: Scalars['Int']['output'];
};

export type CmlThrowAwayCountAggregate = {
  __typename?: 'CmlThrowAwayCountAggregate';
  _all: Scalars['Int']['output'];
  checkedBy: Scalars['Int']['output'];
  cmlThrowAwayLocation: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type CmlThrowAwayCreateInput = {
  checkedBy?: InputMaybe<Scalars['String']['input']>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlThrowAwayInput>;
  cmlThrowAwayLocation: CmlThrowAwayLocation;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutCmlThrowAwayInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutCmlThrowAwayInput>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
};

export type CmlThrowAwayCreateNestedOneWithoutCmlThrowAwayCmlItemsInput = {
  connect?: InputMaybe<CmlThrowAwayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayCmlItemsInput>;
  create?: InputMaybe<CmlThrowAwayCreateWithoutCmlThrowAwayCmlItemsInput>;
};

export type CmlThrowAwayCreateNestedOneWithoutCmlThrowAwayPackagingItemsInput = {
  connect?: InputMaybe<CmlThrowAwayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayPackagingItemsInput>;
  create?: InputMaybe<CmlThrowAwayCreateWithoutCmlThrowAwayPackagingItemsInput>;
};

export type CmlThrowAwayCreateNestedOneWithoutCmlThrowAwayRawMaterialItemsInput = {
  connect?: InputMaybe<CmlThrowAwayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayRawMaterialItemsInput>;
  create?: InputMaybe<CmlThrowAwayCreateWithoutCmlThrowAwayRawMaterialItemsInput>;
};

export type CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayCmlItemsInput = {
  create: CmlThrowAwayCreateWithoutCmlThrowAwayCmlItemsInput;
  where: CmlThrowAwayWhereUniqueInput;
};

export type CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayPackagingItemsInput = {
  create: CmlThrowAwayCreateWithoutCmlThrowAwayPackagingItemsInput;
  where: CmlThrowAwayWhereUniqueInput;
};

export type CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayRawMaterialItemsInput = {
  create: CmlThrowAwayCreateWithoutCmlThrowAwayRawMaterialItemsInput;
  where: CmlThrowAwayWhereUniqueInput;
};

export type CmlThrowAwayCreateWithoutCmlThrowAwayCmlItemsInput = {
  checkedBy?: InputMaybe<Scalars['String']['input']>;
  cmlThrowAwayLocation: CmlThrowAwayLocation;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutCmlThrowAwayInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutCmlThrowAwayInput>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
};

export type CmlThrowAwayCreateWithoutCmlThrowAwayPackagingItemsInput = {
  checkedBy?: InputMaybe<Scalars['String']['input']>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlThrowAwayInput>;
  cmlThrowAwayLocation: CmlThrowAwayLocation;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutCmlThrowAwayInput>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
};

export type CmlThrowAwayCreateWithoutCmlThrowAwayRawMaterialItemsInput = {
  checkedBy?: InputMaybe<Scalars['String']['input']>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemCreateNestedManyWithoutCmlThrowAwayInput>;
  cmlThrowAwayLocation: CmlThrowAwayLocation;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutCmlThrowAwayInput>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
};

export { CmlThrowAwayLocation };

export type CmlThrowAwayMaxAggregate = {
  __typename?: 'CmlThrowAwayMaxAggregate';
  checkedBy?: Maybe<Scalars['String']['output']>;
  cmlThrowAwayLocation?: Maybe<CmlThrowAwayLocation>;
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CmlThrowAwayMinAggregate = {
  __typename?: 'CmlThrowAwayMinAggregate';
  checkedBy?: Maybe<Scalars['String']['output']>;
  cmlThrowAwayLocation?: Maybe<CmlThrowAwayLocation>;
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type CmlThrowAwayOrderByWithRelationInput = {
  checkedBy?: InputMaybe<SortOrder>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemOrderByRelationAggregateInput>;
  cmlThrowAwayLocation?: InputMaybe<SortOrder>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemOrderByRelationAggregateInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemOrderByRelationAggregateInput>;
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type CmlThrowAwayPackagingItem = {
  __typename?: 'CmlThrowAwayPackagingItem';
  checkedQuantity?: Maybe<Scalars['Int']['output']>;
  cmlThrowAway: CmlThrowAway;
  cmlThrowAwayId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type CmlThrowAwayPackagingItemCreateManyCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type CmlThrowAwayPackagingItemCreateManyCmlThrowAwayInputEnvelope = {
  data: Array<CmlThrowAwayPackagingItemCreateManyCmlThrowAwayInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlThrowAwayPackagingItemCreateManyPackagingProductInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  cmlThrowAwayId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type CmlThrowAwayPackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<CmlThrowAwayPackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlThrowAwayPackagingItemCreateNestedManyWithoutCmlThrowAwayInput = {
  connect?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayPackagingItemCreateOrConnectWithoutCmlThrowAwayInput>>;
  create?: InputMaybe<Array<CmlThrowAwayPackagingItemCreateWithoutCmlThrowAwayInput>>;
  createMany?: InputMaybe<CmlThrowAwayPackagingItemCreateManyCmlThrowAwayInputEnvelope>;
};

export type CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<CmlThrowAwayPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<CmlThrowAwayPackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type CmlThrowAwayPackagingItemCreateOrConnectWithoutCmlThrowAwayInput = {
  create: CmlThrowAwayPackagingItemCreateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayPackagingItemWhereUniqueInput;
};

export type CmlThrowAwayPackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: CmlThrowAwayPackagingItemCreateWithoutPackagingProductInput;
  where: CmlThrowAwayPackagingItemWhereUniqueInput;
};

export type CmlThrowAwayPackagingItemCreateWithoutCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  packagingProduct: PackagingProductCreateNestedOneWithoutCmlThrowAwayPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type CmlThrowAwayPackagingItemCreateWithoutPackagingProductInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  cmlThrowAway: CmlThrowAwayCreateNestedOneWithoutCmlThrowAwayPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type CmlThrowAwayPackagingItemListRelationFilter = {
  every?: InputMaybe<CmlThrowAwayPackagingItemWhereInput>;
  none?: InputMaybe<CmlThrowAwayPackagingItemWhereInput>;
  some?: InputMaybe<CmlThrowAwayPackagingItemWhereInput>;
};

export type CmlThrowAwayPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CmlThrowAwayPackagingItemOrderByWithRelationInput = {
  checkedQuantity?: InputMaybe<SortOrder>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayOrderByWithRelationInput>;
  cmlThrowAwayId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum CmlThrowAwayPackagingItemScalarFieldEnum {
  CheckedQuantity = 'checkedQuantity',
  CmlThrowAwayId = 'cmlThrowAwayId',
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity'
}

export type CmlThrowAwayPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<CmlThrowAwayPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CmlThrowAwayPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CmlThrowAwayPackagingItemScalarWhereInput>>;
  checkedQuantity?: InputMaybe<IntNullableFilter>;
  cmlThrowAwayId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type CmlThrowAwayPackagingItemUpdateManyMutationInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CmlThrowAwayPackagingItemUpdateManyWithWhereWithoutCmlThrowAwayInput = {
  data: CmlThrowAwayPackagingItemUpdateManyMutationInput;
  where: CmlThrowAwayPackagingItemScalarWhereInput;
};

export type CmlThrowAwayPackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: CmlThrowAwayPackagingItemUpdateManyMutationInput;
  where: CmlThrowAwayPackagingItemScalarWhereInput;
};

export type CmlThrowAwayPackagingItemUpdateManyWithoutCmlThrowAwayNestedInput = {
  connect?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayPackagingItemCreateOrConnectWithoutCmlThrowAwayInput>>;
  create?: InputMaybe<Array<CmlThrowAwayPackagingItemCreateWithoutCmlThrowAwayInput>>;
  createMany?: InputMaybe<CmlThrowAwayPackagingItemCreateManyCmlThrowAwayInputEnvelope>;
  delete?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlThrowAwayPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlThrowAwayPackagingItemUpdateWithWhereUniqueWithoutCmlThrowAwayInput>>;
  updateMany?: InputMaybe<Array<CmlThrowAwayPackagingItemUpdateManyWithWhereWithoutCmlThrowAwayInput>>;
  upsert?: InputMaybe<Array<CmlThrowAwayPackagingItemUpsertWithWhereUniqueWithoutCmlThrowAwayInput>>;
};

export type CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<CmlThrowAwayPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<CmlThrowAwayPackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlThrowAwayPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlThrowAwayPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<CmlThrowAwayPackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<CmlThrowAwayPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type CmlThrowAwayPackagingItemUpdateWithWhereUniqueWithoutCmlThrowAwayInput = {
  data: CmlThrowAwayPackagingItemUpdateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayPackagingItemWhereUniqueInput;
};

export type CmlThrowAwayPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: CmlThrowAwayPackagingItemUpdateWithoutPackagingProductInput;
  where: CmlThrowAwayPackagingItemWhereUniqueInput;
};

export type CmlThrowAwayPackagingItemUpdateWithoutCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutCmlThrowAwayPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CmlThrowAwayPackagingItemUpdateWithoutPackagingProductInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayUpdateOneRequiredWithoutCmlThrowAwayPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CmlThrowAwayPackagingItemUpsertWithWhereUniqueWithoutCmlThrowAwayInput = {
  create: CmlThrowAwayPackagingItemCreateWithoutCmlThrowAwayInput;
  update: CmlThrowAwayPackagingItemUpdateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayPackagingItemWhereUniqueInput;
};

export type CmlThrowAwayPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: CmlThrowAwayPackagingItemCreateWithoutPackagingProductInput;
  update: CmlThrowAwayPackagingItemUpdateWithoutPackagingProductInput;
  where: CmlThrowAwayPackagingItemWhereUniqueInput;
};

export type CmlThrowAwayPackagingItemWhereInput = {
  AND?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<CmlThrowAwayPackagingItemWhereInput>>;
  checkedQuantity?: InputMaybe<IntNullableFilter>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayRelationFilter>;
  cmlThrowAwayId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type CmlThrowAwayPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CmlThrowAwayRawMaterialItem = {
  __typename?: 'CmlThrowAwayRawMaterialItem';
  checkedQuantity?: Maybe<Scalars['Int']['output']>;
  cmlThrowAway: CmlThrowAway;
  cmlThrowAwayId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
};

export type CmlThrowAwayRawMaterialItemCreateManyCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type CmlThrowAwayRawMaterialItemCreateManyCmlThrowAwayInputEnvelope = {
  data: Array<CmlThrowAwayRawMaterialItemCreateManyCmlThrowAwayInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlThrowAwayRawMaterialItemCreateManyRawMaterialProductInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  cmlThrowAwayId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
};

export type CmlThrowAwayRawMaterialItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<CmlThrowAwayRawMaterialItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CmlThrowAwayRawMaterialItemCreateNestedManyWithoutCmlThrowAwayInput = {
  connect?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayRawMaterialItemCreateOrConnectWithoutCmlThrowAwayInput>>;
  create?: InputMaybe<Array<CmlThrowAwayRawMaterialItemCreateWithoutCmlThrowAwayInput>>;
  createMany?: InputMaybe<CmlThrowAwayRawMaterialItemCreateManyCmlThrowAwayInputEnvelope>;
};

export type CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<CmlThrowAwayRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<CmlThrowAwayRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
};

export type CmlThrowAwayRawMaterialItemCreateOrConnectWithoutCmlThrowAwayInput = {
  create: CmlThrowAwayRawMaterialItemCreateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayRawMaterialItemWhereUniqueInput;
};

export type CmlThrowAwayRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: CmlThrowAwayRawMaterialItemCreateWithoutRawMaterialProductInput;
  where: CmlThrowAwayRawMaterialItemWhereUniqueInput;
};

export type CmlThrowAwayRawMaterialItemCreateWithoutCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutCmlThrowAwayRawMaterialItemsInput;
};

export type CmlThrowAwayRawMaterialItemCreateWithoutRawMaterialProductInput = {
  checkedQuantity?: InputMaybe<Scalars['Int']['input']>;
  cmlThrowAway: CmlThrowAwayCreateNestedOneWithoutCmlThrowAwayRawMaterialItemsInput;
  quantity: Scalars['Float']['input'];
};

export type CmlThrowAwayRawMaterialItemListRelationFilter = {
  every?: InputMaybe<CmlThrowAwayRawMaterialItemWhereInput>;
  none?: InputMaybe<CmlThrowAwayRawMaterialItemWhereInput>;
  some?: InputMaybe<CmlThrowAwayRawMaterialItemWhereInput>;
};

export type CmlThrowAwayRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CmlThrowAwayRawMaterialItemOrderByWithRelationInput = {
  checkedQuantity?: InputMaybe<SortOrder>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayOrderByWithRelationInput>;
  cmlThrowAwayId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
};

export enum CmlThrowAwayRawMaterialItemScalarFieldEnum {
  CheckedQuantity = 'checkedQuantity',
  CmlThrowAwayId = 'cmlThrowAwayId',
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId'
}

export type CmlThrowAwayRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<CmlThrowAwayRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CmlThrowAwayRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CmlThrowAwayRawMaterialItemScalarWhereInput>>;
  checkedQuantity?: InputMaybe<IntNullableFilter>;
  cmlThrowAwayId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type CmlThrowAwayRawMaterialItemUpdateManyMutationInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CmlThrowAwayRawMaterialItemUpdateManyWithWhereWithoutCmlThrowAwayInput = {
  data: CmlThrowAwayRawMaterialItemUpdateManyMutationInput;
  where: CmlThrowAwayRawMaterialItemScalarWhereInput;
};

export type CmlThrowAwayRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: CmlThrowAwayRawMaterialItemUpdateManyMutationInput;
  where: CmlThrowAwayRawMaterialItemScalarWhereInput;
};

export type CmlThrowAwayRawMaterialItemUpdateManyWithoutCmlThrowAwayNestedInput = {
  connect?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayRawMaterialItemCreateOrConnectWithoutCmlThrowAwayInput>>;
  create?: InputMaybe<Array<CmlThrowAwayRawMaterialItemCreateWithoutCmlThrowAwayInput>>;
  createMany?: InputMaybe<CmlThrowAwayRawMaterialItemCreateManyCmlThrowAwayInputEnvelope>;
  delete?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlThrowAwayRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlThrowAwayRawMaterialItemUpdateWithWhereUniqueWithoutCmlThrowAwayInput>>;
  updateMany?: InputMaybe<Array<CmlThrowAwayRawMaterialItemUpdateManyWithWhereWithoutCmlThrowAwayInput>>;
  upsert?: InputMaybe<Array<CmlThrowAwayRawMaterialItemUpsertWithWhereUniqueWithoutCmlThrowAwayInput>>;
};

export type CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CmlThrowAwayRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<CmlThrowAwayRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<CmlThrowAwayRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CmlThrowAwayRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CmlThrowAwayRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<CmlThrowAwayRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<CmlThrowAwayRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type CmlThrowAwayRawMaterialItemUpdateWithWhereUniqueWithoutCmlThrowAwayInput = {
  data: CmlThrowAwayRawMaterialItemUpdateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayRawMaterialItemWhereUniqueInput;
};

export type CmlThrowAwayRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: CmlThrowAwayRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: CmlThrowAwayRawMaterialItemWhereUniqueInput;
};

export type CmlThrowAwayRawMaterialItemUpdateWithoutCmlThrowAwayInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutCmlThrowAwayRawMaterialItemsNestedInput>;
};

export type CmlThrowAwayRawMaterialItemUpdateWithoutRawMaterialProductInput = {
  checkedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayUpdateOneRequiredWithoutCmlThrowAwayRawMaterialItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CmlThrowAwayRawMaterialItemUpsertWithWhereUniqueWithoutCmlThrowAwayInput = {
  create: CmlThrowAwayRawMaterialItemCreateWithoutCmlThrowAwayInput;
  update: CmlThrowAwayRawMaterialItemUpdateWithoutCmlThrowAwayInput;
  where: CmlThrowAwayRawMaterialItemWhereUniqueInput;
};

export type CmlThrowAwayRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: CmlThrowAwayRawMaterialItemCreateWithoutRawMaterialProductInput;
  update: CmlThrowAwayRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: CmlThrowAwayRawMaterialItemWhereUniqueInput;
};

export type CmlThrowAwayRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<CmlThrowAwayRawMaterialItemWhereInput>>;
  checkedQuantity?: InputMaybe<IntNullableFilter>;
  cmlThrowAway?: InputMaybe<CmlThrowAwayRelationFilter>;
  cmlThrowAwayId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type CmlThrowAwayRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CmlThrowAwayRelationFilter = {
  is?: InputMaybe<CmlThrowAwayWhereInput>;
  isNot?: InputMaybe<CmlThrowAwayWhereInput>;
};

export enum CmlThrowAwayScalarFieldEnum {
  CheckedBy = 'checkedBy',
  CmlThrowAwayLocation = 'cmlThrowAwayLocation',
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Id = 'id'
}

export type CmlThrowAwaySumAggregate = {
  __typename?: 'CmlThrowAwaySumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type CmlThrowAwayUpdateInput = {
  checkedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  cmlThrowAwayLocation?: InputMaybe<EnumCmlThrowAwayLocationFieldUpdateOperationsInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
};

export type CmlThrowAwayUpdateOneRequiredWithoutCmlThrowAwayCmlItemsNestedInput = {
  connect?: InputMaybe<CmlThrowAwayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayCmlItemsInput>;
  create?: InputMaybe<CmlThrowAwayCreateWithoutCmlThrowAwayCmlItemsInput>;
  update?: InputMaybe<CmlThrowAwayUpdateWithoutCmlThrowAwayCmlItemsInput>;
  upsert?: InputMaybe<CmlThrowAwayUpsertWithoutCmlThrowAwayCmlItemsInput>;
};

export type CmlThrowAwayUpdateOneRequiredWithoutCmlThrowAwayPackagingItemsNestedInput = {
  connect?: InputMaybe<CmlThrowAwayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayPackagingItemsInput>;
  create?: InputMaybe<CmlThrowAwayCreateWithoutCmlThrowAwayPackagingItemsInput>;
  update?: InputMaybe<CmlThrowAwayUpdateWithoutCmlThrowAwayPackagingItemsInput>;
  upsert?: InputMaybe<CmlThrowAwayUpsertWithoutCmlThrowAwayPackagingItemsInput>;
};

export type CmlThrowAwayUpdateOneRequiredWithoutCmlThrowAwayRawMaterialItemsNestedInput = {
  connect?: InputMaybe<CmlThrowAwayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CmlThrowAwayCreateOrConnectWithoutCmlThrowAwayRawMaterialItemsInput>;
  create?: InputMaybe<CmlThrowAwayCreateWithoutCmlThrowAwayRawMaterialItemsInput>;
  update?: InputMaybe<CmlThrowAwayUpdateWithoutCmlThrowAwayRawMaterialItemsInput>;
  upsert?: InputMaybe<CmlThrowAwayUpsertWithoutCmlThrowAwayRawMaterialItemsInput>;
};

export type CmlThrowAwayUpdateWithoutCmlThrowAwayCmlItemsInput = {
  checkedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cmlThrowAwayLocation?: InputMaybe<EnumCmlThrowAwayLocationFieldUpdateOperationsInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
};

export type CmlThrowAwayUpdateWithoutCmlThrowAwayPackagingItemsInput = {
  checkedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  cmlThrowAwayLocation?: InputMaybe<EnumCmlThrowAwayLocationFieldUpdateOperationsInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
};

export type CmlThrowAwayUpdateWithoutCmlThrowAwayRawMaterialItemsInput = {
  checkedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  cmlThrowAwayLocation?: InputMaybe<EnumCmlThrowAwayLocationFieldUpdateOperationsInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutCmlThrowAwayNestedInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
};

export type CmlThrowAwayUpsertWithoutCmlThrowAwayCmlItemsInput = {
  create: CmlThrowAwayCreateWithoutCmlThrowAwayCmlItemsInput;
  update: CmlThrowAwayUpdateWithoutCmlThrowAwayCmlItemsInput;
};

export type CmlThrowAwayUpsertWithoutCmlThrowAwayPackagingItemsInput = {
  create: CmlThrowAwayCreateWithoutCmlThrowAwayPackagingItemsInput;
  update: CmlThrowAwayUpdateWithoutCmlThrowAwayPackagingItemsInput;
};

export type CmlThrowAwayUpsertWithoutCmlThrowAwayRawMaterialItemsInput = {
  create: CmlThrowAwayCreateWithoutCmlThrowAwayRawMaterialItemsInput;
  update: CmlThrowAwayUpdateWithoutCmlThrowAwayRawMaterialItemsInput;
};

export type CmlThrowAwayWhereInput = {
  AND?: InputMaybe<Array<CmlThrowAwayWhereInput>>;
  NOT?: InputMaybe<Array<CmlThrowAwayWhereInput>>;
  OR?: InputMaybe<Array<CmlThrowAwayWhereInput>>;
  checkedBy?: InputMaybe<StringNullableFilter>;
  cmlThrowAwayCmlItems?: InputMaybe<CmlThrowAwayCmlItemListRelationFilter>;
  cmlThrowAwayLocation?: InputMaybe<EnumCmlThrowAwayLocationFilter>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemListRelationFilter>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemListRelationFilter>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
};

export type CmlThrowAwayWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ConsolidatedThrowAwayReportItemWhereInput = {
  location?: InputMaybe<Scalars['String']['input']>;
  monthYear?: InputMaybe<Scalars['DateTime']['input']>;
  outletId?: InputMaybe<Scalars['Float']['input']>;
};

export type Crt = {
  __typename?: 'Crt';
  _count?: Maybe<CrtCount>;
  crtBeverageItems: Array<CrtBeverageItem>;
  crtCmlItems: Array<CrtCmlItem>;
  crtPackagingItems: Array<CrtPackagingItem>;
  crtRawMaterialItems: Array<CrtRawMaterialItem>;
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id: Scalars['Int']['output'];
  outlet: Outlet;
  outletId: Scalars['Int']['output'];
};


export type CrtCrtBeverageItemsArgs = {
  cursor?: InputMaybe<CrtBeverageItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtBeverageItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtBeverageItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtBeverageItemWhereInput>;
};


export type CrtCrtCmlItemsArgs = {
  cursor?: InputMaybe<CrtCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtCmlItemWhereInput>;
};


export type CrtCrtPackagingItemsArgs = {
  cursor?: InputMaybe<CrtPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtPackagingItemWhereInput>;
};


export type CrtCrtRawMaterialItemsArgs = {
  cursor?: InputMaybe<CrtRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtRawMaterialItemWhereInput>;
};

export type CrtAvgAggregate = {
  __typename?: 'CrtAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
};

export type CrtBeverageItem = {
  __typename?: 'CrtBeverageItem';
  beverage: Beverage;
  beverageId: Scalars['Int']['output'];
  crt: Crt;
  crtId: Scalars['Int']['output'];
  cupsSoldLarge: Scalars['Int']['output'];
  cupsSoldMedium: Scalars['Int']['output'];
  cupsSoldXl: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type CrtBeverageItemCreateManyBeverageInput = {
  crtId: Scalars['Int']['input'];
  cupsSoldLarge?: InputMaybe<Scalars['Int']['input']>;
  cupsSoldMedium?: InputMaybe<Scalars['Int']['input']>;
  cupsSoldXl?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CrtBeverageItemCreateManyBeverageInputEnvelope = {
  data: Array<CrtBeverageItemCreateManyBeverageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtBeverageItemCreateManyCrtInput = {
  beverageId: Scalars['Int']['input'];
  cupsSoldLarge?: InputMaybe<Scalars['Int']['input']>;
  cupsSoldMedium?: InputMaybe<Scalars['Int']['input']>;
  cupsSoldXl?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CrtBeverageItemCreateManyCrtInputEnvelope = {
  data: Array<CrtBeverageItemCreateManyCrtInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtBeverageItemCreateNestedManyWithoutBeverageInput = {
  connect?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtBeverageItemCreateOrConnectWithoutBeverageInput>>;
  create?: InputMaybe<Array<CrtBeverageItemCreateWithoutBeverageInput>>;
  createMany?: InputMaybe<CrtBeverageItemCreateManyBeverageInputEnvelope>;
};

export type CrtBeverageItemCreateNestedManyWithoutCrtInput = {
  connect?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtBeverageItemCreateOrConnectWithoutCrtInput>>;
  create?: InputMaybe<Array<CrtBeverageItemCreateWithoutCrtInput>>;
  createMany?: InputMaybe<CrtBeverageItemCreateManyCrtInputEnvelope>;
};

export type CrtBeverageItemCreateOrConnectWithoutBeverageInput = {
  create: CrtBeverageItemCreateWithoutBeverageInput;
  where: CrtBeverageItemWhereUniqueInput;
};

export type CrtBeverageItemCreateOrConnectWithoutCrtInput = {
  create: CrtBeverageItemCreateWithoutCrtInput;
  where: CrtBeverageItemWhereUniqueInput;
};

export type CrtBeverageItemCreateWithoutBeverageInput = {
  crt: CrtCreateNestedOneWithoutCrtBeverageItemsInput;
  cupsSoldLarge?: InputMaybe<Scalars['Int']['input']>;
  cupsSoldMedium?: InputMaybe<Scalars['Int']['input']>;
  cupsSoldXl?: InputMaybe<Scalars['Int']['input']>;
};

export type CrtBeverageItemCreateWithoutCrtInput = {
  beverage: BeverageCreateNestedOneWithoutCrtBeverageItemsInput;
  cupsSoldLarge?: InputMaybe<Scalars['Int']['input']>;
  cupsSoldMedium?: InputMaybe<Scalars['Int']['input']>;
  cupsSoldXl?: InputMaybe<Scalars['Int']['input']>;
};

export type CrtBeverageItemListRelationFilter = {
  every?: InputMaybe<CrtBeverageItemWhereInput>;
  none?: InputMaybe<CrtBeverageItemWhereInput>;
  some?: InputMaybe<CrtBeverageItemWhereInput>;
};

export type CrtBeverageItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CrtBeverageItemOrderByWithRelationInput = {
  beverage?: InputMaybe<BeverageOrderByWithRelationInput>;
  beverageId?: InputMaybe<SortOrder>;
  crt?: InputMaybe<CrtOrderByWithRelationInput>;
  crtId?: InputMaybe<SortOrder>;
  cupsSoldLarge?: InputMaybe<SortOrder>;
  cupsSoldMedium?: InputMaybe<SortOrder>;
  cupsSoldXl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export enum CrtBeverageItemScalarFieldEnum {
  BeverageId = 'beverageId',
  CrtId = 'crtId',
  CupsSoldLarge = 'cupsSoldLarge',
  CupsSoldMedium = 'cupsSoldMedium',
  CupsSoldXl = 'cupsSoldXl',
  Id = 'id'
}

export type CrtBeverageItemScalarWhereInput = {
  AND?: InputMaybe<Array<CrtBeverageItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CrtBeverageItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CrtBeverageItemScalarWhereInput>>;
  beverageId?: InputMaybe<IntFilter>;
  crtId?: InputMaybe<IntFilter>;
  cupsSoldLarge?: InputMaybe<IntFilter>;
  cupsSoldMedium?: InputMaybe<IntFilter>;
  cupsSoldXl?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type CrtBeverageItemUpdateManyMutationInput = {
  cupsSoldLarge?: InputMaybe<IntFieldUpdateOperationsInput>;
  cupsSoldMedium?: InputMaybe<IntFieldUpdateOperationsInput>;
  cupsSoldXl?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CrtBeverageItemUpdateManyWithWhereWithoutBeverageInput = {
  data: CrtBeverageItemUpdateManyMutationInput;
  where: CrtBeverageItemScalarWhereInput;
};

export type CrtBeverageItemUpdateManyWithWhereWithoutCrtInput = {
  data: CrtBeverageItemUpdateManyMutationInput;
  where: CrtBeverageItemScalarWhereInput;
};

export type CrtBeverageItemUpdateManyWithoutBeverageNestedInput = {
  connect?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtBeverageItemCreateOrConnectWithoutBeverageInput>>;
  create?: InputMaybe<Array<CrtBeverageItemCreateWithoutBeverageInput>>;
  createMany?: InputMaybe<CrtBeverageItemCreateManyBeverageInputEnvelope>;
  delete?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CrtBeverageItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CrtBeverageItemUpdateWithWhereUniqueWithoutBeverageInput>>;
  updateMany?: InputMaybe<Array<CrtBeverageItemUpdateManyWithWhereWithoutBeverageInput>>;
  upsert?: InputMaybe<Array<CrtBeverageItemUpsertWithWhereUniqueWithoutBeverageInput>>;
};

export type CrtBeverageItemUpdateManyWithoutCrtNestedInput = {
  connect?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtBeverageItemCreateOrConnectWithoutCrtInput>>;
  create?: InputMaybe<Array<CrtBeverageItemCreateWithoutCrtInput>>;
  createMany?: InputMaybe<CrtBeverageItemCreateManyCrtInputEnvelope>;
  delete?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CrtBeverageItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CrtBeverageItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CrtBeverageItemUpdateWithWhereUniqueWithoutCrtInput>>;
  updateMany?: InputMaybe<Array<CrtBeverageItemUpdateManyWithWhereWithoutCrtInput>>;
  upsert?: InputMaybe<Array<CrtBeverageItemUpsertWithWhereUniqueWithoutCrtInput>>;
};

export type CrtBeverageItemUpdateWithWhereUniqueWithoutBeverageInput = {
  data: CrtBeverageItemUpdateWithoutBeverageInput;
  where: CrtBeverageItemWhereUniqueInput;
};

export type CrtBeverageItemUpdateWithWhereUniqueWithoutCrtInput = {
  data: CrtBeverageItemUpdateWithoutCrtInput;
  where: CrtBeverageItemWhereUniqueInput;
};

export type CrtBeverageItemUpdateWithoutBeverageInput = {
  crt?: InputMaybe<CrtUpdateOneRequiredWithoutCrtBeverageItemsNestedInput>;
  cupsSoldLarge?: InputMaybe<IntFieldUpdateOperationsInput>;
  cupsSoldMedium?: InputMaybe<IntFieldUpdateOperationsInput>;
  cupsSoldXl?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CrtBeverageItemUpdateWithoutCrtInput = {
  beverage?: InputMaybe<BeverageUpdateOneRequiredWithoutCrtBeverageItemsNestedInput>;
  cupsSoldLarge?: InputMaybe<IntFieldUpdateOperationsInput>;
  cupsSoldMedium?: InputMaybe<IntFieldUpdateOperationsInput>;
  cupsSoldXl?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CrtBeverageItemUpsertWithWhereUniqueWithoutBeverageInput = {
  create: CrtBeverageItemCreateWithoutBeverageInput;
  update: CrtBeverageItemUpdateWithoutBeverageInput;
  where: CrtBeverageItemWhereUniqueInput;
};

export type CrtBeverageItemUpsertWithWhereUniqueWithoutCrtInput = {
  create: CrtBeverageItemCreateWithoutCrtInput;
  update: CrtBeverageItemUpdateWithoutCrtInput;
  where: CrtBeverageItemWhereUniqueInput;
};

export type CrtBeverageItemWhereInput = {
  AND?: InputMaybe<Array<CrtBeverageItemWhereInput>>;
  NOT?: InputMaybe<Array<CrtBeverageItemWhereInput>>;
  OR?: InputMaybe<Array<CrtBeverageItemWhereInput>>;
  beverage?: InputMaybe<BeverageRelationFilter>;
  beverageId?: InputMaybe<IntFilter>;
  crt?: InputMaybe<CrtRelationFilter>;
  crtId?: InputMaybe<IntFilter>;
  cupsSoldLarge?: InputMaybe<IntFilter>;
  cupsSoldMedium?: InputMaybe<IntFilter>;
  cupsSoldXl?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type CrtBeverageItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CrtCmlItem = {
  __typename?: 'CrtCmlItem';
  crt: Crt;
  crtId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  salesCategory: SalesCategory;
};

export type CrtCmlItemCreateManyCrtInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  salesCategory: SalesCategory;
};

export type CrtCmlItemCreateManyCrtInputEnvelope = {
  data: Array<CrtCmlItemCreateManyCrtInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtCmlItemCreateNestedManyWithoutCrtInput = {
  connect?: InputMaybe<Array<CrtCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtCmlItemCreateOrConnectWithoutCrtInput>>;
  create?: InputMaybe<Array<CrtCmlItemCreateWithoutCrtInput>>;
  createMany?: InputMaybe<CrtCmlItemCreateManyCrtInputEnvelope>;
};

export type CrtCmlItemCreateOrConnectWithoutCrtInput = {
  create: CrtCmlItemCreateWithoutCrtInput;
  where: CrtCmlItemWhereUniqueInput;
};

export type CrtCmlItemCreateWithoutCrtInput = {
  quantity: Scalars['Int']['input'];
  salesCategory: SalesCategory;
};

export type CrtCmlItemListRelationFilter = {
  every?: InputMaybe<CrtCmlItemWhereInput>;
  none?: InputMaybe<CrtCmlItemWhereInput>;
  some?: InputMaybe<CrtCmlItemWhereInput>;
};

export type CrtCmlItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CrtCmlItemOrderByWithRelationInput = {
  crt?: InputMaybe<CrtOrderByWithRelationInput>;
  crtId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  salesCategory?: InputMaybe<SortOrder>;
};

export type CrtCmlItemSalesCategoryCrtIdCompoundUniqueInput = {
  crtId: Scalars['Int']['input'];
  salesCategory: SalesCategory;
};

export enum CrtCmlItemScalarFieldEnum {
  CrtId = 'crtId',
  Id = 'id',
  Quantity = 'quantity',
  SalesCategory = 'salesCategory'
}

export type CrtCmlItemScalarWhereInput = {
  AND?: InputMaybe<Array<CrtCmlItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CrtCmlItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CrtCmlItemScalarWhereInput>>;
  crtId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  salesCategory?: InputMaybe<EnumSalesCategoryFilter>;
};

export type CrtCmlItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
};

export type CrtCmlItemUpdateManyWithWhereWithoutCrtInput = {
  data: CrtCmlItemUpdateManyMutationInput;
  where: CrtCmlItemScalarWhereInput;
};

export type CrtCmlItemUpdateManyWithoutCrtNestedInput = {
  connect?: InputMaybe<Array<CrtCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtCmlItemCreateOrConnectWithoutCrtInput>>;
  create?: InputMaybe<Array<CrtCmlItemCreateWithoutCrtInput>>;
  createMany?: InputMaybe<CrtCmlItemCreateManyCrtInputEnvelope>;
  delete?: InputMaybe<Array<CrtCmlItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CrtCmlItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CrtCmlItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CrtCmlItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CrtCmlItemUpdateWithWhereUniqueWithoutCrtInput>>;
  updateMany?: InputMaybe<Array<CrtCmlItemUpdateManyWithWhereWithoutCrtInput>>;
  upsert?: InputMaybe<Array<CrtCmlItemUpsertWithWhereUniqueWithoutCrtInput>>;
};

export type CrtCmlItemUpdateWithWhereUniqueWithoutCrtInput = {
  data: CrtCmlItemUpdateWithoutCrtInput;
  where: CrtCmlItemWhereUniqueInput;
};

export type CrtCmlItemUpdateWithoutCrtInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  salesCategory?: InputMaybe<EnumSalesCategoryFieldUpdateOperationsInput>;
};

export type CrtCmlItemUpsertWithWhereUniqueWithoutCrtInput = {
  create: CrtCmlItemCreateWithoutCrtInput;
  update: CrtCmlItemUpdateWithoutCrtInput;
  where: CrtCmlItemWhereUniqueInput;
};

export type CrtCmlItemWhereInput = {
  AND?: InputMaybe<Array<CrtCmlItemWhereInput>>;
  NOT?: InputMaybe<Array<CrtCmlItemWhereInput>>;
  OR?: InputMaybe<Array<CrtCmlItemWhereInput>>;
  crt?: InputMaybe<CrtRelationFilter>;
  crtId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  salesCategory?: InputMaybe<EnumSalesCategoryFilter>;
};

export type CrtCmlItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  salesCategory_crtId?: InputMaybe<CrtCmlItemSalesCategoryCrtIdCompoundUniqueInput>;
};

export type CrtCount = {
  __typename?: 'CrtCount';
  crtBeverageItems: Scalars['Int']['output'];
  crtCmlItems: Scalars['Int']['output'];
  crtPackagingItems: Scalars['Int']['output'];
  crtRawMaterialItems: Scalars['Int']['output'];
};

export type CrtCountAggregate = {
  __typename?: 'CrtCountAggregate';
  _all: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
};

export type CrtCreateInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemCreateNestedManyWithoutCrtInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemCreateNestedManyWithoutCrtInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutCrtInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutCrtInput>;
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutCrtsInput;
};

export type CrtCreateManyOutletInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CrtCreateManyOutletInputEnvelope = {
  data: Array<CrtCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<CrtWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<CrtCreateWithoutOutletInput>>;
  createMany?: InputMaybe<CrtCreateManyOutletInputEnvelope>;
};

export type CrtCreateNestedOneWithoutCrtBeverageItemsInput = {
  connect?: InputMaybe<CrtWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CrtCreateOrConnectWithoutCrtBeverageItemsInput>;
  create?: InputMaybe<CrtCreateWithoutCrtBeverageItemsInput>;
};

export type CrtCreateNestedOneWithoutCrtPackagingItemsInput = {
  connect?: InputMaybe<CrtWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CrtCreateOrConnectWithoutCrtPackagingItemsInput>;
  create?: InputMaybe<CrtCreateWithoutCrtPackagingItemsInput>;
};

export type CrtCreateNestedOneWithoutCrtRawMaterialItemsInput = {
  connect?: InputMaybe<CrtWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CrtCreateOrConnectWithoutCrtRawMaterialItemsInput>;
  create?: InputMaybe<CrtCreateWithoutCrtRawMaterialItemsInput>;
};

export type CrtCreateOrConnectWithoutCrtBeverageItemsInput = {
  create: CrtCreateWithoutCrtBeverageItemsInput;
  where: CrtWhereUniqueInput;
};

export type CrtCreateOrConnectWithoutCrtPackagingItemsInput = {
  create: CrtCreateWithoutCrtPackagingItemsInput;
  where: CrtWhereUniqueInput;
};

export type CrtCreateOrConnectWithoutCrtRawMaterialItemsInput = {
  create: CrtCreateWithoutCrtRawMaterialItemsInput;
  where: CrtWhereUniqueInput;
};

export type CrtCreateOrConnectWithoutOutletInput = {
  create: CrtCreateWithoutOutletInput;
  where: CrtWhereUniqueInput;
};

export type CrtCreateWithoutCrtBeverageItemsInput = {
  crtCmlItems?: InputMaybe<CrtCmlItemCreateNestedManyWithoutCrtInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutCrtInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutCrtInput>;
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutCrtsInput;
};

export type CrtCreateWithoutCrtPackagingItemsInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemCreateNestedManyWithoutCrtInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemCreateNestedManyWithoutCrtInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutCrtInput>;
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutCrtsInput;
};

export type CrtCreateWithoutCrtRawMaterialItemsInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemCreateNestedManyWithoutCrtInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemCreateNestedManyWithoutCrtInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutCrtInput>;
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutCrtsInput;
};

export type CrtCreateWithoutOutletInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemCreateNestedManyWithoutCrtInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemCreateNestedManyWithoutCrtInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutCrtInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutCrtInput>;
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
};

export type CrtDeliveryShiftScheduleOutletIdDateCompoundUniqueInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outletId: Scalars['Int']['input'];
};

export type CrtListRelationFilter = {
  every?: InputMaybe<CrtWhereInput>;
  none?: InputMaybe<CrtWhereInput>;
  some?: InputMaybe<CrtWhereInput>;
};

export type CrtMaxAggregate = {
  __typename?: 'CrtMaxAggregate';
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type CrtMinAggregate = {
  __typename?: 'CrtMinAggregate';
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type CrtOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CrtOrderByWithRelationInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemOrderByRelationAggregateInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemOrderByRelationAggregateInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemOrderByRelationAggregateInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemOrderByRelationAggregateInput>;
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
};

export type CrtPackagingItem = {
  __typename?: 'CrtPackagingItem';
  crt: Crt;
  crtId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type CrtPackagingItemCreateManyCrtInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type CrtPackagingItemCreateManyCrtInputEnvelope = {
  data: Array<CrtPackagingItemCreateManyCrtInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtPackagingItemCreateManyPackagingProductInput = {
  crtId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type CrtPackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<CrtPackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtPackagingItemCreateNestedManyWithoutCrtInput = {
  connect?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtPackagingItemCreateOrConnectWithoutCrtInput>>;
  create?: InputMaybe<Array<CrtPackagingItemCreateWithoutCrtInput>>;
  createMany?: InputMaybe<CrtPackagingItemCreateManyCrtInputEnvelope>;
};

export type CrtPackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<CrtPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<CrtPackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type CrtPackagingItemCreateOrConnectWithoutCrtInput = {
  create: CrtPackagingItemCreateWithoutCrtInput;
  where: CrtPackagingItemWhereUniqueInput;
};

export type CrtPackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: CrtPackagingItemCreateWithoutPackagingProductInput;
  where: CrtPackagingItemWhereUniqueInput;
};

export type CrtPackagingItemCreateWithoutCrtInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutCrtPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type CrtPackagingItemCreateWithoutPackagingProductInput = {
  crt: CrtCreateNestedOneWithoutCrtPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type CrtPackagingItemListRelationFilter = {
  every?: InputMaybe<CrtPackagingItemWhereInput>;
  none?: InputMaybe<CrtPackagingItemWhereInput>;
  some?: InputMaybe<CrtPackagingItemWhereInput>;
};

export type CrtPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CrtPackagingItemOrderByWithRelationInput = {
  crt?: InputMaybe<CrtOrderByWithRelationInput>;
  crtId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum CrtPackagingItemScalarFieldEnum {
  CrtId = 'crtId',
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity'
}

export type CrtPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<CrtPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CrtPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CrtPackagingItemScalarWhereInput>>;
  crtId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type CrtPackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CrtPackagingItemUpdateManyWithWhereWithoutCrtInput = {
  data: CrtPackagingItemUpdateManyMutationInput;
  where: CrtPackagingItemScalarWhereInput;
};

export type CrtPackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: CrtPackagingItemUpdateManyMutationInput;
  where: CrtPackagingItemScalarWhereInput;
};

export type CrtPackagingItemUpdateManyWithoutCrtNestedInput = {
  connect?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtPackagingItemCreateOrConnectWithoutCrtInput>>;
  create?: InputMaybe<Array<CrtPackagingItemCreateWithoutCrtInput>>;
  createMany?: InputMaybe<CrtPackagingItemCreateManyCrtInputEnvelope>;
  delete?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CrtPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CrtPackagingItemUpdateWithWhereUniqueWithoutCrtInput>>;
  updateMany?: InputMaybe<Array<CrtPackagingItemUpdateManyWithWhereWithoutCrtInput>>;
  upsert?: InputMaybe<Array<CrtPackagingItemUpsertWithWhereUniqueWithoutCrtInput>>;
};

export type CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<CrtPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<CrtPackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CrtPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CrtPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CrtPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<CrtPackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<CrtPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type CrtPackagingItemUpdateWithWhereUniqueWithoutCrtInput = {
  data: CrtPackagingItemUpdateWithoutCrtInput;
  where: CrtPackagingItemWhereUniqueInput;
};

export type CrtPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: CrtPackagingItemUpdateWithoutPackagingProductInput;
  where: CrtPackagingItemWhereUniqueInput;
};

export type CrtPackagingItemUpdateWithoutCrtInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutCrtPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CrtPackagingItemUpdateWithoutPackagingProductInput = {
  crt?: InputMaybe<CrtUpdateOneRequiredWithoutCrtPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CrtPackagingItemUpsertWithWhereUniqueWithoutCrtInput = {
  create: CrtPackagingItemCreateWithoutCrtInput;
  update: CrtPackagingItemUpdateWithoutCrtInput;
  where: CrtPackagingItemWhereUniqueInput;
};

export type CrtPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: CrtPackagingItemCreateWithoutPackagingProductInput;
  update: CrtPackagingItemUpdateWithoutPackagingProductInput;
  where: CrtPackagingItemWhereUniqueInput;
};

export type CrtPackagingItemWhereInput = {
  AND?: InputMaybe<Array<CrtPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<CrtPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<CrtPackagingItemWhereInput>>;
  crt?: InputMaybe<CrtRelationFilter>;
  crtId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type CrtPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CrtRawMaterialItem = {
  __typename?: 'CrtRawMaterialItem';
  crt: Crt;
  crtId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
};

export type CrtRawMaterialItemCreateManyCrtInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type CrtRawMaterialItemCreateManyCrtInputEnvelope = {
  data: Array<CrtRawMaterialItemCreateManyCrtInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtRawMaterialItemCreateManyRawMaterialProductInput = {
  crtId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
};

export type CrtRawMaterialItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<CrtRawMaterialItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtRawMaterialItemCreateNestedManyWithoutCrtInput = {
  connect?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtRawMaterialItemCreateOrConnectWithoutCrtInput>>;
  create?: InputMaybe<Array<CrtRawMaterialItemCreateWithoutCrtInput>>;
  createMany?: InputMaybe<CrtRawMaterialItemCreateManyCrtInputEnvelope>;
};

export type CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<CrtRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<CrtRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
};

export type CrtRawMaterialItemCreateOrConnectWithoutCrtInput = {
  create: CrtRawMaterialItemCreateWithoutCrtInput;
  where: CrtRawMaterialItemWhereUniqueInput;
};

export type CrtRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: CrtRawMaterialItemCreateWithoutRawMaterialProductInput;
  where: CrtRawMaterialItemWhereUniqueInput;
};

export type CrtRawMaterialItemCreateWithoutCrtInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutCrtRawMaterialItemsInput;
};

export type CrtRawMaterialItemCreateWithoutRawMaterialProductInput = {
  crt: CrtCreateNestedOneWithoutCrtRawMaterialItemsInput;
  quantity: Scalars['Float']['input'];
};

export type CrtRawMaterialItemListRelationFilter = {
  every?: InputMaybe<CrtRawMaterialItemWhereInput>;
  none?: InputMaybe<CrtRawMaterialItemWhereInput>;
  some?: InputMaybe<CrtRawMaterialItemWhereInput>;
};

export type CrtRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CrtRawMaterialItemOrderByWithRelationInput = {
  crt?: InputMaybe<CrtOrderByWithRelationInput>;
  crtId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
};

export enum CrtRawMaterialItemScalarFieldEnum {
  CrtId = 'crtId',
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId'
}

export type CrtRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<CrtRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CrtRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CrtRawMaterialItemScalarWhereInput>>;
  crtId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type CrtRawMaterialItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CrtRawMaterialItemUpdateManyWithWhereWithoutCrtInput = {
  data: CrtRawMaterialItemUpdateManyMutationInput;
  where: CrtRawMaterialItemScalarWhereInput;
};

export type CrtRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: CrtRawMaterialItemUpdateManyMutationInput;
  where: CrtRawMaterialItemScalarWhereInput;
};

export type CrtRawMaterialItemUpdateManyWithoutCrtNestedInput = {
  connect?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtRawMaterialItemCreateOrConnectWithoutCrtInput>>;
  create?: InputMaybe<Array<CrtRawMaterialItemCreateWithoutCrtInput>>;
  createMany?: InputMaybe<CrtRawMaterialItemCreateManyCrtInputEnvelope>;
  delete?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CrtRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CrtRawMaterialItemUpdateWithWhereUniqueWithoutCrtInput>>;
  updateMany?: InputMaybe<Array<CrtRawMaterialItemUpdateManyWithWhereWithoutCrtInput>>;
  upsert?: InputMaybe<Array<CrtRawMaterialItemUpsertWithWhereUniqueWithoutCrtInput>>;
};

export type CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<CrtRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<CrtRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CrtRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CrtRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CrtRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<CrtRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<CrtRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type CrtRawMaterialItemUpdateWithWhereUniqueWithoutCrtInput = {
  data: CrtRawMaterialItemUpdateWithoutCrtInput;
  where: CrtRawMaterialItemWhereUniqueInput;
};

export type CrtRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: CrtRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: CrtRawMaterialItemWhereUniqueInput;
};

export type CrtRawMaterialItemUpdateWithoutCrtInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutCrtRawMaterialItemsNestedInput>;
};

export type CrtRawMaterialItemUpdateWithoutRawMaterialProductInput = {
  crt?: InputMaybe<CrtUpdateOneRequiredWithoutCrtRawMaterialItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CrtRawMaterialItemUpsertWithWhereUniqueWithoutCrtInput = {
  create: CrtRawMaterialItemCreateWithoutCrtInput;
  update: CrtRawMaterialItemUpdateWithoutCrtInput;
  where: CrtRawMaterialItemWhereUniqueInput;
};

export type CrtRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: CrtRawMaterialItemCreateWithoutRawMaterialProductInput;
  update: CrtRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: CrtRawMaterialItemWhereUniqueInput;
};

export type CrtRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<CrtRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<CrtRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<CrtRawMaterialItemWhereInput>>;
  crt?: InputMaybe<CrtRelationFilter>;
  crtId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type CrtRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CrtRelationFilter = {
  is?: InputMaybe<CrtWhereInput>;
  isNot?: InputMaybe<CrtWhereInput>;
};

export enum CrtScalarFieldEnum {
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Id = 'id',
  OutletId = 'outletId'
}

export type CrtScalarWhereInput = {
  AND?: InputMaybe<Array<CrtScalarWhereInput>>;
  NOT?: InputMaybe<Array<CrtScalarWhereInput>>;
  OR?: InputMaybe<Array<CrtScalarWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outletId?: InputMaybe<IntFilter>;
};

export type CrtSumAggregate = {
  __typename?: 'CrtSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type CrtUpdateInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemUpdateManyWithoutCrtNestedInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemUpdateManyWithoutCrtNestedInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutCrtNestedInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutCrtNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutCrtsNestedInput>;
};

export type CrtUpdateManyMutationInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
};

export type CrtUpdateManyWithWhereWithoutOutletInput = {
  data: CrtUpdateManyMutationInput;
  where: CrtScalarWhereInput;
};

export type CrtUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<CrtWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CrtCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<CrtCreateWithoutOutletInput>>;
  createMany?: InputMaybe<CrtCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<CrtWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CrtScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CrtWhereUniqueInput>>;
  set?: InputMaybe<Array<CrtWhereUniqueInput>>;
  update?: InputMaybe<Array<CrtUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<CrtUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<CrtUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type CrtUpdateOneRequiredWithoutCrtBeverageItemsNestedInput = {
  connect?: InputMaybe<CrtWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CrtCreateOrConnectWithoutCrtBeverageItemsInput>;
  create?: InputMaybe<CrtCreateWithoutCrtBeverageItemsInput>;
  update?: InputMaybe<CrtUpdateWithoutCrtBeverageItemsInput>;
  upsert?: InputMaybe<CrtUpsertWithoutCrtBeverageItemsInput>;
};

export type CrtUpdateOneRequiredWithoutCrtPackagingItemsNestedInput = {
  connect?: InputMaybe<CrtWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CrtCreateOrConnectWithoutCrtPackagingItemsInput>;
  create?: InputMaybe<CrtCreateWithoutCrtPackagingItemsInput>;
  update?: InputMaybe<CrtUpdateWithoutCrtPackagingItemsInput>;
  upsert?: InputMaybe<CrtUpsertWithoutCrtPackagingItemsInput>;
};

export type CrtUpdateOneRequiredWithoutCrtRawMaterialItemsNestedInput = {
  connect?: InputMaybe<CrtWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CrtCreateOrConnectWithoutCrtRawMaterialItemsInput>;
  create?: InputMaybe<CrtCreateWithoutCrtRawMaterialItemsInput>;
  update?: InputMaybe<CrtUpdateWithoutCrtRawMaterialItemsInput>;
  upsert?: InputMaybe<CrtUpsertWithoutCrtRawMaterialItemsInput>;
};

export type CrtUpdateWithWhereUniqueWithoutOutletInput = {
  data: CrtUpdateWithoutOutletInput;
  where: CrtWhereUniqueInput;
};

export type CrtUpdateWithoutCrtBeverageItemsInput = {
  crtCmlItems?: InputMaybe<CrtCmlItemUpdateManyWithoutCrtNestedInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutCrtNestedInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutCrtNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutCrtsNestedInput>;
};

export type CrtUpdateWithoutCrtPackagingItemsInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemUpdateManyWithoutCrtNestedInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemUpdateManyWithoutCrtNestedInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutCrtNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutCrtsNestedInput>;
};

export type CrtUpdateWithoutCrtRawMaterialItemsInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemUpdateManyWithoutCrtNestedInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemUpdateManyWithoutCrtNestedInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutCrtNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutCrtsNestedInput>;
};

export type CrtUpdateWithoutOutletInput = {
  crtBeverageItems?: InputMaybe<CrtBeverageItemUpdateManyWithoutCrtNestedInput>;
  crtCmlItems?: InputMaybe<CrtCmlItemUpdateManyWithoutCrtNestedInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutCrtNestedInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutCrtNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
};

export type CrtUpsertWithWhereUniqueWithoutOutletInput = {
  create: CrtCreateWithoutOutletInput;
  update: CrtUpdateWithoutOutletInput;
  where: CrtWhereUniqueInput;
};

export type CrtUpsertWithoutCrtBeverageItemsInput = {
  create: CrtCreateWithoutCrtBeverageItemsInput;
  update: CrtUpdateWithoutCrtBeverageItemsInput;
};

export type CrtUpsertWithoutCrtPackagingItemsInput = {
  create: CrtCreateWithoutCrtPackagingItemsInput;
  update: CrtUpdateWithoutCrtPackagingItemsInput;
};

export type CrtUpsertWithoutCrtRawMaterialItemsInput = {
  create: CrtCreateWithoutCrtRawMaterialItemsInput;
  update: CrtUpdateWithoutCrtRawMaterialItemsInput;
};

export type CrtWhereInput = {
  AND?: InputMaybe<Array<CrtWhereInput>>;
  NOT?: InputMaybe<Array<CrtWhereInput>>;
  OR?: InputMaybe<Array<CrtWhereInput>>;
  crtBeverageItems?: InputMaybe<CrtBeverageItemListRelationFilter>;
  crtCmlItems?: InputMaybe<CrtCmlItemListRelationFilter>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemListRelationFilter>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemListRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
};

export type CrtWhereUniqueInput = {
  deliveryShiftSchedule_outletId_date?: InputMaybe<CrtDeliveryShiftScheduleOutletIdDateCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type DailyRawMaterialFinishingOutletVarianceReport = {
  __typename?: 'DailyRawMaterialFinishingOutletVarianceReport';
  actualEnding?: Maybe<Scalars['Float']['output']>;
  beginning?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  fromStoreRoom?: Maybe<Scalars['Float']['output']>;
  rawMaterial?: Maybe<Scalars['String']['output']>;
  shouldBe?: Maybe<Scalars['Float']['output']>;
  theoretical?: Maybe<Scalars['Float']['output']>;
  throwAway?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type DailyRawMaterialFinishingOutletVarianceReportWhereInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryShiftSchedule?: InputMaybe<DeliveryShiftSchedule>;
  outletId?: InputMaybe<Scalars['Float']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type DailyRawMaterialKitchenVarianceReport = {
  __typename?: 'DailyRawMaterialKitchenVarianceReport';
  actualEnding?: Maybe<Scalars['Float']['output']>;
  beginning?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  fromStoreRoom?: Maybe<Scalars['Float']['output']>;
  rawMaterial?: Maybe<Scalars['String']['output']>;
  shouldBe?: Maybe<Scalars['Float']['output']>;
  theoretical?: Maybe<Scalars['Float']['output']>;
  throwAway?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type DailyRawMaterialKitchenVarianceReportWhereInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryShiftSchedule?: InputMaybe<DeliveryShiftSchedule>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DeliveryReceipt = {
  __typename?: 'DeliveryReceipt';
  _count?: Maybe<DeliveryReceiptCount>;
  basketReleasedQuantity: Scalars['Int']['output'];
  cdmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  chmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  deliveryReceiptItems: Array<DeliveryReceiptItem>;
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id: Scalars['Int']['output'];
  outlet: Outlet;
  outletId: Scalars['Int']['output'];
  pymFryingTime?: Maybe<Scalars['DateTime']['output']>;
  receivedByDriver?: Maybe<Scalars['String']['output']>;
  receivedDeliveryReceipt?: Maybe<ReceivedDeliveryReceipt>;
  screenSeparatorReleasedQuantity: Scalars['Int']['output'];
  ydmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  yrcFryingTime?: Maybe<Scalars['DateTime']['output']>;
};


export type DeliveryReceiptDeliveryReceiptItemsArgs = {
  cursor?: InputMaybe<DeliveryReceiptItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryReceiptItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryReceiptItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptItemWhereInput>;
};

export type DeliveryReceiptAvgAggregate = {
  __typename?: 'DeliveryReceiptAvgAggregate';
  basketReleasedQuantity?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
  screenSeparatorReleasedQuantity?: Maybe<Scalars['Float']['output']>;
};

export type DeliveryReceiptCount = {
  __typename?: 'DeliveryReceiptCount';
  deliveryReceiptItems: Scalars['Int']['output'];
};

export type DeliveryReceiptCountAggregate = {
  __typename?: 'DeliveryReceiptCountAggregate';
  _all: Scalars['Int']['output'];
  basketReleasedQuantity: Scalars['Int']['output'];
  cdmFryingTime: Scalars['Int']['output'];
  chmFryingTime: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
  pymFryingTime: Scalars['Int']['output'];
  receivedByDriver: Scalars['Int']['output'];
  screenSeparatorReleasedQuantity: Scalars['Int']['output'];
  ydmFryingTime: Scalars['Int']['output'];
  yrcFryingTime: Scalars['Int']['output'];
};

export type DeliveryReceiptCreateInput = {
  basketReleasedQuantity: Scalars['Int']['input'];
  cdmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  chmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutDeliveryReceiptInput>;
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutDeliveryReceiptsInput;
  pymFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptCreateNestedOneWithoutDeliveryReceiptInput>;
  screenSeparatorReleasedQuantity: Scalars['Int']['input'];
  ydmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  yrcFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeliveryReceiptCreateManyOutletInput = {
  basketReleasedQuantity: Scalars['Int']['input'];
  cdmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  chmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id?: InputMaybe<Scalars['Int']['input']>;
  pymFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  screenSeparatorReleasedQuantity: Scalars['Int']['input'];
  ydmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  yrcFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeliveryReceiptCreateManyOutletInputEnvelope = {
  data: Array<DeliveryReceiptCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeliveryReceiptCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<DeliveryReceiptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeliveryReceiptCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<DeliveryReceiptCreateWithoutOutletInput>>;
  createMany?: InputMaybe<DeliveryReceiptCreateManyOutletInputEnvelope>;
};

export type DeliveryReceiptCreateNestedOneWithoutDeliveryReceiptItemsInput = {
  connect?: InputMaybe<DeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeliveryReceiptCreateOrConnectWithoutDeliveryReceiptItemsInput>;
  create?: InputMaybe<DeliveryReceiptCreateWithoutDeliveryReceiptItemsInput>;
};

export type DeliveryReceiptCreateNestedOneWithoutReceivedDeliveryReceiptInput = {
  connect?: InputMaybe<DeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeliveryReceiptCreateOrConnectWithoutReceivedDeliveryReceiptInput>;
  create?: InputMaybe<DeliveryReceiptCreateWithoutReceivedDeliveryReceiptInput>;
};

export type DeliveryReceiptCreateOrConnectWithoutDeliveryReceiptItemsInput = {
  create: DeliveryReceiptCreateWithoutDeliveryReceiptItemsInput;
  where: DeliveryReceiptWhereUniqueInput;
};

export type DeliveryReceiptCreateOrConnectWithoutOutletInput = {
  create: DeliveryReceiptCreateWithoutOutletInput;
  where: DeliveryReceiptWhereUniqueInput;
};

export type DeliveryReceiptCreateOrConnectWithoutReceivedDeliveryReceiptInput = {
  create: DeliveryReceiptCreateWithoutReceivedDeliveryReceiptInput;
  where: DeliveryReceiptWhereUniqueInput;
};

export type DeliveryReceiptCreateWithoutDeliveryReceiptItemsInput = {
  basketReleasedQuantity: Scalars['Int']['input'];
  cdmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  chmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutDeliveryReceiptsInput;
  pymFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptCreateNestedOneWithoutDeliveryReceiptInput>;
  screenSeparatorReleasedQuantity: Scalars['Int']['input'];
  ydmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  yrcFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeliveryReceiptCreateWithoutOutletInput = {
  basketReleasedQuantity: Scalars['Int']['input'];
  cdmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  chmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutDeliveryReceiptInput>;
  deliveryShiftSchedule: DeliveryShiftSchedule;
  pymFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptCreateNestedOneWithoutDeliveryReceiptInput>;
  screenSeparatorReleasedQuantity: Scalars['Int']['input'];
  ydmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  yrcFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeliveryReceiptCreateWithoutReceivedDeliveryReceiptInput = {
  basketReleasedQuantity: Scalars['Int']['input'];
  cdmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  chmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemCreateNestedManyWithoutDeliveryReceiptInput>;
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutDeliveryReceiptsInput;
  pymFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  screenSeparatorReleasedQuantity: Scalars['Int']['input'];
  ydmFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
  yrcFryingTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeliveryReceiptItem = {
  __typename?: 'DeliveryReceiptItem';
  cmlProduct: CmlProduct;
  cmlProductId: Scalars['Int']['output'];
  deliveryReceipt: DeliveryReceipt;
  deliveryReceiptId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  receivedDeliveryReceiptItem?: Maybe<ReceivedDeliveryReceiptItem>;
};

export type DeliveryReceiptItemAvgAggregate = {
  __typename?: 'DeliveryReceiptItemAvgAggregate';
  cmlProductId?: Maybe<Scalars['Float']['output']>;
  deliveryReceiptId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type DeliveryReceiptItemCountAggregate = {
  __typename?: 'DeliveryReceiptItemCountAggregate';
  _all: Scalars['Int']['output'];
  cmlProductId: Scalars['Int']['output'];
  deliveryReceiptId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type DeliveryReceiptItemCreateManyCmlProductInput = {
  deliveryReceiptId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type DeliveryReceiptItemCreateManyCmlProductInputEnvelope = {
  data: Array<DeliveryReceiptItemCreateManyCmlProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeliveryReceiptItemCreateManyDeliveryReceiptInput = {
  cmlProductId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type DeliveryReceiptItemCreateManyDeliveryReceiptInputEnvelope = {
  data: Array<DeliveryReceiptItemCreateManyDeliveryReceiptInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeliveryReceiptItemCreateNestedManyWithoutCmlProductInput = {
  connect?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeliveryReceiptItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<DeliveryReceiptItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<DeliveryReceiptItemCreateManyCmlProductInputEnvelope>;
};

export type DeliveryReceiptItemCreateNestedManyWithoutDeliveryReceiptInput = {
  connect?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeliveryReceiptItemCreateOrConnectWithoutDeliveryReceiptInput>>;
  create?: InputMaybe<Array<DeliveryReceiptItemCreateWithoutDeliveryReceiptInput>>;
  createMany?: InputMaybe<DeliveryReceiptItemCreateManyDeliveryReceiptInputEnvelope>;
};

export type DeliveryReceiptItemCreateNestedOneWithoutReceivedDeliveryReceiptItemInput = {
  connect?: InputMaybe<DeliveryReceiptItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeliveryReceiptItemCreateOrConnectWithoutReceivedDeliveryReceiptItemInput>;
  create?: InputMaybe<DeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptItemInput>;
};

export type DeliveryReceiptItemCreateOrConnectWithoutCmlProductInput = {
  create: DeliveryReceiptItemCreateWithoutCmlProductInput;
  where: DeliveryReceiptItemWhereUniqueInput;
};

export type DeliveryReceiptItemCreateOrConnectWithoutDeliveryReceiptInput = {
  create: DeliveryReceiptItemCreateWithoutDeliveryReceiptInput;
  where: DeliveryReceiptItemWhereUniqueInput;
};

export type DeliveryReceiptItemCreateOrConnectWithoutReceivedDeliveryReceiptItemInput = {
  create: DeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptItemInput;
  where: DeliveryReceiptItemWhereUniqueInput;
};

export type DeliveryReceiptItemCreateWithoutCmlProductInput = {
  deliveryReceipt: DeliveryReceiptCreateNestedOneWithoutDeliveryReceiptItemsInput;
  quantity: Scalars['Int']['input'];
  receivedDeliveryReceiptItem?: InputMaybe<ReceivedDeliveryReceiptItemCreateNestedOneWithoutDeliveryReceiptItemInput>;
};

export type DeliveryReceiptItemCreateWithoutDeliveryReceiptInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutDeliveryReceiptItemsInput;
  quantity: Scalars['Int']['input'];
  receivedDeliveryReceiptItem?: InputMaybe<ReceivedDeliveryReceiptItemCreateNestedOneWithoutDeliveryReceiptItemInput>;
};

export type DeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptItemInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutDeliveryReceiptItemsInput;
  deliveryReceipt: DeliveryReceiptCreateNestedOneWithoutDeliveryReceiptItemsInput;
  quantity: Scalars['Int']['input'];
};

export type DeliveryReceiptItemListRelationFilter = {
  every?: InputMaybe<DeliveryReceiptItemWhereInput>;
  none?: InputMaybe<DeliveryReceiptItemWhereInput>;
  some?: InputMaybe<DeliveryReceiptItemWhereInput>;
};

export type DeliveryReceiptItemMaxAggregate = {
  __typename?: 'DeliveryReceiptItemMaxAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  deliveryReceiptId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryReceiptItemMinAggregate = {
  __typename?: 'DeliveryReceiptItemMinAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  deliveryReceiptId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryReceiptItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeliveryReceiptItemOrderByWithRelationInput = {
  cmlProduct?: InputMaybe<CmlProductOrderByWithRelationInput>;
  cmlProductId?: InputMaybe<SortOrder>;
  deliveryReceipt?: InputMaybe<DeliveryReceiptOrderByWithRelationInput>;
  deliveryReceiptId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  receivedDeliveryReceiptItem?: InputMaybe<ReceivedDeliveryReceiptItemOrderByWithRelationInput>;
};

export type DeliveryReceiptItemRelationFilter = {
  is?: InputMaybe<DeliveryReceiptItemWhereInput>;
  isNot?: InputMaybe<DeliveryReceiptItemWhereInput>;
};

export enum DeliveryReceiptItemScalarFieldEnum {
  CmlProductId = 'cmlProductId',
  DeliveryReceiptId = 'deliveryReceiptId',
  Id = 'id',
  Quantity = 'quantity'
}

export type DeliveryReceiptItemScalarWhereInput = {
  AND?: InputMaybe<Array<DeliveryReceiptItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryReceiptItemScalarWhereInput>>;
  OR?: InputMaybe<Array<DeliveryReceiptItemScalarWhereInput>>;
  cmlProductId?: InputMaybe<IntFilter>;
  deliveryReceiptId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type DeliveryReceiptItemSumAggregate = {
  __typename?: 'DeliveryReceiptItemSumAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  deliveryReceiptId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryReceiptItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type DeliveryReceiptItemUpdateManyWithWhereWithoutCmlProductInput = {
  data: DeliveryReceiptItemUpdateManyMutationInput;
  where: DeliveryReceiptItemScalarWhereInput;
};

export type DeliveryReceiptItemUpdateManyWithWhereWithoutDeliveryReceiptInput = {
  data: DeliveryReceiptItemUpdateManyMutationInput;
  where: DeliveryReceiptItemScalarWhereInput;
};

export type DeliveryReceiptItemUpdateManyWithoutCmlProductNestedInput = {
  connect?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeliveryReceiptItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<DeliveryReceiptItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<DeliveryReceiptItemCreateManyCmlProductInputEnvelope>;
  delete?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeliveryReceiptItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  set?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  update?: InputMaybe<Array<DeliveryReceiptItemUpdateWithWhereUniqueWithoutCmlProductInput>>;
  updateMany?: InputMaybe<Array<DeliveryReceiptItemUpdateManyWithWhereWithoutCmlProductInput>>;
  upsert?: InputMaybe<Array<DeliveryReceiptItemUpsertWithWhereUniqueWithoutCmlProductInput>>;
};

export type DeliveryReceiptItemUpdateManyWithoutDeliveryReceiptNestedInput = {
  connect?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeliveryReceiptItemCreateOrConnectWithoutDeliveryReceiptInput>>;
  create?: InputMaybe<Array<DeliveryReceiptItemCreateWithoutDeliveryReceiptInput>>;
  createMany?: InputMaybe<DeliveryReceiptItemCreateManyDeliveryReceiptInputEnvelope>;
  delete?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeliveryReceiptItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  set?: InputMaybe<Array<DeliveryReceiptItemWhereUniqueInput>>;
  update?: InputMaybe<Array<DeliveryReceiptItemUpdateWithWhereUniqueWithoutDeliveryReceiptInput>>;
  updateMany?: InputMaybe<Array<DeliveryReceiptItemUpdateManyWithWhereWithoutDeliveryReceiptInput>>;
  upsert?: InputMaybe<Array<DeliveryReceiptItemUpsertWithWhereUniqueWithoutDeliveryReceiptInput>>;
};

export type DeliveryReceiptItemUpdateOneRequiredWithoutReceivedDeliveryReceiptItemNestedInput = {
  connect?: InputMaybe<DeliveryReceiptItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeliveryReceiptItemCreateOrConnectWithoutReceivedDeliveryReceiptItemInput>;
  create?: InputMaybe<DeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptItemInput>;
  update?: InputMaybe<DeliveryReceiptItemUpdateWithoutReceivedDeliveryReceiptItemInput>;
  upsert?: InputMaybe<DeliveryReceiptItemUpsertWithoutReceivedDeliveryReceiptItemInput>;
};

export type DeliveryReceiptItemUpdateWithWhereUniqueWithoutCmlProductInput = {
  data: DeliveryReceiptItemUpdateWithoutCmlProductInput;
  where: DeliveryReceiptItemWhereUniqueInput;
};

export type DeliveryReceiptItemUpdateWithWhereUniqueWithoutDeliveryReceiptInput = {
  data: DeliveryReceiptItemUpdateWithoutDeliveryReceiptInput;
  where: DeliveryReceiptItemWhereUniqueInput;
};

export type DeliveryReceiptItemUpdateWithoutCmlProductInput = {
  deliveryReceipt?: InputMaybe<DeliveryReceiptUpdateOneRequiredWithoutDeliveryReceiptItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedDeliveryReceiptItem?: InputMaybe<ReceivedDeliveryReceiptItemUpdateOneWithoutDeliveryReceiptItemNestedInput>;
};

export type DeliveryReceiptItemUpdateWithoutDeliveryReceiptInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutDeliveryReceiptItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedDeliveryReceiptItem?: InputMaybe<ReceivedDeliveryReceiptItemUpdateOneWithoutDeliveryReceiptItemNestedInput>;
};

export type DeliveryReceiptItemUpdateWithoutReceivedDeliveryReceiptItemInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutDeliveryReceiptItemsNestedInput>;
  deliveryReceipt?: InputMaybe<DeliveryReceiptUpdateOneRequiredWithoutDeliveryReceiptItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type DeliveryReceiptItemUpsertWithWhereUniqueWithoutCmlProductInput = {
  create: DeliveryReceiptItemCreateWithoutCmlProductInput;
  update: DeliveryReceiptItemUpdateWithoutCmlProductInput;
  where: DeliveryReceiptItemWhereUniqueInput;
};

export type DeliveryReceiptItemUpsertWithWhereUniqueWithoutDeliveryReceiptInput = {
  create: DeliveryReceiptItemCreateWithoutDeliveryReceiptInput;
  update: DeliveryReceiptItemUpdateWithoutDeliveryReceiptInput;
  where: DeliveryReceiptItemWhereUniqueInput;
};

export type DeliveryReceiptItemUpsertWithoutReceivedDeliveryReceiptItemInput = {
  create: DeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptItemInput;
  update: DeliveryReceiptItemUpdateWithoutReceivedDeliveryReceiptItemInput;
};

export type DeliveryReceiptItemWhereInput = {
  AND?: InputMaybe<Array<DeliveryReceiptItemWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryReceiptItemWhereInput>>;
  OR?: InputMaybe<Array<DeliveryReceiptItemWhereInput>>;
  cmlProduct?: InputMaybe<CmlProductRelationFilter>;
  cmlProductId?: InputMaybe<IntFilter>;
  deliveryReceipt?: InputMaybe<DeliveryReceiptRelationFilter>;
  deliveryReceiptId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  receivedDeliveryReceiptItem?: InputMaybe<ReceivedDeliveryReceiptItemRelationFilter>;
};

export type DeliveryReceiptItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type DeliveryReceiptListRelationFilter = {
  every?: InputMaybe<DeliveryReceiptWhereInput>;
  none?: InputMaybe<DeliveryReceiptWhereInput>;
  some?: InputMaybe<DeliveryReceiptWhereInput>;
};

export type DeliveryReceiptMaxAggregate = {
  __typename?: 'DeliveryReceiptMaxAggregate';
  basketReleasedQuantity?: Maybe<Scalars['Int']['output']>;
  cdmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  chmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  pymFryingTime?: Maybe<Scalars['DateTime']['output']>;
  receivedByDriver?: Maybe<Scalars['String']['output']>;
  screenSeparatorReleasedQuantity?: Maybe<Scalars['Int']['output']>;
  ydmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  yrcFryingTime?: Maybe<Scalars['DateTime']['output']>;
};

export type DeliveryReceiptMinAggregate = {
  __typename?: 'DeliveryReceiptMinAggregate';
  basketReleasedQuantity?: Maybe<Scalars['Int']['output']>;
  cdmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  chmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  pymFryingTime?: Maybe<Scalars['DateTime']['output']>;
  receivedByDriver?: Maybe<Scalars['String']['output']>;
  screenSeparatorReleasedQuantity?: Maybe<Scalars['Int']['output']>;
  ydmFryingTime?: Maybe<Scalars['DateTime']['output']>;
  yrcFryingTime?: Maybe<Scalars['DateTime']['output']>;
};

export type DeliveryReceiptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeliveryReceiptOrderByWithRelationInput = {
  basketReleasedQuantity?: InputMaybe<SortOrder>;
  cdmFryingTime?: InputMaybe<SortOrder>;
  chmFryingTime?: InputMaybe<SortOrder>;
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemOrderByRelationAggregateInput>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
  pymFryingTime?: InputMaybe<SortOrder>;
  receivedByDriver?: InputMaybe<SortOrder>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptOrderByWithRelationInput>;
  screenSeparatorReleasedQuantity?: InputMaybe<SortOrder>;
  ydmFryingTime?: InputMaybe<SortOrder>;
  yrcFryingTime?: InputMaybe<SortOrder>;
};

export type DeliveryReceiptRelationFilter = {
  is?: InputMaybe<DeliveryReceiptWhereInput>;
  isNot?: InputMaybe<DeliveryReceiptWhereInput>;
};

export enum DeliveryReceiptScalarFieldEnum {
  BasketReleasedQuantity = 'basketReleasedQuantity',
  CdmFryingTime = 'cdmFryingTime',
  ChmFryingTime = 'chmFryingTime',
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Id = 'id',
  OutletId = 'outletId',
  PymFryingTime = 'pymFryingTime',
  ReceivedByDriver = 'receivedByDriver',
  ScreenSeparatorReleasedQuantity = 'screenSeparatorReleasedQuantity',
  YdmFryingTime = 'ydmFryingTime',
  YrcFryingTime = 'yrcFryingTime'
}

export type DeliveryReceiptScalarWhereInput = {
  AND?: InputMaybe<Array<DeliveryReceiptScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryReceiptScalarWhereInput>>;
  OR?: InputMaybe<Array<DeliveryReceiptScalarWhereInput>>;
  basketReleasedQuantity?: InputMaybe<IntFilter>;
  cdmFryingTime?: InputMaybe<DateTimeNullableFilter>;
  chmFryingTime?: InputMaybe<DateTimeNullableFilter>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outletId?: InputMaybe<IntFilter>;
  pymFryingTime?: InputMaybe<DateTimeNullableFilter>;
  receivedByDriver?: InputMaybe<StringNullableFilter>;
  screenSeparatorReleasedQuantity?: InputMaybe<IntFilter>;
  ydmFryingTime?: InputMaybe<DateTimeNullableFilter>;
  yrcFryingTime?: InputMaybe<DateTimeNullableFilter>;
};

export type DeliveryReceiptSumAggregate = {
  __typename?: 'DeliveryReceiptSumAggregate';
  basketReleasedQuantity?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  screenSeparatorReleasedQuantity?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryReceiptSummary = {
  __typename?: 'DeliveryReceiptSummary';
  cmlProduct?: Maybe<CmlProduct>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type DeliveryReceiptUpdateInput = {
  basketReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  cdmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  chmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutDeliveryReceiptNestedInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutDeliveryReceiptsNestedInput>;
  pymFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptUpdateOneWithoutDeliveryReceiptNestedInput>;
  screenSeparatorReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ydmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  yrcFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DeliveryReceiptUpdateManyMutationInput = {
  basketReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  cdmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  chmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  pymFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  screenSeparatorReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ydmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  yrcFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DeliveryReceiptUpdateManyWithWhereWithoutOutletInput = {
  data: DeliveryReceiptUpdateManyMutationInput;
  where: DeliveryReceiptScalarWhereInput;
};

export type DeliveryReceiptUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<DeliveryReceiptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeliveryReceiptCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<DeliveryReceiptCreateWithoutOutletInput>>;
  createMany?: InputMaybe<DeliveryReceiptCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<DeliveryReceiptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeliveryReceiptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeliveryReceiptWhereUniqueInput>>;
  set?: InputMaybe<Array<DeliveryReceiptWhereUniqueInput>>;
  update?: InputMaybe<Array<DeliveryReceiptUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<DeliveryReceiptUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<DeliveryReceiptUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type DeliveryReceiptUpdateOneRequiredWithoutDeliveryReceiptItemsNestedInput = {
  connect?: InputMaybe<DeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeliveryReceiptCreateOrConnectWithoutDeliveryReceiptItemsInput>;
  create?: InputMaybe<DeliveryReceiptCreateWithoutDeliveryReceiptItemsInput>;
  update?: InputMaybe<DeliveryReceiptUpdateWithoutDeliveryReceiptItemsInput>;
  upsert?: InputMaybe<DeliveryReceiptUpsertWithoutDeliveryReceiptItemsInput>;
};

export type DeliveryReceiptUpdateOneRequiredWithoutReceivedDeliveryReceiptNestedInput = {
  connect?: InputMaybe<DeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeliveryReceiptCreateOrConnectWithoutReceivedDeliveryReceiptInput>;
  create?: InputMaybe<DeliveryReceiptCreateWithoutReceivedDeliveryReceiptInput>;
  update?: InputMaybe<DeliveryReceiptUpdateWithoutReceivedDeliveryReceiptInput>;
  upsert?: InputMaybe<DeliveryReceiptUpsertWithoutReceivedDeliveryReceiptInput>;
};

export type DeliveryReceiptUpdateWithWhereUniqueWithoutOutletInput = {
  data: DeliveryReceiptUpdateWithoutOutletInput;
  where: DeliveryReceiptWhereUniqueInput;
};

export type DeliveryReceiptUpdateWithoutDeliveryReceiptItemsInput = {
  basketReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  cdmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  chmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutDeliveryReceiptsNestedInput>;
  pymFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptUpdateOneWithoutDeliveryReceiptNestedInput>;
  screenSeparatorReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ydmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  yrcFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DeliveryReceiptUpdateWithoutOutletInput = {
  basketReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  cdmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  chmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutDeliveryReceiptNestedInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  pymFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptUpdateOneWithoutDeliveryReceiptNestedInput>;
  screenSeparatorReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ydmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  yrcFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DeliveryReceiptUpdateWithoutReceivedDeliveryReceiptInput = {
  basketReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  cdmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  chmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemUpdateManyWithoutDeliveryReceiptNestedInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutDeliveryReceiptsNestedInput>;
  pymFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  screenSeparatorReleasedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ydmFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  yrcFryingTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DeliveryReceiptUpsertWithWhereUniqueWithoutOutletInput = {
  create: DeliveryReceiptCreateWithoutOutletInput;
  update: DeliveryReceiptUpdateWithoutOutletInput;
  where: DeliveryReceiptWhereUniqueInput;
};

export type DeliveryReceiptUpsertWithoutDeliveryReceiptItemsInput = {
  create: DeliveryReceiptCreateWithoutDeliveryReceiptItemsInput;
  update: DeliveryReceiptUpdateWithoutDeliveryReceiptItemsInput;
};

export type DeliveryReceiptUpsertWithoutReceivedDeliveryReceiptInput = {
  create: DeliveryReceiptCreateWithoutReceivedDeliveryReceiptInput;
  update: DeliveryReceiptUpdateWithoutReceivedDeliveryReceiptInput;
};

export type DeliveryReceiptWhereInput = {
  AND?: InputMaybe<Array<DeliveryReceiptWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryReceiptWhereInput>>;
  OR?: InputMaybe<Array<DeliveryReceiptWhereInput>>;
  basketReleasedQuantity?: InputMaybe<IntFilter>;
  cdmFryingTime?: InputMaybe<DateTimeNullableFilter>;
  chmFryingTime?: InputMaybe<DateTimeNullableFilter>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryReceiptItems?: InputMaybe<DeliveryReceiptItemListRelationFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
  pymFryingTime?: InputMaybe<DateTimeNullableFilter>;
  receivedByDriver?: InputMaybe<StringNullableFilter>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptRelationFilter>;
  screenSeparatorReleasedQuantity?: InputMaybe<IntFilter>;
  ydmFryingTime?: InputMaybe<DateTimeNullableFilter>;
  yrcFryingTime?: InputMaybe<DateTimeNullableFilter>;
};

export type DeliveryReceiptWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export { DeliveryShiftSchedule };

export type DonutDistributionCmlProductItem = {
  __typename?: 'DonutDistributionCmlProductItem';
  cmlProduct: Scalars['String']['output'];
  perCmlProductDonutDistributions: Array<PerCmlProductDonutDistribution>;
};

export type DonutDistributionPerOutlet = {
  __typename?: 'DonutDistributionPerOutlet';
  cmlProducts: Array<DonutDistributionCmlProductItem>;
  donutMixAndCut?: Maybe<Scalars['String']['output']>;
};

export type DonutDistributionPerOutletWhereInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryShiftSchedule?: InputMaybe<DeliveryShiftSchedule>;
  outletIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  productCategory?: InputMaybe<ProductCategory>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type DonutEndingConsolidation = {
  __typename?: 'DonutEndingConsolidation';
  cmlProducts: Array<CmlProductItem>;
  donutMixAndCut: Scalars['String']['output'];
};

export type DonutEndingConsolidationWhereInput = {
  deliveryShiftSchedule?: InputMaybe<DeliveryShiftSchedule>;
  monthYear?: InputMaybe<Scalars['DateTime']['input']>;
  outletId?: InputMaybe<Scalars['Float']['input']>;
};

export type DonutMixAndCut = {
  __typename?: 'DonutMixAndCut';
  _count?: Maybe<DonutMixAndCutCount>;
  cmlProducts: Array<CmlProduct>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


export type DonutMixAndCutCmlProductsArgs = {
  cursor?: InputMaybe<CmlProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductWhereInput>;
};

export type DonutMixAndCutAvgAggregate = {
  __typename?: 'DonutMixAndCutAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type DonutMixAndCutCount = {
  __typename?: 'DonutMixAndCutCount';
  cmlProducts: Scalars['Int']['output'];
};

export type DonutMixAndCutCountAggregate = {
  __typename?: 'DonutMixAndCutCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
};

export type DonutMixAndCutCreateInput = {
  cmlProducts?: InputMaybe<CmlProductCreateNestedManyWithoutDonutMixAndCutInput>;
  name: Scalars['String']['input'];
};

export type DonutMixAndCutCreateNestedOneWithoutCmlProductsInput = {
  connect?: InputMaybe<DonutMixAndCutWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DonutMixAndCutCreateOrConnectWithoutCmlProductsInput>;
  create?: InputMaybe<DonutMixAndCutCreateWithoutCmlProductsInput>;
};

export type DonutMixAndCutCreateOrConnectWithoutCmlProductsInput = {
  create: DonutMixAndCutCreateWithoutCmlProductsInput;
  where: DonutMixAndCutWhereUniqueInput;
};

export type DonutMixAndCutCreateWithoutCmlProductsInput = {
  name: Scalars['String']['input'];
};

export type DonutMixAndCutMaxAggregate = {
  __typename?: 'DonutMixAndCutMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DonutMixAndCutMinAggregate = {
  __typename?: 'DonutMixAndCutMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DonutMixAndCutOrderByWithRelationInput = {
  cmlProducts?: InputMaybe<CmlProductOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type DonutMixAndCutRelationFilter = {
  is?: InputMaybe<DonutMixAndCutWhereInput>;
  isNot?: InputMaybe<DonutMixAndCutWhereInput>;
};

export enum DonutMixAndCutScalarFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type DonutMixAndCutSumAggregate = {
  __typename?: 'DonutMixAndCutSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type DonutMixAndCutUpdateInput = {
  cmlProducts?: InputMaybe<CmlProductUpdateManyWithoutDonutMixAndCutNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DonutMixAndCutUpdateOneRequiredWithoutCmlProductsNestedInput = {
  connect?: InputMaybe<DonutMixAndCutWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DonutMixAndCutCreateOrConnectWithoutCmlProductsInput>;
  create?: InputMaybe<DonutMixAndCutCreateWithoutCmlProductsInput>;
  update?: InputMaybe<DonutMixAndCutUpdateWithoutCmlProductsInput>;
  upsert?: InputMaybe<DonutMixAndCutUpsertWithoutCmlProductsInput>;
};

export type DonutMixAndCutUpdateWithoutCmlProductsInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DonutMixAndCutUpsertWithoutCmlProductsInput = {
  create: DonutMixAndCutCreateWithoutCmlProductsInput;
  update: DonutMixAndCutUpdateWithoutCmlProductsInput;
};

export type DonutMixAndCutWhereInput = {
  AND?: InputMaybe<Array<DonutMixAndCutWhereInput>>;
  NOT?: InputMaybe<Array<DonutMixAndCutWhereInput>>;
  OR?: InputMaybe<Array<DonutMixAndCutWhereInput>>;
  cmlProducts?: InputMaybe<CmlProductListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type DonutMixAndCutWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DonutThrowAway = {
  __typename?: 'DonutThrowAway';
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type DonutsConsolidatedThrowAwayReportItem = {
  __typename?: 'DonutsConsolidatedThrowAwayReportItem';
  date?: Maybe<Scalars['DateTime']['output']>;
  donuts?: Maybe<Array<DonutThrowAway>>;
};

export type EndingSdEmTa = {
  __typename?: 'EndingSdEmTa';
  _count?: Maybe<EndingSdEmTaCount>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  endingSdEmTaBeverageItems: Array<EndingSdEmTaBeverageItem>;
  endingSdEmTaCmlItems: Array<EndingSdEmTaCmlItem>;
  endingSdEmTaPackagingItems: Array<EndingSdEmTaPackagingItem>;
  endingSdEmTaRawMaterialItems: Array<EndingSdEmTaRawMaterialItem>;
  formType: FormType;
  id: Scalars['Int']['output'];
  notedBy?: Maybe<Scalars['String']['output']>;
  outlet: Outlet;
  outletId: Scalars['Int']['output'];
  receivedBy?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};


export type EndingSdEmTaEndingSdEmTaBeverageItemsArgs = {
  cursor?: InputMaybe<EndingSdEmTaBeverageItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaBeverageItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaBeverageItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaBeverageItemWhereInput>;
};


export type EndingSdEmTaEndingSdEmTaCmlItemsArgs = {
  cursor?: InputMaybe<EndingSdEmTaCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaCmlItemWhereInput>;
};


export type EndingSdEmTaEndingSdEmTaPackagingItemsArgs = {
  cursor?: InputMaybe<EndingSdEmTaPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaPackagingItemWhereInput>;
};


export type EndingSdEmTaEndingSdEmTaRawMaterialItemsArgs = {
  cursor?: InputMaybe<EndingSdEmTaRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaRawMaterialItemWhereInput>;
};

export type EndingSdEmTaAvgAggregate = {
  __typename?: 'EndingSdEmTaAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
};

export type EndingSdEmTaBeverageItem = {
  __typename?: 'EndingSdEmTaBeverageItem';
  beverage: Beverage;
  beverageId: Scalars['Int']['output'];
  endingSdEmTa: EndingSdEmTa;
  endingSdEmTaId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
};

export type EndingSdEmTaBeverageItemCreateManyBeverageInput = {
  endingSdEmTaId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
};

export type EndingSdEmTaBeverageItemCreateManyBeverageInputEnvelope = {
  data: Array<EndingSdEmTaBeverageItemCreateManyBeverageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaBeverageItemCreateManyEndingSdEmTaInput = {
  beverageId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
};

export type EndingSdEmTaBeverageItemCreateManyEndingSdEmTaInputEnvelope = {
  data: Array<EndingSdEmTaBeverageItemCreateManyEndingSdEmTaInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaBeverageItemCreateNestedManyWithoutBeverageInput = {
  connect?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaBeverageItemCreateOrConnectWithoutBeverageInput>>;
  create?: InputMaybe<Array<EndingSdEmTaBeverageItemCreateWithoutBeverageInput>>;
  createMany?: InputMaybe<EndingSdEmTaBeverageItemCreateManyBeverageInputEnvelope>;
};

export type EndingSdEmTaBeverageItemCreateNestedManyWithoutEndingSdEmTaInput = {
  connect?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaBeverageItemCreateOrConnectWithoutEndingSdEmTaInput>>;
  create?: InputMaybe<Array<EndingSdEmTaBeverageItemCreateWithoutEndingSdEmTaInput>>;
  createMany?: InputMaybe<EndingSdEmTaBeverageItemCreateManyEndingSdEmTaInputEnvelope>;
};

export type EndingSdEmTaBeverageItemCreateOrConnectWithoutBeverageInput = {
  create: EndingSdEmTaBeverageItemCreateWithoutBeverageInput;
  where: EndingSdEmTaBeverageItemWhereUniqueInput;
};

export type EndingSdEmTaBeverageItemCreateOrConnectWithoutEndingSdEmTaInput = {
  create: EndingSdEmTaBeverageItemCreateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaBeverageItemWhereUniqueInput;
};

export type EndingSdEmTaBeverageItemCreateWithoutBeverageInput = {
  endingSdEmTa: EndingSdEmTaCreateNestedOneWithoutEndingSdEmTaBeverageItemsInput;
  quantity: Scalars['Float']['input'];
};

export type EndingSdEmTaBeverageItemCreateWithoutEndingSdEmTaInput = {
  beverage: BeverageCreateNestedOneWithoutEndingSdEmTaBeverageItemsInput;
  quantity: Scalars['Float']['input'];
};

export type EndingSdEmTaBeverageItemListRelationFilter = {
  every?: InputMaybe<EndingSdEmTaBeverageItemWhereInput>;
  none?: InputMaybe<EndingSdEmTaBeverageItemWhereInput>;
  some?: InputMaybe<EndingSdEmTaBeverageItemWhereInput>;
};

export type EndingSdEmTaBeverageItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EndingSdEmTaBeverageItemOrderByWithRelationInput = {
  beverage?: InputMaybe<BeverageOrderByWithRelationInput>;
  beverageId?: InputMaybe<SortOrder>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaOrderByWithRelationInput>;
  endingSdEmTaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum EndingSdEmTaBeverageItemScalarFieldEnum {
  BeverageId = 'beverageId',
  EndingSdEmTaId = 'endingSdEmTaId',
  Id = 'id',
  Quantity = 'quantity'
}

export type EndingSdEmTaBeverageItemScalarWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaBeverageItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaBeverageItemScalarWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaBeverageItemScalarWhereInput>>;
  beverageId?: InputMaybe<IntFilter>;
  endingSdEmTaId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
};

export type EndingSdEmTaBeverageItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type EndingSdEmTaBeverageItemUpdateManyWithWhereWithoutBeverageInput = {
  data: EndingSdEmTaBeverageItemUpdateManyMutationInput;
  where: EndingSdEmTaBeverageItemScalarWhereInput;
};

export type EndingSdEmTaBeverageItemUpdateManyWithWhereWithoutEndingSdEmTaInput = {
  data: EndingSdEmTaBeverageItemUpdateManyMutationInput;
  where: EndingSdEmTaBeverageItemScalarWhereInput;
};

export type EndingSdEmTaBeverageItemUpdateManyWithoutBeverageNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaBeverageItemCreateOrConnectWithoutBeverageInput>>;
  create?: InputMaybe<Array<EndingSdEmTaBeverageItemCreateWithoutBeverageInput>>;
  createMany?: InputMaybe<EndingSdEmTaBeverageItemCreateManyBeverageInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaBeverageItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaBeverageItemUpdateWithWhereUniqueWithoutBeverageInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaBeverageItemUpdateManyWithWhereWithoutBeverageInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaBeverageItemUpsertWithWhereUniqueWithoutBeverageInput>>;
};

export type EndingSdEmTaBeverageItemUpdateManyWithoutEndingSdEmTaNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaBeverageItemCreateOrConnectWithoutEndingSdEmTaInput>>;
  create?: InputMaybe<Array<EndingSdEmTaBeverageItemCreateWithoutEndingSdEmTaInput>>;
  createMany?: InputMaybe<EndingSdEmTaBeverageItemCreateManyEndingSdEmTaInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaBeverageItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaBeverageItemUpdateWithWhereUniqueWithoutEndingSdEmTaInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaBeverageItemUpdateManyWithWhereWithoutEndingSdEmTaInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaBeverageItemUpsertWithWhereUniqueWithoutEndingSdEmTaInput>>;
};

export type EndingSdEmTaBeverageItemUpdateWithWhereUniqueWithoutBeverageInput = {
  data: EndingSdEmTaBeverageItemUpdateWithoutBeverageInput;
  where: EndingSdEmTaBeverageItemWhereUniqueInput;
};

export type EndingSdEmTaBeverageItemUpdateWithWhereUniqueWithoutEndingSdEmTaInput = {
  data: EndingSdEmTaBeverageItemUpdateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaBeverageItemWhereUniqueInput;
};

export type EndingSdEmTaBeverageItemUpdateWithoutBeverageInput = {
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateOneRequiredWithoutEndingSdEmTaBeverageItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type EndingSdEmTaBeverageItemUpdateWithoutEndingSdEmTaInput = {
  beverage?: InputMaybe<BeverageUpdateOneRequiredWithoutEndingSdEmTaBeverageItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type EndingSdEmTaBeverageItemUpsertWithWhereUniqueWithoutBeverageInput = {
  create: EndingSdEmTaBeverageItemCreateWithoutBeverageInput;
  update: EndingSdEmTaBeverageItemUpdateWithoutBeverageInput;
  where: EndingSdEmTaBeverageItemWhereUniqueInput;
};

export type EndingSdEmTaBeverageItemUpsertWithWhereUniqueWithoutEndingSdEmTaInput = {
  create: EndingSdEmTaBeverageItemCreateWithoutEndingSdEmTaInput;
  update: EndingSdEmTaBeverageItemUpdateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaBeverageItemWhereUniqueInput;
};

export type EndingSdEmTaBeverageItemWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaBeverageItemWhereInput>>;
  beverage?: InputMaybe<BeverageRelationFilter>;
  beverageId?: InputMaybe<IntFilter>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaRelationFilter>;
  endingSdEmTaId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
};

export type EndingSdEmTaBeverageItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EndingSdEmTaCmlItem = {
  __typename?: 'EndingSdEmTaCmlItem';
  cmlProduct: CmlProduct;
  cmlProductId: Scalars['Int']['output'];
  endingSdEmTa: EndingSdEmTa;
  endingSdEmTaId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type EndingSdEmTaCmlItemCreateManyCmlProductInput = {
  endingSdEmTaId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type EndingSdEmTaCmlItemCreateManyCmlProductInputEnvelope = {
  data: Array<EndingSdEmTaCmlItemCreateManyCmlProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaCmlItemCreateManyEndingSdEmTaInput = {
  cmlProductId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type EndingSdEmTaCmlItemCreateManyEndingSdEmTaInputEnvelope = {
  data: Array<EndingSdEmTaCmlItemCreateManyEndingSdEmTaInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaCmlItemCreateNestedManyWithoutCmlProductInput = {
  connect?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaCmlItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<EndingSdEmTaCmlItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<EndingSdEmTaCmlItemCreateManyCmlProductInputEnvelope>;
};

export type EndingSdEmTaCmlItemCreateNestedManyWithoutEndingSdEmTaInput = {
  connect?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaCmlItemCreateOrConnectWithoutEndingSdEmTaInput>>;
  create?: InputMaybe<Array<EndingSdEmTaCmlItemCreateWithoutEndingSdEmTaInput>>;
  createMany?: InputMaybe<EndingSdEmTaCmlItemCreateManyEndingSdEmTaInputEnvelope>;
};

export type EndingSdEmTaCmlItemCreateOrConnectWithoutCmlProductInput = {
  create: EndingSdEmTaCmlItemCreateWithoutCmlProductInput;
  where: EndingSdEmTaCmlItemWhereUniqueInput;
};

export type EndingSdEmTaCmlItemCreateOrConnectWithoutEndingSdEmTaInput = {
  create: EndingSdEmTaCmlItemCreateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaCmlItemWhereUniqueInput;
};

export type EndingSdEmTaCmlItemCreateWithoutCmlProductInput = {
  endingSdEmTa: EndingSdEmTaCreateNestedOneWithoutEndingSdEmTaCmlItemsInput;
  quantity: Scalars['Int']['input'];
};

export type EndingSdEmTaCmlItemCreateWithoutEndingSdEmTaInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutEndingSdEmTaCmlItemsInput;
  quantity: Scalars['Int']['input'];
};

export type EndingSdEmTaCmlItemListRelationFilter = {
  every?: InputMaybe<EndingSdEmTaCmlItemWhereInput>;
  none?: InputMaybe<EndingSdEmTaCmlItemWhereInput>;
  some?: InputMaybe<EndingSdEmTaCmlItemWhereInput>;
};

export type EndingSdEmTaCmlItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EndingSdEmTaCmlItemOrderByWithRelationInput = {
  cmlProduct?: InputMaybe<CmlProductOrderByWithRelationInput>;
  cmlProductId?: InputMaybe<SortOrder>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaOrderByWithRelationInput>;
  endingSdEmTaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum EndingSdEmTaCmlItemScalarFieldEnum {
  CmlProductId = 'cmlProductId',
  EndingSdEmTaId = 'endingSdEmTaId',
  Id = 'id',
  Quantity = 'quantity'
}

export type EndingSdEmTaCmlItemScalarWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaCmlItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaCmlItemScalarWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaCmlItemScalarWhereInput>>;
  cmlProductId?: InputMaybe<IntFilter>;
  endingSdEmTaId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type EndingSdEmTaCmlItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EndingSdEmTaCmlItemUpdateManyWithWhereWithoutCmlProductInput = {
  data: EndingSdEmTaCmlItemUpdateManyMutationInput;
  where: EndingSdEmTaCmlItemScalarWhereInput;
};

export type EndingSdEmTaCmlItemUpdateManyWithWhereWithoutEndingSdEmTaInput = {
  data: EndingSdEmTaCmlItemUpdateManyMutationInput;
  where: EndingSdEmTaCmlItemScalarWhereInput;
};

export type EndingSdEmTaCmlItemUpdateManyWithoutCmlProductNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaCmlItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<EndingSdEmTaCmlItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<EndingSdEmTaCmlItemCreateManyCmlProductInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaCmlItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaCmlItemUpdateWithWhereUniqueWithoutCmlProductInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaCmlItemUpdateManyWithWhereWithoutCmlProductInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaCmlItemUpsertWithWhereUniqueWithoutCmlProductInput>>;
};

export type EndingSdEmTaCmlItemUpdateManyWithoutEndingSdEmTaNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaCmlItemCreateOrConnectWithoutEndingSdEmTaInput>>;
  create?: InputMaybe<Array<EndingSdEmTaCmlItemCreateWithoutEndingSdEmTaInput>>;
  createMany?: InputMaybe<EndingSdEmTaCmlItemCreateManyEndingSdEmTaInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaCmlItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaCmlItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaCmlItemUpdateWithWhereUniqueWithoutEndingSdEmTaInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaCmlItemUpdateManyWithWhereWithoutEndingSdEmTaInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaCmlItemUpsertWithWhereUniqueWithoutEndingSdEmTaInput>>;
};

export type EndingSdEmTaCmlItemUpdateWithWhereUniqueWithoutCmlProductInput = {
  data: EndingSdEmTaCmlItemUpdateWithoutCmlProductInput;
  where: EndingSdEmTaCmlItemWhereUniqueInput;
};

export type EndingSdEmTaCmlItemUpdateWithWhereUniqueWithoutEndingSdEmTaInput = {
  data: EndingSdEmTaCmlItemUpdateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaCmlItemWhereUniqueInput;
};

export type EndingSdEmTaCmlItemUpdateWithoutCmlProductInput = {
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateOneRequiredWithoutEndingSdEmTaCmlItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EndingSdEmTaCmlItemUpdateWithoutEndingSdEmTaInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutEndingSdEmTaCmlItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EndingSdEmTaCmlItemUpsertWithWhereUniqueWithoutCmlProductInput = {
  create: EndingSdEmTaCmlItemCreateWithoutCmlProductInput;
  update: EndingSdEmTaCmlItemUpdateWithoutCmlProductInput;
  where: EndingSdEmTaCmlItemWhereUniqueInput;
};

export type EndingSdEmTaCmlItemUpsertWithWhereUniqueWithoutEndingSdEmTaInput = {
  create: EndingSdEmTaCmlItemCreateWithoutEndingSdEmTaInput;
  update: EndingSdEmTaCmlItemUpdateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaCmlItemWhereUniqueInput;
};

export type EndingSdEmTaCmlItemWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaCmlItemWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaCmlItemWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaCmlItemWhereInput>>;
  cmlProduct?: InputMaybe<CmlProductRelationFilter>;
  cmlProductId?: InputMaybe<IntFilter>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaRelationFilter>;
  endingSdEmTaId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type EndingSdEmTaCmlItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EndingSdEmTaCount = {
  __typename?: 'EndingSdEmTaCount';
  endingSdEmTaBeverageItems: Scalars['Int']['output'];
  endingSdEmTaCmlItems: Scalars['Int']['output'];
  endingSdEmTaPackagingItems: Scalars['Int']['output'];
  endingSdEmTaRawMaterialItems: Scalars['Int']['output'];
};

export type EndingSdEmTaCountAggregate = {
  __typename?: 'EndingSdEmTaCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  formType: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  notedBy: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
  receivedBy: Scalars['Int']['output'];
  remarks: Scalars['Int']['output'];
};

export type EndingSdEmTaCreateInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutEndingSdEmTaInput>;
  formType: FormType;
  notedBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutEndingSdEmTaInput;
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type EndingSdEmTaCreateManyOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  formType: FormType;
  id?: InputMaybe<Scalars['Int']['input']>;
  notedBy?: InputMaybe<Scalars['String']['input']>;
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type EndingSdEmTaCreateManyOutletInputEnvelope = {
  data: Array<EndingSdEmTaCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<EndingSdEmTaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<EndingSdEmTaCreateWithoutOutletInput>>;
  createMany?: InputMaybe<EndingSdEmTaCreateManyOutletInputEnvelope>;
};

export type EndingSdEmTaCreateNestedOneWithoutEndingSdEmTaBeverageItemsInput = {
  connect?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaBeverageItemsInput>;
  create?: InputMaybe<EndingSdEmTaCreateWithoutEndingSdEmTaBeverageItemsInput>;
};

export type EndingSdEmTaCreateNestedOneWithoutEndingSdEmTaCmlItemsInput = {
  connect?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaCmlItemsInput>;
  create?: InputMaybe<EndingSdEmTaCreateWithoutEndingSdEmTaCmlItemsInput>;
};

export type EndingSdEmTaCreateNestedOneWithoutEndingSdEmTaPackagingItemsInput = {
  connect?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaPackagingItemsInput>;
  create?: InputMaybe<EndingSdEmTaCreateWithoutEndingSdEmTaPackagingItemsInput>;
};

export type EndingSdEmTaCreateNestedOneWithoutEndingSdEmTaRawMaterialItemsInput = {
  connect?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaRawMaterialItemsInput>;
  create?: InputMaybe<EndingSdEmTaCreateWithoutEndingSdEmTaRawMaterialItemsInput>;
};

export type EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaBeverageItemsInput = {
  create: EndingSdEmTaCreateWithoutEndingSdEmTaBeverageItemsInput;
  where: EndingSdEmTaWhereUniqueInput;
};

export type EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaCmlItemsInput = {
  create: EndingSdEmTaCreateWithoutEndingSdEmTaCmlItemsInput;
  where: EndingSdEmTaWhereUniqueInput;
};

export type EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaPackagingItemsInput = {
  create: EndingSdEmTaCreateWithoutEndingSdEmTaPackagingItemsInput;
  where: EndingSdEmTaWhereUniqueInput;
};

export type EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaRawMaterialItemsInput = {
  create: EndingSdEmTaCreateWithoutEndingSdEmTaRawMaterialItemsInput;
  where: EndingSdEmTaWhereUniqueInput;
};

export type EndingSdEmTaCreateOrConnectWithoutOutletInput = {
  create: EndingSdEmTaCreateWithoutOutletInput;
  where: EndingSdEmTaWhereUniqueInput;
};

export type EndingSdEmTaCreateWithoutEndingSdEmTaBeverageItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutEndingSdEmTaInput>;
  formType: FormType;
  notedBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutEndingSdEmTaInput;
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type EndingSdEmTaCreateWithoutEndingSdEmTaCmlItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutEndingSdEmTaInput>;
  formType: FormType;
  notedBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutEndingSdEmTaInput;
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type EndingSdEmTaCreateWithoutEndingSdEmTaPackagingItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutEndingSdEmTaInput>;
  formType: FormType;
  notedBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutEndingSdEmTaInput;
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type EndingSdEmTaCreateWithoutEndingSdEmTaRawMaterialItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutEndingSdEmTaInput>;
  formType: FormType;
  notedBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutEndingSdEmTaInput;
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type EndingSdEmTaCreateWithoutOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutEndingSdEmTaInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutEndingSdEmTaInput>;
  formType: FormType;
  notedBy?: InputMaybe<Scalars['String']['input']>;
  receivedBy?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type EndingSdEmTaListRelationFilter = {
  every?: InputMaybe<EndingSdEmTaWhereInput>;
  none?: InputMaybe<EndingSdEmTaWhereInput>;
  some?: InputMaybe<EndingSdEmTaWhereInput>;
};

export type EndingSdEmTaMaxAggregate = {
  __typename?: 'EndingSdEmTaMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  formType?: Maybe<FormType>;
  id?: Maybe<Scalars['Int']['output']>;
  notedBy?: Maybe<Scalars['String']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type EndingSdEmTaMinAggregate = {
  __typename?: 'EndingSdEmTaMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  formType?: Maybe<FormType>;
  id?: Maybe<Scalars['Int']['output']>;
  notedBy?: Maybe<Scalars['String']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type EndingSdEmTaOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EndingSdEmTaOrderByWithRelationInput = {
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemOrderByRelationAggregateInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemOrderByRelationAggregateInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemOrderByRelationAggregateInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemOrderByRelationAggregateInput>;
  formType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  notedBy?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
  receivedBy?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
};

export type EndingSdEmTaPackagingItem = {
  __typename?: 'EndingSdEmTaPackagingItem';
  endingSdEmTa: EndingSdEmTa;
  endingSdEmTaId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type EndingSdEmTaPackagingItemCreateManyEndingSdEmTaInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type EndingSdEmTaPackagingItemCreateManyEndingSdEmTaInputEnvelope = {
  data: Array<EndingSdEmTaPackagingItemCreateManyEndingSdEmTaInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaPackagingItemCreateManyPackagingProductInput = {
  endingSdEmTaId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type EndingSdEmTaPackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<EndingSdEmTaPackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaPackagingItemCreateNestedManyWithoutEndingSdEmTaInput = {
  connect?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaPackagingItemCreateOrConnectWithoutEndingSdEmTaInput>>;
  create?: InputMaybe<Array<EndingSdEmTaPackagingItemCreateWithoutEndingSdEmTaInput>>;
  createMany?: InputMaybe<EndingSdEmTaPackagingItemCreateManyEndingSdEmTaInputEnvelope>;
};

export type EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<EndingSdEmTaPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<EndingSdEmTaPackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type EndingSdEmTaPackagingItemCreateOrConnectWithoutEndingSdEmTaInput = {
  create: EndingSdEmTaPackagingItemCreateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaPackagingItemWhereUniqueInput;
};

export type EndingSdEmTaPackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: EndingSdEmTaPackagingItemCreateWithoutPackagingProductInput;
  where: EndingSdEmTaPackagingItemWhereUniqueInput;
};

export type EndingSdEmTaPackagingItemCreateWithoutEndingSdEmTaInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutEndingSdEmTaPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type EndingSdEmTaPackagingItemCreateWithoutPackagingProductInput = {
  endingSdEmTa: EndingSdEmTaCreateNestedOneWithoutEndingSdEmTaPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type EndingSdEmTaPackagingItemListRelationFilter = {
  every?: InputMaybe<EndingSdEmTaPackagingItemWhereInput>;
  none?: InputMaybe<EndingSdEmTaPackagingItemWhereInput>;
  some?: InputMaybe<EndingSdEmTaPackagingItemWhereInput>;
};

export type EndingSdEmTaPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EndingSdEmTaPackagingItemOrderByWithRelationInput = {
  endingSdEmTa?: InputMaybe<EndingSdEmTaOrderByWithRelationInput>;
  endingSdEmTaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum EndingSdEmTaPackagingItemScalarFieldEnum {
  EndingSdEmTaId = 'endingSdEmTaId',
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity'
}

export type EndingSdEmTaPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaPackagingItemScalarWhereInput>>;
  endingSdEmTaId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type EndingSdEmTaPackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EndingSdEmTaPackagingItemUpdateManyWithWhereWithoutEndingSdEmTaInput = {
  data: EndingSdEmTaPackagingItemUpdateManyMutationInput;
  where: EndingSdEmTaPackagingItemScalarWhereInput;
};

export type EndingSdEmTaPackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: EndingSdEmTaPackagingItemUpdateManyMutationInput;
  where: EndingSdEmTaPackagingItemScalarWhereInput;
};

export type EndingSdEmTaPackagingItemUpdateManyWithoutEndingSdEmTaNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaPackagingItemCreateOrConnectWithoutEndingSdEmTaInput>>;
  create?: InputMaybe<Array<EndingSdEmTaPackagingItemCreateWithoutEndingSdEmTaInput>>;
  createMany?: InputMaybe<EndingSdEmTaPackagingItemCreateManyEndingSdEmTaInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaPackagingItemUpdateWithWhereUniqueWithoutEndingSdEmTaInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaPackagingItemUpdateManyWithWhereWithoutEndingSdEmTaInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaPackagingItemUpsertWithWhereUniqueWithoutEndingSdEmTaInput>>;
};

export type EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<EndingSdEmTaPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<EndingSdEmTaPackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaPackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type EndingSdEmTaPackagingItemUpdateWithWhereUniqueWithoutEndingSdEmTaInput = {
  data: EndingSdEmTaPackagingItemUpdateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaPackagingItemWhereUniqueInput;
};

export type EndingSdEmTaPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: EndingSdEmTaPackagingItemUpdateWithoutPackagingProductInput;
  where: EndingSdEmTaPackagingItemWhereUniqueInput;
};

export type EndingSdEmTaPackagingItemUpdateWithoutEndingSdEmTaInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutEndingSdEmTaPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EndingSdEmTaPackagingItemUpdateWithoutPackagingProductInput = {
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateOneRequiredWithoutEndingSdEmTaPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type EndingSdEmTaPackagingItemUpsertWithWhereUniqueWithoutEndingSdEmTaInput = {
  create: EndingSdEmTaPackagingItemCreateWithoutEndingSdEmTaInput;
  update: EndingSdEmTaPackagingItemUpdateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaPackagingItemWhereUniqueInput;
};

export type EndingSdEmTaPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: EndingSdEmTaPackagingItemCreateWithoutPackagingProductInput;
  update: EndingSdEmTaPackagingItemUpdateWithoutPackagingProductInput;
  where: EndingSdEmTaPackagingItemWhereUniqueInput;
};

export type EndingSdEmTaPackagingItemWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaPackagingItemWhereInput>>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaRelationFilter>;
  endingSdEmTaId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type EndingSdEmTaPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EndingSdEmTaRawMaterialItem = {
  __typename?: 'EndingSdEmTaRawMaterialItem';
  endingSdEmTa: EndingSdEmTa;
  endingSdEmTaId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
};

export type EndingSdEmTaRawMaterialItemCreateManyEndingSdEmTaInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type EndingSdEmTaRawMaterialItemCreateManyEndingSdEmTaInputEnvelope = {
  data: Array<EndingSdEmTaRawMaterialItemCreateManyEndingSdEmTaInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaRawMaterialItemCreateManyRawMaterialProductInput = {
  endingSdEmTaId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
};

export type EndingSdEmTaRawMaterialItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<EndingSdEmTaRawMaterialItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndingSdEmTaRawMaterialItemCreateNestedManyWithoutEndingSdEmTaInput = {
  connect?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaRawMaterialItemCreateOrConnectWithoutEndingSdEmTaInput>>;
  create?: InputMaybe<Array<EndingSdEmTaRawMaterialItemCreateWithoutEndingSdEmTaInput>>;
  createMany?: InputMaybe<EndingSdEmTaRawMaterialItemCreateManyEndingSdEmTaInputEnvelope>;
};

export type EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<EndingSdEmTaRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<EndingSdEmTaRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
};

export type EndingSdEmTaRawMaterialItemCreateOrConnectWithoutEndingSdEmTaInput = {
  create: EndingSdEmTaRawMaterialItemCreateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaRawMaterialItemWhereUniqueInput;
};

export type EndingSdEmTaRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: EndingSdEmTaRawMaterialItemCreateWithoutRawMaterialProductInput;
  where: EndingSdEmTaRawMaterialItemWhereUniqueInput;
};

export type EndingSdEmTaRawMaterialItemCreateWithoutEndingSdEmTaInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutEndingSdEmTaRawMaterialItemsInput;
};

export type EndingSdEmTaRawMaterialItemCreateWithoutRawMaterialProductInput = {
  endingSdEmTa: EndingSdEmTaCreateNestedOneWithoutEndingSdEmTaRawMaterialItemsInput;
  quantity: Scalars['Float']['input'];
};

export type EndingSdEmTaRawMaterialItemListRelationFilter = {
  every?: InputMaybe<EndingSdEmTaRawMaterialItemWhereInput>;
  none?: InputMaybe<EndingSdEmTaRawMaterialItemWhereInput>;
  some?: InputMaybe<EndingSdEmTaRawMaterialItemWhereInput>;
};

export type EndingSdEmTaRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EndingSdEmTaRawMaterialItemOrderByWithRelationInput = {
  endingSdEmTa?: InputMaybe<EndingSdEmTaOrderByWithRelationInput>;
  endingSdEmTaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
};

export enum EndingSdEmTaRawMaterialItemScalarFieldEnum {
  EndingSdEmTaId = 'endingSdEmTaId',
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId'
}

export type EndingSdEmTaRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaRawMaterialItemScalarWhereInput>>;
  endingSdEmTaId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type EndingSdEmTaRawMaterialItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type EndingSdEmTaRawMaterialItemUpdateManyWithWhereWithoutEndingSdEmTaInput = {
  data: EndingSdEmTaRawMaterialItemUpdateManyMutationInput;
  where: EndingSdEmTaRawMaterialItemScalarWhereInput;
};

export type EndingSdEmTaRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: EndingSdEmTaRawMaterialItemUpdateManyMutationInput;
  where: EndingSdEmTaRawMaterialItemScalarWhereInput;
};

export type EndingSdEmTaRawMaterialItemUpdateManyWithoutEndingSdEmTaNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaRawMaterialItemCreateOrConnectWithoutEndingSdEmTaInput>>;
  create?: InputMaybe<Array<EndingSdEmTaRawMaterialItemCreateWithoutEndingSdEmTaInput>>;
  createMany?: InputMaybe<EndingSdEmTaRawMaterialItemCreateManyEndingSdEmTaInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaRawMaterialItemUpdateWithWhereUniqueWithoutEndingSdEmTaInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaRawMaterialItemUpdateManyWithWhereWithoutEndingSdEmTaInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaRawMaterialItemUpsertWithWhereUniqueWithoutEndingSdEmTaInput>>;
};

export type EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<EndingSdEmTaRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<EndingSdEmTaRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type EndingSdEmTaRawMaterialItemUpdateWithWhereUniqueWithoutEndingSdEmTaInput = {
  data: EndingSdEmTaRawMaterialItemUpdateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaRawMaterialItemWhereUniqueInput;
};

export type EndingSdEmTaRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: EndingSdEmTaRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: EndingSdEmTaRawMaterialItemWhereUniqueInput;
};

export type EndingSdEmTaRawMaterialItemUpdateWithoutEndingSdEmTaInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutEndingSdEmTaRawMaterialItemsNestedInput>;
};

export type EndingSdEmTaRawMaterialItemUpdateWithoutRawMaterialProductInput = {
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateOneRequiredWithoutEndingSdEmTaRawMaterialItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type EndingSdEmTaRawMaterialItemUpsertWithWhereUniqueWithoutEndingSdEmTaInput = {
  create: EndingSdEmTaRawMaterialItemCreateWithoutEndingSdEmTaInput;
  update: EndingSdEmTaRawMaterialItemUpdateWithoutEndingSdEmTaInput;
  where: EndingSdEmTaRawMaterialItemWhereUniqueInput;
};

export type EndingSdEmTaRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: EndingSdEmTaRawMaterialItemCreateWithoutRawMaterialProductInput;
  update: EndingSdEmTaRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: EndingSdEmTaRawMaterialItemWhereUniqueInput;
};

export type EndingSdEmTaRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaRawMaterialItemWhereInput>>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaRelationFilter>;
  endingSdEmTaId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type EndingSdEmTaRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EndingSdEmTaRelationFilter = {
  is?: InputMaybe<EndingSdEmTaWhereInput>;
  isNot?: InputMaybe<EndingSdEmTaWhereInput>;
};

export enum EndingSdEmTaScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  FormType = 'formType',
  Id = 'id',
  NotedBy = 'notedBy',
  OutletId = 'outletId',
  ReceivedBy = 'receivedBy',
  Remarks = 'remarks'
}

export type EndingSdEmTaScalarWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaScalarWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaScalarWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaScalarWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  formType?: InputMaybe<EnumFormTypeFilter>;
  id?: InputMaybe<IntFilter>;
  notedBy?: InputMaybe<StringNullableFilter>;
  outletId?: InputMaybe<IntFilter>;
  receivedBy?: InputMaybe<StringNullableFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
};

export type EndingSdEmTaSumAggregate = {
  __typename?: 'EndingSdEmTaSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type EndingSdEmTaUpdateInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  formType?: InputMaybe<EnumFormTypeFieldUpdateOperationsInput>;
  notedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutEndingSdEmTaNestedInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EndingSdEmTaUpdateManyMutationInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  formType?: InputMaybe<EnumFormTypeFieldUpdateOperationsInput>;
  notedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EndingSdEmTaUpdateManyWithWhereWithoutOutletInput = {
  data: EndingSdEmTaUpdateManyMutationInput;
  where: EndingSdEmTaScalarWhereInput;
};

export type EndingSdEmTaUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<EndingSdEmTaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EndingSdEmTaCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<EndingSdEmTaCreateWithoutOutletInput>>;
  createMany?: InputMaybe<EndingSdEmTaCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<EndingSdEmTaWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EndingSdEmTaScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EndingSdEmTaWhereUniqueInput>>;
  set?: InputMaybe<Array<EndingSdEmTaWhereUniqueInput>>;
  update?: InputMaybe<Array<EndingSdEmTaUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<EndingSdEmTaUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<EndingSdEmTaUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type EndingSdEmTaUpdateOneRequiredWithoutEndingSdEmTaBeverageItemsNestedInput = {
  connect?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaBeverageItemsInput>;
  create?: InputMaybe<EndingSdEmTaCreateWithoutEndingSdEmTaBeverageItemsInput>;
  update?: InputMaybe<EndingSdEmTaUpdateWithoutEndingSdEmTaBeverageItemsInput>;
  upsert?: InputMaybe<EndingSdEmTaUpsertWithoutEndingSdEmTaBeverageItemsInput>;
};

export type EndingSdEmTaUpdateOneRequiredWithoutEndingSdEmTaCmlItemsNestedInput = {
  connect?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaCmlItemsInput>;
  create?: InputMaybe<EndingSdEmTaCreateWithoutEndingSdEmTaCmlItemsInput>;
  update?: InputMaybe<EndingSdEmTaUpdateWithoutEndingSdEmTaCmlItemsInput>;
  upsert?: InputMaybe<EndingSdEmTaUpsertWithoutEndingSdEmTaCmlItemsInput>;
};

export type EndingSdEmTaUpdateOneRequiredWithoutEndingSdEmTaPackagingItemsNestedInput = {
  connect?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaPackagingItemsInput>;
  create?: InputMaybe<EndingSdEmTaCreateWithoutEndingSdEmTaPackagingItemsInput>;
  update?: InputMaybe<EndingSdEmTaUpdateWithoutEndingSdEmTaPackagingItemsInput>;
  upsert?: InputMaybe<EndingSdEmTaUpsertWithoutEndingSdEmTaPackagingItemsInput>;
};

export type EndingSdEmTaUpdateOneRequiredWithoutEndingSdEmTaRawMaterialItemsNestedInput = {
  connect?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EndingSdEmTaCreateOrConnectWithoutEndingSdEmTaRawMaterialItemsInput>;
  create?: InputMaybe<EndingSdEmTaCreateWithoutEndingSdEmTaRawMaterialItemsInput>;
  update?: InputMaybe<EndingSdEmTaUpdateWithoutEndingSdEmTaRawMaterialItemsInput>;
  upsert?: InputMaybe<EndingSdEmTaUpsertWithoutEndingSdEmTaRawMaterialItemsInput>;
};

export type EndingSdEmTaUpdateWithWhereUniqueWithoutOutletInput = {
  data: EndingSdEmTaUpdateWithoutOutletInput;
  where: EndingSdEmTaWhereUniqueInput;
};

export type EndingSdEmTaUpdateWithoutEndingSdEmTaBeverageItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  formType?: InputMaybe<EnumFormTypeFieldUpdateOperationsInput>;
  notedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutEndingSdEmTaNestedInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EndingSdEmTaUpdateWithoutEndingSdEmTaCmlItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  formType?: InputMaybe<EnumFormTypeFieldUpdateOperationsInput>;
  notedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutEndingSdEmTaNestedInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EndingSdEmTaUpdateWithoutEndingSdEmTaPackagingItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  formType?: InputMaybe<EnumFormTypeFieldUpdateOperationsInput>;
  notedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutEndingSdEmTaNestedInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EndingSdEmTaUpdateWithoutEndingSdEmTaRawMaterialItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  formType?: InputMaybe<EnumFormTypeFieldUpdateOperationsInput>;
  notedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutEndingSdEmTaNestedInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EndingSdEmTaUpdateWithoutOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutEndingSdEmTaNestedInput>;
  formType?: InputMaybe<EnumFormTypeFieldUpdateOperationsInput>;
  notedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EndingSdEmTaUpsertWithWhereUniqueWithoutOutletInput = {
  create: EndingSdEmTaCreateWithoutOutletInput;
  update: EndingSdEmTaUpdateWithoutOutletInput;
  where: EndingSdEmTaWhereUniqueInput;
};

export type EndingSdEmTaUpsertWithoutEndingSdEmTaBeverageItemsInput = {
  create: EndingSdEmTaCreateWithoutEndingSdEmTaBeverageItemsInput;
  update: EndingSdEmTaUpdateWithoutEndingSdEmTaBeverageItemsInput;
};

export type EndingSdEmTaUpsertWithoutEndingSdEmTaCmlItemsInput = {
  create: EndingSdEmTaCreateWithoutEndingSdEmTaCmlItemsInput;
  update: EndingSdEmTaUpdateWithoutEndingSdEmTaCmlItemsInput;
};

export type EndingSdEmTaUpsertWithoutEndingSdEmTaPackagingItemsInput = {
  create: EndingSdEmTaCreateWithoutEndingSdEmTaPackagingItemsInput;
  update: EndingSdEmTaUpdateWithoutEndingSdEmTaPackagingItemsInput;
};

export type EndingSdEmTaUpsertWithoutEndingSdEmTaRawMaterialItemsInput = {
  create: EndingSdEmTaCreateWithoutEndingSdEmTaRawMaterialItemsInput;
  update: EndingSdEmTaUpdateWithoutEndingSdEmTaRawMaterialItemsInput;
};

export type EndingSdEmTaWhereInput = {
  AND?: InputMaybe<Array<EndingSdEmTaWhereInput>>;
  NOT?: InputMaybe<Array<EndingSdEmTaWhereInput>>;
  OR?: InputMaybe<Array<EndingSdEmTaWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  endingSdEmTaBeverageItems?: InputMaybe<EndingSdEmTaBeverageItemListRelationFilter>;
  endingSdEmTaCmlItems?: InputMaybe<EndingSdEmTaCmlItemListRelationFilter>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemListRelationFilter>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemListRelationFilter>;
  formType?: InputMaybe<EnumFormTypeFilter>;
  id?: InputMaybe<IntFilter>;
  notedBy?: InputMaybe<StringNullableFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
  receivedBy?: InputMaybe<StringNullableFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
};

export type EndingSdEmTaWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type EnumCmlThrowAwayLocationFieldUpdateOperationsInput = {
  set?: InputMaybe<CmlThrowAwayLocation>;
};

export type EnumCmlThrowAwayLocationFilter = {
  equals?: InputMaybe<CmlThrowAwayLocation>;
  in?: InputMaybe<Array<CmlThrowAwayLocation>>;
  not?: InputMaybe<NestedEnumCmlThrowAwayLocationFilter>;
  notIn?: InputMaybe<Array<CmlThrowAwayLocation>>;
};

export type EnumDeliveryShiftScheduleFieldUpdateOperationsInput = {
  set?: InputMaybe<DeliveryShiftSchedule>;
};

export type EnumDeliveryShiftScheduleFilter = {
  equals?: InputMaybe<DeliveryShiftSchedule>;
  in?: InputMaybe<Array<DeliveryShiftSchedule>>;
  not?: InputMaybe<NestedEnumDeliveryShiftScheduleFilter>;
  notIn?: InputMaybe<Array<DeliveryShiftSchedule>>;
};

export type EnumFormTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<FormType>;
};

export type EnumFormTypeFilter = {
  equals?: InputMaybe<FormType>;
  in?: InputMaybe<Array<FormType>>;
  not?: InputMaybe<NestedEnumFormTypeFilter>;
  notIn?: InputMaybe<Array<FormType>>;
};

export type EnumNumberOfShiftsFieldUpdateOperationsInput = {
  set?: InputMaybe<NumberOfShifts>;
};

export type EnumNumberOfShiftsFilter = {
  equals?: InputMaybe<NumberOfShifts>;
  in?: InputMaybe<Array<NumberOfShifts>>;
  not?: InputMaybe<NestedEnumNumberOfShiftsFilter>;
  notIn?: InputMaybe<Array<NumberOfShifts>>;
};

export type EnumPackagingCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<PackagingCategory>;
};

export type EnumPackagingCategoryFilter = {
  equals?: InputMaybe<PackagingCategory>;
  in?: InputMaybe<Array<PackagingCategory>>;
  not?: InputMaybe<NestedEnumPackagingCategoryFilter>;
  notIn?: InputMaybe<Array<PackagingCategory>>;
};

export type EnumProductCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductCategory>;
};

export type EnumProductCategoryFilter = {
  equals?: InputMaybe<ProductCategory>;
  in?: InputMaybe<Array<ProductCategory>>;
  not?: InputMaybe<NestedEnumProductCategoryFilter>;
  notIn?: InputMaybe<Array<ProductCategory>>;
};

export type EnumRawMaterialCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<RawMaterialCategory>;
};

export type EnumRawMaterialCategoryFilter = {
  equals?: InputMaybe<RawMaterialCategory>;
  in?: InputMaybe<Array<RawMaterialCategory>>;
  not?: InputMaybe<NestedEnumRawMaterialCategoryFilter>;
  notIn?: InputMaybe<Array<RawMaterialCategory>>;
};

export type EnumRawMaterialFinishingEndingLocationFieldUpdateOperationsInput = {
  set?: InputMaybe<RawMaterialFinishingEndingLocation>;
};

export type EnumRawMaterialFinishingEndingLocationFilter = {
  equals?: InputMaybe<RawMaterialFinishingEndingLocation>;
  in?: InputMaybe<Array<RawMaterialFinishingEndingLocation>>;
  not?: InputMaybe<NestedEnumRawMaterialFinishingEndingLocationFilter>;
  notIn?: InputMaybe<Array<RawMaterialFinishingEndingLocation>>;
};

export type EnumSalesCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<SalesCategory>;
};

export type EnumSalesCategoryFilter = {
  equals?: InputMaybe<SalesCategory>;
  in?: InputMaybe<Array<SalesCategory>>;
  not?: InputMaybe<NestedEnumSalesCategoryFilter>;
  notIn?: InputMaybe<Array<SalesCategory>>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<Status>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumTransferDirectionFieldUpdateOperationsInput = {
  set?: InputMaybe<TransferDirection>;
};

export type EnumTransferDirectionFilter = {
  equals?: InputMaybe<TransferDirection>;
  in?: InputMaybe<Array<TransferDirection>>;
  not?: InputMaybe<NestedEnumTransferDirectionFilter>;
  notIn?: InputMaybe<Array<TransferDirection>>;
};

export type EnumUserGroupFieldUpdateOperationsInput = {
  set?: InputMaybe<UserGroup>;
};

export type EnumUserGroupFilter = {
  equals?: InputMaybe<UserGroup>;
  in?: InputMaybe<Array<UserGroup>>;
  not?: InputMaybe<NestedEnumUserGroupFilter>;
  notIn?: InputMaybe<Array<UserGroup>>;
};

export type EnumUserGroupNullableListFilter = {
  equals?: InputMaybe<Array<UserGroup>>;
  has?: InputMaybe<UserGroup>;
  hasEvery?: InputMaybe<Array<UserGroup>>;
  hasSome?: InputMaybe<Array<UserGroup>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export { FormType };

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type MonthlyOutletPackagingInventory = {
  __typename?: 'MonthlyOutletPackagingInventory';
  date: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  outlet: Outlet;
  outletId: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type MonthlyOutletPackagingInventoryAvgAggregate = {
  __typename?: 'MonthlyOutletPackagingInventoryAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
  packagingProductId?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type MonthlyOutletPackagingInventoryCountAggregate = {
  __typename?: 'MonthlyOutletPackagingInventoryCountAggregate';
  _all: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type MonthlyOutletPackagingInventoryCreateInput = {
  date: Scalars['DateTime']['input'];
  outlet: OutletCreateNestedOneWithoutMonthlyOutletPackagingInventoriesInput;
  packagingProduct: PackagingProductCreateNestedOneWithoutMonthlyOutletPackagingInventoriesInput;
  quantity: Scalars['Int']['input'];
};

export type MonthlyOutletPackagingInventoryCreateManyOutletInput = {
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type MonthlyOutletPackagingInventoryCreateManyOutletInputEnvelope = {
  data: Array<MonthlyOutletPackagingInventoryCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MonthlyOutletPackagingInventoryCreateManyPackagingProductInput = {
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  outletId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type MonthlyOutletPackagingInventoryCreateManyPackagingProductInputEnvelope = {
  data: Array<MonthlyOutletPackagingInventoryCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MonthlyOutletPackagingInventoryCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<MonthlyOutletPackagingInventoryCreateWithoutOutletInput>>;
  createMany?: InputMaybe<MonthlyOutletPackagingInventoryCreateManyOutletInputEnvelope>;
};

export type MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MonthlyOutletPackagingInventoryCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<MonthlyOutletPackagingInventoryCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<MonthlyOutletPackagingInventoryCreateManyPackagingProductInputEnvelope>;
};

export type MonthlyOutletPackagingInventoryCreateOrConnectWithoutOutletInput = {
  create: MonthlyOutletPackagingInventoryCreateWithoutOutletInput;
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
};

export type MonthlyOutletPackagingInventoryCreateOrConnectWithoutPackagingProductInput = {
  create: MonthlyOutletPackagingInventoryCreateWithoutPackagingProductInput;
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
};

export type MonthlyOutletPackagingInventoryCreateWithoutOutletInput = {
  date: Scalars['DateTime']['input'];
  packagingProduct: PackagingProductCreateNestedOneWithoutMonthlyOutletPackagingInventoriesInput;
  quantity: Scalars['Int']['input'];
};

export type MonthlyOutletPackagingInventoryCreateWithoutPackagingProductInput = {
  date: Scalars['DateTime']['input'];
  outlet: OutletCreateNestedOneWithoutMonthlyOutletPackagingInventoriesInput;
  quantity: Scalars['Int']['input'];
};

export type MonthlyOutletPackagingInventoryListRelationFilter = {
  every?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
  none?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
  some?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
};

export type MonthlyOutletPackagingInventoryMaxAggregate = {
  __typename?: 'MonthlyOutletPackagingInventoryMaxAggregate';
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type MonthlyOutletPackagingInventoryMinAggregate = {
  __typename?: 'MonthlyOutletPackagingInventoryMinAggregate';
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type MonthlyOutletPackagingInventoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MonthlyOutletPackagingInventoryOrderByWithRelationInput = {
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum MonthlyOutletPackagingInventoryScalarFieldEnum {
  Date = 'date',
  Id = 'id',
  OutletId = 'outletId',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity'
}

export type MonthlyOutletPackagingInventoryScalarWhereInput = {
  AND?: InputMaybe<Array<MonthlyOutletPackagingInventoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<MonthlyOutletPackagingInventoryScalarWhereInput>>;
  OR?: InputMaybe<Array<MonthlyOutletPackagingInventoryScalarWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  outletId?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type MonthlyOutletPackagingInventorySumAggregate = {
  __typename?: 'MonthlyOutletPackagingInventorySumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type MonthlyOutletPackagingInventoryUpdateInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutMonthlyOutletPackagingInventoriesNestedInput>;
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutMonthlyOutletPackagingInventoriesNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type MonthlyOutletPackagingInventoryUpdateManyMutationInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type MonthlyOutletPackagingInventoryUpdateManyWithWhereWithoutOutletInput = {
  data: MonthlyOutletPackagingInventoryUpdateManyMutationInput;
  where: MonthlyOutletPackagingInventoryScalarWhereInput;
};

export type MonthlyOutletPackagingInventoryUpdateManyWithWhereWithoutPackagingProductInput = {
  data: MonthlyOutletPackagingInventoryUpdateManyMutationInput;
  where: MonthlyOutletPackagingInventoryScalarWhereInput;
};

export type MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MonthlyOutletPackagingInventoryCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<MonthlyOutletPackagingInventoryCreateWithoutOutletInput>>;
  createMany?: InputMaybe<MonthlyOutletPackagingInventoryCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MonthlyOutletPackagingInventoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  set?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  update?: InputMaybe<Array<MonthlyOutletPackagingInventoryUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<MonthlyOutletPackagingInventoryUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<MonthlyOutletPackagingInventoryUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MonthlyOutletPackagingInventoryCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<MonthlyOutletPackagingInventoryCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<MonthlyOutletPackagingInventoryCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MonthlyOutletPackagingInventoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  set?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
  update?: InputMaybe<Array<MonthlyOutletPackagingInventoryUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<MonthlyOutletPackagingInventoryUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<MonthlyOutletPackagingInventoryUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type MonthlyOutletPackagingInventoryUpdateWithWhereUniqueWithoutOutletInput = {
  data: MonthlyOutletPackagingInventoryUpdateWithoutOutletInput;
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
};

export type MonthlyOutletPackagingInventoryUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: MonthlyOutletPackagingInventoryUpdateWithoutPackagingProductInput;
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
};

export type MonthlyOutletPackagingInventoryUpdateWithoutOutletInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutMonthlyOutletPackagingInventoriesNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type MonthlyOutletPackagingInventoryUpdateWithoutPackagingProductInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutMonthlyOutletPackagingInventoriesNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type MonthlyOutletPackagingInventoryUpsertWithWhereUniqueWithoutOutletInput = {
  create: MonthlyOutletPackagingInventoryCreateWithoutOutletInput;
  update: MonthlyOutletPackagingInventoryUpdateWithoutOutletInput;
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
};

export type MonthlyOutletPackagingInventoryUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: MonthlyOutletPackagingInventoryCreateWithoutPackagingProductInput;
  update: MonthlyOutletPackagingInventoryUpdateWithoutPackagingProductInput;
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
};

export type MonthlyOutletPackagingInventoryWhereInput = {
  AND?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereInput>>;
  NOT?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereInput>>;
  OR?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type MonthlyOutletPackagingInventoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBetweenEntityTransfer: BetweenEntityTransfer;
  createBeverage: Beverage;
  createCheatSheet: CheatSheet;
  createCmlProduct: CmlProduct;
  createCmlProductRecipe: CmlProductRecipe;
  createCmlThrowAway: CmlThrowAway;
  createCrt: Crt;
  createDeliveryReceipt: DeliveryReceipt;
  createDonutMixAndCut: DonutMixAndCut;
  createEndingSdEmTa: EndingSdEmTa;
  createManyMonthlyOutletPackagingInventory: AffectedRowsOutput;
  createMonthlyOutletPackagingInventory: MonthlyOutletPackagingInventory;
  createOutlet: Outlet;
  createOutletFinishing: OutletFinishing;
  createPackaging: Packaging;
  createPackagingRequest: PackagingRequest;
  createProjection: Projection;
  createPurchaseOrder: PurchaseOrder;
  createRawMaterial: RawMaterial;
  createRawMaterialFinishingEnding: RawMaterialFinishingEnding;
  createRawMaterialProduct: RawMaterialProduct;
  createRawMaterialProductRecipe: RawMaterialProductRecipe;
  createRawMaterialRelease: RawMaterialRelease;
  createRawMaterialRequest: RawMaterialRequest;
  createReceivedDeliveryReceipt: ReceivedDeliveryReceipt;
  createReceivedSdsDeliveryReceipt: ReceivedSdsDeliveryReceipt;
  createReceivedTransfer: ReceivedTransfer;
  createSdsDeliveryReceipt: SdsDeliveryReceipt;
  createTransfer: Transfer;
  createUnit: Unit;
  createUser: User;
  createWarehouseInventory: WarehouseInventory;
  deleteManyBetweenEntityTransfer: Array<BetweenEntityTransfer>;
  deleteManyBeverage: Array<Beverage>;
  deleteManyCheatSheet: Array<CheatSheet>;
  deleteManyCmlProduct: Array<CmlProduct>;
  deleteManyCmlProductRecipe: Array<CmlProductRecipe>;
  deleteManyCmlThrowAway: Array<CmlThrowAway>;
  deleteManyCrt: Array<Crt>;
  deleteManyDeliveryReceipt: Array<DeliveryReceipt>;
  deleteManyDonutMixAndCut: Array<DonutMixAndCut>;
  deleteManyEndingSdEmTa: Array<EndingSdEmTa>;
  deleteManyMonthlyOutletPackagingInventory: Array<MonthlyOutletPackagingInventory>;
  deleteManyOutlet: Array<Outlet>;
  deleteManyOutletFinishing: Array<OutletFinishing>;
  deleteManyPackaging: Array<Packaging>;
  deleteManyPackagingRequest: Array<PackagingRequest>;
  deleteManyProjection: Array<Projection>;
  deleteManyPurchaseOrder: Array<PurchaseOrder>;
  deleteManyRawMaterial: Array<RawMaterial>;
  deleteManyRawMaterialFinishingEnding: Array<RawMaterialFinishingEnding>;
  deleteManyRawMaterialProduct: Array<RawMaterialProduct>;
  deleteManyRawMaterialProductRecipe: Array<RawMaterialProductRecipe>;
  deleteManyRawMaterialRelease: Array<RawMaterialRelease>;
  deleteManyRawMaterialRequest: Array<RawMaterialRequest>;
  deleteManyReceivedDeliveryReceipt: Array<ReceivedDeliveryReceipt>;
  deleteManyReceivedSdsDeliveryReceipt: Array<ReceivedSdsDeliveryReceipt>;
  deleteManyReceivedTransfer: Array<ReceivedTransfer>;
  deleteManySdsDeliveryReceipt: Array<SdsDeliveryReceipt>;
  deleteManyTransfer: Array<Transfer>;
  deleteManyUnit: Array<Unit>;
  deleteManyUser: Array<User>;
  deleteManyWarehouseInventory: Array<WarehouseInventory>;
  signin: User;
  signout: Scalars['String']['output'];
  updateBetweenEntityTransfer: BetweenEntityTransfer;
  updateBeverage: Beverage;
  updateCheatSheet: CheatSheet;
  updateCmlProduct: CmlProduct;
  updateCmlProductRecipe: CmlProductRecipe;
  updateCmlThrowAway: CmlThrowAway;
  updateCrt: Crt;
  updateDeliveryReceipt: DeliveryReceipt;
  updateDonutMixAndCut: DonutMixAndCut;
  updateEndingSdEmTa: EndingSdEmTa;
  updateMonthlyOutletPackagingInventory: MonthlyOutletPackagingInventory;
  updateOutlet: Outlet;
  updateOutletFinishing: OutletFinishing;
  updatePackaging: Packaging;
  updatePackagingRequest: PackagingRequest;
  updateProjection: Projection;
  updatePurchaseOrder: PurchaseOrder;
  updateRawMaterial: RawMaterial;
  updateRawMaterialFinishingEnding: RawMaterialFinishingEnding;
  updateRawMaterialProduct: RawMaterialProduct;
  updateRawMaterialProductRecipe: RawMaterialProductRecipe;
  updateRawMaterialRelease: RawMaterialRelease;
  updateRawMaterialRequest: RawMaterialRequest;
  updateReceivedDeliveryReceipt: ReceivedDeliveryReceipt;
  updateReceivedSdsDeliveryReceipt: ReceivedSdsDeliveryReceipt;
  updateReceivedTransfer: ReceivedTransfer;
  updateSdsDeliveryReceipt: SdsDeliveryReceipt;
  updateTransfer: Transfer;
  updateUnit: Unit;
  updateUser: User;
  updateWarehouseInventory: WarehouseInventory;
};


export type MutationCreateBetweenEntityTransferArgs = {
  data: BetweenEntityTransferCreateInput;
};


export type MutationCreateBeverageArgs = {
  data: BeverageCreateInput;
};


export type MutationCreateCheatSheetArgs = {
  data: CheatSheetCreateInput;
};


export type MutationCreateCmlProductArgs = {
  data: CmlProductCreateInput;
};


export type MutationCreateCmlProductRecipeArgs = {
  data: CmlProductRecipeCreateInput;
};


export type MutationCreateCmlThrowAwayArgs = {
  data: CmlThrowAwayCreateInput;
};


export type MutationCreateCrtArgs = {
  data: CrtCreateInput;
};


export type MutationCreateDeliveryReceiptArgs = {
  data: DeliveryReceiptCreateInput;
};


export type MutationCreateDonutMixAndCutArgs = {
  data: DonutMixAndCutCreateInput;
};


export type MutationCreateEndingSdEmTaArgs = {
  data: EndingSdEmTaCreateInput;
};


export type MutationCreateManyMonthlyOutletPackagingInventoryArgs = {
  data: Array<MonthlyOutletPackagingInventoryCreateInput>;
};


export type MutationCreateMonthlyOutletPackagingInventoryArgs = {
  data: MonthlyOutletPackagingInventoryCreateInput;
};


export type MutationCreateOutletArgs = {
  data: OutletCreateInput;
};


export type MutationCreateOutletFinishingArgs = {
  data: OutletFinishingCreateInput;
};


export type MutationCreatePackagingArgs = {
  data: PackagingCreateInput;
};


export type MutationCreatePackagingRequestArgs = {
  data: PackagingRequestCreateInput;
};


export type MutationCreateProjectionArgs = {
  data: ProjectionCreateInput;
};


export type MutationCreatePurchaseOrderArgs = {
  data: PurchaseOrderCreateInput;
};


export type MutationCreateRawMaterialArgs = {
  data: RawMaterialCreateInput;
};


export type MutationCreateRawMaterialFinishingEndingArgs = {
  data: RawMaterialFinishingEndingCreateInput;
};


export type MutationCreateRawMaterialProductArgs = {
  data: RawMaterialProductCreateInput;
};


export type MutationCreateRawMaterialProductRecipeArgs = {
  data: RawMaterialProductRecipeCreateInput;
};


export type MutationCreateRawMaterialReleaseArgs = {
  data: RawMaterialReleaseCreateInput;
};


export type MutationCreateRawMaterialRequestArgs = {
  data: RawMaterialRequestCreateInput;
};


export type MutationCreateReceivedDeliveryReceiptArgs = {
  data: ReceivedDeliveryReceiptCreateInput;
};


export type MutationCreateReceivedSdsDeliveryReceiptArgs = {
  data: ReceivedSdsDeliveryReceiptCreateInput;
};


export type MutationCreateReceivedTransferArgs = {
  data: ReceivedTransferCreateInput;
};


export type MutationCreateSdsDeliveryReceiptArgs = {
  data: SdsDeliveryReceiptCreateInput;
};


export type MutationCreateTransferArgs = {
  data: TransferCreateInput;
};


export type MutationCreateUnitArgs = {
  data: UnitCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateWarehouseInventoryArgs = {
  data: WarehouseInventoryCreateInput;
};


export type MutationDeleteManyBetweenEntityTransferArgs = {
  where?: InputMaybe<Array<BetweenEntityTransferWhereUniqueInput>>;
};


export type MutationDeleteManyBeverageArgs = {
  where?: InputMaybe<Array<BeverageWhereUniqueInput>>;
};


export type MutationDeleteManyCheatSheetArgs = {
  where?: InputMaybe<Array<CheatSheetWhereUniqueInput>>;
};


export type MutationDeleteManyCmlProductArgs = {
  where?: InputMaybe<Array<CmlProductWhereUniqueInput>>;
};


export type MutationDeleteManyCmlProductRecipeArgs = {
  where?: InputMaybe<Array<CmlProductRecipeWhereUniqueInput>>;
};


export type MutationDeleteManyCmlThrowAwayArgs = {
  where?: InputMaybe<Array<CmlThrowAwayWhereUniqueInput>>;
};


export type MutationDeleteManyCrtArgs = {
  where?: InputMaybe<Array<CrtWhereUniqueInput>>;
};


export type MutationDeleteManyDeliveryReceiptArgs = {
  where?: InputMaybe<Array<DeliveryReceiptWhereUniqueInput>>;
};


export type MutationDeleteManyDonutMixAndCutArgs = {
  where?: InputMaybe<Array<DonutMixAndCutWhereUniqueInput>>;
};


export type MutationDeleteManyEndingSdEmTaArgs = {
  where?: InputMaybe<Array<EndingSdEmTaWhereUniqueInput>>;
};


export type MutationDeleteManyMonthlyOutletPackagingInventoryArgs = {
  where?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput>>;
};


export type MutationDeleteManyOutletArgs = {
  where?: InputMaybe<Array<OutletWhereUniqueInput>>;
};


export type MutationDeleteManyOutletFinishingArgs = {
  where?: InputMaybe<Array<OutletFinishingWhereUniqueInput>>;
};


export type MutationDeleteManyPackagingArgs = {
  where?: InputMaybe<Array<PackagingWhereUniqueInput>>;
};


export type MutationDeleteManyPackagingRequestArgs = {
  where?: InputMaybe<Array<PackagingRequestWhereUniqueInput>>;
};


export type MutationDeleteManyProjectionArgs = {
  where?: InputMaybe<Array<ProjectionWhereUniqueInput>>;
};


export type MutationDeleteManyPurchaseOrderArgs = {
  where?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
};


export type MutationDeleteManyRawMaterialArgs = {
  where?: InputMaybe<Array<RawMaterialWhereUniqueInput>>;
};


export type MutationDeleteManyRawMaterialFinishingEndingArgs = {
  where?: InputMaybe<Array<RawMaterialFinishingEndingWhereUniqueInput>>;
};


export type MutationDeleteManyRawMaterialProductArgs = {
  where?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
};


export type MutationDeleteManyRawMaterialProductRecipeArgs = {
  where?: InputMaybe<Array<RawMaterialProductRecipeWhereUniqueInput>>;
};


export type MutationDeleteManyRawMaterialReleaseArgs = {
  where?: InputMaybe<Array<RawMaterialReleaseWhereUniqueInput>>;
};


export type MutationDeleteManyRawMaterialRequestArgs = {
  where?: InputMaybe<Array<RawMaterialRequestWhereUniqueInput>>;
};


export type MutationDeleteManyReceivedDeliveryReceiptArgs = {
  where?: InputMaybe<Array<ReceivedDeliveryReceiptWhereUniqueInput>>;
};


export type MutationDeleteManyReceivedSdsDeliveryReceiptArgs = {
  where?: InputMaybe<Array<ReceivedSdsDeliveryReceiptWhereUniqueInput>>;
};


export type MutationDeleteManyReceivedTransferArgs = {
  where?: InputMaybe<Array<ReceivedTransferWhereUniqueInput>>;
};


export type MutationDeleteManySdsDeliveryReceiptArgs = {
  where?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
};


export type MutationDeleteManyTransferArgs = {
  where?: InputMaybe<Array<TransferWhereUniqueInput>>;
};


export type MutationDeleteManyUnitArgs = {
  where?: InputMaybe<Array<UnitWhereUniqueInput>>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<Array<UserWhereUniqueInput>>;
};


export type MutationDeleteManyWarehouseInventoryArgs = {
  where?: InputMaybe<Array<WarehouseInventoryWhereUniqueInput>>;
};


export type MutationSigninArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUpdateBetweenEntityTransferArgs = {
  data: BetweenEntityTransferUpdateInput;
  where: BetweenEntityTransferWhereUniqueInput;
};


export type MutationUpdateBeverageArgs = {
  data: BeverageUpdateInput;
  where: BeverageWhereUniqueInput;
};


export type MutationUpdateCheatSheetArgs = {
  data: CheatSheetUpdateInput;
  where: CheatSheetWhereUniqueInput;
};


export type MutationUpdateCmlProductArgs = {
  data: CmlProductUpdateInput;
  where: CmlProductWhereUniqueInput;
};


export type MutationUpdateCmlProductRecipeArgs = {
  data: CmlProductRecipeUpdateInput;
  where: CmlProductRecipeWhereUniqueInput;
};


export type MutationUpdateCmlThrowAwayArgs = {
  data: CmlThrowAwayUpdateInput;
  where: CmlThrowAwayWhereUniqueInput;
};


export type MutationUpdateCrtArgs = {
  data: CrtUpdateInput;
  where: CrtWhereUniqueInput;
};


export type MutationUpdateDeliveryReceiptArgs = {
  data: DeliveryReceiptUpdateInput;
  where: DeliveryReceiptWhereUniqueInput;
};


export type MutationUpdateDonutMixAndCutArgs = {
  data: DonutMixAndCutUpdateInput;
  where: DonutMixAndCutWhereUniqueInput;
};


export type MutationUpdateEndingSdEmTaArgs = {
  data: EndingSdEmTaUpdateInput;
  where: EndingSdEmTaWhereUniqueInput;
};


export type MutationUpdateMonthlyOutletPackagingInventoryArgs = {
  data: MonthlyOutletPackagingInventoryUpdateInput;
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
};


export type MutationUpdateOutletArgs = {
  data: OutletUpdateInput;
  where: OutletWhereUniqueInput;
};


export type MutationUpdateOutletFinishingArgs = {
  data: OutletFinishingUpdateInput;
  where: OutletFinishingWhereUniqueInput;
};


export type MutationUpdatePackagingArgs = {
  data: PackagingUpdateInput;
  where: PackagingWhereUniqueInput;
};


export type MutationUpdatePackagingRequestArgs = {
  data: PackagingRequestUpdateInput;
  where: PackagingRequestWhereUniqueInput;
};


export type MutationUpdateProjectionArgs = {
  data: ProjectionUpdateInput;
  where: ProjectionWhereUniqueInput;
};


export type MutationUpdatePurchaseOrderArgs = {
  data: PurchaseOrderUpdateInput;
  where: PurchaseOrderWhereUniqueInput;
};


export type MutationUpdateRawMaterialArgs = {
  data: RawMaterialUpdateInput;
  where: RawMaterialWhereUniqueInput;
};


export type MutationUpdateRawMaterialFinishingEndingArgs = {
  data: RawMaterialFinishingEndingUpdateInput;
  where: RawMaterialFinishingEndingWhereUniqueInput;
};


export type MutationUpdateRawMaterialProductArgs = {
  data: RawMaterialProductUpdateInput;
  where: RawMaterialProductWhereUniqueInput;
};


export type MutationUpdateRawMaterialProductRecipeArgs = {
  data: RawMaterialProductRecipeUpdateInput;
  where: RawMaterialProductRecipeWhereUniqueInput;
};


export type MutationUpdateRawMaterialReleaseArgs = {
  data: RawMaterialReleaseUpdateInput;
  where: RawMaterialReleaseWhereUniqueInput;
};


export type MutationUpdateRawMaterialRequestArgs = {
  data: RawMaterialRequestUpdateInput;
  where: RawMaterialRequestWhereUniqueInput;
};


export type MutationUpdateReceivedDeliveryReceiptArgs = {
  data: ReceivedDeliveryReceiptUpdateInput;
  where: ReceivedDeliveryReceiptWhereUniqueInput;
};


export type MutationUpdateReceivedSdsDeliveryReceiptArgs = {
  data: ReceivedSdsDeliveryReceiptUpdateInput;
  where: ReceivedSdsDeliveryReceiptWhereUniqueInput;
};


export type MutationUpdateReceivedTransferArgs = {
  data: ReceivedTransferUpdateInput;
  where: ReceivedTransferWhereUniqueInput;
};


export type MutationUpdateSdsDeliveryReceiptArgs = {
  data: SdsDeliveryReceiptUpdateInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};


export type MutationUpdateTransferArgs = {
  data: TransferUpdateInput;
  where: TransferWhereUniqueInput;
};


export type MutationUpdateUnitArgs = {
  data: UnitUpdateInput;
  where: UnitWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateWarehouseInventoryArgs = {
  data: WarehouseInventoryUpdateInput;
  where: WarehouseInventoryWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumCmlThrowAwayLocationFilter = {
  equals?: InputMaybe<CmlThrowAwayLocation>;
  in?: InputMaybe<Array<CmlThrowAwayLocation>>;
  not?: InputMaybe<NestedEnumCmlThrowAwayLocationFilter>;
  notIn?: InputMaybe<Array<CmlThrowAwayLocation>>;
};

export type NestedEnumDeliveryShiftScheduleFilter = {
  equals?: InputMaybe<DeliveryShiftSchedule>;
  in?: InputMaybe<Array<DeliveryShiftSchedule>>;
  not?: InputMaybe<NestedEnumDeliveryShiftScheduleFilter>;
  notIn?: InputMaybe<Array<DeliveryShiftSchedule>>;
};

export type NestedEnumFormTypeFilter = {
  equals?: InputMaybe<FormType>;
  in?: InputMaybe<Array<FormType>>;
  not?: InputMaybe<NestedEnumFormTypeFilter>;
  notIn?: InputMaybe<Array<FormType>>;
};

export type NestedEnumNumberOfShiftsFilter = {
  equals?: InputMaybe<NumberOfShifts>;
  in?: InputMaybe<Array<NumberOfShifts>>;
  not?: InputMaybe<NestedEnumNumberOfShiftsFilter>;
  notIn?: InputMaybe<Array<NumberOfShifts>>;
};

export type NestedEnumPackagingCategoryFilter = {
  equals?: InputMaybe<PackagingCategory>;
  in?: InputMaybe<Array<PackagingCategory>>;
  not?: InputMaybe<NestedEnumPackagingCategoryFilter>;
  notIn?: InputMaybe<Array<PackagingCategory>>;
};

export type NestedEnumProductCategoryFilter = {
  equals?: InputMaybe<ProductCategory>;
  in?: InputMaybe<Array<ProductCategory>>;
  not?: InputMaybe<NestedEnumProductCategoryFilter>;
  notIn?: InputMaybe<Array<ProductCategory>>;
};

export type NestedEnumRawMaterialCategoryFilter = {
  equals?: InputMaybe<RawMaterialCategory>;
  in?: InputMaybe<Array<RawMaterialCategory>>;
  not?: InputMaybe<NestedEnumRawMaterialCategoryFilter>;
  notIn?: InputMaybe<Array<RawMaterialCategory>>;
};

export type NestedEnumRawMaterialFinishingEndingLocationFilter = {
  equals?: InputMaybe<RawMaterialFinishingEndingLocation>;
  in?: InputMaybe<Array<RawMaterialFinishingEndingLocation>>;
  not?: InputMaybe<NestedEnumRawMaterialFinishingEndingLocationFilter>;
  notIn?: InputMaybe<Array<RawMaterialFinishingEndingLocation>>;
};

export type NestedEnumSalesCategoryFilter = {
  equals?: InputMaybe<SalesCategory>;
  in?: InputMaybe<Array<SalesCategory>>;
  not?: InputMaybe<NestedEnumSalesCategoryFilter>;
  notIn?: InputMaybe<Array<SalesCategory>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumTransferDirectionFilter = {
  equals?: InputMaybe<TransferDirection>;
  in?: InputMaybe<Array<TransferDirection>>;
  not?: InputMaybe<NestedEnumTransferDirectionFilter>;
  notIn?: InputMaybe<Array<TransferDirection>>;
};

export type NestedEnumUserGroupFilter = {
  equals?: InputMaybe<UserGroup>;
  in?: InputMaybe<Array<UserGroup>>;
  not?: InputMaybe<NestedEnumUserGroupFilter>;
  notIn?: InputMaybe<Array<UserGroup>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export { NumberOfShifts };

export type Outlet = {
  __typename?: 'Outlet';
  _count?: Maybe<OutletCount>;
  crts: Array<Crt>;
  deliveryReceipts: Array<DeliveryReceipt>;
  endingSdEmTa: Array<EndingSdEmTa>;
  id: Scalars['Int']['output'];
  monthlyOutletPackagingInventories: Array<MonthlyOutletPackagingInventory>;
  name: Scalars['String']['output'];
  numberOfShifts: NumberOfShifts;
  outletFinishings: Array<OutletFinishing>;
  packagingRequests: Array<PackagingRequest>;
  projections: Array<Projection>;
  rawMaterialFinishingEndings: Array<RawMaterialFinishingEnding>;
  rawMaterialRequests: Array<RawMaterialRequest>;
  sdsDeliveryReceipts: Array<SdsDeliveryReceipt>;
  status: Status;
  transferFromOutlets: Array<Transfer>;
  transferToOutlets: Array<Transfer>;
  users: Array<User>;
};


export type OutletCrtsArgs = {
  cursor?: InputMaybe<CrtWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtWhereInput>;
};


export type OutletDeliveryReceiptsArgs = {
  cursor?: InputMaybe<DeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptWhereInput>;
};


export type OutletEndingSdEmTaArgs = {
  cursor?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaWhereInput>;
};


export type OutletMonthlyOutletPackagingInventoriesArgs = {
  cursor?: InputMaybe<MonthlyOutletPackagingInventoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<MonthlyOutletPackagingInventoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MonthlyOutletPackagingInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
};


export type OutletOutletFinishingsArgs = {
  cursor?: InputMaybe<OutletFinishingWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutletFinishingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutletFinishingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletFinishingWhereInput>;
};


export type OutletPackagingRequestsArgs = {
  cursor?: InputMaybe<PackagingRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingRequestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingRequestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingRequestWhereInput>;
};


export type OutletProjectionsArgs = {
  cursor?: InputMaybe<ProjectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectionWhereInput>;
};


export type OutletRawMaterialFinishingEndingsArgs = {
  cursor?: InputMaybe<RawMaterialFinishingEndingWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialFinishingEndingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialFinishingEndingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
};


export type OutletRawMaterialRequestsArgs = {
  cursor?: InputMaybe<RawMaterialRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialRequestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialRequestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialRequestWhereInput>;
};


export type OutletSdsDeliveryReceiptsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
};


export type OutletTransferFromOutletsArgs = {
  cursor?: InputMaybe<TransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferWhereInput>;
};


export type OutletTransferToOutletsArgs = {
  cursor?: InputMaybe<TransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferWhereInput>;
};


export type OutletUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type OutletAvgAggregate = {
  __typename?: 'OutletAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type OutletCount = {
  __typename?: 'OutletCount';
  crts: Scalars['Int']['output'];
  deliveryReceipts: Scalars['Int']['output'];
  endingSdEmTa: Scalars['Int']['output'];
  monthlyOutletPackagingInventories: Scalars['Int']['output'];
  outletFinishings: Scalars['Int']['output'];
  packagingRequests: Scalars['Int']['output'];
  projections: Scalars['Int']['output'];
  rawMaterialFinishingEndings: Scalars['Int']['output'];
  rawMaterialRequests: Scalars['Int']['output'];
  sdsDeliveryReceipts: Scalars['Int']['output'];
  transferFromOutlets: Scalars['Int']['output'];
  transferToOutlets: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};

export type OutletCountAggregate = {
  __typename?: 'OutletCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  numberOfShifts: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
};

export type OutletCreateInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<OutletWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutletCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<OutletCreateWithoutUsersInput>>;
};

export type OutletCreateNestedOneWithoutCrtsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutCrtsInput>;
  create?: InputMaybe<OutletCreateWithoutCrtsInput>;
};

export type OutletCreateNestedOneWithoutDeliveryReceiptsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutDeliveryReceiptsInput>;
  create?: InputMaybe<OutletCreateWithoutDeliveryReceiptsInput>;
};

export type OutletCreateNestedOneWithoutEndingSdEmTaInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutEndingSdEmTaInput>;
  create?: InputMaybe<OutletCreateWithoutEndingSdEmTaInput>;
};

export type OutletCreateNestedOneWithoutMonthlyOutletPackagingInventoriesInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutMonthlyOutletPackagingInventoriesInput>;
  create?: InputMaybe<OutletCreateWithoutMonthlyOutletPackagingInventoriesInput>;
};

export type OutletCreateNestedOneWithoutOutletFinishingsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutOutletFinishingsInput>;
  create?: InputMaybe<OutletCreateWithoutOutletFinishingsInput>;
};

export type OutletCreateNestedOneWithoutPackagingRequestsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutPackagingRequestsInput>;
  create?: InputMaybe<OutletCreateWithoutPackagingRequestsInput>;
};

export type OutletCreateNestedOneWithoutProjectionsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutProjectionsInput>;
  create?: InputMaybe<OutletCreateWithoutProjectionsInput>;
};

export type OutletCreateNestedOneWithoutRawMaterialFinishingEndingsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutRawMaterialFinishingEndingsInput>;
  create?: InputMaybe<OutletCreateWithoutRawMaterialFinishingEndingsInput>;
};

export type OutletCreateNestedOneWithoutRawMaterialRequestsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutRawMaterialRequestsInput>;
  create?: InputMaybe<OutletCreateWithoutRawMaterialRequestsInput>;
};

export type OutletCreateNestedOneWithoutSdsDeliveryReceiptsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutSdsDeliveryReceiptsInput>;
  create?: InputMaybe<OutletCreateWithoutSdsDeliveryReceiptsInput>;
};

export type OutletCreateNestedOneWithoutTransferFromOutletsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutTransferFromOutletsInput>;
  create?: InputMaybe<OutletCreateWithoutTransferFromOutletsInput>;
};

export type OutletCreateNestedOneWithoutTransferToOutletsInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutTransferToOutletsInput>;
  create?: InputMaybe<OutletCreateWithoutTransferToOutletsInput>;
};

export type OutletCreateOrConnectWithoutCrtsInput = {
  create: OutletCreateWithoutCrtsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutDeliveryReceiptsInput = {
  create: OutletCreateWithoutDeliveryReceiptsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutEndingSdEmTaInput = {
  create: OutletCreateWithoutEndingSdEmTaInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutMonthlyOutletPackagingInventoriesInput = {
  create: OutletCreateWithoutMonthlyOutletPackagingInventoriesInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutOutletFinishingsInput = {
  create: OutletCreateWithoutOutletFinishingsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutPackagingRequestsInput = {
  create: OutletCreateWithoutPackagingRequestsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutProjectionsInput = {
  create: OutletCreateWithoutProjectionsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutRawMaterialFinishingEndingsInput = {
  create: OutletCreateWithoutRawMaterialFinishingEndingsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutRawMaterialRequestsInput = {
  create: OutletCreateWithoutRawMaterialRequestsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutSdsDeliveryReceiptsInput = {
  create: OutletCreateWithoutSdsDeliveryReceiptsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutTransferFromOutletsInput = {
  create: OutletCreateWithoutTransferFromOutletsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutTransferToOutletsInput = {
  create: OutletCreateWithoutTransferToOutletsInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateOrConnectWithoutUsersInput = {
  create: OutletCreateWithoutUsersInput;
  where: OutletWhereUniqueInput;
};

export type OutletCreateWithoutCrtsInput = {
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutDeliveryReceiptsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutEndingSdEmTaInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutMonthlyOutletPackagingInventoriesInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutOutletFinishingsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutPackagingRequestsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutProjectionsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutRawMaterialFinishingEndingsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutRawMaterialRequestsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutSdsDeliveryReceiptsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutTransferFromOutletsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutTransferToOutletsInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOutletsInput>;
};

export type OutletCreateWithoutUsersInput = {
  crts?: InputMaybe<CrtCreateNestedManyWithoutOutletInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptCreateNestedManyWithoutOutletInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaCreateNestedManyWithoutOutletInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutOutletInput>;
  name: Scalars['String']['input'];
  numberOfShifts?: InputMaybe<NumberOfShifts>;
  outletFinishings?: InputMaybe<OutletFinishingCreateNestedManyWithoutOutletInput>;
  packagingRequests?: InputMaybe<PackagingRequestCreateNestedManyWithoutOutletInput>;
  projections?: InputMaybe<ProjectionCreateNestedManyWithoutOutletInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestCreateNestedManyWithoutOutletInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutOutletInput>;
  status?: InputMaybe<Status>;
  transferFromOutlets?: InputMaybe<TransferCreateNestedManyWithoutFromOutletInput>;
  transferToOutlets?: InputMaybe<TransferCreateNestedManyWithoutToOutletInput>;
};

export type OutletFinishing = {
  __typename?: 'OutletFinishing';
  _count?: Maybe<OutletFinishingCount>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id: Scalars['Int']['output'];
  outlet: Outlet;
  outletFinishingItems: Array<OutletFinishingItem>;
  outletId: Scalars['Int']['output'];
};


export type OutletFinishingOutletFinishingItemsArgs = {
  cursor?: InputMaybe<OutletFinishingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutletFinishingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutletFinishingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletFinishingItemWhereInput>;
};

export type OutletFinishingAvgAggregate = {
  __typename?: 'OutletFinishingAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
};

export type OutletFinishingCount = {
  __typename?: 'OutletFinishingCount';
  outletFinishingItems: Scalars['Int']['output'];
};

export type OutletFinishingCountAggregate = {
  __typename?: 'OutletFinishingCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
};

export type OutletFinishingCreateInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutOutletFinishingsInput;
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutOutletFinishingInput>;
};

export type OutletFinishingCreateManyOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type OutletFinishingCreateManyOutletInputEnvelope = {
  data: Array<OutletFinishingCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OutletFinishingCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<OutletFinishingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutletFinishingCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<OutletFinishingCreateWithoutOutletInput>>;
  createMany?: InputMaybe<OutletFinishingCreateManyOutletInputEnvelope>;
};

export type OutletFinishingCreateNestedOneWithoutOutletFinishingItemsInput = {
  connect?: InputMaybe<OutletFinishingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletFinishingCreateOrConnectWithoutOutletFinishingItemsInput>;
  create?: InputMaybe<OutletFinishingCreateWithoutOutletFinishingItemsInput>;
};

export type OutletFinishingCreateOrConnectWithoutOutletFinishingItemsInput = {
  create: OutletFinishingCreateWithoutOutletFinishingItemsInput;
  where: OutletFinishingWhereUniqueInput;
};

export type OutletFinishingCreateOrConnectWithoutOutletInput = {
  create: OutletFinishingCreateWithoutOutletInput;
  where: OutletFinishingWhereUniqueInput;
};

export type OutletFinishingCreateWithoutOutletFinishingItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutOutletFinishingsInput;
};

export type OutletFinishingCreateWithoutOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outletFinishingItems?: InputMaybe<OutletFinishingItemCreateNestedManyWithoutOutletFinishingInput>;
};

export type OutletFinishingDateDeliveryShiftScheduleOutletIdCompoundUniqueInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outletId: Scalars['Int']['input'];
};

export type OutletFinishingItem = {
  __typename?: 'OutletFinishingItem';
  cmlProduct: CmlProduct;
  cmlProductId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  outletFinishing: OutletFinishing;
  outletFinishingId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type OutletFinishingItemCreateManyCmlProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  outletFinishingId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type OutletFinishingItemCreateManyCmlProductInputEnvelope = {
  data: Array<OutletFinishingItemCreateManyCmlProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OutletFinishingItemCreateManyOutletFinishingInput = {
  cmlProductId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type OutletFinishingItemCreateManyOutletFinishingInputEnvelope = {
  data: Array<OutletFinishingItemCreateManyOutletFinishingInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OutletFinishingItemCreateNestedManyWithoutCmlProductInput = {
  connect?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutletFinishingItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<OutletFinishingItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<OutletFinishingItemCreateManyCmlProductInputEnvelope>;
};

export type OutletFinishingItemCreateNestedManyWithoutOutletFinishingInput = {
  connect?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutletFinishingItemCreateOrConnectWithoutOutletFinishingInput>>;
  create?: InputMaybe<Array<OutletFinishingItemCreateWithoutOutletFinishingInput>>;
  createMany?: InputMaybe<OutletFinishingItemCreateManyOutletFinishingInputEnvelope>;
};

export type OutletFinishingItemCreateOrConnectWithoutCmlProductInput = {
  create: OutletFinishingItemCreateWithoutCmlProductInput;
  where: OutletFinishingItemWhereUniqueInput;
};

export type OutletFinishingItemCreateOrConnectWithoutOutletFinishingInput = {
  create: OutletFinishingItemCreateWithoutOutletFinishingInput;
  where: OutletFinishingItemWhereUniqueInput;
};

export type OutletFinishingItemCreateWithoutCmlProductInput = {
  outletFinishing: OutletFinishingCreateNestedOneWithoutOutletFinishingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type OutletFinishingItemCreateWithoutOutletFinishingInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutOutletFinishingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type OutletFinishingItemListRelationFilter = {
  every?: InputMaybe<OutletFinishingItemWhereInput>;
  none?: InputMaybe<OutletFinishingItemWhereInput>;
  some?: InputMaybe<OutletFinishingItemWhereInput>;
};

export type OutletFinishingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OutletFinishingItemOrderByWithRelationInput = {
  cmlProduct?: InputMaybe<CmlProductOrderByWithRelationInput>;
  cmlProductId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outletFinishing?: InputMaybe<OutletFinishingOrderByWithRelationInput>;
  outletFinishingId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum OutletFinishingItemScalarFieldEnum {
  CmlProductId = 'cmlProductId',
  Id = 'id',
  OutletFinishingId = 'outletFinishingId',
  Quantity = 'quantity'
}

export type OutletFinishingItemScalarWhereInput = {
  AND?: InputMaybe<Array<OutletFinishingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<OutletFinishingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<OutletFinishingItemScalarWhereInput>>;
  cmlProductId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  outletFinishingId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type OutletFinishingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type OutletFinishingItemUpdateManyWithWhereWithoutCmlProductInput = {
  data: OutletFinishingItemUpdateManyMutationInput;
  where: OutletFinishingItemScalarWhereInput;
};

export type OutletFinishingItemUpdateManyWithWhereWithoutOutletFinishingInput = {
  data: OutletFinishingItemUpdateManyMutationInput;
  where: OutletFinishingItemScalarWhereInput;
};

export type OutletFinishingItemUpdateManyWithoutCmlProductNestedInput = {
  connect?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutletFinishingItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<OutletFinishingItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<OutletFinishingItemCreateManyCmlProductInputEnvelope>;
  delete?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutletFinishingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<OutletFinishingItemUpdateWithWhereUniqueWithoutCmlProductInput>>;
  updateMany?: InputMaybe<Array<OutletFinishingItemUpdateManyWithWhereWithoutCmlProductInput>>;
  upsert?: InputMaybe<Array<OutletFinishingItemUpsertWithWhereUniqueWithoutCmlProductInput>>;
};

export type OutletFinishingItemUpdateManyWithoutOutletFinishingNestedInput = {
  connect?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutletFinishingItemCreateOrConnectWithoutOutletFinishingInput>>;
  create?: InputMaybe<Array<OutletFinishingItemCreateWithoutOutletFinishingInput>>;
  createMany?: InputMaybe<OutletFinishingItemCreateManyOutletFinishingInputEnvelope>;
  delete?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutletFinishingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<OutletFinishingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<OutletFinishingItemUpdateWithWhereUniqueWithoutOutletFinishingInput>>;
  updateMany?: InputMaybe<Array<OutletFinishingItemUpdateManyWithWhereWithoutOutletFinishingInput>>;
  upsert?: InputMaybe<Array<OutletFinishingItemUpsertWithWhereUniqueWithoutOutletFinishingInput>>;
};

export type OutletFinishingItemUpdateWithWhereUniqueWithoutCmlProductInput = {
  data: OutletFinishingItemUpdateWithoutCmlProductInput;
  where: OutletFinishingItemWhereUniqueInput;
};

export type OutletFinishingItemUpdateWithWhereUniqueWithoutOutletFinishingInput = {
  data: OutletFinishingItemUpdateWithoutOutletFinishingInput;
  where: OutletFinishingItemWhereUniqueInput;
};

export type OutletFinishingItemUpdateWithoutCmlProductInput = {
  outletFinishing?: InputMaybe<OutletFinishingUpdateOneRequiredWithoutOutletFinishingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type OutletFinishingItemUpdateWithoutOutletFinishingInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutOutletFinishingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type OutletFinishingItemUpsertWithWhereUniqueWithoutCmlProductInput = {
  create: OutletFinishingItemCreateWithoutCmlProductInput;
  update: OutletFinishingItemUpdateWithoutCmlProductInput;
  where: OutletFinishingItemWhereUniqueInput;
};

export type OutletFinishingItemUpsertWithWhereUniqueWithoutOutletFinishingInput = {
  create: OutletFinishingItemCreateWithoutOutletFinishingInput;
  update: OutletFinishingItemUpdateWithoutOutletFinishingInput;
  where: OutletFinishingItemWhereUniqueInput;
};

export type OutletFinishingItemWhereInput = {
  AND?: InputMaybe<Array<OutletFinishingItemWhereInput>>;
  NOT?: InputMaybe<Array<OutletFinishingItemWhereInput>>;
  OR?: InputMaybe<Array<OutletFinishingItemWhereInput>>;
  cmlProduct?: InputMaybe<CmlProductRelationFilter>;
  cmlProductId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  outletFinishing?: InputMaybe<OutletFinishingRelationFilter>;
  outletFinishingId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type OutletFinishingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type OutletFinishingListRelationFilter = {
  every?: InputMaybe<OutletFinishingWhereInput>;
  none?: InputMaybe<OutletFinishingWhereInput>;
  some?: InputMaybe<OutletFinishingWhereInput>;
};

export type OutletFinishingMaxAggregate = {
  __typename?: 'OutletFinishingMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type OutletFinishingMinAggregate = {
  __typename?: 'OutletFinishingMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type OutletFinishingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OutletFinishingOrderByWithRelationInput = {
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemOrderByRelationAggregateInput>;
  outletId?: InputMaybe<SortOrder>;
};

export type OutletFinishingRelationFilter = {
  is?: InputMaybe<OutletFinishingWhereInput>;
  isNot?: InputMaybe<OutletFinishingWhereInput>;
};

export enum OutletFinishingScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Id = 'id',
  OutletId = 'outletId'
}

export type OutletFinishingScalarWhereInput = {
  AND?: InputMaybe<Array<OutletFinishingScalarWhereInput>>;
  NOT?: InputMaybe<Array<OutletFinishingScalarWhereInput>>;
  OR?: InputMaybe<Array<OutletFinishingScalarWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outletId?: InputMaybe<IntFilter>;
};

export type OutletFinishingSumAggregate = {
  __typename?: 'OutletFinishingSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type OutletFinishingUpdateInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutOutletFinishingsNestedInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutOutletFinishingNestedInput>;
};

export type OutletFinishingUpdateManyMutationInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
};

export type OutletFinishingUpdateManyWithWhereWithoutOutletInput = {
  data: OutletFinishingUpdateManyMutationInput;
  where: OutletFinishingScalarWhereInput;
};

export type OutletFinishingUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<OutletFinishingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutletFinishingCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<OutletFinishingCreateWithoutOutletInput>>;
  createMany?: InputMaybe<OutletFinishingCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<OutletFinishingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutletFinishingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutletFinishingWhereUniqueInput>>;
  set?: InputMaybe<Array<OutletFinishingWhereUniqueInput>>;
  update?: InputMaybe<Array<OutletFinishingUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<OutletFinishingUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<OutletFinishingUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type OutletFinishingUpdateOneRequiredWithoutOutletFinishingItemsNestedInput = {
  connect?: InputMaybe<OutletFinishingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletFinishingCreateOrConnectWithoutOutletFinishingItemsInput>;
  create?: InputMaybe<OutletFinishingCreateWithoutOutletFinishingItemsInput>;
  update?: InputMaybe<OutletFinishingUpdateWithoutOutletFinishingItemsInput>;
  upsert?: InputMaybe<OutletFinishingUpsertWithoutOutletFinishingItemsInput>;
};

export type OutletFinishingUpdateWithWhereUniqueWithoutOutletInput = {
  data: OutletFinishingUpdateWithoutOutletInput;
  where: OutletFinishingWhereUniqueInput;
};

export type OutletFinishingUpdateWithoutOutletFinishingItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutOutletFinishingsNestedInput>;
};

export type OutletFinishingUpdateWithoutOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemUpdateManyWithoutOutletFinishingNestedInput>;
};

export type OutletFinishingUpsertWithWhereUniqueWithoutOutletInput = {
  create: OutletFinishingCreateWithoutOutletInput;
  update: OutletFinishingUpdateWithoutOutletInput;
  where: OutletFinishingWhereUniqueInput;
};

export type OutletFinishingUpsertWithoutOutletFinishingItemsInput = {
  create: OutletFinishingCreateWithoutOutletFinishingItemsInput;
  update: OutletFinishingUpdateWithoutOutletFinishingItemsInput;
};

export type OutletFinishingWhereInput = {
  AND?: InputMaybe<Array<OutletFinishingWhereInput>>;
  NOT?: InputMaybe<Array<OutletFinishingWhereInput>>;
  OR?: InputMaybe<Array<OutletFinishingWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletFinishingItems?: InputMaybe<OutletFinishingItemListRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
};

export type OutletFinishingWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  date_deliveryShiftSchedule_outletId?: InputMaybe<OutletFinishingDateDeliveryShiftScheduleOutletIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type OutletListRelationFilter = {
  every?: InputMaybe<OutletWhereInput>;
  none?: InputMaybe<OutletWhereInput>;
  some?: InputMaybe<OutletWhereInput>;
};

export type OutletMaxAggregate = {
  __typename?: 'OutletMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfShifts?: Maybe<NumberOfShifts>;
  status?: Maybe<Status>;
};

export type OutletMinAggregate = {
  __typename?: 'OutletMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfShifts?: Maybe<NumberOfShifts>;
  status?: Maybe<Status>;
};

export type OutletOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OutletOrderByWithRelationInput = {
  crts?: InputMaybe<CrtOrderByRelationAggregateInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptOrderByRelationAggregateInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  numberOfShifts?: InputMaybe<SortOrder>;
  outletFinishings?: InputMaybe<OutletFinishingOrderByRelationAggregateInput>;
  packagingRequests?: InputMaybe<PackagingRequestOrderByRelationAggregateInput>;
  projections?: InputMaybe<ProjectionOrderByRelationAggregateInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingOrderByRelationAggregateInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestOrderByRelationAggregateInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  transferFromOutlets?: InputMaybe<TransferOrderByRelationAggregateInput>;
  transferToOutlets?: InputMaybe<TransferOrderByRelationAggregateInput>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type OutletPackagingEndingConsolidation = {
  __typename?: 'OutletPackagingEndingConsolidation';
  category?: Maybe<Scalars['String']['output']>;
  packaging?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type OutletPackagingEndingConsolidationWhereInput = {
  monthYear?: InputMaybe<Scalars['DateTime']['input']>;
  outletId?: InputMaybe<Scalars['Float']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type OutletRelationFilter = {
  is?: InputMaybe<OutletWhereInput>;
  isNot?: InputMaybe<OutletWhereInput>;
};

export enum OutletScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  NumberOfShifts = 'numberOfShifts',
  Status = 'status'
}

export type OutletScalarWhereInput = {
  AND?: InputMaybe<Array<OutletScalarWhereInput>>;
  NOT?: InputMaybe<Array<OutletScalarWhereInput>>;
  OR?: InputMaybe<Array<OutletScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFilter>;
  status?: InputMaybe<EnumStatusFilter>;
};

export type OutletSumAggregate = {
  __typename?: 'OutletSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type OutletUpdateInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateManyMutationInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type OutletUpdateManyWithWhereWithoutUsersInput = {
  data: OutletUpdateManyMutationInput;
  where: OutletScalarWhereInput;
};

export type OutletUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<OutletWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutletCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<OutletCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<OutletWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutletScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutletWhereUniqueInput>>;
  set?: InputMaybe<Array<OutletWhereUniqueInput>>;
  update?: InputMaybe<Array<OutletUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<OutletUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<OutletUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type OutletUpdateOneRequiredWithoutCrtsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutCrtsInput>;
  create?: InputMaybe<OutletCreateWithoutCrtsInput>;
  update?: InputMaybe<OutletUpdateWithoutCrtsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutCrtsInput>;
};

export type OutletUpdateOneRequiredWithoutDeliveryReceiptsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutDeliveryReceiptsInput>;
  create?: InputMaybe<OutletCreateWithoutDeliveryReceiptsInput>;
  update?: InputMaybe<OutletUpdateWithoutDeliveryReceiptsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutDeliveryReceiptsInput>;
};

export type OutletUpdateOneRequiredWithoutEndingSdEmTaNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutEndingSdEmTaInput>;
  create?: InputMaybe<OutletCreateWithoutEndingSdEmTaInput>;
  update?: InputMaybe<OutletUpdateWithoutEndingSdEmTaInput>;
  upsert?: InputMaybe<OutletUpsertWithoutEndingSdEmTaInput>;
};

export type OutletUpdateOneRequiredWithoutMonthlyOutletPackagingInventoriesNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutMonthlyOutletPackagingInventoriesInput>;
  create?: InputMaybe<OutletCreateWithoutMonthlyOutletPackagingInventoriesInput>;
  update?: InputMaybe<OutletUpdateWithoutMonthlyOutletPackagingInventoriesInput>;
  upsert?: InputMaybe<OutletUpsertWithoutMonthlyOutletPackagingInventoriesInput>;
};

export type OutletUpdateOneRequiredWithoutOutletFinishingsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutOutletFinishingsInput>;
  create?: InputMaybe<OutletCreateWithoutOutletFinishingsInput>;
  update?: InputMaybe<OutletUpdateWithoutOutletFinishingsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutOutletFinishingsInput>;
};

export type OutletUpdateOneRequiredWithoutPackagingRequestsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutPackagingRequestsInput>;
  create?: InputMaybe<OutletCreateWithoutPackagingRequestsInput>;
  update?: InputMaybe<OutletUpdateWithoutPackagingRequestsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutPackagingRequestsInput>;
};

export type OutletUpdateOneRequiredWithoutProjectionsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutProjectionsInput>;
  create?: InputMaybe<OutletCreateWithoutProjectionsInput>;
  update?: InputMaybe<OutletUpdateWithoutProjectionsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutProjectionsInput>;
};

export type OutletUpdateOneRequiredWithoutRawMaterialRequestsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutRawMaterialRequestsInput>;
  create?: InputMaybe<OutletCreateWithoutRawMaterialRequestsInput>;
  update?: InputMaybe<OutletUpdateWithoutRawMaterialRequestsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutRawMaterialRequestsInput>;
};

export type OutletUpdateOneRequiredWithoutSdsDeliveryReceiptsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutSdsDeliveryReceiptsInput>;
  create?: InputMaybe<OutletCreateWithoutSdsDeliveryReceiptsInput>;
  update?: InputMaybe<OutletUpdateWithoutSdsDeliveryReceiptsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutSdsDeliveryReceiptsInput>;
};

export type OutletUpdateOneRequiredWithoutTransferFromOutletsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutTransferFromOutletsInput>;
  create?: InputMaybe<OutletCreateWithoutTransferFromOutletsInput>;
  update?: InputMaybe<OutletUpdateWithoutTransferFromOutletsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutTransferFromOutletsInput>;
};

export type OutletUpdateOneRequiredWithoutTransferToOutletsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutTransferToOutletsInput>;
  create?: InputMaybe<OutletCreateWithoutTransferToOutletsInput>;
  update?: InputMaybe<OutletUpdateWithoutTransferToOutletsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutTransferToOutletsInput>;
};

export type OutletUpdateOneWithoutRawMaterialFinishingEndingsNestedInput = {
  connect?: InputMaybe<OutletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutletCreateOrConnectWithoutRawMaterialFinishingEndingsInput>;
  create?: InputMaybe<OutletCreateWithoutRawMaterialFinishingEndingsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OutletUpdateWithoutRawMaterialFinishingEndingsInput>;
  upsert?: InputMaybe<OutletUpsertWithoutRawMaterialFinishingEndingsInput>;
};

export type OutletUpdateWithWhereUniqueWithoutUsersInput = {
  data: OutletUpdateWithoutUsersInput;
  where: OutletWhereUniqueInput;
};

export type OutletUpdateWithoutCrtsInput = {
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutDeliveryReceiptsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutEndingSdEmTaInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutMonthlyOutletPackagingInventoriesInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutOutletFinishingsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutPackagingRequestsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutProjectionsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutRawMaterialFinishingEndingsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutRawMaterialRequestsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutSdsDeliveryReceiptsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutTransferFromOutletsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutTransferToOutletsInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutOutletsNestedInput>;
};

export type OutletUpdateWithoutUsersInput = {
  crts?: InputMaybe<CrtUpdateManyWithoutOutletNestedInput>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaUpdateManyWithoutOutletNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutOutletNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFieldUpdateOperationsInput>;
  outletFinishings?: InputMaybe<OutletFinishingUpdateManyWithoutOutletNestedInput>;
  packagingRequests?: InputMaybe<PackagingRequestUpdateManyWithoutOutletNestedInput>;
  projections?: InputMaybe<ProjectionUpdateManyWithoutOutletNestedInput>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestUpdateManyWithoutOutletNestedInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  transferFromOutlets?: InputMaybe<TransferUpdateManyWithoutFromOutletNestedInput>;
  transferToOutlets?: InputMaybe<TransferUpdateManyWithoutToOutletNestedInput>;
};

export type OutletUpsertWithWhereUniqueWithoutUsersInput = {
  create: OutletCreateWithoutUsersInput;
  update: OutletUpdateWithoutUsersInput;
  where: OutletWhereUniqueInput;
};

export type OutletUpsertWithoutCrtsInput = {
  create: OutletCreateWithoutCrtsInput;
  update: OutletUpdateWithoutCrtsInput;
};

export type OutletUpsertWithoutDeliveryReceiptsInput = {
  create: OutletCreateWithoutDeliveryReceiptsInput;
  update: OutletUpdateWithoutDeliveryReceiptsInput;
};

export type OutletUpsertWithoutEndingSdEmTaInput = {
  create: OutletCreateWithoutEndingSdEmTaInput;
  update: OutletUpdateWithoutEndingSdEmTaInput;
};

export type OutletUpsertWithoutMonthlyOutletPackagingInventoriesInput = {
  create: OutletCreateWithoutMonthlyOutletPackagingInventoriesInput;
  update: OutletUpdateWithoutMonthlyOutletPackagingInventoriesInput;
};

export type OutletUpsertWithoutOutletFinishingsInput = {
  create: OutletCreateWithoutOutletFinishingsInput;
  update: OutletUpdateWithoutOutletFinishingsInput;
};

export type OutletUpsertWithoutPackagingRequestsInput = {
  create: OutletCreateWithoutPackagingRequestsInput;
  update: OutletUpdateWithoutPackagingRequestsInput;
};

export type OutletUpsertWithoutProjectionsInput = {
  create: OutletCreateWithoutProjectionsInput;
  update: OutletUpdateWithoutProjectionsInput;
};

export type OutletUpsertWithoutRawMaterialFinishingEndingsInput = {
  create: OutletCreateWithoutRawMaterialFinishingEndingsInput;
  update: OutletUpdateWithoutRawMaterialFinishingEndingsInput;
};

export type OutletUpsertWithoutRawMaterialRequestsInput = {
  create: OutletCreateWithoutRawMaterialRequestsInput;
  update: OutletUpdateWithoutRawMaterialRequestsInput;
};

export type OutletUpsertWithoutSdsDeliveryReceiptsInput = {
  create: OutletCreateWithoutSdsDeliveryReceiptsInput;
  update: OutletUpdateWithoutSdsDeliveryReceiptsInput;
};

export type OutletUpsertWithoutTransferFromOutletsInput = {
  create: OutletCreateWithoutTransferFromOutletsInput;
  update: OutletUpdateWithoutTransferFromOutletsInput;
};

export type OutletUpsertWithoutTransferToOutletsInput = {
  create: OutletCreateWithoutTransferToOutletsInput;
  update: OutletUpdateWithoutTransferToOutletsInput;
};

export type OutletWhereInput = {
  AND?: InputMaybe<Array<OutletWhereInput>>;
  NOT?: InputMaybe<Array<OutletWhereInput>>;
  OR?: InputMaybe<Array<OutletWhereInput>>;
  crts?: InputMaybe<CrtListRelationFilter>;
  deliveryReceipts?: InputMaybe<DeliveryReceiptListRelationFilter>;
  endingSdEmTa?: InputMaybe<EndingSdEmTaListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  numberOfShifts?: InputMaybe<EnumNumberOfShiftsFilter>;
  outletFinishings?: InputMaybe<OutletFinishingListRelationFilter>;
  packagingRequests?: InputMaybe<PackagingRequestListRelationFilter>;
  projections?: InputMaybe<ProjectionListRelationFilter>;
  rawMaterialFinishingEndings?: InputMaybe<RawMaterialFinishingEndingListRelationFilter>;
  rawMaterialRequests?: InputMaybe<RawMaterialRequestListRelationFilter>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  transferFromOutlets?: InputMaybe<TransferListRelationFilter>;
  transferToOutlets?: InputMaybe<TransferListRelationFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type OutletWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Packaging = {
  __typename?: 'Packaging';
  _count?: Maybe<PackagingCount>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  packagingCategory: PackagingCategory;
  packagingProducts: Array<PackagingProduct>;
  status: Status;
};


export type PackagingPackagingProductsArgs = {
  cursor?: InputMaybe<PackagingProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingProductWhereInput>;
};

export type PackagingAvgAggregate = {
  __typename?: 'PackagingAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export { PackagingCategory };

export type PackagingCount = {
  __typename?: 'PackagingCount';
  packagingProducts: Scalars['Int']['output'];
};

export type PackagingCountAggregate = {
  __typename?: 'PackagingCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  packagingCategory: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
};

export type PackagingCreateInput = {
  name: Scalars['String']['input'];
  packagingCategory: PackagingCategory;
  packagingProducts?: InputMaybe<PackagingProductCreateNestedManyWithoutPackagingInput>;
  status: Status;
};

export type PackagingCreateNestedOneWithoutPackagingProductsInput = {
  connect?: InputMaybe<PackagingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingCreateOrConnectWithoutPackagingProductsInput>;
  create?: InputMaybe<PackagingCreateWithoutPackagingProductsInput>;
};

export type PackagingCreateOrConnectWithoutPackagingProductsInput = {
  create: PackagingCreateWithoutPackagingProductsInput;
  where: PackagingWhereUniqueInput;
};

export type PackagingCreateWithoutPackagingProductsInput = {
  name: Scalars['String']['input'];
  packagingCategory: PackagingCategory;
  status: Status;
};

export type PackagingMaxAggregate = {
  __typename?: 'PackagingMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packagingCategory?: Maybe<PackagingCategory>;
  status?: Maybe<Status>;
};

export type PackagingMinAggregate = {
  __typename?: 'PackagingMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packagingCategory?: Maybe<PackagingCategory>;
  status?: Maybe<Status>;
};

export type PackagingOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  packagingCategory?: InputMaybe<SortOrder>;
  packagingProducts?: InputMaybe<PackagingProductOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
};

export type PackagingProduct = {
  __typename?: 'PackagingProduct';
  _count?: Maybe<PackagingProductCount>;
  baseUnit: Scalars['Boolean']['output'];
  betweenEntityTransferPackagingItems: Array<BetweenEntityTransferPackagingItem>;
  cmlThrowAwayPackagingItems: Array<CmlThrowAwayPackagingItem>;
  conversionToBaseUnit: Scalars['Float']['output'];
  crtPackagingItems: Array<CrtPackagingItem>;
  endingSdEmTaPackagingItems: Array<EndingSdEmTaPackagingItem>;
  id: Scalars['Int']['output'];
  monthlyOutletPackagingInventories: Array<MonthlyOutletPackagingInventory>;
  packaging: Packaging;
  packagingId: Scalars['Int']['output'];
  packagingRequestItems: Array<PackagingRequestItem>;
  purchaseOrderPackagingItems: Array<PurchaseOrderPackagingItem>;
  rawMaterialReleasePackagingItems: Array<RawMaterialReleasePackagingItem>;
  sdsDeliveryReceiptPackagingItems: Array<SdsDeliveryReceiptPackagingItem>;
  transferPackagingItems: Array<TransferPackagingItem>;
  unit: Unit;
  unitId: Scalars['Int']['output'];
  warehouseInventoryPackagingItems: Array<WarehouseInventoryPackagingItem>;
};


export type PackagingProductBetweenEntityTransferPackagingItemsArgs = {
  cursor?: InputMaybe<BetweenEntityTransferPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BetweenEntityTransferPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BetweenEntityTransferPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BetweenEntityTransferPackagingItemWhereInput>;
};


export type PackagingProductCmlThrowAwayPackagingItemsArgs = {
  cursor?: InputMaybe<CmlThrowAwayPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlThrowAwayPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlThrowAwayPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayPackagingItemWhereInput>;
};


export type PackagingProductCrtPackagingItemsArgs = {
  cursor?: InputMaybe<CrtPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtPackagingItemWhereInput>;
};


export type PackagingProductEndingSdEmTaPackagingItemsArgs = {
  cursor?: InputMaybe<EndingSdEmTaPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaPackagingItemWhereInput>;
};


export type PackagingProductMonthlyOutletPackagingInventoriesArgs = {
  cursor?: InputMaybe<MonthlyOutletPackagingInventoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<MonthlyOutletPackagingInventoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MonthlyOutletPackagingInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
};


export type PackagingProductPackagingRequestItemsArgs = {
  cursor?: InputMaybe<PackagingRequestItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingRequestItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingRequestItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingRequestItemWhereInput>;
};


export type PackagingProductPurchaseOrderPackagingItemsArgs = {
  cursor?: InputMaybe<PurchaseOrderPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PurchaseOrderPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PurchaseOrderPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderPackagingItemWhereInput>;
};


export type PackagingProductRawMaterialReleasePackagingItemsArgs = {
  cursor?: InputMaybe<RawMaterialReleasePackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialReleasePackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialReleasePackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleasePackagingItemWhereInput>;
};


export type PackagingProductSdsDeliveryReceiptPackagingItemsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
};


export type PackagingProductTransferPackagingItemsArgs = {
  cursor?: InputMaybe<TransferPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferPackagingItemWhereInput>;
};


export type PackagingProductWarehouseInventoryPackagingItemsArgs = {
  cursor?: InputMaybe<WarehouseInventoryPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<WarehouseInventoryPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WarehouseInventoryPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WarehouseInventoryPackagingItemWhereInput>;
};

export type PackagingProductCount = {
  __typename?: 'PackagingProductCount';
  betweenEntityTransferPackagingItems: Scalars['Int']['output'];
  cmlThrowAwayPackagingItems: Scalars['Int']['output'];
  crtPackagingItems: Scalars['Int']['output'];
  endingSdEmTaPackagingItems: Scalars['Int']['output'];
  monthlyOutletPackagingInventories: Scalars['Int']['output'];
  packagingRequestItems: Scalars['Int']['output'];
  purchaseOrderPackagingItems: Scalars['Int']['output'];
  rawMaterialReleasePackagingItems: Scalars['Int']['output'];
  sdsDeliveryReceiptPackagingItems: Scalars['Int']['output'];
  transferPackagingItems: Scalars['Int']['output'];
  warehouseInventoryPackagingItems: Scalars['Int']['output'];
};

export type PackagingProductCreateManyPackagingInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  conversionToBaseUnit: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  unitId: Scalars['Int']['input'];
};

export type PackagingProductCreateManyPackagingInputEnvelope = {
  data: Array<PackagingProductCreateManyPackagingInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PackagingProductCreateManyUnitInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  conversionToBaseUnit: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingId: Scalars['Int']['input'];
};

export type PackagingProductCreateManyUnitInputEnvelope = {
  data: Array<PackagingProductCreateManyUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PackagingProductCreateNestedManyWithoutPackagingInput = {
  connect?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingProductCreateOrConnectWithoutPackagingInput>>;
  create?: InputMaybe<Array<PackagingProductCreateWithoutPackagingInput>>;
  createMany?: InputMaybe<PackagingProductCreateManyPackagingInputEnvelope>;
};

export type PackagingProductCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingProductCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<PackagingProductCreateWithoutUnitInput>>;
  createMany?: InputMaybe<PackagingProductCreateManyUnitInputEnvelope>;
};

export type PackagingProductCreateNestedOneWithoutBetweenEntityTransferPackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutBetweenEntityTransferPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutBetweenEntityTransferPackagingItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutCmlThrowAwayPackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutCmlThrowAwayPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutCmlThrowAwayPackagingItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutCrtPackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutCrtPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutCrtPackagingItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutEndingSdEmTaPackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutEndingSdEmTaPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutEndingSdEmTaPackagingItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutMonthlyOutletPackagingInventoriesInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutMonthlyOutletPackagingInventoriesInput>;
  create?: InputMaybe<PackagingProductCreateWithoutMonthlyOutletPackagingInventoriesInput>;
};

export type PackagingProductCreateNestedOneWithoutPackagingRequestItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutPackagingRequestItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutPackagingRequestItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutPurchaseOrderPackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutPurchaseOrderPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutPurchaseOrderPackagingItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutRawMaterialReleasePackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutRawMaterialReleasePackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutRawMaterialReleasePackagingItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutSdsDeliveryReceiptPackagingItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutTransferPackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutTransferPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutTransferPackagingItemsInput>;
};

export type PackagingProductCreateNestedOneWithoutWarehouseInventoryPackagingItemsInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutWarehouseInventoryPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutWarehouseInventoryPackagingItemsInput>;
};

export type PackagingProductCreateOrConnectWithoutBetweenEntityTransferPackagingItemsInput = {
  create: PackagingProductCreateWithoutBetweenEntityTransferPackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutCmlThrowAwayPackagingItemsInput = {
  create: PackagingProductCreateWithoutCmlThrowAwayPackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutCrtPackagingItemsInput = {
  create: PackagingProductCreateWithoutCrtPackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutEndingSdEmTaPackagingItemsInput = {
  create: PackagingProductCreateWithoutEndingSdEmTaPackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutMonthlyOutletPackagingInventoriesInput = {
  create: PackagingProductCreateWithoutMonthlyOutletPackagingInventoriesInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutPackagingInput = {
  create: PackagingProductCreateWithoutPackagingInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutPackagingRequestItemsInput = {
  create: PackagingProductCreateWithoutPackagingRequestItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutPurchaseOrderPackagingItemsInput = {
  create: PackagingProductCreateWithoutPurchaseOrderPackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutRawMaterialReleasePackagingItemsInput = {
  create: PackagingProductCreateWithoutRawMaterialReleasePackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemsInput = {
  create: PackagingProductCreateWithoutSdsDeliveryReceiptPackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutTransferPackagingItemsInput = {
  create: PackagingProductCreateWithoutTransferPackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutUnitInput = {
  create: PackagingProductCreateWithoutUnitInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateOrConnectWithoutWarehouseInventoryPackagingItemsInput = {
  create: PackagingProductCreateWithoutWarehouseInventoryPackagingItemsInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductCreateWithoutBetweenEntityTransferPackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutCmlThrowAwayPackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutCrtPackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutEndingSdEmTaPackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutMonthlyOutletPackagingInventoriesInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutPackagingInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutPackagingRequestItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutPurchaseOrderPackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutRawMaterialReleasePackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutSdsDeliveryReceiptPackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutTransferPackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutUnitInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput>;
};

export type PackagingProductCreateWithoutWarehouseInventoryPackagingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtPackagingItems?: InputMaybe<CrtPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryCreateNestedManyWithoutPackagingProductInput>;
  packaging: PackagingCreateNestedOneWithoutPackagingProductsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingProductInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutPackagingProductInput>;
  unit: UnitCreateNestedOneWithoutPackagingProductsInput;
};

export type PackagingProductListRelationFilter = {
  every?: InputMaybe<PackagingProductWhereInput>;
  none?: InputMaybe<PackagingProductWhereInput>;
  some?: InputMaybe<PackagingProductWhereInput>;
};

export type PackagingProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PackagingProductOrderByWithRelationInput = {
  baseUnit?: InputMaybe<SortOrder>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemOrderByRelationAggregateInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemOrderByRelationAggregateInput>;
  conversionToBaseUnit?: InputMaybe<SortOrder>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemOrderByRelationAggregateInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryOrderByRelationAggregateInput>;
  packaging?: InputMaybe<PackagingOrderByWithRelationInput>;
  packagingId?: InputMaybe<SortOrder>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemOrderByRelationAggregateInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemOrderByRelationAggregateInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemOrderByRelationAggregateInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemOrderByRelationAggregateInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemOrderByRelationAggregateInput>;
  unit?: InputMaybe<UnitOrderByWithRelationInput>;
  unitId?: InputMaybe<SortOrder>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemOrderByRelationAggregateInput>;
};

export type PackagingProductRelationFilter = {
  is?: InputMaybe<PackagingProductWhereInput>;
  isNot?: InputMaybe<PackagingProductWhereInput>;
};

export enum PackagingProductScalarFieldEnum {
  BaseUnit = 'baseUnit',
  ConversionToBaseUnit = 'conversionToBaseUnit',
  Id = 'id',
  PackagingId = 'packagingId',
  UnitId = 'unitId'
}

export type PackagingProductScalarWhereInput = {
  AND?: InputMaybe<Array<PackagingProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<PackagingProductScalarWhereInput>>;
  OR?: InputMaybe<Array<PackagingProductScalarWhereInput>>;
  baseUnit?: InputMaybe<BoolFilter>;
  conversionToBaseUnit?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  packagingId?: InputMaybe<IntFilter>;
  unitId?: InputMaybe<IntFilter>;
};

export type PackagingProductUnitIdPackagingIdCompoundUniqueInput = {
  packagingId: Scalars['Int']['input'];
  unitId: Scalars['Int']['input'];
};

export type PackagingProductUpdateManyMutationInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PackagingProductUpdateManyWithWhereWithoutPackagingInput = {
  data: PackagingProductUpdateManyMutationInput;
  where: PackagingProductScalarWhereInput;
};

export type PackagingProductUpdateManyWithWhereWithoutUnitInput = {
  data: PackagingProductUpdateManyMutationInput;
  where: PackagingProductScalarWhereInput;
};

export type PackagingProductUpdateManyWithoutPackagingNestedInput = {
  connect?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingProductCreateOrConnectWithoutPackagingInput>>;
  create?: InputMaybe<Array<PackagingProductCreateWithoutPackagingInput>>;
  createMany?: InputMaybe<PackagingProductCreateManyPackagingInputEnvelope>;
  delete?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackagingProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  set?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  update?: InputMaybe<Array<PackagingProductUpdateWithWhereUniqueWithoutPackagingInput>>;
  updateMany?: InputMaybe<Array<PackagingProductUpdateManyWithWhereWithoutPackagingInput>>;
  upsert?: InputMaybe<Array<PackagingProductUpsertWithWhereUniqueWithoutPackagingInput>>;
};

export type PackagingProductUpdateManyWithoutUnitNestedInput = {
  connect?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingProductCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<PackagingProductCreateWithoutUnitInput>>;
  createMany?: InputMaybe<PackagingProductCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackagingProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  set?: InputMaybe<Array<PackagingProductWhereUniqueInput>>;
  update?: InputMaybe<Array<PackagingProductUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<PackagingProductUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<PackagingProductUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type PackagingProductUpdateOneRequiredWithoutBetweenEntityTransferPackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutBetweenEntityTransferPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutBetweenEntityTransferPackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutBetweenEntityTransferPackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutBetweenEntityTransferPackagingItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutCmlThrowAwayPackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutCmlThrowAwayPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutCmlThrowAwayPackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutCmlThrowAwayPackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutCmlThrowAwayPackagingItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutCrtPackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutCrtPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutCrtPackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutCrtPackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutCrtPackagingItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutEndingSdEmTaPackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutEndingSdEmTaPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutEndingSdEmTaPackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutEndingSdEmTaPackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutEndingSdEmTaPackagingItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutMonthlyOutletPackagingInventoriesNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutMonthlyOutletPackagingInventoriesInput>;
  create?: InputMaybe<PackagingProductCreateWithoutMonthlyOutletPackagingInventoriesInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutMonthlyOutletPackagingInventoriesInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutMonthlyOutletPackagingInventoriesInput>;
};

export type PackagingProductUpdateOneRequiredWithoutPackagingRequestItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutPackagingRequestItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutPackagingRequestItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutPackagingRequestItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutPackagingRequestItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutPurchaseOrderPackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutPurchaseOrderPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutPurchaseOrderPackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutPurchaseOrderPackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutPurchaseOrderPackagingItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutRawMaterialReleasePackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutRawMaterialReleasePackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutRawMaterialReleasePackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutRawMaterialReleasePackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutRawMaterialReleasePackagingItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutSdsDeliveryReceiptPackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutSdsDeliveryReceiptPackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutSdsDeliveryReceiptPackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutSdsDeliveryReceiptPackagingItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutTransferPackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutTransferPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutTransferPackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutTransferPackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutTransferPackagingItemsInput>;
};

export type PackagingProductUpdateOneRequiredWithoutWarehouseInventoryPackagingItemsNestedInput = {
  connect?: InputMaybe<PackagingProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingProductCreateOrConnectWithoutWarehouseInventoryPackagingItemsInput>;
  create?: InputMaybe<PackagingProductCreateWithoutWarehouseInventoryPackagingItemsInput>;
  update?: InputMaybe<PackagingProductUpdateWithoutWarehouseInventoryPackagingItemsInput>;
  upsert?: InputMaybe<PackagingProductUpsertWithoutWarehouseInventoryPackagingItemsInput>;
};

export type PackagingProductUpdateWithWhereUniqueWithoutPackagingInput = {
  data: PackagingProductUpdateWithoutPackagingInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductUpdateWithWhereUniqueWithoutUnitInput = {
  data: PackagingProductUpdateWithoutUnitInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductUpdateWithoutBetweenEntityTransferPackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutCmlThrowAwayPackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutCrtPackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutEndingSdEmTaPackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutMonthlyOutletPackagingInventoriesInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutPackagingInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutPackagingRequestItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutPurchaseOrderPackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutRawMaterialReleasePackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutSdsDeliveryReceiptPackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutTransferPackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutUnitInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
};

export type PackagingProductUpdateWithoutWarehouseInventoryPackagingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryUpdateManyWithoutPackagingProductNestedInput>;
  packaging?: InputMaybe<PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPackagingProductsNestedInput>;
};

export type PackagingProductUpsertWithWhereUniqueWithoutPackagingInput = {
  create: PackagingProductCreateWithoutPackagingInput;
  update: PackagingProductUpdateWithoutPackagingInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductUpsertWithWhereUniqueWithoutUnitInput = {
  create: PackagingProductCreateWithoutUnitInput;
  update: PackagingProductUpdateWithoutUnitInput;
  where: PackagingProductWhereUniqueInput;
};

export type PackagingProductUpsertWithoutBetweenEntityTransferPackagingItemsInput = {
  create: PackagingProductCreateWithoutBetweenEntityTransferPackagingItemsInput;
  update: PackagingProductUpdateWithoutBetweenEntityTransferPackagingItemsInput;
};

export type PackagingProductUpsertWithoutCmlThrowAwayPackagingItemsInput = {
  create: PackagingProductCreateWithoutCmlThrowAwayPackagingItemsInput;
  update: PackagingProductUpdateWithoutCmlThrowAwayPackagingItemsInput;
};

export type PackagingProductUpsertWithoutCrtPackagingItemsInput = {
  create: PackagingProductCreateWithoutCrtPackagingItemsInput;
  update: PackagingProductUpdateWithoutCrtPackagingItemsInput;
};

export type PackagingProductUpsertWithoutEndingSdEmTaPackagingItemsInput = {
  create: PackagingProductCreateWithoutEndingSdEmTaPackagingItemsInput;
  update: PackagingProductUpdateWithoutEndingSdEmTaPackagingItemsInput;
};

export type PackagingProductUpsertWithoutMonthlyOutletPackagingInventoriesInput = {
  create: PackagingProductCreateWithoutMonthlyOutletPackagingInventoriesInput;
  update: PackagingProductUpdateWithoutMonthlyOutletPackagingInventoriesInput;
};

export type PackagingProductUpsertWithoutPackagingRequestItemsInput = {
  create: PackagingProductCreateWithoutPackagingRequestItemsInput;
  update: PackagingProductUpdateWithoutPackagingRequestItemsInput;
};

export type PackagingProductUpsertWithoutPurchaseOrderPackagingItemsInput = {
  create: PackagingProductCreateWithoutPurchaseOrderPackagingItemsInput;
  update: PackagingProductUpdateWithoutPurchaseOrderPackagingItemsInput;
};

export type PackagingProductUpsertWithoutRawMaterialReleasePackagingItemsInput = {
  create: PackagingProductCreateWithoutRawMaterialReleasePackagingItemsInput;
  update: PackagingProductUpdateWithoutRawMaterialReleasePackagingItemsInput;
};

export type PackagingProductUpsertWithoutSdsDeliveryReceiptPackagingItemsInput = {
  create: PackagingProductCreateWithoutSdsDeliveryReceiptPackagingItemsInput;
  update: PackagingProductUpdateWithoutSdsDeliveryReceiptPackagingItemsInput;
};

export type PackagingProductUpsertWithoutTransferPackagingItemsInput = {
  create: PackagingProductCreateWithoutTransferPackagingItemsInput;
  update: PackagingProductUpdateWithoutTransferPackagingItemsInput;
};

export type PackagingProductUpsertWithoutWarehouseInventoryPackagingItemsInput = {
  create: PackagingProductCreateWithoutWarehouseInventoryPackagingItemsInput;
  update: PackagingProductUpdateWithoutWarehouseInventoryPackagingItemsInput;
};

export type PackagingProductWhereInput = {
  AND?: InputMaybe<Array<PackagingProductWhereInput>>;
  NOT?: InputMaybe<Array<PackagingProductWhereInput>>;
  OR?: InputMaybe<Array<PackagingProductWhereInput>>;
  baseUnit?: InputMaybe<BoolFilter>;
  betweenEntityTransferPackagingItems?: InputMaybe<BetweenEntityTransferPackagingItemListRelationFilter>;
  cmlThrowAwayPackagingItems?: InputMaybe<CmlThrowAwayPackagingItemListRelationFilter>;
  conversionToBaseUnit?: InputMaybe<FloatFilter>;
  crtPackagingItems?: InputMaybe<CrtPackagingItemListRelationFilter>;
  endingSdEmTaPackagingItems?: InputMaybe<EndingSdEmTaPackagingItemListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  monthlyOutletPackagingInventories?: InputMaybe<MonthlyOutletPackagingInventoryListRelationFilter>;
  packaging?: InputMaybe<PackagingRelationFilter>;
  packagingId?: InputMaybe<IntFilter>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemListRelationFilter>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemListRelationFilter>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemListRelationFilter>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemListRelationFilter>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemListRelationFilter>;
  unit?: InputMaybe<UnitRelationFilter>;
  unitId?: InputMaybe<IntFilter>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemListRelationFilter>;
};

export type PackagingProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unitId_packagingId?: InputMaybe<PackagingProductUnitIdPackagingIdCompoundUniqueInput>;
};

export type PackagingRelationFilter = {
  is?: InputMaybe<PackagingWhereInput>;
  isNot?: InputMaybe<PackagingWhereInput>;
};

export type PackagingRequest = {
  __typename?: 'PackagingRequest';
  _count?: Maybe<PackagingRequestCount>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  neededDate: Scalars['DateTime']['output'];
  outlet: Outlet;
  outletId: Scalars['Int']['output'];
  packagingRequestItems: Array<PackagingRequestItem>;
  requestDate: Scalars['DateTime']['output'];
  sdsDeliveryReceipts: Array<SdsDeliveryReceipt>;
  status: Scalars['String']['output'];
};


export type PackagingRequestPackagingRequestItemsArgs = {
  cursor?: InputMaybe<PackagingRequestItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingRequestItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingRequestItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingRequestItemWhereInput>;
};


export type PackagingRequestSdsDeliveryReceiptsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
};

export type PackagingRequestAvgAggregate = {
  __typename?: 'PackagingRequestAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
};

export type PackagingRequestCount = {
  __typename?: 'PackagingRequestCount';
  packagingRequestItems: Scalars['Int']['output'];
  sdsDeliveryReceipts: Scalars['Int']['output'];
};

export type PackagingRequestCountAggregate = {
  __typename?: 'PackagingRequestCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  neededDate: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
  requestDate: Scalars['Int']['output'];
};

export type PackagingRequestCreateInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  neededDate: Scalars['DateTime']['input'];
  outlet: OutletCreateNestedOneWithoutPackagingRequestsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingRequestInput>;
  requestDate: Scalars['DateTime']['input'];
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutPackagingRequestInput>;
};

export type PackagingRequestCreateManyOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  neededDate: Scalars['DateTime']['input'];
  requestDate: Scalars['DateTime']['input'];
};

export type PackagingRequestCreateManyOutletInputEnvelope = {
  data: Array<PackagingRequestCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PackagingRequestCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<PackagingRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingRequestCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<PackagingRequestCreateWithoutOutletInput>>;
  createMany?: InputMaybe<PackagingRequestCreateManyOutletInputEnvelope>;
};

export type PackagingRequestCreateNestedOneWithoutPackagingRequestItemsInput = {
  connect?: InputMaybe<PackagingRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingRequestCreateOrConnectWithoutPackagingRequestItemsInput>;
  create?: InputMaybe<PackagingRequestCreateWithoutPackagingRequestItemsInput>;
};

export type PackagingRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput = {
  connect?: InputMaybe<PackagingRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingRequestCreateOrConnectWithoutSdsDeliveryReceiptsInput>;
  create?: InputMaybe<PackagingRequestCreateWithoutSdsDeliveryReceiptsInput>;
};

export type PackagingRequestCreateOrConnectWithoutOutletInput = {
  create: PackagingRequestCreateWithoutOutletInput;
  where: PackagingRequestWhereUniqueInput;
};

export type PackagingRequestCreateOrConnectWithoutPackagingRequestItemsInput = {
  create: PackagingRequestCreateWithoutPackagingRequestItemsInput;
  where: PackagingRequestWhereUniqueInput;
};

export type PackagingRequestCreateOrConnectWithoutSdsDeliveryReceiptsInput = {
  create: PackagingRequestCreateWithoutSdsDeliveryReceiptsInput;
  where: PackagingRequestWhereUniqueInput;
};

export type PackagingRequestCreateWithoutOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  neededDate: Scalars['DateTime']['input'];
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingRequestInput>;
  requestDate: Scalars['DateTime']['input'];
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutPackagingRequestInput>;
};

export type PackagingRequestCreateWithoutPackagingRequestItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  neededDate: Scalars['DateTime']['input'];
  outlet: OutletCreateNestedOneWithoutPackagingRequestsInput;
  requestDate: Scalars['DateTime']['input'];
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutPackagingRequestInput>;
};

export type PackagingRequestCreateWithoutSdsDeliveryReceiptsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  neededDate: Scalars['DateTime']['input'];
  outlet: OutletCreateNestedOneWithoutPackagingRequestsInput;
  packagingRequestItems?: InputMaybe<PackagingRequestItemCreateNestedManyWithoutPackagingRequestInput>;
  requestDate: Scalars['DateTime']['input'];
};

export type PackagingRequestItem = {
  __typename?: 'PackagingRequestItem';
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  packagingRequest: PackagingRequest;
  packagingRequestId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type PackagingRequestItemCreateManyPackagingProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingRequestId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type PackagingRequestItemCreateManyPackagingProductInputEnvelope = {
  data: Array<PackagingRequestItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PackagingRequestItemCreateManyPackagingRequestInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type PackagingRequestItemCreateManyPackagingRequestInputEnvelope = {
  data: Array<PackagingRequestItemCreateManyPackagingRequestInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PackagingRequestItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingRequestItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<PackagingRequestItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<PackagingRequestItemCreateManyPackagingProductInputEnvelope>;
};

export type PackagingRequestItemCreateNestedManyWithoutPackagingRequestInput = {
  connect?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingRequestItemCreateOrConnectWithoutPackagingRequestInput>>;
  create?: InputMaybe<Array<PackagingRequestItemCreateWithoutPackagingRequestInput>>;
  createMany?: InputMaybe<PackagingRequestItemCreateManyPackagingRequestInputEnvelope>;
};

export type PackagingRequestItemCreateOrConnectWithoutPackagingProductInput = {
  create: PackagingRequestItemCreateWithoutPackagingProductInput;
  where: PackagingRequestItemWhereUniqueInput;
};

export type PackagingRequestItemCreateOrConnectWithoutPackagingRequestInput = {
  create: PackagingRequestItemCreateWithoutPackagingRequestInput;
  where: PackagingRequestItemWhereUniqueInput;
};

export type PackagingRequestItemCreateWithoutPackagingProductInput = {
  packagingRequest: PackagingRequestCreateNestedOneWithoutPackagingRequestItemsInput;
  quantity: Scalars['Int']['input'];
};

export type PackagingRequestItemCreateWithoutPackagingRequestInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutPackagingRequestItemsInput;
  quantity: Scalars['Int']['input'];
};

export type PackagingRequestItemListRelationFilter = {
  every?: InputMaybe<PackagingRequestItemWhereInput>;
  none?: InputMaybe<PackagingRequestItemWhereInput>;
  some?: InputMaybe<PackagingRequestItemWhereInput>;
};

export type PackagingRequestItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PackagingRequestItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  packagingRequest?: InputMaybe<PackagingRequestOrderByWithRelationInput>;
  packagingRequestId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum PackagingRequestItemScalarFieldEnum {
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  PackagingRequestId = 'packagingRequestId',
  Quantity = 'quantity'
}

export type PackagingRequestItemScalarWhereInput = {
  AND?: InputMaybe<Array<PackagingRequestItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<PackagingRequestItemScalarWhereInput>>;
  OR?: InputMaybe<Array<PackagingRequestItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  packagingRequestId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type PackagingRequestItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PackagingRequestItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: PackagingRequestItemUpdateManyMutationInput;
  where: PackagingRequestItemScalarWhereInput;
};

export type PackagingRequestItemUpdateManyWithWhereWithoutPackagingRequestInput = {
  data: PackagingRequestItemUpdateManyMutationInput;
  where: PackagingRequestItemScalarWhereInput;
};

export type PackagingRequestItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingRequestItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<PackagingRequestItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<PackagingRequestItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackagingRequestItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  update?: InputMaybe<Array<PackagingRequestItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<PackagingRequestItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<PackagingRequestItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type PackagingRequestItemUpdateManyWithoutPackagingRequestNestedInput = {
  connect?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingRequestItemCreateOrConnectWithoutPackagingRequestInput>>;
  create?: InputMaybe<Array<PackagingRequestItemCreateWithoutPackagingRequestInput>>;
  createMany?: InputMaybe<PackagingRequestItemCreateManyPackagingRequestInputEnvelope>;
  delete?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackagingRequestItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PackagingRequestItemWhereUniqueInput>>;
  update?: InputMaybe<Array<PackagingRequestItemUpdateWithWhereUniqueWithoutPackagingRequestInput>>;
  updateMany?: InputMaybe<Array<PackagingRequestItemUpdateManyWithWhereWithoutPackagingRequestInput>>;
  upsert?: InputMaybe<Array<PackagingRequestItemUpsertWithWhereUniqueWithoutPackagingRequestInput>>;
};

export type PackagingRequestItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: PackagingRequestItemUpdateWithoutPackagingProductInput;
  where: PackagingRequestItemWhereUniqueInput;
};

export type PackagingRequestItemUpdateWithWhereUniqueWithoutPackagingRequestInput = {
  data: PackagingRequestItemUpdateWithoutPackagingRequestInput;
  where: PackagingRequestItemWhereUniqueInput;
};

export type PackagingRequestItemUpdateWithoutPackagingProductInput = {
  packagingRequest?: InputMaybe<PackagingRequestUpdateOneRequiredWithoutPackagingRequestItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PackagingRequestItemUpdateWithoutPackagingRequestInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutPackagingRequestItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type PackagingRequestItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: PackagingRequestItemCreateWithoutPackagingProductInput;
  update: PackagingRequestItemUpdateWithoutPackagingProductInput;
  where: PackagingRequestItemWhereUniqueInput;
};

export type PackagingRequestItemUpsertWithWhereUniqueWithoutPackagingRequestInput = {
  create: PackagingRequestItemCreateWithoutPackagingRequestInput;
  update: PackagingRequestItemUpdateWithoutPackagingRequestInput;
  where: PackagingRequestItemWhereUniqueInput;
};

export type PackagingRequestItemWhereInput = {
  AND?: InputMaybe<Array<PackagingRequestItemWhereInput>>;
  NOT?: InputMaybe<Array<PackagingRequestItemWhereInput>>;
  OR?: InputMaybe<Array<PackagingRequestItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  packagingRequest?: InputMaybe<PackagingRequestRelationFilter>;
  packagingRequestId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type PackagingRequestItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PackagingRequestListRelationFilter = {
  every?: InputMaybe<PackagingRequestWhereInput>;
  none?: InputMaybe<PackagingRequestWhereInput>;
  some?: InputMaybe<PackagingRequestWhereInput>;
};

export type PackagingRequestMaxAggregate = {
  __typename?: 'PackagingRequestMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  neededDate?: Maybe<Scalars['DateTime']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  requestDate?: Maybe<Scalars['DateTime']['output']>;
};

export type PackagingRequestMinAggregate = {
  __typename?: 'PackagingRequestMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  neededDate?: Maybe<Scalars['DateTime']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  requestDate?: Maybe<Scalars['DateTime']['output']>;
};

export type PackagingRequestOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PackagingRequestOrderByWithRelationInput = {
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  neededDate?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemOrderByRelationAggregateInput>;
  requestDate?: InputMaybe<SortOrder>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptOrderByRelationAggregateInput>;
};

export type PackagingRequestRelationFilter = {
  is?: InputMaybe<PackagingRequestWhereInput>;
  isNot?: InputMaybe<PackagingRequestWhereInput>;
};

export enum PackagingRequestScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Id = 'id',
  NeededDate = 'neededDate',
  OutletId = 'outletId',
  RequestDate = 'requestDate'
}

export type PackagingRequestScalarWhereInput = {
  AND?: InputMaybe<Array<PackagingRequestScalarWhereInput>>;
  NOT?: InputMaybe<Array<PackagingRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<PackagingRequestScalarWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  neededDate?: InputMaybe<DateTimeFilter>;
  outletId?: InputMaybe<IntFilter>;
  requestDate?: InputMaybe<DateTimeFilter>;
};

export type PackagingRequestSumAggregate = {
  __typename?: 'PackagingRequestSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type PackagingRequestUpdateInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutPackagingRequestsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingRequestNestedInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutPackagingRequestNestedInput>;
};

export type PackagingRequestUpdateManyMutationInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PackagingRequestUpdateManyWithWhereWithoutOutletInput = {
  data: PackagingRequestUpdateManyMutationInput;
  where: PackagingRequestScalarWhereInput;
};

export type PackagingRequestUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<PackagingRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackagingRequestCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<PackagingRequestCreateWithoutOutletInput>>;
  createMany?: InputMaybe<PackagingRequestCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<PackagingRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackagingRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackagingRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<PackagingRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<PackagingRequestUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<PackagingRequestUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<PackagingRequestUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type PackagingRequestUpdateOneRequiredWithoutPackagingRequestItemsNestedInput = {
  connect?: InputMaybe<PackagingRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingRequestCreateOrConnectWithoutPackagingRequestItemsInput>;
  create?: InputMaybe<PackagingRequestCreateWithoutPackagingRequestItemsInput>;
  update?: InputMaybe<PackagingRequestUpdateWithoutPackagingRequestItemsInput>;
  upsert?: InputMaybe<PackagingRequestUpsertWithoutPackagingRequestItemsInput>;
};

export type PackagingRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput = {
  connect?: InputMaybe<PackagingRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingRequestCreateOrConnectWithoutSdsDeliveryReceiptsInput>;
  create?: InputMaybe<PackagingRequestCreateWithoutSdsDeliveryReceiptsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<PackagingRequestUpdateWithoutSdsDeliveryReceiptsInput>;
  upsert?: InputMaybe<PackagingRequestUpsertWithoutSdsDeliveryReceiptsInput>;
};

export type PackagingRequestUpdateWithWhereUniqueWithoutOutletInput = {
  data: PackagingRequestUpdateWithoutOutletInput;
  where: PackagingRequestWhereUniqueInput;
};

export type PackagingRequestUpdateWithoutOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingRequestNestedInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutPackagingRequestNestedInput>;
};

export type PackagingRequestUpdateWithoutPackagingRequestItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutPackagingRequestsNestedInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutPackagingRequestNestedInput>;
};

export type PackagingRequestUpdateWithoutSdsDeliveryReceiptsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutPackagingRequestsNestedInput>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemUpdateManyWithoutPackagingRequestNestedInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PackagingRequestUpsertWithWhereUniqueWithoutOutletInput = {
  create: PackagingRequestCreateWithoutOutletInput;
  update: PackagingRequestUpdateWithoutOutletInput;
  where: PackagingRequestWhereUniqueInput;
};

export type PackagingRequestUpsertWithoutPackagingRequestItemsInput = {
  create: PackagingRequestCreateWithoutPackagingRequestItemsInput;
  update: PackagingRequestUpdateWithoutPackagingRequestItemsInput;
};

export type PackagingRequestUpsertWithoutSdsDeliveryReceiptsInput = {
  create: PackagingRequestCreateWithoutSdsDeliveryReceiptsInput;
  update: PackagingRequestUpdateWithoutSdsDeliveryReceiptsInput;
};

export type PackagingRequestWhereInput = {
  AND?: InputMaybe<Array<PackagingRequestWhereInput>>;
  NOT?: InputMaybe<Array<PackagingRequestWhereInput>>;
  OR?: InputMaybe<Array<PackagingRequestWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  neededDate?: InputMaybe<DateTimeFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
  packagingRequestItems?: InputMaybe<PackagingRequestItemListRelationFilter>;
  requestDate?: InputMaybe<DateTimeFilter>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptListRelationFilter>;
};

export type PackagingRequestWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum PackagingScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  PackagingCategory = 'packagingCategory',
  Status = 'status'
}

export type PackagingSumAggregate = {
  __typename?: 'PackagingSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type PackagingUpdateInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packagingCategory?: InputMaybe<EnumPackagingCategoryFieldUpdateOperationsInput>;
  packagingProducts?: InputMaybe<PackagingProductUpdateManyWithoutPackagingNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type PackagingUpdateOneRequiredWithoutPackagingProductsNestedInput = {
  connect?: InputMaybe<PackagingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackagingCreateOrConnectWithoutPackagingProductsInput>;
  create?: InputMaybe<PackagingCreateWithoutPackagingProductsInput>;
  update?: InputMaybe<PackagingUpdateWithoutPackagingProductsInput>;
  upsert?: InputMaybe<PackagingUpsertWithoutPackagingProductsInput>;
};

export type PackagingUpdateWithoutPackagingProductsInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packagingCategory?: InputMaybe<EnumPackagingCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type PackagingUpsertWithoutPackagingProductsInput = {
  create: PackagingCreateWithoutPackagingProductsInput;
  update: PackagingUpdateWithoutPackagingProductsInput;
};

export type PackagingWhereInput = {
  AND?: InputMaybe<Array<PackagingWhereInput>>;
  NOT?: InputMaybe<Array<PackagingWhereInput>>;
  OR?: InputMaybe<Array<PackagingWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  packagingCategory?: InputMaybe<EnumPackagingCategoryFilter>;
  packagingProducts?: InputMaybe<PackagingProductListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
};

export type PackagingWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PerCmlProductDailyEnding = {
  __typename?: 'PerCmlProductDailyEnding';
  name: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export type PerCmlProductDonutDistribution = {
  __typename?: 'PerCmlProductDonutDistribution';
  name: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export { ProductCategory };

export type Projection = {
  __typename?: 'Projection';
  _count?: Maybe<ProjectionCount>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id: Scalars['Int']['output'];
  outlet: Outlet;
  outletId: Scalars['Int']['output'];
  projectionItems: Array<ProjectionItem>;
  projectionSummaryItems: Array<ProjectionSummaryItem>;
};


export type ProjectionProjectionItemsArgs = {
  cursor?: InputMaybe<ProjectionItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectionItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectionItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectionItemWhereInput>;
};

export type ProjectionAvgAggregate = {
  __typename?: 'ProjectionAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
};

export type ProjectionCount = {
  __typename?: 'ProjectionCount';
  projectionItems: Scalars['Int']['output'];
};

export type ProjectionCountAggregate = {
  __typename?: 'ProjectionCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
};

export type ProjectionCreateInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutProjectionsInput;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutProjectionInput>;
};

export type ProjectionCreateManyOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectionCreateManyOutletInputEnvelope = {
  data: Array<ProjectionCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectionCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<ProjectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectionCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<ProjectionCreateWithoutOutletInput>>;
  createMany?: InputMaybe<ProjectionCreateManyOutletInputEnvelope>;
};

export type ProjectionCreateNestedOneWithoutProjectionItemsInput = {
  connect?: InputMaybe<ProjectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectionCreateOrConnectWithoutProjectionItemsInput>;
  create?: InputMaybe<ProjectionCreateWithoutProjectionItemsInput>;
};

export type ProjectionCreateOrConnectWithoutOutletInput = {
  create: ProjectionCreateWithoutOutletInput;
  where: ProjectionWhereUniqueInput;
};

export type ProjectionCreateOrConnectWithoutProjectionItemsInput = {
  create: ProjectionCreateWithoutProjectionItemsInput;
  where: ProjectionWhereUniqueInput;
};

export type ProjectionCreateWithoutOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  projectionItems?: InputMaybe<ProjectionItemCreateNestedManyWithoutProjectionInput>;
};

export type ProjectionCreateWithoutProjectionItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet: OutletCreateNestedOneWithoutProjectionsInput;
};

export type ProjectionItem = {
  __typename?: 'ProjectionItem';
  cmlProduct: CmlProduct;
  cmlProductId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  projection: Projection;
  projectionId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type ProjectionItemCreateManyCmlProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectionId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type ProjectionItemCreateManyCmlProductInputEnvelope = {
  data: Array<ProjectionItemCreateManyCmlProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectionItemCreateManyProjectionInput = {
  cmlProductId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type ProjectionItemCreateManyProjectionInputEnvelope = {
  data: Array<ProjectionItemCreateManyProjectionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectionItemCreateNestedManyWithoutCmlProductInput = {
  connect?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectionItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<ProjectionItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<ProjectionItemCreateManyCmlProductInputEnvelope>;
};

export type ProjectionItemCreateNestedManyWithoutProjectionInput = {
  connect?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectionItemCreateOrConnectWithoutProjectionInput>>;
  create?: InputMaybe<Array<ProjectionItemCreateWithoutProjectionInput>>;
  createMany?: InputMaybe<ProjectionItemCreateManyProjectionInputEnvelope>;
};

export type ProjectionItemCreateOrConnectWithoutCmlProductInput = {
  create: ProjectionItemCreateWithoutCmlProductInput;
  where: ProjectionItemWhereUniqueInput;
};

export type ProjectionItemCreateOrConnectWithoutProjectionInput = {
  create: ProjectionItemCreateWithoutProjectionInput;
  where: ProjectionItemWhereUniqueInput;
};

export type ProjectionItemCreateWithoutCmlProductInput = {
  projection: ProjectionCreateNestedOneWithoutProjectionItemsInput;
  quantity: Scalars['Int']['input'];
};

export type ProjectionItemCreateWithoutProjectionInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutProjectionItemsInput;
  quantity: Scalars['Int']['input'];
};

export type ProjectionItemListRelationFilter = {
  every?: InputMaybe<ProjectionItemWhereInput>;
  none?: InputMaybe<ProjectionItemWhereInput>;
  some?: InputMaybe<ProjectionItemWhereInput>;
};

export type ProjectionItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectionItemOrderByWithRelationInput = {
  cmlProduct?: InputMaybe<CmlProductOrderByWithRelationInput>;
  cmlProductId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projection?: InputMaybe<ProjectionOrderByWithRelationInput>;
  projectionId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum ProjectionItemScalarFieldEnum {
  CmlProductId = 'cmlProductId',
  Id = 'id',
  ProjectionId = 'projectionId',
  Quantity = 'quantity'
}

export type ProjectionItemScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectionItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectionItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectionItemScalarWhereInput>>;
  cmlProductId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  projectionId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type ProjectionItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProjectionItemUpdateManyWithWhereWithoutCmlProductInput = {
  data: ProjectionItemUpdateManyMutationInput;
  where: ProjectionItemScalarWhereInput;
};

export type ProjectionItemUpdateManyWithWhereWithoutProjectionInput = {
  data: ProjectionItemUpdateManyMutationInput;
  where: ProjectionItemScalarWhereInput;
};

export type ProjectionItemUpdateManyWithoutCmlProductNestedInput = {
  connect?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectionItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<ProjectionItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<ProjectionItemCreateManyCmlProductInputEnvelope>;
  delete?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectionItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectionItemUpdateWithWhereUniqueWithoutCmlProductInput>>;
  updateMany?: InputMaybe<Array<ProjectionItemUpdateManyWithWhereWithoutCmlProductInput>>;
  upsert?: InputMaybe<Array<ProjectionItemUpsertWithWhereUniqueWithoutCmlProductInput>>;
};

export type ProjectionItemUpdateManyWithoutProjectionNestedInput = {
  connect?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectionItemCreateOrConnectWithoutProjectionInput>>;
  create?: InputMaybe<Array<ProjectionItemCreateWithoutProjectionInput>>;
  createMany?: InputMaybe<ProjectionItemCreateManyProjectionInputEnvelope>;
  delete?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectionItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectionItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectionItemUpdateWithWhereUniqueWithoutProjectionInput>>;
  updateMany?: InputMaybe<Array<ProjectionItemUpdateManyWithWhereWithoutProjectionInput>>;
  upsert?: InputMaybe<Array<ProjectionItemUpsertWithWhereUniqueWithoutProjectionInput>>;
};

export type ProjectionItemUpdateWithWhereUniqueWithoutCmlProductInput = {
  data: ProjectionItemUpdateWithoutCmlProductInput;
  where: ProjectionItemWhereUniqueInput;
};

export type ProjectionItemUpdateWithWhereUniqueWithoutProjectionInput = {
  data: ProjectionItemUpdateWithoutProjectionInput;
  where: ProjectionItemWhereUniqueInput;
};

export type ProjectionItemUpdateWithoutCmlProductInput = {
  projection?: InputMaybe<ProjectionUpdateOneRequiredWithoutProjectionItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProjectionItemUpdateWithoutProjectionInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutProjectionItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProjectionItemUpsertWithWhereUniqueWithoutCmlProductInput = {
  create: ProjectionItemCreateWithoutCmlProductInput;
  update: ProjectionItemUpdateWithoutCmlProductInput;
  where: ProjectionItemWhereUniqueInput;
};

export type ProjectionItemUpsertWithWhereUniqueWithoutProjectionInput = {
  create: ProjectionItemCreateWithoutProjectionInput;
  update: ProjectionItemUpdateWithoutProjectionInput;
  where: ProjectionItemWhereUniqueInput;
};

export type ProjectionItemWhereInput = {
  AND?: InputMaybe<Array<ProjectionItemWhereInput>>;
  NOT?: InputMaybe<Array<ProjectionItemWhereInput>>;
  OR?: InputMaybe<Array<ProjectionItemWhereInput>>;
  cmlProduct?: InputMaybe<CmlProductRelationFilter>;
  cmlProductId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  projection?: InputMaybe<ProjectionRelationFilter>;
  projectionId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
};

export type ProjectionItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectionListRelationFilter = {
  every?: InputMaybe<ProjectionWhereInput>;
  none?: InputMaybe<ProjectionWhereInput>;
  some?: InputMaybe<ProjectionWhereInput>;
};

export type ProjectionMaxAggregate = {
  __typename?: 'ProjectionMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type ProjectionMinAggregate = {
  __typename?: 'ProjectionMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type ProjectionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectionOrderByWithRelationInput = {
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
  projectionItems?: InputMaybe<ProjectionItemOrderByRelationAggregateInput>;
};

export type ProjectionRelationFilter = {
  is?: InputMaybe<ProjectionWhereInput>;
  isNot?: InputMaybe<ProjectionWhereInput>;
};

export enum ProjectionScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Id = 'id',
  OutletId = 'outletId'
}

export type ProjectionScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectionScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectionScalarWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outletId?: InputMaybe<IntFilter>;
};

export type ProjectionSumAggregate = {
  __typename?: 'ProjectionSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type ProjectionSummaryItem = {
  __typename?: 'ProjectionSummaryItem';
  quantity: Scalars['Float']['output'];
  salesCategory: SalesCategory;
};

export type ProjectionToRawMaterial = {
  __typename?: 'ProjectionToRawMaterial';
  category?: Maybe<Scalars['String']['output']>;
  firstDelivery?: Maybe<Scalars['Float']['output']>;
  rawMaterial?: Maybe<Scalars['String']['output']>;
  secondDelivery?: Maybe<Scalars['Float']['output']>;
  thirdDelivery?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type ProjectionToRawMaterialWhereInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectionUpdateInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutProjectionsNestedInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutProjectionNestedInput>;
};

export type ProjectionUpdateManyMutationInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
};

export type ProjectionUpdateManyWithWhereWithoutOutletInput = {
  data: ProjectionUpdateManyMutationInput;
  where: ProjectionScalarWhereInput;
};

export type ProjectionUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<ProjectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectionCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<ProjectionCreateWithoutOutletInput>>;
  createMany?: InputMaybe<ProjectionCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<ProjectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectionWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectionWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectionUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<ProjectionUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<ProjectionUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type ProjectionUpdateOneRequiredWithoutProjectionItemsNestedInput = {
  connect?: InputMaybe<ProjectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectionCreateOrConnectWithoutProjectionItemsInput>;
  create?: InputMaybe<ProjectionCreateWithoutProjectionItemsInput>;
  update?: InputMaybe<ProjectionUpdateWithoutProjectionItemsInput>;
  upsert?: InputMaybe<ProjectionUpsertWithoutProjectionItemsInput>;
};

export type ProjectionUpdateWithWhereUniqueWithoutOutletInput = {
  data: ProjectionUpdateWithoutOutletInput;
  where: ProjectionWhereUniqueInput;
};

export type ProjectionUpdateWithoutOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  projectionItems?: InputMaybe<ProjectionItemUpdateManyWithoutProjectionNestedInput>;
};

export type ProjectionUpdateWithoutProjectionItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutProjectionsNestedInput>;
};

export type ProjectionUpsertWithWhereUniqueWithoutOutletInput = {
  create: ProjectionCreateWithoutOutletInput;
  update: ProjectionUpdateWithoutOutletInput;
  where: ProjectionWhereUniqueInput;
};

export type ProjectionUpsertWithoutProjectionItemsInput = {
  create: ProjectionCreateWithoutProjectionItemsInput;
  update: ProjectionUpdateWithoutProjectionItemsInput;
};

export type ProjectionWhereInput = {
  AND?: InputMaybe<Array<ProjectionWhereInput>>;
  NOT?: InputMaybe<Array<ProjectionWhereInput>>;
  OR?: InputMaybe<Array<ProjectionWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
  projectionItems?: InputMaybe<ProjectionItemListRelationFilter>;
};

export type ProjectionWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  _count?: Maybe<PurchaseOrderCount>;
  dateArrived: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  purchaseOrderPackagingItems: Array<PurchaseOrderPackagingItem>;
  purchaseOrderRawMaterialItems: Array<PurchaseOrderRawMaterialItem>;
  salesInvoiceNumber: Scalars['String']['output'];
};


export type PurchaseOrderPurchaseOrderPackagingItemsArgs = {
  cursor?: InputMaybe<PurchaseOrderPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PurchaseOrderPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PurchaseOrderPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderPackagingItemWhereInput>;
};


export type PurchaseOrderPurchaseOrderRawMaterialItemsArgs = {
  cursor?: InputMaybe<PurchaseOrderRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PurchaseOrderRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PurchaseOrderRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderRawMaterialItemWhereInput>;
};

export type PurchaseOrderAvgAggregate = {
  __typename?: 'PurchaseOrderAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type PurchaseOrderCount = {
  __typename?: 'PurchaseOrderCount';
  purchaseOrderPackagingItems: Scalars['Int']['output'];
  purchaseOrderRawMaterialItems: Scalars['Int']['output'];
};

export type PurchaseOrderCountAggregate = {
  __typename?: 'PurchaseOrderCountAggregate';
  _all: Scalars['Int']['output'];
  dateArrived: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  salesInvoiceNumber: Scalars['Int']['output'];
};

export type PurchaseOrderCreateInput = {
  dateArrived: Scalars['DateTime']['input'];
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPurchaseOrderInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutPurchaseOrderInput>;
  salesInvoiceNumber: Scalars['String']['input'];
};

export type PurchaseOrderCreateNestedOneWithoutPurchaseOrderPackagingItemsInput = {
  connect?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseOrderCreateOrConnectWithoutPurchaseOrderPackagingItemsInput>;
  create?: InputMaybe<PurchaseOrderCreateWithoutPurchaseOrderPackagingItemsInput>;
};

export type PurchaseOrderCreateNestedOneWithoutPurchaseOrderRawMaterialItemsInput = {
  connect?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseOrderCreateOrConnectWithoutPurchaseOrderRawMaterialItemsInput>;
  create?: InputMaybe<PurchaseOrderCreateWithoutPurchaseOrderRawMaterialItemsInput>;
};

export type PurchaseOrderCreateOrConnectWithoutPurchaseOrderPackagingItemsInput = {
  create: PurchaseOrderCreateWithoutPurchaseOrderPackagingItemsInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderCreateOrConnectWithoutPurchaseOrderRawMaterialItemsInput = {
  create: PurchaseOrderCreateWithoutPurchaseOrderRawMaterialItemsInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderCreateWithoutPurchaseOrderPackagingItemsInput = {
  dateArrived: Scalars['DateTime']['input'];
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutPurchaseOrderInput>;
  salesInvoiceNumber: Scalars['String']['input'];
};

export type PurchaseOrderCreateWithoutPurchaseOrderRawMaterialItemsInput = {
  dateArrived: Scalars['DateTime']['input'];
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemCreateNestedManyWithoutPurchaseOrderInput>;
  salesInvoiceNumber: Scalars['String']['input'];
};

export type PurchaseOrderMaxAggregate = {
  __typename?: 'PurchaseOrderMaxAggregate';
  dateArrived?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  salesInvoiceNumber?: Maybe<Scalars['String']['output']>;
};

export type PurchaseOrderMinAggregate = {
  __typename?: 'PurchaseOrderMinAggregate';
  dateArrived?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  salesInvoiceNumber?: Maybe<Scalars['String']['output']>;
};

export type PurchaseOrderOrderByWithRelationInput = {
  dateArrived?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemOrderByRelationAggregateInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemOrderByRelationAggregateInput>;
  salesInvoiceNumber?: InputMaybe<SortOrder>;
};

export type PurchaseOrderPackagingItem = {
  __typename?: 'PurchaseOrderPackagingItem';
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  purchaseOrder: PurchaseOrder;
  purchaseOrderId: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
};

export type PurchaseOrderPackagingItemCreateManyPackagingProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderId: Scalars['Int']['input'];
  quantity: Scalars['Float']['input'];
};

export type PurchaseOrderPackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<PurchaseOrderPackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PurchaseOrderPackagingItemCreateManyPurchaseOrderInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Float']['input'];
};

export type PurchaseOrderPackagingItemCreateManyPurchaseOrderInputEnvelope = {
  data: Array<PurchaseOrderPackagingItemCreateManyPurchaseOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PurchaseOrderPackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<PurchaseOrderPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<PurchaseOrderPackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type PurchaseOrderPackagingItemCreateNestedManyWithoutPurchaseOrderInput = {
  connect?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderPackagingItemCreateOrConnectWithoutPurchaseOrderInput>>;
  create?: InputMaybe<Array<PurchaseOrderPackagingItemCreateWithoutPurchaseOrderInput>>;
  createMany?: InputMaybe<PurchaseOrderPackagingItemCreateManyPurchaseOrderInputEnvelope>;
};

export type PurchaseOrderPackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: PurchaseOrderPackagingItemCreateWithoutPackagingProductInput;
  where: PurchaseOrderPackagingItemWhereUniqueInput;
};

export type PurchaseOrderPackagingItemCreateOrConnectWithoutPurchaseOrderInput = {
  create: PurchaseOrderPackagingItemCreateWithoutPurchaseOrderInput;
  where: PurchaseOrderPackagingItemWhereUniqueInput;
};

export type PurchaseOrderPackagingItemCreateWithoutPackagingProductInput = {
  purchaseOrder: PurchaseOrderCreateNestedOneWithoutPurchaseOrderPackagingItemsInput;
  quantity: Scalars['Float']['input'];
};

export type PurchaseOrderPackagingItemCreateWithoutPurchaseOrderInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutPurchaseOrderPackagingItemsInput;
  quantity: Scalars['Float']['input'];
};

export type PurchaseOrderPackagingItemListRelationFilter = {
  every?: InputMaybe<PurchaseOrderPackagingItemWhereInput>;
  none?: InputMaybe<PurchaseOrderPackagingItemWhereInput>;
  some?: InputMaybe<PurchaseOrderPackagingItemWhereInput>;
};

export type PurchaseOrderPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PurchaseOrderPackagingItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  purchaseOrder?: InputMaybe<PurchaseOrderOrderByWithRelationInput>;
  purchaseOrderId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
};

export enum PurchaseOrderPackagingItemScalarFieldEnum {
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  PurchaseOrderId = 'purchaseOrderId',
  Quantity = 'quantity'
}

export type PurchaseOrderPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderPackagingItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  purchaseOrderId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
};

export type PurchaseOrderPackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderPackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: PurchaseOrderPackagingItemUpdateManyMutationInput;
  where: PurchaseOrderPackagingItemScalarWhereInput;
};

export type PurchaseOrderPackagingItemUpdateManyWithWhereWithoutPurchaseOrderInput = {
  data: PurchaseOrderPackagingItemUpdateManyMutationInput;
  where: PurchaseOrderPackagingItemScalarWhereInput;
};

export type PurchaseOrderPackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<PurchaseOrderPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<PurchaseOrderPackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderPackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type PurchaseOrderPackagingItemUpdateManyWithoutPurchaseOrderNestedInput = {
  connect?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderPackagingItemCreateOrConnectWithoutPurchaseOrderInput>>;
  create?: InputMaybe<Array<PurchaseOrderPackagingItemCreateWithoutPurchaseOrderInput>>;
  createMany?: InputMaybe<PurchaseOrderPackagingItemCreateManyPurchaseOrderInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderPackagingItemUpdateWithWhereUniqueWithoutPurchaseOrderInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderPackagingItemUpdateManyWithWhereWithoutPurchaseOrderInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderPackagingItemUpsertWithWhereUniqueWithoutPurchaseOrderInput>>;
};

export type PurchaseOrderPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: PurchaseOrderPackagingItemUpdateWithoutPackagingProductInput;
  where: PurchaseOrderPackagingItemWhereUniqueInput;
};

export type PurchaseOrderPackagingItemUpdateWithWhereUniqueWithoutPurchaseOrderInput = {
  data: PurchaseOrderPackagingItemUpdateWithoutPurchaseOrderInput;
  where: PurchaseOrderPackagingItemWhereUniqueInput;
};

export type PurchaseOrderPackagingItemUpdateWithoutPackagingProductInput = {
  purchaseOrder?: InputMaybe<PurchaseOrderUpdateOneRequiredWithoutPurchaseOrderPackagingItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderPackagingItemUpdateWithoutPurchaseOrderInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutPurchaseOrderPackagingItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: PurchaseOrderPackagingItemCreateWithoutPackagingProductInput;
  update: PurchaseOrderPackagingItemUpdateWithoutPackagingProductInput;
  where: PurchaseOrderPackagingItemWhereUniqueInput;
};

export type PurchaseOrderPackagingItemUpsertWithWhereUniqueWithoutPurchaseOrderInput = {
  create: PurchaseOrderPackagingItemCreateWithoutPurchaseOrderInput;
  update: PurchaseOrderPackagingItemUpdateWithoutPurchaseOrderInput;
  where: PurchaseOrderPackagingItemWhereUniqueInput;
};

export type PurchaseOrderPackagingItemWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderPackagingItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  purchaseOrder?: InputMaybe<PurchaseOrderRelationFilter>;
  purchaseOrderId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
};

export type PurchaseOrderPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PurchaseOrderRawMaterialItem = {
  __typename?: 'PurchaseOrderRawMaterialItem';
  id: Scalars['Int']['output'];
  purchaseOrder: PurchaseOrder;
  purchaseOrderId: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
};

export type PurchaseOrderRawMaterialItemCreateManyPurchaseOrderInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type PurchaseOrderRawMaterialItemCreateManyPurchaseOrderInputEnvelope = {
  data: Array<PurchaseOrderRawMaterialItemCreateManyPurchaseOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PurchaseOrderRawMaterialItemCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderId: Scalars['Int']['input'];
  quantity: Scalars['Float']['input'];
};

export type PurchaseOrderRawMaterialItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<PurchaseOrderRawMaterialItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PurchaseOrderRawMaterialItemCreateNestedManyWithoutPurchaseOrderInput = {
  connect?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderRawMaterialItemCreateOrConnectWithoutPurchaseOrderInput>>;
  create?: InputMaybe<Array<PurchaseOrderRawMaterialItemCreateWithoutPurchaseOrderInput>>;
  createMany?: InputMaybe<PurchaseOrderRawMaterialItemCreateManyPurchaseOrderInputEnvelope>;
};

export type PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<PurchaseOrderRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<PurchaseOrderRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
};

export type PurchaseOrderRawMaterialItemCreateOrConnectWithoutPurchaseOrderInput = {
  create: PurchaseOrderRawMaterialItemCreateWithoutPurchaseOrderInput;
  where: PurchaseOrderRawMaterialItemWhereUniqueInput;
};

export type PurchaseOrderRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: PurchaseOrderRawMaterialItemCreateWithoutRawMaterialProductInput;
  where: PurchaseOrderRawMaterialItemWhereUniqueInput;
};

export type PurchaseOrderRawMaterialItemCreateWithoutPurchaseOrderInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutPurchaseOrderRawMaterialItemsInput;
};

export type PurchaseOrderRawMaterialItemCreateWithoutRawMaterialProductInput = {
  purchaseOrder: PurchaseOrderCreateNestedOneWithoutPurchaseOrderRawMaterialItemsInput;
  quantity: Scalars['Float']['input'];
};

export type PurchaseOrderRawMaterialItemListRelationFilter = {
  every?: InputMaybe<PurchaseOrderRawMaterialItemWhereInput>;
  none?: InputMaybe<PurchaseOrderRawMaterialItemWhereInput>;
  some?: InputMaybe<PurchaseOrderRawMaterialItemWhereInput>;
};

export type PurchaseOrderRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PurchaseOrderRawMaterialItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  purchaseOrder?: InputMaybe<PurchaseOrderOrderByWithRelationInput>;
  purchaseOrderId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
};

export enum PurchaseOrderRawMaterialItemScalarFieldEnum {
  Id = 'id',
  PurchaseOrderId = 'purchaseOrderId',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId'
}

export type PurchaseOrderRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderRawMaterialItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  purchaseOrderId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type PurchaseOrderRawMaterialItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderRawMaterialItemUpdateManyWithWhereWithoutPurchaseOrderInput = {
  data: PurchaseOrderRawMaterialItemUpdateManyMutationInput;
  where: PurchaseOrderRawMaterialItemScalarWhereInput;
};

export type PurchaseOrderRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: PurchaseOrderRawMaterialItemUpdateManyMutationInput;
  where: PurchaseOrderRawMaterialItemScalarWhereInput;
};

export type PurchaseOrderRawMaterialItemUpdateManyWithoutPurchaseOrderNestedInput = {
  connect?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderRawMaterialItemCreateOrConnectWithoutPurchaseOrderInput>>;
  create?: InputMaybe<Array<PurchaseOrderRawMaterialItemCreateWithoutPurchaseOrderInput>>;
  createMany?: InputMaybe<PurchaseOrderRawMaterialItemCreateManyPurchaseOrderInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderRawMaterialItemUpdateWithWhereUniqueWithoutPurchaseOrderInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderRawMaterialItemUpdateManyWithWhereWithoutPurchaseOrderInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderRawMaterialItemUpsertWithWhereUniqueWithoutPurchaseOrderInput>>;
};

export type PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<PurchaseOrderRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<PurchaseOrderRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type PurchaseOrderRawMaterialItemUpdateWithWhereUniqueWithoutPurchaseOrderInput = {
  data: PurchaseOrderRawMaterialItemUpdateWithoutPurchaseOrderInput;
  where: PurchaseOrderRawMaterialItemWhereUniqueInput;
};

export type PurchaseOrderRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: PurchaseOrderRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: PurchaseOrderRawMaterialItemWhereUniqueInput;
};

export type PurchaseOrderRawMaterialItemUpdateWithoutPurchaseOrderInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutPurchaseOrderRawMaterialItemsNestedInput>;
};

export type PurchaseOrderRawMaterialItemUpdateWithoutRawMaterialProductInput = {
  purchaseOrder?: InputMaybe<PurchaseOrderUpdateOneRequiredWithoutPurchaseOrderRawMaterialItemsNestedInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderRawMaterialItemUpsertWithWhereUniqueWithoutPurchaseOrderInput = {
  create: PurchaseOrderRawMaterialItemCreateWithoutPurchaseOrderInput;
  update: PurchaseOrderRawMaterialItemUpdateWithoutPurchaseOrderInput;
  where: PurchaseOrderRawMaterialItemWhereUniqueInput;
};

export type PurchaseOrderRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: PurchaseOrderRawMaterialItemCreateWithoutRawMaterialProductInput;
  update: PurchaseOrderRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: PurchaseOrderRawMaterialItemWhereUniqueInput;
};

export type PurchaseOrderRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderRawMaterialItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  purchaseOrder?: InputMaybe<PurchaseOrderRelationFilter>;
  purchaseOrderId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type PurchaseOrderRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PurchaseOrderRelationFilter = {
  is?: InputMaybe<PurchaseOrderWhereInput>;
  isNot?: InputMaybe<PurchaseOrderWhereInput>;
};

export enum PurchaseOrderScalarFieldEnum {
  DateArrived = 'dateArrived',
  Id = 'id',
  SalesInvoiceNumber = 'salesInvoiceNumber'
}

export type PurchaseOrderSumAggregate = {
  __typename?: 'PurchaseOrderSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type PurchaseOrderUpdateInput = {
  dateArrived?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPurchaseOrderNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutPurchaseOrderNestedInput>;
  salesInvoiceNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PurchaseOrderUpdateOneRequiredWithoutPurchaseOrderPackagingItemsNestedInput = {
  connect?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseOrderCreateOrConnectWithoutPurchaseOrderPackagingItemsInput>;
  create?: InputMaybe<PurchaseOrderCreateWithoutPurchaseOrderPackagingItemsInput>;
  update?: InputMaybe<PurchaseOrderUpdateWithoutPurchaseOrderPackagingItemsInput>;
  upsert?: InputMaybe<PurchaseOrderUpsertWithoutPurchaseOrderPackagingItemsInput>;
};

export type PurchaseOrderUpdateOneRequiredWithoutPurchaseOrderRawMaterialItemsNestedInput = {
  connect?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseOrderCreateOrConnectWithoutPurchaseOrderRawMaterialItemsInput>;
  create?: InputMaybe<PurchaseOrderCreateWithoutPurchaseOrderRawMaterialItemsInput>;
  update?: InputMaybe<PurchaseOrderUpdateWithoutPurchaseOrderRawMaterialItemsInput>;
  upsert?: InputMaybe<PurchaseOrderUpsertWithoutPurchaseOrderRawMaterialItemsInput>;
};

export type PurchaseOrderUpdateWithoutPurchaseOrderPackagingItemsInput = {
  dateArrived?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutPurchaseOrderNestedInput>;
  salesInvoiceNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PurchaseOrderUpdateWithoutPurchaseOrderRawMaterialItemsInput = {
  dateArrived?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemUpdateManyWithoutPurchaseOrderNestedInput>;
  salesInvoiceNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PurchaseOrderUpsertWithoutPurchaseOrderPackagingItemsInput = {
  create: PurchaseOrderCreateWithoutPurchaseOrderPackagingItemsInput;
  update: PurchaseOrderUpdateWithoutPurchaseOrderPackagingItemsInput;
};

export type PurchaseOrderUpsertWithoutPurchaseOrderRawMaterialItemsInput = {
  create: PurchaseOrderCreateWithoutPurchaseOrderRawMaterialItemsInput;
  update: PurchaseOrderUpdateWithoutPurchaseOrderRawMaterialItemsInput;
};

export type PurchaseOrderWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderWhereInput>>;
  dateArrived?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  purchaseOrderPackagingItems?: InputMaybe<PurchaseOrderPackagingItemListRelationFilter>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemListRelationFilter>;
  salesInvoiceNumber?: InputMaybe<StringFilter>;
};

export type PurchaseOrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  salesInvoiceNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  aggregateAuditTrail: AggregateAuditTrail;
  aggregateBetweenEntityTransfer: AggregateBetweenEntityTransfer;
  aggregateBeverage: AggregateBeverage;
  aggregateCheatSheet: AggregateCheatSheet;
  aggregateCmlProduct: AggregateCmlProduct;
  aggregateCmlProductRecipe: AggregateCmlProductRecipe;
  aggregateCmlThrowAway: AggregateCmlThrowAway;
  aggregateCrt: AggregateCrt;
  aggregateDeliveryReceipt: AggregateDeliveryReceipt;
  aggregateDonutMixAndCut: AggregateDonutMixAndCut;
  aggregateEndingSdEmTa: AggregateEndingSdEmTa;
  aggregateMonthlyOutletPackagingInventory: AggregateMonthlyOutletPackagingInventory;
  aggregateOutlet: AggregateOutlet;
  aggregateOutletFinishing: AggregateOutletFinishing;
  aggregatePackaging: AggregatePackaging;
  aggregatePackagingRequest: AggregatePackagingRequest;
  aggregateProjection: AggregateProjection;
  aggregatePurchaseOrder: AggregatePurchaseOrder;
  aggregateRawMaterial: AggregateRawMaterial;
  aggregateRawMaterialFinishingEnding: AggregateRawMaterialFinishingEnding;
  aggregateRawMaterialProduct: AggregateRawMaterialProduct;
  aggregateRawMaterialProductRecipe: AggregateRawMaterialProductRecipe;
  aggregateRawMaterialRelease: AggregateRawMaterialRelease;
  aggregateRawMaterialRequest: AggregateRawMaterialRequest;
  aggregateReceivedDeliveryReceipt: AggregateReceivedDeliveryReceipt;
  aggregateReceivedSdsDeliveryReceipt: AggregateReceivedSdsDeliveryReceipt;
  aggregateReceivedTransfer: AggregateReceivedTransfer;
  aggregateSdsDeliveryReceipt: AggregateSdsDeliveryReceipt;
  aggregateTransfer: AggregateTransfer;
  aggregateUnit: AggregateUnit;
  aggregateUser: AggregateUser;
  aggregateVarianceReportOnCmlTransfer: AggregateTransferCmlItem;
  aggregateVarianceReportOnDeliveryReceiptItem: AggregateDeliveryReceiptItem;
  aggregateVarianceReportOnPackagingTransfer: AggregateTransferPackagingItem;
  aggregateVarianceReportOnRawMaterialTransfer: AggregateTransferRawMaterialItem;
  aggregateVarianceReportOnSdsPackagingItem: AggregateSdsDeliveryReceiptPackagingItem;
  aggregateVarianceReportOnSdsRawMaterialItem: AggregateSdsDeliveryReceiptRawMaterialItem;
  aggregateWarehouseInventory: AggregateWarehouseInventory;
  auditTrails: Array<AuditTrail>;
  betweenEntityTransfer?: Maybe<BetweenEntityTransfer>;
  betweenEntityTransfers: Array<BetweenEntityTransfer>;
  beverage?: Maybe<Beverage>;
  beverages: Array<Beverage>;
  beveragesConsolidatedThrowAwayReportItems: Array<BeveragesConsolidatedThrowAwayReportItem>;
  beveragesDropdown: Array<Beverage>;
  beveragesSalesProductControlReportItems: Array<BeveragesSalesProductControlReportItem>;
  cheatSheet?: Maybe<CheatSheet>;
  cheatSheets: Array<CheatSheet>;
  cmlProduct?: Maybe<CmlProduct>;
  cmlProductIngredients: Array<CmlProductIngredient>;
  cmlProductRecipe?: Maybe<CmlProductRecipe>;
  cmlProductRecipes: Array<CmlProductRecipe>;
  cmlProducts: Array<CmlProduct>;
  cmlProductsDropdown: Array<CmlProduct>;
  cmlProductsSalesProductControlReportItems: Array<CmlProductsSalesProductControlReportItem>;
  cmlThrowAway?: Maybe<CmlThrowAway>;
  cmlThrowAways: Array<CmlThrowAway>;
  crt?: Maybe<Crt>;
  crts: Array<Crt>;
  currentUser?: Maybe<User>;
  dailyRawMaterialFinishingOutletVarianceReport: Array<DailyRawMaterialFinishingOutletVarianceReport>;
  dailyRawMaterialKitchenVarianceReport: Array<DailyRawMaterialKitchenVarianceReport>;
  deliveryReceipt?: Maybe<DeliveryReceipt>;
  deliveryReceiptItems: Array<DeliveryReceiptItem>;
  deliveryReceipts: Array<DeliveryReceipt>;
  deliveryReceiptsDropdown: Array<DeliveryReceipt>;
  donutDistributionPerOutlet: Array<DonutDistributionPerOutlet>;
  donutEndingConsolidations: Array<DonutEndingConsolidation>;
  donutMixAndCut?: Maybe<DonutMixAndCut>;
  donutMixAndCuts: Array<DonutMixAndCut>;
  donutsConsolidatedThrowAwayReportItems: Array<DonutsConsolidatedThrowAwayReportItem>;
  endingSdEmTa?: Maybe<EndingSdEmTa>;
  endingSdEmTas: Array<EndingSdEmTa>;
  findFirstOutletFinishing?: Maybe<OutletFinishing>;
  monthlyOutletPackagingInventories: Array<MonthlyOutletPackagingInventory>;
  monthlyOutletPackagingInventory?: Maybe<MonthlyOutletPackagingInventory>;
  outlet?: Maybe<Outlet>;
  outletFinishing?: Maybe<OutletFinishing>;
  outletFinishings: Array<OutletFinishing>;
  outletPackagingEndingConsolidations: Array<OutletPackagingEndingConsolidation>;
  outlets: Array<Outlet>;
  outletsDropdown: Array<Outlet>;
  packaging?: Maybe<Packaging>;
  packagingProducts: Array<PackagingProduct>;
  packagingRequest?: Maybe<PackagingRequest>;
  packagingRequests: Array<PackagingRequest>;
  packagings: Array<Packaging>;
  packagingsDropdown: Array<Packaging>;
  projection?: Maybe<Projection>;
  projectionItems: Array<ProjectionItem>;
  projectionToRawMaterials: Array<ProjectionToRawMaterial>;
  projections: Array<Projection>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchaseOrders: Array<PurchaseOrder>;
  rawMaterial?: Maybe<RawMaterial>;
  rawMaterialFinishingEnding?: Maybe<RawMaterialFinishingEnding>;
  rawMaterialFinishingEndings: Array<RawMaterialFinishingEnding>;
  rawMaterialProduct?: Maybe<RawMaterialProduct>;
  rawMaterialProductRecipe?: Maybe<RawMaterialProductRecipe>;
  rawMaterialProductRecipes: Array<RawMaterialProductRecipe>;
  rawMaterialProducts: Array<RawMaterialProduct>;
  rawMaterialRelease?: Maybe<RawMaterialRelease>;
  rawMaterialReleaseForBreakdownReferences: Array<RawMaterialReleaseReference>;
  rawMaterialReleaseItemFromBreakdownReferences: Array<RawMaterialReleaseReference>;
  rawMaterialReleaseItemReferences: Array<RawMaterialReleaseReference>;
  rawMaterialReleases: Array<RawMaterialRelease>;
  rawMaterialRequest?: Maybe<RawMaterialRequest>;
  rawMaterialRequests: Array<RawMaterialRequest>;
  rawMaterials: Array<RawMaterial>;
  rawMaterialsConsolidatedThrowAwayReportItems: Array<RawMaterialsConsolidatedThrowAwayReportItem>;
  rawMaterialsDropdown: Array<RawMaterial>;
  rawMaterialsSalesProductControlReportItems: Array<RawMaterialsSalesProductControlReportItem>;
  receivedDeliveryReceipt?: Maybe<ReceivedDeliveryReceipt>;
  receivedDeliveryReceipts: Array<ReceivedDeliveryReceipt>;
  receivedSdsDeliveryReceipt?: Maybe<ReceivedSdsDeliveryReceipt>;
  receivedSdsDeliveryReceipts: Array<ReceivedSdsDeliveryReceipt>;
  receivedTransfer?: Maybe<ReceivedTransfer>;
  receivedTransfers: Array<ReceivedTransfer>;
  salesPackagingControlReportItems: Array<SalesPackagingControlReportItem>;
  sdsDeliveryReceipt?: Maybe<SdsDeliveryReceipt>;
  sdsDeliveryReceiptPackagingItems: Array<SdsDeliveryReceiptPackagingItem>;
  sdsDeliveryReceiptRawMaterialItems: Array<SdsDeliveryReceiptRawMaterialItem>;
  sdsDeliveryReceipts: Array<SdsDeliveryReceipt>;
  sdsDeliveryReceiptsDropdown: Array<SdsDeliveryReceipt>;
  totalDeliveryReceiptSummary: Array<TotalDeliveryReceiptSummary>;
  transfer?: Maybe<Transfer>;
  transferCmlItems: Array<TransferCmlItem>;
  transferPackagingItems: Array<TransferPackagingItem>;
  transferRawMaterialItems: Array<TransferRawMaterialItem>;
  transfers: Array<Transfer>;
  unit?: Maybe<Unit>;
  units: Array<Unit>;
  user?: Maybe<User>;
  users: Array<User>;
  varianceReportOnCmlTransfers: Array<VarianceReportOnCmlTransfer>;
  varianceReportOnDeliveryReceiptItems: Array<VarianceReportOnDeliveryReceiptItem>;
  varianceReportOnPackagingTransfers: Array<VarianceReportOnPackagingTransfer>;
  varianceReportOnRawMaterialTransfers: Array<VarianceReportOnRawMaterialTransfer>;
  varianceReportOnSdsPackagingItems: Array<VarianceReportOnSdsPackagingItem>;
  varianceReportOnSdsRawMaterialItems: Array<VarianceReportOnSdsRawMaterialItem>;
  warehouseInventories: Array<WarehouseInventory>;
  warehouseInventory?: Maybe<WarehouseInventory>;
  warehousePackagingInventoryReportItems: Array<WarehousePackagingInventoryReportItem>;
  warehouseRawMaterialInventoryReportItems: Array<WarehouseRawMaterialInventoryReportItem>;
};


export type QueryAggregateAuditTrailArgs = {
  cursor?: InputMaybe<AuditTrailWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AuditTrailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AuditTrailWhereInput>;
};


export type QueryAggregateBetweenEntityTransferArgs = {
  cursor?: InputMaybe<BetweenEntityTransferWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BetweenEntityTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BetweenEntityTransferWhereInput>;
};


export type QueryAggregateBeverageArgs = {
  cursor?: InputMaybe<BeverageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BeverageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BeverageWhereInput>;
};


export type QueryAggregateCheatSheetArgs = {
  cursor?: InputMaybe<CheatSheetWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CheatSheetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CheatSheetWhereInput>;
};


export type QueryAggregateCmlProductArgs = {
  cursor?: InputMaybe<CmlProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CmlProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductWhereInput>;
};


export type QueryAggregateCmlProductRecipeArgs = {
  cursor?: InputMaybe<CmlProductRecipeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CmlProductRecipeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductRecipeWhereInput>;
};


export type QueryAggregateCmlThrowAwayArgs = {
  cursor?: InputMaybe<CmlThrowAwayWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CmlThrowAwayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayWhereInput>;
};


export type QueryAggregateCrtArgs = {
  cursor?: InputMaybe<CrtWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CrtOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtWhereInput>;
};


export type QueryAggregateDeliveryReceiptArgs = {
  cursor?: InputMaybe<DeliveryReceiptWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptWhereInput>;
};


export type QueryAggregateDonutMixAndCutArgs = {
  cursor?: InputMaybe<DonutMixAndCutWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DonutMixAndCutOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DonutMixAndCutWhereInput>;
};


export type QueryAggregateEndingSdEmTaArgs = {
  cursor?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EndingSdEmTaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaWhereInput>;
};


export type QueryAggregateMonthlyOutletPackagingInventoryArgs = {
  cursor?: InputMaybe<MonthlyOutletPackagingInventoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MonthlyOutletPackagingInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
};


export type QueryAggregateOutletArgs = {
  cursor?: InputMaybe<OutletWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OutletOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
};


export type QueryAggregateOutletFinishingArgs = {
  cursor?: InputMaybe<OutletFinishingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OutletFinishingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletFinishingWhereInput>;
};


export type QueryAggregatePackagingArgs = {
  cursor?: InputMaybe<PackagingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PackagingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingWhereInput>;
};


export type QueryAggregatePackagingRequestArgs = {
  cursor?: InputMaybe<PackagingRequestWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PackagingRequestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingRequestWhereInput>;
};


export type QueryAggregateProjectionArgs = {
  cursor?: InputMaybe<ProjectionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProjectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectionWhereInput>;
};


export type QueryAggregatePurchaseOrderArgs = {
  cursor?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PurchaseOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type QueryAggregateRawMaterialArgs = {
  cursor?: InputMaybe<RawMaterialWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RawMaterialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialWhereInput>;
};


export type QueryAggregateRawMaterialFinishingEndingArgs = {
  cursor?: InputMaybe<RawMaterialFinishingEndingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RawMaterialFinishingEndingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
};


export type QueryAggregateRawMaterialProductArgs = {
  cursor?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RawMaterialProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductWhereInput>;
};


export type QueryAggregateRawMaterialProductRecipeArgs = {
  cursor?: InputMaybe<RawMaterialProductRecipeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RawMaterialProductRecipeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductRecipeWhereInput>;
};


export type QueryAggregateRawMaterialReleaseArgs = {
  cursor?: InputMaybe<RawMaterialReleaseWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RawMaterialReleaseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleaseWhereInput>;
};


export type QueryAggregateRawMaterialRequestArgs = {
  cursor?: InputMaybe<RawMaterialRequestWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RawMaterialRequestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialRequestWhereInput>;
};


export type QueryAggregateReceivedDeliveryReceiptArgs = {
  cursor?: InputMaybe<ReceivedDeliveryReceiptWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReceivedDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedDeliveryReceiptWhereInput>;
};


export type QueryAggregateReceivedSdsDeliveryReceiptArgs = {
  cursor?: InputMaybe<ReceivedSdsDeliveryReceiptWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReceivedSdsDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedSdsDeliveryReceiptWhereInput>;
};


export type QueryAggregateReceivedTransferArgs = {
  cursor?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReceivedTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedTransferWhereInput>;
};


export type QueryAggregateSdsDeliveryReceiptArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
};


export type QueryAggregateTransferArgs = {
  cursor?: InputMaybe<TransferWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferWhereInput>;
};


export type QueryAggregateUnitArgs = {
  cursor?: InputMaybe<UnitWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UnitOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UnitWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAggregateVarianceReportOnCmlTransferArgs = {
  where?: InputMaybe<VarianceReportOnCmlTransferWhereInput>;
};


export type QueryAggregateVarianceReportOnDeliveryReceiptItemArgs = {
  where?: InputMaybe<VarianceReportOnDeliveryReceiptItemWhereInput>;
};


export type QueryAggregateVarianceReportOnPackagingTransferArgs = {
  where?: InputMaybe<VarianceReportOnPackagingTransferWhereInput>;
};


export type QueryAggregateVarianceReportOnRawMaterialTransferArgs = {
  where?: InputMaybe<VarianceReportOnRawMaterialTransferWhereInput>;
};


export type QueryAggregateVarianceReportOnSdsPackagingItemArgs = {
  where?: InputMaybe<VarianceReportOnSdsPackagingItemWhereInput>;
};


export type QueryAggregateVarianceReportOnSdsRawMaterialItemArgs = {
  where?: InputMaybe<VarianceReportOnSdsRawMaterialItemWhereInput>;
};


export type QueryAggregateWarehouseInventoryArgs = {
  cursor?: InputMaybe<WarehouseInventoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<WarehouseInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WarehouseInventoryWhereInput>;
};


export type QueryAuditTrailsArgs = {
  cursor?: InputMaybe<AuditTrailWhereUniqueInput>;
  distinct?: InputMaybe<Array<AuditTrailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AuditTrailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AuditTrailWhereInput>;
};


export type QueryBetweenEntityTransferArgs = {
  where: BetweenEntityTransferWhereUniqueInput;
};


export type QueryBetweenEntityTransfersArgs = {
  cursor?: InputMaybe<BetweenEntityTransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<BetweenEntityTransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BetweenEntityTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BetweenEntityTransferWhereInput>;
};


export type QueryBeverageArgs = {
  where: BeverageWhereUniqueInput;
};


export type QueryBeveragesArgs = {
  cursor?: InputMaybe<BeverageWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeverageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeverageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BeverageWhereInput>;
};


export type QueryBeveragesConsolidatedThrowAwayReportItemsArgs = {
  where?: InputMaybe<ConsolidatedThrowAwayReportItemWhereInput>;
};


export type QueryBeveragesDropdownArgs = {
  cursor?: InputMaybe<BeverageWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeverageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeverageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BeverageWhereInput>;
};


export type QueryBeveragesSalesProductControlReportItemsArgs = {
  where?: InputMaybe<SalesProductControlReportItemWhereInput>;
};


export type QueryCheatSheetArgs = {
  where: CheatSheetWhereUniqueInput;
};


export type QueryCheatSheetsArgs = {
  cursor?: InputMaybe<CheatSheetWhereUniqueInput>;
  distinct?: InputMaybe<Array<CheatSheetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CheatSheetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CheatSheetWhereInput>;
};


export type QueryCmlProductArgs = {
  where: CmlProductWhereUniqueInput;
};


export type QueryCmlProductIngredientsArgs = {
  cursor?: InputMaybe<CmlProductIngredientWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductIngredientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductIngredientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductIngredientWhereInput>;
};


export type QueryCmlProductRecipeArgs = {
  where: CmlProductRecipeWhereUniqueInput;
};


export type QueryCmlProductRecipesArgs = {
  cursor?: InputMaybe<CmlProductRecipeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductRecipeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductRecipeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductRecipeWhereInput>;
};


export type QueryCmlProductsArgs = {
  cursor?: InputMaybe<CmlProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductWhereInput>;
};


export type QueryCmlProductsDropdownArgs = {
  cursor?: InputMaybe<CmlProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductWhereInput>;
};


export type QueryCmlProductsSalesProductControlReportItemsArgs = {
  where?: InputMaybe<SalesProductControlReportItemWhereInput>;
};


export type QueryCmlThrowAwayArgs = {
  where: CmlThrowAwayWhereUniqueInput;
};


export type QueryCmlThrowAwaysArgs = {
  cursor?: InputMaybe<CmlThrowAwayWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlThrowAwayScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlThrowAwayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayWhereInput>;
};


export type QueryCrtArgs = {
  where: CrtWhereUniqueInput;
};


export type QueryCrtsArgs = {
  cursor?: InputMaybe<CrtWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtWhereInput>;
};


export type QueryDailyRawMaterialFinishingOutletVarianceReportArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DailyRawMaterialFinishingOutletVarianceReportWhereInput>;
};


export type QueryDailyRawMaterialKitchenVarianceReportArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DailyRawMaterialKitchenVarianceReportWhereInput>;
};


export type QueryDeliveryReceiptArgs = {
  where: DeliveryReceiptWhereUniqueInput;
};


export type QueryDeliveryReceiptItemsArgs = {
  cursor?: InputMaybe<DeliveryReceiptItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryReceiptItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryReceiptItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptItemWhereInput>;
};


export type QueryDeliveryReceiptsArgs = {
  cursor?: InputMaybe<DeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptWhereInput>;
};


export type QueryDeliveryReceiptsDropdownArgs = {
  cursor?: InputMaybe<DeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptWhereInput>;
};


export type QueryDonutDistributionPerOutletArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DonutDistributionPerOutletWhereInput>;
};


export type QueryDonutEndingConsolidationsArgs = {
  where?: InputMaybe<DonutEndingConsolidationWhereInput>;
};


export type QueryDonutMixAndCutArgs = {
  where: DonutMixAndCutWhereUniqueInput;
};


export type QueryDonutMixAndCutsArgs = {
  cursor?: InputMaybe<DonutMixAndCutWhereUniqueInput>;
  distinct?: InputMaybe<Array<DonutMixAndCutScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DonutMixAndCutOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DonutMixAndCutWhereInput>;
};


export type QueryDonutsConsolidatedThrowAwayReportItemsArgs = {
  where?: InputMaybe<ConsolidatedThrowAwayReportItemWhereInput>;
};


export type QueryEndingSdEmTaArgs = {
  where: EndingSdEmTaWhereUniqueInput;
};


export type QueryEndingSdEmTasArgs = {
  cursor?: InputMaybe<EndingSdEmTaWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaWhereInput>;
};


export type QueryFindFirstOutletFinishingArgs = {
  cursor?: InputMaybe<OutletFinishingWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutletFinishingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutletFinishingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletFinishingWhereInput>;
};


export type QueryMonthlyOutletPackagingInventoriesArgs = {
  cursor?: InputMaybe<MonthlyOutletPackagingInventoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<MonthlyOutletPackagingInventoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MonthlyOutletPackagingInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
};


export type QueryMonthlyOutletPackagingInventoryArgs = {
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
};


export type QueryOutletArgs = {
  where: OutletWhereUniqueInput;
};


export type QueryOutletFinishingArgs = {
  where: OutletFinishingWhereUniqueInput;
};


export type QueryOutletFinishingsArgs = {
  cursor?: InputMaybe<OutletFinishingWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutletFinishingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutletFinishingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletFinishingWhereInput>;
};


export type QueryOutletPackagingEndingConsolidationsArgs = {
  where?: InputMaybe<OutletPackagingEndingConsolidationWhereInput>;
};


export type QueryOutletsArgs = {
  cursor?: InputMaybe<OutletWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutletScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutletOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
};


export type QueryOutletsDropdownArgs = {
  cursor?: InputMaybe<OutletWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutletScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutletOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
};


export type QueryPackagingArgs = {
  where: PackagingWhereUniqueInput;
};


export type QueryPackagingProductsArgs = {
  cursor?: InputMaybe<PackagingProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingProductWhereInput>;
};


export type QueryPackagingRequestArgs = {
  where: PackagingRequestWhereUniqueInput;
};


export type QueryPackagingRequestsArgs = {
  cursor?: InputMaybe<PackagingRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingRequestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingRequestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingRequestWhereInput>;
};


export type QueryPackagingsArgs = {
  cursor?: InputMaybe<PackagingWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingWhereInput>;
};


export type QueryPackagingsDropdownArgs = {
  cursor?: InputMaybe<PackagingWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingWhereInput>;
};


export type QueryProjectionArgs = {
  where: ProjectionWhereUniqueInput;
};


export type QueryProjectionItemsArgs = {
  cursor?: InputMaybe<ProjectionItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectionItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectionItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectionItemWhereInput>;
};


export type QueryProjectionToRawMaterialsArgs = {
  where?: InputMaybe<ProjectionToRawMaterialWhereInput>;
};


export type QueryProjectionsArgs = {
  cursor?: InputMaybe<ProjectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectionWhereInput>;
};


export type QueryPurchaseOrderArgs = {
  where: PurchaseOrderWhereUniqueInput;
};


export type QueryPurchaseOrdersArgs = {
  cursor?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<PurchaseOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PurchaseOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type QueryRawMaterialArgs = {
  where: RawMaterialWhereUniqueInput;
};


export type QueryRawMaterialFinishingEndingArgs = {
  where: RawMaterialFinishingEndingWhereUniqueInput;
};


export type QueryRawMaterialFinishingEndingsArgs = {
  cursor?: InputMaybe<RawMaterialFinishingEndingWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialFinishingEndingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialFinishingEndingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
};


export type QueryRawMaterialProductArgs = {
  where: RawMaterialProductWhereUniqueInput;
};


export type QueryRawMaterialProductRecipeArgs = {
  where: RawMaterialProductRecipeWhereUniqueInput;
};


export type QueryRawMaterialProductRecipesArgs = {
  cursor?: InputMaybe<RawMaterialProductRecipeWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialProductRecipeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialProductRecipeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductRecipeWhereInput>;
};


export type QueryRawMaterialProductsArgs = {
  cursor?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductWhereInput>;
};


export type QueryRawMaterialReleaseArgs = {
  where: RawMaterialReleaseWhereUniqueInput;
};


export type QueryRawMaterialReleaseForBreakdownReferencesArgs = {
  data: RawMaterialReleaseReferenceInput;
};


export type QueryRawMaterialReleaseItemFromBreakdownReferencesArgs = {
  data: Array<RawMaterialReleaseItemsFromBreakdownInput>;
};


export type QueryRawMaterialReleaseItemReferencesArgs = {
  data: RawMaterialReleaseReferenceInput;
};


export type QueryRawMaterialReleasesArgs = {
  cursor?: InputMaybe<RawMaterialReleaseWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialReleaseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialReleaseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleaseWhereInput>;
};


export type QueryRawMaterialRequestArgs = {
  where: RawMaterialRequestWhereUniqueInput;
};


export type QueryRawMaterialRequestsArgs = {
  cursor?: InputMaybe<RawMaterialRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialRequestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialRequestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialRequestWhereInput>;
};


export type QueryRawMaterialsArgs = {
  cursor?: InputMaybe<RawMaterialWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialWhereInput>;
};


export type QueryRawMaterialsConsolidatedThrowAwayReportItemsArgs = {
  where?: InputMaybe<ConsolidatedThrowAwayReportItemWhereInput>;
};


export type QueryRawMaterialsDropdownArgs = {
  cursor?: InputMaybe<RawMaterialWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialWhereInput>;
};


export type QueryRawMaterialsSalesProductControlReportItemsArgs = {
  where?: InputMaybe<SalesProductControlReportItemWhereInput>;
};


export type QueryReceivedDeliveryReceiptArgs = {
  where: ReceivedDeliveryReceiptWhereUniqueInput;
};


export type QueryReceivedDeliveryReceiptsArgs = {
  cursor?: InputMaybe<ReceivedDeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedDeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedDeliveryReceiptWhereInput>;
};


export type QueryReceivedSdsDeliveryReceiptArgs = {
  where: ReceivedSdsDeliveryReceiptWhereUniqueInput;
};


export type QueryReceivedSdsDeliveryReceiptsArgs = {
  cursor?: InputMaybe<ReceivedSdsDeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedSdsDeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedSdsDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedSdsDeliveryReceiptWhereInput>;
};


export type QueryReceivedTransferArgs = {
  where: ReceivedTransferWhereUniqueInput;
};


export type QueryReceivedTransfersArgs = {
  cursor?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedTransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedTransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedTransferWhereInput>;
};


export type QuerySalesPackagingControlReportItemsArgs = {
  where?: InputMaybe<SalesPackagingControlReportItemWhereInput>;
};


export type QuerySdsDeliveryReceiptArgs = {
  where: SdsDeliveryReceiptWhereUniqueInput;
};


export type QuerySdsDeliveryReceiptPackagingItemsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
};


export type QuerySdsDeliveryReceiptRawMaterialItemsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
};


export type QuerySdsDeliveryReceiptsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
};


export type QuerySdsDeliveryReceiptsDropdownArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
};


export type QueryTotalDeliveryReceiptSummaryArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TotalDeliveryReceiptSummaryWhereInput>;
};


export type QueryTransferArgs = {
  where: TransferWhereUniqueInput;
};


export type QueryTransferCmlItemsArgs = {
  cursor?: InputMaybe<TransferCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferCmlItemWhereInput>;
};


export type QueryTransferPackagingItemsArgs = {
  cursor?: InputMaybe<TransferPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferPackagingItemWhereInput>;
};


export type QueryTransferRawMaterialItemsArgs = {
  cursor?: InputMaybe<TransferRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferRawMaterialItemWhereInput>;
};


export type QueryTransfersArgs = {
  cursor?: InputMaybe<TransferWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferWhereInput>;
};


export type QueryUnitArgs = {
  where: UnitWhereUniqueInput;
};


export type QueryUnitsArgs = {
  cursor?: InputMaybe<UnitWhereUniqueInput>;
  distinct?: InputMaybe<Array<UnitScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UnitOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UnitWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVarianceReportOnCmlTransfersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnCmlTransferWhereInput>;
};


export type QueryVarianceReportOnDeliveryReceiptItemsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnDeliveryReceiptItemWhereInput>;
};


export type QueryVarianceReportOnPackagingTransfersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnPackagingTransferWhereInput>;
};


export type QueryVarianceReportOnRawMaterialTransfersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnRawMaterialTransferWhereInput>;
};


export type QueryVarianceReportOnSdsPackagingItemsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnSdsPackagingItemWhereInput>;
};


export type QueryVarianceReportOnSdsRawMaterialItemsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnSdsRawMaterialItemWhereInput>;
};


export type QueryWarehouseInventoriesArgs = {
  cursor?: InputMaybe<WarehouseInventoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<WarehouseInventoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WarehouseInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WarehouseInventoryWhereInput>;
};


export type QueryWarehouseInventoryArgs = {
  where: WarehouseInventoryWhereUniqueInput;
};


export type QueryWarehousePackagingInventoryReportItemsArgs = {
  where?: InputMaybe<WarehousePackagingInventoryReportItemWhereInput>;
};


export type QueryWarehouseRawMaterialInventoryReportItemsArgs = {
  where?: InputMaybe<WarehouseRawMaterialInventoryReportItemWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RawMaterial = {
  __typename?: 'RawMaterial';
  _count?: Maybe<RawMaterialCount>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  rawMaterialCategory: RawMaterialCategory;
  rawMaterialProducts: Array<RawMaterialProduct>;
  status: Status;
};


export type RawMaterialRawMaterialProductsArgs = {
  cursor?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductWhereInput>;
};

export type RawMaterialAvgAggregate = {
  __typename?: 'RawMaterialAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export { RawMaterialCategory };

export type RawMaterialCount = {
  __typename?: 'RawMaterialCount';
  rawMaterialProducts: Scalars['Int']['output'];
};

export type RawMaterialCountAggregate = {
  __typename?: 'RawMaterialCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  rawMaterialCategory: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
};

export type RawMaterialCreateInput = {
  name: Scalars['String']['input'];
  rawMaterialCategory: RawMaterialCategory;
  rawMaterialProducts?: InputMaybe<RawMaterialProductCreateNestedManyWithoutRawMaterialInput>;
  status?: InputMaybe<Status>;
};

export type RawMaterialCreateNestedOneWithoutRawMaterialProductsInput = {
  connect?: InputMaybe<RawMaterialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialCreateOrConnectWithoutRawMaterialProductsInput>;
  create?: InputMaybe<RawMaterialCreateWithoutRawMaterialProductsInput>;
};

export type RawMaterialCreateOrConnectWithoutRawMaterialProductsInput = {
  create: RawMaterialCreateWithoutRawMaterialProductsInput;
  where: RawMaterialWhereUniqueInput;
};

export type RawMaterialCreateWithoutRawMaterialProductsInput = {
  name: Scalars['String']['input'];
  rawMaterialCategory: RawMaterialCategory;
  status?: InputMaybe<Status>;
};

export type RawMaterialFinishingEnding = {
  __typename?: 'RawMaterialFinishingEnding';
  _count?: Maybe<RawMaterialFinishingEndingCount>;
  controlNumber: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id: Scalars['Int']['output'];
  outlet?: Maybe<Outlet>;
  outletId?: Maybe<Scalars['Int']['output']>;
  rawMaterialFinishingEndingItems: Array<RawMaterialFinishingEndingItem>;
  rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation;
  remarks?: Maybe<Scalars['String']['output']>;
};


export type RawMaterialFinishingEndingRawMaterialFinishingEndingItemsArgs = {
  cursor?: InputMaybe<RawMaterialFinishingEndingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialFinishingEndingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialFinishingEndingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialFinishingEndingItemWhereInput>;
};

export type RawMaterialFinishingEndingAvgAggregate = {
  __typename?: 'RawMaterialFinishingEndingAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
};

export type RawMaterialFinishingEndingCount = {
  __typename?: 'RawMaterialFinishingEndingCount';
  rawMaterialFinishingEndingItems: Scalars['Int']['output'];
};

export type RawMaterialFinishingEndingCountAggregate = {
  __typename?: 'RawMaterialFinishingEndingCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
  rawMaterialFinishingEndingLocation: Scalars['Int']['output'];
  remarks: Scalars['Int']['output'];
};

export type RawMaterialFinishingEndingCreateInput = {
  controlNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet?: InputMaybe<OutletCreateNestedOneWithoutRawMaterialFinishingEndingsInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialFinishingEndingInput>;
  rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type RawMaterialFinishingEndingCreateManyOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id?: InputMaybe<Scalars['Int']['input']>;
  rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type RawMaterialFinishingEndingCreateManyOutletInputEnvelope = {
  data: Array<RawMaterialFinishingEndingCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialFinishingEndingCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<RawMaterialFinishingEndingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialFinishingEndingCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<RawMaterialFinishingEndingCreateWithoutOutletInput>>;
  createMany?: InputMaybe<RawMaterialFinishingEndingCreateManyOutletInputEnvelope>;
};

export type RawMaterialFinishingEndingCreateNestedOneWithoutRawMaterialFinishingEndingItemsInput = {
  connect?: InputMaybe<RawMaterialFinishingEndingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialFinishingEndingCreateOrConnectWithoutRawMaterialFinishingEndingItemsInput>;
  create?: InputMaybe<RawMaterialFinishingEndingCreateWithoutRawMaterialFinishingEndingItemsInput>;
};

export type RawMaterialFinishingEndingCreateOrConnectWithoutOutletInput = {
  create: RawMaterialFinishingEndingCreateWithoutOutletInput;
  where: RawMaterialFinishingEndingWhereUniqueInput;
};

export type RawMaterialFinishingEndingCreateOrConnectWithoutRawMaterialFinishingEndingItemsInput = {
  create: RawMaterialFinishingEndingCreateWithoutRawMaterialFinishingEndingItemsInput;
  where: RawMaterialFinishingEndingWhereUniqueInput;
};

export type RawMaterialFinishingEndingCreateWithoutOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialFinishingEndingInput>;
  rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type RawMaterialFinishingEndingCreateWithoutRawMaterialFinishingEndingItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outlet?: InputMaybe<OutletCreateNestedOneWithoutRawMaterialFinishingEndingsInput>;
  rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type RawMaterialFinishingEndingItem = {
  __typename?: 'RawMaterialFinishingEndingItem';
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialFinishingEnding: RawMaterialFinishingEnding;
  rawMaterialFinishingEndingId: Scalars['Int']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
};

export type RawMaterialFinishingEndingItemCreateManyRawMaterialFinishingEndingInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type RawMaterialFinishingEndingItemCreateManyRawMaterialFinishingEndingInputEnvelope = {
  data: Array<RawMaterialFinishingEndingItemCreateManyRawMaterialFinishingEndingInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialFinishingEndingItemCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialFinishingEndingId: Scalars['Int']['input'];
};

export type RawMaterialFinishingEndingItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<RawMaterialFinishingEndingItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialFinishingEndingInput = {
  connect?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialFinishingEndingItemCreateOrConnectWithoutRawMaterialFinishingEndingInput>>;
  create?: InputMaybe<Array<RawMaterialFinishingEndingItemCreateWithoutRawMaterialFinishingEndingInput>>;
  createMany?: InputMaybe<RawMaterialFinishingEndingItemCreateManyRawMaterialFinishingEndingInputEnvelope>;
};

export type RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialFinishingEndingItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialFinishingEndingItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialFinishingEndingItemCreateManyRawMaterialProductInputEnvelope>;
};

export type RawMaterialFinishingEndingItemCreateOrConnectWithoutRawMaterialFinishingEndingInput = {
  create: RawMaterialFinishingEndingItemCreateWithoutRawMaterialFinishingEndingInput;
  where: RawMaterialFinishingEndingItemWhereUniqueInput;
};

export type RawMaterialFinishingEndingItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: RawMaterialFinishingEndingItemCreateWithoutRawMaterialProductInput;
  where: RawMaterialFinishingEndingItemWhereUniqueInput;
};

export type RawMaterialFinishingEndingItemCreateWithoutRawMaterialFinishingEndingInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutRawMaterialFinishingEndingItemsInput;
};

export type RawMaterialFinishingEndingItemCreateWithoutRawMaterialProductInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialFinishingEnding: RawMaterialFinishingEndingCreateNestedOneWithoutRawMaterialFinishingEndingItemsInput;
};

export type RawMaterialFinishingEndingItemListRelationFilter = {
  every?: InputMaybe<RawMaterialFinishingEndingItemWhereInput>;
  none?: InputMaybe<RawMaterialFinishingEndingItemWhereInput>;
  some?: InputMaybe<RawMaterialFinishingEndingItemWhereInput>;
};

export type RawMaterialFinishingEndingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialFinishingEndingItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialFinishingEnding?: InputMaybe<RawMaterialFinishingEndingOrderByWithRelationInput>;
  rawMaterialFinishingEndingId?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
};

export enum RawMaterialFinishingEndingItemScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialFinishingEndingId = 'rawMaterialFinishingEndingId',
  RawMaterialProductId = 'rawMaterialProductId'
}

export type RawMaterialFinishingEndingItemScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialFinishingEndingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialFinishingEndingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialFinishingEndingItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialFinishingEndingId?: InputMaybe<IntFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type RawMaterialFinishingEndingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type RawMaterialFinishingEndingItemUpdateManyWithWhereWithoutRawMaterialFinishingEndingInput = {
  data: RawMaterialFinishingEndingItemUpdateManyMutationInput;
  where: RawMaterialFinishingEndingItemScalarWhereInput;
};

export type RawMaterialFinishingEndingItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: RawMaterialFinishingEndingItemUpdateManyMutationInput;
  where: RawMaterialFinishingEndingItemScalarWhereInput;
};

export type RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialFinishingEndingNestedInput = {
  connect?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialFinishingEndingItemCreateOrConnectWithoutRawMaterialFinishingEndingInput>>;
  create?: InputMaybe<Array<RawMaterialFinishingEndingItemCreateWithoutRawMaterialFinishingEndingInput>>;
  createMany?: InputMaybe<RawMaterialFinishingEndingItemCreateManyRawMaterialFinishingEndingInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialFinishingEndingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialFinishingEndingItemUpdateWithWhereUniqueWithoutRawMaterialFinishingEndingInput>>;
  updateMany?: InputMaybe<Array<RawMaterialFinishingEndingItemUpdateManyWithWhereWithoutRawMaterialFinishingEndingInput>>;
  upsert?: InputMaybe<Array<RawMaterialFinishingEndingItemUpsertWithWhereUniqueWithoutRawMaterialFinishingEndingInput>>;
};

export type RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialFinishingEndingItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialFinishingEndingItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialFinishingEndingItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialFinishingEndingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialFinishingEndingItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<RawMaterialFinishingEndingItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<RawMaterialFinishingEndingItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type RawMaterialFinishingEndingItemUpdateWithWhereUniqueWithoutRawMaterialFinishingEndingInput = {
  data: RawMaterialFinishingEndingItemUpdateWithoutRawMaterialFinishingEndingInput;
  where: RawMaterialFinishingEndingItemWhereUniqueInput;
};

export type RawMaterialFinishingEndingItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: RawMaterialFinishingEndingItemUpdateWithoutRawMaterialProductInput;
  where: RawMaterialFinishingEndingItemWhereUniqueInput;
};

export type RawMaterialFinishingEndingItemUpdateWithoutRawMaterialFinishingEndingInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutRawMaterialFinishingEndingItemsNestedInput>;
};

export type RawMaterialFinishingEndingItemUpdateWithoutRawMaterialProductInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialFinishingEnding?: InputMaybe<RawMaterialFinishingEndingUpdateOneRequiredWithoutRawMaterialFinishingEndingItemsNestedInput>;
};

export type RawMaterialFinishingEndingItemUpsertWithWhereUniqueWithoutRawMaterialFinishingEndingInput = {
  create: RawMaterialFinishingEndingItemCreateWithoutRawMaterialFinishingEndingInput;
  update: RawMaterialFinishingEndingItemUpdateWithoutRawMaterialFinishingEndingInput;
  where: RawMaterialFinishingEndingItemWhereUniqueInput;
};

export type RawMaterialFinishingEndingItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: RawMaterialFinishingEndingItemCreateWithoutRawMaterialProductInput;
  update: RawMaterialFinishingEndingItemUpdateWithoutRawMaterialProductInput;
  where: RawMaterialFinishingEndingItemWhereUniqueInput;
};

export type RawMaterialFinishingEndingItemWhereInput = {
  AND?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialFinishingEndingItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialFinishingEnding?: InputMaybe<RawMaterialFinishingEndingRelationFilter>;
  rawMaterialFinishingEndingId?: InputMaybe<IntFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
};

export type RawMaterialFinishingEndingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RawMaterialFinishingEndingListRelationFilter = {
  every?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
  none?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
  some?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
};

export { RawMaterialFinishingEndingLocation };

export type RawMaterialFinishingEndingMaxAggregate = {
  __typename?: 'RawMaterialFinishingEndingMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  rawMaterialFinishingEndingLocation?: Maybe<RawMaterialFinishingEndingLocation>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type RawMaterialFinishingEndingMinAggregate = {
  __typename?: 'RawMaterialFinishingEndingMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  rawMaterialFinishingEndingLocation?: Maybe<RawMaterialFinishingEndingLocation>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type RawMaterialFinishingEndingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialFinishingEndingOrderByWithRelationInput = {
  controlNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemOrderByRelationAggregateInput>;
  rawMaterialFinishingEndingLocation?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
};

export type RawMaterialFinishingEndingOutletIdDateDeliveryShiftScheduleRawMaterialFinishingEndingLocationCompoundUniqueInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  outletId: Scalars['Int']['input'];
  rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation;
};

export type RawMaterialFinishingEndingRelationFilter = {
  is?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
  isNot?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
};

export enum RawMaterialFinishingEndingScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Id = 'id',
  OutletId = 'outletId',
  RawMaterialFinishingEndingLocation = 'rawMaterialFinishingEndingLocation',
  Remarks = 'remarks'
}

export type RawMaterialFinishingEndingScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialFinishingEndingScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialFinishingEndingScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialFinishingEndingScalarWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outletId?: InputMaybe<IntNullableFilter>;
  rawMaterialFinishingEndingLocation?: InputMaybe<EnumRawMaterialFinishingEndingLocationFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
};

export type RawMaterialFinishingEndingSumAggregate = {
  __typename?: 'RawMaterialFinishingEndingSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialFinishingEndingUpdateInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneWithoutRawMaterialFinishingEndingsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialFinishingEndingNestedInput>;
  rawMaterialFinishingEndingLocation?: InputMaybe<EnumRawMaterialFinishingEndingLocationFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RawMaterialFinishingEndingUpdateManyMutationInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  rawMaterialFinishingEndingLocation?: InputMaybe<EnumRawMaterialFinishingEndingLocationFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RawMaterialFinishingEndingUpdateManyWithWhereWithoutOutletInput = {
  data: RawMaterialFinishingEndingUpdateManyMutationInput;
  where: RawMaterialFinishingEndingScalarWhereInput;
};

export type RawMaterialFinishingEndingUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<RawMaterialFinishingEndingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialFinishingEndingCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<RawMaterialFinishingEndingCreateWithoutOutletInput>>;
  createMany?: InputMaybe<RawMaterialFinishingEndingCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialFinishingEndingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialFinishingEndingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialFinishingEndingWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialFinishingEndingWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialFinishingEndingUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<RawMaterialFinishingEndingUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<RawMaterialFinishingEndingUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type RawMaterialFinishingEndingUpdateOneRequiredWithoutRawMaterialFinishingEndingItemsNestedInput = {
  connect?: InputMaybe<RawMaterialFinishingEndingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialFinishingEndingCreateOrConnectWithoutRawMaterialFinishingEndingItemsInput>;
  create?: InputMaybe<RawMaterialFinishingEndingCreateWithoutRawMaterialFinishingEndingItemsInput>;
  update?: InputMaybe<RawMaterialFinishingEndingUpdateWithoutRawMaterialFinishingEndingItemsInput>;
  upsert?: InputMaybe<RawMaterialFinishingEndingUpsertWithoutRawMaterialFinishingEndingItemsInput>;
};

export type RawMaterialFinishingEndingUpdateWithWhereUniqueWithoutOutletInput = {
  data: RawMaterialFinishingEndingUpdateWithoutOutletInput;
  where: RawMaterialFinishingEndingWhereUniqueInput;
};

export type RawMaterialFinishingEndingUpdateWithoutOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialFinishingEndingNestedInput>;
  rawMaterialFinishingEndingLocation?: InputMaybe<EnumRawMaterialFinishingEndingLocationFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RawMaterialFinishingEndingUpdateWithoutRawMaterialFinishingEndingItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneWithoutRawMaterialFinishingEndingsNestedInput>;
  rawMaterialFinishingEndingLocation?: InputMaybe<EnumRawMaterialFinishingEndingLocationFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RawMaterialFinishingEndingUpsertWithWhereUniqueWithoutOutletInput = {
  create: RawMaterialFinishingEndingCreateWithoutOutletInput;
  update: RawMaterialFinishingEndingUpdateWithoutOutletInput;
  where: RawMaterialFinishingEndingWhereUniqueInput;
};

export type RawMaterialFinishingEndingUpsertWithoutRawMaterialFinishingEndingItemsInput = {
  create: RawMaterialFinishingEndingCreateWithoutRawMaterialFinishingEndingItemsInput;
  update: RawMaterialFinishingEndingUpdateWithoutRawMaterialFinishingEndingItemsInput;
};

export type RawMaterialFinishingEndingWhereInput = {
  AND?: InputMaybe<Array<RawMaterialFinishingEndingWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialFinishingEndingWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialFinishingEndingWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntNullableFilter>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemListRelationFilter>;
  rawMaterialFinishingEndingLocation?: InputMaybe<EnumRawMaterialFinishingEndingLocationFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
};

export type RawMaterialFinishingEndingWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  outletId_date_deliveryShiftSchedule_rawMaterialFinishingEndingLocation?: InputMaybe<RawMaterialFinishingEndingOutletIdDateDeliveryShiftScheduleRawMaterialFinishingEndingLocationCompoundUniqueInput>;
};

export type RawMaterialMaxAggregate = {
  __typename?: 'RawMaterialMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rawMaterialCategory?: Maybe<RawMaterialCategory>;
  status?: Maybe<Status>;
};

export type RawMaterialMinAggregate = {
  __typename?: 'RawMaterialMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rawMaterialCategory?: Maybe<RawMaterialCategory>;
  status?: Maybe<Status>;
};

export type RawMaterialOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rawMaterialCategory?: InputMaybe<SortOrder>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
};

export type RawMaterialProduct = {
  __typename?: 'RawMaterialProduct';
  _count?: Maybe<RawMaterialProductCount>;
  baseUnit: Scalars['Boolean']['output'];
  betweenEntityTransferRawMaterialItems: Array<BetweenEntityTransferRawMaterialItem>;
  beverages: Array<Beverage>;
  cmlProductIngredients: Array<CmlProductIngredient>;
  cmlThrowAwayRawMaterialItems: Array<CmlThrowAwayRawMaterialItem>;
  conversionToBaseUnit: Scalars['Float']['output'];
  crtRawMaterialItems: Array<CrtRawMaterialItem>;
  endingSdEmTaRawMaterialItems: Array<EndingSdEmTaRawMaterialItem>;
  id: Scalars['Int']['output'];
  purchaseOrderRawMaterialItems: Array<PurchaseOrderRawMaterialItem>;
  rawMaterial: RawMaterial;
  rawMaterialFinishingEndingItems: Array<RawMaterialFinishingEndingItem>;
  rawMaterialId: Scalars['Int']['output'];
  rawMaterialProductIngredients: Array<RawMaterialProductIngredient>;
  rawMaterialProductRecipe?: Maybe<RawMaterialProductRecipe>;
  rawMaterialReleaseForBreakdowns: Array<RawMaterialReleaseForBreakdown>;
  rawMaterialReleaseItems: Array<RawMaterialReleaseItem>;
  rawMaterialRequestItems: Array<RawMaterialRequestItem>;
  sdsDeliveryReceiptRawMaterialItems: Array<SdsDeliveryReceiptRawMaterialItem>;
  transferRawMaterialItems: Array<TransferRawMaterialItem>;
  unit: Unit;
  unitId: Scalars['Int']['output'];
  warehouseInventoryRawMaterialItems: Array<WarehouseInventoryRawMaterialItem>;
};


export type RawMaterialProductBetweenEntityTransferRawMaterialItemsArgs = {
  cursor?: InputMaybe<BetweenEntityTransferRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BetweenEntityTransferRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BetweenEntityTransferRawMaterialItemWhereInput>;
};


export type RawMaterialProductBeveragesArgs = {
  cursor?: InputMaybe<BeverageWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeverageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeverageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BeverageWhereInput>;
};


export type RawMaterialProductCmlProductIngredientsArgs = {
  cursor?: InputMaybe<CmlProductIngredientWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductIngredientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductIngredientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductIngredientWhereInput>;
};


export type RawMaterialProductCmlThrowAwayRawMaterialItemsArgs = {
  cursor?: InputMaybe<CmlThrowAwayRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlThrowAwayRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlThrowAwayRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayRawMaterialItemWhereInput>;
};


export type RawMaterialProductCrtRawMaterialItemsArgs = {
  cursor?: InputMaybe<CrtRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CrtRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CrtRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtRawMaterialItemWhereInput>;
};


export type RawMaterialProductEndingSdEmTaRawMaterialItemsArgs = {
  cursor?: InputMaybe<EndingSdEmTaRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<EndingSdEmTaRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EndingSdEmTaRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaRawMaterialItemWhereInput>;
};


export type RawMaterialProductPurchaseOrderRawMaterialItemsArgs = {
  cursor?: InputMaybe<PurchaseOrderRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PurchaseOrderRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PurchaseOrderRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderRawMaterialItemWhereInput>;
};


export type RawMaterialProductRawMaterialFinishingEndingItemsArgs = {
  cursor?: InputMaybe<RawMaterialFinishingEndingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialFinishingEndingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialFinishingEndingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialFinishingEndingItemWhereInput>;
};


export type RawMaterialProductRawMaterialProductIngredientsArgs = {
  cursor?: InputMaybe<RawMaterialProductIngredientWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialProductIngredientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialProductIngredientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductIngredientWhereInput>;
};


export type RawMaterialProductRawMaterialReleaseForBreakdownsArgs = {
  cursor?: InputMaybe<RawMaterialReleaseForBreakdownWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialReleaseForBreakdownScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialReleaseForBreakdownOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleaseForBreakdownWhereInput>;
};


export type RawMaterialProductRawMaterialReleaseItemsArgs = {
  cursor?: InputMaybe<RawMaterialReleaseItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialReleaseItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialReleaseItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleaseItemWhereInput>;
};


export type RawMaterialProductRawMaterialRequestItemsArgs = {
  cursor?: InputMaybe<RawMaterialRequestItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialRequestItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialRequestItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialRequestItemWhereInput>;
};


export type RawMaterialProductSdsDeliveryReceiptRawMaterialItemsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
};


export type RawMaterialProductTransferRawMaterialItemsArgs = {
  cursor?: InputMaybe<TransferRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferRawMaterialItemWhereInput>;
};


export type RawMaterialProductWarehouseInventoryRawMaterialItemsArgs = {
  cursor?: InputMaybe<WarehouseInventoryRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<WarehouseInventoryRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WarehouseInventoryRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WarehouseInventoryRawMaterialItemWhereInput>;
};

export type RawMaterialProductAvgAggregate = {
  __typename?: 'RawMaterialProductAvgAggregate';
  conversionToBaseUnit?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  rawMaterialId?: Maybe<Scalars['Float']['output']>;
  unitId?: Maybe<Scalars['Float']['output']>;
};

export type RawMaterialProductCount = {
  __typename?: 'RawMaterialProductCount';
  betweenEntityTransferRawMaterialItems: Scalars['Int']['output'];
  beverages: Scalars['Int']['output'];
  cmlProductIngredients: Scalars['Int']['output'];
  cmlThrowAwayRawMaterialItems: Scalars['Int']['output'];
  crtRawMaterialItems: Scalars['Int']['output'];
  endingSdEmTaRawMaterialItems: Scalars['Int']['output'];
  purchaseOrderRawMaterialItems: Scalars['Int']['output'];
  rawMaterialFinishingEndingItems: Scalars['Int']['output'];
  rawMaterialProductIngredients: Scalars['Int']['output'];
  rawMaterialReleaseForBreakdowns: Scalars['Int']['output'];
  rawMaterialReleaseItems: Scalars['Int']['output'];
  rawMaterialRequestItems: Scalars['Int']['output'];
  sdsDeliveryReceiptRawMaterialItems: Scalars['Int']['output'];
  transferRawMaterialItems: Scalars['Int']['output'];
  warehouseInventoryRawMaterialItems: Scalars['Int']['output'];
};

export type RawMaterialProductCountAggregate = {
  __typename?: 'RawMaterialProductCountAggregate';
  _all: Scalars['Int']['output'];
  baseUnit: Scalars['Int']['output'];
  conversionToBaseUnit: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  rawMaterialId: Scalars['Int']['output'];
  unitId: Scalars['Int']['output'];
};

export type RawMaterialProductCreateInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateManyRawMaterialInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  conversionToBaseUnit: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  unitId: Scalars['Int']['input'];
};

export type RawMaterialProductCreateManyRawMaterialInputEnvelope = {
  data: Array<RawMaterialProductCreateManyRawMaterialInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialProductCreateManyUnitInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  conversionToBaseUnit: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  rawMaterialId: Scalars['Int']['input'];
};

export type RawMaterialProductCreateManyUnitInputEnvelope = {
  data: Array<RawMaterialProductCreateManyUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialProductCreateNestedManyWithoutRawMaterialInput = {
  connect?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialProductCreateOrConnectWithoutRawMaterialInput>>;
  create?: InputMaybe<Array<RawMaterialProductCreateWithoutRawMaterialInput>>;
  createMany?: InputMaybe<RawMaterialProductCreateManyRawMaterialInputEnvelope>;
};

export type RawMaterialProductCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialProductCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<RawMaterialProductCreateWithoutUnitInput>>;
  createMany?: InputMaybe<RawMaterialProductCreateManyUnitInputEnvelope>;
};

export type RawMaterialProductCreateNestedOneWithoutBetweenEntityTransferRawMaterialItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutBetweenEntityTransferRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutBetweenEntityTransferRawMaterialItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutBeveragesInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutBeveragesInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutBeveragesInput>;
};

export type RawMaterialProductCreateNestedOneWithoutCmlProductIngredientsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutCmlProductIngredientsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutCmlProductIngredientsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutCmlThrowAwayRawMaterialItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutCmlThrowAwayRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutCmlThrowAwayRawMaterialItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutCrtRawMaterialItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutCrtRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutCrtRawMaterialItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutEndingSdEmTaRawMaterialItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutEndingSdEmTaRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutEndingSdEmTaRawMaterialItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutPurchaseOrderRawMaterialItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutPurchaseOrderRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutPurchaseOrderRawMaterialItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutRawMaterialFinishingEndingItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialFinishingEndingItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialFinishingEndingItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutRawMaterialProductIngredientsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialProductIngredientsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialProductIngredientsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutRawMaterialProductRecipeInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialProductRecipeInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialProductRecipeInput>;
};

export type RawMaterialProductCreateNestedOneWithoutRawMaterialReleaseForBreakdownsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialReleaseForBreakdownsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialReleaseForBreakdownsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutRawMaterialReleaseItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialReleaseItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialReleaseItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutRawMaterialRequestItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialRequestItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialRequestItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutTransferRawMaterialItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutTransferRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutTransferRawMaterialItemsInput>;
};

export type RawMaterialProductCreateNestedOneWithoutWarehouseInventoryRawMaterialItemsInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutWarehouseInventoryRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutWarehouseInventoryRawMaterialItemsInput>;
};

export type RawMaterialProductCreateOrConnectWithoutBetweenEntityTransferRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutBetweenEntityTransferRawMaterialItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutBeveragesInput = {
  create: RawMaterialProductCreateWithoutBeveragesInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutCmlProductIngredientsInput = {
  create: RawMaterialProductCreateWithoutCmlProductIngredientsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutCmlThrowAwayRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutCmlThrowAwayRawMaterialItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutCrtRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutCrtRawMaterialItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutEndingSdEmTaRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutEndingSdEmTaRawMaterialItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutPurchaseOrderRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutPurchaseOrderRawMaterialItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutRawMaterialFinishingEndingItemsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialFinishingEndingItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutRawMaterialInput = {
  create: RawMaterialProductCreateWithoutRawMaterialInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutRawMaterialProductIngredientsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialProductIngredientsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutRawMaterialProductRecipeInput = {
  create: RawMaterialProductCreateWithoutRawMaterialProductRecipeInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutRawMaterialReleaseForBreakdownsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialReleaseForBreakdownsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutRawMaterialReleaseItemsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialReleaseItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutRawMaterialRequestItemsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialRequestItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutTransferRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutTransferRawMaterialItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutUnitInput = {
  create: RawMaterialProductCreateWithoutUnitInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateOrConnectWithoutWarehouseInventoryRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutWarehouseInventoryRawMaterialItemsInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductCreateWithoutBetweenEntityTransferRawMaterialItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutBeveragesInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutCmlProductIngredientsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutCmlThrowAwayRawMaterialItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutCrtRawMaterialItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutEndingSdEmTaRawMaterialItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutPurchaseOrderRawMaterialItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutRawMaterialFinishingEndingItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutRawMaterialInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutRawMaterialProductIngredientsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutRawMaterialProductRecipeInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutRawMaterialReleaseForBreakdownsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutRawMaterialReleaseItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutRawMaterialRequestItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutTransferRawMaterialItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutUnitInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
};

export type RawMaterialProductCreateWithoutWarehouseInventoryRawMaterialItemsInput = {
  baseUnit?: InputMaybe<Scalars['Boolean']['input']>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutRawMaterialProductInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  conversionToBaseUnit: Scalars['Float']['input'];
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterial: RawMaterialCreateNestedOneWithoutRawMaterialProductsInput;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput>;
  unit: UnitCreateNestedOneWithoutRawMaterialProductsInput;
};

export type RawMaterialProductIngredient = {
  __typename?: 'RawMaterialProductIngredient';
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  rawMaterialProductRecipe: RawMaterialProductRecipe;
  rawMaterialProductRecipeId: Scalars['Int']['output'];
};

export type RawMaterialProductIngredientCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductRecipeId: Scalars['Int']['input'];
};

export type RawMaterialProductIngredientCreateManyRawMaterialProductInputEnvelope = {
  data: Array<RawMaterialProductIngredientCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialProductIngredientCreateManyRawMaterialProductRecipeInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type RawMaterialProductIngredientCreateManyRawMaterialProductRecipeInputEnvelope = {
  data: Array<RawMaterialProductIngredientCreateManyRawMaterialProductRecipeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialProductIngredientCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialProductIngredientCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialProductIngredientCreateManyRawMaterialProductInputEnvelope>;
};

export type RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductRecipeInput = {
  connect?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialProductIngredientCreateOrConnectWithoutRawMaterialProductRecipeInput>>;
  create?: InputMaybe<Array<RawMaterialProductIngredientCreateWithoutRawMaterialProductRecipeInput>>;
  createMany?: InputMaybe<RawMaterialProductIngredientCreateManyRawMaterialProductRecipeInputEnvelope>;
};

export type RawMaterialProductIngredientCreateOrConnectWithoutRawMaterialProductInput = {
  create: RawMaterialProductIngredientCreateWithoutRawMaterialProductInput;
  where: RawMaterialProductIngredientWhereUniqueInput;
};

export type RawMaterialProductIngredientCreateOrConnectWithoutRawMaterialProductRecipeInput = {
  create: RawMaterialProductIngredientCreateWithoutRawMaterialProductRecipeInput;
  where: RawMaterialProductIngredientWhereUniqueInput;
};

export type RawMaterialProductIngredientCreateWithoutRawMaterialProductInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProductRecipe: RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductIngredientsInput;
};

export type RawMaterialProductIngredientCreateWithoutRawMaterialProductRecipeInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutRawMaterialProductIngredientsInput;
};

export type RawMaterialProductIngredientListRelationFilter = {
  every?: InputMaybe<RawMaterialProductIngredientWhereInput>;
  none?: InputMaybe<RawMaterialProductIngredientWhereInput>;
  some?: InputMaybe<RawMaterialProductIngredientWhereInput>;
};

export type RawMaterialProductIngredientOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialProductIngredientOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeOrderByWithRelationInput>;
  rawMaterialProductRecipeId?: InputMaybe<SortOrder>;
};

export enum RawMaterialProductIngredientScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId',
  RawMaterialProductRecipeId = 'rawMaterialProductRecipeId'
}

export type RawMaterialProductIngredientScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialProductIngredientScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialProductIngredientScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialProductIngredientScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialProductRecipeId?: InputMaybe<IntFilter>;
};

export type RawMaterialProductIngredientUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type RawMaterialProductIngredientUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: RawMaterialProductIngredientUpdateManyMutationInput;
  where: RawMaterialProductIngredientScalarWhereInput;
};

export type RawMaterialProductIngredientUpdateManyWithWhereWithoutRawMaterialProductRecipeInput = {
  data: RawMaterialProductIngredientUpdateManyMutationInput;
  where: RawMaterialProductIngredientScalarWhereInput;
};

export type RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialProductIngredientCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialProductIngredientCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialProductIngredientCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialProductIngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialProductIngredientUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<RawMaterialProductIngredientUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<RawMaterialProductIngredientUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductRecipeNestedInput = {
  connect?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialProductIngredientCreateOrConnectWithoutRawMaterialProductRecipeInput>>;
  create?: InputMaybe<Array<RawMaterialProductIngredientCreateWithoutRawMaterialProductRecipeInput>>;
  createMany?: InputMaybe<RawMaterialProductIngredientCreateManyRawMaterialProductRecipeInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialProductIngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialProductIngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialProductIngredientUpdateWithWhereUniqueWithoutRawMaterialProductRecipeInput>>;
  updateMany?: InputMaybe<Array<RawMaterialProductIngredientUpdateManyWithWhereWithoutRawMaterialProductRecipeInput>>;
  upsert?: InputMaybe<Array<RawMaterialProductIngredientUpsertWithWhereUniqueWithoutRawMaterialProductRecipeInput>>;
};

export type RawMaterialProductIngredientUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: RawMaterialProductIngredientUpdateWithoutRawMaterialProductInput;
  where: RawMaterialProductIngredientWhereUniqueInput;
};

export type RawMaterialProductIngredientUpdateWithWhereUniqueWithoutRawMaterialProductRecipeInput = {
  data: RawMaterialProductIngredientUpdateWithoutRawMaterialProductRecipeInput;
  where: RawMaterialProductIngredientWhereUniqueInput;
};

export type RawMaterialProductIngredientUpdateWithoutRawMaterialProductInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneRequiredWithoutRawMaterialProductIngredientsNestedInput>;
};

export type RawMaterialProductIngredientUpdateWithoutRawMaterialProductRecipeInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutRawMaterialProductIngredientsNestedInput>;
};

export type RawMaterialProductIngredientUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: RawMaterialProductIngredientCreateWithoutRawMaterialProductInput;
  update: RawMaterialProductIngredientUpdateWithoutRawMaterialProductInput;
  where: RawMaterialProductIngredientWhereUniqueInput;
};

export type RawMaterialProductIngredientUpsertWithWhereUniqueWithoutRawMaterialProductRecipeInput = {
  create: RawMaterialProductIngredientCreateWithoutRawMaterialProductRecipeInput;
  update: RawMaterialProductIngredientUpdateWithoutRawMaterialProductRecipeInput;
  where: RawMaterialProductIngredientWhereUniqueInput;
};

export type RawMaterialProductIngredientWhereInput = {
  AND?: InputMaybe<Array<RawMaterialProductIngredientWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialProductIngredientWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialProductIngredientWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeRelationFilter>;
  rawMaterialProductRecipeId?: InputMaybe<IntFilter>;
};

export type RawMaterialProductIngredientWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RawMaterialProductListRelationFilter = {
  every?: InputMaybe<RawMaterialProductWhereInput>;
  none?: InputMaybe<RawMaterialProductWhereInput>;
  some?: InputMaybe<RawMaterialProductWhereInput>;
};

export type RawMaterialProductMaxAggregate = {
  __typename?: 'RawMaterialProductMaxAggregate';
  baseUnit?: Maybe<Scalars['Boolean']['output']>;
  conversionToBaseUnit?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rawMaterialId?: Maybe<Scalars['Int']['output']>;
  unitId?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialProductMinAggregate = {
  __typename?: 'RawMaterialProductMinAggregate';
  baseUnit?: Maybe<Scalars['Boolean']['output']>;
  conversionToBaseUnit?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rawMaterialId?: Maybe<Scalars['Int']['output']>;
  unitId?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialProductOrderByWithRelationInput = {
  baseUnit?: InputMaybe<SortOrder>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemOrderByRelationAggregateInput>;
  beverages?: InputMaybe<BeverageOrderByRelationAggregateInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientOrderByRelationAggregateInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemOrderByRelationAggregateInput>;
  conversionToBaseUnit?: InputMaybe<SortOrder>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemOrderByRelationAggregateInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemOrderByRelationAggregateInput>;
  rawMaterial?: InputMaybe<RawMaterialOrderByWithRelationInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemOrderByRelationAggregateInput>;
  rawMaterialId?: InputMaybe<SortOrder>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientOrderByRelationAggregateInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeOrderByWithRelationInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownOrderByRelationAggregateInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemOrderByRelationAggregateInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemOrderByRelationAggregateInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemOrderByRelationAggregateInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemOrderByRelationAggregateInput>;
  unit?: InputMaybe<UnitOrderByWithRelationInput>;
  unitId?: InputMaybe<SortOrder>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemOrderByRelationAggregateInput>;
};

export type RawMaterialProductRecipe = {
  __typename?: 'RawMaterialProductRecipe';
  _count?: Maybe<RawMaterialProductRecipeCount>;
  id: Scalars['Int']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  rawMaterialProductIngredients: Array<RawMaterialProductIngredient>;
};


export type RawMaterialProductRecipeRawMaterialProductIngredientsArgs = {
  cursor?: InputMaybe<RawMaterialProductIngredientWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialProductIngredientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialProductIngredientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductIngredientWhereInput>;
};

export type RawMaterialProductRecipeAvgAggregate = {
  __typename?: 'RawMaterialProductRecipeAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Float']['output']>;
};

export type RawMaterialProductRecipeCount = {
  __typename?: 'RawMaterialProductRecipeCount';
  rawMaterialProductIngredients: Scalars['Int']['output'];
};

export type RawMaterialProductRecipeCountAggregate = {
  __typename?: 'RawMaterialProductRecipeCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  rawMaterialProductId: Scalars['Int']['output'];
};

export type RawMaterialProductRecipeCreateInput = {
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutRawMaterialProductRecipeInput;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductRecipeInput>;
};

export type RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductIngredientsInput = {
  connect?: InputMaybe<RawMaterialProductRecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductRecipeCreateOrConnectWithoutRawMaterialProductIngredientsInput>;
  create?: InputMaybe<RawMaterialProductRecipeCreateWithoutRawMaterialProductIngredientsInput>;
};

export type RawMaterialProductRecipeCreateNestedOneWithoutRawMaterialProductInput = {
  connect?: InputMaybe<RawMaterialProductRecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductRecipeCreateOrConnectWithoutRawMaterialProductInput>;
  create?: InputMaybe<RawMaterialProductRecipeCreateWithoutRawMaterialProductInput>;
};

export type RawMaterialProductRecipeCreateOrConnectWithoutRawMaterialProductIngredientsInput = {
  create: RawMaterialProductRecipeCreateWithoutRawMaterialProductIngredientsInput;
  where: RawMaterialProductRecipeWhereUniqueInput;
};

export type RawMaterialProductRecipeCreateOrConnectWithoutRawMaterialProductInput = {
  create: RawMaterialProductRecipeCreateWithoutRawMaterialProductInput;
  where: RawMaterialProductRecipeWhereUniqueInput;
};

export type RawMaterialProductRecipeCreateWithoutRawMaterialProductIngredientsInput = {
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutRawMaterialProductRecipeInput;
};

export type RawMaterialProductRecipeCreateWithoutRawMaterialProductInput = {
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientCreateNestedManyWithoutRawMaterialProductRecipeInput>;
};

export type RawMaterialProductRecipeMaxAggregate = {
  __typename?: 'RawMaterialProductRecipeMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialProductRecipeMinAggregate = {
  __typename?: 'RawMaterialProductRecipeMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialProductRecipeOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientOrderByRelationAggregateInput>;
};

export type RawMaterialProductRecipeRelationFilter = {
  is?: InputMaybe<RawMaterialProductRecipeWhereInput>;
  isNot?: InputMaybe<RawMaterialProductRecipeWhereInput>;
};

export enum RawMaterialProductRecipeScalarFieldEnum {
  Id = 'id',
  RawMaterialProductId = 'rawMaterialProductId'
}

export type RawMaterialProductRecipeSumAggregate = {
  __typename?: 'RawMaterialProductRecipeSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialProductRecipeUpdateInput = {
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutRawMaterialProductRecipeNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductRecipeNestedInput>;
};

export type RawMaterialProductRecipeUpdateOneRequiredWithoutRawMaterialProductIngredientsNestedInput = {
  connect?: InputMaybe<RawMaterialProductRecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductRecipeCreateOrConnectWithoutRawMaterialProductIngredientsInput>;
  create?: InputMaybe<RawMaterialProductRecipeCreateWithoutRawMaterialProductIngredientsInput>;
  update?: InputMaybe<RawMaterialProductRecipeUpdateWithoutRawMaterialProductIngredientsInput>;
  upsert?: InputMaybe<RawMaterialProductRecipeUpsertWithoutRawMaterialProductIngredientsInput>;
};

export type RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<RawMaterialProductRecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductRecipeCreateOrConnectWithoutRawMaterialProductInput>;
  create?: InputMaybe<RawMaterialProductRecipeCreateWithoutRawMaterialProductInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<RawMaterialProductRecipeUpdateWithoutRawMaterialProductInput>;
  upsert?: InputMaybe<RawMaterialProductRecipeUpsertWithoutRawMaterialProductInput>;
};

export type RawMaterialProductRecipeUpdateWithoutRawMaterialProductIngredientsInput = {
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutRawMaterialProductRecipeNestedInput>;
};

export type RawMaterialProductRecipeUpdateWithoutRawMaterialProductInput = {
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductRecipeNestedInput>;
};

export type RawMaterialProductRecipeUpsertWithoutRawMaterialProductIngredientsInput = {
  create: RawMaterialProductRecipeCreateWithoutRawMaterialProductIngredientsInput;
  update: RawMaterialProductRecipeUpdateWithoutRawMaterialProductIngredientsInput;
};

export type RawMaterialProductRecipeUpsertWithoutRawMaterialProductInput = {
  create: RawMaterialProductRecipeCreateWithoutRawMaterialProductInput;
  update: RawMaterialProductRecipeUpdateWithoutRawMaterialProductInput;
};

export type RawMaterialProductRecipeWhereInput = {
  AND?: InputMaybe<Array<RawMaterialProductRecipeWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialProductRecipeWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialProductRecipeWhereInput>>;
  id?: InputMaybe<IntFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientListRelationFilter>;
};

export type RawMaterialProductRecipeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  rawMaterialProductId?: InputMaybe<Scalars['Int']['input']>;
};

export type RawMaterialProductRelationFilter = {
  is?: InputMaybe<RawMaterialProductWhereInput>;
  isNot?: InputMaybe<RawMaterialProductWhereInput>;
};

export enum RawMaterialProductScalarFieldEnum {
  BaseUnit = 'baseUnit',
  ConversionToBaseUnit = 'conversionToBaseUnit',
  Id = 'id',
  RawMaterialId = 'rawMaterialId',
  UnitId = 'unitId'
}

export type RawMaterialProductScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialProductScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialProductScalarWhereInput>>;
  baseUnit?: InputMaybe<BoolFilter>;
  conversionToBaseUnit?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  rawMaterialId?: InputMaybe<IntFilter>;
  unitId?: InputMaybe<IntFilter>;
};

export type RawMaterialProductSumAggregate = {
  __typename?: 'RawMaterialProductSumAggregate';
  conversionToBaseUnit?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rawMaterialId?: Maybe<Scalars['Int']['output']>;
  unitId?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialProductUnitIdRawMaterialIdCompoundUniqueInput = {
  rawMaterialId: Scalars['Int']['input'];
  unitId: Scalars['Int']['input'];
};

export type RawMaterialProductUpdateInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateManyMutationInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type RawMaterialProductUpdateManyWithWhereWithoutRawMaterialInput = {
  data: RawMaterialProductUpdateManyMutationInput;
  where: RawMaterialProductScalarWhereInput;
};

export type RawMaterialProductUpdateManyWithWhereWithoutUnitInput = {
  data: RawMaterialProductUpdateManyMutationInput;
  where: RawMaterialProductScalarWhereInput;
};

export type RawMaterialProductUpdateManyWithoutRawMaterialNestedInput = {
  connect?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialProductCreateOrConnectWithoutRawMaterialInput>>;
  create?: InputMaybe<Array<RawMaterialProductCreateWithoutRawMaterialInput>>;
  createMany?: InputMaybe<RawMaterialProductCreateManyRawMaterialInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialProductUpdateWithWhereUniqueWithoutRawMaterialInput>>;
  updateMany?: InputMaybe<Array<RawMaterialProductUpdateManyWithWhereWithoutRawMaterialInput>>;
  upsert?: InputMaybe<Array<RawMaterialProductUpsertWithWhereUniqueWithoutRawMaterialInput>>;
};

export type RawMaterialProductUpdateManyWithoutUnitNestedInput = {
  connect?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialProductCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<RawMaterialProductCreateWithoutUnitInput>>;
  createMany?: InputMaybe<RawMaterialProductCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialProductWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialProductUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<RawMaterialProductUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<RawMaterialProductUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type RawMaterialProductUpdateOneRequiredWithoutBetweenEntityTransferRawMaterialItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutBetweenEntityTransferRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutBetweenEntityTransferRawMaterialItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutBetweenEntityTransferRawMaterialItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutBetweenEntityTransferRawMaterialItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutBeveragesNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutBeveragesInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutBeveragesInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutBeveragesInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutBeveragesInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutCmlProductIngredientsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutCmlProductIngredientsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutCmlProductIngredientsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutCmlProductIngredientsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutCmlProductIngredientsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutCmlThrowAwayRawMaterialItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutCmlThrowAwayRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutCmlThrowAwayRawMaterialItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutCmlThrowAwayRawMaterialItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutCmlThrowAwayRawMaterialItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutCrtRawMaterialItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutCrtRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutCrtRawMaterialItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutCrtRawMaterialItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutCrtRawMaterialItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutEndingSdEmTaRawMaterialItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutEndingSdEmTaRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutEndingSdEmTaRawMaterialItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutEndingSdEmTaRawMaterialItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutEndingSdEmTaRawMaterialItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutPurchaseOrderRawMaterialItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutPurchaseOrderRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutPurchaseOrderRawMaterialItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutPurchaseOrderRawMaterialItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutPurchaseOrderRawMaterialItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutRawMaterialFinishingEndingItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialFinishingEndingItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialFinishingEndingItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutRawMaterialFinishingEndingItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutRawMaterialFinishingEndingItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutRawMaterialProductIngredientsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialProductIngredientsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialProductIngredientsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutRawMaterialProductIngredientsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutRawMaterialProductIngredientsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutRawMaterialProductRecipeNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialProductRecipeInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialProductRecipeInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutRawMaterialProductRecipeInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutRawMaterialProductRecipeInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutRawMaterialReleaseForBreakdownsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialReleaseForBreakdownsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialReleaseForBreakdownsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutRawMaterialReleaseForBreakdownsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutRawMaterialReleaseForBreakdownsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutRawMaterialReleaseItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialReleaseItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialReleaseItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutRawMaterialReleaseItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutRawMaterialReleaseItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutRawMaterialRequestItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutRawMaterialRequestItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutRawMaterialRequestItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutRawMaterialRequestItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutRawMaterialRequestItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutSdsDeliveryReceiptRawMaterialItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutTransferRawMaterialItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutTransferRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutTransferRawMaterialItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutTransferRawMaterialItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutTransferRawMaterialItemsInput>;
};

export type RawMaterialProductUpdateOneRequiredWithoutWarehouseInventoryRawMaterialItemsNestedInput = {
  connect?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialProductCreateOrConnectWithoutWarehouseInventoryRawMaterialItemsInput>;
  create?: InputMaybe<RawMaterialProductCreateWithoutWarehouseInventoryRawMaterialItemsInput>;
  update?: InputMaybe<RawMaterialProductUpdateWithoutWarehouseInventoryRawMaterialItemsInput>;
  upsert?: InputMaybe<RawMaterialProductUpsertWithoutWarehouseInventoryRawMaterialItemsInput>;
};

export type RawMaterialProductUpdateWithWhereUniqueWithoutRawMaterialInput = {
  data: RawMaterialProductUpdateWithoutRawMaterialInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductUpdateWithWhereUniqueWithoutUnitInput = {
  data: RawMaterialProductUpdateWithoutUnitInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductUpdateWithoutBetweenEntityTransferRawMaterialItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutBeveragesInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutCmlProductIngredientsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutCmlThrowAwayRawMaterialItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutCrtRawMaterialItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutEndingSdEmTaRawMaterialItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutPurchaseOrderRawMaterialItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutRawMaterialFinishingEndingItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutRawMaterialInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutRawMaterialProductIngredientsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutRawMaterialProductRecipeInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutRawMaterialReleaseForBreakdownsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutRawMaterialReleaseItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutRawMaterialRequestItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutTransferRawMaterialItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutUnitInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
};

export type RawMaterialProductUpdateWithoutWarehouseInventoryRawMaterialItemsInput = {
  baseUnit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  beverages?: InputMaybe<BeverageUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  conversionToBaseUnit?: InputMaybe<FloatFieldUpdateOperationsInput>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterial?: InputMaybe<RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeUpdateOneWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput>;
};

export type RawMaterialProductUpsertWithWhereUniqueWithoutRawMaterialInput = {
  create: RawMaterialProductCreateWithoutRawMaterialInput;
  update: RawMaterialProductUpdateWithoutRawMaterialInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductUpsertWithWhereUniqueWithoutUnitInput = {
  create: RawMaterialProductCreateWithoutUnitInput;
  update: RawMaterialProductUpdateWithoutUnitInput;
  where: RawMaterialProductWhereUniqueInput;
};

export type RawMaterialProductUpsertWithoutBetweenEntityTransferRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutBetweenEntityTransferRawMaterialItemsInput;
  update: RawMaterialProductUpdateWithoutBetweenEntityTransferRawMaterialItemsInput;
};

export type RawMaterialProductUpsertWithoutBeveragesInput = {
  create: RawMaterialProductCreateWithoutBeveragesInput;
  update: RawMaterialProductUpdateWithoutBeveragesInput;
};

export type RawMaterialProductUpsertWithoutCmlProductIngredientsInput = {
  create: RawMaterialProductCreateWithoutCmlProductIngredientsInput;
  update: RawMaterialProductUpdateWithoutCmlProductIngredientsInput;
};

export type RawMaterialProductUpsertWithoutCmlThrowAwayRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutCmlThrowAwayRawMaterialItemsInput;
  update: RawMaterialProductUpdateWithoutCmlThrowAwayRawMaterialItemsInput;
};

export type RawMaterialProductUpsertWithoutCrtRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutCrtRawMaterialItemsInput;
  update: RawMaterialProductUpdateWithoutCrtRawMaterialItemsInput;
};

export type RawMaterialProductUpsertWithoutEndingSdEmTaRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutEndingSdEmTaRawMaterialItemsInput;
  update: RawMaterialProductUpdateWithoutEndingSdEmTaRawMaterialItemsInput;
};

export type RawMaterialProductUpsertWithoutPurchaseOrderRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutPurchaseOrderRawMaterialItemsInput;
  update: RawMaterialProductUpdateWithoutPurchaseOrderRawMaterialItemsInput;
};

export type RawMaterialProductUpsertWithoutRawMaterialFinishingEndingItemsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialFinishingEndingItemsInput;
  update: RawMaterialProductUpdateWithoutRawMaterialFinishingEndingItemsInput;
};

export type RawMaterialProductUpsertWithoutRawMaterialProductIngredientsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialProductIngredientsInput;
  update: RawMaterialProductUpdateWithoutRawMaterialProductIngredientsInput;
};

export type RawMaterialProductUpsertWithoutRawMaterialProductRecipeInput = {
  create: RawMaterialProductCreateWithoutRawMaterialProductRecipeInput;
  update: RawMaterialProductUpdateWithoutRawMaterialProductRecipeInput;
};

export type RawMaterialProductUpsertWithoutRawMaterialReleaseForBreakdownsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialReleaseForBreakdownsInput;
  update: RawMaterialProductUpdateWithoutRawMaterialReleaseForBreakdownsInput;
};

export type RawMaterialProductUpsertWithoutRawMaterialReleaseItemsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialReleaseItemsInput;
  update: RawMaterialProductUpdateWithoutRawMaterialReleaseItemsInput;
};

export type RawMaterialProductUpsertWithoutRawMaterialRequestItemsInput = {
  create: RawMaterialProductCreateWithoutRawMaterialRequestItemsInput;
  update: RawMaterialProductUpdateWithoutRawMaterialRequestItemsInput;
};

export type RawMaterialProductUpsertWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput;
  update: RawMaterialProductUpdateWithoutSdsDeliveryReceiptRawMaterialItemsInput;
};

export type RawMaterialProductUpsertWithoutTransferRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutTransferRawMaterialItemsInput;
  update: RawMaterialProductUpdateWithoutTransferRawMaterialItemsInput;
};

export type RawMaterialProductUpsertWithoutWarehouseInventoryRawMaterialItemsInput = {
  create: RawMaterialProductCreateWithoutWarehouseInventoryRawMaterialItemsInput;
  update: RawMaterialProductUpdateWithoutWarehouseInventoryRawMaterialItemsInput;
};

export type RawMaterialProductWhereInput = {
  AND?: InputMaybe<Array<RawMaterialProductWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialProductWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialProductWhereInput>>;
  baseUnit?: InputMaybe<BoolFilter>;
  betweenEntityTransferRawMaterialItems?: InputMaybe<BetweenEntityTransferRawMaterialItemListRelationFilter>;
  beverages?: InputMaybe<BeverageListRelationFilter>;
  cmlProductIngredients?: InputMaybe<CmlProductIngredientListRelationFilter>;
  cmlThrowAwayRawMaterialItems?: InputMaybe<CmlThrowAwayRawMaterialItemListRelationFilter>;
  conversionToBaseUnit?: InputMaybe<FloatFilter>;
  crtRawMaterialItems?: InputMaybe<CrtRawMaterialItemListRelationFilter>;
  endingSdEmTaRawMaterialItems?: InputMaybe<EndingSdEmTaRawMaterialItemListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  purchaseOrderRawMaterialItems?: InputMaybe<PurchaseOrderRawMaterialItemListRelationFilter>;
  rawMaterial?: InputMaybe<RawMaterialRelationFilter>;
  rawMaterialFinishingEndingItems?: InputMaybe<RawMaterialFinishingEndingItemListRelationFilter>;
  rawMaterialId?: InputMaybe<IntFilter>;
  rawMaterialProductIngredients?: InputMaybe<RawMaterialProductIngredientListRelationFilter>;
  rawMaterialProductRecipe?: InputMaybe<RawMaterialProductRecipeRelationFilter>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownListRelationFilter>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemListRelationFilter>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemListRelationFilter>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemListRelationFilter>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemListRelationFilter>;
  unit?: InputMaybe<UnitRelationFilter>;
  unitId?: InputMaybe<IntFilter>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemListRelationFilter>;
};

export type RawMaterialProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unitId_rawMaterialId?: InputMaybe<RawMaterialProductUnitIdRawMaterialIdCompoundUniqueInput>;
};

export type RawMaterialRelationFilter = {
  is?: InputMaybe<RawMaterialWhereInput>;
  isNot?: InputMaybe<RawMaterialWhereInput>;
};

export type RawMaterialRelease = {
  __typename?: 'RawMaterialRelease';
  _count?: Maybe<RawMaterialReleaseCount>;
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  destination: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  rawMaterialReleaseForBreakdowns: Array<RawMaterialReleaseForBreakdown>;
  rawMaterialReleaseItems: Array<RawMaterialReleaseItem>;
  rawMaterialReleasePackagingItems: Array<RawMaterialReleasePackagingItem>;
};


export type RawMaterialReleaseRawMaterialReleaseForBreakdownsArgs = {
  cursor?: InputMaybe<RawMaterialReleaseForBreakdownWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialReleaseForBreakdownScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialReleaseForBreakdownOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleaseForBreakdownWhereInput>;
};


export type RawMaterialReleaseRawMaterialReleaseItemsArgs = {
  cursor?: InputMaybe<RawMaterialReleaseItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialReleaseItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialReleaseItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleaseItemWhereInput>;
};


export type RawMaterialReleaseRawMaterialReleasePackagingItemsArgs = {
  cursor?: InputMaybe<RawMaterialReleasePackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialReleasePackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialReleasePackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleasePackagingItemWhereInput>;
};

export type RawMaterialReleaseAvgAggregate = {
  __typename?: 'RawMaterialReleaseAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type RawMaterialReleaseCount = {
  __typename?: 'RawMaterialReleaseCount';
  rawMaterialReleaseForBreakdowns: Scalars['Int']['output'];
  rawMaterialReleaseItems: Scalars['Int']['output'];
  rawMaterialReleasePackagingItems: Scalars['Int']['output'];
};

export type RawMaterialReleaseCountAggregate = {
  __typename?: 'RawMaterialReleaseCountAggregate';
  _all: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  destination: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type RawMaterialReleaseCreateInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  destination?: InputMaybe<Scalars['String']['input']>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialReleaseInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialReleaseInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutRawMaterialReleaseInput>;
};

export type RawMaterialReleaseCreateNestedOneWithoutRawMaterialReleaseForBreakdownsInput = {
  connect?: InputMaybe<RawMaterialReleaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleaseForBreakdownsInput>;
  create?: InputMaybe<RawMaterialReleaseCreateWithoutRawMaterialReleaseForBreakdownsInput>;
};

export type RawMaterialReleaseCreateNestedOneWithoutRawMaterialReleaseItemsInput = {
  connect?: InputMaybe<RawMaterialReleaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleaseItemsInput>;
  create?: InputMaybe<RawMaterialReleaseCreateWithoutRawMaterialReleaseItemsInput>;
};

export type RawMaterialReleaseCreateNestedOneWithoutRawMaterialReleasePackagingItemsInput = {
  connect?: InputMaybe<RawMaterialReleaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleasePackagingItemsInput>;
  create?: InputMaybe<RawMaterialReleaseCreateWithoutRawMaterialReleasePackagingItemsInput>;
};

export type RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleaseForBreakdownsInput = {
  create: RawMaterialReleaseCreateWithoutRawMaterialReleaseForBreakdownsInput;
  where: RawMaterialReleaseWhereUniqueInput;
};

export type RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleaseItemsInput = {
  create: RawMaterialReleaseCreateWithoutRawMaterialReleaseItemsInput;
  where: RawMaterialReleaseWhereUniqueInput;
};

export type RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleasePackagingItemsInput = {
  create: RawMaterialReleaseCreateWithoutRawMaterialReleasePackagingItemsInput;
  where: RawMaterialReleaseWhereUniqueInput;
};

export type RawMaterialReleaseCreateWithoutRawMaterialReleaseForBreakdownsInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  destination?: InputMaybe<Scalars['String']['input']>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialReleaseInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutRawMaterialReleaseInput>;
};

export type RawMaterialReleaseCreateWithoutRawMaterialReleaseItemsInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  destination?: InputMaybe<Scalars['String']['input']>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialReleaseInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemCreateNestedManyWithoutRawMaterialReleaseInput>;
};

export type RawMaterialReleaseCreateWithoutRawMaterialReleasePackagingItemsInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  destination?: InputMaybe<Scalars['String']['input']>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialReleaseInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialReleaseInput>;
};

export type RawMaterialReleaseForBreakdown = {
  __typename?: 'RawMaterialReleaseForBreakdown';
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  rawMaterialRelease: RawMaterialRelease;
  rawMaterialReleaseId: Scalars['Int']['output'];
  referenceQuantity: Scalars['Float']['output'];
  referenceUnit: Scalars['String']['output'];
};

export type RawMaterialReleaseForBreakdownCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialReleaseId: Scalars['Int']['input'];
  referenceQuantity: Scalars['Float']['input'];
  referenceUnit: Scalars['String']['input'];
};

export type RawMaterialReleaseForBreakdownCreateManyRawMaterialProductInputEnvelope = {
  data: Array<RawMaterialReleaseForBreakdownCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialReleaseForBreakdownCreateManyRawMaterialReleaseInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
  referenceQuantity: Scalars['Float']['input'];
  referenceUnit: Scalars['String']['input'];
};

export type RawMaterialReleaseForBreakdownCreateManyRawMaterialReleaseInputEnvelope = {
  data: Array<RawMaterialReleaseForBreakdownCreateManyRawMaterialReleaseInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleaseForBreakdownCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialReleaseForBreakdownCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialReleaseForBreakdownCreateManyRawMaterialProductInputEnvelope>;
};

export type RawMaterialReleaseForBreakdownCreateNestedManyWithoutRawMaterialReleaseInput = {
  connect?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleaseForBreakdownCreateOrConnectWithoutRawMaterialReleaseInput>>;
  create?: InputMaybe<Array<RawMaterialReleaseForBreakdownCreateWithoutRawMaterialReleaseInput>>;
  createMany?: InputMaybe<RawMaterialReleaseForBreakdownCreateManyRawMaterialReleaseInputEnvelope>;
};

export type RawMaterialReleaseForBreakdownCreateOrConnectWithoutRawMaterialProductInput = {
  create: RawMaterialReleaseForBreakdownCreateWithoutRawMaterialProductInput;
  where: RawMaterialReleaseForBreakdownWhereUniqueInput;
};

export type RawMaterialReleaseForBreakdownCreateOrConnectWithoutRawMaterialReleaseInput = {
  create: RawMaterialReleaseForBreakdownCreateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleaseForBreakdownWhereUniqueInput;
};

export type RawMaterialReleaseForBreakdownCreateWithoutRawMaterialProductInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialRelease: RawMaterialReleaseCreateNestedOneWithoutRawMaterialReleaseForBreakdownsInput;
  referenceQuantity: Scalars['Float']['input'];
  referenceUnit: Scalars['String']['input'];
};

export type RawMaterialReleaseForBreakdownCreateWithoutRawMaterialReleaseInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutRawMaterialReleaseForBreakdownsInput;
  referenceQuantity: Scalars['Float']['input'];
  referenceUnit: Scalars['String']['input'];
};

export type RawMaterialReleaseForBreakdownListRelationFilter = {
  every?: InputMaybe<RawMaterialReleaseForBreakdownWhereInput>;
  none?: InputMaybe<RawMaterialReleaseForBreakdownWhereInput>;
  some?: InputMaybe<RawMaterialReleaseForBreakdownWhereInput>;
};

export type RawMaterialReleaseForBreakdownOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialReleaseForBreakdownOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseOrderByWithRelationInput>;
  rawMaterialReleaseId?: InputMaybe<SortOrder>;
  referenceQuantity?: InputMaybe<SortOrder>;
  referenceUnit?: InputMaybe<SortOrder>;
};

export enum RawMaterialReleaseForBreakdownScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId',
  RawMaterialReleaseId = 'rawMaterialReleaseId',
  ReferenceQuantity = 'referenceQuantity',
  ReferenceUnit = 'referenceUnit'
}

export type RawMaterialReleaseForBreakdownScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialReleaseForBreakdownScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialReleaseForBreakdownScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialReleaseForBreakdownScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialReleaseId?: InputMaybe<IntFilter>;
  referenceQuantity?: InputMaybe<FloatFilter>;
  referenceUnit?: InputMaybe<StringFilter>;
};

export type RawMaterialReleaseForBreakdownUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceQuantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RawMaterialReleaseForBreakdownUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: RawMaterialReleaseForBreakdownUpdateManyMutationInput;
  where: RawMaterialReleaseForBreakdownScalarWhereInput;
};

export type RawMaterialReleaseForBreakdownUpdateManyWithWhereWithoutRawMaterialReleaseInput = {
  data: RawMaterialReleaseForBreakdownUpdateManyMutationInput;
  where: RawMaterialReleaseForBreakdownScalarWhereInput;
};

export type RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleaseForBreakdownCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialReleaseForBreakdownCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialReleaseForBreakdownCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialReleaseForBreakdownScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialReleaseForBreakdownUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<RawMaterialReleaseForBreakdownUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<RawMaterialReleaseForBreakdownUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialReleaseNestedInput = {
  connect?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleaseForBreakdownCreateOrConnectWithoutRawMaterialReleaseInput>>;
  create?: InputMaybe<Array<RawMaterialReleaseForBreakdownCreateWithoutRawMaterialReleaseInput>>;
  createMany?: InputMaybe<RawMaterialReleaseForBreakdownCreateManyRawMaterialReleaseInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialReleaseForBreakdownScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialReleaseForBreakdownUpdateWithWhereUniqueWithoutRawMaterialReleaseInput>>;
  updateMany?: InputMaybe<Array<RawMaterialReleaseForBreakdownUpdateManyWithWhereWithoutRawMaterialReleaseInput>>;
  upsert?: InputMaybe<Array<RawMaterialReleaseForBreakdownUpsertWithWhereUniqueWithoutRawMaterialReleaseInput>>;
};

export type RawMaterialReleaseForBreakdownUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: RawMaterialReleaseForBreakdownUpdateWithoutRawMaterialProductInput;
  where: RawMaterialReleaseForBreakdownWhereUniqueInput;
};

export type RawMaterialReleaseForBreakdownUpdateWithWhereUniqueWithoutRawMaterialReleaseInput = {
  data: RawMaterialReleaseForBreakdownUpdateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleaseForBreakdownWhereUniqueInput;
};

export type RawMaterialReleaseForBreakdownUpdateWithoutRawMaterialProductInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseUpdateOneRequiredWithoutRawMaterialReleaseForBreakdownsNestedInput>;
  referenceQuantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RawMaterialReleaseForBreakdownUpdateWithoutRawMaterialReleaseInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutRawMaterialReleaseForBreakdownsNestedInput>;
  referenceQuantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RawMaterialReleaseForBreakdownUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: RawMaterialReleaseForBreakdownCreateWithoutRawMaterialProductInput;
  update: RawMaterialReleaseForBreakdownUpdateWithoutRawMaterialProductInput;
  where: RawMaterialReleaseForBreakdownWhereUniqueInput;
};

export type RawMaterialReleaseForBreakdownUpsertWithWhereUniqueWithoutRawMaterialReleaseInput = {
  create: RawMaterialReleaseForBreakdownCreateWithoutRawMaterialReleaseInput;
  update: RawMaterialReleaseForBreakdownUpdateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleaseForBreakdownWhereUniqueInput;
};

export type RawMaterialReleaseForBreakdownWhereInput = {
  AND?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialReleaseForBreakdownWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseRelationFilter>;
  rawMaterialReleaseId?: InputMaybe<IntFilter>;
  referenceQuantity?: InputMaybe<FloatFilter>;
  referenceUnit?: InputMaybe<StringFilter>;
};

export type RawMaterialReleaseForBreakdownWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RawMaterialReleaseItem = {
  __typename?: 'RawMaterialReleaseItem';
  fromBreakdown: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  rawMaterialRelease: RawMaterialRelease;
  rawMaterialReleaseId: Scalars['Int']['output'];
  referenceQuantity: Scalars['Float']['output'];
  referenceUnit: Scalars['String']['output'];
};

export type RawMaterialReleaseItemCreateManyRawMaterialProductInput = {
  fromBreakdown?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialReleaseId: Scalars['Int']['input'];
  referenceQuantity: Scalars['Float']['input'];
  referenceUnit: Scalars['String']['input'];
};

export type RawMaterialReleaseItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<RawMaterialReleaseItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialReleaseItemCreateManyRawMaterialReleaseInput = {
  fromBreakdown?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
  referenceQuantity: Scalars['Float']['input'];
  referenceUnit: Scalars['String']['input'];
};

export type RawMaterialReleaseItemCreateManyRawMaterialReleaseInputEnvelope = {
  data: Array<RawMaterialReleaseItemCreateManyRawMaterialReleaseInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleaseItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialReleaseItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialReleaseItemCreateManyRawMaterialProductInputEnvelope>;
};

export type RawMaterialReleaseItemCreateNestedManyWithoutRawMaterialReleaseInput = {
  connect?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleaseItemCreateOrConnectWithoutRawMaterialReleaseInput>>;
  create?: InputMaybe<Array<RawMaterialReleaseItemCreateWithoutRawMaterialReleaseInput>>;
  createMany?: InputMaybe<RawMaterialReleaseItemCreateManyRawMaterialReleaseInputEnvelope>;
};

export type RawMaterialReleaseItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: RawMaterialReleaseItemCreateWithoutRawMaterialProductInput;
  where: RawMaterialReleaseItemWhereUniqueInput;
};

export type RawMaterialReleaseItemCreateOrConnectWithoutRawMaterialReleaseInput = {
  create: RawMaterialReleaseItemCreateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleaseItemWhereUniqueInput;
};

export type RawMaterialReleaseItemCreateWithoutRawMaterialProductInput = {
  fromBreakdown?: InputMaybe<Scalars['Boolean']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialRelease: RawMaterialReleaseCreateNestedOneWithoutRawMaterialReleaseItemsInput;
  referenceQuantity: Scalars['Float']['input'];
  referenceUnit: Scalars['String']['input'];
};

export type RawMaterialReleaseItemCreateWithoutRawMaterialReleaseInput = {
  fromBreakdown?: InputMaybe<Scalars['Boolean']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutRawMaterialReleaseItemsInput;
  referenceQuantity: Scalars['Float']['input'];
  referenceUnit: Scalars['String']['input'];
};

export type RawMaterialReleaseItemListRelationFilter = {
  every?: InputMaybe<RawMaterialReleaseItemWhereInput>;
  none?: InputMaybe<RawMaterialReleaseItemWhereInput>;
  some?: InputMaybe<RawMaterialReleaseItemWhereInput>;
};

export type RawMaterialReleaseItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialReleaseItemOrderByWithRelationInput = {
  fromBreakdown?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseOrderByWithRelationInput>;
  rawMaterialReleaseId?: InputMaybe<SortOrder>;
  referenceQuantity?: InputMaybe<SortOrder>;
  referenceUnit?: InputMaybe<SortOrder>;
};

export enum RawMaterialReleaseItemScalarFieldEnum {
  FromBreakdown = 'fromBreakdown',
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId',
  RawMaterialReleaseId = 'rawMaterialReleaseId',
  ReferenceQuantity = 'referenceQuantity',
  ReferenceUnit = 'referenceUnit'
}

export type RawMaterialReleaseItemScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialReleaseItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialReleaseItemScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialReleaseItemScalarWhereInput>>;
  fromBreakdown?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialReleaseId?: InputMaybe<IntFilter>;
  referenceQuantity?: InputMaybe<FloatFilter>;
  referenceUnit?: InputMaybe<StringFilter>;
};

export type RawMaterialReleaseItemUpdateManyMutationInput = {
  fromBreakdown?: InputMaybe<BoolFieldUpdateOperationsInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceQuantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RawMaterialReleaseItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: RawMaterialReleaseItemUpdateManyMutationInput;
  where: RawMaterialReleaseItemScalarWhereInput;
};

export type RawMaterialReleaseItemUpdateManyWithWhereWithoutRawMaterialReleaseInput = {
  data: RawMaterialReleaseItemUpdateManyMutationInput;
  where: RawMaterialReleaseItemScalarWhereInput;
};

export type RawMaterialReleaseItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleaseItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialReleaseItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialReleaseItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialReleaseItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialReleaseItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<RawMaterialReleaseItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<RawMaterialReleaseItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type RawMaterialReleaseItemUpdateManyWithoutRawMaterialReleaseNestedInput = {
  connect?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleaseItemCreateOrConnectWithoutRawMaterialReleaseInput>>;
  create?: InputMaybe<Array<RawMaterialReleaseItemCreateWithoutRawMaterialReleaseInput>>;
  createMany?: InputMaybe<RawMaterialReleaseItemCreateManyRawMaterialReleaseInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialReleaseItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialReleaseItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialReleaseItemUpdateWithWhereUniqueWithoutRawMaterialReleaseInput>>;
  updateMany?: InputMaybe<Array<RawMaterialReleaseItemUpdateManyWithWhereWithoutRawMaterialReleaseInput>>;
  upsert?: InputMaybe<Array<RawMaterialReleaseItemUpsertWithWhereUniqueWithoutRawMaterialReleaseInput>>;
};

export type RawMaterialReleaseItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: RawMaterialReleaseItemUpdateWithoutRawMaterialProductInput;
  where: RawMaterialReleaseItemWhereUniqueInput;
};

export type RawMaterialReleaseItemUpdateWithWhereUniqueWithoutRawMaterialReleaseInput = {
  data: RawMaterialReleaseItemUpdateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleaseItemWhereUniqueInput;
};

export type RawMaterialReleaseItemUpdateWithoutRawMaterialProductInput = {
  fromBreakdown?: InputMaybe<BoolFieldUpdateOperationsInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseUpdateOneRequiredWithoutRawMaterialReleaseItemsNestedInput>;
  referenceQuantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RawMaterialReleaseItemUpdateWithoutRawMaterialReleaseInput = {
  fromBreakdown?: InputMaybe<BoolFieldUpdateOperationsInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutRawMaterialReleaseItemsNestedInput>;
  referenceQuantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RawMaterialReleaseItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: RawMaterialReleaseItemCreateWithoutRawMaterialProductInput;
  update: RawMaterialReleaseItemUpdateWithoutRawMaterialProductInput;
  where: RawMaterialReleaseItemWhereUniqueInput;
};

export type RawMaterialReleaseItemUpsertWithWhereUniqueWithoutRawMaterialReleaseInput = {
  create: RawMaterialReleaseItemCreateWithoutRawMaterialReleaseInput;
  update: RawMaterialReleaseItemUpdateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleaseItemWhereUniqueInput;
};

export type RawMaterialReleaseItemWhereInput = {
  AND?: InputMaybe<Array<RawMaterialReleaseItemWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialReleaseItemWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialReleaseItemWhereInput>>;
  fromBreakdown?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseRelationFilter>;
  rawMaterialReleaseId?: InputMaybe<IntFilter>;
  referenceQuantity?: InputMaybe<FloatFilter>;
  referenceUnit?: InputMaybe<StringFilter>;
};

export type RawMaterialReleaseItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RawMaterialReleaseItemsFromBreakdownInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialId: Scalars['Float']['input'];
  unitId: Scalars['Float']['input'];
};

export type RawMaterialReleaseMaxAggregate = {
  __typename?: 'RawMaterialReleaseMaxAggregate';
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  destination?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialReleaseMinAggregate = {
  __typename?: 'RawMaterialReleaseMinAggregate';
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  destination?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialReleaseOrderByWithRelationInput = {
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  destination?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownOrderByRelationAggregateInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemOrderByRelationAggregateInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemOrderByRelationAggregateInput>;
};

export type RawMaterialReleasePackagingItem = {
  __typename?: 'RawMaterialReleasePackagingItem';
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  rawMaterialRelease: RawMaterialRelease;
  rawMaterialReleaseId: Scalars['Int']['output'];
};

export type RawMaterialReleasePackagingItemCreateManyPackagingProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  rawMaterialReleaseId: Scalars['Int']['input'];
};

export type RawMaterialReleasePackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<RawMaterialReleasePackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialReleasePackagingItemCreateManyRawMaterialReleaseInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type RawMaterialReleasePackagingItemCreateManyRawMaterialReleaseInputEnvelope = {
  data: Array<RawMaterialReleasePackagingItemCreateManyRawMaterialReleaseInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialReleasePackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleasePackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<RawMaterialReleasePackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<RawMaterialReleasePackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type RawMaterialReleasePackagingItemCreateNestedManyWithoutRawMaterialReleaseInput = {
  connect?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleasePackagingItemCreateOrConnectWithoutRawMaterialReleaseInput>>;
  create?: InputMaybe<Array<RawMaterialReleasePackagingItemCreateWithoutRawMaterialReleaseInput>>;
  createMany?: InputMaybe<RawMaterialReleasePackagingItemCreateManyRawMaterialReleaseInputEnvelope>;
};

export type RawMaterialReleasePackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: RawMaterialReleasePackagingItemCreateWithoutPackagingProductInput;
  where: RawMaterialReleasePackagingItemWhereUniqueInput;
};

export type RawMaterialReleasePackagingItemCreateOrConnectWithoutRawMaterialReleaseInput = {
  create: RawMaterialReleasePackagingItemCreateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleasePackagingItemWhereUniqueInput;
};

export type RawMaterialReleasePackagingItemCreateWithoutPackagingProductInput = {
  quantity: Scalars['Int']['input'];
  rawMaterialRelease: RawMaterialReleaseCreateNestedOneWithoutRawMaterialReleasePackagingItemsInput;
};

export type RawMaterialReleasePackagingItemCreateWithoutRawMaterialReleaseInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutRawMaterialReleasePackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type RawMaterialReleasePackagingItemListRelationFilter = {
  every?: InputMaybe<RawMaterialReleasePackagingItemWhereInput>;
  none?: InputMaybe<RawMaterialReleasePackagingItemWhereInput>;
  some?: InputMaybe<RawMaterialReleasePackagingItemWhereInput>;
};

export type RawMaterialReleasePackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialReleasePackagingItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseOrderByWithRelationInput>;
  rawMaterialReleaseId?: InputMaybe<SortOrder>;
};

export enum RawMaterialReleasePackagingItemScalarFieldEnum {
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity',
  RawMaterialReleaseId = 'rawMaterialReleaseId'
}

export type RawMaterialReleasePackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialReleasePackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialReleasePackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialReleasePackagingItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  rawMaterialReleaseId?: InputMaybe<IntFilter>;
};

export type RawMaterialReleasePackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RawMaterialReleasePackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: RawMaterialReleasePackagingItemUpdateManyMutationInput;
  where: RawMaterialReleasePackagingItemScalarWhereInput;
};

export type RawMaterialReleasePackagingItemUpdateManyWithWhereWithoutRawMaterialReleaseInput = {
  data: RawMaterialReleasePackagingItemUpdateManyMutationInput;
  where: RawMaterialReleasePackagingItemScalarWhereInput;
};

export type RawMaterialReleasePackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleasePackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<RawMaterialReleasePackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<RawMaterialReleasePackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialReleasePackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialReleasePackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<RawMaterialReleasePackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<RawMaterialReleasePackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type RawMaterialReleasePackagingItemUpdateManyWithoutRawMaterialReleaseNestedInput = {
  connect?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialReleasePackagingItemCreateOrConnectWithoutRawMaterialReleaseInput>>;
  create?: InputMaybe<Array<RawMaterialReleasePackagingItemCreateWithoutRawMaterialReleaseInput>>;
  createMany?: InputMaybe<RawMaterialReleasePackagingItemCreateManyRawMaterialReleaseInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialReleasePackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialReleasePackagingItemUpdateWithWhereUniqueWithoutRawMaterialReleaseInput>>;
  updateMany?: InputMaybe<Array<RawMaterialReleasePackagingItemUpdateManyWithWhereWithoutRawMaterialReleaseInput>>;
  upsert?: InputMaybe<Array<RawMaterialReleasePackagingItemUpsertWithWhereUniqueWithoutRawMaterialReleaseInput>>;
};

export type RawMaterialReleasePackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: RawMaterialReleasePackagingItemUpdateWithoutPackagingProductInput;
  where: RawMaterialReleasePackagingItemWhereUniqueInput;
};

export type RawMaterialReleasePackagingItemUpdateWithWhereUniqueWithoutRawMaterialReleaseInput = {
  data: RawMaterialReleasePackagingItemUpdateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleasePackagingItemWhereUniqueInput;
};

export type RawMaterialReleasePackagingItemUpdateWithoutPackagingProductInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseUpdateOneRequiredWithoutRawMaterialReleasePackagingItemsNestedInput>;
};

export type RawMaterialReleasePackagingItemUpdateWithoutRawMaterialReleaseInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutRawMaterialReleasePackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RawMaterialReleasePackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: RawMaterialReleasePackagingItemCreateWithoutPackagingProductInput;
  update: RawMaterialReleasePackagingItemUpdateWithoutPackagingProductInput;
  where: RawMaterialReleasePackagingItemWhereUniqueInput;
};

export type RawMaterialReleasePackagingItemUpsertWithWhereUniqueWithoutRawMaterialReleaseInput = {
  create: RawMaterialReleasePackagingItemCreateWithoutRawMaterialReleaseInput;
  update: RawMaterialReleasePackagingItemUpdateWithoutRawMaterialReleaseInput;
  where: RawMaterialReleasePackagingItemWhereUniqueInput;
};

export type RawMaterialReleasePackagingItemWhereInput = {
  AND?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialReleasePackagingItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  rawMaterialRelease?: InputMaybe<RawMaterialReleaseRelationFilter>;
  rawMaterialReleaseId?: InputMaybe<IntFilter>;
};

export type RawMaterialReleasePackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RawMaterialReleaseReference = {
  __typename?: 'RawMaterialReleaseReference';
  rawMaterial: RawMaterial;
  referenceQuantity: Scalars['Float']['output'];
  referenceUnit: Unit;
};

export type RawMaterialReleaseReferenceInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
};

export type RawMaterialReleaseRelationFilter = {
  is?: InputMaybe<RawMaterialReleaseWhereInput>;
  isNot?: InputMaybe<RawMaterialReleaseWhereInput>;
};

export enum RawMaterialReleaseScalarFieldEnum {
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Destination = 'destination',
  Id = 'id'
}

export type RawMaterialReleaseSumAggregate = {
  __typename?: 'RawMaterialReleaseSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialReleaseUpdateInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  destination?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialReleaseNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialReleaseNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutRawMaterialReleaseNestedInput>;
};

export type RawMaterialReleaseUpdateOneRequiredWithoutRawMaterialReleaseForBreakdownsNestedInput = {
  connect?: InputMaybe<RawMaterialReleaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleaseForBreakdownsInput>;
  create?: InputMaybe<RawMaterialReleaseCreateWithoutRawMaterialReleaseForBreakdownsInput>;
  update?: InputMaybe<RawMaterialReleaseUpdateWithoutRawMaterialReleaseForBreakdownsInput>;
  upsert?: InputMaybe<RawMaterialReleaseUpsertWithoutRawMaterialReleaseForBreakdownsInput>;
};

export type RawMaterialReleaseUpdateOneRequiredWithoutRawMaterialReleaseItemsNestedInput = {
  connect?: InputMaybe<RawMaterialReleaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleaseItemsInput>;
  create?: InputMaybe<RawMaterialReleaseCreateWithoutRawMaterialReleaseItemsInput>;
  update?: InputMaybe<RawMaterialReleaseUpdateWithoutRawMaterialReleaseItemsInput>;
  upsert?: InputMaybe<RawMaterialReleaseUpsertWithoutRawMaterialReleaseItemsInput>;
};

export type RawMaterialReleaseUpdateOneRequiredWithoutRawMaterialReleasePackagingItemsNestedInput = {
  connect?: InputMaybe<RawMaterialReleaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialReleaseCreateOrConnectWithoutRawMaterialReleasePackagingItemsInput>;
  create?: InputMaybe<RawMaterialReleaseCreateWithoutRawMaterialReleasePackagingItemsInput>;
  update?: InputMaybe<RawMaterialReleaseUpdateWithoutRawMaterialReleasePackagingItemsInput>;
  upsert?: InputMaybe<RawMaterialReleaseUpsertWithoutRawMaterialReleasePackagingItemsInput>;
};

export type RawMaterialReleaseUpdateWithoutRawMaterialReleaseForBreakdownsInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  destination?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialReleaseNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutRawMaterialReleaseNestedInput>;
};

export type RawMaterialReleaseUpdateWithoutRawMaterialReleaseItemsInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  destination?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialReleaseNestedInput>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemUpdateManyWithoutRawMaterialReleaseNestedInput>;
};

export type RawMaterialReleaseUpdateWithoutRawMaterialReleasePackagingItemsInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  destination?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownUpdateManyWithoutRawMaterialReleaseNestedInput>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemUpdateManyWithoutRawMaterialReleaseNestedInput>;
};

export type RawMaterialReleaseUpsertWithoutRawMaterialReleaseForBreakdownsInput = {
  create: RawMaterialReleaseCreateWithoutRawMaterialReleaseForBreakdownsInput;
  update: RawMaterialReleaseUpdateWithoutRawMaterialReleaseForBreakdownsInput;
};

export type RawMaterialReleaseUpsertWithoutRawMaterialReleaseItemsInput = {
  create: RawMaterialReleaseCreateWithoutRawMaterialReleaseItemsInput;
  update: RawMaterialReleaseUpdateWithoutRawMaterialReleaseItemsInput;
};

export type RawMaterialReleaseUpsertWithoutRawMaterialReleasePackagingItemsInput = {
  create: RawMaterialReleaseCreateWithoutRawMaterialReleasePackagingItemsInput;
  update: RawMaterialReleaseUpdateWithoutRawMaterialReleasePackagingItemsInput;
};

export type RawMaterialReleaseWhereInput = {
  AND?: InputMaybe<Array<RawMaterialReleaseWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialReleaseWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialReleaseWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  destination?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  rawMaterialReleaseForBreakdowns?: InputMaybe<RawMaterialReleaseForBreakdownListRelationFilter>;
  rawMaterialReleaseItems?: InputMaybe<RawMaterialReleaseItemListRelationFilter>;
  rawMaterialReleasePackagingItems?: InputMaybe<RawMaterialReleasePackagingItemListRelationFilter>;
};

export type RawMaterialReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RawMaterialRequest = {
  __typename?: 'RawMaterialRequest';
  _count?: Maybe<RawMaterialRequestCount>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  neededDate: Scalars['DateTime']['output'];
  outlet: Outlet;
  outletId: Scalars['Int']['output'];
  rawMaterialRequestItems: Array<RawMaterialRequestItem>;
  requestDate: Scalars['DateTime']['output'];
  sdsDeliveryReceipts: Array<SdsDeliveryReceipt>;
  status: Scalars['String']['output'];
};


export type RawMaterialRequestRawMaterialRequestItemsArgs = {
  cursor?: InputMaybe<RawMaterialRequestItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialRequestItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialRequestItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialRequestItemWhereInput>;
};


export type RawMaterialRequestSdsDeliveryReceiptsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
};

export type RawMaterialRequestAvgAggregate = {
  __typename?: 'RawMaterialRequestAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
};

export type RawMaterialRequestCount = {
  __typename?: 'RawMaterialRequestCount';
  rawMaterialRequestItems: Scalars['Int']['output'];
  sdsDeliveryReceipts: Scalars['Int']['output'];
};

export type RawMaterialRequestCountAggregate = {
  __typename?: 'RawMaterialRequestCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  neededDate: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
  requestDate: Scalars['Int']['output'];
};

export type RawMaterialRequestCreateInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  neededDate: Scalars['DateTime']['input'];
  outlet: OutletCreateNestedOneWithoutRawMaterialRequestsInput;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialRequestInput>;
  requestDate: Scalars['DateTime']['input'];
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutRawMaterialRequestInput>;
};

export type RawMaterialRequestCreateManyOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  neededDate: Scalars['DateTime']['input'];
  requestDate: Scalars['DateTime']['input'];
};

export type RawMaterialRequestCreateManyOutletInputEnvelope = {
  data: Array<RawMaterialRequestCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialRequestCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<RawMaterialRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialRequestCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<RawMaterialRequestCreateWithoutOutletInput>>;
  createMany?: InputMaybe<RawMaterialRequestCreateManyOutletInputEnvelope>;
};

export type RawMaterialRequestCreateNestedOneWithoutRawMaterialRequestItemsInput = {
  connect?: InputMaybe<RawMaterialRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialRequestCreateOrConnectWithoutRawMaterialRequestItemsInput>;
  create?: InputMaybe<RawMaterialRequestCreateWithoutRawMaterialRequestItemsInput>;
};

export type RawMaterialRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput = {
  connect?: InputMaybe<RawMaterialRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialRequestCreateOrConnectWithoutSdsDeliveryReceiptsInput>;
  create?: InputMaybe<RawMaterialRequestCreateWithoutSdsDeliveryReceiptsInput>;
};

export type RawMaterialRequestCreateOrConnectWithoutOutletInput = {
  create: RawMaterialRequestCreateWithoutOutletInput;
  where: RawMaterialRequestWhereUniqueInput;
};

export type RawMaterialRequestCreateOrConnectWithoutRawMaterialRequestItemsInput = {
  create: RawMaterialRequestCreateWithoutRawMaterialRequestItemsInput;
  where: RawMaterialRequestWhereUniqueInput;
};

export type RawMaterialRequestCreateOrConnectWithoutSdsDeliveryReceiptsInput = {
  create: RawMaterialRequestCreateWithoutSdsDeliveryReceiptsInput;
  where: RawMaterialRequestWhereUniqueInput;
};

export type RawMaterialRequestCreateWithoutOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  neededDate: Scalars['DateTime']['input'];
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialRequestInput>;
  requestDate: Scalars['DateTime']['input'];
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutRawMaterialRequestInput>;
};

export type RawMaterialRequestCreateWithoutRawMaterialRequestItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  neededDate: Scalars['DateTime']['input'];
  outlet: OutletCreateNestedOneWithoutRawMaterialRequestsInput;
  requestDate: Scalars['DateTime']['input'];
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptCreateNestedManyWithoutRawMaterialRequestInput>;
};

export type RawMaterialRequestCreateWithoutSdsDeliveryReceiptsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  neededDate: Scalars['DateTime']['input'];
  outlet: OutletCreateNestedOneWithoutRawMaterialRequestsInput;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemCreateNestedManyWithoutRawMaterialRequestInput>;
  requestDate: Scalars['DateTime']['input'];
};

export type RawMaterialRequestItem = {
  __typename?: 'RawMaterialRequestItem';
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  rawMaterialRequest: RawMaterialRequest;
  rawMaterialRequestId: Scalars['Int']['output'];
};

export type RawMaterialRequestItemCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialRequestId: Scalars['Int']['input'];
};

export type RawMaterialRequestItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<RawMaterialRequestItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialRequestItemCreateManyRawMaterialRequestInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type RawMaterialRequestItemCreateManyRawMaterialRequestInputEnvelope = {
  data: Array<RawMaterialRequestItemCreateManyRawMaterialRequestInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RawMaterialRequestItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialRequestItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialRequestItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialRequestItemCreateManyRawMaterialProductInputEnvelope>;
};

export type RawMaterialRequestItemCreateNestedManyWithoutRawMaterialRequestInput = {
  connect?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialRequestItemCreateOrConnectWithoutRawMaterialRequestInput>>;
  create?: InputMaybe<Array<RawMaterialRequestItemCreateWithoutRawMaterialRequestInput>>;
  createMany?: InputMaybe<RawMaterialRequestItemCreateManyRawMaterialRequestInputEnvelope>;
};

export type RawMaterialRequestItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: RawMaterialRequestItemCreateWithoutRawMaterialProductInput;
  where: RawMaterialRequestItemWhereUniqueInput;
};

export type RawMaterialRequestItemCreateOrConnectWithoutRawMaterialRequestInput = {
  create: RawMaterialRequestItemCreateWithoutRawMaterialRequestInput;
  where: RawMaterialRequestItemWhereUniqueInput;
};

export type RawMaterialRequestItemCreateWithoutRawMaterialProductInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialRequest: RawMaterialRequestCreateNestedOneWithoutRawMaterialRequestItemsInput;
};

export type RawMaterialRequestItemCreateWithoutRawMaterialRequestInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutRawMaterialRequestItemsInput;
};

export type RawMaterialRequestItemListRelationFilter = {
  every?: InputMaybe<RawMaterialRequestItemWhereInput>;
  none?: InputMaybe<RawMaterialRequestItemWhereInput>;
  some?: InputMaybe<RawMaterialRequestItemWhereInput>;
};

export type RawMaterialRequestItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialRequestItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestOrderByWithRelationInput>;
  rawMaterialRequestId?: InputMaybe<SortOrder>;
};

export enum RawMaterialRequestItemScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId',
  RawMaterialRequestId = 'rawMaterialRequestId'
}

export type RawMaterialRequestItemScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialRequestItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialRequestItemScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialRequestItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialRequestId?: InputMaybe<IntFilter>;
};

export type RawMaterialRequestItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type RawMaterialRequestItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: RawMaterialRequestItemUpdateManyMutationInput;
  where: RawMaterialRequestItemScalarWhereInput;
};

export type RawMaterialRequestItemUpdateManyWithWhereWithoutRawMaterialRequestInput = {
  data: RawMaterialRequestItemUpdateManyMutationInput;
  where: RawMaterialRequestItemScalarWhereInput;
};

export type RawMaterialRequestItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialRequestItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<RawMaterialRequestItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<RawMaterialRequestItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialRequestItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialRequestItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<RawMaterialRequestItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<RawMaterialRequestItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type RawMaterialRequestItemUpdateManyWithoutRawMaterialRequestNestedInput = {
  connect?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialRequestItemCreateOrConnectWithoutRawMaterialRequestInput>>;
  create?: InputMaybe<Array<RawMaterialRequestItemCreateWithoutRawMaterialRequestInput>>;
  createMany?: InputMaybe<RawMaterialRequestItemCreateManyRawMaterialRequestInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialRequestItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialRequestItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialRequestItemUpdateWithWhereUniqueWithoutRawMaterialRequestInput>>;
  updateMany?: InputMaybe<Array<RawMaterialRequestItemUpdateManyWithWhereWithoutRawMaterialRequestInput>>;
  upsert?: InputMaybe<Array<RawMaterialRequestItemUpsertWithWhereUniqueWithoutRawMaterialRequestInput>>;
};

export type RawMaterialRequestItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: RawMaterialRequestItemUpdateWithoutRawMaterialProductInput;
  where: RawMaterialRequestItemWhereUniqueInput;
};

export type RawMaterialRequestItemUpdateWithWhereUniqueWithoutRawMaterialRequestInput = {
  data: RawMaterialRequestItemUpdateWithoutRawMaterialRequestInput;
  where: RawMaterialRequestItemWhereUniqueInput;
};

export type RawMaterialRequestItemUpdateWithoutRawMaterialProductInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestUpdateOneRequiredWithoutRawMaterialRequestItemsNestedInput>;
};

export type RawMaterialRequestItemUpdateWithoutRawMaterialRequestInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutRawMaterialRequestItemsNestedInput>;
};

export type RawMaterialRequestItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: RawMaterialRequestItemCreateWithoutRawMaterialProductInput;
  update: RawMaterialRequestItemUpdateWithoutRawMaterialProductInput;
  where: RawMaterialRequestItemWhereUniqueInput;
};

export type RawMaterialRequestItemUpsertWithWhereUniqueWithoutRawMaterialRequestInput = {
  create: RawMaterialRequestItemCreateWithoutRawMaterialRequestInput;
  update: RawMaterialRequestItemUpdateWithoutRawMaterialRequestInput;
  where: RawMaterialRequestItemWhereUniqueInput;
};

export type RawMaterialRequestItemWhereInput = {
  AND?: InputMaybe<Array<RawMaterialRequestItemWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialRequestItemWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialRequestItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestRelationFilter>;
  rawMaterialRequestId?: InputMaybe<IntFilter>;
};

export type RawMaterialRequestItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RawMaterialRequestListRelationFilter = {
  every?: InputMaybe<RawMaterialRequestWhereInput>;
  none?: InputMaybe<RawMaterialRequestWhereInput>;
  some?: InputMaybe<RawMaterialRequestWhereInput>;
};

export type RawMaterialRequestMaxAggregate = {
  __typename?: 'RawMaterialRequestMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  neededDate?: Maybe<Scalars['DateTime']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  requestDate?: Maybe<Scalars['DateTime']['output']>;
};

export type RawMaterialRequestMinAggregate = {
  __typename?: 'RawMaterialRequestMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  neededDate?: Maybe<Scalars['DateTime']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  requestDate?: Maybe<Scalars['DateTime']['output']>;
};

export type RawMaterialRequestOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RawMaterialRequestOrderByWithRelationInput = {
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  neededDate?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemOrderByRelationAggregateInput>;
  requestDate?: InputMaybe<SortOrder>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptOrderByRelationAggregateInput>;
};

export type RawMaterialRequestRelationFilter = {
  is?: InputMaybe<RawMaterialRequestWhereInput>;
  isNot?: InputMaybe<RawMaterialRequestWhereInput>;
};

export enum RawMaterialRequestScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Id = 'id',
  NeededDate = 'neededDate',
  OutletId = 'outletId',
  RequestDate = 'requestDate'
}

export type RawMaterialRequestScalarWhereInput = {
  AND?: InputMaybe<Array<RawMaterialRequestScalarWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialRequestScalarWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  neededDate?: InputMaybe<DateTimeFilter>;
  outletId?: InputMaybe<IntFilter>;
  requestDate?: InputMaybe<DateTimeFilter>;
};

export type RawMaterialRequestSumAggregate = {
  __typename?: 'RawMaterialRequestSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialRequestUpdateInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutRawMaterialRequestsNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialRequestNestedInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutRawMaterialRequestNestedInput>;
};

export type RawMaterialRequestUpdateManyMutationInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RawMaterialRequestUpdateManyWithWhereWithoutOutletInput = {
  data: RawMaterialRequestUpdateManyMutationInput;
  where: RawMaterialRequestScalarWhereInput;
};

export type RawMaterialRequestUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<RawMaterialRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RawMaterialRequestCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<RawMaterialRequestCreateWithoutOutletInput>>;
  createMany?: InputMaybe<RawMaterialRequestCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<RawMaterialRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RawMaterialRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RawMaterialRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<RawMaterialRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<RawMaterialRequestUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<RawMaterialRequestUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<RawMaterialRequestUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type RawMaterialRequestUpdateOneRequiredWithoutRawMaterialRequestItemsNestedInput = {
  connect?: InputMaybe<RawMaterialRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialRequestCreateOrConnectWithoutRawMaterialRequestItemsInput>;
  create?: InputMaybe<RawMaterialRequestCreateWithoutRawMaterialRequestItemsInput>;
  update?: InputMaybe<RawMaterialRequestUpdateWithoutRawMaterialRequestItemsInput>;
  upsert?: InputMaybe<RawMaterialRequestUpsertWithoutRawMaterialRequestItemsInput>;
};

export type RawMaterialRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput = {
  connect?: InputMaybe<RawMaterialRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialRequestCreateOrConnectWithoutSdsDeliveryReceiptsInput>;
  create?: InputMaybe<RawMaterialRequestCreateWithoutSdsDeliveryReceiptsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<RawMaterialRequestUpdateWithoutSdsDeliveryReceiptsInput>;
  upsert?: InputMaybe<RawMaterialRequestUpsertWithoutSdsDeliveryReceiptsInput>;
};

export type RawMaterialRequestUpdateWithWhereUniqueWithoutOutletInput = {
  data: RawMaterialRequestUpdateWithoutOutletInput;
  where: RawMaterialRequestWhereUniqueInput;
};

export type RawMaterialRequestUpdateWithoutOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialRequestNestedInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutRawMaterialRequestNestedInput>;
};

export type RawMaterialRequestUpdateWithoutRawMaterialRequestItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutRawMaterialRequestsNestedInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptUpdateManyWithoutRawMaterialRequestNestedInput>;
};

export type RawMaterialRequestUpdateWithoutSdsDeliveryReceiptsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  neededDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutRawMaterialRequestsNestedInput>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemUpdateManyWithoutRawMaterialRequestNestedInput>;
  requestDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RawMaterialRequestUpsertWithWhereUniqueWithoutOutletInput = {
  create: RawMaterialRequestCreateWithoutOutletInput;
  update: RawMaterialRequestUpdateWithoutOutletInput;
  where: RawMaterialRequestWhereUniqueInput;
};

export type RawMaterialRequestUpsertWithoutRawMaterialRequestItemsInput = {
  create: RawMaterialRequestCreateWithoutRawMaterialRequestItemsInput;
  update: RawMaterialRequestUpdateWithoutRawMaterialRequestItemsInput;
};

export type RawMaterialRequestUpsertWithoutSdsDeliveryReceiptsInput = {
  create: RawMaterialRequestCreateWithoutSdsDeliveryReceiptsInput;
  update: RawMaterialRequestUpdateWithoutSdsDeliveryReceiptsInput;
};

export type RawMaterialRequestWhereInput = {
  AND?: InputMaybe<Array<RawMaterialRequestWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialRequestWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialRequestWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  neededDate?: InputMaybe<DateTimeFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
  rawMaterialRequestItems?: InputMaybe<RawMaterialRequestItemListRelationFilter>;
  requestDate?: InputMaybe<DateTimeFilter>;
  sdsDeliveryReceipts?: InputMaybe<SdsDeliveryReceiptListRelationFilter>;
};

export type RawMaterialRequestWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum RawMaterialScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  RawMaterialCategory = 'rawMaterialCategory',
  Status = 'status'
}

export type RawMaterialSumAggregate = {
  __typename?: 'RawMaterialSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type RawMaterialThrowAway = {
  __typename?: 'RawMaterialThrowAway';
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type RawMaterialUpdateInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawMaterialCategory?: InputMaybe<EnumRawMaterialCategoryFieldUpdateOperationsInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductUpdateManyWithoutRawMaterialNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type RawMaterialUpdateOneRequiredWithoutRawMaterialProductsNestedInput = {
  connect?: InputMaybe<RawMaterialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RawMaterialCreateOrConnectWithoutRawMaterialProductsInput>;
  create?: InputMaybe<RawMaterialCreateWithoutRawMaterialProductsInput>;
  update?: InputMaybe<RawMaterialUpdateWithoutRawMaterialProductsInput>;
  upsert?: InputMaybe<RawMaterialUpsertWithoutRawMaterialProductsInput>;
};

export type RawMaterialUpdateWithoutRawMaterialProductsInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawMaterialCategory?: InputMaybe<EnumRawMaterialCategoryFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type RawMaterialUpsertWithoutRawMaterialProductsInput = {
  create: RawMaterialCreateWithoutRawMaterialProductsInput;
  update: RawMaterialUpdateWithoutRawMaterialProductsInput;
};

export type RawMaterialWhereInput = {
  AND?: InputMaybe<Array<RawMaterialWhereInput>>;
  NOT?: InputMaybe<Array<RawMaterialWhereInput>>;
  OR?: InputMaybe<Array<RawMaterialWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  rawMaterialCategory?: InputMaybe<EnumRawMaterialCategoryFilter>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
};

export type RawMaterialWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RawMaterialsConsolidatedThrowAwayReportItem = {
  __typename?: 'RawMaterialsConsolidatedThrowAwayReportItem';
  date?: Maybe<Scalars['DateTime']['output']>;
  rawMaterials?: Maybe<Array<RawMaterialThrowAway>>;
};

export type RawMaterialsSalesProductControlReportItem = {
  __typename?: 'RawMaterialsSalesProductControlReportItem';
  beginningQuantity?: Maybe<Scalars['Float']['output']>;
  crtRawMaterialItemQuantity?: Maybe<Scalars['Float']['output']>;
  deliveriesQuantity?: Maybe<Scalars['Float']['output']>;
  emQuantity?: Maybe<Scalars['Float']['output']>;
  endingQuantity?: Maybe<Scalars['Float']['output']>;
  rawMaterial?: Maybe<Scalars['String']['output']>;
  sdQuantity?: Maybe<Scalars['Float']['output']>;
  taQuantity?: Maybe<Scalars['Float']['output']>;
  transferInQuantity?: Maybe<Scalars['Float']['output']>;
  transferOutQuantity?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  usage?: Maybe<Scalars['Float']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type ReceivedDeliveryReceipt = {
  __typename?: 'ReceivedDeliveryReceipt';
  _count?: Maybe<ReceivedDeliveryReceiptCount>;
  dateTime: Scalars['DateTime']['output'];
  deliveryReceipt: DeliveryReceipt;
  deliveryReceiptId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  receivedBy: Scalars['String']['output'];
  receivedDeliveryReceiptItems: Array<ReceivedDeliveryReceiptItem>;
};


export type ReceivedDeliveryReceiptReceivedDeliveryReceiptItemsArgs = {
  cursor?: InputMaybe<ReceivedDeliveryReceiptItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedDeliveryReceiptItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedDeliveryReceiptItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedDeliveryReceiptItemWhereInput>;
};

export type ReceivedDeliveryReceiptAvgAggregate = {
  __typename?: 'ReceivedDeliveryReceiptAvgAggregate';
  deliveryReceiptId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type ReceivedDeliveryReceiptCount = {
  __typename?: 'ReceivedDeliveryReceiptCount';
  receivedDeliveryReceiptItems: Scalars['Int']['output'];
};

export type ReceivedDeliveryReceiptCountAggregate = {
  __typename?: 'ReceivedDeliveryReceiptCountAggregate';
  _all: Scalars['Int']['output'];
  dateTime: Scalars['Int']['output'];
  deliveryReceiptId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  receivedBy: Scalars['Int']['output'];
};

export type ReceivedDeliveryReceiptCreateInput = {
  dateTime: Scalars['DateTime']['input'];
  deliveryReceipt: DeliveryReceiptCreateNestedOneWithoutReceivedDeliveryReceiptInput;
  receivedBy: Scalars['String']['input'];
  receivedDeliveryReceiptItems?: InputMaybe<ReceivedDeliveryReceiptItemCreateNestedManyWithoutReceivedDeliveryReceiptInput>;
};

export type ReceivedDeliveryReceiptCreateNestedOneWithoutDeliveryReceiptInput = {
  connect?: InputMaybe<ReceivedDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedDeliveryReceiptCreateOrConnectWithoutDeliveryReceiptInput>;
  create?: InputMaybe<ReceivedDeliveryReceiptCreateWithoutDeliveryReceiptInput>;
};

export type ReceivedDeliveryReceiptCreateNestedOneWithoutReceivedDeliveryReceiptItemsInput = {
  connect?: InputMaybe<ReceivedDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedDeliveryReceiptCreateOrConnectWithoutReceivedDeliveryReceiptItemsInput>;
  create?: InputMaybe<ReceivedDeliveryReceiptCreateWithoutReceivedDeliveryReceiptItemsInput>;
};

export type ReceivedDeliveryReceiptCreateOrConnectWithoutDeliveryReceiptInput = {
  create: ReceivedDeliveryReceiptCreateWithoutDeliveryReceiptInput;
  where: ReceivedDeliveryReceiptWhereUniqueInput;
};

export type ReceivedDeliveryReceiptCreateOrConnectWithoutReceivedDeliveryReceiptItemsInput = {
  create: ReceivedDeliveryReceiptCreateWithoutReceivedDeliveryReceiptItemsInput;
  where: ReceivedDeliveryReceiptWhereUniqueInput;
};

export type ReceivedDeliveryReceiptCreateWithoutDeliveryReceiptInput = {
  dateTime: Scalars['DateTime']['input'];
  receivedBy: Scalars['String']['input'];
  receivedDeliveryReceiptItems?: InputMaybe<ReceivedDeliveryReceiptItemCreateNestedManyWithoutReceivedDeliveryReceiptInput>;
};

export type ReceivedDeliveryReceiptCreateWithoutReceivedDeliveryReceiptItemsInput = {
  dateTime: Scalars['DateTime']['input'];
  deliveryReceipt: DeliveryReceiptCreateNestedOneWithoutReceivedDeliveryReceiptInput;
  receivedBy: Scalars['String']['input'];
};

export type ReceivedDeliveryReceiptItem = {
  __typename?: 'ReceivedDeliveryReceiptItem';
  deliveryReceiptItem: DeliveryReceiptItem;
  deliveryReceiptItemId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  receivedDeliveryReceipt: ReceivedDeliveryReceipt;
  receivedDeliveryReceiptId: Scalars['Int']['output'];
  receivedQuantity: Scalars['Int']['output'];
  rejectedQuantity: Scalars['Int']['output'];
};

export type ReceivedDeliveryReceiptItemCreateManyReceivedDeliveryReceiptInput = {
  deliveryReceiptItemId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  receivedQuantity: Scalars['Int']['input'];
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedDeliveryReceiptItemCreateManyReceivedDeliveryReceiptInputEnvelope = {
  data: Array<ReceivedDeliveryReceiptItemCreateManyReceivedDeliveryReceiptInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedDeliveryReceiptItemCreateNestedManyWithoutReceivedDeliveryReceiptInput = {
  connect?: InputMaybe<Array<ReceivedDeliveryReceiptItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedDeliveryReceiptItemCreateOrConnectWithoutReceivedDeliveryReceiptInput>>;
  create?: InputMaybe<Array<ReceivedDeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptInput>>;
  createMany?: InputMaybe<ReceivedDeliveryReceiptItemCreateManyReceivedDeliveryReceiptInputEnvelope>;
};

export type ReceivedDeliveryReceiptItemCreateNestedOneWithoutDeliveryReceiptItemInput = {
  connect?: InputMaybe<ReceivedDeliveryReceiptItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedDeliveryReceiptItemCreateOrConnectWithoutDeliveryReceiptItemInput>;
  create?: InputMaybe<ReceivedDeliveryReceiptItemCreateWithoutDeliveryReceiptItemInput>;
};

export type ReceivedDeliveryReceiptItemCreateOrConnectWithoutDeliveryReceiptItemInput = {
  create: ReceivedDeliveryReceiptItemCreateWithoutDeliveryReceiptItemInput;
  where: ReceivedDeliveryReceiptItemWhereUniqueInput;
};

export type ReceivedDeliveryReceiptItemCreateOrConnectWithoutReceivedDeliveryReceiptInput = {
  create: ReceivedDeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptInput;
  where: ReceivedDeliveryReceiptItemWhereUniqueInput;
};

export type ReceivedDeliveryReceiptItemCreateWithoutDeliveryReceiptItemInput = {
  receivedDeliveryReceipt: ReceivedDeliveryReceiptCreateNestedOneWithoutReceivedDeliveryReceiptItemsInput;
  receivedQuantity: Scalars['Int']['input'];
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedDeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptInput = {
  deliveryReceiptItem: DeliveryReceiptItemCreateNestedOneWithoutReceivedDeliveryReceiptItemInput;
  receivedQuantity: Scalars['Int']['input'];
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedDeliveryReceiptItemListRelationFilter = {
  every?: InputMaybe<ReceivedDeliveryReceiptItemWhereInput>;
  none?: InputMaybe<ReceivedDeliveryReceiptItemWhereInput>;
  some?: InputMaybe<ReceivedDeliveryReceiptItemWhereInput>;
};

export type ReceivedDeliveryReceiptItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReceivedDeliveryReceiptItemOrderByWithRelationInput = {
  deliveryReceiptItem?: InputMaybe<DeliveryReceiptItemOrderByWithRelationInput>;
  deliveryReceiptItemId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptOrderByWithRelationInput>;
  receivedDeliveryReceiptId?: InputMaybe<SortOrder>;
  receivedQuantity?: InputMaybe<SortOrder>;
  rejectedQuantity?: InputMaybe<SortOrder>;
};

export type ReceivedDeliveryReceiptItemRelationFilter = {
  is?: InputMaybe<ReceivedDeliveryReceiptItemWhereInput>;
  isNot?: InputMaybe<ReceivedDeliveryReceiptItemWhereInput>;
};

export enum ReceivedDeliveryReceiptItemScalarFieldEnum {
  DeliveryReceiptItemId = 'deliveryReceiptItemId',
  Id = 'id',
  ReceivedDeliveryReceiptId = 'receivedDeliveryReceiptId',
  ReceivedQuantity = 'receivedQuantity',
  RejectedQuantity = 'rejectedQuantity'
}

export type ReceivedDeliveryReceiptItemScalarWhereInput = {
  AND?: InputMaybe<Array<ReceivedDeliveryReceiptItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedDeliveryReceiptItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ReceivedDeliveryReceiptItemScalarWhereInput>>;
  deliveryReceiptItemId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  receivedDeliveryReceiptId?: InputMaybe<IntFilter>;
  receivedQuantity?: InputMaybe<IntFilter>;
  rejectedQuantity?: InputMaybe<IntFilter>;
};

export type ReceivedDeliveryReceiptItemUpdateManyMutationInput = {
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedDeliveryReceiptItemUpdateManyWithWhereWithoutReceivedDeliveryReceiptInput = {
  data: ReceivedDeliveryReceiptItemUpdateManyMutationInput;
  where: ReceivedDeliveryReceiptItemScalarWhereInput;
};

export type ReceivedDeliveryReceiptItemUpdateManyWithoutReceivedDeliveryReceiptNestedInput = {
  connect?: InputMaybe<Array<ReceivedDeliveryReceiptItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedDeliveryReceiptItemCreateOrConnectWithoutReceivedDeliveryReceiptInput>>;
  create?: InputMaybe<Array<ReceivedDeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptInput>>;
  createMany?: InputMaybe<ReceivedDeliveryReceiptItemCreateManyReceivedDeliveryReceiptInputEnvelope>;
  delete?: InputMaybe<Array<ReceivedDeliveryReceiptItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReceivedDeliveryReceiptItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReceivedDeliveryReceiptItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ReceivedDeliveryReceiptItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ReceivedDeliveryReceiptItemUpdateWithWhereUniqueWithoutReceivedDeliveryReceiptInput>>;
  updateMany?: InputMaybe<Array<ReceivedDeliveryReceiptItemUpdateManyWithWhereWithoutReceivedDeliveryReceiptInput>>;
  upsert?: InputMaybe<Array<ReceivedDeliveryReceiptItemUpsertWithWhereUniqueWithoutReceivedDeliveryReceiptInput>>;
};

export type ReceivedDeliveryReceiptItemUpdateOneWithoutDeliveryReceiptItemNestedInput = {
  connect?: InputMaybe<ReceivedDeliveryReceiptItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedDeliveryReceiptItemCreateOrConnectWithoutDeliveryReceiptItemInput>;
  create?: InputMaybe<ReceivedDeliveryReceiptItemCreateWithoutDeliveryReceiptItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedDeliveryReceiptItemUpdateWithoutDeliveryReceiptItemInput>;
  upsert?: InputMaybe<ReceivedDeliveryReceiptItemUpsertWithoutDeliveryReceiptItemInput>;
};

export type ReceivedDeliveryReceiptItemUpdateWithWhereUniqueWithoutReceivedDeliveryReceiptInput = {
  data: ReceivedDeliveryReceiptItemUpdateWithoutReceivedDeliveryReceiptInput;
  where: ReceivedDeliveryReceiptItemWhereUniqueInput;
};

export type ReceivedDeliveryReceiptItemUpdateWithoutDeliveryReceiptItemInput = {
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptUpdateOneRequiredWithoutReceivedDeliveryReceiptItemsNestedInput>;
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedDeliveryReceiptItemUpdateWithoutReceivedDeliveryReceiptInput = {
  deliveryReceiptItem?: InputMaybe<DeliveryReceiptItemUpdateOneRequiredWithoutReceivedDeliveryReceiptItemNestedInput>;
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedDeliveryReceiptItemUpsertWithWhereUniqueWithoutReceivedDeliveryReceiptInput = {
  create: ReceivedDeliveryReceiptItemCreateWithoutReceivedDeliveryReceiptInput;
  update: ReceivedDeliveryReceiptItemUpdateWithoutReceivedDeliveryReceiptInput;
  where: ReceivedDeliveryReceiptItemWhereUniqueInput;
};

export type ReceivedDeliveryReceiptItemUpsertWithoutDeliveryReceiptItemInput = {
  create: ReceivedDeliveryReceiptItemCreateWithoutDeliveryReceiptItemInput;
  update: ReceivedDeliveryReceiptItemUpdateWithoutDeliveryReceiptItemInput;
};

export type ReceivedDeliveryReceiptItemWhereInput = {
  AND?: InputMaybe<Array<ReceivedDeliveryReceiptItemWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedDeliveryReceiptItemWhereInput>>;
  OR?: InputMaybe<Array<ReceivedDeliveryReceiptItemWhereInput>>;
  deliveryReceiptItem?: InputMaybe<DeliveryReceiptItemRelationFilter>;
  deliveryReceiptItemId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  receivedDeliveryReceipt?: InputMaybe<ReceivedDeliveryReceiptRelationFilter>;
  receivedDeliveryReceiptId?: InputMaybe<IntFilter>;
  receivedQuantity?: InputMaybe<IntFilter>;
  rejectedQuantity?: InputMaybe<IntFilter>;
};

export type ReceivedDeliveryReceiptItemWhereUniqueInput = {
  deliveryReceiptItemId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedDeliveryReceiptMaxAggregate = {
  __typename?: 'ReceivedDeliveryReceiptMaxAggregate';
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  deliveryReceiptId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
};

export type ReceivedDeliveryReceiptMinAggregate = {
  __typename?: 'ReceivedDeliveryReceiptMinAggregate';
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  deliveryReceiptId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
};

export type ReceivedDeliveryReceiptOrderByWithRelationInput = {
  dateTime?: InputMaybe<SortOrder>;
  deliveryReceipt?: InputMaybe<DeliveryReceiptOrderByWithRelationInput>;
  deliveryReceiptId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receivedBy?: InputMaybe<SortOrder>;
  receivedDeliveryReceiptItems?: InputMaybe<ReceivedDeliveryReceiptItemOrderByRelationAggregateInput>;
};

export type ReceivedDeliveryReceiptRelationFilter = {
  is?: InputMaybe<ReceivedDeliveryReceiptWhereInput>;
  isNot?: InputMaybe<ReceivedDeliveryReceiptWhereInput>;
};

export enum ReceivedDeliveryReceiptScalarFieldEnum {
  DateTime = 'dateTime',
  DeliveryReceiptId = 'deliveryReceiptId',
  Id = 'id',
  ReceivedBy = 'receivedBy'
}

export type ReceivedDeliveryReceiptSumAggregate = {
  __typename?: 'ReceivedDeliveryReceiptSumAggregate';
  deliveryReceiptId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedDeliveryReceiptUpdateInput = {
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryReceipt?: InputMaybe<DeliveryReceiptUpdateOneRequiredWithoutReceivedDeliveryReceiptNestedInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedDeliveryReceiptItems?: InputMaybe<ReceivedDeliveryReceiptItemUpdateManyWithoutReceivedDeliveryReceiptNestedInput>;
};

export type ReceivedDeliveryReceiptUpdateOneRequiredWithoutReceivedDeliveryReceiptItemsNestedInput = {
  connect?: InputMaybe<ReceivedDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedDeliveryReceiptCreateOrConnectWithoutReceivedDeliveryReceiptItemsInput>;
  create?: InputMaybe<ReceivedDeliveryReceiptCreateWithoutReceivedDeliveryReceiptItemsInput>;
  update?: InputMaybe<ReceivedDeliveryReceiptUpdateWithoutReceivedDeliveryReceiptItemsInput>;
  upsert?: InputMaybe<ReceivedDeliveryReceiptUpsertWithoutReceivedDeliveryReceiptItemsInput>;
};

export type ReceivedDeliveryReceiptUpdateOneWithoutDeliveryReceiptNestedInput = {
  connect?: InputMaybe<ReceivedDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedDeliveryReceiptCreateOrConnectWithoutDeliveryReceiptInput>;
  create?: InputMaybe<ReceivedDeliveryReceiptCreateWithoutDeliveryReceiptInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedDeliveryReceiptUpdateWithoutDeliveryReceiptInput>;
  upsert?: InputMaybe<ReceivedDeliveryReceiptUpsertWithoutDeliveryReceiptInput>;
};

export type ReceivedDeliveryReceiptUpdateWithoutDeliveryReceiptInput = {
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedDeliveryReceiptItems?: InputMaybe<ReceivedDeliveryReceiptItemUpdateManyWithoutReceivedDeliveryReceiptNestedInput>;
};

export type ReceivedDeliveryReceiptUpdateWithoutReceivedDeliveryReceiptItemsInput = {
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryReceipt?: InputMaybe<DeliveryReceiptUpdateOneRequiredWithoutReceivedDeliveryReceiptNestedInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ReceivedDeliveryReceiptUpsertWithoutDeliveryReceiptInput = {
  create: ReceivedDeliveryReceiptCreateWithoutDeliveryReceiptInput;
  update: ReceivedDeliveryReceiptUpdateWithoutDeliveryReceiptInput;
};

export type ReceivedDeliveryReceiptUpsertWithoutReceivedDeliveryReceiptItemsInput = {
  create: ReceivedDeliveryReceiptCreateWithoutReceivedDeliveryReceiptItemsInput;
  update: ReceivedDeliveryReceiptUpdateWithoutReceivedDeliveryReceiptItemsInput;
};

export type ReceivedDeliveryReceiptWhereInput = {
  AND?: InputMaybe<Array<ReceivedDeliveryReceiptWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedDeliveryReceiptWhereInput>>;
  OR?: InputMaybe<Array<ReceivedDeliveryReceiptWhereInput>>;
  dateTime?: InputMaybe<DateTimeFilter>;
  deliveryReceipt?: InputMaybe<DeliveryReceiptRelationFilter>;
  deliveryReceiptId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  receivedBy?: InputMaybe<StringFilter>;
  receivedDeliveryReceiptItems?: InputMaybe<ReceivedDeliveryReceiptItemListRelationFilter>;
};

export type ReceivedDeliveryReceiptWhereUniqueInput = {
  deliveryReceiptId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedSdsDeliveryReceipt = {
  __typename?: 'ReceivedSdsDeliveryReceipt';
  _count?: Maybe<ReceivedSdsDeliveryReceiptCount>;
  dateTime: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id: Scalars['Int']['output'];
  receivedBy: Scalars['String']['output'];
  receivedSdsDeliveryReceiptPackagingItems: Array<ReceivedSdsDeliveryReceiptPackagingItem>;
  receivedSdsDeliveryReceiptRawMaterialItems: Array<ReceivedSdsDeliveryReceiptRawMaterialItem>;
  sdsDeliveryReceipt: SdsDeliveryReceipt;
  sdsDeliveryReceiptId: Scalars['Int']['output'];
};


export type ReceivedSdsDeliveryReceiptReceivedSdsDeliveryReceiptPackagingItemsArgs = {
  cursor?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>;
};


export type ReceivedSdsDeliveryReceiptReceivedSdsDeliveryReceiptRawMaterialItemsArgs = {
  cursor?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>;
};

export type ReceivedSdsDeliveryReceiptAvgAggregate = {
  __typename?: 'ReceivedSdsDeliveryReceiptAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Float']['output']>;
};

export type ReceivedSdsDeliveryReceiptCount = {
  __typename?: 'ReceivedSdsDeliveryReceiptCount';
  receivedSdsDeliveryReceiptPackagingItems: Scalars['Int']['output'];
  receivedSdsDeliveryReceiptRawMaterialItems: Scalars['Int']['output'];
};

export type ReceivedSdsDeliveryReceiptCountAggregate = {
  __typename?: 'ReceivedSdsDeliveryReceiptCountAggregate';
  _all: Scalars['Int']['output'];
  dateTime: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  receivedBy: Scalars['Int']['output'];
  sdsDeliveryReceiptId: Scalars['Int']['output'];
};

export type ReceivedSdsDeliveryReceiptCreateInput = {
  dateTime: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedSdsDeliveryReceiptPackagingItems?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateNestedManyWithoutReceivedSdsDeliveryReceiptInput>;
  receivedSdsDeliveryReceiptRawMaterialItems?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutReceivedSdsDeliveryReceiptInput>;
  sdsDeliveryReceipt: SdsDeliveryReceiptCreateNestedOneWithoutReceivedSdsDeliveryReceiptInput;
};

export type ReceivedSdsDeliveryReceiptCreateNestedOneWithoutReceivedSdsDeliveryReceiptPackagingItemsInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptPackagingItemsInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptPackagingItemsInput>;
};

export type ReceivedSdsDeliveryReceiptCreateNestedOneWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput>;
};

export type ReceivedSdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptInput>;
};

export type ReceivedSdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptPackagingItemsInput = {
  create: ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptPackagingItemsInput;
  where: ReceivedSdsDeliveryReceiptWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput = {
  create: ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput;
  where: ReceivedSdsDeliveryReceiptWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptInput = {
  create: ReceivedSdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptInput;
  where: ReceivedSdsDeliveryReceiptWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptPackagingItemsInput = {
  dateTime: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedSdsDeliveryReceiptRawMaterialItems?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutReceivedSdsDeliveryReceiptInput>;
  sdsDeliveryReceipt: SdsDeliveryReceiptCreateNestedOneWithoutReceivedSdsDeliveryReceiptInput;
};

export type ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput = {
  dateTime: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedSdsDeliveryReceiptPackagingItems?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateNestedManyWithoutReceivedSdsDeliveryReceiptInput>;
  sdsDeliveryReceipt: SdsDeliveryReceiptCreateNestedOneWithoutReceivedSdsDeliveryReceiptInput;
};

export type ReceivedSdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptInput = {
  dateTime: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedSdsDeliveryReceiptPackagingItems?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateNestedManyWithoutReceivedSdsDeliveryReceiptInput>;
  receivedSdsDeliveryReceiptRawMaterialItems?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutReceivedSdsDeliveryReceiptInput>;
};

export type ReceivedSdsDeliveryReceiptMaxAggregate = {
  __typename?: 'ReceivedSdsDeliveryReceiptMaxAggregate';
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedSdsDeliveryReceiptMinAggregate = {
  __typename?: 'ReceivedSdsDeliveryReceiptMinAggregate';
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedSdsDeliveryReceiptOrderByWithRelationInput = {
  dateTime?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receivedBy?: InputMaybe<SortOrder>;
  receivedSdsDeliveryReceiptPackagingItems?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemOrderByRelationAggregateInput>;
  receivedSdsDeliveryReceiptRawMaterialItems?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemOrderByRelationAggregateInput>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptOrderByWithRelationInput>;
  sdsDeliveryReceiptId?: InputMaybe<SortOrder>;
};

export type ReceivedSdsDeliveryReceiptPackagingItem = {
  __typename?: 'ReceivedSdsDeliveryReceiptPackagingItem';
  id: Scalars['Int']['output'];
  receivedQuantity: Scalars['Int']['output'];
  receivedSdsDeliveryReceipt: ReceivedSdsDeliveryReceipt;
  receivedSdsDeliveryReceiptId: Scalars['Int']['output'];
  rejectedQuantity: Scalars['Int']['output'];
  sdsDeliveryReceiptPackagingItem: SdsDeliveryReceiptPackagingItem;
  sdsDeliveryReceiptPackagingItemId: Scalars['Int']['output'];
};

export type ReceivedSdsDeliveryReceiptPackagingItemCreateManyReceivedSdsDeliveryReceiptInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  receivedQuantity: Scalars['Int']['input'];
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
  sdsDeliveryReceiptPackagingItemId: Scalars['Int']['input'];
};

export type ReceivedSdsDeliveryReceiptPackagingItemCreateManyReceivedSdsDeliveryReceiptInputEnvelope = {
  data: Array<ReceivedSdsDeliveryReceiptPackagingItemCreateManyReceivedSdsDeliveryReceiptInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemCreateNestedManyWithoutReceivedSdsDeliveryReceiptInput = {
  connect?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput>>;
  create?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptInput>>;
  createMany?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateManyReceivedSdsDeliveryReceiptInputEnvelope>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptPackagingItemInput>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput = {
  create: ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptInput;
  where: ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptPackagingItemCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemInput = {
  create: ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptPackagingItemInput;
  where: ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptInput = {
  receivedQuantity: Scalars['Int']['input'];
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
  sdsDeliveryReceiptPackagingItem: SdsDeliveryReceiptPackagingItemCreateNestedOneWithoutReceivedSdsDeliveryReceiptPackagingItemInput;
};

export type ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptPackagingItemInput = {
  receivedQuantity: Scalars['Int']['input'];
  receivedSdsDeliveryReceipt: ReceivedSdsDeliveryReceiptCreateNestedOneWithoutReceivedSdsDeliveryReceiptPackagingItemsInput;
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemListRelationFilter = {
  every?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>;
  none?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>;
  some?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  receivedQuantity?: InputMaybe<SortOrder>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptOrderByWithRelationInput>;
  receivedSdsDeliveryReceiptId?: InputMaybe<SortOrder>;
  rejectedQuantity?: InputMaybe<SortOrder>;
  sdsDeliveryReceiptPackagingItem?: InputMaybe<SdsDeliveryReceiptPackagingItemOrderByWithRelationInput>;
  sdsDeliveryReceiptPackagingItemId?: InputMaybe<SortOrder>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemRelationFilter = {
  is?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>;
  isNot?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>;
};

export enum ReceivedSdsDeliveryReceiptPackagingItemScalarFieldEnum {
  Id = 'id',
  ReceivedQuantity = 'receivedQuantity',
  ReceivedSdsDeliveryReceiptId = 'receivedSdsDeliveryReceiptId',
  RejectedQuantity = 'rejectedQuantity',
  SdsDeliveryReceiptPackagingItemId = 'sdsDeliveryReceiptPackagingItemId'
}

export type ReceivedSdsDeliveryReceiptPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  receivedQuantity?: InputMaybe<IntFilter>;
  receivedSdsDeliveryReceiptId?: InputMaybe<IntFilter>;
  rejectedQuantity?: InputMaybe<IntFilter>;
  sdsDeliveryReceiptPackagingItemId?: InputMaybe<IntFilter>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpdateManyMutationInput = {
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpdateManyWithWhereWithoutReceivedSdsDeliveryReceiptInput = {
  data: ReceivedSdsDeliveryReceiptPackagingItemUpdateManyMutationInput;
  where: ReceivedSdsDeliveryReceiptPackagingItemScalarWhereInput;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpdateManyWithoutReceivedSdsDeliveryReceiptNestedInput = {
  connect?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput>>;
  create?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptInput>>;
  createMany?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateManyReceivedSdsDeliveryReceiptInputEnvelope>;
  delete?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemUpdateWithWhereUniqueWithoutReceivedSdsDeliveryReceiptInput>>;
  updateMany?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemUpdateManyWithWhereWithoutReceivedSdsDeliveryReceiptInput>>;
  upsert?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemUpsertWithWhereUniqueWithoutReceivedSdsDeliveryReceiptInput>>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpdateOneWithoutSdsDeliveryReceiptPackagingItemNestedInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptPackagingItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemUpdateWithoutSdsDeliveryReceiptPackagingItemInput>;
  upsert?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemUpsertWithoutSdsDeliveryReceiptPackagingItemInput>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpdateWithWhereUniqueWithoutReceivedSdsDeliveryReceiptInput = {
  data: ReceivedSdsDeliveryReceiptPackagingItemUpdateWithoutReceivedSdsDeliveryReceiptInput;
  where: ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpdateWithoutReceivedSdsDeliveryReceiptInput = {
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdsDeliveryReceiptPackagingItem?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptPackagingItemNestedInput>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpdateWithoutSdsDeliveryReceiptPackagingItemInput = {
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptPackagingItemsNestedInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpsertWithWhereUniqueWithoutReceivedSdsDeliveryReceiptInput = {
  create: ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptInput;
  update: ReceivedSdsDeliveryReceiptPackagingItemUpdateWithoutReceivedSdsDeliveryReceiptInput;
  where: ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptPackagingItemUpsertWithoutSdsDeliveryReceiptPackagingItemInput = {
  create: ReceivedSdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptPackagingItemInput;
  update: ReceivedSdsDeliveryReceiptPackagingItemUpdateWithoutSdsDeliveryReceiptPackagingItemInput;
};

export type ReceivedSdsDeliveryReceiptPackagingItemWhereInput = {
  AND?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<ReceivedSdsDeliveryReceiptPackagingItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  receivedQuantity?: InputMaybe<IntFilter>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptRelationFilter>;
  receivedSdsDeliveryReceiptId?: InputMaybe<IntFilter>;
  rejectedQuantity?: InputMaybe<IntFilter>;
  sdsDeliveryReceiptPackagingItem?: InputMaybe<SdsDeliveryReceiptPackagingItemRelationFilter>;
  sdsDeliveryReceiptPackagingItemId?: InputMaybe<IntFilter>;
};

export type ReceivedSdsDeliveryReceiptPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sdsDeliveryReceiptPackagingItemId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItem = {
  __typename?: 'ReceivedSdsDeliveryReceiptRawMaterialItem';
  id: Scalars['Int']['output'];
  receivedQuantity: Scalars['Int']['output'];
  receivedSdsDeliveryReceipt: ReceivedSdsDeliveryReceipt;
  receivedSdsDeliveryReceiptId: Scalars['Int']['output'];
  rejectedQuantity: Scalars['Int']['output'];
  sdsDeliveryReceiptRawMaterialItem: SdsDeliveryReceiptRawMaterialItem;
  sdsDeliveryReceiptRawMaterialItemId: Scalars['Int']['output'];
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemCreateManyReceivedSdsDeliveryReceiptInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  receivedQuantity: Scalars['Int']['input'];
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
  sdsDeliveryReceiptRawMaterialItemId: Scalars['Int']['input'];
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemCreateManyReceivedSdsDeliveryReceiptInputEnvelope = {
  data: Array<ReceivedSdsDeliveryReceiptRawMaterialItemCreateManyReceivedSdsDeliveryReceiptInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutReceivedSdsDeliveryReceiptInput = {
  connect?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput>>;
  create?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptInput>>;
  createMany?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateManyReceivedSdsDeliveryReceiptInputEnvelope>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptRawMaterialItemInput>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput = {
  create: ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptInput;
  where: ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemInput = {
  create: ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptRawMaterialItemInput;
  where: ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptInput = {
  receivedQuantity: Scalars['Int']['input'];
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
  sdsDeliveryReceiptRawMaterialItem: SdsDeliveryReceiptRawMaterialItemCreateNestedOneWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptRawMaterialItemInput = {
  receivedQuantity: Scalars['Int']['input'];
  receivedSdsDeliveryReceipt: ReceivedSdsDeliveryReceiptCreateNestedOneWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput;
  rejectedQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemListRelationFilter = {
  every?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>;
  none?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>;
  some?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  receivedQuantity?: InputMaybe<SortOrder>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptOrderByWithRelationInput>;
  receivedSdsDeliveryReceiptId?: InputMaybe<SortOrder>;
  rejectedQuantity?: InputMaybe<SortOrder>;
  sdsDeliveryReceiptRawMaterialItem?: InputMaybe<SdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput>;
  sdsDeliveryReceiptRawMaterialItemId?: InputMaybe<SortOrder>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemRelationFilter = {
  is?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>;
  isNot?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>;
};

export enum ReceivedSdsDeliveryReceiptRawMaterialItemScalarFieldEnum {
  Id = 'id',
  ReceivedQuantity = 'receivedQuantity',
  ReceivedSdsDeliveryReceiptId = 'receivedSdsDeliveryReceiptId',
  RejectedQuantity = 'rejectedQuantity',
  SdsDeliveryReceiptRawMaterialItemId = 'sdsDeliveryReceiptRawMaterialItemId'
}

export type ReceivedSdsDeliveryReceiptRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  receivedQuantity?: InputMaybe<IntFilter>;
  receivedSdsDeliveryReceiptId?: InputMaybe<IntFilter>;
  rejectedQuantity?: InputMaybe<IntFilter>;
  sdsDeliveryReceiptRawMaterialItemId?: InputMaybe<IntFilter>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpdateManyMutationInput = {
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpdateManyWithWhereWithoutReceivedSdsDeliveryReceiptInput = {
  data: ReceivedSdsDeliveryReceiptRawMaterialItemUpdateManyMutationInput;
  where: ReceivedSdsDeliveryReceiptRawMaterialItemScalarWhereInput;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpdateManyWithoutReceivedSdsDeliveryReceiptNestedInput = {
  connect?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput>>;
  create?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptInput>>;
  createMany?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateManyReceivedSdsDeliveryReceiptInputEnvelope>;
  delete?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemUpdateWithWhereUniqueWithoutReceivedSdsDeliveryReceiptInput>>;
  updateMany?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemUpdateManyWithWhereWithoutReceivedSdsDeliveryReceiptInput>>;
  upsert?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemUpsertWithWhereUniqueWithoutReceivedSdsDeliveryReceiptInput>>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpdateOneWithoutSdsDeliveryReceiptRawMaterialItemNestedInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptRawMaterialItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemUpdateWithoutSdsDeliveryReceiptRawMaterialItemInput>;
  upsert?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemUpsertWithoutSdsDeliveryReceiptRawMaterialItemInput>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpdateWithWhereUniqueWithoutReceivedSdsDeliveryReceiptInput = {
  data: ReceivedSdsDeliveryReceiptRawMaterialItemUpdateWithoutReceivedSdsDeliveryReceiptInput;
  where: ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpdateWithoutReceivedSdsDeliveryReceiptInput = {
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdsDeliveryReceiptRawMaterialItem?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptRawMaterialItemNestedInput>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpdateWithoutSdsDeliveryReceiptRawMaterialItemInput = {
  receivedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptRawMaterialItemsNestedInput>;
  rejectedQuantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpsertWithWhereUniqueWithoutReceivedSdsDeliveryReceiptInput = {
  create: ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptInput;
  update: ReceivedSdsDeliveryReceiptRawMaterialItemUpdateWithoutReceivedSdsDeliveryReceiptInput;
  where: ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemUpsertWithoutSdsDeliveryReceiptRawMaterialItemInput = {
  create: ReceivedSdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptRawMaterialItemInput;
  update: ReceivedSdsDeliveryReceiptRawMaterialItemUpdateWithoutSdsDeliveryReceiptRawMaterialItemInput;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<ReceivedSdsDeliveryReceiptRawMaterialItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  receivedQuantity?: InputMaybe<IntFilter>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptRelationFilter>;
  receivedSdsDeliveryReceiptId?: InputMaybe<IntFilter>;
  rejectedQuantity?: InputMaybe<IntFilter>;
  sdsDeliveryReceiptRawMaterialItem?: InputMaybe<SdsDeliveryReceiptRawMaterialItemRelationFilter>;
  sdsDeliveryReceiptRawMaterialItemId?: InputMaybe<IntFilter>;
};

export type ReceivedSdsDeliveryReceiptRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sdsDeliveryReceiptRawMaterialItemId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedSdsDeliveryReceiptRelationFilter = {
  is?: InputMaybe<ReceivedSdsDeliveryReceiptWhereInput>;
  isNot?: InputMaybe<ReceivedSdsDeliveryReceiptWhereInput>;
};

export enum ReceivedSdsDeliveryReceiptScalarFieldEnum {
  DateTime = 'dateTime',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Id = 'id',
  ReceivedBy = 'receivedBy',
  SdsDeliveryReceiptId = 'sdsDeliveryReceiptId'
}

export type ReceivedSdsDeliveryReceiptSumAggregate = {
  __typename?: 'ReceivedSdsDeliveryReceiptSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedSdsDeliveryReceiptUpdateInput = {
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceiptPackagingItems?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemUpdateManyWithoutReceivedSdsDeliveryReceiptNestedInput>;
  receivedSdsDeliveryReceiptRawMaterialItems?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemUpdateManyWithoutReceivedSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptNestedInput>;
};

export type ReceivedSdsDeliveryReceiptUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptPackagingItemsNestedInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptPackagingItemsInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptPackagingItemsInput>;
  update?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptPackagingItemsInput>;
  upsert?: InputMaybe<ReceivedSdsDeliveryReceiptUpsertWithoutReceivedSdsDeliveryReceiptPackagingItemsInput>;
};

export type ReceivedSdsDeliveryReceiptUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptRawMaterialItemsNestedInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput>;
  update?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput>;
  upsert?: InputMaybe<ReceivedSdsDeliveryReceiptUpsertWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput>;
};

export type ReceivedSdsDeliveryReceiptUpdateOneWithoutSdsDeliveryReceiptNestedInput = {
  connect?: InputMaybe<ReceivedSdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedSdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptInput>;
  create?: InputMaybe<ReceivedSdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptInput>;
  upsert?: InputMaybe<ReceivedSdsDeliveryReceiptUpsertWithoutSdsDeliveryReceiptInput>;
};

export type ReceivedSdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptPackagingItemsInput = {
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceiptRawMaterialItems?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemUpdateManyWithoutReceivedSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptNestedInput>;
};

export type ReceivedSdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput = {
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceiptPackagingItems?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemUpdateManyWithoutReceivedSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptNestedInput>;
};

export type ReceivedSdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptInput = {
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceiptPackagingItems?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemUpdateManyWithoutReceivedSdsDeliveryReceiptNestedInput>;
  receivedSdsDeliveryReceiptRawMaterialItems?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemUpdateManyWithoutReceivedSdsDeliveryReceiptNestedInput>;
};

export type ReceivedSdsDeliveryReceiptUpsertWithoutReceivedSdsDeliveryReceiptPackagingItemsInput = {
  create: ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptPackagingItemsInput;
  update: ReceivedSdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptPackagingItemsInput;
};

export type ReceivedSdsDeliveryReceiptUpsertWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput = {
  create: ReceivedSdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput;
  update: ReceivedSdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptRawMaterialItemsInput;
};

export type ReceivedSdsDeliveryReceiptUpsertWithoutSdsDeliveryReceiptInput = {
  create: ReceivedSdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptInput;
  update: ReceivedSdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptInput;
};

export type ReceivedSdsDeliveryReceiptWhereInput = {
  AND?: InputMaybe<Array<ReceivedSdsDeliveryReceiptWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedSdsDeliveryReceiptWhereInput>>;
  OR?: InputMaybe<Array<ReceivedSdsDeliveryReceiptWhereInput>>;
  dateTime?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  receivedBy?: InputMaybe<StringFilter>;
  receivedSdsDeliveryReceiptPackagingItems?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemListRelationFilter>;
  receivedSdsDeliveryReceiptRawMaterialItems?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemListRelationFilter>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptRelationFilter>;
  sdsDeliveryReceiptId?: InputMaybe<IntFilter>;
};

export type ReceivedSdsDeliveryReceiptWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sdsDeliveryReceiptId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedTransfer = {
  __typename?: 'ReceivedTransfer';
  _count?: Maybe<ReceivedTransferCount>;
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id: Scalars['Int']['output'];
  receivedBy: Scalars['String']['output'];
  receivedTransferCmlItems: Array<ReceivedTransferCmlItem>;
  receivedTransferPackagingItems: Array<ReceivedTransferPackagingItem>;
  receivedTransferRawMaterialItems: Array<ReceivedTransferRawMaterialItem>;
  transfer: Transfer;
  transferId: Scalars['Int']['output'];
};


export type ReceivedTransferReceivedTransferCmlItemsArgs = {
  cursor?: InputMaybe<ReceivedTransferCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedTransferCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedTransferCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedTransferCmlItemWhereInput>;
};


export type ReceivedTransferReceivedTransferPackagingItemsArgs = {
  cursor?: InputMaybe<ReceivedTransferPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedTransferPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedTransferPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedTransferPackagingItemWhereInput>;
};


export type ReceivedTransferReceivedTransferRawMaterialItemsArgs = {
  cursor?: InputMaybe<ReceivedTransferRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedTransferRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedTransferRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedTransferRawMaterialItemWhereInput>;
};

export type ReceivedTransferAvgAggregate = {
  __typename?: 'ReceivedTransferAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  transferId?: Maybe<Scalars['Float']['output']>;
};

export type ReceivedTransferCmlItem = {
  __typename?: 'ReceivedTransferCmlItem';
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  receivedTransfer: ReceivedTransfer;
  receivedTransferId: Scalars['Int']['output'];
  transferCmlItem: TransferCmlItem;
  transferCmlItemId: Scalars['Int']['output'];
};

export type ReceivedTransferCmlItemCreateManyReceivedTransferInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  transferCmlItemId: Scalars['Int']['input'];
};

export type ReceivedTransferCmlItemCreateManyReceivedTransferInputEnvelope = {
  data: Array<ReceivedTransferCmlItemCreateManyReceivedTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedTransferCmlItemCreateNestedManyWithoutReceivedTransferInput = {
  connect?: InputMaybe<Array<ReceivedTransferCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedTransferCmlItemCreateOrConnectWithoutReceivedTransferInput>>;
  create?: InputMaybe<Array<ReceivedTransferCmlItemCreateWithoutReceivedTransferInput>>;
  createMany?: InputMaybe<ReceivedTransferCmlItemCreateManyReceivedTransferInputEnvelope>;
};

export type ReceivedTransferCmlItemCreateNestedOneWithoutTransferCmlItemInput = {
  connect?: InputMaybe<ReceivedTransferCmlItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCmlItemCreateOrConnectWithoutTransferCmlItemInput>;
  create?: InputMaybe<ReceivedTransferCmlItemCreateWithoutTransferCmlItemInput>;
};

export type ReceivedTransferCmlItemCreateOrConnectWithoutReceivedTransferInput = {
  create: ReceivedTransferCmlItemCreateWithoutReceivedTransferInput;
  where: ReceivedTransferCmlItemWhereUniqueInput;
};

export type ReceivedTransferCmlItemCreateOrConnectWithoutTransferCmlItemInput = {
  create: ReceivedTransferCmlItemCreateWithoutTransferCmlItemInput;
  where: ReceivedTransferCmlItemWhereUniqueInput;
};

export type ReceivedTransferCmlItemCreateWithoutReceivedTransferInput = {
  quantity: Scalars['Int']['input'];
  transferCmlItem: TransferCmlItemCreateNestedOneWithoutReceivedTransferCmlItemInput;
};

export type ReceivedTransferCmlItemCreateWithoutTransferCmlItemInput = {
  quantity: Scalars['Int']['input'];
  receivedTransfer: ReceivedTransferCreateNestedOneWithoutReceivedTransferCmlItemsInput;
};

export type ReceivedTransferCmlItemListRelationFilter = {
  every?: InputMaybe<ReceivedTransferCmlItemWhereInput>;
  none?: InputMaybe<ReceivedTransferCmlItemWhereInput>;
  some?: InputMaybe<ReceivedTransferCmlItemWhereInput>;
};

export type ReceivedTransferCmlItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReceivedTransferCmlItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  receivedTransfer?: InputMaybe<ReceivedTransferOrderByWithRelationInput>;
  receivedTransferId?: InputMaybe<SortOrder>;
  transferCmlItem?: InputMaybe<TransferCmlItemOrderByWithRelationInput>;
  transferCmlItemId?: InputMaybe<SortOrder>;
};

export type ReceivedTransferCmlItemRelationFilter = {
  is?: InputMaybe<ReceivedTransferCmlItemWhereInput>;
  isNot?: InputMaybe<ReceivedTransferCmlItemWhereInput>;
};

export enum ReceivedTransferCmlItemScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  ReceivedTransferId = 'receivedTransferId',
  TransferCmlItemId = 'transferCmlItemId'
}

export type ReceivedTransferCmlItemScalarWhereInput = {
  AND?: InputMaybe<Array<ReceivedTransferCmlItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedTransferCmlItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ReceivedTransferCmlItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  receivedTransferId?: InputMaybe<IntFilter>;
  transferCmlItemId?: InputMaybe<IntFilter>;
};

export type ReceivedTransferCmlItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedTransferCmlItemUpdateManyWithWhereWithoutReceivedTransferInput = {
  data: ReceivedTransferCmlItemUpdateManyMutationInput;
  where: ReceivedTransferCmlItemScalarWhereInput;
};

export type ReceivedTransferCmlItemUpdateManyWithoutReceivedTransferNestedInput = {
  connect?: InputMaybe<Array<ReceivedTransferCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedTransferCmlItemCreateOrConnectWithoutReceivedTransferInput>>;
  create?: InputMaybe<Array<ReceivedTransferCmlItemCreateWithoutReceivedTransferInput>>;
  createMany?: InputMaybe<ReceivedTransferCmlItemCreateManyReceivedTransferInputEnvelope>;
  delete?: InputMaybe<Array<ReceivedTransferCmlItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReceivedTransferCmlItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReceivedTransferCmlItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ReceivedTransferCmlItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ReceivedTransferCmlItemUpdateWithWhereUniqueWithoutReceivedTransferInput>>;
  updateMany?: InputMaybe<Array<ReceivedTransferCmlItemUpdateManyWithWhereWithoutReceivedTransferInput>>;
  upsert?: InputMaybe<Array<ReceivedTransferCmlItemUpsertWithWhereUniqueWithoutReceivedTransferInput>>;
};

export type ReceivedTransferCmlItemUpdateOneWithoutTransferCmlItemNestedInput = {
  connect?: InputMaybe<ReceivedTransferCmlItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCmlItemCreateOrConnectWithoutTransferCmlItemInput>;
  create?: InputMaybe<ReceivedTransferCmlItemCreateWithoutTransferCmlItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedTransferCmlItemUpdateWithoutTransferCmlItemInput>;
  upsert?: InputMaybe<ReceivedTransferCmlItemUpsertWithoutTransferCmlItemInput>;
};

export type ReceivedTransferCmlItemUpdateWithWhereUniqueWithoutReceivedTransferInput = {
  data: ReceivedTransferCmlItemUpdateWithoutReceivedTransferInput;
  where: ReceivedTransferCmlItemWhereUniqueInput;
};

export type ReceivedTransferCmlItemUpdateWithoutReceivedTransferInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  transferCmlItem?: InputMaybe<TransferCmlItemUpdateOneRequiredWithoutReceivedTransferCmlItemNestedInput>;
};

export type ReceivedTransferCmlItemUpdateWithoutTransferCmlItemInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneRequiredWithoutReceivedTransferCmlItemsNestedInput>;
};

export type ReceivedTransferCmlItemUpsertWithWhereUniqueWithoutReceivedTransferInput = {
  create: ReceivedTransferCmlItemCreateWithoutReceivedTransferInput;
  update: ReceivedTransferCmlItemUpdateWithoutReceivedTransferInput;
  where: ReceivedTransferCmlItemWhereUniqueInput;
};

export type ReceivedTransferCmlItemUpsertWithoutTransferCmlItemInput = {
  create: ReceivedTransferCmlItemCreateWithoutTransferCmlItemInput;
  update: ReceivedTransferCmlItemUpdateWithoutTransferCmlItemInput;
};

export type ReceivedTransferCmlItemWhereInput = {
  AND?: InputMaybe<Array<ReceivedTransferCmlItemWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedTransferCmlItemWhereInput>>;
  OR?: InputMaybe<Array<ReceivedTransferCmlItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  receivedTransfer?: InputMaybe<ReceivedTransferRelationFilter>;
  receivedTransferId?: InputMaybe<IntFilter>;
  transferCmlItem?: InputMaybe<TransferCmlItemRelationFilter>;
  transferCmlItemId?: InputMaybe<IntFilter>;
};

export type ReceivedTransferCmlItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  transferCmlItemId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedTransferCount = {
  __typename?: 'ReceivedTransferCount';
  receivedTransferCmlItems: Scalars['Int']['output'];
  receivedTransferPackagingItems: Scalars['Int']['output'];
  receivedTransferRawMaterialItems: Scalars['Int']['output'];
};

export type ReceivedTransferCountAggregate = {
  __typename?: 'ReceivedTransferCountAggregate';
  _all: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  receivedBy: Scalars['Int']['output'];
  transferId: Scalars['Int']['output'];
};

export type ReceivedTransferCreateInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemCreateNestedManyWithoutReceivedTransferInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemCreateNestedManyWithoutReceivedTransferInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemCreateNestedManyWithoutReceivedTransferInput>;
  transfer: TransferCreateNestedOneWithoutReceivedTransferInput;
};

export type ReceivedTransferCreateNestedOneWithoutReceivedTransferCmlItemsInput = {
  connect?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCreateOrConnectWithoutReceivedTransferCmlItemsInput>;
  create?: InputMaybe<ReceivedTransferCreateWithoutReceivedTransferCmlItemsInput>;
};

export type ReceivedTransferCreateNestedOneWithoutReceivedTransferPackagingItemsInput = {
  connect?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCreateOrConnectWithoutReceivedTransferPackagingItemsInput>;
  create?: InputMaybe<ReceivedTransferCreateWithoutReceivedTransferPackagingItemsInput>;
};

export type ReceivedTransferCreateNestedOneWithoutReceivedTransferRawMaterialItemsInput = {
  connect?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCreateOrConnectWithoutReceivedTransferRawMaterialItemsInput>;
  create?: InputMaybe<ReceivedTransferCreateWithoutReceivedTransferRawMaterialItemsInput>;
};

export type ReceivedTransferCreateNestedOneWithoutTransferInput = {
  connect?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCreateOrConnectWithoutTransferInput>;
  create?: InputMaybe<ReceivedTransferCreateWithoutTransferInput>;
};

export type ReceivedTransferCreateOrConnectWithoutReceivedTransferCmlItemsInput = {
  create: ReceivedTransferCreateWithoutReceivedTransferCmlItemsInput;
  where: ReceivedTransferWhereUniqueInput;
};

export type ReceivedTransferCreateOrConnectWithoutReceivedTransferPackagingItemsInput = {
  create: ReceivedTransferCreateWithoutReceivedTransferPackagingItemsInput;
  where: ReceivedTransferWhereUniqueInput;
};

export type ReceivedTransferCreateOrConnectWithoutReceivedTransferRawMaterialItemsInput = {
  create: ReceivedTransferCreateWithoutReceivedTransferRawMaterialItemsInput;
  where: ReceivedTransferWhereUniqueInput;
};

export type ReceivedTransferCreateOrConnectWithoutTransferInput = {
  create: ReceivedTransferCreateWithoutTransferInput;
  where: ReceivedTransferWhereUniqueInput;
};

export type ReceivedTransferCreateWithoutReceivedTransferCmlItemsInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemCreateNestedManyWithoutReceivedTransferInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemCreateNestedManyWithoutReceivedTransferInput>;
  transfer: TransferCreateNestedOneWithoutReceivedTransferInput;
};

export type ReceivedTransferCreateWithoutReceivedTransferPackagingItemsInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemCreateNestedManyWithoutReceivedTransferInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemCreateNestedManyWithoutReceivedTransferInput>;
  transfer: TransferCreateNestedOneWithoutReceivedTransferInput;
};

export type ReceivedTransferCreateWithoutReceivedTransferRawMaterialItemsInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemCreateNestedManyWithoutReceivedTransferInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemCreateNestedManyWithoutReceivedTransferInput>;
  transfer: TransferCreateNestedOneWithoutReceivedTransferInput;
};

export type ReceivedTransferCreateWithoutTransferInput = {
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedBy: Scalars['String']['input'];
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemCreateNestedManyWithoutReceivedTransferInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemCreateNestedManyWithoutReceivedTransferInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemCreateNestedManyWithoutReceivedTransferInput>;
};

export type ReceivedTransferMaxAggregate = {
  __typename?: 'ReceivedTransferMaxAggregate';
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedTransferMinAggregate = {
  __typename?: 'ReceivedTransferMinAggregate';
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  id?: Maybe<Scalars['Int']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedTransferOrderByWithRelationInput = {
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receivedBy?: InputMaybe<SortOrder>;
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemOrderByRelationAggregateInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemOrderByRelationAggregateInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemOrderByRelationAggregateInput>;
  transfer?: InputMaybe<TransferOrderByWithRelationInput>;
  transferId?: InputMaybe<SortOrder>;
};

export type ReceivedTransferPackagingItem = {
  __typename?: 'ReceivedTransferPackagingItem';
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  receivedTransfer: ReceivedTransfer;
  receivedTransferId: Scalars['Int']['output'];
  transferPackagingItem: TransferPackagingItem;
  transferPackagingItemId: Scalars['Int']['output'];
};

export type ReceivedTransferPackagingItemCreateManyReceivedTransferInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  transferPackagingItemId: Scalars['Int']['input'];
};

export type ReceivedTransferPackagingItemCreateManyReceivedTransferInputEnvelope = {
  data: Array<ReceivedTransferPackagingItemCreateManyReceivedTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedTransferPackagingItemCreateNestedManyWithoutReceivedTransferInput = {
  connect?: InputMaybe<Array<ReceivedTransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedTransferPackagingItemCreateOrConnectWithoutReceivedTransferInput>>;
  create?: InputMaybe<Array<ReceivedTransferPackagingItemCreateWithoutReceivedTransferInput>>;
  createMany?: InputMaybe<ReceivedTransferPackagingItemCreateManyReceivedTransferInputEnvelope>;
};

export type ReceivedTransferPackagingItemCreateNestedOneWithoutTransferPackagingItemInput = {
  connect?: InputMaybe<ReceivedTransferPackagingItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferPackagingItemCreateOrConnectWithoutTransferPackagingItemInput>;
  create?: InputMaybe<ReceivedTransferPackagingItemCreateWithoutTransferPackagingItemInput>;
};

export type ReceivedTransferPackagingItemCreateOrConnectWithoutReceivedTransferInput = {
  create: ReceivedTransferPackagingItemCreateWithoutReceivedTransferInput;
  where: ReceivedTransferPackagingItemWhereUniqueInput;
};

export type ReceivedTransferPackagingItemCreateOrConnectWithoutTransferPackagingItemInput = {
  create: ReceivedTransferPackagingItemCreateWithoutTransferPackagingItemInput;
  where: ReceivedTransferPackagingItemWhereUniqueInput;
};

export type ReceivedTransferPackagingItemCreateWithoutReceivedTransferInput = {
  quantity: Scalars['Int']['input'];
  transferPackagingItem: TransferPackagingItemCreateNestedOneWithoutReceivedTransferPackagingItemsInput;
};

export type ReceivedTransferPackagingItemCreateWithoutTransferPackagingItemInput = {
  quantity: Scalars['Int']['input'];
  receivedTransfer: ReceivedTransferCreateNestedOneWithoutReceivedTransferPackagingItemsInput;
};

export type ReceivedTransferPackagingItemListRelationFilter = {
  every?: InputMaybe<ReceivedTransferPackagingItemWhereInput>;
  none?: InputMaybe<ReceivedTransferPackagingItemWhereInput>;
  some?: InputMaybe<ReceivedTransferPackagingItemWhereInput>;
};

export type ReceivedTransferPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReceivedTransferPackagingItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  receivedTransfer?: InputMaybe<ReceivedTransferOrderByWithRelationInput>;
  receivedTransferId?: InputMaybe<SortOrder>;
  transferPackagingItem?: InputMaybe<TransferPackagingItemOrderByWithRelationInput>;
  transferPackagingItemId?: InputMaybe<SortOrder>;
};

export type ReceivedTransferPackagingItemRelationFilter = {
  is?: InputMaybe<ReceivedTransferPackagingItemWhereInput>;
  isNot?: InputMaybe<ReceivedTransferPackagingItemWhereInput>;
};

export enum ReceivedTransferPackagingItemScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  ReceivedTransferId = 'receivedTransferId',
  TransferPackagingItemId = 'transferPackagingItemId'
}

export type ReceivedTransferPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<ReceivedTransferPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedTransferPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ReceivedTransferPackagingItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  receivedTransferId?: InputMaybe<IntFilter>;
  transferPackagingItemId?: InputMaybe<IntFilter>;
};

export type ReceivedTransferPackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReceivedTransferPackagingItemUpdateManyWithWhereWithoutReceivedTransferInput = {
  data: ReceivedTransferPackagingItemUpdateManyMutationInput;
  where: ReceivedTransferPackagingItemScalarWhereInput;
};

export type ReceivedTransferPackagingItemUpdateManyWithoutReceivedTransferNestedInput = {
  connect?: InputMaybe<Array<ReceivedTransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedTransferPackagingItemCreateOrConnectWithoutReceivedTransferInput>>;
  create?: InputMaybe<Array<ReceivedTransferPackagingItemCreateWithoutReceivedTransferInput>>;
  createMany?: InputMaybe<ReceivedTransferPackagingItemCreateManyReceivedTransferInputEnvelope>;
  delete?: InputMaybe<Array<ReceivedTransferPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReceivedTransferPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReceivedTransferPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ReceivedTransferPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ReceivedTransferPackagingItemUpdateWithWhereUniqueWithoutReceivedTransferInput>>;
  updateMany?: InputMaybe<Array<ReceivedTransferPackagingItemUpdateManyWithWhereWithoutReceivedTransferInput>>;
  upsert?: InputMaybe<Array<ReceivedTransferPackagingItemUpsertWithWhereUniqueWithoutReceivedTransferInput>>;
};

export type ReceivedTransferPackagingItemUpdateOneWithoutTransferPackagingItemNestedInput = {
  connect?: InputMaybe<ReceivedTransferPackagingItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferPackagingItemCreateOrConnectWithoutTransferPackagingItemInput>;
  create?: InputMaybe<ReceivedTransferPackagingItemCreateWithoutTransferPackagingItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedTransferPackagingItemUpdateWithoutTransferPackagingItemInput>;
  upsert?: InputMaybe<ReceivedTransferPackagingItemUpsertWithoutTransferPackagingItemInput>;
};

export type ReceivedTransferPackagingItemUpdateWithWhereUniqueWithoutReceivedTransferInput = {
  data: ReceivedTransferPackagingItemUpdateWithoutReceivedTransferInput;
  where: ReceivedTransferPackagingItemWhereUniqueInput;
};

export type ReceivedTransferPackagingItemUpdateWithoutReceivedTransferInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  transferPackagingItem?: InputMaybe<TransferPackagingItemUpdateOneRequiredWithoutReceivedTransferPackagingItemsNestedInput>;
};

export type ReceivedTransferPackagingItemUpdateWithoutTransferPackagingItemInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneRequiredWithoutReceivedTransferPackagingItemsNestedInput>;
};

export type ReceivedTransferPackagingItemUpsertWithWhereUniqueWithoutReceivedTransferInput = {
  create: ReceivedTransferPackagingItemCreateWithoutReceivedTransferInput;
  update: ReceivedTransferPackagingItemUpdateWithoutReceivedTransferInput;
  where: ReceivedTransferPackagingItemWhereUniqueInput;
};

export type ReceivedTransferPackagingItemUpsertWithoutTransferPackagingItemInput = {
  create: ReceivedTransferPackagingItemCreateWithoutTransferPackagingItemInput;
  update: ReceivedTransferPackagingItemUpdateWithoutTransferPackagingItemInput;
};

export type ReceivedTransferPackagingItemWhereInput = {
  AND?: InputMaybe<Array<ReceivedTransferPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedTransferPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<ReceivedTransferPackagingItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  receivedTransfer?: InputMaybe<ReceivedTransferRelationFilter>;
  receivedTransferId?: InputMaybe<IntFilter>;
  transferPackagingItem?: InputMaybe<TransferPackagingItemRelationFilter>;
  transferPackagingItemId?: InputMaybe<IntFilter>;
};

export type ReceivedTransferPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  transferPackagingItemId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedTransferRawMaterialItem = {
  __typename?: 'ReceivedTransferRawMaterialItem';
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  receivedTransfer: ReceivedTransfer;
  receivedTransferId: Scalars['Int']['output'];
  transferRawMaterialItem: TransferRawMaterialItem;
  transferRawMaterialItemId: Scalars['Int']['output'];
};

export type ReceivedTransferRawMaterialItemCreateManyReceivedTransferInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  transferRawMaterialItemId: Scalars['Int']['input'];
};

export type ReceivedTransferRawMaterialItemCreateManyReceivedTransferInputEnvelope = {
  data: Array<ReceivedTransferRawMaterialItemCreateManyReceivedTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedTransferRawMaterialItemCreateNestedManyWithoutReceivedTransferInput = {
  connect?: InputMaybe<Array<ReceivedTransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedTransferRawMaterialItemCreateOrConnectWithoutReceivedTransferInput>>;
  create?: InputMaybe<Array<ReceivedTransferRawMaterialItemCreateWithoutReceivedTransferInput>>;
  createMany?: InputMaybe<ReceivedTransferRawMaterialItemCreateManyReceivedTransferInputEnvelope>;
};

export type ReceivedTransferRawMaterialItemCreateNestedOneWithoutTransferRawMaterialItemInput = {
  connect?: InputMaybe<ReceivedTransferRawMaterialItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferRawMaterialItemCreateOrConnectWithoutTransferRawMaterialItemInput>;
  create?: InputMaybe<ReceivedTransferRawMaterialItemCreateWithoutTransferRawMaterialItemInput>;
};

export type ReceivedTransferRawMaterialItemCreateOrConnectWithoutReceivedTransferInput = {
  create: ReceivedTransferRawMaterialItemCreateWithoutReceivedTransferInput;
  where: ReceivedTransferRawMaterialItemWhereUniqueInput;
};

export type ReceivedTransferRawMaterialItemCreateOrConnectWithoutTransferRawMaterialItemInput = {
  create: ReceivedTransferRawMaterialItemCreateWithoutTransferRawMaterialItemInput;
  where: ReceivedTransferRawMaterialItemWhereUniqueInput;
};

export type ReceivedTransferRawMaterialItemCreateWithoutReceivedTransferInput = {
  quantity: Scalars['Float']['input'];
  transferRawMaterialItem: TransferRawMaterialItemCreateNestedOneWithoutReceivedTransferRawMaterialItemsInput;
};

export type ReceivedTransferRawMaterialItemCreateWithoutTransferRawMaterialItemInput = {
  quantity: Scalars['Float']['input'];
  receivedTransfer: ReceivedTransferCreateNestedOneWithoutReceivedTransferRawMaterialItemsInput;
};

export type ReceivedTransferRawMaterialItemListRelationFilter = {
  every?: InputMaybe<ReceivedTransferRawMaterialItemWhereInput>;
  none?: InputMaybe<ReceivedTransferRawMaterialItemWhereInput>;
  some?: InputMaybe<ReceivedTransferRawMaterialItemWhereInput>;
};

export type ReceivedTransferRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReceivedTransferRawMaterialItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  receivedTransfer?: InputMaybe<ReceivedTransferOrderByWithRelationInput>;
  receivedTransferId?: InputMaybe<SortOrder>;
  transferRawMaterialItem?: InputMaybe<TransferRawMaterialItemOrderByWithRelationInput>;
  transferRawMaterialItemId?: InputMaybe<SortOrder>;
};

export type ReceivedTransferRawMaterialItemRelationFilter = {
  is?: InputMaybe<ReceivedTransferRawMaterialItemWhereInput>;
  isNot?: InputMaybe<ReceivedTransferRawMaterialItemWhereInput>;
};

export enum ReceivedTransferRawMaterialItemScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  ReceivedTransferId = 'receivedTransferId',
  TransferRawMaterialItemId = 'transferRawMaterialItemId'
}

export type ReceivedTransferRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<ReceivedTransferRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedTransferRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ReceivedTransferRawMaterialItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  receivedTransferId?: InputMaybe<IntFilter>;
  transferRawMaterialItemId?: InputMaybe<IntFilter>;
};

export type ReceivedTransferRawMaterialItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ReceivedTransferRawMaterialItemUpdateManyWithWhereWithoutReceivedTransferInput = {
  data: ReceivedTransferRawMaterialItemUpdateManyMutationInput;
  where: ReceivedTransferRawMaterialItemScalarWhereInput;
};

export type ReceivedTransferRawMaterialItemUpdateManyWithoutReceivedTransferNestedInput = {
  connect?: InputMaybe<Array<ReceivedTransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReceivedTransferRawMaterialItemCreateOrConnectWithoutReceivedTransferInput>>;
  create?: InputMaybe<Array<ReceivedTransferRawMaterialItemCreateWithoutReceivedTransferInput>>;
  createMany?: InputMaybe<ReceivedTransferRawMaterialItemCreateManyReceivedTransferInputEnvelope>;
  delete?: InputMaybe<Array<ReceivedTransferRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReceivedTransferRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReceivedTransferRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ReceivedTransferRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ReceivedTransferRawMaterialItemUpdateWithWhereUniqueWithoutReceivedTransferInput>>;
  updateMany?: InputMaybe<Array<ReceivedTransferRawMaterialItemUpdateManyWithWhereWithoutReceivedTransferInput>>;
  upsert?: InputMaybe<Array<ReceivedTransferRawMaterialItemUpsertWithWhereUniqueWithoutReceivedTransferInput>>;
};

export type ReceivedTransferRawMaterialItemUpdateOneWithoutTransferRawMaterialItemNestedInput = {
  connect?: InputMaybe<ReceivedTransferRawMaterialItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferRawMaterialItemCreateOrConnectWithoutTransferRawMaterialItemInput>;
  create?: InputMaybe<ReceivedTransferRawMaterialItemCreateWithoutTransferRawMaterialItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedTransferRawMaterialItemUpdateWithoutTransferRawMaterialItemInput>;
  upsert?: InputMaybe<ReceivedTransferRawMaterialItemUpsertWithoutTransferRawMaterialItemInput>;
};

export type ReceivedTransferRawMaterialItemUpdateWithWhereUniqueWithoutReceivedTransferInput = {
  data: ReceivedTransferRawMaterialItemUpdateWithoutReceivedTransferInput;
  where: ReceivedTransferRawMaterialItemWhereUniqueInput;
};

export type ReceivedTransferRawMaterialItemUpdateWithoutReceivedTransferInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  transferRawMaterialItem?: InputMaybe<TransferRawMaterialItemUpdateOneRequiredWithoutReceivedTransferRawMaterialItemsNestedInput>;
};

export type ReceivedTransferRawMaterialItemUpdateWithoutTransferRawMaterialItemInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneRequiredWithoutReceivedTransferRawMaterialItemsNestedInput>;
};

export type ReceivedTransferRawMaterialItemUpsertWithWhereUniqueWithoutReceivedTransferInput = {
  create: ReceivedTransferRawMaterialItemCreateWithoutReceivedTransferInput;
  update: ReceivedTransferRawMaterialItemUpdateWithoutReceivedTransferInput;
  where: ReceivedTransferRawMaterialItemWhereUniqueInput;
};

export type ReceivedTransferRawMaterialItemUpsertWithoutTransferRawMaterialItemInput = {
  create: ReceivedTransferRawMaterialItemCreateWithoutTransferRawMaterialItemInput;
  update: ReceivedTransferRawMaterialItemUpdateWithoutTransferRawMaterialItemInput;
};

export type ReceivedTransferRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<ReceivedTransferRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedTransferRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<ReceivedTransferRawMaterialItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  receivedTransfer?: InputMaybe<ReceivedTransferRelationFilter>;
  receivedTransferId?: InputMaybe<IntFilter>;
  transferRawMaterialItem?: InputMaybe<TransferRawMaterialItemRelationFilter>;
  transferRawMaterialItemId?: InputMaybe<IntFilter>;
};

export type ReceivedTransferRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  transferRawMaterialItemId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReceivedTransferRelationFilter = {
  is?: InputMaybe<ReceivedTransferWhereInput>;
  isNot?: InputMaybe<ReceivedTransferWhereInput>;
};

export enum ReceivedTransferScalarFieldEnum {
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  Id = 'id',
  ReceivedBy = 'receivedBy',
  TransferId = 'transferId'
}

export type ReceivedTransferSumAggregate = {
  __typename?: 'ReceivedTransferSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedTransferUpdateInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemUpdateManyWithoutReceivedTransferNestedInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemUpdateManyWithoutReceivedTransferNestedInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemUpdateManyWithoutReceivedTransferNestedInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutReceivedTransferNestedInput>;
};

export type ReceivedTransferUpdateOneRequiredWithoutReceivedTransferCmlItemsNestedInput = {
  connect?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCreateOrConnectWithoutReceivedTransferCmlItemsInput>;
  create?: InputMaybe<ReceivedTransferCreateWithoutReceivedTransferCmlItemsInput>;
  update?: InputMaybe<ReceivedTransferUpdateWithoutReceivedTransferCmlItemsInput>;
  upsert?: InputMaybe<ReceivedTransferUpsertWithoutReceivedTransferCmlItemsInput>;
};

export type ReceivedTransferUpdateOneRequiredWithoutReceivedTransferPackagingItemsNestedInput = {
  connect?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCreateOrConnectWithoutReceivedTransferPackagingItemsInput>;
  create?: InputMaybe<ReceivedTransferCreateWithoutReceivedTransferPackagingItemsInput>;
  update?: InputMaybe<ReceivedTransferUpdateWithoutReceivedTransferPackagingItemsInput>;
  upsert?: InputMaybe<ReceivedTransferUpsertWithoutReceivedTransferPackagingItemsInput>;
};

export type ReceivedTransferUpdateOneRequiredWithoutReceivedTransferRawMaterialItemsNestedInput = {
  connect?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCreateOrConnectWithoutReceivedTransferRawMaterialItemsInput>;
  create?: InputMaybe<ReceivedTransferCreateWithoutReceivedTransferRawMaterialItemsInput>;
  update?: InputMaybe<ReceivedTransferUpdateWithoutReceivedTransferRawMaterialItemsInput>;
  upsert?: InputMaybe<ReceivedTransferUpsertWithoutReceivedTransferRawMaterialItemsInput>;
};

export type ReceivedTransferUpdateOneWithoutTransferNestedInput = {
  connect?: InputMaybe<ReceivedTransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReceivedTransferCreateOrConnectWithoutTransferInput>;
  create?: InputMaybe<ReceivedTransferCreateWithoutTransferInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReceivedTransferUpdateWithoutTransferInput>;
  upsert?: InputMaybe<ReceivedTransferUpsertWithoutTransferInput>;
};

export type ReceivedTransferUpdateWithoutReceivedTransferCmlItemsInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemUpdateManyWithoutReceivedTransferNestedInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemUpdateManyWithoutReceivedTransferNestedInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutReceivedTransferNestedInput>;
};

export type ReceivedTransferUpdateWithoutReceivedTransferPackagingItemsInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemUpdateManyWithoutReceivedTransferNestedInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemUpdateManyWithoutReceivedTransferNestedInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutReceivedTransferNestedInput>;
};

export type ReceivedTransferUpdateWithoutReceivedTransferRawMaterialItemsInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemUpdateManyWithoutReceivedTransferNestedInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemUpdateManyWithoutReceivedTransferNestedInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutReceivedTransferNestedInput>;
};

export type ReceivedTransferUpdateWithoutTransferInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemUpdateManyWithoutReceivedTransferNestedInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemUpdateManyWithoutReceivedTransferNestedInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemUpdateManyWithoutReceivedTransferNestedInput>;
};

export type ReceivedTransferUpsertWithoutReceivedTransferCmlItemsInput = {
  create: ReceivedTransferCreateWithoutReceivedTransferCmlItemsInput;
  update: ReceivedTransferUpdateWithoutReceivedTransferCmlItemsInput;
};

export type ReceivedTransferUpsertWithoutReceivedTransferPackagingItemsInput = {
  create: ReceivedTransferCreateWithoutReceivedTransferPackagingItemsInput;
  update: ReceivedTransferUpdateWithoutReceivedTransferPackagingItemsInput;
};

export type ReceivedTransferUpsertWithoutReceivedTransferRawMaterialItemsInput = {
  create: ReceivedTransferCreateWithoutReceivedTransferRawMaterialItemsInput;
  update: ReceivedTransferUpdateWithoutReceivedTransferRawMaterialItemsInput;
};

export type ReceivedTransferUpsertWithoutTransferInput = {
  create: ReceivedTransferCreateWithoutTransferInput;
  update: ReceivedTransferUpdateWithoutTransferInput;
};

export type ReceivedTransferWhereInput = {
  AND?: InputMaybe<Array<ReceivedTransferWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedTransferWhereInput>>;
  OR?: InputMaybe<Array<ReceivedTransferWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  id?: InputMaybe<IntFilter>;
  receivedBy?: InputMaybe<StringFilter>;
  receivedTransferCmlItems?: InputMaybe<ReceivedTransferCmlItemListRelationFilter>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemListRelationFilter>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemListRelationFilter>;
  transfer?: InputMaybe<TransferRelationFilter>;
  transferId?: InputMaybe<IntFilter>;
};

export type ReceivedTransferWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  transferId?: InputMaybe<Scalars['Int']['input']>;
};

export { SalesCategory };

export type SalesPackagingControlReportItem = {
  __typename?: 'SalesPackagingControlReportItem';
  beginningQuantity?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  crtPackagingItemQuantity?: Maybe<Scalars['Float']['output']>;
  deliveriesQuantity?: Maybe<Scalars['Float']['output']>;
  emQuantity?: Maybe<Scalars['Float']['output']>;
  endingQuantity?: Maybe<Scalars['Float']['output']>;
  packaging?: Maybe<Scalars['String']['output']>;
  sdQuantity?: Maybe<Scalars['Float']['output']>;
  taQuantity?: Maybe<Scalars['Float']['output']>;
  transferInQuantity?: Maybe<Scalars['Float']['output']>;
  transferOutQuantity?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  usage?: Maybe<Scalars['Float']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type SalesPackagingControlReportItemWhereInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryShiftSchedule?: InputMaybe<DeliveryShiftSchedule>;
  outletId?: InputMaybe<Scalars['Float']['input']>;
};

export type SalesProductControlReportItemWhereInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryShiftSchedule?: InputMaybe<DeliveryShiftSchedule>;
  outletId?: InputMaybe<Scalars['Float']['input']>;
};

export type SdsDeliveryReceipt = {
  __typename?: 'SdsDeliveryReceipt';
  _count?: Maybe<SdsDeliveryReceiptCount>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  deliveredBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  outlet: Outlet;
  outletId: Scalars['Int']['output'];
  packagingRequest?: Maybe<PackagingRequest>;
  packagingRequestId?: Maybe<Scalars['Int']['output']>;
  rawMaterialRequest?: Maybe<RawMaterialRequest>;
  rawMaterialRequestId?: Maybe<Scalars['Int']['output']>;
  receivedSdsDeliveryReceipt?: Maybe<ReceivedSdsDeliveryReceipt>;
  sdsDeliveryReceiptPackagingItems: Array<SdsDeliveryReceiptPackagingItem>;
  sdsDeliveryReceiptRawMaterialItems: Array<SdsDeliveryReceiptRawMaterialItem>;
};


export type SdsDeliveryReceiptSdsDeliveryReceiptPackagingItemsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
};


export type SdsDeliveryReceiptSdsDeliveryReceiptRawMaterialItemsArgs = {
  cursor?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
};

export type SdsDeliveryReceiptAvgAggregate = {
  __typename?: 'SdsDeliveryReceiptAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  outletId?: Maybe<Scalars['Float']['output']>;
  packagingRequestId?: Maybe<Scalars['Float']['output']>;
  rawMaterialRequestId?: Maybe<Scalars['Float']['output']>;
};

export type SdsDeliveryReceiptCount = {
  __typename?: 'SdsDeliveryReceiptCount';
  sdsDeliveryReceiptPackagingItems: Scalars['Int']['output'];
  sdsDeliveryReceiptRawMaterialItems: Scalars['Int']['output'];
};

export type SdsDeliveryReceiptCountAggregate = {
  __typename?: 'SdsDeliveryReceiptCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveredBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  outletId: Scalars['Int']['output'];
  packagingRequestId: Scalars['Int']['output'];
  rawMaterialRequestId: Scalars['Int']['output'];
};

export type SdsDeliveryReceiptCreateInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutSdsDeliveryReceiptsInput;
  packagingRequest?: InputMaybe<PackagingRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptCreateManyOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingRequestId?: InputMaybe<Scalars['Int']['input']>;
  rawMaterialRequestId?: InputMaybe<Scalars['Int']['input']>;
};

export type SdsDeliveryReceiptCreateManyOutletInputEnvelope = {
  data: Array<SdsDeliveryReceiptCreateManyOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SdsDeliveryReceiptCreateManyPackagingRequestInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  outletId: Scalars['Int']['input'];
  rawMaterialRequestId?: InputMaybe<Scalars['Int']['input']>;
};

export type SdsDeliveryReceiptCreateManyPackagingRequestInputEnvelope = {
  data: Array<SdsDeliveryReceiptCreateManyPackagingRequestInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SdsDeliveryReceiptCreateManyRawMaterialRequestInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  outletId: Scalars['Int']['input'];
  packagingRequestId?: InputMaybe<Scalars['Int']['input']>;
};

export type SdsDeliveryReceiptCreateManyRawMaterialRequestInputEnvelope = {
  data: Array<SdsDeliveryReceiptCreateManyRawMaterialRequestInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SdsDeliveryReceiptCreateNestedManyWithoutOutletInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptCreateWithoutOutletInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptCreateManyOutletInputEnvelope>;
};

export type SdsDeliveryReceiptCreateNestedManyWithoutPackagingRequestInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptCreateOrConnectWithoutPackagingRequestInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptCreateWithoutPackagingRequestInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptCreateManyPackagingRequestInputEnvelope>;
};

export type SdsDeliveryReceiptCreateNestedManyWithoutRawMaterialRequestInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptCreateOrConnectWithoutRawMaterialRequestInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptCreateWithoutRawMaterialRequestInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptCreateManyRawMaterialRequestInputEnvelope>;
};

export type SdsDeliveryReceiptCreateNestedOneWithoutReceivedSdsDeliveryReceiptInput = {
  connect?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput>;
  create?: InputMaybe<SdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemsInput = {
  connect?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemsInput>;
  create?: InputMaybe<SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptPackagingItemsInput>;
};

export type SdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  connect?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
  create?: InputMaybe<SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
};

export type SdsDeliveryReceiptCreateOrConnectWithoutOutletInput = {
  create: SdsDeliveryReceiptCreateWithoutOutletInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptCreateOrConnectWithoutPackagingRequestInput = {
  create: SdsDeliveryReceiptCreateWithoutPackagingRequestInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptCreateOrConnectWithoutRawMaterialRequestInput = {
  create: SdsDeliveryReceiptCreateWithoutRawMaterialRequestInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput = {
  create: SdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemsInput = {
  create: SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptPackagingItemsInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  create: SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptCreateWithoutOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  packagingRequest?: InputMaybe<PackagingRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptCreateWithoutPackagingRequestInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutSdsDeliveryReceiptsInput;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptCreateWithoutRawMaterialRequestInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutSdsDeliveryReceiptsInput;
  packagingRequest?: InputMaybe<PackagingRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutSdsDeliveryReceiptsInput;
  packagingRequest?: InputMaybe<PackagingRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptPackagingItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutSdsDeliveryReceiptsInput;
  packagingRequest?: InputMaybe<PackagingRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  outlet: OutletCreateNestedOneWithoutSdsDeliveryReceiptsInput;
  packagingRequest?: InputMaybe<PackagingRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestCreateNestedOneWithoutSdsDeliveryReceiptsInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptListRelationFilter = {
  every?: InputMaybe<SdsDeliveryReceiptWhereInput>;
  none?: InputMaybe<SdsDeliveryReceiptWhereInput>;
  some?: InputMaybe<SdsDeliveryReceiptWhereInput>;
};

export type SdsDeliveryReceiptMaxAggregate = {
  __typename?: 'SdsDeliveryReceiptMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveredBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  packagingRequestId?: Maybe<Scalars['Int']['output']>;
  rawMaterialRequestId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptMinAggregate = {
  __typename?: 'SdsDeliveryReceiptMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveredBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  packagingRequestId?: Maybe<Scalars['Int']['output']>;
  rawMaterialRequestId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SdsDeliveryReceiptOrderByWithRelationInput = {
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deliveredBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outlet?: InputMaybe<OutletOrderByWithRelationInput>;
  outletId?: InputMaybe<SortOrder>;
  packagingRequest?: InputMaybe<PackagingRequestOrderByWithRelationInput>;
  packagingRequestId?: InputMaybe<SortOrder>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestOrderByWithRelationInput>;
  rawMaterialRequestId?: InputMaybe<SortOrder>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptOrderByWithRelationInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemOrderByRelationAggregateInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemOrderByRelationAggregateInput>;
};

export type SdsDeliveryReceiptPackagingItem = {
  __typename?: 'SdsDeliveryReceiptPackagingItem';
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  receivedSdsDeliveryReceiptPackagingItem?: Maybe<ReceivedSdsDeliveryReceiptPackagingItem>;
  referenceQuantity?: Maybe<Scalars['Int']['output']>;
  referenceUnit?: Maybe<Scalars['String']['output']>;
  sdsDeliveryReceipt: SdsDeliveryReceipt;
  sdsDeliveryReceiptId: Scalars['Int']['output'];
};

export type SdsDeliveryReceiptPackagingItemAvgAggregate = {
  __typename?: 'SdsDeliveryReceiptPackagingItemAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  packagingProductId?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  referenceQuantity?: Maybe<Scalars['Float']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Float']['output']>;
};

export type SdsDeliveryReceiptPackagingItemCountAggregate = {
  __typename?: 'SdsDeliveryReceiptPackagingItemCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  referenceQuantity: Scalars['Int']['output'];
  referenceUnit: Scalars['Int']['output'];
  sdsDeliveryReceiptId: Scalars['Int']['output'];
};

export type SdsDeliveryReceiptPackagingItemCreateManyPackagingProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
  sdsDeliveryReceiptId: Scalars['Int']['input'];
};

export type SdsDeliveryReceiptPackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<SdsDeliveryReceiptPackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SdsDeliveryReceiptPackagingItemCreateManySdsDeliveryReceiptInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
};

export type SdsDeliveryReceiptPackagingItemCreateManySdsDeliveryReceiptInputEnvelope = {
  data: Array<SdsDeliveryReceiptPackagingItemCreateManySdsDeliveryReceiptInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type SdsDeliveryReceiptPackagingItemCreateNestedManyWithoutSdsDeliveryReceiptInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutSdsDeliveryReceiptInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateManySdsDeliveryReceiptInputEnvelope>;
};

export type SdsDeliveryReceiptPackagingItemCreateNestedOneWithoutReceivedSdsDeliveryReceiptPackagingItemInput = {
  connect?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptPackagingItemInput>;
  create?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptPackagingItemInput>;
};

export type SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: SdsDeliveryReceiptPackagingItemCreateWithoutPackagingProductInput;
  where: SdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptPackagingItemInput = {
  create: SdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptPackagingItemInput;
  where: SdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutSdsDeliveryReceiptInput = {
  create: SdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptInput;
  where: SdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type SdsDeliveryReceiptPackagingItemCreateWithoutPackagingProductInput = {
  quantity: Scalars['Int']['input'];
  receivedSdsDeliveryReceiptPackagingItem?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemInput>;
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
  sdsDeliveryReceipt: SdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemsInput;
};

export type SdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptPackagingItemInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemsInput;
  quantity: Scalars['Int']['input'];
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
  sdsDeliveryReceipt: SdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemsInput;
};

export type SdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemsInput;
  quantity: Scalars['Int']['input'];
  receivedSdsDeliveryReceiptPackagingItem?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemCreateNestedOneWithoutSdsDeliveryReceiptPackagingItemInput>;
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
};

export type SdsDeliveryReceiptPackagingItemListRelationFilter = {
  every?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
  none?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
  some?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
};

export type SdsDeliveryReceiptPackagingItemMaxAggregate = {
  __typename?: 'SdsDeliveryReceiptPackagingItemMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  referenceQuantity?: Maybe<Scalars['Int']['output']>;
  referenceUnit?: Maybe<Scalars['String']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptPackagingItemMinAggregate = {
  __typename?: 'SdsDeliveryReceiptPackagingItemMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  referenceQuantity?: Maybe<Scalars['Int']['output']>;
  referenceUnit?: Maybe<Scalars['String']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SdsDeliveryReceiptPackagingItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  receivedSdsDeliveryReceiptPackagingItem?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemOrderByWithRelationInput>;
  referenceQuantity?: InputMaybe<SortOrder>;
  referenceUnit?: InputMaybe<SortOrder>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptOrderByWithRelationInput>;
  sdsDeliveryReceiptId?: InputMaybe<SortOrder>;
};

export type SdsDeliveryReceiptPackagingItemRelationFilter = {
  is?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
  isNot?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
};

export enum SdsDeliveryReceiptPackagingItemScalarFieldEnum {
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity',
  ReferenceQuantity = 'referenceQuantity',
  ReferenceUnit = 'referenceUnit',
  SdsDeliveryReceiptId = 'sdsDeliveryReceiptId'
}

export type SdsDeliveryReceiptPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  referenceQuantity?: InputMaybe<IntNullableFilter>;
  referenceUnit?: InputMaybe<StringNullableFilter>;
  sdsDeliveryReceiptId?: InputMaybe<IntFilter>;
};

export type SdsDeliveryReceiptPackagingItemSumAggregate = {
  __typename?: 'SdsDeliveryReceiptPackagingItemSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  referenceQuantity?: Maybe<Scalars['Int']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptPackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  referenceQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SdsDeliveryReceiptPackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: SdsDeliveryReceiptPackagingItemUpdateManyMutationInput;
  where: SdsDeliveryReceiptPackagingItemScalarWhereInput;
};

export type SdsDeliveryReceiptPackagingItemUpdateManyWithWhereWithoutSdsDeliveryReceiptInput = {
  data: SdsDeliveryReceiptPackagingItemUpdateManyMutationInput;
  where: SdsDeliveryReceiptPackagingItemScalarWhereInput;
};

export type SdsDeliveryReceiptPackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type SdsDeliveryReceiptPackagingItemUpdateManyWithoutSdsDeliveryReceiptNestedInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutSdsDeliveryReceiptInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateManySdsDeliveryReceiptInputEnvelope>;
  delete?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemUpdateWithWhereUniqueWithoutSdsDeliveryReceiptInput>>;
  updateMany?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemUpdateManyWithWhereWithoutSdsDeliveryReceiptInput>>;
  upsert?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemUpsertWithWhereUniqueWithoutSdsDeliveryReceiptInput>>;
};

export type SdsDeliveryReceiptPackagingItemUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptPackagingItemNestedInput = {
  connect?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptPackagingItemInput>;
  create?: InputMaybe<SdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptPackagingItemInput>;
  update?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateWithoutReceivedSdsDeliveryReceiptPackagingItemInput>;
  upsert?: InputMaybe<SdsDeliveryReceiptPackagingItemUpsertWithoutReceivedSdsDeliveryReceiptPackagingItemInput>;
};

export type SdsDeliveryReceiptPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: SdsDeliveryReceiptPackagingItemUpdateWithoutPackagingProductInput;
  where: SdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type SdsDeliveryReceiptPackagingItemUpdateWithWhereUniqueWithoutSdsDeliveryReceiptInput = {
  data: SdsDeliveryReceiptPackagingItemUpdateWithoutSdsDeliveryReceiptInput;
  where: SdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type SdsDeliveryReceiptPackagingItemUpdateWithoutPackagingProductInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceiptPackagingItem?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemUpdateOneWithoutSdsDeliveryReceiptPackagingItemNestedInput>;
  referenceQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptUpdateOneRequiredWithoutSdsDeliveryReceiptPackagingItemsNestedInput>;
};

export type SdsDeliveryReceiptPackagingItemUpdateWithoutReceivedSdsDeliveryReceiptPackagingItemInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutSdsDeliveryReceiptPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  referenceQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptUpdateOneRequiredWithoutSdsDeliveryReceiptPackagingItemsNestedInput>;
};

export type SdsDeliveryReceiptPackagingItemUpdateWithoutSdsDeliveryReceiptInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutSdsDeliveryReceiptPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceiptPackagingItem?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemUpdateOneWithoutSdsDeliveryReceiptPackagingItemNestedInput>;
  referenceQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SdsDeliveryReceiptPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: SdsDeliveryReceiptPackagingItemCreateWithoutPackagingProductInput;
  update: SdsDeliveryReceiptPackagingItemUpdateWithoutPackagingProductInput;
  where: SdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type SdsDeliveryReceiptPackagingItemUpsertWithWhereUniqueWithoutSdsDeliveryReceiptInput = {
  create: SdsDeliveryReceiptPackagingItemCreateWithoutSdsDeliveryReceiptInput;
  update: SdsDeliveryReceiptPackagingItemUpdateWithoutSdsDeliveryReceiptInput;
  where: SdsDeliveryReceiptPackagingItemWhereUniqueInput;
};

export type SdsDeliveryReceiptPackagingItemUpsertWithoutReceivedSdsDeliveryReceiptPackagingItemInput = {
  create: SdsDeliveryReceiptPackagingItemCreateWithoutReceivedSdsDeliveryReceiptPackagingItemInput;
  update: SdsDeliveryReceiptPackagingItemUpdateWithoutReceivedSdsDeliveryReceiptPackagingItemInput;
};

export type SdsDeliveryReceiptPackagingItemWhereInput = {
  AND?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  receivedSdsDeliveryReceiptPackagingItem?: InputMaybe<ReceivedSdsDeliveryReceiptPackagingItemRelationFilter>;
  referenceQuantity?: InputMaybe<IntNullableFilter>;
  referenceUnit?: InputMaybe<StringNullableFilter>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptRelationFilter>;
  sdsDeliveryReceiptId?: InputMaybe<IntFilter>;
};

export type SdsDeliveryReceiptPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type SdsDeliveryReceiptRawMaterialItem = {
  __typename?: 'SdsDeliveryReceiptRawMaterialItem';
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  receivedSdsDeliveryReceiptRawMaterialItem?: Maybe<ReceivedSdsDeliveryReceiptRawMaterialItem>;
  referenceQuantity?: Maybe<Scalars['Int']['output']>;
  referenceUnit?: Maybe<Scalars['String']['output']>;
  sdsDeliveryReceipt: SdsDeliveryReceipt;
  sdsDeliveryReceiptId: Scalars['Int']['output'];
};

export type SdsDeliveryReceiptRawMaterialItemAvgAggregate = {
  __typename?: 'SdsDeliveryReceiptRawMaterialItemAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Float']['output']>;
  referenceQuantity?: Maybe<Scalars['Float']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Float']['output']>;
};

export type SdsDeliveryReceiptRawMaterialItemCountAggregate = {
  __typename?: 'SdsDeliveryReceiptRawMaterialItemCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  rawMaterialProductId: Scalars['Int']['output'];
  referenceQuantity: Scalars['Int']['output'];
  referenceUnit: Scalars['Int']['output'];
  sdsDeliveryReceiptId: Scalars['Int']['output'];
};

export type SdsDeliveryReceiptRawMaterialItemCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
  sdsDeliveryReceiptId: Scalars['Int']['input'];
};

export type SdsDeliveryReceiptRawMaterialItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<SdsDeliveryReceiptRawMaterialItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SdsDeliveryReceiptRawMaterialItemCreateManySdsDeliveryReceiptInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
};

export type SdsDeliveryReceiptRawMaterialItemCreateManySdsDeliveryReceiptInputEnvelope = {
  data: Array<SdsDeliveryReceiptRawMaterialItemCreateManySdsDeliveryReceiptInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
};

export type SdsDeliveryReceiptRawMaterialItemCreateNestedManyWithoutSdsDeliveryReceiptInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutSdsDeliveryReceiptInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateManySdsDeliveryReceiptInputEnvelope>;
};

export type SdsDeliveryReceiptRawMaterialItemCreateNestedOneWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput = {
  connect?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput>;
  create?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput>;
};

export type SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: SdsDeliveryReceiptRawMaterialItemCreateWithoutRawMaterialProductInput;
  where: SdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput = {
  create: SdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput;
  where: SdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutSdsDeliveryReceiptInput = {
  create: SdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptInput;
  where: SdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type SdsDeliveryReceiptRawMaterialItemCreateWithoutRawMaterialProductInput = {
  quantity: Scalars['Float']['input'];
  receivedSdsDeliveryReceiptRawMaterialItem?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemInput>;
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
  sdsDeliveryReceipt: SdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemsInput;
};

export type SdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemsInput;
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
  sdsDeliveryReceipt: SdsDeliveryReceiptCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemsInput;
};

export type SdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemsInput;
  receivedSdsDeliveryReceiptRawMaterialItem?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemCreateNestedOneWithoutSdsDeliveryReceiptRawMaterialItemInput>;
  referenceQuantity?: InputMaybe<Scalars['Int']['input']>;
  referenceUnit?: InputMaybe<Scalars['String']['input']>;
};

export type SdsDeliveryReceiptRawMaterialItemListRelationFilter = {
  every?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
  none?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
  some?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
};

export type SdsDeliveryReceiptRawMaterialItemMaxAggregate = {
  __typename?: 'SdsDeliveryReceiptRawMaterialItemMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  referenceQuantity?: Maybe<Scalars['Int']['output']>;
  referenceUnit?: Maybe<Scalars['String']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptRawMaterialItemMinAggregate = {
  __typename?: 'SdsDeliveryReceiptRawMaterialItemMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  referenceQuantity?: Maybe<Scalars['Int']['output']>;
  referenceUnit?: Maybe<Scalars['String']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  receivedSdsDeliveryReceiptRawMaterialItem?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput>;
  referenceQuantity?: InputMaybe<SortOrder>;
  referenceUnit?: InputMaybe<SortOrder>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptOrderByWithRelationInput>;
  sdsDeliveryReceiptId?: InputMaybe<SortOrder>;
};

export type SdsDeliveryReceiptRawMaterialItemRelationFilter = {
  is?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
  isNot?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
};

export enum SdsDeliveryReceiptRawMaterialItemScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId',
  ReferenceQuantity = 'referenceQuantity',
  ReferenceUnit = 'referenceUnit',
  SdsDeliveryReceiptId = 'sdsDeliveryReceiptId'
}

export type SdsDeliveryReceiptRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  referenceQuantity?: InputMaybe<IntNullableFilter>;
  referenceUnit?: InputMaybe<StringNullableFilter>;
  sdsDeliveryReceiptId?: InputMaybe<IntFilter>;
};

export type SdsDeliveryReceiptRawMaterialItemSumAggregate = {
  __typename?: 'SdsDeliveryReceiptRawMaterialItemSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  referenceQuantity?: Maybe<Scalars['Int']['output']>;
  sdsDeliveryReceiptId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  referenceQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: SdsDeliveryReceiptRawMaterialItemUpdateManyMutationInput;
  where: SdsDeliveryReceiptRawMaterialItemScalarWhereInput;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateManyWithWhereWithoutSdsDeliveryReceiptInput = {
  data: SdsDeliveryReceiptRawMaterialItemUpdateManyMutationInput;
  where: SdsDeliveryReceiptRawMaterialItemScalarWhereInput;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutSdsDeliveryReceiptNestedInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutSdsDeliveryReceiptInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateManySdsDeliveryReceiptInputEnvelope>;
  delete?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemUpdateWithWhereUniqueWithoutSdsDeliveryReceiptInput>>;
  updateMany?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemUpdateManyWithWhereWithoutSdsDeliveryReceiptInput>>;
  upsert?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemUpsertWithWhereUniqueWithoutSdsDeliveryReceiptInput>>;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptRawMaterialItemNestedInput = {
  connect?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateOrConnectWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput>;
  create?: InputMaybe<SdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput>;
  update?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput>;
  upsert?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpsertWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput>;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: SdsDeliveryReceiptRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: SdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateWithWhereUniqueWithoutSdsDeliveryReceiptInput = {
  data: SdsDeliveryReceiptRawMaterialItemUpdateWithoutSdsDeliveryReceiptInput;
  where: SdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateWithoutRawMaterialProductInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  receivedSdsDeliveryReceiptRawMaterialItem?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemUpdateOneWithoutSdsDeliveryReceiptRawMaterialItemNestedInput>;
  referenceQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptUpdateOneRequiredWithoutSdsDeliveryReceiptRawMaterialItemsNestedInput>;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutSdsDeliveryReceiptRawMaterialItemsNestedInput>;
  referenceQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptUpdateOneRequiredWithoutSdsDeliveryReceiptRawMaterialItemsNestedInput>;
};

export type SdsDeliveryReceiptRawMaterialItemUpdateWithoutSdsDeliveryReceiptInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutSdsDeliveryReceiptRawMaterialItemsNestedInput>;
  receivedSdsDeliveryReceiptRawMaterialItem?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemUpdateOneWithoutSdsDeliveryReceiptRawMaterialItemNestedInput>;
  referenceQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  referenceUnit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SdsDeliveryReceiptRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: SdsDeliveryReceiptRawMaterialItemCreateWithoutRawMaterialProductInput;
  update: SdsDeliveryReceiptRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: SdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type SdsDeliveryReceiptRawMaterialItemUpsertWithWhereUniqueWithoutSdsDeliveryReceiptInput = {
  create: SdsDeliveryReceiptRawMaterialItemCreateWithoutSdsDeliveryReceiptInput;
  update: SdsDeliveryReceiptRawMaterialItemUpdateWithoutSdsDeliveryReceiptInput;
  where: SdsDeliveryReceiptRawMaterialItemWhereUniqueInput;
};

export type SdsDeliveryReceiptRawMaterialItemUpsertWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput = {
  create: SdsDeliveryReceiptRawMaterialItemCreateWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput;
  update: SdsDeliveryReceiptRawMaterialItemUpdateWithoutReceivedSdsDeliveryReceiptRawMaterialItemInput;
};

export type SdsDeliveryReceiptRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  receivedSdsDeliveryReceiptRawMaterialItem?: InputMaybe<ReceivedSdsDeliveryReceiptRawMaterialItemRelationFilter>;
  referenceQuantity?: InputMaybe<IntNullableFilter>;
  referenceUnit?: InputMaybe<StringNullableFilter>;
  sdsDeliveryReceipt?: InputMaybe<SdsDeliveryReceiptRelationFilter>;
  sdsDeliveryReceiptId?: InputMaybe<IntFilter>;
};

export type SdsDeliveryReceiptRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type SdsDeliveryReceiptRelationFilter = {
  is?: InputMaybe<SdsDeliveryReceiptWhereInput>;
  isNot?: InputMaybe<SdsDeliveryReceiptWhereInput>;
};

export enum SdsDeliveryReceiptScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Date = 'date',
  DeliveredBy = 'deliveredBy',
  Id = 'id',
  OutletId = 'outletId',
  PackagingRequestId = 'packagingRequestId',
  RawMaterialRequestId = 'rawMaterialRequestId'
}

export type SdsDeliveryReceiptScalarWhereInput = {
  AND?: InputMaybe<Array<SdsDeliveryReceiptScalarWhereInput>>;
  NOT?: InputMaybe<Array<SdsDeliveryReceiptScalarWhereInput>>;
  OR?: InputMaybe<Array<SdsDeliveryReceiptScalarWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveredBy?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  outletId?: InputMaybe<IntFilter>;
  packagingRequestId?: InputMaybe<IntNullableFilter>;
  rawMaterialRequestId?: InputMaybe<IntNullableFilter>;
};

export type SdsDeliveryReceiptSumAggregate = {
  __typename?: 'SdsDeliveryReceiptSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  outletId?: Maybe<Scalars['Int']['output']>;
  packagingRequestId?: Maybe<Scalars['Int']['output']>;
  rawMaterialRequestId?: Maybe<Scalars['Int']['output']>;
};

export type SdsDeliveryReceiptUpdateInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveredBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutSdsDeliveryReceiptsNestedInput>;
  packagingRequest?: InputMaybe<PackagingRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateOneWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
};

export type SdsDeliveryReceiptUpdateManyMutationInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveredBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SdsDeliveryReceiptUpdateManyWithWhereWithoutOutletInput = {
  data: SdsDeliveryReceiptUpdateManyMutationInput;
  where: SdsDeliveryReceiptScalarWhereInput;
};

export type SdsDeliveryReceiptUpdateManyWithWhereWithoutPackagingRequestInput = {
  data: SdsDeliveryReceiptUpdateManyMutationInput;
  where: SdsDeliveryReceiptScalarWhereInput;
};

export type SdsDeliveryReceiptUpdateManyWithWhereWithoutRawMaterialRequestInput = {
  data: SdsDeliveryReceiptUpdateManyMutationInput;
  where: SdsDeliveryReceiptScalarWhereInput;
};

export type SdsDeliveryReceiptUpdateManyWithoutOutletNestedInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptCreateOrConnectWithoutOutletInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptCreateWithoutOutletInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptCreateManyOutletInputEnvelope>;
  delete?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SdsDeliveryReceiptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  set?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  update?: InputMaybe<Array<SdsDeliveryReceiptUpdateWithWhereUniqueWithoutOutletInput>>;
  updateMany?: InputMaybe<Array<SdsDeliveryReceiptUpdateManyWithWhereWithoutOutletInput>>;
  upsert?: InputMaybe<Array<SdsDeliveryReceiptUpsertWithWhereUniqueWithoutOutletInput>>;
};

export type SdsDeliveryReceiptUpdateManyWithoutPackagingRequestNestedInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptCreateOrConnectWithoutPackagingRequestInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptCreateWithoutPackagingRequestInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptCreateManyPackagingRequestInputEnvelope>;
  delete?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SdsDeliveryReceiptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  set?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  update?: InputMaybe<Array<SdsDeliveryReceiptUpdateWithWhereUniqueWithoutPackagingRequestInput>>;
  updateMany?: InputMaybe<Array<SdsDeliveryReceiptUpdateManyWithWhereWithoutPackagingRequestInput>>;
  upsert?: InputMaybe<Array<SdsDeliveryReceiptUpsertWithWhereUniqueWithoutPackagingRequestInput>>;
};

export type SdsDeliveryReceiptUpdateManyWithoutRawMaterialRequestNestedInput = {
  connect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SdsDeliveryReceiptCreateOrConnectWithoutRawMaterialRequestInput>>;
  create?: InputMaybe<Array<SdsDeliveryReceiptCreateWithoutRawMaterialRequestInput>>;
  createMany?: InputMaybe<SdsDeliveryReceiptCreateManyRawMaterialRequestInputEnvelope>;
  delete?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SdsDeliveryReceiptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  set?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput>>;
  update?: InputMaybe<Array<SdsDeliveryReceiptUpdateWithWhereUniqueWithoutRawMaterialRequestInput>>;
  updateMany?: InputMaybe<Array<SdsDeliveryReceiptUpdateManyWithWhereWithoutRawMaterialRequestInput>>;
  upsert?: InputMaybe<Array<SdsDeliveryReceiptUpsertWithWhereUniqueWithoutRawMaterialRequestInput>>;
};

export type SdsDeliveryReceiptUpdateOneRequiredWithoutReceivedSdsDeliveryReceiptNestedInput = {
  connect?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptCreateOrConnectWithoutReceivedSdsDeliveryReceiptInput>;
  create?: InputMaybe<SdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptInput>;
  update?: InputMaybe<SdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptInput>;
  upsert?: InputMaybe<SdsDeliveryReceiptUpsertWithoutReceivedSdsDeliveryReceiptInput>;
};

export type SdsDeliveryReceiptUpdateOneRequiredWithoutSdsDeliveryReceiptPackagingItemsNestedInput = {
  connect?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptPackagingItemsInput>;
  create?: InputMaybe<SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptPackagingItemsInput>;
  update?: InputMaybe<SdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptPackagingItemsInput>;
  upsert?: InputMaybe<SdsDeliveryReceiptUpsertWithoutSdsDeliveryReceiptPackagingItemsInput>;
};

export type SdsDeliveryReceiptUpdateOneRequiredWithoutSdsDeliveryReceiptRawMaterialItemsNestedInput = {
  connect?: InputMaybe<SdsDeliveryReceiptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SdsDeliveryReceiptCreateOrConnectWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
  create?: InputMaybe<SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
  update?: InputMaybe<SdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
  upsert?: InputMaybe<SdsDeliveryReceiptUpsertWithoutSdsDeliveryReceiptRawMaterialItemsInput>;
};

export type SdsDeliveryReceiptUpdateWithWhereUniqueWithoutOutletInput = {
  data: SdsDeliveryReceiptUpdateWithoutOutletInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptUpdateWithWhereUniqueWithoutPackagingRequestInput = {
  data: SdsDeliveryReceiptUpdateWithoutPackagingRequestInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptUpdateWithWhereUniqueWithoutRawMaterialRequestInput = {
  data: SdsDeliveryReceiptUpdateWithoutRawMaterialRequestInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptUpdateWithoutOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveredBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  packagingRequest?: InputMaybe<PackagingRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateOneWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
};

export type SdsDeliveryReceiptUpdateWithoutPackagingRequestInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveredBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutSdsDeliveryReceiptsNestedInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateOneWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
};

export type SdsDeliveryReceiptUpdateWithoutRawMaterialRequestInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveredBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutSdsDeliveryReceiptsNestedInput>;
  packagingRequest?: InputMaybe<PackagingRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateOneWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
};

export type SdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveredBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutSdsDeliveryReceiptsNestedInput>;
  packagingRequest?: InputMaybe<PackagingRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
};

export type SdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptPackagingItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveredBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutSdsDeliveryReceiptsNestedInput>;
  packagingRequest?: InputMaybe<PackagingRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateOneWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
};

export type SdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveredBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  outlet?: InputMaybe<OutletUpdateOneRequiredWithoutSdsDeliveryReceiptsNestedInput>;
  packagingRequest?: InputMaybe<PackagingRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestUpdateOneWithoutSdsDeliveryReceiptsNestedInput>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptUpdateOneWithoutSdsDeliveryReceiptNestedInput>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemUpdateManyWithoutSdsDeliveryReceiptNestedInput>;
};

export type SdsDeliveryReceiptUpsertWithWhereUniqueWithoutOutletInput = {
  create: SdsDeliveryReceiptCreateWithoutOutletInput;
  update: SdsDeliveryReceiptUpdateWithoutOutletInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptUpsertWithWhereUniqueWithoutPackagingRequestInput = {
  create: SdsDeliveryReceiptCreateWithoutPackagingRequestInput;
  update: SdsDeliveryReceiptUpdateWithoutPackagingRequestInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptUpsertWithWhereUniqueWithoutRawMaterialRequestInput = {
  create: SdsDeliveryReceiptCreateWithoutRawMaterialRequestInput;
  update: SdsDeliveryReceiptUpdateWithoutRawMaterialRequestInput;
  where: SdsDeliveryReceiptWhereUniqueInput;
};

export type SdsDeliveryReceiptUpsertWithoutReceivedSdsDeliveryReceiptInput = {
  create: SdsDeliveryReceiptCreateWithoutReceivedSdsDeliveryReceiptInput;
  update: SdsDeliveryReceiptUpdateWithoutReceivedSdsDeliveryReceiptInput;
};

export type SdsDeliveryReceiptUpsertWithoutSdsDeliveryReceiptPackagingItemsInput = {
  create: SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptPackagingItemsInput;
  update: SdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptPackagingItemsInput;
};

export type SdsDeliveryReceiptUpsertWithoutSdsDeliveryReceiptRawMaterialItemsInput = {
  create: SdsDeliveryReceiptCreateWithoutSdsDeliveryReceiptRawMaterialItemsInput;
  update: SdsDeliveryReceiptUpdateWithoutSdsDeliveryReceiptRawMaterialItemsInput;
};

export type SdsDeliveryReceiptWhereInput = {
  AND?: InputMaybe<Array<SdsDeliveryReceiptWhereInput>>;
  NOT?: InputMaybe<Array<SdsDeliveryReceiptWhereInput>>;
  OR?: InputMaybe<Array<SdsDeliveryReceiptWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveredBy?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  outlet?: InputMaybe<OutletRelationFilter>;
  outletId?: InputMaybe<IntFilter>;
  packagingRequest?: InputMaybe<PackagingRequestRelationFilter>;
  packagingRequestId?: InputMaybe<IntNullableFilter>;
  rawMaterialRequest?: InputMaybe<RawMaterialRequestRelationFilter>;
  rawMaterialRequestId?: InputMaybe<IntNullableFilter>;
  receivedSdsDeliveryReceipt?: InputMaybe<ReceivedSdsDeliveryReceiptRelationFilter>;
  sdsDeliveryReceiptPackagingItems?: InputMaybe<SdsDeliveryReceiptPackagingItemListRelationFilter>;
  sdsDeliveryReceiptRawMaterialItems?: InputMaybe<SdsDeliveryReceiptRawMaterialItemListRelationFilter>;
};

export type SdsDeliveryReceiptWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export { Status };

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TotalDeliveryReceiptSummary = {
  __typename?: 'TotalDeliveryReceiptSummary';
  deliveryReceiptSummaries?: Maybe<Array<DeliveryReceiptSummary>>;
  donutMixAndCut?: Maybe<Scalars['String']['output']>;
};

export type TotalDeliveryReceiptSummaryWhereInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryShiftSchedule?: InputMaybe<DeliveryShiftSchedule>;
};

export type Transfer = {
  __typename?: 'Transfer';
  _count?: Maybe<TransferCount>;
  controlNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  fromOutlet: Outlet;
  fromOutletId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  receivedByDriver?: Maybe<Scalars['String']['output']>;
  receivedTransfer?: Maybe<ReceivedTransfer>;
  toOutlet: Outlet;
  toOutletId: Scalars['Int']['output'];
  transferCmlItems: Array<TransferCmlItem>;
  transferPackagingItems: Array<TransferPackagingItem>;
  transferRawMaterialItems: Array<TransferRawMaterialItem>;
};


export type TransferTransferCmlItemsArgs = {
  cursor?: InputMaybe<TransferCmlItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferCmlItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferCmlItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferCmlItemWhereInput>;
};


export type TransferTransferPackagingItemsArgs = {
  cursor?: InputMaybe<TransferPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferPackagingItemWhereInput>;
};


export type TransferTransferRawMaterialItemsArgs = {
  cursor?: InputMaybe<TransferRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransferRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransferRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferRawMaterialItemWhereInput>;
};

export type TransferAvgAggregate = {
  __typename?: 'TransferAvgAggregate';
  fromOutletId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  toOutletId?: Maybe<Scalars['Float']['output']>;
};

export type TransferCmlItem = {
  __typename?: 'TransferCmlItem';
  cmlProduct: CmlProduct;
  cmlProductId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  receivedTransferCmlItem?: Maybe<ReceivedTransferCmlItem>;
  transfer: Transfer;
  transferId: Scalars['Int']['output'];
};

export type TransferCmlItemAvgAggregate = {
  __typename?: 'TransferCmlItemAvgAggregate';
  cmlProductId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  transferId?: Maybe<Scalars['Float']['output']>;
};

export type TransferCmlItemCountAggregate = {
  __typename?: 'TransferCmlItemCountAggregate';
  _all: Scalars['Int']['output'];
  cmlProductId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  transferId: Scalars['Int']['output'];
};

export type TransferCmlItemCreateManyCmlProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  transferId: Scalars['Int']['input'];
};

export type TransferCmlItemCreateManyCmlProductInputEnvelope = {
  data: Array<TransferCmlItemCreateManyCmlProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferCmlItemCreateManyTransferInput = {
  cmlProductId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type TransferCmlItemCreateManyTransferInputEnvelope = {
  data: Array<TransferCmlItemCreateManyTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferCmlItemCreateNestedManyWithoutCmlProductInput = {
  connect?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferCmlItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<TransferCmlItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<TransferCmlItemCreateManyCmlProductInputEnvelope>;
};

export type TransferCmlItemCreateNestedManyWithoutTransferInput = {
  connect?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferCmlItemCreateOrConnectWithoutTransferInput>>;
  create?: InputMaybe<Array<TransferCmlItemCreateWithoutTransferInput>>;
  createMany?: InputMaybe<TransferCmlItemCreateManyTransferInputEnvelope>;
};

export type TransferCmlItemCreateNestedOneWithoutReceivedTransferCmlItemInput = {
  connect?: InputMaybe<TransferCmlItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCmlItemCreateOrConnectWithoutReceivedTransferCmlItemInput>;
  create?: InputMaybe<TransferCmlItemCreateWithoutReceivedTransferCmlItemInput>;
};

export type TransferCmlItemCreateOrConnectWithoutCmlProductInput = {
  create: TransferCmlItemCreateWithoutCmlProductInput;
  where: TransferCmlItemWhereUniqueInput;
};

export type TransferCmlItemCreateOrConnectWithoutReceivedTransferCmlItemInput = {
  create: TransferCmlItemCreateWithoutReceivedTransferCmlItemInput;
  where: TransferCmlItemWhereUniqueInput;
};

export type TransferCmlItemCreateOrConnectWithoutTransferInput = {
  create: TransferCmlItemCreateWithoutTransferInput;
  where: TransferCmlItemWhereUniqueInput;
};

export type TransferCmlItemCreateWithoutCmlProductInput = {
  quantity: Scalars['Int']['input'];
  receivedTransferCmlItem?: InputMaybe<ReceivedTransferCmlItemCreateNestedOneWithoutTransferCmlItemInput>;
  transfer: TransferCreateNestedOneWithoutTransferCmlItemsInput;
};

export type TransferCmlItemCreateWithoutReceivedTransferCmlItemInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutTransferCmlItemsInput;
  quantity: Scalars['Int']['input'];
  transfer: TransferCreateNestedOneWithoutTransferCmlItemsInput;
};

export type TransferCmlItemCreateWithoutTransferInput = {
  cmlProduct: CmlProductCreateNestedOneWithoutTransferCmlItemsInput;
  quantity: Scalars['Int']['input'];
  receivedTransferCmlItem?: InputMaybe<ReceivedTransferCmlItemCreateNestedOneWithoutTransferCmlItemInput>;
};

export type TransferCmlItemListRelationFilter = {
  every?: InputMaybe<TransferCmlItemWhereInput>;
  none?: InputMaybe<TransferCmlItemWhereInput>;
  some?: InputMaybe<TransferCmlItemWhereInput>;
};

export type TransferCmlItemMaxAggregate = {
  __typename?: 'TransferCmlItemMaxAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferCmlItemMinAggregate = {
  __typename?: 'TransferCmlItemMinAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferCmlItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransferCmlItemOrderByWithRelationInput = {
  cmlProduct?: InputMaybe<CmlProductOrderByWithRelationInput>;
  cmlProductId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  receivedTransferCmlItem?: InputMaybe<ReceivedTransferCmlItemOrderByWithRelationInput>;
  transfer?: InputMaybe<TransferOrderByWithRelationInput>;
  transferId?: InputMaybe<SortOrder>;
};

export type TransferCmlItemRelationFilter = {
  is?: InputMaybe<TransferCmlItemWhereInput>;
  isNot?: InputMaybe<TransferCmlItemWhereInput>;
};

export enum TransferCmlItemScalarFieldEnum {
  CmlProductId = 'cmlProductId',
  Id = 'id',
  Quantity = 'quantity',
  TransferId = 'transferId'
}

export type TransferCmlItemScalarWhereInput = {
  AND?: InputMaybe<Array<TransferCmlItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransferCmlItemScalarWhereInput>>;
  OR?: InputMaybe<Array<TransferCmlItemScalarWhereInput>>;
  cmlProductId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  transferId?: InputMaybe<IntFilter>;
};

export type TransferCmlItemSumAggregate = {
  __typename?: 'TransferCmlItemSumAggregate';
  cmlProductId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferCmlItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TransferCmlItemUpdateManyWithWhereWithoutCmlProductInput = {
  data: TransferCmlItemUpdateManyMutationInput;
  where: TransferCmlItemScalarWhereInput;
};

export type TransferCmlItemUpdateManyWithWhereWithoutTransferInput = {
  data: TransferCmlItemUpdateManyMutationInput;
  where: TransferCmlItemScalarWhereInput;
};

export type TransferCmlItemUpdateManyWithoutCmlProductNestedInput = {
  connect?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferCmlItemCreateOrConnectWithoutCmlProductInput>>;
  create?: InputMaybe<Array<TransferCmlItemCreateWithoutCmlProductInput>>;
  createMany?: InputMaybe<TransferCmlItemCreateManyCmlProductInputEnvelope>;
  delete?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferCmlItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferCmlItemUpdateWithWhereUniqueWithoutCmlProductInput>>;
  updateMany?: InputMaybe<Array<TransferCmlItemUpdateManyWithWhereWithoutCmlProductInput>>;
  upsert?: InputMaybe<Array<TransferCmlItemUpsertWithWhereUniqueWithoutCmlProductInput>>;
};

export type TransferCmlItemUpdateManyWithoutTransferNestedInput = {
  connect?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferCmlItemCreateOrConnectWithoutTransferInput>>;
  create?: InputMaybe<Array<TransferCmlItemCreateWithoutTransferInput>>;
  createMany?: InputMaybe<TransferCmlItemCreateManyTransferInputEnvelope>;
  delete?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferCmlItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferCmlItemWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferCmlItemUpdateWithWhereUniqueWithoutTransferInput>>;
  updateMany?: InputMaybe<Array<TransferCmlItemUpdateManyWithWhereWithoutTransferInput>>;
  upsert?: InputMaybe<Array<TransferCmlItemUpsertWithWhereUniqueWithoutTransferInput>>;
};

export type TransferCmlItemUpdateOneRequiredWithoutReceivedTransferCmlItemNestedInput = {
  connect?: InputMaybe<TransferCmlItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCmlItemCreateOrConnectWithoutReceivedTransferCmlItemInput>;
  create?: InputMaybe<TransferCmlItemCreateWithoutReceivedTransferCmlItemInput>;
  update?: InputMaybe<TransferCmlItemUpdateWithoutReceivedTransferCmlItemInput>;
  upsert?: InputMaybe<TransferCmlItemUpsertWithoutReceivedTransferCmlItemInput>;
};

export type TransferCmlItemUpdateWithWhereUniqueWithoutCmlProductInput = {
  data: TransferCmlItemUpdateWithoutCmlProductInput;
  where: TransferCmlItemWhereUniqueInput;
};

export type TransferCmlItemUpdateWithWhereUniqueWithoutTransferInput = {
  data: TransferCmlItemUpdateWithoutTransferInput;
  where: TransferCmlItemWhereUniqueInput;
};

export type TransferCmlItemUpdateWithoutCmlProductInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedTransferCmlItem?: InputMaybe<ReceivedTransferCmlItemUpdateOneWithoutTransferCmlItemNestedInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutTransferCmlItemsNestedInput>;
};

export type TransferCmlItemUpdateWithoutReceivedTransferCmlItemInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutTransferCmlItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutTransferCmlItemsNestedInput>;
};

export type TransferCmlItemUpdateWithoutTransferInput = {
  cmlProduct?: InputMaybe<CmlProductUpdateOneRequiredWithoutTransferCmlItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedTransferCmlItem?: InputMaybe<ReceivedTransferCmlItemUpdateOneWithoutTransferCmlItemNestedInput>;
};

export type TransferCmlItemUpsertWithWhereUniqueWithoutCmlProductInput = {
  create: TransferCmlItemCreateWithoutCmlProductInput;
  update: TransferCmlItemUpdateWithoutCmlProductInput;
  where: TransferCmlItemWhereUniqueInput;
};

export type TransferCmlItemUpsertWithWhereUniqueWithoutTransferInput = {
  create: TransferCmlItemCreateWithoutTransferInput;
  update: TransferCmlItemUpdateWithoutTransferInput;
  where: TransferCmlItemWhereUniqueInput;
};

export type TransferCmlItemUpsertWithoutReceivedTransferCmlItemInput = {
  create: TransferCmlItemCreateWithoutReceivedTransferCmlItemInput;
  update: TransferCmlItemUpdateWithoutReceivedTransferCmlItemInput;
};

export type TransferCmlItemWhereInput = {
  AND?: InputMaybe<Array<TransferCmlItemWhereInput>>;
  NOT?: InputMaybe<Array<TransferCmlItemWhereInput>>;
  OR?: InputMaybe<Array<TransferCmlItemWhereInput>>;
  cmlProduct?: InputMaybe<CmlProductRelationFilter>;
  cmlProductId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  receivedTransferCmlItem?: InputMaybe<ReceivedTransferCmlItemRelationFilter>;
  transfer?: InputMaybe<TransferRelationFilter>;
  transferId?: InputMaybe<IntFilter>;
};

export type TransferCmlItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TransferCount = {
  __typename?: 'TransferCount';
  transferCmlItems: Scalars['Int']['output'];
  transferPackagingItems: Scalars['Int']['output'];
  transferRawMaterialItems: Scalars['Int']['output'];
};

export type TransferCountAggregate = {
  __typename?: 'TransferCountAggregate';
  _all: Scalars['Int']['output'];
  controlNumber: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  deliveryShiftSchedule: Scalars['Int']['output'];
  fromOutletId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  receivedByDriver: Scalars['Int']['output'];
  toOutletId: Scalars['Int']['output'];
};

export type TransferCreateInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  fromOutlet: OutletCreateNestedOneWithoutTransferFromOutletsInput;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedTransfer?: InputMaybe<ReceivedTransferCreateNestedOneWithoutTransferInput>;
  toOutlet: OutletCreateNestedOneWithoutTransferToOutletsInput;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutTransferInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutTransferInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutTransferInput>;
};

export type TransferCreateManyFromOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  id?: InputMaybe<Scalars['Int']['input']>;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  toOutletId: Scalars['Int']['input'];
};

export type TransferCreateManyFromOutletInputEnvelope = {
  data: Array<TransferCreateManyFromOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferCreateManyToOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  fromOutletId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
};

export type TransferCreateManyToOutletInputEnvelope = {
  data: Array<TransferCreateManyToOutletInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferCreateNestedManyWithoutFromOutletInput = {
  connect?: InputMaybe<Array<TransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferCreateOrConnectWithoutFromOutletInput>>;
  create?: InputMaybe<Array<TransferCreateWithoutFromOutletInput>>;
  createMany?: InputMaybe<TransferCreateManyFromOutletInputEnvelope>;
};

export type TransferCreateNestedManyWithoutToOutletInput = {
  connect?: InputMaybe<Array<TransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferCreateOrConnectWithoutToOutletInput>>;
  create?: InputMaybe<Array<TransferCreateWithoutToOutletInput>>;
  createMany?: InputMaybe<TransferCreateManyToOutletInputEnvelope>;
};

export type TransferCreateNestedOneWithoutReceivedTransferInput = {
  connect?: InputMaybe<TransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCreateOrConnectWithoutReceivedTransferInput>;
  create?: InputMaybe<TransferCreateWithoutReceivedTransferInput>;
};

export type TransferCreateNestedOneWithoutTransferCmlItemsInput = {
  connect?: InputMaybe<TransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCreateOrConnectWithoutTransferCmlItemsInput>;
  create?: InputMaybe<TransferCreateWithoutTransferCmlItemsInput>;
};

export type TransferCreateNestedOneWithoutTransferPackagingItemsInput = {
  connect?: InputMaybe<TransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCreateOrConnectWithoutTransferPackagingItemsInput>;
  create?: InputMaybe<TransferCreateWithoutTransferPackagingItemsInput>;
};

export type TransferCreateNestedOneWithoutTransferRawMaterialItemsInput = {
  connect?: InputMaybe<TransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCreateOrConnectWithoutTransferRawMaterialItemsInput>;
  create?: InputMaybe<TransferCreateWithoutTransferRawMaterialItemsInput>;
};

export type TransferCreateOrConnectWithoutFromOutletInput = {
  create: TransferCreateWithoutFromOutletInput;
  where: TransferWhereUniqueInput;
};

export type TransferCreateOrConnectWithoutReceivedTransferInput = {
  create: TransferCreateWithoutReceivedTransferInput;
  where: TransferWhereUniqueInput;
};

export type TransferCreateOrConnectWithoutToOutletInput = {
  create: TransferCreateWithoutToOutletInput;
  where: TransferWhereUniqueInput;
};

export type TransferCreateOrConnectWithoutTransferCmlItemsInput = {
  create: TransferCreateWithoutTransferCmlItemsInput;
  where: TransferWhereUniqueInput;
};

export type TransferCreateOrConnectWithoutTransferPackagingItemsInput = {
  create: TransferCreateWithoutTransferPackagingItemsInput;
  where: TransferWhereUniqueInput;
};

export type TransferCreateOrConnectWithoutTransferRawMaterialItemsInput = {
  create: TransferCreateWithoutTransferRawMaterialItemsInput;
  where: TransferWhereUniqueInput;
};

export type TransferCreateWithoutFromOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedTransfer?: InputMaybe<ReceivedTransferCreateNestedOneWithoutTransferInput>;
  toOutlet: OutletCreateNestedOneWithoutTransferToOutletsInput;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutTransferInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutTransferInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutTransferInput>;
};

export type TransferCreateWithoutReceivedTransferInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  fromOutlet: OutletCreateNestedOneWithoutTransferFromOutletsInput;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  toOutlet: OutletCreateNestedOneWithoutTransferToOutletsInput;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutTransferInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutTransferInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutTransferInput>;
};

export type TransferCreateWithoutToOutletInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  fromOutlet: OutletCreateNestedOneWithoutTransferFromOutletsInput;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedTransfer?: InputMaybe<ReceivedTransferCreateNestedOneWithoutTransferInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutTransferInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutTransferInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutTransferInput>;
};

export type TransferCreateWithoutTransferCmlItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  fromOutlet: OutletCreateNestedOneWithoutTransferFromOutletsInput;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedTransfer?: InputMaybe<ReceivedTransferCreateNestedOneWithoutTransferInput>;
  toOutlet: OutletCreateNestedOneWithoutTransferToOutletsInput;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutTransferInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutTransferInput>;
};

export type TransferCreateWithoutTransferPackagingItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  fromOutlet: OutletCreateNestedOneWithoutTransferFromOutletsInput;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedTransfer?: InputMaybe<ReceivedTransferCreateNestedOneWithoutTransferInput>;
  toOutlet: OutletCreateNestedOneWithoutTransferToOutletsInput;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutTransferInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemCreateNestedManyWithoutTransferInput>;
};

export type TransferCreateWithoutTransferRawMaterialItemsInput = {
  controlNumber: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  deliveryShiftSchedule: DeliveryShiftSchedule;
  fromOutlet: OutletCreateNestedOneWithoutTransferFromOutletsInput;
  receivedByDriver?: InputMaybe<Scalars['String']['input']>;
  receivedTransfer?: InputMaybe<ReceivedTransferCreateNestedOneWithoutTransferInput>;
  toOutlet: OutletCreateNestedOneWithoutTransferToOutletsInput;
  transferCmlItems?: InputMaybe<TransferCmlItemCreateNestedManyWithoutTransferInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemCreateNestedManyWithoutTransferInput>;
};

export { TransferDirection };

export type TransferListRelationFilter = {
  every?: InputMaybe<TransferWhereInput>;
  none?: InputMaybe<TransferWhereInput>;
  some?: InputMaybe<TransferWhereInput>;
};

export type TransferMaxAggregate = {
  __typename?: 'TransferMaxAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  fromOutletId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  receivedByDriver?: Maybe<Scalars['String']['output']>;
  toOutletId?: Maybe<Scalars['Int']['output']>;
};

export type TransferMinAggregate = {
  __typename?: 'TransferMinAggregate';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<DeliveryShiftSchedule>;
  fromOutletId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  receivedByDriver?: Maybe<Scalars['String']['output']>;
  toOutletId?: Maybe<Scalars['Int']['output']>;
};

export type TransferOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransferOrderByWithRelationInput = {
  controlNumber?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deliveryShiftSchedule?: InputMaybe<SortOrder>;
  fromOutlet?: InputMaybe<OutletOrderByWithRelationInput>;
  fromOutletId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receivedByDriver?: InputMaybe<SortOrder>;
  receivedTransfer?: InputMaybe<ReceivedTransferOrderByWithRelationInput>;
  toOutlet?: InputMaybe<OutletOrderByWithRelationInput>;
  toOutletId?: InputMaybe<SortOrder>;
  transferCmlItems?: InputMaybe<TransferCmlItemOrderByRelationAggregateInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemOrderByRelationAggregateInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemOrderByRelationAggregateInput>;
};

export type TransferPackagingItem = {
  __typename?: 'TransferPackagingItem';
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  receivedTransferPackagingItems?: Maybe<ReceivedTransferPackagingItem>;
  transfer: Transfer;
  transferId: Scalars['Int']['output'];
};

export type TransferPackagingItemAvgAggregate = {
  __typename?: 'TransferPackagingItemAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  packagingProductId?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  transferId?: Maybe<Scalars['Float']['output']>;
};

export type TransferPackagingItemCountAggregate = {
  __typename?: 'TransferPackagingItemCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  transferId: Scalars['Int']['output'];
};

export type TransferPackagingItemCreateManyPackagingProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  transferId: Scalars['Int']['input'];
};

export type TransferPackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<TransferPackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferPackagingItemCreateManyTransferInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type TransferPackagingItemCreateManyTransferInputEnvelope = {
  data: Array<TransferPackagingItemCreateManyTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferPackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<TransferPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<TransferPackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type TransferPackagingItemCreateNestedManyWithoutTransferInput = {
  connect?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferPackagingItemCreateOrConnectWithoutTransferInput>>;
  create?: InputMaybe<Array<TransferPackagingItemCreateWithoutTransferInput>>;
  createMany?: InputMaybe<TransferPackagingItemCreateManyTransferInputEnvelope>;
};

export type TransferPackagingItemCreateNestedOneWithoutReceivedTransferPackagingItemsInput = {
  connect?: InputMaybe<TransferPackagingItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferPackagingItemCreateOrConnectWithoutReceivedTransferPackagingItemsInput>;
  create?: InputMaybe<TransferPackagingItemCreateWithoutReceivedTransferPackagingItemsInput>;
};

export type TransferPackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: TransferPackagingItemCreateWithoutPackagingProductInput;
  where: TransferPackagingItemWhereUniqueInput;
};

export type TransferPackagingItemCreateOrConnectWithoutReceivedTransferPackagingItemsInput = {
  create: TransferPackagingItemCreateWithoutReceivedTransferPackagingItemsInput;
  where: TransferPackagingItemWhereUniqueInput;
};

export type TransferPackagingItemCreateOrConnectWithoutTransferInput = {
  create: TransferPackagingItemCreateWithoutTransferInput;
  where: TransferPackagingItemWhereUniqueInput;
};

export type TransferPackagingItemCreateWithoutPackagingProductInput = {
  quantity: Scalars['Int']['input'];
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemCreateNestedOneWithoutTransferPackagingItemInput>;
  transfer: TransferCreateNestedOneWithoutTransferPackagingItemsInput;
};

export type TransferPackagingItemCreateWithoutReceivedTransferPackagingItemsInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutTransferPackagingItemsInput;
  quantity: Scalars['Int']['input'];
  transfer: TransferCreateNestedOneWithoutTransferPackagingItemsInput;
};

export type TransferPackagingItemCreateWithoutTransferInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutTransferPackagingItemsInput;
  quantity: Scalars['Int']['input'];
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemCreateNestedOneWithoutTransferPackagingItemInput>;
};

export type TransferPackagingItemListRelationFilter = {
  every?: InputMaybe<TransferPackagingItemWhereInput>;
  none?: InputMaybe<TransferPackagingItemWhereInput>;
  some?: InputMaybe<TransferPackagingItemWhereInput>;
};

export type TransferPackagingItemMaxAggregate = {
  __typename?: 'TransferPackagingItemMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferPackagingItemMinAggregate = {
  __typename?: 'TransferPackagingItemMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransferPackagingItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemOrderByWithRelationInput>;
  transfer?: InputMaybe<TransferOrderByWithRelationInput>;
  transferId?: InputMaybe<SortOrder>;
};

export type TransferPackagingItemRelationFilter = {
  is?: InputMaybe<TransferPackagingItemWhereInput>;
  isNot?: InputMaybe<TransferPackagingItemWhereInput>;
};

export enum TransferPackagingItemScalarFieldEnum {
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity',
  TransferId = 'transferId'
}

export type TransferPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<TransferPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransferPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<TransferPackagingItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  transferId?: InputMaybe<IntFilter>;
};

export type TransferPackagingItemSumAggregate = {
  __typename?: 'TransferPackagingItemSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  packagingProductId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferPackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TransferPackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: TransferPackagingItemUpdateManyMutationInput;
  where: TransferPackagingItemScalarWhereInput;
};

export type TransferPackagingItemUpdateManyWithWhereWithoutTransferInput = {
  data: TransferPackagingItemUpdateManyMutationInput;
  where: TransferPackagingItemScalarWhereInput;
};

export type TransferPackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<TransferPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<TransferPackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<TransferPackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<TransferPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type TransferPackagingItemUpdateManyWithoutTransferNestedInput = {
  connect?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferPackagingItemCreateOrConnectWithoutTransferInput>>;
  create?: InputMaybe<Array<TransferPackagingItemCreateWithoutTransferInput>>;
  createMany?: InputMaybe<TransferPackagingItemCreateManyTransferInputEnvelope>;
  delete?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferPackagingItemUpdateWithWhereUniqueWithoutTransferInput>>;
  updateMany?: InputMaybe<Array<TransferPackagingItemUpdateManyWithWhereWithoutTransferInput>>;
  upsert?: InputMaybe<Array<TransferPackagingItemUpsertWithWhereUniqueWithoutTransferInput>>;
};

export type TransferPackagingItemUpdateOneRequiredWithoutReceivedTransferPackagingItemsNestedInput = {
  connect?: InputMaybe<TransferPackagingItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferPackagingItemCreateOrConnectWithoutReceivedTransferPackagingItemsInput>;
  create?: InputMaybe<TransferPackagingItemCreateWithoutReceivedTransferPackagingItemsInput>;
  update?: InputMaybe<TransferPackagingItemUpdateWithoutReceivedTransferPackagingItemsInput>;
  upsert?: InputMaybe<TransferPackagingItemUpsertWithoutReceivedTransferPackagingItemsInput>;
};

export type TransferPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: TransferPackagingItemUpdateWithoutPackagingProductInput;
  where: TransferPackagingItemWhereUniqueInput;
};

export type TransferPackagingItemUpdateWithWhereUniqueWithoutTransferInput = {
  data: TransferPackagingItemUpdateWithoutTransferInput;
  where: TransferPackagingItemWhereUniqueInput;
};

export type TransferPackagingItemUpdateWithoutPackagingProductInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemUpdateOneWithoutTransferPackagingItemNestedInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutTransferPackagingItemsNestedInput>;
};

export type TransferPackagingItemUpdateWithoutReceivedTransferPackagingItemsInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutTransferPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutTransferPackagingItemsNestedInput>;
};

export type TransferPackagingItemUpdateWithoutTransferInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutTransferPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemUpdateOneWithoutTransferPackagingItemNestedInput>;
};

export type TransferPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: TransferPackagingItemCreateWithoutPackagingProductInput;
  update: TransferPackagingItemUpdateWithoutPackagingProductInput;
  where: TransferPackagingItemWhereUniqueInput;
};

export type TransferPackagingItemUpsertWithWhereUniqueWithoutTransferInput = {
  create: TransferPackagingItemCreateWithoutTransferInput;
  update: TransferPackagingItemUpdateWithoutTransferInput;
  where: TransferPackagingItemWhereUniqueInput;
};

export type TransferPackagingItemUpsertWithoutReceivedTransferPackagingItemsInput = {
  create: TransferPackagingItemCreateWithoutReceivedTransferPackagingItemsInput;
  update: TransferPackagingItemUpdateWithoutReceivedTransferPackagingItemsInput;
};

export type TransferPackagingItemWhereInput = {
  AND?: InputMaybe<Array<TransferPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<TransferPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<TransferPackagingItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  receivedTransferPackagingItems?: InputMaybe<ReceivedTransferPackagingItemRelationFilter>;
  transfer?: InputMaybe<TransferRelationFilter>;
  transferId?: InputMaybe<IntFilter>;
};

export type TransferPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TransferRawMaterialItem = {
  __typename?: 'TransferRawMaterialItem';
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  receivedTransferRawMaterialItems?: Maybe<ReceivedTransferRawMaterialItem>;
  transfer: Transfer;
  transferId: Scalars['Int']['output'];
};

export type TransferRawMaterialItemAvgAggregate = {
  __typename?: 'TransferRawMaterialItemAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Float']['output']>;
  transferId?: Maybe<Scalars['Float']['output']>;
};

export type TransferRawMaterialItemCountAggregate = {
  __typename?: 'TransferRawMaterialItemCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  rawMaterialProductId: Scalars['Int']['output'];
  transferId: Scalars['Int']['output'];
};

export type TransferRawMaterialItemCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  transferId: Scalars['Int']['input'];
};

export type TransferRawMaterialItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<TransferRawMaterialItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferRawMaterialItemCreateManyTransferInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type TransferRawMaterialItemCreateManyTransferInputEnvelope = {
  data: Array<TransferRawMaterialItemCreateManyTransferInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<TransferRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<TransferRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
};

export type TransferRawMaterialItemCreateNestedManyWithoutTransferInput = {
  connect?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferRawMaterialItemCreateOrConnectWithoutTransferInput>>;
  create?: InputMaybe<Array<TransferRawMaterialItemCreateWithoutTransferInput>>;
  createMany?: InputMaybe<TransferRawMaterialItemCreateManyTransferInputEnvelope>;
};

export type TransferRawMaterialItemCreateNestedOneWithoutReceivedTransferRawMaterialItemsInput = {
  connect?: InputMaybe<TransferRawMaterialItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferRawMaterialItemCreateOrConnectWithoutReceivedTransferRawMaterialItemsInput>;
  create?: InputMaybe<TransferRawMaterialItemCreateWithoutReceivedTransferRawMaterialItemsInput>;
};

export type TransferRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: TransferRawMaterialItemCreateWithoutRawMaterialProductInput;
  where: TransferRawMaterialItemWhereUniqueInput;
};

export type TransferRawMaterialItemCreateOrConnectWithoutReceivedTransferRawMaterialItemsInput = {
  create: TransferRawMaterialItemCreateWithoutReceivedTransferRawMaterialItemsInput;
  where: TransferRawMaterialItemWhereUniqueInput;
};

export type TransferRawMaterialItemCreateOrConnectWithoutTransferInput = {
  create: TransferRawMaterialItemCreateWithoutTransferInput;
  where: TransferRawMaterialItemWhereUniqueInput;
};

export type TransferRawMaterialItemCreateWithoutRawMaterialProductInput = {
  quantity: Scalars['Float']['input'];
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemCreateNestedOneWithoutTransferRawMaterialItemInput>;
  transfer: TransferCreateNestedOneWithoutTransferRawMaterialItemsInput;
};

export type TransferRawMaterialItemCreateWithoutReceivedTransferRawMaterialItemsInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutTransferRawMaterialItemsInput;
  transfer: TransferCreateNestedOneWithoutTransferRawMaterialItemsInput;
};

export type TransferRawMaterialItemCreateWithoutTransferInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutTransferRawMaterialItemsInput;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemCreateNestedOneWithoutTransferRawMaterialItemInput>;
};

export type TransferRawMaterialItemListRelationFilter = {
  every?: InputMaybe<TransferRawMaterialItemWhereInput>;
  none?: InputMaybe<TransferRawMaterialItemWhereInput>;
  some?: InputMaybe<TransferRawMaterialItemWhereInput>;
};

export type TransferRawMaterialItemMaxAggregate = {
  __typename?: 'TransferRawMaterialItemMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferRawMaterialItemMinAggregate = {
  __typename?: 'TransferRawMaterialItemMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransferRawMaterialItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemOrderByWithRelationInput>;
  transfer?: InputMaybe<TransferOrderByWithRelationInput>;
  transferId?: InputMaybe<SortOrder>;
};

export type TransferRawMaterialItemRelationFilter = {
  is?: InputMaybe<TransferRawMaterialItemWhereInput>;
  isNot?: InputMaybe<TransferRawMaterialItemWhereInput>;
};

export enum TransferRawMaterialItemScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId',
  TransferId = 'transferId'
}

export type TransferRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<TransferRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransferRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<TransferRawMaterialItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  transferId?: InputMaybe<IntFilter>;
};

export type TransferRawMaterialItemSumAggregate = {
  __typename?: 'TransferRawMaterialItemSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterialProductId?: Maybe<Scalars['Int']['output']>;
  transferId?: Maybe<Scalars['Int']['output']>;
};

export type TransferRawMaterialItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type TransferRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: TransferRawMaterialItemUpdateManyMutationInput;
  where: TransferRawMaterialItemScalarWhereInput;
};

export type TransferRawMaterialItemUpdateManyWithWhereWithoutTransferInput = {
  data: TransferRawMaterialItemUpdateManyMutationInput;
  where: TransferRawMaterialItemScalarWhereInput;
};

export type TransferRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<TransferRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<TransferRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<TransferRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<TransferRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type TransferRawMaterialItemUpdateManyWithoutTransferNestedInput = {
  connect?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferRawMaterialItemCreateOrConnectWithoutTransferInput>>;
  create?: InputMaybe<Array<TransferRawMaterialItemCreateWithoutTransferInput>>;
  createMany?: InputMaybe<TransferRawMaterialItemCreateManyTransferInputEnvelope>;
  delete?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferRawMaterialItemUpdateWithWhereUniqueWithoutTransferInput>>;
  updateMany?: InputMaybe<Array<TransferRawMaterialItemUpdateManyWithWhereWithoutTransferInput>>;
  upsert?: InputMaybe<Array<TransferRawMaterialItemUpsertWithWhereUniqueWithoutTransferInput>>;
};

export type TransferRawMaterialItemUpdateOneRequiredWithoutReceivedTransferRawMaterialItemsNestedInput = {
  connect?: InputMaybe<TransferRawMaterialItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferRawMaterialItemCreateOrConnectWithoutReceivedTransferRawMaterialItemsInput>;
  create?: InputMaybe<TransferRawMaterialItemCreateWithoutReceivedTransferRawMaterialItemsInput>;
  update?: InputMaybe<TransferRawMaterialItemUpdateWithoutReceivedTransferRawMaterialItemsInput>;
  upsert?: InputMaybe<TransferRawMaterialItemUpsertWithoutReceivedTransferRawMaterialItemsInput>;
};

export type TransferRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: TransferRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: TransferRawMaterialItemWhereUniqueInput;
};

export type TransferRawMaterialItemUpdateWithWhereUniqueWithoutTransferInput = {
  data: TransferRawMaterialItemUpdateWithoutTransferInput;
  where: TransferRawMaterialItemWhereUniqueInput;
};

export type TransferRawMaterialItemUpdateWithoutRawMaterialProductInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemUpdateOneWithoutTransferRawMaterialItemNestedInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutTransferRawMaterialItemsNestedInput>;
};

export type TransferRawMaterialItemUpdateWithoutReceivedTransferRawMaterialItemsInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutTransferRawMaterialItemsNestedInput>;
  transfer?: InputMaybe<TransferUpdateOneRequiredWithoutTransferRawMaterialItemsNestedInput>;
};

export type TransferRawMaterialItemUpdateWithoutTransferInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutTransferRawMaterialItemsNestedInput>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemUpdateOneWithoutTransferRawMaterialItemNestedInput>;
};

export type TransferRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: TransferRawMaterialItemCreateWithoutRawMaterialProductInput;
  update: TransferRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: TransferRawMaterialItemWhereUniqueInput;
};

export type TransferRawMaterialItemUpsertWithWhereUniqueWithoutTransferInput = {
  create: TransferRawMaterialItemCreateWithoutTransferInput;
  update: TransferRawMaterialItemUpdateWithoutTransferInput;
  where: TransferRawMaterialItemWhereUniqueInput;
};

export type TransferRawMaterialItemUpsertWithoutReceivedTransferRawMaterialItemsInput = {
  create: TransferRawMaterialItemCreateWithoutReceivedTransferRawMaterialItemsInput;
  update: TransferRawMaterialItemUpdateWithoutReceivedTransferRawMaterialItemsInput;
};

export type TransferRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<TransferRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<TransferRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<TransferRawMaterialItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  receivedTransferRawMaterialItems?: InputMaybe<ReceivedTransferRawMaterialItemRelationFilter>;
  transfer?: InputMaybe<TransferRelationFilter>;
  transferId?: InputMaybe<IntFilter>;
};

export type TransferRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TransferRelationFilter = {
  is?: InputMaybe<TransferWhereInput>;
  isNot?: InputMaybe<TransferWhereInput>;
};

export enum TransferScalarFieldEnum {
  ControlNumber = 'controlNumber',
  CreatedBy = 'createdBy',
  Date = 'date',
  DeliveryShiftSchedule = 'deliveryShiftSchedule',
  FromOutletId = 'fromOutletId',
  Id = 'id',
  ReceivedByDriver = 'receivedByDriver',
  ToOutletId = 'toOutletId'
}

export type TransferScalarWhereInput = {
  AND?: InputMaybe<Array<TransferScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransferScalarWhereInput>>;
  OR?: InputMaybe<Array<TransferScalarWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  fromOutletId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  receivedByDriver?: InputMaybe<StringNullableFilter>;
  toOutletId?: InputMaybe<IntFilter>;
};

export type TransferSumAggregate = {
  __typename?: 'TransferSumAggregate';
  fromOutletId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  toOutletId?: Maybe<Scalars['Int']['output']>;
};

export type TransferUpdateInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  fromOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferFromOutletsNestedInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneWithoutTransferNestedInput>;
  toOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferToOutletsNestedInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutTransferNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutTransferNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutTransferNestedInput>;
};

export type TransferUpdateManyMutationInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TransferUpdateManyWithWhereWithoutFromOutletInput = {
  data: TransferUpdateManyMutationInput;
  where: TransferScalarWhereInput;
};

export type TransferUpdateManyWithWhereWithoutToOutletInput = {
  data: TransferUpdateManyMutationInput;
  where: TransferScalarWhereInput;
};

export type TransferUpdateManyWithoutFromOutletNestedInput = {
  connect?: InputMaybe<Array<TransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferCreateOrConnectWithoutFromOutletInput>>;
  create?: InputMaybe<Array<TransferCreateWithoutFromOutletInput>>;
  createMany?: InputMaybe<TransferCreateManyFromOutletInputEnvelope>;
  delete?: InputMaybe<Array<TransferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferUpdateWithWhereUniqueWithoutFromOutletInput>>;
  updateMany?: InputMaybe<Array<TransferUpdateManyWithWhereWithoutFromOutletInput>>;
  upsert?: InputMaybe<Array<TransferUpsertWithWhereUniqueWithoutFromOutletInput>>;
};

export type TransferUpdateManyWithoutToOutletNestedInput = {
  connect?: InputMaybe<Array<TransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferCreateOrConnectWithoutToOutletInput>>;
  create?: InputMaybe<Array<TransferCreateWithoutToOutletInput>>;
  createMany?: InputMaybe<TransferCreateManyToOutletInputEnvelope>;
  delete?: InputMaybe<Array<TransferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferUpdateWithWhereUniqueWithoutToOutletInput>>;
  updateMany?: InputMaybe<Array<TransferUpdateManyWithWhereWithoutToOutletInput>>;
  upsert?: InputMaybe<Array<TransferUpsertWithWhereUniqueWithoutToOutletInput>>;
};

export type TransferUpdateOneRequiredWithoutReceivedTransferNestedInput = {
  connect?: InputMaybe<TransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCreateOrConnectWithoutReceivedTransferInput>;
  create?: InputMaybe<TransferCreateWithoutReceivedTransferInput>;
  update?: InputMaybe<TransferUpdateWithoutReceivedTransferInput>;
  upsert?: InputMaybe<TransferUpsertWithoutReceivedTransferInput>;
};

export type TransferUpdateOneRequiredWithoutTransferCmlItemsNestedInput = {
  connect?: InputMaybe<TransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCreateOrConnectWithoutTransferCmlItemsInput>;
  create?: InputMaybe<TransferCreateWithoutTransferCmlItemsInput>;
  update?: InputMaybe<TransferUpdateWithoutTransferCmlItemsInput>;
  upsert?: InputMaybe<TransferUpsertWithoutTransferCmlItemsInput>;
};

export type TransferUpdateOneRequiredWithoutTransferPackagingItemsNestedInput = {
  connect?: InputMaybe<TransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCreateOrConnectWithoutTransferPackagingItemsInput>;
  create?: InputMaybe<TransferCreateWithoutTransferPackagingItemsInput>;
  update?: InputMaybe<TransferUpdateWithoutTransferPackagingItemsInput>;
  upsert?: InputMaybe<TransferUpsertWithoutTransferPackagingItemsInput>;
};

export type TransferUpdateOneRequiredWithoutTransferRawMaterialItemsNestedInput = {
  connect?: InputMaybe<TransferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferCreateOrConnectWithoutTransferRawMaterialItemsInput>;
  create?: InputMaybe<TransferCreateWithoutTransferRawMaterialItemsInput>;
  update?: InputMaybe<TransferUpdateWithoutTransferRawMaterialItemsInput>;
  upsert?: InputMaybe<TransferUpsertWithoutTransferRawMaterialItemsInput>;
};

export type TransferUpdateWithWhereUniqueWithoutFromOutletInput = {
  data: TransferUpdateWithoutFromOutletInput;
  where: TransferWhereUniqueInput;
};

export type TransferUpdateWithWhereUniqueWithoutToOutletInput = {
  data: TransferUpdateWithoutToOutletInput;
  where: TransferWhereUniqueInput;
};

export type TransferUpdateWithoutFromOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneWithoutTransferNestedInput>;
  toOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferToOutletsNestedInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutTransferNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutTransferNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutTransferNestedInput>;
};

export type TransferUpdateWithoutReceivedTransferInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  fromOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferFromOutletsNestedInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  toOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferToOutletsNestedInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutTransferNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutTransferNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutTransferNestedInput>;
};

export type TransferUpdateWithoutToOutletInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  fromOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferFromOutletsNestedInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneWithoutTransferNestedInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutTransferNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutTransferNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutTransferNestedInput>;
};

export type TransferUpdateWithoutTransferCmlItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  fromOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferFromOutletsNestedInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneWithoutTransferNestedInput>;
  toOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferToOutletsNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutTransferNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutTransferNestedInput>;
};

export type TransferUpdateWithoutTransferPackagingItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  fromOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferFromOutletsNestedInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneWithoutTransferNestedInput>;
  toOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferToOutletsNestedInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutTransferNestedInput>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemUpdateManyWithoutTransferNestedInput>;
};

export type TransferUpdateWithoutTransferRawMaterialItemsInput = {
  controlNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFieldUpdateOperationsInput>;
  fromOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferFromOutletsNestedInput>;
  receivedByDriver?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receivedTransfer?: InputMaybe<ReceivedTransferUpdateOneWithoutTransferNestedInput>;
  toOutlet?: InputMaybe<OutletUpdateOneRequiredWithoutTransferToOutletsNestedInput>;
  transferCmlItems?: InputMaybe<TransferCmlItemUpdateManyWithoutTransferNestedInput>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemUpdateManyWithoutTransferNestedInput>;
};

export type TransferUpsertWithWhereUniqueWithoutFromOutletInput = {
  create: TransferCreateWithoutFromOutletInput;
  update: TransferUpdateWithoutFromOutletInput;
  where: TransferWhereUniqueInput;
};

export type TransferUpsertWithWhereUniqueWithoutToOutletInput = {
  create: TransferCreateWithoutToOutletInput;
  update: TransferUpdateWithoutToOutletInput;
  where: TransferWhereUniqueInput;
};

export type TransferUpsertWithoutReceivedTransferInput = {
  create: TransferCreateWithoutReceivedTransferInput;
  update: TransferUpdateWithoutReceivedTransferInput;
};

export type TransferUpsertWithoutTransferCmlItemsInput = {
  create: TransferCreateWithoutTransferCmlItemsInput;
  update: TransferUpdateWithoutTransferCmlItemsInput;
};

export type TransferUpsertWithoutTransferPackagingItemsInput = {
  create: TransferCreateWithoutTransferPackagingItemsInput;
  update: TransferUpdateWithoutTransferPackagingItemsInput;
};

export type TransferUpsertWithoutTransferRawMaterialItemsInput = {
  create: TransferCreateWithoutTransferRawMaterialItemsInput;
  update: TransferUpdateWithoutTransferRawMaterialItemsInput;
};

export type TransferWhereInput = {
  AND?: InputMaybe<Array<TransferWhereInput>>;
  NOT?: InputMaybe<Array<TransferWhereInput>>;
  OR?: InputMaybe<Array<TransferWhereInput>>;
  controlNumber?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  deliveryShiftSchedule?: InputMaybe<EnumDeliveryShiftScheduleFilter>;
  fromOutlet?: InputMaybe<OutletRelationFilter>;
  fromOutletId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  receivedByDriver?: InputMaybe<StringNullableFilter>;
  receivedTransfer?: InputMaybe<ReceivedTransferRelationFilter>;
  toOutlet?: InputMaybe<OutletRelationFilter>;
  toOutletId?: InputMaybe<IntFilter>;
  transferCmlItems?: InputMaybe<TransferCmlItemListRelationFilter>;
  transferPackagingItems?: InputMaybe<TransferPackagingItemListRelationFilter>;
  transferRawMaterialItems?: InputMaybe<TransferRawMaterialItemListRelationFilter>;
};

export type TransferWhereUniqueInput = {
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Unit = {
  __typename?: 'Unit';
  _count?: Maybe<UnitCount>;
  beverages: Array<Beverage>;
  cmlProducts: Array<CmlProduct>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  packagingProducts: Array<PackagingProduct>;
  rawMaterialProducts: Array<RawMaterialProduct>;
};


export type UnitBeveragesArgs = {
  cursor?: InputMaybe<BeverageWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeverageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeverageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BeverageWhereInput>;
};


export type UnitCmlProductsArgs = {
  cursor?: InputMaybe<CmlProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<CmlProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CmlProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductWhereInput>;
};


export type UnitPackagingProductsArgs = {
  cursor?: InputMaybe<PackagingProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackagingProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackagingProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingProductWhereInput>;
};


export type UnitRawMaterialProductsArgs = {
  cursor?: InputMaybe<RawMaterialProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<RawMaterialProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RawMaterialProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductWhereInput>;
};

export type UnitAvgAggregate = {
  __typename?: 'UnitAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type UnitCount = {
  __typename?: 'UnitCount';
  beverages: Scalars['Int']['output'];
  cmlProducts: Scalars['Int']['output'];
  packagingProducts: Scalars['Int']['output'];
  rawMaterialProducts: Scalars['Int']['output'];
};

export type UnitCountAggregate = {
  __typename?: 'UnitCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
};

export type UnitCreateInput = {
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutUnitInput>;
  cmlProducts?: InputMaybe<CmlProductCreateNestedManyWithoutUnitInput>;
  name: Scalars['String']['input'];
  packagingProducts?: InputMaybe<PackagingProductCreateNestedManyWithoutUnitInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductCreateNestedManyWithoutUnitInput>;
};

export type UnitCreateNestedOneWithoutBeveragesInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutBeveragesInput>;
  create?: InputMaybe<UnitCreateWithoutBeveragesInput>;
};

export type UnitCreateNestedOneWithoutCmlProductsInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutCmlProductsInput>;
  create?: InputMaybe<UnitCreateWithoutCmlProductsInput>;
};

export type UnitCreateNestedOneWithoutPackagingProductsInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutPackagingProductsInput>;
  create?: InputMaybe<UnitCreateWithoutPackagingProductsInput>;
};

export type UnitCreateNestedOneWithoutRawMaterialProductsInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutRawMaterialProductsInput>;
  create?: InputMaybe<UnitCreateWithoutRawMaterialProductsInput>;
};

export type UnitCreateOrConnectWithoutBeveragesInput = {
  create: UnitCreateWithoutBeveragesInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutCmlProductsInput = {
  create: UnitCreateWithoutCmlProductsInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutPackagingProductsInput = {
  create: UnitCreateWithoutPackagingProductsInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutRawMaterialProductsInput = {
  create: UnitCreateWithoutRawMaterialProductsInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateWithoutBeveragesInput = {
  cmlProducts?: InputMaybe<CmlProductCreateNestedManyWithoutUnitInput>;
  name: Scalars['String']['input'];
  packagingProducts?: InputMaybe<PackagingProductCreateNestedManyWithoutUnitInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductCreateNestedManyWithoutUnitInput>;
};

export type UnitCreateWithoutCmlProductsInput = {
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutUnitInput>;
  name: Scalars['String']['input'];
  packagingProducts?: InputMaybe<PackagingProductCreateNestedManyWithoutUnitInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductCreateNestedManyWithoutUnitInput>;
};

export type UnitCreateWithoutPackagingProductsInput = {
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutUnitInput>;
  cmlProducts?: InputMaybe<CmlProductCreateNestedManyWithoutUnitInput>;
  name: Scalars['String']['input'];
  rawMaterialProducts?: InputMaybe<RawMaterialProductCreateNestedManyWithoutUnitInput>;
};

export type UnitCreateWithoutRawMaterialProductsInput = {
  beverages?: InputMaybe<BeverageCreateNestedManyWithoutUnitInput>;
  cmlProducts?: InputMaybe<CmlProductCreateNestedManyWithoutUnitInput>;
  name: Scalars['String']['input'];
  packagingProducts?: InputMaybe<PackagingProductCreateNestedManyWithoutUnitInput>;
};

export type UnitMaxAggregate = {
  __typename?: 'UnitMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UnitMinAggregate = {
  __typename?: 'UnitMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UnitOrderByWithRelationInput = {
  beverages?: InputMaybe<BeverageOrderByRelationAggregateInput>;
  cmlProducts?: InputMaybe<CmlProductOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  packagingProducts?: InputMaybe<PackagingProductOrderByRelationAggregateInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductOrderByRelationAggregateInput>;
};

export type UnitRelationFilter = {
  is?: InputMaybe<UnitWhereInput>;
  isNot?: InputMaybe<UnitWhereInput>;
};

export enum UnitScalarFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type UnitSumAggregate = {
  __typename?: 'UnitSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type UnitUpdateInput = {
  beverages?: InputMaybe<BeverageUpdateManyWithoutUnitNestedInput>;
  cmlProducts?: InputMaybe<CmlProductUpdateManyWithoutUnitNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packagingProducts?: InputMaybe<PackagingProductUpdateManyWithoutUnitNestedInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductUpdateManyWithoutUnitNestedInput>;
};

export type UnitUpdateOneRequiredWithoutBeveragesNestedInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutBeveragesInput>;
  create?: InputMaybe<UnitCreateWithoutBeveragesInput>;
  update?: InputMaybe<UnitUpdateWithoutBeveragesInput>;
  upsert?: InputMaybe<UnitUpsertWithoutBeveragesInput>;
};

export type UnitUpdateOneRequiredWithoutCmlProductsNestedInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutCmlProductsInput>;
  create?: InputMaybe<UnitCreateWithoutCmlProductsInput>;
  update?: InputMaybe<UnitUpdateWithoutCmlProductsInput>;
  upsert?: InputMaybe<UnitUpsertWithoutCmlProductsInput>;
};

export type UnitUpdateOneRequiredWithoutPackagingProductsNestedInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutPackagingProductsInput>;
  create?: InputMaybe<UnitCreateWithoutPackagingProductsInput>;
  update?: InputMaybe<UnitUpdateWithoutPackagingProductsInput>;
  upsert?: InputMaybe<UnitUpsertWithoutPackagingProductsInput>;
};

export type UnitUpdateOneRequiredWithoutRawMaterialProductsNestedInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutRawMaterialProductsInput>;
  create?: InputMaybe<UnitCreateWithoutRawMaterialProductsInput>;
  update?: InputMaybe<UnitUpdateWithoutRawMaterialProductsInput>;
  upsert?: InputMaybe<UnitUpsertWithoutRawMaterialProductsInput>;
};

export type UnitUpdateWithoutBeveragesInput = {
  cmlProducts?: InputMaybe<CmlProductUpdateManyWithoutUnitNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packagingProducts?: InputMaybe<PackagingProductUpdateManyWithoutUnitNestedInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductUpdateManyWithoutUnitNestedInput>;
};

export type UnitUpdateWithoutCmlProductsInput = {
  beverages?: InputMaybe<BeverageUpdateManyWithoutUnitNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packagingProducts?: InputMaybe<PackagingProductUpdateManyWithoutUnitNestedInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductUpdateManyWithoutUnitNestedInput>;
};

export type UnitUpdateWithoutPackagingProductsInput = {
  beverages?: InputMaybe<BeverageUpdateManyWithoutUnitNestedInput>;
  cmlProducts?: InputMaybe<CmlProductUpdateManyWithoutUnitNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductUpdateManyWithoutUnitNestedInput>;
};

export type UnitUpdateWithoutRawMaterialProductsInput = {
  beverages?: InputMaybe<BeverageUpdateManyWithoutUnitNestedInput>;
  cmlProducts?: InputMaybe<CmlProductUpdateManyWithoutUnitNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packagingProducts?: InputMaybe<PackagingProductUpdateManyWithoutUnitNestedInput>;
};

export type UnitUpsertWithoutBeveragesInput = {
  create: UnitCreateWithoutBeveragesInput;
  update: UnitUpdateWithoutBeveragesInput;
};

export type UnitUpsertWithoutCmlProductsInput = {
  create: UnitCreateWithoutCmlProductsInput;
  update: UnitUpdateWithoutCmlProductsInput;
};

export type UnitUpsertWithoutPackagingProductsInput = {
  create: UnitCreateWithoutPackagingProductsInput;
  update: UnitUpdateWithoutPackagingProductsInput;
};

export type UnitUpsertWithoutRawMaterialProductsInput = {
  create: UnitCreateWithoutRawMaterialProductsInput;
  update: UnitUpdateWithoutRawMaterialProductsInput;
};

export type UnitWhereInput = {
  AND?: InputMaybe<Array<UnitWhereInput>>;
  NOT?: InputMaybe<Array<UnitWhereInput>>;
  OR?: InputMaybe<Array<UnitWhereInput>>;
  beverages?: InputMaybe<BeverageListRelationFilter>;
  cmlProducts?: InputMaybe<CmlProductListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  packagingProducts?: InputMaybe<PackagingProductListRelationFilter>;
  rawMaterialProducts?: InputMaybe<RawMaterialProductListRelationFilter>;
};

export type UnitWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  outlets: Array<Outlet>;
  password: Scalars['String']['output'];
  userGroups: Array<UserGroup>;
  username: Scalars['String']['output'];
};


export type UserOutletsArgs = {
  cursor?: InputMaybe<OutletWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutletScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutletOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type UserCount = {
  __typename?: 'UserCount';
  outlets: Scalars['Int']['output'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  password: Scalars['Int']['output'];
  userGroups: Scalars['Int']['output'];
  username: Scalars['Int']['output'];
};

export type UserCreateInput = {
  name: Scalars['String']['input'];
  outlets?: InputMaybe<OutletCreateNestedManyWithoutUsersInput>;
  password: Scalars['String']['input'];
  userGroups?: InputMaybe<UserCreateuserGroupsInput>;
  username: Scalars['String']['input'];
};

export type UserCreateNestedManyWithoutOutletsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOutletsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOutletsInput>>;
};

export type UserCreateOrConnectWithoutOutletsInput = {
  create: UserCreateWithoutOutletsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutOutletsInput = {
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userGroups?: InputMaybe<UserCreateuserGroupsInput>;
  username: Scalars['String']['input'];
};

export type UserCreateuserGroupsInput = {
  set: Array<UserGroup>;
};

export { UserGroup };

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outlets?: InputMaybe<OutletOrderByRelationAggregateInput>;
  password?: InputMaybe<SortOrder>;
  userGroups?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export enum UserScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Password = 'password',
  UserGroups = 'userGroups',
  Username = 'username'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  userGroups?: InputMaybe<EnumUserGroupNullableListFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type UserUpdateInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outlets?: InputMaybe<OutletUpdateManyWithoutUsersNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  userGroups?: InputMaybe<UserUpdateuserGroupsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  userGroups?: InputMaybe<UserUpdateuserGroupsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutOutletsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutOutletsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOutletsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOutletsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutOutletsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutOutletsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutOutletsInput>>;
};

export type UserUpdateWithWhereUniqueWithoutOutletsInput = {
  data: UserUpdateWithoutOutletsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutOutletsInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  userGroups?: InputMaybe<UserUpdateuserGroupsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateuserGroupsInput = {
  push?: InputMaybe<Array<UserGroup>>;
  set?: InputMaybe<Array<UserGroup>>;
};

export type UserUpsertWithWhereUniqueWithoutOutletsInput = {
  create: UserCreateWithoutOutletsInput;
  update: UserUpdateWithoutOutletsInput;
  where: UserWhereUniqueInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  outlets?: InputMaybe<OutletListRelationFilter>;
  password?: InputMaybe<StringFilter>;
  userGroups?: InputMaybe<EnumUserGroupNullableListFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type VarianceReportOnCmlTransfer = {
  __typename?: 'VarianceReportOnCmlTransfer';
  cmlProduct?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<Scalars['String']['output']>;
  donutMixAndCut?: Maybe<Scalars['String']['output']>;
  fromOutlet?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  receivedQuantity?: Maybe<Scalars['Float']['output']>;
  toOutlet?: Maybe<Scalars['String']['output']>;
  transferNumber?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type VarianceReportOnCmlTransferWhereInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VarianceReportOnDeliveryReceiptItem = {
  __typename?: 'VarianceReportOnDeliveryReceiptItem';
  cmlProduct?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryReceiptNumber?: Maybe<Scalars['String']['output']>;
  deliveryShiftSchedule?: Maybe<Scalars['String']['output']>;
  donutMixAndCut?: Maybe<Scalars['String']['output']>;
  outlet?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  received?: Maybe<Scalars['Float']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  rejected?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type VarianceReportOnDeliveryReceiptItemWhereInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VarianceReportOnPackagingTransfer = {
  __typename?: 'VarianceReportOnPackagingTransfer';
  category?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<Scalars['String']['output']>;
  fromOutlet?: Maybe<Scalars['String']['output']>;
  packaging?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  receivedQuantity?: Maybe<Scalars['Float']['output']>;
  toOutlet?: Maybe<Scalars['String']['output']>;
  transferNumber?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type VarianceReportOnPackagingTransferWhereInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VarianceReportOnRawMaterialTransfer = {
  __typename?: 'VarianceReportOnRawMaterialTransfer';
  category?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryShiftSchedule?: Maybe<Scalars['String']['output']>;
  fromOutlet?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterial?: Maybe<Scalars['String']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  receivedQuantity?: Maybe<Scalars['Float']['output']>;
  toOutlet?: Maybe<Scalars['String']['output']>;
  transferNumber?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type VarianceReportOnRawMaterialTransferWhereInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VarianceReportOnSdsPackagingItem = {
  __typename?: 'VarianceReportOnSdsPackagingItem';
  category?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryReceiptNumber?: Maybe<Scalars['String']['output']>;
  deliveryShiftSchedule?: Maybe<Scalars['String']['output']>;
  outlet?: Maybe<Scalars['String']['output']>;
  packaging?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  received?: Maybe<Scalars['Float']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  rejected?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type VarianceReportOnSdsPackagingItemWhereInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VarianceReportOnSdsRawMaterialItem = {
  __typename?: 'VarianceReportOnSdsRawMaterialItem';
  category?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryReceiptNumber?: Maybe<Scalars['String']['output']>;
  deliveryShiftSchedule?: Maybe<Scalars['String']['output']>;
  outlet?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rawMaterial?: Maybe<Scalars['String']['output']>;
  received?: Maybe<Scalars['Float']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  rejected?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type VarianceReportOnSdsRawMaterialItemWhereInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WarehouseInventory = {
  __typename?: 'WarehouseInventory';
  _count?: Maybe<WarehouseInventoryCount>;
  createdBy: Scalars['String']['output'];
  dateTime: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
  warehouseInventoryPackagingItems: Array<WarehouseInventoryPackagingItem>;
  warehouseInventoryRawMaterialItems: Array<WarehouseInventoryRawMaterialItem>;
};


export type WarehouseInventoryWarehouseInventoryPackagingItemsArgs = {
  cursor?: InputMaybe<WarehouseInventoryPackagingItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<WarehouseInventoryPackagingItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WarehouseInventoryPackagingItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WarehouseInventoryPackagingItemWhereInput>;
};


export type WarehouseInventoryWarehouseInventoryRawMaterialItemsArgs = {
  cursor?: InputMaybe<WarehouseInventoryRawMaterialItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<WarehouseInventoryRawMaterialItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WarehouseInventoryRawMaterialItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WarehouseInventoryRawMaterialItemWhereInput>;
};

export type WarehouseInventoryAvgAggregate = {
  __typename?: 'WarehouseInventoryAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type WarehouseInventoryCount = {
  __typename?: 'WarehouseInventoryCount';
  warehouseInventoryPackagingItems: Scalars['Int']['output'];
  warehouseInventoryRawMaterialItems: Scalars['Int']['output'];
};

export type WarehouseInventoryCountAggregate = {
  __typename?: 'WarehouseInventoryCountAggregate';
  _all: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  dateTime: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  remarks: Scalars['Int']['output'];
};

export type WarehouseInventoryCreateInput = {
  createdBy: Scalars['String']['input'];
  dateTime: Scalars['DateTime']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutWarehouseInventoryInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutWarehouseInventoryInput>;
};

export type WarehouseInventoryCreateNestedOneWithoutWarehouseInventoryPackagingItemsInput = {
  connect?: InputMaybe<WarehouseInventoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WarehouseInventoryCreateOrConnectWithoutWarehouseInventoryPackagingItemsInput>;
  create?: InputMaybe<WarehouseInventoryCreateWithoutWarehouseInventoryPackagingItemsInput>;
};

export type WarehouseInventoryCreateNestedOneWithoutWarehouseInventoryRawMaterialItemsInput = {
  connect?: InputMaybe<WarehouseInventoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WarehouseInventoryCreateOrConnectWithoutWarehouseInventoryRawMaterialItemsInput>;
  create?: InputMaybe<WarehouseInventoryCreateWithoutWarehouseInventoryRawMaterialItemsInput>;
};

export type WarehouseInventoryCreateOrConnectWithoutWarehouseInventoryPackagingItemsInput = {
  create: WarehouseInventoryCreateWithoutWarehouseInventoryPackagingItemsInput;
  where: WarehouseInventoryWhereUniqueInput;
};

export type WarehouseInventoryCreateOrConnectWithoutWarehouseInventoryRawMaterialItemsInput = {
  create: WarehouseInventoryCreateWithoutWarehouseInventoryRawMaterialItemsInput;
  where: WarehouseInventoryWhereUniqueInput;
};

export type WarehouseInventoryCreateWithoutWarehouseInventoryPackagingItemsInput = {
  createdBy: Scalars['String']['input'];
  dateTime: Scalars['DateTime']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemCreateNestedManyWithoutWarehouseInventoryInput>;
};

export type WarehouseInventoryCreateWithoutWarehouseInventoryRawMaterialItemsInput = {
  createdBy: Scalars['String']['input'];
  dateTime: Scalars['DateTime']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemCreateNestedManyWithoutWarehouseInventoryInput>;
};

export type WarehouseInventoryMaxAggregate = {
  __typename?: 'WarehouseInventoryMaxAggregate';
  createdBy?: Maybe<Scalars['String']['output']>;
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type WarehouseInventoryMinAggregate = {
  __typename?: 'WarehouseInventoryMinAggregate';
  createdBy?: Maybe<Scalars['String']['output']>;
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type WarehouseInventoryOrderByWithRelationInput = {
  createdBy?: InputMaybe<SortOrder>;
  dateTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemOrderByRelationAggregateInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemOrderByRelationAggregateInput>;
};

export type WarehouseInventoryPackagingItem = {
  __typename?: 'WarehouseInventoryPackagingItem';
  id: Scalars['Int']['output'];
  packagingProduct: PackagingProduct;
  packagingProductId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  warehouseInventory: WarehouseInventory;
  warehouseInventoryId: Scalars['Int']['output'];
};

export type WarehouseInventoryPackagingItemCreateManyPackagingProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  warehouseInventoryId: Scalars['Int']['input'];
};

export type WarehouseInventoryPackagingItemCreateManyPackagingProductInputEnvelope = {
  data: Array<WarehouseInventoryPackagingItemCreateManyPackagingProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WarehouseInventoryPackagingItemCreateManyWarehouseInventoryInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  packagingProductId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type WarehouseInventoryPackagingItemCreateManyWarehouseInventoryInputEnvelope = {
  data: Array<WarehouseInventoryPackagingItemCreateManyWarehouseInventoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WarehouseInventoryPackagingItemCreateNestedManyWithoutPackagingProductInput = {
  connect?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WarehouseInventoryPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<WarehouseInventoryPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<WarehouseInventoryPackagingItemCreateManyPackagingProductInputEnvelope>;
};

export type WarehouseInventoryPackagingItemCreateNestedManyWithoutWarehouseInventoryInput = {
  connect?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WarehouseInventoryPackagingItemCreateOrConnectWithoutWarehouseInventoryInput>>;
  create?: InputMaybe<Array<WarehouseInventoryPackagingItemCreateWithoutWarehouseInventoryInput>>;
  createMany?: InputMaybe<WarehouseInventoryPackagingItemCreateManyWarehouseInventoryInputEnvelope>;
};

export type WarehouseInventoryPackagingItemCreateOrConnectWithoutPackagingProductInput = {
  create: WarehouseInventoryPackagingItemCreateWithoutPackagingProductInput;
  where: WarehouseInventoryPackagingItemWhereUniqueInput;
};

export type WarehouseInventoryPackagingItemCreateOrConnectWithoutWarehouseInventoryInput = {
  create: WarehouseInventoryPackagingItemCreateWithoutWarehouseInventoryInput;
  where: WarehouseInventoryPackagingItemWhereUniqueInput;
};

export type WarehouseInventoryPackagingItemCreateWithoutPackagingProductInput = {
  quantity: Scalars['Int']['input'];
  warehouseInventory: WarehouseInventoryCreateNestedOneWithoutWarehouseInventoryPackagingItemsInput;
};

export type WarehouseInventoryPackagingItemCreateWithoutWarehouseInventoryInput = {
  packagingProduct: PackagingProductCreateNestedOneWithoutWarehouseInventoryPackagingItemsInput;
  quantity: Scalars['Int']['input'];
};

export type WarehouseInventoryPackagingItemListRelationFilter = {
  every?: InputMaybe<WarehouseInventoryPackagingItemWhereInput>;
  none?: InputMaybe<WarehouseInventoryPackagingItemWhereInput>;
  some?: InputMaybe<WarehouseInventoryPackagingItemWhereInput>;
};

export type WarehouseInventoryPackagingItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WarehouseInventoryPackagingItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  packagingProduct?: InputMaybe<PackagingProductOrderByWithRelationInput>;
  packagingProductId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  warehouseInventory?: InputMaybe<WarehouseInventoryOrderByWithRelationInput>;
  warehouseInventoryId?: InputMaybe<SortOrder>;
};

export enum WarehouseInventoryPackagingItemScalarFieldEnum {
  Id = 'id',
  PackagingProductId = 'packagingProductId',
  Quantity = 'quantity',
  WarehouseInventoryId = 'warehouseInventoryId'
}

export type WarehouseInventoryPackagingItemScalarWhereInput = {
  AND?: InputMaybe<Array<WarehouseInventoryPackagingItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<WarehouseInventoryPackagingItemScalarWhereInput>>;
  OR?: InputMaybe<Array<WarehouseInventoryPackagingItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  warehouseInventoryId?: InputMaybe<IntFilter>;
};

export type WarehouseInventoryPackagingItemUpdateManyMutationInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type WarehouseInventoryPackagingItemUpdateManyWithWhereWithoutPackagingProductInput = {
  data: WarehouseInventoryPackagingItemUpdateManyMutationInput;
  where: WarehouseInventoryPackagingItemScalarWhereInput;
};

export type WarehouseInventoryPackagingItemUpdateManyWithWhereWithoutWarehouseInventoryInput = {
  data: WarehouseInventoryPackagingItemUpdateManyMutationInput;
  where: WarehouseInventoryPackagingItemScalarWhereInput;
};

export type WarehouseInventoryPackagingItemUpdateManyWithoutPackagingProductNestedInput = {
  connect?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WarehouseInventoryPackagingItemCreateOrConnectWithoutPackagingProductInput>>;
  create?: InputMaybe<Array<WarehouseInventoryPackagingItemCreateWithoutPackagingProductInput>>;
  createMany?: InputMaybe<WarehouseInventoryPackagingItemCreateManyPackagingProductInputEnvelope>;
  delete?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WarehouseInventoryPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<WarehouseInventoryPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput>>;
  updateMany?: InputMaybe<Array<WarehouseInventoryPackagingItemUpdateManyWithWhereWithoutPackagingProductInput>>;
  upsert?: InputMaybe<Array<WarehouseInventoryPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput>>;
};

export type WarehouseInventoryPackagingItemUpdateManyWithoutWarehouseInventoryNestedInput = {
  connect?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WarehouseInventoryPackagingItemCreateOrConnectWithoutWarehouseInventoryInput>>;
  create?: InputMaybe<Array<WarehouseInventoryPackagingItemCreateWithoutWarehouseInventoryInput>>;
  createMany?: InputMaybe<WarehouseInventoryPackagingItemCreateManyWarehouseInventoryInputEnvelope>;
  delete?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WarehouseInventoryPackagingItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  set?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereUniqueInput>>;
  update?: InputMaybe<Array<WarehouseInventoryPackagingItemUpdateWithWhereUniqueWithoutWarehouseInventoryInput>>;
  updateMany?: InputMaybe<Array<WarehouseInventoryPackagingItemUpdateManyWithWhereWithoutWarehouseInventoryInput>>;
  upsert?: InputMaybe<Array<WarehouseInventoryPackagingItemUpsertWithWhereUniqueWithoutWarehouseInventoryInput>>;
};

export type WarehouseInventoryPackagingItemUpdateWithWhereUniqueWithoutPackagingProductInput = {
  data: WarehouseInventoryPackagingItemUpdateWithoutPackagingProductInput;
  where: WarehouseInventoryPackagingItemWhereUniqueInput;
};

export type WarehouseInventoryPackagingItemUpdateWithWhereUniqueWithoutWarehouseInventoryInput = {
  data: WarehouseInventoryPackagingItemUpdateWithoutWarehouseInventoryInput;
  where: WarehouseInventoryPackagingItemWhereUniqueInput;
};

export type WarehouseInventoryPackagingItemUpdateWithoutPackagingProductInput = {
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  warehouseInventory?: InputMaybe<WarehouseInventoryUpdateOneRequiredWithoutWarehouseInventoryPackagingItemsNestedInput>;
};

export type WarehouseInventoryPackagingItemUpdateWithoutWarehouseInventoryInput = {
  packagingProduct?: InputMaybe<PackagingProductUpdateOneRequiredWithoutWarehouseInventoryPackagingItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type WarehouseInventoryPackagingItemUpsertWithWhereUniqueWithoutPackagingProductInput = {
  create: WarehouseInventoryPackagingItemCreateWithoutPackagingProductInput;
  update: WarehouseInventoryPackagingItemUpdateWithoutPackagingProductInput;
  where: WarehouseInventoryPackagingItemWhereUniqueInput;
};

export type WarehouseInventoryPackagingItemUpsertWithWhereUniqueWithoutWarehouseInventoryInput = {
  create: WarehouseInventoryPackagingItemCreateWithoutWarehouseInventoryInput;
  update: WarehouseInventoryPackagingItemUpdateWithoutWarehouseInventoryInput;
  where: WarehouseInventoryPackagingItemWhereUniqueInput;
};

export type WarehouseInventoryPackagingItemWhereInput = {
  AND?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereInput>>;
  NOT?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereInput>>;
  OR?: InputMaybe<Array<WarehouseInventoryPackagingItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  packagingProduct?: InputMaybe<PackagingProductRelationFilter>;
  packagingProductId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  warehouseInventory?: InputMaybe<WarehouseInventoryRelationFilter>;
  warehouseInventoryId?: InputMaybe<IntFilter>;
};

export type WarehouseInventoryPackagingItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type WarehouseInventoryRawMaterialItem = {
  __typename?: 'WarehouseInventoryRawMaterialItem';
  id: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rawMaterialProduct: RawMaterialProduct;
  rawMaterialProductId: Scalars['Int']['output'];
  warehouseInventory: WarehouseInventory;
  warehouseInventoryId: Scalars['Int']['output'];
};

export type WarehouseInventoryRawMaterialItemCreateManyRawMaterialProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  warehouseInventoryId: Scalars['Int']['input'];
};

export type WarehouseInventoryRawMaterialItemCreateManyRawMaterialProductInputEnvelope = {
  data: Array<WarehouseInventoryRawMaterialItemCreateManyRawMaterialProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WarehouseInventoryRawMaterialItemCreateManyWarehouseInventoryInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rawMaterialProductId: Scalars['Int']['input'];
};

export type WarehouseInventoryRawMaterialItemCreateManyWarehouseInventoryInputEnvelope = {
  data: Array<WarehouseInventoryRawMaterialItemCreateManyWarehouseInventoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WarehouseInventoryRawMaterialItemCreateNestedManyWithoutRawMaterialProductInput = {
  connect?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WarehouseInventoryRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<WarehouseInventoryRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<WarehouseInventoryRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
};

export type WarehouseInventoryRawMaterialItemCreateNestedManyWithoutWarehouseInventoryInput = {
  connect?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WarehouseInventoryRawMaterialItemCreateOrConnectWithoutWarehouseInventoryInput>>;
  create?: InputMaybe<Array<WarehouseInventoryRawMaterialItemCreateWithoutWarehouseInventoryInput>>;
  createMany?: InputMaybe<WarehouseInventoryRawMaterialItemCreateManyWarehouseInventoryInputEnvelope>;
};

export type WarehouseInventoryRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput = {
  create: WarehouseInventoryRawMaterialItemCreateWithoutRawMaterialProductInput;
  where: WarehouseInventoryRawMaterialItemWhereUniqueInput;
};

export type WarehouseInventoryRawMaterialItemCreateOrConnectWithoutWarehouseInventoryInput = {
  create: WarehouseInventoryRawMaterialItemCreateWithoutWarehouseInventoryInput;
  where: WarehouseInventoryRawMaterialItemWhereUniqueInput;
};

export type WarehouseInventoryRawMaterialItemCreateWithoutRawMaterialProductInput = {
  quantity: Scalars['Float']['input'];
  warehouseInventory: WarehouseInventoryCreateNestedOneWithoutWarehouseInventoryRawMaterialItemsInput;
};

export type WarehouseInventoryRawMaterialItemCreateWithoutWarehouseInventoryInput = {
  quantity: Scalars['Float']['input'];
  rawMaterialProduct: RawMaterialProductCreateNestedOneWithoutWarehouseInventoryRawMaterialItemsInput;
};

export type WarehouseInventoryRawMaterialItemListRelationFilter = {
  every?: InputMaybe<WarehouseInventoryRawMaterialItemWhereInput>;
  none?: InputMaybe<WarehouseInventoryRawMaterialItemWhereInput>;
  some?: InputMaybe<WarehouseInventoryRawMaterialItemWhereInput>;
};

export type WarehouseInventoryRawMaterialItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WarehouseInventoryRawMaterialItemOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductOrderByWithRelationInput>;
  rawMaterialProductId?: InputMaybe<SortOrder>;
  warehouseInventory?: InputMaybe<WarehouseInventoryOrderByWithRelationInput>;
  warehouseInventoryId?: InputMaybe<SortOrder>;
};

export enum WarehouseInventoryRawMaterialItemScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  RawMaterialProductId = 'rawMaterialProductId',
  WarehouseInventoryId = 'warehouseInventoryId'
}

export type WarehouseInventoryRawMaterialItemScalarWhereInput = {
  AND?: InputMaybe<Array<WarehouseInventoryRawMaterialItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<WarehouseInventoryRawMaterialItemScalarWhereInput>>;
  OR?: InputMaybe<Array<WarehouseInventoryRawMaterialItemScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  warehouseInventoryId?: InputMaybe<IntFilter>;
};

export type WarehouseInventoryRawMaterialItemUpdateManyMutationInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type WarehouseInventoryRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput = {
  data: WarehouseInventoryRawMaterialItemUpdateManyMutationInput;
  where: WarehouseInventoryRawMaterialItemScalarWhereInput;
};

export type WarehouseInventoryRawMaterialItemUpdateManyWithWhereWithoutWarehouseInventoryInput = {
  data: WarehouseInventoryRawMaterialItemUpdateManyMutationInput;
  where: WarehouseInventoryRawMaterialItemScalarWhereInput;
};

export type WarehouseInventoryRawMaterialItemUpdateManyWithoutRawMaterialProductNestedInput = {
  connect?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WarehouseInventoryRawMaterialItemCreateOrConnectWithoutRawMaterialProductInput>>;
  create?: InputMaybe<Array<WarehouseInventoryRawMaterialItemCreateWithoutRawMaterialProductInput>>;
  createMany?: InputMaybe<WarehouseInventoryRawMaterialItemCreateManyRawMaterialProductInputEnvelope>;
  delete?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WarehouseInventoryRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<WarehouseInventoryRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput>>;
  updateMany?: InputMaybe<Array<WarehouseInventoryRawMaterialItemUpdateManyWithWhereWithoutRawMaterialProductInput>>;
  upsert?: InputMaybe<Array<WarehouseInventoryRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput>>;
};

export type WarehouseInventoryRawMaterialItemUpdateManyWithoutWarehouseInventoryNestedInput = {
  connect?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WarehouseInventoryRawMaterialItemCreateOrConnectWithoutWarehouseInventoryInput>>;
  create?: InputMaybe<Array<WarehouseInventoryRawMaterialItemCreateWithoutWarehouseInventoryInput>>;
  createMany?: InputMaybe<WarehouseInventoryRawMaterialItemCreateManyWarehouseInventoryInputEnvelope>;
  delete?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WarehouseInventoryRawMaterialItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  set?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereUniqueInput>>;
  update?: InputMaybe<Array<WarehouseInventoryRawMaterialItemUpdateWithWhereUniqueWithoutWarehouseInventoryInput>>;
  updateMany?: InputMaybe<Array<WarehouseInventoryRawMaterialItemUpdateManyWithWhereWithoutWarehouseInventoryInput>>;
  upsert?: InputMaybe<Array<WarehouseInventoryRawMaterialItemUpsertWithWhereUniqueWithoutWarehouseInventoryInput>>;
};

export type WarehouseInventoryRawMaterialItemUpdateWithWhereUniqueWithoutRawMaterialProductInput = {
  data: WarehouseInventoryRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: WarehouseInventoryRawMaterialItemWhereUniqueInput;
};

export type WarehouseInventoryRawMaterialItemUpdateWithWhereUniqueWithoutWarehouseInventoryInput = {
  data: WarehouseInventoryRawMaterialItemUpdateWithoutWarehouseInventoryInput;
  where: WarehouseInventoryRawMaterialItemWhereUniqueInput;
};

export type WarehouseInventoryRawMaterialItemUpdateWithoutRawMaterialProductInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  warehouseInventory?: InputMaybe<WarehouseInventoryUpdateOneRequiredWithoutWarehouseInventoryRawMaterialItemsNestedInput>;
};

export type WarehouseInventoryRawMaterialItemUpdateWithoutWarehouseInventoryInput = {
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductUpdateOneRequiredWithoutWarehouseInventoryRawMaterialItemsNestedInput>;
};

export type WarehouseInventoryRawMaterialItemUpsertWithWhereUniqueWithoutRawMaterialProductInput = {
  create: WarehouseInventoryRawMaterialItemCreateWithoutRawMaterialProductInput;
  update: WarehouseInventoryRawMaterialItemUpdateWithoutRawMaterialProductInput;
  where: WarehouseInventoryRawMaterialItemWhereUniqueInput;
};

export type WarehouseInventoryRawMaterialItemUpsertWithWhereUniqueWithoutWarehouseInventoryInput = {
  create: WarehouseInventoryRawMaterialItemCreateWithoutWarehouseInventoryInput;
  update: WarehouseInventoryRawMaterialItemUpdateWithoutWarehouseInventoryInput;
  where: WarehouseInventoryRawMaterialItemWhereUniqueInput;
};

export type WarehouseInventoryRawMaterialItemWhereInput = {
  AND?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereInput>>;
  NOT?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereInput>>;
  OR?: InputMaybe<Array<WarehouseInventoryRawMaterialItemWhereInput>>;
  id?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  rawMaterialProduct?: InputMaybe<RawMaterialProductRelationFilter>;
  rawMaterialProductId?: InputMaybe<IntFilter>;
  warehouseInventory?: InputMaybe<WarehouseInventoryRelationFilter>;
  warehouseInventoryId?: InputMaybe<IntFilter>;
};

export type WarehouseInventoryRawMaterialItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type WarehouseInventoryRelationFilter = {
  is?: InputMaybe<WarehouseInventoryWhereInput>;
  isNot?: InputMaybe<WarehouseInventoryWhereInput>;
};

export enum WarehouseInventoryScalarFieldEnum {
  CreatedBy = 'createdBy',
  DateTime = 'dateTime',
  Id = 'id',
  Remarks = 'remarks'
}

export type WarehouseInventorySumAggregate = {
  __typename?: 'WarehouseInventorySumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type WarehouseInventoryUpdateInput = {
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutWarehouseInventoryNestedInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutWarehouseInventoryNestedInput>;
};

export type WarehouseInventoryUpdateOneRequiredWithoutWarehouseInventoryPackagingItemsNestedInput = {
  connect?: InputMaybe<WarehouseInventoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WarehouseInventoryCreateOrConnectWithoutWarehouseInventoryPackagingItemsInput>;
  create?: InputMaybe<WarehouseInventoryCreateWithoutWarehouseInventoryPackagingItemsInput>;
  update?: InputMaybe<WarehouseInventoryUpdateWithoutWarehouseInventoryPackagingItemsInput>;
  upsert?: InputMaybe<WarehouseInventoryUpsertWithoutWarehouseInventoryPackagingItemsInput>;
};

export type WarehouseInventoryUpdateOneRequiredWithoutWarehouseInventoryRawMaterialItemsNestedInput = {
  connect?: InputMaybe<WarehouseInventoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WarehouseInventoryCreateOrConnectWithoutWarehouseInventoryRawMaterialItemsInput>;
  create?: InputMaybe<WarehouseInventoryCreateWithoutWarehouseInventoryRawMaterialItemsInput>;
  update?: InputMaybe<WarehouseInventoryUpdateWithoutWarehouseInventoryRawMaterialItemsInput>;
  upsert?: InputMaybe<WarehouseInventoryUpsertWithoutWarehouseInventoryRawMaterialItemsInput>;
};

export type WarehouseInventoryUpdateWithoutWarehouseInventoryPackagingItemsInput = {
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemUpdateManyWithoutWarehouseInventoryNestedInput>;
};

export type WarehouseInventoryUpdateWithoutWarehouseInventoryRawMaterialItemsInput = {
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  dateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  remarks?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemUpdateManyWithoutWarehouseInventoryNestedInput>;
};

export type WarehouseInventoryUpsertWithoutWarehouseInventoryPackagingItemsInput = {
  create: WarehouseInventoryCreateWithoutWarehouseInventoryPackagingItemsInput;
  update: WarehouseInventoryUpdateWithoutWarehouseInventoryPackagingItemsInput;
};

export type WarehouseInventoryUpsertWithoutWarehouseInventoryRawMaterialItemsInput = {
  create: WarehouseInventoryCreateWithoutWarehouseInventoryRawMaterialItemsInput;
  update: WarehouseInventoryUpdateWithoutWarehouseInventoryRawMaterialItemsInput;
};

export type WarehouseInventoryWhereInput = {
  AND?: InputMaybe<Array<WarehouseInventoryWhereInput>>;
  NOT?: InputMaybe<Array<WarehouseInventoryWhereInput>>;
  OR?: InputMaybe<Array<WarehouseInventoryWhereInput>>;
  createdBy?: InputMaybe<StringFilter>;
  dateTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
  warehouseInventoryPackagingItems?: InputMaybe<WarehouseInventoryPackagingItemListRelationFilter>;
  warehouseInventoryRawMaterialItems?: InputMaybe<WarehouseInventoryRawMaterialItemListRelationFilter>;
};

export type WarehouseInventoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type WarehousePackagingInventoryReportItem = {
  __typename?: 'WarehousePackagingInventoryReportItem';
  actualEndingQuantity?: Maybe<Scalars['Float']['output']>;
  beginningQuantity?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  packaging?: Maybe<Scalars['String']['output']>;
  purchaseQuantity?: Maybe<Scalars['Float']['output']>;
  releasedQuantity?: Maybe<Scalars['Float']['output']>;
  shouldBeQuantity?: Maybe<Scalars['Float']['output']>;
  throwAwayQuantity?: Maybe<Scalars['Float']['output']>;
  transferInQuantity?: Maybe<Scalars['Float']['output']>;
  transferOutQuantity?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type WarehousePackagingInventoryReportItemWhereInput = {
  monthYear?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseRawMaterialInventoryReportItem = {
  __typename?: 'WarehouseRawMaterialInventoryReportItem';
  actualEndingQuantity?: Maybe<Scalars['Float']['output']>;
  beginningQuantity?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  emptiesQuantity?: Maybe<Scalars['Float']['output']>;
  finEndingQuantity?: Maybe<Scalars['Float']['output']>;
  purchaseQuantity?: Maybe<Scalars['Float']['output']>;
  rawMaterial?: Maybe<Scalars['String']['output']>;
  releasedQuantity?: Maybe<Scalars['Float']['output']>;
  shouldBeQuantity?: Maybe<Scalars['Float']['output']>;
  throwAwayQuantity?: Maybe<Scalars['Float']['output']>;
  transferInQuantity?: Maybe<Scalars['Float']['output']>;
  transferOutQuantity?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  variance?: Maybe<Scalars['Float']['output']>;
};

export type WarehouseRawMaterialInventoryReportItemWhereInput = {
  monthYear?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type AggregateAuditTrailQueryVariables = Exact<{
  where?: InputMaybe<AuditTrailWhereInput>;
}>;


export type AggregateAuditTrailQuery = { __typename?: 'Query', aggregateAuditTrail: { __typename?: 'AggregateAuditTrail', _count?: { __typename?: 'AuditTrailCountAggregate', _all: number } | null } };

export type AuditTrailsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AuditTrailWhereInput>;
  orderBy?: InputMaybe<Array<AuditTrailOrderByWithRelationInput> | AuditTrailOrderByWithRelationInput>;
}>;


export type AuditTrailsQuery = { __typename?: 'Query', auditTrails: Array<{ __typename?: 'AuditTrail', id: number, nameOfUser: string, timestamp: any, action: string, moduleForm: string, newData?: any | null, oldData?: any | null }> };

export type AggregateBetweenEntityTransferQueryVariables = Exact<{
  where?: InputMaybe<BetweenEntityTransferWhereInput>;
}>;


export type AggregateBetweenEntityTransferQuery = { __typename?: 'Query', aggregateBetweenEntityTransfer: { __typename?: 'AggregateBetweenEntityTransfer', _count?: { __typename?: 'BetweenEntityTransferCountAggregate', _all: number } | null } };

export type AllBetweenEntityTransferFieldsFragment = { __typename?: 'BetweenEntityTransfer', id: number, date: any, transferDirection: TransferDirection, controlNumber: string, origin: string, destination: string, receivedBy?: string | null, createdBy: string, betweenEntityTransferRawMaterialItems: Array<{ __typename?: 'BetweenEntityTransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, betweenEntityTransferPackagingItems: Array<{ __typename?: 'BetweenEntityTransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type BetweenEntityTransferQueryVariables = Exact<{
  where: BetweenEntityTransferWhereUniqueInput;
}>;


export type BetweenEntityTransferQuery = { __typename?: 'Query', betweenEntityTransfer?: { __typename?: 'BetweenEntityTransfer', id: number, date: any, transferDirection: TransferDirection, controlNumber: string, origin: string, destination: string, receivedBy?: string | null, createdBy: string, betweenEntityTransferRawMaterialItems: Array<{ __typename?: 'BetweenEntityTransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, betweenEntityTransferPackagingItems: Array<{ __typename?: 'BetweenEntityTransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type BetweenEntityTransfersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BetweenEntityTransferWhereInput>;
  orderBy?: InputMaybe<Array<BetweenEntityTransferOrderByWithRelationInput> | BetweenEntityTransferOrderByWithRelationInput>;
}>;


export type BetweenEntityTransfersQuery = { __typename?: 'Query', betweenEntityTransfers: Array<{ __typename?: 'BetweenEntityTransfer', id: number, date: any, transferDirection: TransferDirection, controlNumber: string, origin: string, destination: string, receivedBy?: string | null, createdBy: string, betweenEntityTransferRawMaterialItems: Array<{ __typename?: 'BetweenEntityTransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, betweenEntityTransferPackagingItems: Array<{ __typename?: 'BetweenEntityTransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type CreateBetweenEntityTransferMutationVariables = Exact<{
  data: BetweenEntityTransferCreateInput;
}>;


export type CreateBetweenEntityTransferMutation = { __typename?: 'Mutation', createBetweenEntityTransfer: { __typename?: 'BetweenEntityTransfer', id: number } };

export type DeleteManyBetweenEntityTransferMutationVariables = Exact<{
  where?: InputMaybe<Array<BetweenEntityTransferWhereUniqueInput> | BetweenEntityTransferWhereUniqueInput>;
}>;


export type DeleteManyBetweenEntityTransferMutation = { __typename?: 'Mutation', deleteManyBetweenEntityTransfer: Array<{ __typename?: 'BetweenEntityTransfer', id: number }> };

export type UpdateBetweenEntityTransferMutationVariables = Exact<{
  where: BetweenEntityTransferWhereUniqueInput;
  data: BetweenEntityTransferUpdateInput;
}>;


export type UpdateBetweenEntityTransferMutation = { __typename?: 'Mutation', updateBetweenEntityTransfer: { __typename?: 'BetweenEntityTransfer', id: number } };

export type AggregateBeverageQueryVariables = Exact<{
  where?: InputMaybe<BeverageWhereInput>;
}>;


export type AggregateBeverageQuery = { __typename?: 'Query', aggregateBeverage: { __typename?: 'AggregateBeverage', _count?: { __typename?: 'BeverageCountAggregate', _all: number } | null } };

export type AllBeverageFieldsFragment = { __typename?: 'Beverage', id: number, name: string, yield: number, ozInMedium?: number | null, ozInLarge?: number | null, ozInXl?: number | null, status: Status, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } };

export type BeverageQueryVariables = Exact<{
  where: BeverageWhereUniqueInput;
}>;


export type BeverageQuery = { __typename?: 'Query', beverage?: { __typename?: 'Beverage', id: number, name: string, yield: number, ozInMedium?: number | null, ozInLarge?: number | null, ozInXl?: number | null, status: Status, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } } | null };

export type BeveragesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BeverageWhereInput>;
  orderBy?: InputMaybe<Array<BeverageOrderByWithRelationInput> | BeverageOrderByWithRelationInput>;
}>;


export type BeveragesQuery = { __typename?: 'Query', beverages: Array<{ __typename?: 'Beverage', id: number, name: string, yield: number, ozInMedium?: number | null, ozInLarge?: number | null, ozInXl?: number | null, status: Status, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type BeveragesAllowedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BeverageWhereInput>;
  orderBy?: InputMaybe<Array<BeverageOrderByWithRelationInput> | BeverageOrderByWithRelationInput>;
}>;


export type BeveragesAllowedQuery = { __typename?: 'Query', beverages: Array<{ __typename?: 'Beverage', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } }> };

export type BeveragesDropdownQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BeverageWhereInput>;
  orderBy?: InputMaybe<Array<BeverageOrderByWithRelationInput> | BeverageOrderByWithRelationInput>;
}>;


export type BeveragesDropdownQuery = { __typename?: 'Query', beveragesDropdown: Array<{ __typename?: 'Beverage', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } }> };

export type CreateBeverageMutationVariables = Exact<{
  data: BeverageCreateInput;
}>;


export type CreateBeverageMutation = { __typename?: 'Mutation', createBeverage: { __typename?: 'Beverage', id: number } };

export type DeleteManyBeverageMutationVariables = Exact<{
  where?: InputMaybe<Array<BeverageWhereUniqueInput> | BeverageWhereUniqueInput>;
}>;


export type DeleteManyBeverageMutation = { __typename?: 'Mutation', deleteManyBeverage: Array<{ __typename?: 'Beverage', id: number }> };

export type UpdateBeverageMutationVariables = Exact<{
  where: BeverageWhereUniqueInput;
  data: BeverageUpdateInput;
}>;


export type UpdateBeverageMutation = { __typename?: 'Mutation', updateBeverage: { __typename?: 'Beverage', id: number } };

export type BeveragesConsolidatedThrowAwayReportItemsQueryVariables = Exact<{
  where?: InputMaybe<ConsolidatedThrowAwayReportItemWhereInput>;
}>;


export type BeveragesConsolidatedThrowAwayReportItemsQuery = { __typename?: 'Query', beveragesConsolidatedThrowAwayReportItems: Array<{ __typename?: 'BeveragesConsolidatedThrowAwayReportItem', date?: any | null, beverages?: Array<{ __typename?: 'BeverageThrowAway', name?: string | null, quantity?: number | null }> | null }> };

export type BeveragesSalesProductControlReportItemsQueryVariables = Exact<{
  where?: InputMaybe<SalesProductControlReportItemWhereInput>;
}>;


export type BeveragesSalesProductControlReportItemsQuery = { __typename?: 'Query', beveragesSalesProductControlReportItems: Array<{ __typename?: 'BeveragesSalesProductControlReportItem', beverage?: string | null, beginningQuantity?: number | null, beverageYield?: number | null, endingQuantity?: number | null, emQuantity?: number | null, sdQuantity?: number | null, taQuantity?: number | null, usage?: number | null, cupsSoldMediumQuantity?: number | null, cupsSoldLargeQuantity?: number | null, cupsSoldXlQuantity?: number | null, ozSold?: number | null, variance?: number | null }> };

export type AggregateCheatSheetQueryVariables = Exact<{
  where?: InputMaybe<CheatSheetWhereInput>;
}>;


export type AggregateCheatSheetQuery = { __typename?: 'Query', aggregateCheatSheet: { __typename?: 'AggregateCheatSheet', _count?: { __typename?: 'CheatSheetCountAggregate', _all: number } | null } };

export type AllCheatSheetFieldsFragment = { __typename?: 'CheatSheet', id: number, instructions: string, userGroup: UserGroup };

export type CheatSheetQueryVariables = Exact<{
  where: CheatSheetWhereUniqueInput;
}>;


export type CheatSheetQuery = { __typename?: 'Query', cheatSheet?: { __typename?: 'CheatSheet', id: number, instructions: string, userGroup: UserGroup } | null };

export type CheatSheetsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CheatSheetWhereInput>;
  orderBy?: InputMaybe<Array<CheatSheetOrderByWithRelationInput> | CheatSheetOrderByWithRelationInput>;
}>;


export type CheatSheetsQuery = { __typename?: 'Query', cheatSheets: Array<{ __typename?: 'CheatSheet', id: number, instructions: string, userGroup: UserGroup }> };

export type CreateCheatSheetMutationVariables = Exact<{
  data: CheatSheetCreateInput;
}>;


export type CreateCheatSheetMutation = { __typename?: 'Mutation', createCheatSheet: { __typename?: 'CheatSheet', id: number } };

export type DeleteManyCheatSheetMutationVariables = Exact<{
  where?: InputMaybe<Array<CheatSheetWhereUniqueInput> | CheatSheetWhereUniqueInput>;
}>;


export type DeleteManyCheatSheetMutation = { __typename?: 'Mutation', deleteManyCheatSheet: Array<{ __typename?: 'CheatSheet', id: number }> };

export type UpdateCheatSheetMutationVariables = Exact<{
  where: CheatSheetWhereUniqueInput;
  data: CheatSheetUpdateInput;
}>;


export type UpdateCheatSheetMutation = { __typename?: 'Mutation', updateCheatSheet: { __typename?: 'CheatSheet', id: number } };

export type AggregateCmlProductQueryVariables = Exact<{
  where?: InputMaybe<CmlProductWhereInput>;
}>;


export type AggregateCmlProductQuery = { __typename?: 'Query', aggregateCmlProduct: { __typename?: 'AggregateCmlProduct', _count?: { __typename?: 'CmlProductCountAggregate', _all: number } | null } };

export type AllCmlProductFieldsFragment = { __typename?: 'CmlProduct', id: number, name: string, productCategory: ProductCategory, salesCategory: SalesCategory, status: Status, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string }, latestCmlProductIngredients: Array<{ __typename?: 'CmlProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CmlProductQueryVariables = Exact<{
  where: CmlProductWhereUniqueInput;
}>;


export type CmlProductQuery = { __typename?: 'Query', cmlProduct?: { __typename?: 'CmlProduct', id: number, name: string, productCategory: ProductCategory, salesCategory: SalesCategory, status: Status, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string }, latestCmlProductIngredients: Array<{ __typename?: 'CmlProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type CmlProductsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductWhereInput>;
  orderBy?: InputMaybe<Array<CmlProductOrderByWithRelationInput> | CmlProductOrderByWithRelationInput>;
}>;


export type CmlProductsQuery = { __typename?: 'Query', cmlProducts: Array<{ __typename?: 'CmlProduct', id: number, name: string, productCategory: ProductCategory, salesCategory: SalesCategory, status: Status, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string }, latestCmlProductIngredients: Array<{ __typename?: 'CmlProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type CmlProductsAllowedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductWhereInput>;
  orderBy?: InputMaybe<Array<CmlProductOrderByWithRelationInput> | CmlProductOrderByWithRelationInput>;
}>;


export type CmlProductsAllowedQuery = { __typename?: 'Query', cmlProducts: Array<{ __typename?: 'CmlProduct', id: number, name: string, productCategory: ProductCategory, salesCategory: SalesCategory, status: Status, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }> };

export type CmlProductsDropdownQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductWhereInput>;
  orderBy?: InputMaybe<Array<CmlProductOrderByWithRelationInput> | CmlProductOrderByWithRelationInput>;
}>;


export type CmlProductsDropdownQuery = { __typename?: 'Query', cmlProductsDropdown: Array<{ __typename?: 'CmlProduct', id: number, name: string, productCategory: ProductCategory, salesCategory: SalesCategory, status: Status, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }> };

export type CreateCmlProductMutationVariables = Exact<{
  data: CmlProductCreateInput;
}>;


export type CreateCmlProductMutation = { __typename?: 'Mutation', createCmlProduct: { __typename?: 'CmlProduct', id: number } };

export type DeleteManyCmlProductMutationVariables = Exact<{
  where?: InputMaybe<Array<CmlProductWhereUniqueInput> | CmlProductWhereUniqueInput>;
}>;


export type DeleteManyCmlProductMutation = { __typename?: 'Mutation', deleteManyCmlProduct: Array<{ __typename?: 'CmlProduct', id: number }> };

export type UpdateCmlProductMutationVariables = Exact<{
  where: CmlProductWhereUniqueInput;
  data: CmlProductUpdateInput;
}>;


export type UpdateCmlProductMutation = { __typename?: 'Mutation', updateCmlProduct: { __typename?: 'CmlProduct', id: number } };

export type AggregateCmlProductRecipeQueryVariables = Exact<{
  where?: InputMaybe<CmlProductRecipeWhereInput>;
}>;


export type AggregateCmlProductRecipeQuery = { __typename?: 'Query', aggregateCmlProductRecipe: { __typename?: 'AggregateCmlProductRecipe', _count?: { __typename?: 'CmlProductRecipeCountAggregate', _all: number } | null } };

export type AllCmlProductRecipeFieldsFragment = { __typename?: 'CmlProductRecipe', id: number, date: any, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }, cmlProductIngredients: Array<{ __typename?: 'CmlProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CmlProductRecipeQueryVariables = Exact<{
  where: CmlProductRecipeWhereUniqueInput;
}>;


export type CmlProductRecipeQuery = { __typename?: 'Query', cmlProductRecipe?: { __typename?: 'CmlProductRecipe', id: number, date: any, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }, cmlProductIngredients: Array<{ __typename?: 'CmlProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type CmlProductRecipesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlProductRecipeWhereInput>;
  orderBy?: InputMaybe<Array<CmlProductRecipeOrderByWithRelationInput> | CmlProductRecipeOrderByWithRelationInput>;
}>;


export type CmlProductRecipesQuery = { __typename?: 'Query', cmlProductRecipes: Array<{ __typename?: 'CmlProductRecipe', id: number, date: any, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }, cmlProductIngredients: Array<{ __typename?: 'CmlProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type CreateCmlProductRecipeMutationVariables = Exact<{
  data: CmlProductRecipeCreateInput;
}>;


export type CreateCmlProductRecipeMutation = { __typename?: 'Mutation', createCmlProductRecipe: { __typename?: 'CmlProductRecipe', id: number } };

export type DeleteManyCmlProductRecipeMutationVariables = Exact<{
  where?: InputMaybe<Array<CmlProductRecipeWhereUniqueInput> | CmlProductRecipeWhereUniqueInput>;
}>;


export type DeleteManyCmlProductRecipeMutation = { __typename?: 'Mutation', deleteManyCmlProductRecipe: Array<{ __typename?: 'CmlProductRecipe', id: number }> };

export type UpdateCmlProductRecipeMutationVariables = Exact<{
  where: CmlProductRecipeWhereUniqueInput;
  data: CmlProductRecipeUpdateInput;
}>;


export type UpdateCmlProductRecipeMutation = { __typename?: 'Mutation', updateCmlProductRecipe: { __typename?: 'CmlProductRecipe', id: number } };

export type CmlProductsSalesProductControlReportItemsQueryVariables = Exact<{
  where?: InputMaybe<SalesProductControlReportItemWhereInput>;
}>;


export type CmlProductsSalesProductControlReportItemsQuery = { __typename?: 'Query', cmlProductsSalesProductControlReportItems: Array<{ __typename?: 'CmlProductsSalesProductControlReportItem', salesCategory?: string | null, beginningQuantity?: number | null, deliveriesQuantity?: number | null, transferInQuantity?: number | null, transferOutQuantity?: number | null, emQuantity?: number | null, sdQuantity?: number | null, taQuantity?: number | null, endingQuantity?: number | null, usage?: number | null, crtCmlItemQuantity?: number | null, variance?: number | null }> };

export type AggregateCmlThrowAwayQueryVariables = Exact<{
  where?: InputMaybe<CmlThrowAwayWhereInput>;
}>;


export type AggregateCmlThrowAwayQuery = { __typename?: 'Query', aggregateCmlThrowAway: { __typename?: 'AggregateCmlThrowAway', _count?: { __typename?: 'CmlThrowAwayCountAggregate', _all: number } | null } };

export type AllCmlThrowAwayFieldsFragment = { __typename?: 'CmlThrowAway', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, cmlThrowAwayLocation: CmlThrowAwayLocation, createdBy: string, checkedBy?: string | null, cmlThrowAwayCmlItems: Array<{ __typename?: 'CmlThrowAwayCmlItem', id: number, quantity: number, checkedQuantity?: number | null, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string } }>, cmlThrowAwayRawMaterialItems: Array<{ __typename?: 'CmlThrowAwayRawMaterialItem', id: number, quantity: number, checkedQuantity?: number | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, cmlThrowAwayPackagingItems: Array<{ __typename?: 'CmlThrowAwayPackagingItem', id: number, quantity: number, checkedQuantity?: number | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CmlThrowAwayQueryVariables = Exact<{
  where: CmlThrowAwayWhereUniqueInput;
}>;


export type CmlThrowAwayQuery = { __typename?: 'Query', cmlThrowAway?: { __typename?: 'CmlThrowAway', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, cmlThrowAwayLocation: CmlThrowAwayLocation, createdBy: string, checkedBy?: string | null, cmlThrowAwayCmlItems: Array<{ __typename?: 'CmlThrowAwayCmlItem', id: number, quantity: number, checkedQuantity?: number | null, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string } }>, cmlThrowAwayRawMaterialItems: Array<{ __typename?: 'CmlThrowAwayRawMaterialItem', id: number, quantity: number, checkedQuantity?: number | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, cmlThrowAwayPackagingItems: Array<{ __typename?: 'CmlThrowAwayPackagingItem', id: number, quantity: number, checkedQuantity?: number | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type CmlThrowAwaysQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CmlThrowAwayWhereInput>;
  orderBy?: InputMaybe<Array<CmlThrowAwayOrderByWithRelationInput> | CmlThrowAwayOrderByWithRelationInput>;
}>;


export type CmlThrowAwaysQuery = { __typename?: 'Query', cmlThrowAways: Array<{ __typename?: 'CmlThrowAway', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, cmlThrowAwayLocation: CmlThrowAwayLocation, createdBy: string, checkedBy?: string | null, cmlThrowAwayCmlItems: Array<{ __typename?: 'CmlThrowAwayCmlItem', id: number, quantity: number, checkedQuantity?: number | null, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string } }>, cmlThrowAwayRawMaterialItems: Array<{ __typename?: 'CmlThrowAwayRawMaterialItem', id: number, quantity: number, checkedQuantity?: number | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, cmlThrowAwayPackagingItems: Array<{ __typename?: 'CmlThrowAwayPackagingItem', id: number, quantity: number, checkedQuantity?: number | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type CreateCmlThrowAwayMutationVariables = Exact<{
  data: CmlThrowAwayCreateInput;
}>;


export type CreateCmlThrowAwayMutation = { __typename?: 'Mutation', createCmlThrowAway: { __typename?: 'CmlThrowAway', id: number } };

export type DeleteManyCmlThrowAwayMutationVariables = Exact<{
  where?: InputMaybe<Array<CmlThrowAwayWhereUniqueInput> | CmlThrowAwayWhereUniqueInput>;
}>;


export type DeleteManyCmlThrowAwayMutation = { __typename?: 'Mutation', deleteManyCmlThrowAway: Array<{ __typename?: 'CmlThrowAway', id: number }> };

export type UpdateCmlThrowAwayMutationVariables = Exact<{
  where: CmlThrowAwayWhereUniqueInput;
  data: CmlThrowAwayUpdateInput;
}>;


export type UpdateCmlThrowAwayMutation = { __typename?: 'Mutation', updateCmlThrowAway: { __typename?: 'CmlThrowAway', id: number } };

export type AggregateCrtQueryVariables = Exact<{
  where?: InputMaybe<CrtWhereInput>;
}>;


export type AggregateCrtQuery = { __typename?: 'Query', aggregateCrt: { __typename?: 'AggregateCrt', _count?: { __typename?: 'CrtCountAggregate', _all: number } | null } };

export type AllCrtFieldsFragment = { __typename?: 'Crt', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, outlet: { __typename?: 'Outlet', id: number, name: string }, crtCmlItems: Array<{ __typename?: 'CrtCmlItem', id: number, quantity: number, salesCategory: SalesCategory }>, crtRawMaterialItems: Array<{ __typename?: 'CrtRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, crtPackagingItems: Array<{ __typename?: 'CrtPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, crtBeverageItems: Array<{ __typename?: 'CrtBeverageItem', id: number, cupsSoldMedium: number, cupsSoldLarge: number, cupsSoldXl: number, beverage: { __typename?: 'Beverage', id: number, name: string } }> };

export type CreateCrtMutationVariables = Exact<{
  data: CrtCreateInput;
}>;


export type CreateCrtMutation = { __typename?: 'Mutation', createCrt: { __typename?: 'Crt', id: number } };

export type CrtQueryVariables = Exact<{
  where: CrtWhereUniqueInput;
}>;


export type CrtQuery = { __typename?: 'Query', crt?: { __typename?: 'Crt', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, outlet: { __typename?: 'Outlet', id: number, name: string }, crtCmlItems: Array<{ __typename?: 'CrtCmlItem', id: number, quantity: number, salesCategory: SalesCategory }>, crtRawMaterialItems: Array<{ __typename?: 'CrtRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, crtPackagingItems: Array<{ __typename?: 'CrtPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, crtBeverageItems: Array<{ __typename?: 'CrtBeverageItem', id: number, cupsSoldMedium: number, cupsSoldLarge: number, cupsSoldXl: number, beverage: { __typename?: 'Beverage', id: number, name: string } }> } | null };

export type CrtsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CrtWhereInput>;
  orderBy?: InputMaybe<Array<CrtOrderByWithRelationInput> | CrtOrderByWithRelationInput>;
}>;


export type CrtsQuery = { __typename?: 'Query', crts: Array<{ __typename?: 'Crt', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, outlet: { __typename?: 'Outlet', id: number, name: string }, crtCmlItems: Array<{ __typename?: 'CrtCmlItem', id: number, quantity: number, salesCategory: SalesCategory }>, crtRawMaterialItems: Array<{ __typename?: 'CrtRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, crtPackagingItems: Array<{ __typename?: 'CrtPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, crtBeverageItems: Array<{ __typename?: 'CrtBeverageItem', id: number, cupsSoldMedium: number, cupsSoldLarge: number, cupsSoldXl: number, beverage: { __typename?: 'Beverage', id: number, name: string } }> }> };

export type DeleteManyCrtMutationVariables = Exact<{
  where?: InputMaybe<Array<CrtWhereUniqueInput> | CrtWhereUniqueInput>;
}>;


export type DeleteManyCrtMutation = { __typename?: 'Mutation', deleteManyCrt: Array<{ __typename?: 'Crt', id: number }> };

export type UpdateCrtMutationVariables = Exact<{
  where: CrtWhereUniqueInput;
  data: CrtUpdateInput;
}>;


export type UpdateCrtMutation = { __typename?: 'Mutation', updateCrt: { __typename?: 'Crt', id: number } };

export type DailyRawMaterialFinishingOutletVarianceReportQueryVariables = Exact<{
  where?: InputMaybe<DailyRawMaterialFinishingOutletVarianceReportWhereInput>;
}>;


export type DailyRawMaterialFinishingOutletVarianceReportQuery = { __typename?: 'Query', dailyRawMaterialFinishingOutletVarianceReport: Array<{ __typename?: 'DailyRawMaterialFinishingOutletVarianceReport', category?: string | null, rawMaterial?: string | null, unit?: string | null, beginning?: number | null, fromStoreRoom?: number | null, throwAway?: number | null, theoretical?: number | null, shouldBe?: number | null, actualEnding?: number | null, variance?: number | null }> };

export type DailyRawMaterialKitchenVarianceReportQueryVariables = Exact<{
  where?: InputMaybe<DailyRawMaterialKitchenVarianceReportWhereInput>;
}>;


export type DailyRawMaterialKitchenVarianceReportQuery = { __typename?: 'Query', dailyRawMaterialKitchenVarianceReport: Array<{ __typename?: 'DailyRawMaterialKitchenVarianceReport', category?: string | null, rawMaterial?: string | null, unit?: string | null, beginning?: number | null, fromStoreRoom?: number | null, throwAway?: number | null, theoretical?: number | null, shouldBe?: number | null, actualEnding?: number | null, variance?: number | null }> };

export type AggregateDeliveryReceiptQueryVariables = Exact<{
  where?: InputMaybe<DeliveryReceiptWhereInput>;
}>;


export type AggregateDeliveryReceiptQuery = { __typename?: 'Query', aggregateDeliveryReceipt: { __typename?: 'AggregateDeliveryReceipt', _count?: { __typename?: 'DeliveryReceiptCountAggregate', _all: number } | null } };

export type AllDeliveryReceiptFieldsFragment = { __typename?: 'DeliveryReceipt', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, receivedByDriver?: string | null, ydmFryingTime?: any | null, yrcFryingTime?: any | null, chmFryingTime?: any | null, cdmFryingTime?: any | null, pymFryingTime?: any | null, basketReleasedQuantity: number, screenSeparatorReleasedQuantity: number, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, deliveryReceiptItems: Array<{ __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } }> };

export type CreateDeliveryReceiptMutationVariables = Exact<{
  data: DeliveryReceiptCreateInput;
}>;


export type CreateDeliveryReceiptMutation = { __typename?: 'Mutation', createDeliveryReceipt: { __typename?: 'DeliveryReceipt', id: number } };

export type DeleteManyDeliveryReceiptMutationVariables = Exact<{
  where?: InputMaybe<Array<DeliveryReceiptWhereUniqueInput> | DeliveryReceiptWhereUniqueInput>;
}>;


export type DeleteManyDeliveryReceiptMutation = { __typename?: 'Mutation', deleteManyDeliveryReceipt: Array<{ __typename?: 'DeliveryReceipt', id: number }> };

export type DeliveryReceiptQueryVariables = Exact<{
  where: DeliveryReceiptWhereUniqueInput;
}>;


export type DeliveryReceiptQuery = { __typename?: 'Query', deliveryReceipt?: { __typename?: 'DeliveryReceipt', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, receivedByDriver?: string | null, ydmFryingTime?: any | null, yrcFryingTime?: any | null, chmFryingTime?: any | null, cdmFryingTime?: any | null, pymFryingTime?: any | null, basketReleasedQuantity: number, screenSeparatorReleasedQuantity: number, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, deliveryReceiptItems: Array<{ __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } }> } | null };

export type DeliveryReceiptsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptWhereInput>;
  orderBy?: InputMaybe<Array<DeliveryReceiptOrderByWithRelationInput> | DeliveryReceiptOrderByWithRelationInput>;
}>;


export type DeliveryReceiptsQuery = { __typename?: 'Query', deliveryReceipts: Array<{ __typename?: 'DeliveryReceipt', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, receivedByDriver?: string | null, ydmFryingTime?: any | null, yrcFryingTime?: any | null, chmFryingTime?: any | null, cdmFryingTime?: any | null, pymFryingTime?: any | null, basketReleasedQuantity: number, screenSeparatorReleasedQuantity: number, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, deliveryReceiptItems: Array<{ __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } }> }> };

export type DeliveryReceiptsDropdownQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptWhereInput>;
  orderBy?: InputMaybe<Array<DeliveryReceiptOrderByWithRelationInput> | DeliveryReceiptOrderByWithRelationInput>;
}>;


export type DeliveryReceiptsDropdownQuery = { __typename?: 'Query', deliveryReceiptsDropdown: Array<{ __typename?: 'DeliveryReceipt', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, receivedByDriver?: string | null, ydmFryingTime?: any | null, yrcFryingTime?: any | null, chmFryingTime?: any | null, cdmFryingTime?: any | null, pymFryingTime?: any | null, basketReleasedQuantity: number, screenSeparatorReleasedQuantity: number, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, deliveryReceiptItems: Array<{ __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } }> }> };

export type UpdateDeliveryReceiptMutationVariables = Exact<{
  where: DeliveryReceiptWhereUniqueInput;
  data: DeliveryReceiptUpdateInput;
}>;


export type UpdateDeliveryReceiptMutation = { __typename?: 'Mutation', updateDeliveryReceipt: { __typename?: 'DeliveryReceipt', id: number } };

export type AllDeliveryReceiptItemFieldsFragment = { __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } };

export type DeliveryReceiptItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryReceiptItemWhereInput>;
  orderBy?: InputMaybe<Array<DeliveryReceiptItemOrderByWithRelationInput> | DeliveryReceiptItemOrderByWithRelationInput>;
}>;


export type DeliveryReceiptItemsQuery = { __typename?: 'Query', deliveryReceiptItems: Array<{ __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } }> };

export type DonutDistributionPerOutletQueryVariables = Exact<{
  where?: InputMaybe<DonutDistributionPerOutletWhereInput>;
}>;


export type DonutDistributionPerOutletQuery = { __typename?: 'Query', donutDistributionPerOutlet: Array<{ __typename?: 'DonutDistributionPerOutlet', donutMixAndCut?: string | null, cmlProducts: Array<{ __typename?: 'DonutDistributionCmlProductItem', cmlProduct: string, perCmlProductDonutDistributions: Array<{ __typename?: 'PerCmlProductDonutDistribution', name: string, quantity: number }> }> }> };

export type DonutEndingConsolidationsQueryVariables = Exact<{
  where?: InputMaybe<DonutEndingConsolidationWhereInput>;
}>;


export type DonutEndingConsolidationsQuery = { __typename?: 'Query', donutEndingConsolidations: Array<{ __typename?: 'DonutEndingConsolidation', donutMixAndCut: string, cmlProducts: Array<{ __typename?: 'CmlProductItem', cmlProduct: string, perCmlProductDailyEndings: Array<{ __typename?: 'PerCmlProductDailyEnding', name: string, quantity: number }> }> }> };

export type AggregateDonutMixAndCutQueryVariables = Exact<{
  where?: InputMaybe<DonutMixAndCutWhereInput>;
}>;


export type AggregateDonutMixAndCutQuery = { __typename?: 'Query', aggregateDonutMixAndCut: { __typename?: 'AggregateDonutMixAndCut', _count?: { __typename?: 'DonutMixAndCutCountAggregate', _all: number } | null } };

export type AllDonutMixAndCutFieldsFragment = { __typename?: 'DonutMixAndCut', id: number, name: string };

export type CreateDonutMixAndCutMutationVariables = Exact<{
  data: DonutMixAndCutCreateInput;
}>;


export type CreateDonutMixAndCutMutation = { __typename?: 'Mutation', createDonutMixAndCut: { __typename?: 'DonutMixAndCut', id: number } };

export type DeleteManyDonutMixAndCutMutationVariables = Exact<{
  where?: InputMaybe<Array<DonutMixAndCutWhereUniqueInput> | DonutMixAndCutWhereUniqueInput>;
}>;


export type DeleteManyDonutMixAndCutMutation = { __typename?: 'Mutation', deleteManyDonutMixAndCut: Array<{ __typename?: 'DonutMixAndCut', id: number }> };

export type DonutMixAndCutQueryVariables = Exact<{
  where: DonutMixAndCutWhereUniqueInput;
}>;


export type DonutMixAndCutQuery = { __typename?: 'Query', donutMixAndCut?: { __typename?: 'DonutMixAndCut', id: number, name: string } | null };

export type DonutMixAndCutsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DonutMixAndCutWhereInput>;
  orderBy?: InputMaybe<Array<DonutMixAndCutOrderByWithRelationInput> | DonutMixAndCutOrderByWithRelationInput>;
}>;


export type DonutMixAndCutsQuery = { __typename?: 'Query', donutMixAndCuts: Array<{ __typename?: 'DonutMixAndCut', id: number, name: string }> };

export type UpdateDonutMixAndCutMutationVariables = Exact<{
  where: DonutMixAndCutWhereUniqueInput;
  data: DonutMixAndCutUpdateInput;
}>;


export type UpdateDonutMixAndCutMutation = { __typename?: 'Mutation', updateDonutMixAndCut: { __typename?: 'DonutMixAndCut', id: number } };

export type DonutsConsolidatedThrowAwayReportItemsQueryVariables = Exact<{
  where?: InputMaybe<ConsolidatedThrowAwayReportItemWhereInput>;
}>;


export type DonutsConsolidatedThrowAwayReportItemsQuery = { __typename?: 'Query', donutsConsolidatedThrowAwayReportItems: Array<{ __typename?: 'DonutsConsolidatedThrowAwayReportItem', date?: any | null, donuts?: Array<{ __typename?: 'DonutThrowAway', name?: string | null, quantity?: number | null }> | null }> };

export type AggregateEndingSdEmTaQueryVariables = Exact<{
  where?: InputMaybe<EndingSdEmTaWhereInput>;
}>;


export type AggregateEndingSdEmTaQuery = { __typename?: 'Query', aggregateEndingSdEmTa: { __typename?: 'AggregateEndingSdEmTa', _count?: { __typename?: 'EndingSdEmTaCountAggregate', _all: number } | null } };

export type AllEndingSdEmTaFieldsFragment = { __typename?: 'EndingSdEmTa', id: number, date: any, formType: FormType, controlNumber: string, deliveryShiftSchedule: DeliveryShiftSchedule, createdBy: string, receivedBy?: string | null, notedBy?: string | null, remarks?: string | null, outlet: { __typename?: 'Outlet', id: number, name: string }, endingSdEmTaCmlItems: Array<{ __typename?: 'EndingSdEmTaCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }>, endingSdEmTaPackagingItems: Array<{ __typename?: 'EndingSdEmTaPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }>, endingSdEmTaRawMaterialItems: Array<{ __typename?: 'EndingSdEmTaRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }>, endingSdEmTaBeverageItems: Array<{ __typename?: 'EndingSdEmTaBeverageItem', id: number, quantity: number, beverage: { __typename?: 'Beverage', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateEndingSdEmTaMutationVariables = Exact<{
  data: EndingSdEmTaCreateInput;
}>;


export type CreateEndingSdEmTaMutation = { __typename?: 'Mutation', createEndingSdEmTa: { __typename?: 'EndingSdEmTa', id: number } };

export type DeleteManyEndingSdEmTaMutationVariables = Exact<{
  where?: InputMaybe<Array<EndingSdEmTaWhereUniqueInput> | EndingSdEmTaWhereUniqueInput>;
}>;


export type DeleteManyEndingSdEmTaMutation = { __typename?: 'Mutation', deleteManyEndingSdEmTa: Array<{ __typename?: 'EndingSdEmTa', id: number }> };

export type EndingSdEmTaQueryVariables = Exact<{
  where: EndingSdEmTaWhereUniqueInput;
}>;


export type EndingSdEmTaQuery = { __typename?: 'Query', endingSdEmTa?: { __typename?: 'EndingSdEmTa', id: number, date: any, formType: FormType, controlNumber: string, deliveryShiftSchedule: DeliveryShiftSchedule, createdBy: string, receivedBy?: string | null, notedBy?: string | null, remarks?: string | null, outlet: { __typename?: 'Outlet', id: number, name: string }, endingSdEmTaCmlItems: Array<{ __typename?: 'EndingSdEmTaCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }>, endingSdEmTaPackagingItems: Array<{ __typename?: 'EndingSdEmTaPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }>, endingSdEmTaRawMaterialItems: Array<{ __typename?: 'EndingSdEmTaRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }>, endingSdEmTaBeverageItems: Array<{ __typename?: 'EndingSdEmTaBeverageItem', id: number, quantity: number, beverage: { __typename?: 'Beverage', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type EndingSdEmTasQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EndingSdEmTaWhereInput>;
  orderBy?: InputMaybe<Array<EndingSdEmTaOrderByWithRelationInput> | EndingSdEmTaOrderByWithRelationInput>;
}>;


export type EndingSdEmTasQuery = { __typename?: 'Query', endingSdEmTas: Array<{ __typename?: 'EndingSdEmTa', id: number, date: any, formType: FormType, controlNumber: string, deliveryShiftSchedule: DeliveryShiftSchedule, createdBy: string, receivedBy?: string | null, notedBy?: string | null, remarks?: string | null, outlet: { __typename?: 'Outlet', id: number, name: string }, endingSdEmTaCmlItems: Array<{ __typename?: 'EndingSdEmTaCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }>, endingSdEmTaPackagingItems: Array<{ __typename?: 'EndingSdEmTaPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }>, endingSdEmTaRawMaterialItems: Array<{ __typename?: 'EndingSdEmTaRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }>, endingSdEmTaBeverageItems: Array<{ __typename?: 'EndingSdEmTaBeverageItem', id: number, quantity: number, beverage: { __typename?: 'Beverage', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdateEndingSdEmTaMutationVariables = Exact<{
  where: EndingSdEmTaWhereUniqueInput;
  data: EndingSdEmTaUpdateInput;
}>;


export type UpdateEndingSdEmTaMutation = { __typename?: 'Mutation', updateEndingSdEmTa: { __typename?: 'EndingSdEmTa', id: number } };

export type AggregateMonthlyOutletPackagingInventoryQueryVariables = Exact<{
  where?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
}>;


export type AggregateMonthlyOutletPackagingInventoryQuery = { __typename?: 'Query', aggregateMonthlyOutletPackagingInventory: { __typename?: 'AggregateMonthlyOutletPackagingInventory', _count?: { __typename?: 'MonthlyOutletPackagingInventoryCountAggregate', _all: number } | null } };

export type AllMonthlyOutletPackagingInventoryFieldsFragment = { __typename?: 'MonthlyOutletPackagingInventory', id: number, date: any, quantity: number, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } };

export type CreateManyMonthlyOutletPackagingInventoryMutationVariables = Exact<{
  data: Array<MonthlyOutletPackagingInventoryCreateInput> | MonthlyOutletPackagingInventoryCreateInput;
}>;


export type CreateManyMonthlyOutletPackagingInventoryMutation = { __typename?: 'Mutation', createManyMonthlyOutletPackagingInventory: { __typename?: 'AffectedRowsOutput', count: number } };

export type CreateMonthlyOutletPackagingInventoryMutationVariables = Exact<{
  data: MonthlyOutletPackagingInventoryCreateInput;
}>;


export type CreateMonthlyOutletPackagingInventoryMutation = { __typename?: 'Mutation', createMonthlyOutletPackagingInventory: { __typename?: 'MonthlyOutletPackagingInventory', id: number } };

export type DeleteManyMonthlyOutletPackagingInventoryMutationVariables = Exact<{
  where?: InputMaybe<Array<MonthlyOutletPackagingInventoryWhereUniqueInput> | MonthlyOutletPackagingInventoryWhereUniqueInput>;
}>;


export type DeleteManyMonthlyOutletPackagingInventoryMutation = { __typename?: 'Mutation', deleteManyMonthlyOutletPackagingInventory: Array<{ __typename?: 'MonthlyOutletPackagingInventory', id: number }> };

export type MonthlyOutletPackagingInventoriesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MonthlyOutletPackagingInventoryWhereInput>;
  orderBy?: InputMaybe<Array<MonthlyOutletPackagingInventoryOrderByWithRelationInput> | MonthlyOutletPackagingInventoryOrderByWithRelationInput>;
}>;


export type MonthlyOutletPackagingInventoriesQuery = { __typename?: 'Query', monthlyOutletPackagingInventories: Array<{ __typename?: 'MonthlyOutletPackagingInventory', id: number, date: any, quantity: number, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type MonthlyOutletPackagingInventoryQueryVariables = Exact<{
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
}>;


export type MonthlyOutletPackagingInventoryQuery = { __typename?: 'Query', monthlyOutletPackagingInventory?: { __typename?: 'MonthlyOutletPackagingInventory', id: number, date: any, quantity: number, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } } | null };

export type UpdateMonthlyOutletPackagingInventoryMutationVariables = Exact<{
  where: MonthlyOutletPackagingInventoryWhereUniqueInput;
  data: MonthlyOutletPackagingInventoryUpdateInput;
}>;


export type UpdateMonthlyOutletPackagingInventoryMutation = { __typename?: 'Mutation', updateMonthlyOutletPackagingInventory: { __typename?: 'MonthlyOutletPackagingInventory', id: number } };

export type AggregateOutletQueryVariables = Exact<{
  where?: InputMaybe<OutletWhereInput>;
}>;


export type AggregateOutletQuery = { __typename?: 'Query', aggregateOutlet: { __typename?: 'AggregateOutlet', _count?: { __typename?: 'OutletCountAggregate', _all: number } | null } };

export type AllOutletFieldsFragment = { __typename?: 'Outlet', id: number, name: string, status: Status, numberOfShifts: NumberOfShifts };

export type CreateOutletMutationVariables = Exact<{
  data: OutletCreateInput;
}>;


export type CreateOutletMutation = { __typename?: 'Mutation', createOutlet: { __typename?: 'Outlet', id: number } };

export type DeleteManyOutletMutationVariables = Exact<{
  where?: InputMaybe<Array<OutletWhereUniqueInput> | OutletWhereUniqueInput>;
}>;


export type DeleteManyOutletMutation = { __typename?: 'Mutation', deleteManyOutlet: Array<{ __typename?: 'Outlet', id: number }> };

export type OutletQueryVariables = Exact<{
  where: OutletWhereUniqueInput;
}>;


export type OutletQuery = { __typename?: 'Query', outlet?: { __typename?: 'Outlet', id: number, name: string, status: Status, numberOfShifts: NumberOfShifts } | null };

export type OutletsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
  orderBy?: InputMaybe<Array<OutletOrderByWithRelationInput> | OutletOrderByWithRelationInput>;
}>;


export type OutletsQuery = { __typename?: 'Query', outlets: Array<{ __typename?: 'Outlet', id: number, name: string, status: Status, numberOfShifts: NumberOfShifts }> };

export type OutletsDropdownQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
  orderBy?: InputMaybe<Array<OutletOrderByWithRelationInput> | OutletOrderByWithRelationInput>;
}>;


export type OutletsDropdownQuery = { __typename?: 'Query', outletsDropdown: Array<{ __typename?: 'Outlet', id: number, name: string, status: Status, numberOfShifts: NumberOfShifts }> };

export type UpdateOutletMutationVariables = Exact<{
  where: OutletWhereUniqueInput;
  data: OutletUpdateInput;
}>;


export type UpdateOutletMutation = { __typename?: 'Mutation', updateOutlet: { __typename?: 'Outlet', id: number } };

export type AggregateOutletFinishingQueryVariables = Exact<{
  where?: InputMaybe<OutletFinishingWhereInput>;
}>;


export type AggregateOutletFinishingQuery = { __typename?: 'Query', aggregateOutletFinishing: { __typename?: 'AggregateOutletFinishing', _count?: { __typename?: 'OutletFinishingCountAggregate', _all: number } | null } };

export type AllOutletFinishingFieldsFragment = { __typename?: 'OutletFinishing', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, outletFinishingItems: Array<{ __typename?: 'OutletFinishingItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateOutletFinishingMutationVariables = Exact<{
  data: OutletFinishingCreateInput;
}>;


export type CreateOutletFinishingMutation = { __typename?: 'Mutation', createOutletFinishing: { __typename?: 'OutletFinishing', id: number } };

export type DeleteManyOutletFinishingMutationVariables = Exact<{
  where?: InputMaybe<Array<OutletFinishingWhereUniqueInput> | OutletFinishingWhereUniqueInput>;
}>;


export type DeleteManyOutletFinishingMutation = { __typename?: 'Mutation', deleteManyOutletFinishing: Array<{ __typename?: 'OutletFinishing', id: number }> };

export type FindFirstOutletFinishingQueryVariables = Exact<{
  where?: InputMaybe<OutletFinishingWhereInput>;
  orderBy?: InputMaybe<Array<OutletFinishingOrderByWithRelationInput> | OutletFinishingOrderByWithRelationInput>;
}>;


export type FindFirstOutletFinishingQuery = { __typename?: 'Query', findFirstOutletFinishing?: { __typename?: 'OutletFinishing', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, outletFinishingItems: Array<{ __typename?: 'OutletFinishingItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type OutletFinishingQueryVariables = Exact<{
  where: OutletFinishingWhereUniqueInput;
}>;


export type OutletFinishingQuery = { __typename?: 'Query', outletFinishing?: { __typename?: 'OutletFinishing', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, outletFinishingItems: Array<{ __typename?: 'OutletFinishingItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type OutletFinishingsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletFinishingWhereInput>;
  orderBy?: InputMaybe<Array<OutletFinishingOrderByWithRelationInput> | OutletFinishingOrderByWithRelationInput>;
}>;


export type OutletFinishingsQuery = { __typename?: 'Query', outletFinishings: Array<{ __typename?: 'OutletFinishing', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, outletFinishingItems: Array<{ __typename?: 'OutletFinishingItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdateOutletFinishingMutationVariables = Exact<{
  where: OutletFinishingWhereUniqueInput;
  data: OutletFinishingUpdateInput;
}>;


export type UpdateOutletFinishingMutation = { __typename?: 'Mutation', updateOutletFinishing: { __typename?: 'OutletFinishing', id: number } };

export type OutletPackagingEndingConsolidationsQueryVariables = Exact<{
  where?: InputMaybe<OutletPackagingEndingConsolidationWhereInput>;
}>;


export type OutletPackagingEndingConsolidationsQuery = { __typename?: 'Query', outletPackagingEndingConsolidations: Array<{ __typename?: 'OutletPackagingEndingConsolidation', packaging?: string | null, category?: string | null, unit?: string | null, quantity?: number | null }> };

export type AggregatePackagingQueryVariables = Exact<{
  where?: InputMaybe<PackagingWhereInput>;
}>;


export type AggregatePackagingQuery = { __typename?: 'Query', aggregatePackaging: { __typename?: 'AggregatePackaging', _count?: { __typename?: 'PackagingCountAggregate', _all: number } | null } };

export type AllPackagingFieldsFragment = { __typename?: 'Packaging', id: number, name: string, status: Status, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> };

export type CreatePackagingMutationVariables = Exact<{
  data: PackagingCreateInput;
}>;


export type CreatePackagingMutation = { __typename?: 'Mutation', createPackaging: { __typename?: 'Packaging', id: number } };

export type DeleteManyPackagingMutationVariables = Exact<{
  where?: InputMaybe<Array<PackagingWhereUniqueInput> | PackagingWhereUniqueInput>;
}>;


export type DeleteManyPackagingMutation = { __typename?: 'Mutation', deleteManyPackaging: Array<{ __typename?: 'Packaging', id: number }> };

export type PackagingQueryVariables = Exact<{
  where: PackagingWhereUniqueInput;
}>;


export type PackagingQuery = { __typename?: 'Query', packaging?: { __typename?: 'Packaging', id: number, name: string, status: Status, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> } | null };

export type PackagingsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingWhereInput>;
  orderBy?: InputMaybe<Array<PackagingOrderByWithRelationInput> | PackagingOrderByWithRelationInput>;
}>;


export type PackagingsQuery = { __typename?: 'Query', packagings: Array<{ __typename?: 'Packaging', id: number, name: string, status: Status, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }> };

export type PackagingsAllowQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingWhereInput>;
  orderBy?: InputMaybe<Array<PackagingOrderByWithRelationInput> | PackagingOrderByWithRelationInput>;
}>;


export type PackagingsAllowQuery = { __typename?: 'Query', packagings: Array<{ __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }> };

export type PackagingsDropdownQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingWhereInput>;
  orderBy?: InputMaybe<Array<PackagingOrderByWithRelationInput> | PackagingOrderByWithRelationInput>;
}>;


export type PackagingsDropdownQuery = { __typename?: 'Query', packagingsDropdown: Array<{ __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }> };

export type UpdatePackagingMutationVariables = Exact<{
  where: PackagingWhereUniqueInput;
  data: PackagingUpdateInput;
}>;


export type UpdatePackagingMutation = { __typename?: 'Mutation', updatePackaging: { __typename?: 'Packaging', id: number } };

export type AllPackagingProductFieldsFragment = { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, packagingCategory: PackagingCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string } };

export type PackagingProductsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingProductWhereInput>;
  orderBy?: InputMaybe<Array<PackagingProductOrderByWithRelationInput> | PackagingProductOrderByWithRelationInput>;
}>;


export type PackagingProductsQuery = { __typename?: 'Query', packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, packagingCategory: PackagingCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }> };

export type PackagingProductsAllowedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingProductWhereInput>;
  orderBy?: InputMaybe<Array<PackagingProductOrderByWithRelationInput> | PackagingProductOrderByWithRelationInput>;
}>;


export type PackagingProductsAllowedQuery = { __typename?: 'Query', packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, packagingCategory: PackagingCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }> };

export type AggregatePackagingRequestQueryVariables = Exact<{
  where?: InputMaybe<PackagingRequestWhereInput>;
}>;


export type AggregatePackagingRequestQuery = { __typename?: 'Query', aggregatePackagingRequest: { __typename?: 'AggregatePackagingRequest', _count?: { __typename?: 'PackagingRequestCountAggregate', _all: number } | null } };

export type AllPackagingRequestFieldsFragment = { __typename?: 'PackagingRequest', id: number, requestDate: any, neededDate: any, controlNumber: string, status: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingRequestItems: Array<{ __typename?: 'PackagingRequestItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreatePackagingRequestMutationVariables = Exact<{
  data: PackagingRequestCreateInput;
}>;


export type CreatePackagingRequestMutation = { __typename?: 'Mutation', createPackagingRequest: { __typename?: 'PackagingRequest', id: number } };

export type DeleteManyPackagingRequestMutationVariables = Exact<{
  where?: InputMaybe<Array<PackagingRequestWhereUniqueInput> | PackagingRequestWhereUniqueInput>;
}>;


export type DeleteManyPackagingRequestMutation = { __typename?: 'Mutation', deleteManyPackagingRequest: Array<{ __typename?: 'PackagingRequest', id: number }> };

export type PackagingRequestQueryVariables = Exact<{
  where: PackagingRequestWhereUniqueInput;
}>;


export type PackagingRequestQuery = { __typename?: 'Query', packagingRequest?: { __typename?: 'PackagingRequest', id: number, requestDate: any, neededDate: any, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingRequestItems: Array<{ __typename?: 'PackagingRequestItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type PackagingRequestsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PackagingRequestWhereInput>;
  orderBy?: InputMaybe<Array<PackagingRequestOrderByWithRelationInput> | PackagingRequestOrderByWithRelationInput>;
}>;


export type PackagingRequestsQuery = { __typename?: 'Query', packagingRequests: Array<{ __typename?: 'PackagingRequest', id: number, requestDate: any, neededDate: any, controlNumber: string, status: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingRequestItems: Array<{ __typename?: 'PackagingRequestItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdatePackagingRequestMutationVariables = Exact<{
  where: PackagingRequestWhereUniqueInput;
  data: PackagingRequestUpdateInput;
}>;


export type UpdatePackagingRequestMutation = { __typename?: 'Mutation', updatePackagingRequest: { __typename?: 'PackagingRequest', id: number } };

export type AggregateProjectionQueryVariables = Exact<{
  where?: InputMaybe<ProjectionWhereInput>;
}>;


export type AggregateProjectionQuery = { __typename?: 'Query', aggregateProjection: { __typename?: 'AggregateProjection', _count?: { __typename?: 'ProjectionCountAggregate', _all: number } | null } };

export type AllProjectionFieldsFragment = { __typename?: 'Projection', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, projectionItems: Array<{ __typename?: 'ProjectionItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, salesCategory: SalesCategory, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateProjectionMutationVariables = Exact<{
  data: ProjectionCreateInput;
}>;


export type CreateProjectionMutation = { __typename?: 'Mutation', createProjection: { __typename?: 'Projection', id: number } };

export type DeleteManyProjectionMutationVariables = Exact<{
  where?: InputMaybe<Array<ProjectionWhereUniqueInput> | ProjectionWhereUniqueInput>;
}>;


export type DeleteManyProjectionMutation = { __typename?: 'Mutation', deleteManyProjection: Array<{ __typename?: 'Projection', id: number }> };

export type ProjectionQueryVariables = Exact<{
  where: ProjectionWhereUniqueInput;
}>;


export type ProjectionQuery = { __typename?: 'Query', projection?: { __typename?: 'Projection', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, projectionItems: Array<{ __typename?: 'ProjectionItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, salesCategory: SalesCategory, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type ProjectionsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectionWhereInput>;
  orderBy?: InputMaybe<Array<ProjectionOrderByWithRelationInput> | ProjectionOrderByWithRelationInput>;
}>;


export type ProjectionsQuery = { __typename?: 'Query', projections: Array<{ __typename?: 'Projection', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, projectionItems: Array<{ __typename?: 'ProjectionItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, salesCategory: SalesCategory, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdateProjectionMutationVariables = Exact<{
  where: ProjectionWhereUniqueInput;
  data: ProjectionUpdateInput;
}>;


export type UpdateProjectionMutation = { __typename?: 'Mutation', updateProjection: { __typename?: 'Projection', id: number } };

export type ProjectionSummaryPerOutletQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectionWhereInput>;
  orderBy?: InputMaybe<Array<ProjectionOrderByWithRelationInput> | ProjectionOrderByWithRelationInput>;
}>;


export type ProjectionSummaryPerOutletQuery = { __typename?: 'Query', projections: Array<{ __typename?: 'Projection', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, projectionSummaryItems: Array<{ __typename?: 'ProjectionSummaryItem', salesCategory: SalesCategory, quantity: number }> }> };

export type ProjectionToRawMaterialsQueryVariables = Exact<{
  where?: InputMaybe<ProjectionToRawMaterialWhereInput>;
}>;


export type ProjectionToRawMaterialsQuery = { __typename?: 'Query', projectionToRawMaterials: Array<{ __typename?: 'ProjectionToRawMaterial', category?: string | null, rawMaterial?: string | null, unit?: string | null, firstDelivery?: number | null, secondDelivery?: number | null, thirdDelivery?: number | null }> };

export type AggregatePurchaseOrderQueryVariables = Exact<{
  where?: InputMaybe<PurchaseOrderWhereInput>;
}>;


export type AggregatePurchaseOrderQuery = { __typename?: 'Query', aggregatePurchaseOrder: { __typename?: 'AggregatePurchaseOrder', _count?: { __typename?: 'PurchaseOrderCountAggregate', _all: number } | null } };

export type AllPurchaseOrderFieldsFragment = { __typename?: 'PurchaseOrder', id: number, salesInvoiceNumber: string, dateArrived: any, purchaseOrderRawMaterialItems: Array<{ __typename?: 'PurchaseOrderRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, purchaseOrderPackagingItems: Array<{ __typename?: 'PurchaseOrderPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreatePurchaseOrderMutationVariables = Exact<{
  data: PurchaseOrderCreateInput;
}>;


export type CreatePurchaseOrderMutation = { __typename?: 'Mutation', createPurchaseOrder: { __typename?: 'PurchaseOrder', id: number } };

export type DeleteManyPurchaseOrderMutationVariables = Exact<{
  where?: InputMaybe<Array<PurchaseOrderWhereUniqueInput> | PurchaseOrderWhereUniqueInput>;
}>;


export type DeleteManyPurchaseOrderMutation = { __typename?: 'Mutation', deleteManyPurchaseOrder: Array<{ __typename?: 'PurchaseOrder', id: number }> };

export type PurchaseOrderQueryVariables = Exact<{
  where: PurchaseOrderWhereUniqueInput;
}>;


export type PurchaseOrderQuery = { __typename?: 'Query', purchaseOrder?: { __typename?: 'PurchaseOrder', id: number, salesInvoiceNumber: string, dateArrived: any, purchaseOrderRawMaterialItems: Array<{ __typename?: 'PurchaseOrderRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, purchaseOrderPackagingItems: Array<{ __typename?: 'PurchaseOrderPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type PurchaseOrdersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderWhereInput>;
  orderBy?: InputMaybe<Array<PurchaseOrderOrderByWithRelationInput> | PurchaseOrderOrderByWithRelationInput>;
}>;


export type PurchaseOrdersQuery = { __typename?: 'Query', purchaseOrders: Array<{ __typename?: 'PurchaseOrder', id: number, salesInvoiceNumber: string, dateArrived: any, purchaseOrderRawMaterialItems: Array<{ __typename?: 'PurchaseOrderRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, purchaseOrderPackagingItems: Array<{ __typename?: 'PurchaseOrderPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdatePurchaseOrderMutationVariables = Exact<{
  where: PurchaseOrderWhereUniqueInput;
  data: PurchaseOrderUpdateInput;
}>;


export type UpdatePurchaseOrderMutation = { __typename?: 'Mutation', updatePurchaseOrder: { __typename?: 'PurchaseOrder', id: number } };

export type AggregateRawMaterialQueryVariables = Exact<{
  where?: InputMaybe<RawMaterialWhereInput>;
}>;


export type AggregateRawMaterialQuery = { __typename?: 'Query', aggregateRawMaterial: { __typename?: 'AggregateRawMaterial', _count?: { __typename?: 'RawMaterialCountAggregate', _all: number } | null } };

export type AllRawMaterialFieldsFragment = { __typename?: 'RawMaterial', id: number, name: string, status: Status, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string } } }> }> };

export type CreateRawMaterialMutationVariables = Exact<{
  data: RawMaterialCreateInput;
}>;


export type CreateRawMaterialMutation = { __typename?: 'Mutation', createRawMaterial: { __typename?: 'RawMaterial', id: number } };

export type DeleteManyRawMaterialMutationVariables = Exact<{
  where?: InputMaybe<Array<RawMaterialWhereUniqueInput> | RawMaterialWhereUniqueInput>;
}>;


export type DeleteManyRawMaterialMutation = { __typename?: 'Mutation', deleteManyRawMaterial: Array<{ __typename?: 'RawMaterial', id: number }> };

export type RawMaterialQueryVariables = Exact<{
  where: RawMaterialWhereUniqueInput;
}>;


export type RawMaterialQuery = { __typename?: 'Query', rawMaterial?: { __typename?: 'RawMaterial', id: number, name: string, status: Status, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string } } }> }> } | null };

export type RawMaterialsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialOrderByWithRelationInput> | RawMaterialOrderByWithRelationInput>;
}>;


export type RawMaterialsQuery = { __typename?: 'Query', rawMaterials: Array<{ __typename?: 'RawMaterial', id: number, name: string, status: Status, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string } } }> }> }> };

export type RawMaterialsAllowQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialOrderByWithRelationInput> | RawMaterialOrderByWithRelationInput>;
}>;


export type RawMaterialsAllowQuery = { __typename?: 'Query', rawMaterials: Array<{ __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }> };

export type RawMaterialsDropdownQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialOrderByWithRelationInput> | RawMaterialOrderByWithRelationInput>;
}>;


export type RawMaterialsDropdownQuery = { __typename?: 'Query', rawMaterialsDropdown: Array<{ __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, conversionToBaseUnit: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }> };

export type UpdateRawMaterialMutationVariables = Exact<{
  where: RawMaterialWhereUniqueInput;
  data: RawMaterialUpdateInput;
}>;


export type UpdateRawMaterialMutation = { __typename?: 'Mutation', updateRawMaterial: { __typename?: 'RawMaterial', id: number } };

export type AggregateRawMaterialFinishingEndingQueryVariables = Exact<{
  where?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
}>;


export type AggregateRawMaterialFinishingEndingQuery = { __typename?: 'Query', aggregateRawMaterialFinishingEnding: { __typename?: 'AggregateRawMaterialFinishingEnding', _count?: { __typename?: 'RawMaterialFinishingEndingCountAggregate', _all: number } | null } };

export type AllRawMaterialFinishingEndingFieldsFragment = { __typename?: 'RawMaterialFinishingEnding', id: number, controlNumber: string, rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, createdBy: string, createdAt: any, remarks?: string | null, outlet?: { __typename?: 'Outlet', id: number, name: string } | null, rawMaterialFinishingEndingItems: Array<{ __typename?: 'RawMaterialFinishingEndingItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateRawMaterialFinishingEndingMutationVariables = Exact<{
  data: RawMaterialFinishingEndingCreateInput;
}>;


export type CreateRawMaterialFinishingEndingMutation = { __typename?: 'Mutation', createRawMaterialFinishingEnding: { __typename?: 'RawMaterialFinishingEnding', id: number } };

export type DeleteManyRawMaterialFinishingEndingMutationVariables = Exact<{
  where?: InputMaybe<Array<RawMaterialFinishingEndingWhereUniqueInput> | RawMaterialFinishingEndingWhereUniqueInput>;
}>;


export type DeleteManyRawMaterialFinishingEndingMutation = { __typename?: 'Mutation', deleteManyRawMaterialFinishingEnding: Array<{ __typename?: 'RawMaterialFinishingEnding', id: number }> };

export type RawMaterialFinishingEndingQueryVariables = Exact<{
  where: RawMaterialFinishingEndingWhereUniqueInput;
}>;


export type RawMaterialFinishingEndingQuery = { __typename?: 'Query', rawMaterialFinishingEnding?: { __typename?: 'RawMaterialFinishingEnding', id: number, controlNumber: string, rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, createdBy: string, createdAt: any, remarks?: string | null, outlet?: { __typename?: 'Outlet', id: number, name: string } | null, rawMaterialFinishingEndingItems: Array<{ __typename?: 'RawMaterialFinishingEndingItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type RawMaterialFinishingEndingsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialFinishingEndingWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialFinishingEndingOrderByWithRelationInput> | RawMaterialFinishingEndingOrderByWithRelationInput>;
}>;


export type RawMaterialFinishingEndingsQuery = { __typename?: 'Query', rawMaterialFinishingEndings: Array<{ __typename?: 'RawMaterialFinishingEnding', id: number, controlNumber: string, rawMaterialFinishingEndingLocation: RawMaterialFinishingEndingLocation, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, createdBy: string, createdAt: any, remarks?: string | null, outlet?: { __typename?: 'Outlet', id: number, name: string } | null, rawMaterialFinishingEndingItems: Array<{ __typename?: 'RawMaterialFinishingEndingItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdateRawMaterialFinishingEndingMutationVariables = Exact<{
  where: RawMaterialFinishingEndingWhereUniqueInput;
  data: RawMaterialFinishingEndingUpdateInput;
}>;


export type UpdateRawMaterialFinishingEndingMutation = { __typename?: 'Mutation', updateRawMaterialFinishingEnding: { __typename?: 'RawMaterialFinishingEnding', id: number } };

export type AggregateRawMaterialProductQueryVariables = Exact<{
  where?: InputMaybe<RawMaterialProductWhereInput>;
}>;


export type AggregateRawMaterialProductQuery = { __typename?: 'Query', aggregateRawMaterialProduct: { __typename?: 'AggregateRawMaterialProduct', _count?: { __typename?: 'RawMaterialProductCountAggregate', _all: number } | null } };

export type AllRawMaterialProductFieldsFragment = { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, rawMaterialCategory: RawMaterialCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string } } }> };

export type DeleteManyRawMaterialProductMutationVariables = Exact<{
  where?: InputMaybe<Array<RawMaterialProductWhereUniqueInput> | RawMaterialProductWhereUniqueInput>;
}>;


export type DeleteManyRawMaterialProductMutation = { __typename?: 'Mutation', deleteManyRawMaterialProduct: Array<{ __typename?: 'RawMaterialProduct', id: number }> };

export type RawMaterialProductQueryVariables = Exact<{
  where: RawMaterialProductWhereUniqueInput;
}>;


export type RawMaterialProductQuery = { __typename?: 'Query', rawMaterialProduct?: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, rawMaterialCategory: RawMaterialCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string } } }> } | null };

export type RawMaterialProductsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialProductOrderByWithRelationInput> | RawMaterialProductOrderByWithRelationInput>;
}>;


export type RawMaterialProductsQuery = { __typename?: 'Query', rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, rawMaterialCategory: RawMaterialCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string } } }> }> };

export type RawMaterialProductsAllowedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialProductOrderByWithRelationInput> | RawMaterialProductOrderByWithRelationInput>;
}>;


export type RawMaterialProductsAllowedQuery = { __typename?: 'Query', rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, rawMaterialCategory: RawMaterialCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }> };

export type UpdateRawMaterialProductMutationVariables = Exact<{
  where: RawMaterialProductWhereUniqueInput;
  data: RawMaterialProductUpdateInput;
}>;


export type UpdateRawMaterialProductMutation = { __typename?: 'Mutation', updateRawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number } };

export type AggregateRawMaterialProductRecipeQueryVariables = Exact<{
  where?: InputMaybe<RawMaterialProductRecipeWhereInput>;
}>;


export type AggregateRawMaterialProductRecipeQuery = { __typename?: 'Query', aggregateRawMaterialProductRecipe: { __typename?: 'AggregateRawMaterialProductRecipe', _count?: { __typename?: 'RawMaterialProductRecipeCountAggregate', _all: number } | null } };

export type AllRawMaterialProductRecipeFieldsFragment = { __typename?: 'RawMaterialProductRecipe', id: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, rawMaterialCategory: RawMaterialCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }> };

export type CreateRawMaterialProductRecipeMutationVariables = Exact<{
  data: RawMaterialProductRecipeCreateInput;
}>;


export type CreateRawMaterialProductRecipeMutation = { __typename?: 'Mutation', createRawMaterialProductRecipe: { __typename?: 'RawMaterialProductRecipe', id: number } };

export type DeleteManyRawMaterialProductRecipeMutationVariables = Exact<{
  where?: InputMaybe<Array<RawMaterialProductRecipeWhereUniqueInput> | RawMaterialProductRecipeWhereUniqueInput>;
}>;


export type DeleteManyRawMaterialProductRecipeMutation = { __typename?: 'Mutation', deleteManyRawMaterialProductRecipe: Array<{ __typename?: 'RawMaterialProductRecipe', id: number }> };

export type RawMaterialProductRecipeQueryVariables = Exact<{
  where: RawMaterialProductRecipeWhereUniqueInput;
}>;


export type RawMaterialProductRecipeQuery = { __typename?: 'Query', rawMaterialProductRecipe?: { __typename?: 'RawMaterialProductRecipe', id: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, rawMaterialCategory: RawMaterialCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }> } | null };

export type RawMaterialProductRecipesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialProductRecipeWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialProductRecipeOrderByWithRelationInput> | RawMaterialProductRecipeOrderByWithRelationInput>;
}>;


export type RawMaterialProductRecipesQuery = { __typename?: 'Query', rawMaterialProductRecipes: Array<{ __typename?: 'RawMaterialProductRecipe', id: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, rawMaterialCategory: RawMaterialCategory, name: string }, unit: { __typename?: 'Unit', id: number, name: string } }, rawMaterialProductIngredients: Array<{ __typename?: 'RawMaterialProductIngredient', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string }, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> } } }> }> };

export type UpdateRawMaterialProductRecipeMutationVariables = Exact<{
  where: RawMaterialProductRecipeWhereUniqueInput;
  data: RawMaterialProductRecipeUpdateInput;
}>;


export type UpdateRawMaterialProductRecipeMutation = { __typename?: 'Mutation', updateRawMaterialProductRecipe: { __typename?: 'RawMaterialProductRecipe', id: number } };

export type AggregateRawMaterialReleaseQueryVariables = Exact<{
  where?: InputMaybe<RawMaterialReleaseWhereInput>;
}>;


export type AggregateRawMaterialReleaseQuery = { __typename?: 'Query', aggregateRawMaterialRelease: { __typename?: 'AggregateRawMaterialRelease', _count?: { __typename?: 'RawMaterialReleaseCountAggregate', _all: number } | null } };

export type AllRawMaterialReleaseFieldsFragment = { __typename?: 'RawMaterialRelease', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, destination: string, rawMaterialReleaseItems: Array<{ __typename?: 'RawMaterialReleaseItem', id: number, quantity: number, referenceQuantity: number, referenceUnit: string, fromBreakdown: boolean, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, rawMaterialReleaseForBreakdowns: Array<{ __typename?: 'RawMaterialReleaseForBreakdown', id: number, quantity: number, referenceQuantity: number, referenceUnit: string, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, rawMaterialReleasePackagingItems: Array<{ __typename?: 'RawMaterialReleasePackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateRawMaterialReleaseMutationVariables = Exact<{
  data: RawMaterialReleaseCreateInput;
}>;


export type CreateRawMaterialReleaseMutation = { __typename?: 'Mutation', createRawMaterialRelease: { __typename?: 'RawMaterialRelease', id: number } };

export type DeleteManyRawMaterialReleaseMutationVariables = Exact<{
  where?: InputMaybe<Array<RawMaterialReleaseWhereUniqueInput> | RawMaterialReleaseWhereUniqueInput>;
}>;


export type DeleteManyRawMaterialReleaseMutation = { __typename?: 'Mutation', deleteManyRawMaterialRelease: Array<{ __typename?: 'RawMaterialRelease', id: number }> };

export type RawMaterialReleaseQueryVariables = Exact<{
  where: RawMaterialReleaseWhereUniqueInput;
}>;


export type RawMaterialReleaseQuery = { __typename?: 'Query', rawMaterialRelease?: { __typename?: 'RawMaterialRelease', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, destination: string, rawMaterialReleaseItems: Array<{ __typename?: 'RawMaterialReleaseItem', id: number, quantity: number, referenceQuantity: number, referenceUnit: string, fromBreakdown: boolean, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, rawMaterialReleaseForBreakdowns: Array<{ __typename?: 'RawMaterialReleaseForBreakdown', id: number, quantity: number, referenceQuantity: number, referenceUnit: string, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, rawMaterialReleasePackagingItems: Array<{ __typename?: 'RawMaterialReleasePackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type RawMaterialReleaseForBreakdownReferencesQueryVariables = Exact<{
  data: RawMaterialReleaseReferenceInput;
}>;


export type RawMaterialReleaseForBreakdownReferencesQuery = { __typename?: 'Query', rawMaterialReleaseForBreakdownReferences: Array<{ __typename?: 'RawMaterialReleaseReference', referenceQuantity: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, referenceUnit: { __typename?: 'Unit', id: number, name: string } }> };

export type RawMaterialReleaseItemFromBreakdownReferencesQueryVariables = Exact<{
  data: Array<RawMaterialReleaseItemsFromBreakdownInput> | RawMaterialReleaseItemsFromBreakdownInput;
}>;


export type RawMaterialReleaseItemFromBreakdownReferencesQuery = { __typename?: 'Query', rawMaterialReleaseItemFromBreakdownReferences: Array<{ __typename?: 'RawMaterialReleaseReference', referenceQuantity: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, referenceUnit: { __typename?: 'Unit', id: number, name: string } }> };

export type RawMaterialReleaseItemReferencesQueryVariables = Exact<{
  data: RawMaterialReleaseReferenceInput;
}>;


export type RawMaterialReleaseItemReferencesQuery = { __typename?: 'Query', rawMaterialReleaseItemReferences: Array<{ __typename?: 'RawMaterialReleaseReference', referenceQuantity: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, referenceUnit: { __typename?: 'Unit', id: number, name: string } }> };

export type RawMaterialReleasesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialReleaseWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialReleaseOrderByWithRelationInput> | RawMaterialReleaseOrderByWithRelationInput>;
}>;


export type RawMaterialReleasesQuery = { __typename?: 'Query', rawMaterialReleases: Array<{ __typename?: 'RawMaterialRelease', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, destination: string, rawMaterialReleaseItems: Array<{ __typename?: 'RawMaterialReleaseItem', id: number, quantity: number, referenceQuantity: number, referenceUnit: string, fromBreakdown: boolean, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, rawMaterialReleaseForBreakdowns: Array<{ __typename?: 'RawMaterialReleaseForBreakdown', id: number, quantity: number, referenceQuantity: number, referenceUnit: string, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, rawMaterialReleasePackagingItems: Array<{ __typename?: 'RawMaterialReleasePackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdateRawMaterialReleaseMutationVariables = Exact<{
  where: RawMaterialReleaseWhereUniqueInput;
  data: RawMaterialReleaseUpdateInput;
}>;


export type UpdateRawMaterialReleaseMutation = { __typename?: 'Mutation', updateRawMaterialRelease: { __typename?: 'RawMaterialRelease', id: number } };

export type AggregateRawMaterialRequestQueryVariables = Exact<{
  where?: InputMaybe<RawMaterialRequestWhereInput>;
}>;


export type AggregateRawMaterialRequestQuery = { __typename?: 'Query', aggregateRawMaterialRequest: { __typename?: 'AggregateRawMaterialRequest', _count?: { __typename?: 'RawMaterialRequestCountAggregate', _all: number } | null } };

export type AllRawMaterialRequestFieldsFragment = { __typename?: 'RawMaterialRequest', id: number, requestDate: any, neededDate: any, controlNumber: string, status: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, rawMaterialRequestItems: Array<{ __typename?: 'RawMaterialRequestItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateRawMaterialRequestMutationVariables = Exact<{
  data: RawMaterialRequestCreateInput;
}>;


export type CreateRawMaterialRequestMutation = { __typename?: 'Mutation', createRawMaterialRequest: { __typename?: 'RawMaterialRequest', id: number } };

export type DeleteManyRawMaterialRequestMutationVariables = Exact<{
  where?: InputMaybe<Array<RawMaterialRequestWhereUniqueInput> | RawMaterialRequestWhereUniqueInput>;
}>;


export type DeleteManyRawMaterialRequestMutation = { __typename?: 'Mutation', deleteManyRawMaterialRequest: Array<{ __typename?: 'RawMaterialRequest', id: number }> };

export type RawMaterialRequestQueryVariables = Exact<{
  where: RawMaterialRequestWhereUniqueInput;
}>;


export type RawMaterialRequestQuery = { __typename?: 'Query', rawMaterialRequest?: { __typename?: 'RawMaterialRequest', id: number, requestDate: any, neededDate: any, controlNumber: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, rawMaterialRequestItems: Array<{ __typename?: 'RawMaterialRequestItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type RawMaterialRequestsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RawMaterialRequestWhereInput>;
  orderBy?: InputMaybe<Array<RawMaterialRequestOrderByWithRelationInput> | RawMaterialRequestOrderByWithRelationInput>;
}>;


export type RawMaterialRequestsQuery = { __typename?: 'Query', rawMaterialRequests: Array<{ __typename?: 'RawMaterialRequest', id: number, requestDate: any, neededDate: any, controlNumber: string, status: string, createdBy: string, outlet: { __typename?: 'Outlet', id: number, name: string }, rawMaterialRequestItems: Array<{ __typename?: 'RawMaterialRequestItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, baseUnit: boolean, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdateRawMaterialRequestMutationVariables = Exact<{
  where: RawMaterialRequestWhereUniqueInput;
  data: RawMaterialRequestUpdateInput;
}>;


export type UpdateRawMaterialRequestMutation = { __typename?: 'Mutation', updateRawMaterialRequest: { __typename?: 'RawMaterialRequest', id: number } };

export type RawMaterialsConsolidatedThrowAwayReportItemsQueryVariables = Exact<{
  where?: InputMaybe<ConsolidatedThrowAwayReportItemWhereInput>;
}>;


export type RawMaterialsConsolidatedThrowAwayReportItemsQuery = { __typename?: 'Query', rawMaterialsConsolidatedThrowAwayReportItems: Array<{ __typename?: 'RawMaterialsConsolidatedThrowAwayReportItem', date?: any | null, rawMaterials?: Array<{ __typename?: 'RawMaterialThrowAway', name?: string | null, quantity?: number | null }> | null }> };

export type RawMaterialsSalesProductControlReportItemsQueryVariables = Exact<{
  where?: InputMaybe<SalesProductControlReportItemWhereInput>;
}>;


export type RawMaterialsSalesProductControlReportItemsQuery = { __typename?: 'Query', rawMaterialsSalesProductControlReportItems: Array<{ __typename?: 'RawMaterialsSalesProductControlReportItem', rawMaterial?: string | null, unit?: string | null, beginningQuantity?: number | null, deliveriesQuantity?: number | null, transferInQuantity?: number | null, transferOutQuantity?: number | null, emQuantity?: number | null, sdQuantity?: number | null, taQuantity?: number | null, endingQuantity?: number | null, usage?: number | null, crtRawMaterialItemQuantity?: number | null, variance?: number | null }> };

export type AggregateReceivedDeliveryReceiptQueryVariables = Exact<{
  where?: InputMaybe<ReceivedDeliveryReceiptWhereInput>;
}>;


export type AggregateReceivedDeliveryReceiptQuery = { __typename?: 'Query', aggregateReceivedDeliveryReceipt: { __typename?: 'AggregateReceivedDeliveryReceipt', _count?: { __typename?: 'ReceivedDeliveryReceiptCountAggregate', _all: number } | null } };

export type AllReceivedDeliveryReceiptFieldsFragment = { __typename?: 'ReceivedDeliveryReceipt', id: number, dateTime: any, receivedBy: string, deliveryReceipt: { __typename?: 'DeliveryReceipt', id: number, controlNumber: string, outlet: { __typename?: 'Outlet', id: number, name: string } }, receivedDeliveryReceiptItems: Array<{ __typename?: 'ReceivedDeliveryReceiptItem', id: number, receivedQuantity: number, rejectedQuantity: number, deliveryReceiptItem: { __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } } }> };

export type CreateReceivedDeliveryReceiptMutationVariables = Exact<{
  data: ReceivedDeliveryReceiptCreateInput;
}>;


export type CreateReceivedDeliveryReceiptMutation = { __typename?: 'Mutation', createReceivedDeliveryReceipt: { __typename?: 'ReceivedDeliveryReceipt', id: number } };

export type DeleteManyReceivedDeliveryReceiptMutationVariables = Exact<{
  where?: InputMaybe<Array<ReceivedDeliveryReceiptWhereUniqueInput> | ReceivedDeliveryReceiptWhereUniqueInput>;
}>;


export type DeleteManyReceivedDeliveryReceiptMutation = { __typename?: 'Mutation', deleteManyReceivedDeliveryReceipt: Array<{ __typename?: 'ReceivedDeliveryReceipt', id: number }> };

export type ReceivedDeliveryReceiptQueryVariables = Exact<{
  where: ReceivedDeliveryReceiptWhereUniqueInput;
}>;


export type ReceivedDeliveryReceiptQuery = { __typename?: 'Query', receivedDeliveryReceipt?: { __typename?: 'ReceivedDeliveryReceipt', id: number, dateTime: any, receivedBy: string, deliveryReceipt: { __typename?: 'DeliveryReceipt', id: number, controlNumber: string, outlet: { __typename?: 'Outlet', id: number, name: string } }, receivedDeliveryReceiptItems: Array<{ __typename?: 'ReceivedDeliveryReceiptItem', id: number, receivedQuantity: number, rejectedQuantity: number, deliveryReceiptItem: { __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } } }> } | null };

export type ReceivedDeliveryReceiptsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedDeliveryReceiptWhereInput>;
  orderBy?: InputMaybe<Array<ReceivedDeliveryReceiptOrderByWithRelationInput> | ReceivedDeliveryReceiptOrderByWithRelationInput>;
}>;


export type ReceivedDeliveryReceiptsQuery = { __typename?: 'Query', receivedDeliveryReceipts: Array<{ __typename?: 'ReceivedDeliveryReceipt', id: number, dateTime: any, receivedBy: string, deliveryReceipt: { __typename?: 'DeliveryReceipt', id: number, controlNumber: string, outlet: { __typename?: 'Outlet', id: number, name: string } }, receivedDeliveryReceiptItems: Array<{ __typename?: 'ReceivedDeliveryReceiptItem', id: number, receivedQuantity: number, rejectedQuantity: number, deliveryReceiptItem: { __typename?: 'DeliveryReceiptItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string }, donutMixAndCut: { __typename?: 'DonutMixAndCut', id: number, name: string } } } }> }> };

export type UpdateReceivedDeliveryReceiptMutationVariables = Exact<{
  where: ReceivedDeliveryReceiptWhereUniqueInput;
  data: ReceivedDeliveryReceiptUpdateInput;
}>;


export type UpdateReceivedDeliveryReceiptMutation = { __typename?: 'Mutation', updateReceivedDeliveryReceipt: { __typename?: 'ReceivedDeliveryReceipt', id: number } };

export type AggregateReceivedSdsDeliveryReceiptQueryVariables = Exact<{
  where?: InputMaybe<ReceivedSdsDeliveryReceiptWhereInput>;
}>;


export type AggregateReceivedSdsDeliveryReceiptQuery = { __typename?: 'Query', aggregateReceivedSdsDeliveryReceipt: { __typename?: 'AggregateReceivedSdsDeliveryReceipt', _count?: { __typename?: 'ReceivedSdsDeliveryReceiptCountAggregate', _all: number } | null } };

export type AllReceivedSdsDeliveryReceiptFieldsFragment = { __typename?: 'ReceivedSdsDeliveryReceipt', id: number, dateTime: any, deliveryShiftSchedule: DeliveryShiftSchedule, receivedBy: string, sdsDeliveryReceipt: { __typename?: 'SdsDeliveryReceipt', id: number, controlNumber: string, outlet: { __typename?: 'Outlet', id: number, name: string } }, receivedSdsDeliveryReceiptPackagingItems: Array<{ __typename?: 'ReceivedSdsDeliveryReceiptPackagingItem', id: number, receivedQuantity: number, rejectedQuantity: number, sdsDeliveryReceiptPackagingItem: { __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedSdsDeliveryReceiptRawMaterialItems: Array<{ __typename?: 'ReceivedSdsDeliveryReceiptRawMaterialItem', id: number, receivedQuantity: number, rejectedQuantity: number, sdsDeliveryReceiptRawMaterialItem: { __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } } }> };

export type CreateReceivedSdsDeliveryReceiptMutationVariables = Exact<{
  data: ReceivedSdsDeliveryReceiptCreateInput;
}>;


export type CreateReceivedSdsDeliveryReceiptMutation = { __typename?: 'Mutation', createReceivedSdsDeliveryReceipt: { __typename?: 'ReceivedSdsDeliveryReceipt', id: number } };

export type DeleteManyReceivedSdsDeliveryReceiptMutationVariables = Exact<{
  where?: InputMaybe<Array<ReceivedSdsDeliveryReceiptWhereUniqueInput> | ReceivedSdsDeliveryReceiptWhereUniqueInput>;
}>;


export type DeleteManyReceivedSdsDeliveryReceiptMutation = { __typename?: 'Mutation', deleteManyReceivedSdsDeliveryReceipt: Array<{ __typename?: 'ReceivedSdsDeliveryReceipt', id: number }> };

export type ReceivedSdsDeliveryReceiptQueryVariables = Exact<{
  where: ReceivedSdsDeliveryReceiptWhereUniqueInput;
}>;


export type ReceivedSdsDeliveryReceiptQuery = { __typename?: 'Query', receivedSdsDeliveryReceipt?: { __typename?: 'ReceivedSdsDeliveryReceipt', id: number, dateTime: any, deliveryShiftSchedule: DeliveryShiftSchedule, receivedBy: string, sdsDeliveryReceipt: { __typename?: 'SdsDeliveryReceipt', id: number, controlNumber: string, outlet: { __typename?: 'Outlet', id: number, name: string } }, receivedSdsDeliveryReceiptPackagingItems: Array<{ __typename?: 'ReceivedSdsDeliveryReceiptPackagingItem', id: number, receivedQuantity: number, rejectedQuantity: number, sdsDeliveryReceiptPackagingItem: { __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedSdsDeliveryReceiptRawMaterialItems: Array<{ __typename?: 'ReceivedSdsDeliveryReceiptRawMaterialItem', id: number, receivedQuantity: number, rejectedQuantity: number, sdsDeliveryReceiptRawMaterialItem: { __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } } }> } | null };

export type ReceivedSdsDeliveryReceiptsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedSdsDeliveryReceiptWhereInput>;
  orderBy?: InputMaybe<Array<ReceivedSdsDeliveryReceiptOrderByWithRelationInput> | ReceivedSdsDeliveryReceiptOrderByWithRelationInput>;
}>;


export type ReceivedSdsDeliveryReceiptsQuery = { __typename?: 'Query', receivedSdsDeliveryReceipts: Array<{ __typename?: 'ReceivedSdsDeliveryReceipt', id: number, dateTime: any, deliveryShiftSchedule: DeliveryShiftSchedule, receivedBy: string, sdsDeliveryReceipt: { __typename?: 'SdsDeliveryReceipt', id: number, controlNumber: string, outlet: { __typename?: 'Outlet', id: number, name: string } }, receivedSdsDeliveryReceiptPackagingItems: Array<{ __typename?: 'ReceivedSdsDeliveryReceiptPackagingItem', id: number, receivedQuantity: number, rejectedQuantity: number, sdsDeliveryReceiptPackagingItem: { __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedSdsDeliveryReceiptRawMaterialItems: Array<{ __typename?: 'ReceivedSdsDeliveryReceiptRawMaterialItem', id: number, receivedQuantity: number, rejectedQuantity: number, sdsDeliveryReceiptRawMaterialItem: { __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } } }> }> };

export type UpdateReceivedSdsDeliveryReceiptMutationVariables = Exact<{
  where: ReceivedSdsDeliveryReceiptWhereUniqueInput;
  data: ReceivedSdsDeliveryReceiptUpdateInput;
}>;


export type UpdateReceivedSdsDeliveryReceiptMutation = { __typename?: 'Mutation', updateReceivedSdsDeliveryReceipt: { __typename?: 'ReceivedSdsDeliveryReceipt', id: number } };

export type AggregateReceivedTransferQueryVariables = Exact<{
  where?: InputMaybe<ReceivedTransferWhereInput>;
}>;


export type AggregateReceivedTransferQuery = { __typename?: 'Query', aggregateReceivedTransfer: { __typename?: 'AggregateReceivedTransfer', _count?: { __typename?: 'ReceivedTransferCountAggregate', _all: number } | null } };

export type AllReceivedTransferFieldsFragment = { __typename?: 'ReceivedTransfer', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, receivedBy: string, transfer: { __typename?: 'Transfer', id: number, controlNumber: string, fromOutlet: { __typename?: 'Outlet', id: number, name: string }, toOutlet: { __typename?: 'Outlet', id: number, name: string } }, receivedTransferCmlItems: Array<{ __typename?: 'ReceivedTransferCmlItem', id: number, quantity: number, transferCmlItem: { __typename?: 'TransferCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedTransferRawMaterialItems: Array<{ __typename?: 'ReceivedTransferRawMaterialItem', id: number, quantity: number, transferRawMaterialItem: { __typename?: 'TransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedTransferPackagingItems: Array<{ __typename?: 'ReceivedTransferPackagingItem', id: number, quantity: number, transferPackagingItem: { __typename?: 'TransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } } }> };

export type CreateReceivedTransferMutationVariables = Exact<{
  data: ReceivedTransferCreateInput;
}>;


export type CreateReceivedTransferMutation = { __typename?: 'Mutation', createReceivedTransfer: { __typename?: 'ReceivedTransfer', id: number } };

export type DeleteManyReceivedTransferMutationVariables = Exact<{
  where?: InputMaybe<Array<ReceivedTransferWhereUniqueInput> | ReceivedTransferWhereUniqueInput>;
}>;


export type DeleteManyReceivedTransferMutation = { __typename?: 'Mutation', deleteManyReceivedTransfer: Array<{ __typename?: 'ReceivedTransfer', id: number }> };

export type ReceivedTransferQueryVariables = Exact<{
  where: ReceivedTransferWhereUniqueInput;
}>;


export type ReceivedTransferQuery = { __typename?: 'Query', receivedTransfer?: { __typename?: 'ReceivedTransfer', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, receivedBy: string, transfer: { __typename?: 'Transfer', id: number, controlNumber: string, fromOutlet: { __typename?: 'Outlet', id: number, name: string }, toOutlet: { __typename?: 'Outlet', id: number, name: string } }, receivedTransferCmlItems: Array<{ __typename?: 'ReceivedTransferCmlItem', id: number, quantity: number, transferCmlItem: { __typename?: 'TransferCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedTransferRawMaterialItems: Array<{ __typename?: 'ReceivedTransferRawMaterialItem', id: number, quantity: number, transferRawMaterialItem: { __typename?: 'TransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedTransferPackagingItems: Array<{ __typename?: 'ReceivedTransferPackagingItem', id: number, quantity: number, transferPackagingItem: { __typename?: 'TransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } } }> } | null };

export type ReceivedTransfersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedTransferWhereInput>;
  orderBy?: InputMaybe<Array<ReceivedTransferOrderByWithRelationInput> | ReceivedTransferOrderByWithRelationInput>;
}>;


export type ReceivedTransfersQuery = { __typename?: 'Query', receivedTransfers: Array<{ __typename?: 'ReceivedTransfer', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, receivedBy: string, transfer: { __typename?: 'Transfer', id: number, controlNumber: string, fromOutlet: { __typename?: 'Outlet', id: number, name: string }, toOutlet: { __typename?: 'Outlet', id: number, name: string } }, receivedTransferCmlItems: Array<{ __typename?: 'ReceivedTransferCmlItem', id: number, quantity: number, transferCmlItem: { __typename?: 'TransferCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedTransferRawMaterialItems: Array<{ __typename?: 'ReceivedTransferRawMaterialItem', id: number, quantity: number, transferRawMaterialItem: { __typename?: 'TransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } } }>, receivedTransferPackagingItems: Array<{ __typename?: 'ReceivedTransferPackagingItem', id: number, quantity: number, transferPackagingItem: { __typename?: 'TransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } } }> }> };

export type UpdateReceivedTransferMutationVariables = Exact<{
  where: ReceivedTransferWhereUniqueInput;
  data: ReceivedTransferUpdateInput;
}>;


export type UpdateReceivedTransferMutation = { __typename?: 'Mutation', updateReceivedTransfer: { __typename?: 'ReceivedTransfer', id: number } };

export type SalesPackagingControlReportItemsQueryVariables = Exact<{
  where?: InputMaybe<SalesPackagingControlReportItemWhereInput>;
}>;


export type SalesPackagingControlReportItemsQuery = { __typename?: 'Query', salesPackagingControlReportItems: Array<{ __typename?: 'SalesPackagingControlReportItem', packaging?: string | null, category?: string | null, unit?: string | null, beginningQuantity?: number | null, deliveriesQuantity?: number | null, transferInQuantity?: number | null, transferOutQuantity?: number | null, emQuantity?: number | null, sdQuantity?: number | null, taQuantity?: number | null, endingQuantity?: number | null, usage?: number | null, crtPackagingItemQuantity?: number | null, variance?: number | null }> };

export type AggregateSdsDeliveryReceiptQueryVariables = Exact<{
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
}>;


export type AggregateSdsDeliveryReceiptQuery = { __typename?: 'Query', aggregateSdsDeliveryReceipt: { __typename?: 'AggregateSdsDeliveryReceipt', _count?: { __typename?: 'SdsDeliveryReceiptCountAggregate', _all: number } | null } };

export type AllSdsDeliveryReceiptFieldsFragment = { __typename?: 'SdsDeliveryReceipt', id: number, date: any, controlNumber: string, createdBy: string, deliveredBy?: string | null, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingRequest?: { __typename?: 'PackagingRequest', id: number, controlNumber: string } | null, rawMaterialRequest?: { __typename?: 'RawMaterialRequest', id: number, controlNumber: string } | null, receivedSdsDeliveryReceipt?: { __typename?: 'ReceivedSdsDeliveryReceipt', id: number, receivedBy: string } | null, sdsDeliveryReceiptRawMaterialItems: Array<{ __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, sdsDeliveryReceiptPackagingItems: Array<{ __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateSdsDeliveryReceiptMutationVariables = Exact<{
  data: SdsDeliveryReceiptCreateInput;
}>;


export type CreateSdsDeliveryReceiptMutation = { __typename?: 'Mutation', createSdsDeliveryReceipt: { __typename?: 'SdsDeliveryReceipt', id: number } };

export type DeleteManySdsDeliveryReceiptMutationVariables = Exact<{
  where?: InputMaybe<Array<SdsDeliveryReceiptWhereUniqueInput> | SdsDeliveryReceiptWhereUniqueInput>;
}>;


export type DeleteManySdsDeliveryReceiptMutation = { __typename?: 'Mutation', deleteManySdsDeliveryReceipt: Array<{ __typename?: 'SdsDeliveryReceipt', id: number }> };

export type SdsDeliveryReceiptQueryVariables = Exact<{
  where: SdsDeliveryReceiptWhereUniqueInput;
}>;


export type SdsDeliveryReceiptQuery = { __typename?: 'Query', sdsDeliveryReceipt?: { __typename?: 'SdsDeliveryReceipt', id: number, date: any, controlNumber: string, createdBy: string, deliveredBy?: string | null, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingRequest?: { __typename?: 'PackagingRequest', id: number, controlNumber: string } | null, rawMaterialRequest?: { __typename?: 'RawMaterialRequest', id: number, controlNumber: string } | null, receivedSdsDeliveryReceipt?: { __typename?: 'ReceivedSdsDeliveryReceipt', id: number, receivedBy: string } | null, sdsDeliveryReceiptRawMaterialItems: Array<{ __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, sdsDeliveryReceiptPackagingItems: Array<{ __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type SdsDeliveryReceiptsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptOrderByWithRelationInput> | SdsDeliveryReceiptOrderByWithRelationInput>;
}>;


export type SdsDeliveryReceiptsQuery = { __typename?: 'Query', sdsDeliveryReceipts: Array<{ __typename?: 'SdsDeliveryReceipt', id: number, date: any, controlNumber: string, createdBy: string, deliveredBy?: string | null, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingRequest?: { __typename?: 'PackagingRequest', id: number, controlNumber: string } | null, rawMaterialRequest?: { __typename?: 'RawMaterialRequest', id: number, controlNumber: string } | null, receivedSdsDeliveryReceipt?: { __typename?: 'ReceivedSdsDeliveryReceipt', id: number, receivedBy: string } | null, sdsDeliveryReceiptRawMaterialItems: Array<{ __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, sdsDeliveryReceiptPackagingItems: Array<{ __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type SdsDeliveryReceiptsDropdownQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptWhereInput>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptOrderByWithRelationInput> | SdsDeliveryReceiptOrderByWithRelationInput>;
}>;


export type SdsDeliveryReceiptsDropdownQuery = { __typename?: 'Query', sdsDeliveryReceiptsDropdown: Array<{ __typename?: 'SdsDeliveryReceipt', id: number, date: any, controlNumber: string, createdBy: string, deliveredBy?: string | null, outlet: { __typename?: 'Outlet', id: number, name: string }, packagingRequest?: { __typename?: 'PackagingRequest', id: number, controlNumber: string } | null, rawMaterialRequest?: { __typename?: 'RawMaterialRequest', id: number, controlNumber: string } | null, receivedSdsDeliveryReceipt?: { __typename?: 'ReceivedSdsDeliveryReceipt', id: number, receivedBy: string } | null, sdsDeliveryReceiptRawMaterialItems: Array<{ __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }>, sdsDeliveryReceiptPackagingItems: Array<{ __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdateSdsDeliveryReceiptMutationVariables = Exact<{
  where: SdsDeliveryReceiptWhereUniqueInput;
  data: SdsDeliveryReceiptUpdateInput;
}>;


export type UpdateSdsDeliveryReceiptMutation = { __typename?: 'Mutation', updateSdsDeliveryReceipt: { __typename?: 'SdsDeliveryReceipt', id: number } };

export type AllSdsDeliveryReceiptPackagingItemFieldsFragment = { __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } };

export type SdsDeliveryReceiptPackagingItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptPackagingItemWhereInput>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptPackagingItemOrderByWithRelationInput> | SdsDeliveryReceiptPackagingItemOrderByWithRelationInput>;
}>;


export type SdsDeliveryReceiptPackagingItemsQuery = { __typename?: 'Query', sdsDeliveryReceiptPackagingItems: Array<{ __typename?: 'SdsDeliveryReceiptPackagingItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory, packagingProducts: Array<{ __typename?: 'PackagingProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type AllSdsDeliveryReceiptRawMaterialItemFieldsFragment = { __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } };

export type SdsDeliveryReceiptRawMaterialItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SdsDeliveryReceiptRawMaterialItemWhereInput>;
  orderBy?: InputMaybe<Array<SdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput> | SdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput>;
}>;


export type SdsDeliveryReceiptRawMaterialItemsQuery = { __typename?: 'Query', sdsDeliveryReceiptRawMaterialItems: Array<{ __typename?: 'SdsDeliveryReceiptRawMaterialItem', id: number, quantity: number, referenceQuantity?: number | null, referenceUnit?: string | null, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory, rawMaterialProducts: Array<{ __typename?: 'RawMaterialProduct', id: number, unit: { __typename?: 'Unit', id: number, name: string } }> }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type TotalDeliveryReceiptSummaryQueryVariables = Exact<{
  where?: InputMaybe<TotalDeliveryReceiptSummaryWhereInput>;
}>;


export type TotalDeliveryReceiptSummaryQuery = { __typename?: 'Query', totalDeliveryReceiptSummary: Array<{ __typename?: 'TotalDeliveryReceiptSummary', donutMixAndCut?: string | null, deliveryReceiptSummaries?: Array<{ __typename?: 'DeliveryReceiptSummary', quantity?: number | null, cmlProduct?: { __typename?: 'CmlProduct', id: number, name: string } | null }> | null }> };

export type AggregateTransferQueryVariables = Exact<{
  where?: InputMaybe<TransferWhereInput>;
}>;


export type AggregateTransferQuery = { __typename?: 'Query', aggregateTransfer: { __typename?: 'AggregateTransfer', _count?: { __typename?: 'TransferCountAggregate', _all: number } | null } };

export type AllTransferFieldsFragment = { __typename?: 'Transfer', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, receivedByDriver?: string | null, receivedTransfer?: { __typename?: 'ReceivedTransfer', id: number, receivedBy: string } | null, fromOutlet: { __typename?: 'Outlet', id: number, name: string }, toOutlet: { __typename?: 'Outlet', id: number, name: string }, transferCmlItems: Array<{ __typename?: 'TransferCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }>, transferRawMaterialItems: Array<{ __typename?: 'TransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }>, transferPackagingItems: Array<{ __typename?: 'TransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateTransferMutationVariables = Exact<{
  data: TransferCreateInput;
}>;


export type CreateTransferMutation = { __typename?: 'Mutation', createTransfer: { __typename?: 'Transfer', id: number } };

export type DeleteManyTransferMutationVariables = Exact<{
  where?: InputMaybe<Array<TransferWhereUniqueInput> | TransferWhereUniqueInput>;
}>;


export type DeleteManyTransferMutation = { __typename?: 'Mutation', deleteManyTransfer: Array<{ __typename?: 'Transfer', id: number }> };

export type TransferQueryVariables = Exact<{
  where: TransferWhereUniqueInput;
}>;


export type TransferQuery = { __typename?: 'Query', transfer?: { __typename?: 'Transfer', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, receivedByDriver?: string | null, receivedTransfer?: { __typename?: 'ReceivedTransfer', id: number, receivedBy: string } | null, fromOutlet: { __typename?: 'Outlet', id: number, name: string }, toOutlet: { __typename?: 'Outlet', id: number, name: string }, transferCmlItems: Array<{ __typename?: 'TransferCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }>, transferRawMaterialItems: Array<{ __typename?: 'TransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }>, transferPackagingItems: Array<{ __typename?: 'TransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type TransfersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferWhereInput>;
  orderBy?: InputMaybe<Array<TransferOrderByWithRelationInput> | TransferOrderByWithRelationInput>;
}>;


export type TransfersQuery = { __typename?: 'Query', transfers: Array<{ __typename?: 'Transfer', id: number, date: any, deliveryShiftSchedule: DeliveryShiftSchedule, controlNumber: string, createdBy: string, receivedByDriver?: string | null, receivedTransfer?: { __typename?: 'ReceivedTransfer', id: number, receivedBy: string } | null, fromOutlet: { __typename?: 'Outlet', id: number, name: string }, toOutlet: { __typename?: 'Outlet', id: number, name: string }, transferCmlItems: Array<{ __typename?: 'TransferCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }>, transferRawMaterialItems: Array<{ __typename?: 'TransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }>, transferPackagingItems: Array<{ __typename?: 'TransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type UpdateTransferMutationVariables = Exact<{
  where: TransferWhereUniqueInput;
  data: TransferUpdateInput;
}>;


export type UpdateTransferMutation = { __typename?: 'Mutation', updateTransfer: { __typename?: 'Transfer', id: number } };

export type AllTransferCmlItemFieldsFragment = { __typename?: 'TransferCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } };

export type TransferCmlItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferCmlItemWhereInput>;
  orderBy?: InputMaybe<Array<TransferCmlItemOrderByWithRelationInput> | TransferCmlItemOrderByWithRelationInput>;
}>;


export type TransferCmlItemsQuery = { __typename?: 'Query', transferCmlItems: Array<{ __typename?: 'TransferCmlItem', id: number, quantity: number, cmlProduct: { __typename?: 'CmlProduct', id: number, name: string, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type AllTransferPackagingItemFieldsFragment = { __typename?: 'TransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } };

export type TransferPackagingItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferPackagingItemWhereInput>;
  orderBy?: InputMaybe<Array<TransferPackagingItemOrderByWithRelationInput> | TransferPackagingItemOrderByWithRelationInput>;
}>;


export type TransferPackagingItemsQuery = { __typename?: 'Query', transferPackagingItems: Array<{ __typename?: 'TransferPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type AllTransferRawMaterialItemFieldsFragment = { __typename?: 'TransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } };

export type TransferRawMaterialItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransferRawMaterialItemWhereInput>;
  orderBy?: InputMaybe<Array<TransferRawMaterialItemOrderByWithRelationInput> | TransferRawMaterialItemOrderByWithRelationInput>;
}>;


export type TransferRawMaterialItemsQuery = { __typename?: 'Query', transferRawMaterialItems: Array<{ __typename?: 'TransferRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type AggregateUnitQueryVariables = Exact<{
  where?: InputMaybe<UnitWhereInput>;
}>;


export type AggregateUnitQuery = { __typename?: 'Query', aggregateUnit: { __typename?: 'AggregateUnit', _count?: { __typename?: 'UnitCountAggregate', _all: number } | null } };

export type AllUnitFieldsFragment = { __typename?: 'Unit', id: number, name: string };

export type CreateUnitMutationVariables = Exact<{
  data: UnitCreateInput;
}>;


export type CreateUnitMutation = { __typename?: 'Mutation', createUnit: { __typename?: 'Unit', id: number } };

export type DeleteManyUnitMutationVariables = Exact<{
  where?: InputMaybe<Array<UnitWhereUniqueInput> | UnitWhereUniqueInput>;
}>;


export type DeleteManyUnitMutation = { __typename?: 'Mutation', deleteManyUnit: Array<{ __typename?: 'Unit', id: number }> };

export type UnitQueryVariables = Exact<{
  where: UnitWhereUniqueInput;
}>;


export type UnitQuery = { __typename?: 'Query', unit?: { __typename?: 'Unit', id: number, name: string } | null };

export type UnitsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UnitWhereInput>;
  orderBy?: InputMaybe<Array<UnitOrderByWithRelationInput> | UnitOrderByWithRelationInput>;
}>;


export type UnitsQuery = { __typename?: 'Query', units: Array<{ __typename?: 'Unit', id: number, name: string }> };

export type UpdateUnitMutationVariables = Exact<{
  where: UnitWhereUniqueInput;
  data: UnitUpdateInput;
}>;


export type UpdateUnitMutation = { __typename?: 'Mutation', updateUnit: { __typename?: 'Unit', id: number } };

export type AggregateUserQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
}>;


export type AggregateUserQuery = { __typename?: 'Query', aggregateUser: { __typename?: 'AggregateUser', _count?: { __typename?: 'UserCountAggregate', _all: number } | null } };

export type AllUserFieldsFragment = { __typename?: 'User', id: number, username: string, name: string, userGroups: Array<UserGroup>, outlets: Array<{ __typename?: 'Outlet', id: number, name: string }> };

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: number, username: string, name: string, userGroups: Array<UserGroup>, outlets: Array<{ __typename?: 'Outlet', id: number }> } | null };

export type DeleteManyUserMutationVariables = Exact<{
  where?: InputMaybe<Array<UserWhereUniqueInput> | UserWhereUniqueInput>;
}>;


export type DeleteManyUserMutation = { __typename?: 'Mutation', deleteManyUser: Array<{ __typename?: 'User', id: number }> };

export type SigninMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SigninMutation = { __typename?: 'Mutation', signin: { __typename?: 'User', id: number, username: string } };

export type SignoutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignoutMutation = { __typename?: 'Mutation', signout: string };

export type UpdateUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number } };

export type UserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: number, username: string, name: string, userGroups: Array<UserGroup>, outlets: Array<{ __typename?: 'Outlet', id: number, name: string }> } | null };

export type UsersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, username: string, name: string, userGroups: Array<UserGroup>, outlets: Array<{ __typename?: 'Outlet', id: number, name: string }> }> };

export type AggregateVarianceReportOnCmlTransferQueryVariables = Exact<{
  where?: InputMaybe<VarianceReportOnCmlTransferWhereInput>;
}>;


export type AggregateVarianceReportOnCmlTransferQuery = { __typename?: 'Query', aggregateVarianceReportOnCmlTransfer: { __typename?: 'AggregateTransferCmlItem', _count?: { __typename?: 'TransferCmlItemCountAggregate', _all: number } | null } };

export type VarianceReportOnCmlTransfersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnCmlTransferWhereInput>;
}>;


export type VarianceReportOnCmlTransfersQuery = { __typename?: 'Query', varianceReportOnCmlTransfers: Array<{ __typename?: 'VarianceReportOnCmlTransfer', date?: any | null, deliveryShiftSchedule?: string | null, fromOutlet?: string | null, toOutlet?: string | null, transferNumber?: string | null, receivedBy?: string | null, donutMixAndCut?: string | null, cmlProduct?: string | null, quantity?: number | null, unit?: string | null, receivedQuantity?: number | null, variance?: number | null }> };

export type AggregateVarianceReportOnDeliveryReceiptItemQueryVariables = Exact<{
  where?: InputMaybe<VarianceReportOnDeliveryReceiptItemWhereInput>;
}>;


export type AggregateVarianceReportOnDeliveryReceiptItemQuery = { __typename?: 'Query', aggregateVarianceReportOnDeliveryReceiptItem: { __typename?: 'AggregateDeliveryReceiptItem', _count?: { __typename?: 'DeliveryReceiptItemCountAggregate', _all: number } | null } };

export type VarianceReportOnDeliveryReceiptItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnDeliveryReceiptItemWhereInput>;
}>;


export type VarianceReportOnDeliveryReceiptItemsQuery = { __typename?: 'Query', varianceReportOnDeliveryReceiptItems: Array<{ __typename?: 'VarianceReportOnDeliveryReceiptItem', date?: any | null, deliveryShiftSchedule?: string | null, outlet?: string | null, deliveryReceiptNumber?: string | null, receivedBy?: string | null, donutMixAndCut?: string | null, cmlProduct?: string | null, quantity?: number | null, unit?: string | null, received?: number | null, rejected?: number | null, variance?: number | null }> };

export type AggregateVarianceReportOnPackagingTransferQueryVariables = Exact<{
  where?: InputMaybe<VarianceReportOnPackagingTransferWhereInput>;
}>;


export type AggregateVarianceReportOnPackagingTransferQuery = { __typename?: 'Query', aggregateVarianceReportOnPackagingTransfer: { __typename?: 'AggregateTransferPackagingItem', _count?: { __typename?: 'TransferPackagingItemCountAggregate', _all: number } | null } };

export type VarianceReportOnPackagingTransfersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnPackagingTransferWhereInput>;
}>;


export type VarianceReportOnPackagingTransfersQuery = { __typename?: 'Query', varianceReportOnPackagingTransfers: Array<{ __typename?: 'VarianceReportOnPackagingTransfer', date?: any | null, deliveryShiftSchedule?: string | null, fromOutlet?: string | null, toOutlet?: string | null, transferNumber?: string | null, receivedBy?: string | null, category?: string | null, packaging?: string | null, quantity?: number | null, unit?: string | null, receivedQuantity?: number | null, variance?: number | null }> };

export type AggregateVarianceReportOnRawMaterialTransferQueryVariables = Exact<{
  where?: InputMaybe<VarianceReportOnRawMaterialTransferWhereInput>;
}>;


export type AggregateVarianceReportOnRawMaterialTransferQuery = { __typename?: 'Query', aggregateVarianceReportOnRawMaterialTransfer: { __typename?: 'AggregateTransferRawMaterialItem', _count?: { __typename?: 'TransferRawMaterialItemCountAggregate', _all: number } | null } };

export type VarianceReportOnRawMaterialTransfersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnRawMaterialTransferWhereInput>;
}>;


export type VarianceReportOnRawMaterialTransfersQuery = { __typename?: 'Query', varianceReportOnRawMaterialTransfers: Array<{ __typename?: 'VarianceReportOnRawMaterialTransfer', date?: any | null, deliveryShiftSchedule?: string | null, fromOutlet?: string | null, toOutlet?: string | null, transferNumber?: string | null, receivedBy?: string | null, category?: string | null, rawMaterial?: string | null, quantity?: number | null, unit?: string | null, receivedQuantity?: number | null, variance?: number | null }> };

export type AggregateVarianceReportOnSdsPackagingItemQueryVariables = Exact<{
  where?: InputMaybe<VarianceReportOnSdsPackagingItemWhereInput>;
}>;


export type AggregateVarianceReportOnSdsPackagingItemQuery = { __typename?: 'Query', aggregateVarianceReportOnSdsPackagingItem: { __typename?: 'AggregateSdsDeliveryReceiptPackagingItem', _count?: { __typename?: 'SdsDeliveryReceiptPackagingItemCountAggregate', _all: number } | null } };

export type VarianceReportOnSdsPackagingItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnSdsPackagingItemWhereInput>;
}>;


export type VarianceReportOnSdsPackagingItemsQuery = { __typename?: 'Query', varianceReportOnSdsPackagingItems: Array<{ __typename?: 'VarianceReportOnSdsPackagingItem', date?: any | null, deliveryShiftSchedule?: string | null, outlet?: string | null, deliveryReceiptNumber?: string | null, receivedBy?: string | null, category?: string | null, packaging?: string | null, quantity?: number | null, unit?: string | null, received?: number | null, rejected?: number | null, variance?: number | null }> };

export type AggregateVarianceReportOnSdsRawMaterialItemQueryVariables = Exact<{
  where?: InputMaybe<VarianceReportOnSdsRawMaterialItemWhereInput>;
}>;


export type AggregateVarianceReportOnSdsRawMaterialItemQuery = { __typename?: 'Query', aggregateVarianceReportOnSdsRawMaterialItem: { __typename?: 'AggregateSdsDeliveryReceiptRawMaterialItem', _count?: { __typename?: 'SdsDeliveryReceiptRawMaterialItemCountAggregate', _all: number } | null } };

export type VarianceReportOnSdsRawMaterialItemsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VarianceReportOnSdsRawMaterialItemWhereInput>;
}>;


export type VarianceReportOnSdsRawMaterialItemsQuery = { __typename?: 'Query', varianceReportOnSdsRawMaterialItems: Array<{ __typename?: 'VarianceReportOnSdsRawMaterialItem', date?: any | null, deliveryShiftSchedule?: string | null, outlet?: string | null, deliveryReceiptNumber?: string | null, receivedBy?: string | null, category?: string | null, rawMaterial?: string | null, quantity?: number | null, unit?: string | null, received?: number | null, rejected?: number | null, variance?: number | null }> };

export type AggregateWarehouseInventoryQueryVariables = Exact<{
  where?: InputMaybe<WarehouseInventoryWhereInput>;
}>;


export type AggregateWarehouseInventoryQuery = { __typename?: 'Query', aggregateWarehouseInventory: { __typename?: 'AggregateWarehouseInventory', _count?: { __typename?: 'WarehouseInventoryCountAggregate', _all: number } | null } };

export type AllWarehouseInventoryFieldsFragment = { __typename?: 'WarehouseInventory', id: number, dateTime: any, createdBy: string, remarks?: string | null, warehouseInventoryRawMaterialItems: Array<{ __typename?: 'WarehouseInventoryRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, warehouseInventoryPackagingItems: Array<{ __typename?: 'WarehouseInventoryPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }> };

export type CreateWarehouseInventoryMutationVariables = Exact<{
  data: WarehouseInventoryCreateInput;
}>;


export type CreateWarehouseInventoryMutation = { __typename?: 'Mutation', createWarehouseInventory: { __typename?: 'WarehouseInventory', id: number } };

export type DeleteManyWarehouseInventoryMutationVariables = Exact<{
  where?: InputMaybe<Array<WarehouseInventoryWhereUniqueInput> | WarehouseInventoryWhereUniqueInput>;
}>;


export type DeleteManyWarehouseInventoryMutation = { __typename?: 'Mutation', deleteManyWarehouseInventory: Array<{ __typename?: 'WarehouseInventory', id: number }> };

export type UpdateWarehouseInventoryMutationVariables = Exact<{
  where: WarehouseInventoryWhereUniqueInput;
  data: WarehouseInventoryUpdateInput;
}>;


export type UpdateWarehouseInventoryMutation = { __typename?: 'Mutation', updateWarehouseInventory: { __typename?: 'WarehouseInventory', id: number } };

export type WarehouseInventoriesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WarehouseInventoryWhereInput>;
  orderBy?: InputMaybe<Array<WarehouseInventoryOrderByWithRelationInput> | WarehouseInventoryOrderByWithRelationInput>;
}>;


export type WarehouseInventoriesQuery = { __typename?: 'Query', warehouseInventories: Array<{ __typename?: 'WarehouseInventory', id: number, dateTime: any, createdBy: string, remarks?: string | null, warehouseInventoryRawMaterialItems: Array<{ __typename?: 'WarehouseInventoryRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, warehouseInventoryPackagingItems: Array<{ __typename?: 'WarehouseInventoryPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }> }> };

export type WarehouseInventoryQueryVariables = Exact<{
  where: WarehouseInventoryWhereUniqueInput;
}>;


export type WarehouseInventoryQuery = { __typename?: 'Query', warehouseInventory?: { __typename?: 'WarehouseInventory', id: number, dateTime: any, createdBy: string, remarks?: string | null, warehouseInventoryRawMaterialItems: Array<{ __typename?: 'WarehouseInventoryRawMaterialItem', id: number, quantity: number, rawMaterialProduct: { __typename?: 'RawMaterialProduct', id: number, rawMaterial: { __typename?: 'RawMaterial', id: number, name: string, rawMaterialCategory: RawMaterialCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }>, warehouseInventoryPackagingItems: Array<{ __typename?: 'WarehouseInventoryPackagingItem', id: number, quantity: number, packagingProduct: { __typename?: 'PackagingProduct', id: number, packaging: { __typename?: 'Packaging', id: number, name: string, packagingCategory: PackagingCategory }, unit: { __typename?: 'Unit', id: number, name: string } } }> } | null };

export type WarehousePackagingInventoryReportItemsQueryVariables = Exact<{
  where?: InputMaybe<WarehousePackagingInventoryReportItemWhereInput>;
}>;


export type WarehousePackagingInventoryReportItemsQuery = { __typename?: 'Query', warehousePackagingInventoryReportItems: Array<{ __typename?: 'WarehousePackagingInventoryReportItem', packaging?: string | null, category?: string | null, unit?: string | null, beginningQuantity?: number | null, purchaseQuantity?: number | null, transferInQuantity?: number | null, transferOutQuantity?: number | null, releasedQuantity?: number | null, throwAwayQuantity?: number | null, shouldBeQuantity?: number | null, actualEndingQuantity?: number | null, variance?: number | null }> };

export type WarehouseRawMaterialInventoryReportItemsQueryVariables = Exact<{
  where?: InputMaybe<WarehouseRawMaterialInventoryReportItemWhereInput>;
}>;


export type WarehouseRawMaterialInventoryReportItemsQuery = { __typename?: 'Query', warehouseRawMaterialInventoryReportItems: Array<{ __typename?: 'WarehouseRawMaterialInventoryReportItem', rawMaterial?: string | null, category?: string | null, unit?: string | null, beginningQuantity?: number | null, purchaseQuantity?: number | null, transferInQuantity?: number | null, transferOutQuantity?: number | null, releasedQuantity?: number | null, throwAwayQuantity?: number | null, shouldBeQuantity?: number | null, actualEndingQuantity?: number | null, variance?: number | null, finEndingQuantity?: number | null, emptiesQuantity?: number | null }> };

export const AllBetweenEntityTransferFieldsFragmentDoc = gql`
    fragment AllBetweenEntityTransferFields on BetweenEntityTransfer {
  id
  date
  transferDirection
  controlNumber
  origin
  destination
  receivedBy
  createdBy
  betweenEntityTransferRawMaterialItems {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
  betweenEntityTransferPackagingItems {
    id
    quantity
    packagingProduct {
      id
      packaging {
        id
        name
        packagingProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllBeverageFieldsFragmentDoc = gql`
    fragment AllBeverageFields on Beverage {
  id
  name
  unit {
    id
    name
  }
  rawMaterialProduct {
    id
    rawMaterial {
      id
      name
    }
    unit {
      id
      name
    }
  }
  yield
  ozInMedium
  ozInLarge
  ozInXl
  status
}
    `;
export const AllCheatSheetFieldsFragmentDoc = gql`
    fragment AllCheatSheetFields on CheatSheet {
  id
  instructions
  userGroup
}
    `;
export const AllCmlProductFieldsFragmentDoc = gql`
    fragment AllCmlProductFields on CmlProduct {
  id
  name
  productCategory
  donutMixAndCut {
    id
    name
  }
  salesCategory
  unit {
    id
    name
  }
  status
  latestCmlProductIngredients {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllCmlProductRecipeFieldsFragmentDoc = gql`
    fragment AllCmlProductRecipeFields on CmlProductRecipe {
  id
  date
  cmlProduct {
    id
    name
    donutMixAndCut {
      id
      name
    }
    unit {
      id
      name
    }
  }
  cmlProductIngredients {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllCmlThrowAwayFieldsFragmentDoc = gql`
    fragment AllCmlThrowAwayFields on CmlThrowAway {
  id
  date
  deliveryShiftSchedule
  controlNumber
  cmlThrowAwayLocation
  createdBy
  checkedBy
  cmlThrowAwayCmlItems {
    id
    quantity
    checkedQuantity
    cmlProduct {
      id
      name
    }
  }
  cmlThrowAwayRawMaterialItems {
    id
    quantity
    checkedQuantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
  cmlThrowAwayPackagingItems {
    id
    quantity
    checkedQuantity
    packagingProduct {
      id
      packaging {
        id
        name
        packagingProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllCrtFieldsFragmentDoc = gql`
    fragment AllCrtFields on Crt {
  id
  date
  deliveryShiftSchedule
  outlet {
    id
    name
  }
  crtCmlItems {
    id
    quantity
    salesCategory
  }
  crtRawMaterialItems {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialCategory
      }
      unit {
        id
        name
      }
    }
  }
  crtPackagingItems {
    id
    quantity
    packagingProduct {
      id
      packaging {
        id
        name
        packagingCategory
      }
      unit {
        id
        name
      }
    }
  }
  crtBeverageItems {
    id
    beverage {
      id
      name
    }
    cupsSoldMedium
    cupsSoldLarge
    cupsSoldXl
  }
}
    `;
export const AllDeliveryReceiptItemFieldsFragmentDoc = gql`
    fragment AllDeliveryReceiptItemFields on DeliveryReceiptItem {
  id
  quantity
  cmlProduct {
    id
    name
    unit {
      id
      name
    }
    donutMixAndCut {
      id
      name
    }
  }
}
    `;
export const AllDeliveryReceiptFieldsFragmentDoc = gql`
    fragment AllDeliveryReceiptFields on DeliveryReceipt {
  id
  date
  deliveryShiftSchedule
  controlNumber
  outlet {
    id
    name
  }
  receivedByDriver
  ydmFryingTime
  yrcFryingTime
  chmFryingTime
  cdmFryingTime
  pymFryingTime
  basketReleasedQuantity
  screenSeparatorReleasedQuantity
  createdBy
  deliveryReceiptItems {
    ...AllDeliveryReceiptItemFields
  }
}
    ${AllDeliveryReceiptItemFieldsFragmentDoc}`;
export const AllDonutMixAndCutFieldsFragmentDoc = gql`
    fragment AllDonutMixAndCutFields on DonutMixAndCut {
  id
  name
}
    `;
export const AllEndingSdEmTaFieldsFragmentDoc = gql`
    fragment AllEndingSdEmTaFields on EndingSdEmTa {
  id
  date
  formType
  controlNumber
  deliveryShiftSchedule
  createdBy
  receivedBy
  notedBy
  remarks
  outlet {
    id
    name
  }
  endingSdEmTaCmlItems {
    id
    quantity
    cmlProduct {
      id
      name
      unit {
        id
        name
      }
    }
  }
  endingSdEmTaPackagingItems {
    id
    quantity
    packagingProduct {
      id
      unit {
        id
        name
      }
      packaging {
        id
        name
        packagingProducts {
          id
          unit {
            id
            name
          }
        }
      }
    }
  }
  endingSdEmTaRawMaterialItems {
    id
    quantity
    rawMaterialProduct {
      id
      unit {
        id
        name
      }
      rawMaterial {
        id
        name
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
    }
  }
  endingSdEmTaBeverageItems {
    id
    quantity
    beverage {
      id
      name
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllMonthlyOutletPackagingInventoryFieldsFragmentDoc = gql`
    fragment AllMonthlyOutletPackagingInventoryFields on MonthlyOutletPackagingInventory {
  id
  date
  quantity
  outlet {
    id
    name
  }
  packagingProduct {
    id
    packaging {
      id
      name
      packagingCategory
      packagingProducts {
        id
        unit {
          id
          name
        }
      }
    }
    unit {
      id
      name
    }
  }
}
    `;
export const AllOutletFieldsFragmentDoc = gql`
    fragment AllOutletFields on Outlet {
  id
  name
  status
  numberOfShifts
}
    `;
export const AllOutletFinishingFieldsFragmentDoc = gql`
    fragment AllOutletFinishingFields on OutletFinishing {
  id
  date
  deliveryShiftSchedule
  controlNumber
  outlet {
    id
    name
  }
  createdBy
  outletFinishingItems {
    id
    quantity
    cmlProduct {
      id
      name
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllPackagingFieldsFragmentDoc = gql`
    fragment AllPackagingFields on Packaging {
  id
  name
  status
  packagingCategory
  packagingProducts {
    id
    unit {
      id
      name
    }
    conversionToBaseUnit
    baseUnit
  }
}
    `;
export const AllPackagingProductFieldsFragmentDoc = gql`
    fragment AllPackagingProductFields on PackagingProduct {
  id
  packaging {
    id
    packagingCategory
    name
  }
  unit {
    id
    name
  }
}
    `;
export const AllPackagingRequestFieldsFragmentDoc = gql`
    fragment AllPackagingRequestFields on PackagingRequest {
  id
  requestDate
  neededDate
  controlNumber
  outlet {
    id
    name
  }
  status
  createdBy
  packagingRequestItems {
    id
    quantity
    packagingProduct {
      id
      packaging {
        id
        name
        packagingProducts {
          id
          baseUnit
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllProjectionFieldsFragmentDoc = gql`
    fragment AllProjectionFields on Projection {
  id
  date
  deliveryShiftSchedule
  controlNumber
  outlet {
    id
    name
  }
  createdBy
  projectionItems {
    id
    quantity
    cmlProduct {
      id
      name
      salesCategory
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllPurchaseOrderFieldsFragmentDoc = gql`
    fragment AllPurchaseOrderFields on PurchaseOrder {
  id
  salesInvoiceNumber
  dateArrived
  purchaseOrderRawMaterialItems {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
  purchaseOrderPackagingItems {
    id
    quantity
    packagingProduct {
      id
      packaging {
        id
        name
        packagingProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllRawMaterialFieldsFragmentDoc = gql`
    fragment AllRawMaterialFields on RawMaterial {
  id
  name
  status
  rawMaterialCategory
  rawMaterialProducts {
    id
    conversionToBaseUnit
    baseUnit
    unit {
      id
      name
    }
    rawMaterialProductIngredients {
      id
      quantity
      rawMaterialProduct {
        id
        baseUnit
        unit {
          id
          name
        }
        rawMaterial {
          id
          name
        }
      }
    }
  }
}
    `;
export const AllRawMaterialFinishingEndingFieldsFragmentDoc = gql`
    fragment AllRawMaterialFinishingEndingFields on RawMaterialFinishingEnding {
  id
  controlNumber
  rawMaterialFinishingEndingLocation
  outlet {
    id
    name
  }
  date
  deliveryShiftSchedule
  createdBy
  createdAt
  remarks
  rawMaterialFinishingEndingItems {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialCategory
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllRawMaterialProductFieldsFragmentDoc = gql`
    fragment AllRawMaterialProductFields on RawMaterialProduct {
  id
  rawMaterial {
    id
    rawMaterialCategory
    name
  }
  unit {
    id
    name
  }
  rawMaterialProductIngredients {
    id
    quantity
    rawMaterialProduct {
      id
      unit {
        id
        name
      }
      rawMaterial {
        id
        name
      }
    }
  }
}
    `;
export const AllRawMaterialProductRecipeFieldsFragmentDoc = gql`
    fragment AllRawMaterialProductRecipeFields on RawMaterialProductRecipe {
  id
  rawMaterialProduct {
    id
    rawMaterial {
      id
      rawMaterialCategory
      name
    }
    unit {
      id
      name
    }
  }
  rawMaterialProductIngredients {
    id
    quantity
    rawMaterialProduct {
      id
      unit {
        id
        name
      }
      rawMaterial {
        id
        name
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export const AllRawMaterialReleaseFieldsFragmentDoc = gql`
    fragment AllRawMaterialReleaseFields on RawMaterialRelease {
  id
  date
  deliveryShiftSchedule
  destination
  rawMaterialReleaseItems {
    id
    quantity
    referenceQuantity
    referenceUnit
    fromBreakdown
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialCategory
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
  rawMaterialReleaseForBreakdowns {
    id
    quantity
    referenceQuantity
    referenceUnit
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialCategory
        rawMaterialProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
  rawMaterialReleasePackagingItems {
    id
    quantity
    packagingProduct {
      id
      packaging {
        id
        name
        packagingCategory
        packagingProducts {
          id
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllRawMaterialRequestFieldsFragmentDoc = gql`
    fragment AllRawMaterialRequestFields on RawMaterialRequest {
  id
  requestDate
  neededDate
  controlNumber
  status
  outlet {
    id
    name
  }
  createdBy
  rawMaterialRequestItems {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialProducts {
          id
          baseUnit
          unit {
            id
            name
          }
        }
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllReceivedDeliveryReceiptFieldsFragmentDoc = gql`
    fragment AllReceivedDeliveryReceiptFields on ReceivedDeliveryReceipt {
  id
  dateTime
  deliveryReceipt {
    id
    controlNumber
    outlet {
      id
      name
    }
  }
  receivedBy
  receivedDeliveryReceiptItems {
    id
    receivedQuantity
    rejectedQuantity
    deliveryReceiptItem {
      ...AllDeliveryReceiptItemFields
    }
  }
}
    ${AllDeliveryReceiptItemFieldsFragmentDoc}`;
export const AllSdsDeliveryReceiptPackagingItemFieldsFragmentDoc = gql`
    fragment AllSdsDeliveryReceiptPackagingItemFields on SdsDeliveryReceiptPackagingItem {
  id
  quantity
  referenceQuantity
  referenceUnit
  packagingProduct {
    id
    packaging {
      id
      name
      packagingCategory
      packagingProducts {
        id
        unit {
          id
          name
        }
      }
    }
    unit {
      id
      name
    }
  }
}
    `;
export const AllSdsDeliveryReceiptRawMaterialItemFieldsFragmentDoc = gql`
    fragment AllSdsDeliveryReceiptRawMaterialItemFields on SdsDeliveryReceiptRawMaterialItem {
  id
  quantity
  referenceQuantity
  referenceUnit
  rawMaterialProduct {
    id
    rawMaterial {
      id
      name
      rawMaterialCategory
      rawMaterialProducts {
        id
        unit {
          id
          name
        }
      }
    }
    unit {
      id
      name
    }
  }
}
    `;
export const AllReceivedSdsDeliveryReceiptFieldsFragmentDoc = gql`
    fragment AllReceivedSdsDeliveryReceiptFields on ReceivedSdsDeliveryReceipt {
  id
  dateTime
  sdsDeliveryReceipt {
    id
    controlNumber
    outlet {
      id
      name
    }
  }
  deliveryShiftSchedule
  receivedBy
  receivedSdsDeliveryReceiptPackagingItems {
    id
    receivedQuantity
    rejectedQuantity
    sdsDeliveryReceiptPackagingItem {
      ...AllSdsDeliveryReceiptPackagingItemFields
    }
  }
  receivedSdsDeliveryReceiptRawMaterialItems {
    id
    receivedQuantity
    rejectedQuantity
    sdsDeliveryReceiptRawMaterialItem {
      ...AllSdsDeliveryReceiptRawMaterialItemFields
    }
  }
}
    ${AllSdsDeliveryReceiptPackagingItemFieldsFragmentDoc}
${AllSdsDeliveryReceiptRawMaterialItemFieldsFragmentDoc}`;
export const AllTransferCmlItemFieldsFragmentDoc = gql`
    fragment AllTransferCmlItemFields on TransferCmlItem {
  id
  quantity
  cmlProduct {
    id
    name
    unit {
      id
      name
    }
  }
}
    `;
export const AllTransferRawMaterialItemFieldsFragmentDoc = gql`
    fragment AllTransferRawMaterialItemFields on TransferRawMaterialItem {
  id
  quantity
  rawMaterialProduct {
    id
    rawMaterial {
      id
      name
    }
    unit {
      id
      name
    }
  }
}
    `;
export const AllTransferPackagingItemFieldsFragmentDoc = gql`
    fragment AllTransferPackagingItemFields on TransferPackagingItem {
  id
  quantity
  packagingProduct {
    id
    packaging {
      id
      name
    }
    unit {
      id
      name
    }
  }
}
    `;
export const AllReceivedTransferFieldsFragmentDoc = gql`
    fragment AllReceivedTransferFields on ReceivedTransfer {
  id
  date
  deliveryShiftSchedule
  receivedBy
  transfer {
    id
    controlNumber
    fromOutlet {
      id
      name
    }
    toOutlet {
      id
      name
    }
  }
  receivedTransferCmlItems {
    id
    quantity
    transferCmlItem {
      ...AllTransferCmlItemFields
    }
  }
  receivedTransferRawMaterialItems {
    id
    quantity
    transferRawMaterialItem {
      ...AllTransferRawMaterialItemFields
    }
  }
  receivedTransferPackagingItems {
    id
    quantity
    transferPackagingItem {
      ...AllTransferPackagingItemFields
    }
  }
}
    ${AllTransferCmlItemFieldsFragmentDoc}
${AllTransferRawMaterialItemFieldsFragmentDoc}
${AllTransferPackagingItemFieldsFragmentDoc}`;
export const AllSdsDeliveryReceiptFieldsFragmentDoc = gql`
    fragment AllSdsDeliveryReceiptFields on SdsDeliveryReceipt {
  id
  date
  controlNumber
  outlet {
    id
    name
  }
  packagingRequest {
    id
    controlNumber
  }
  rawMaterialRequest {
    id
    controlNumber
  }
  createdBy
  deliveredBy
  receivedSdsDeliveryReceipt {
    id
    receivedBy
  }
  sdsDeliveryReceiptRawMaterialItems {
    ...AllSdsDeliveryReceiptRawMaterialItemFields
  }
  sdsDeliveryReceiptPackagingItems {
    ...AllSdsDeliveryReceiptPackagingItemFields
  }
}
    ${AllSdsDeliveryReceiptRawMaterialItemFieldsFragmentDoc}
${AllSdsDeliveryReceiptPackagingItemFieldsFragmentDoc}`;
export const AllTransferFieldsFragmentDoc = gql`
    fragment AllTransferFields on Transfer {
  id
  date
  deliveryShiftSchedule
  controlNumber
  createdBy
  receivedByDriver
  receivedTransfer {
    id
    receivedBy
  }
  fromOutlet {
    id
    name
  }
  toOutlet {
    id
    name
  }
  transferCmlItems {
    id
    quantity
    cmlProduct {
      id
      name
      unit {
        id
        name
      }
    }
  }
  transferRawMaterialItems {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
      }
      unit {
        id
        name
      }
    }
  }
  transferPackagingItems {
    id
    quantity
    packagingProduct {
      id
      packaging {
        id
        name
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AllUnitFieldsFragmentDoc = gql`
    fragment AllUnitFields on Unit {
  id
  name
}
    `;
export const AllUserFieldsFragmentDoc = gql`
    fragment AllUserFields on User {
  id
  username
  name
  userGroups
  outlets {
    id
    name
  }
}
    `;
export const AllWarehouseInventoryFieldsFragmentDoc = gql`
    fragment AllWarehouseInventoryFields on WarehouseInventory {
  id
  dateTime
  createdBy
  remarks
  warehouseInventoryRawMaterialItems {
    id
    quantity
    rawMaterialProduct {
      id
      rawMaterial {
        id
        name
        rawMaterialCategory
      }
      unit {
        id
        name
      }
    }
  }
  warehouseInventoryPackagingItems {
    id
    quantity
    packagingProduct {
      id
      packaging {
        id
        name
        packagingCategory
      }
      unit {
        id
        name
      }
    }
  }
}
    `;
export const AggregateAuditTrailDocument = gql`
    query AggregateAuditTrail($where: AuditTrailWhereInput) {
  aggregateAuditTrail(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateAuditTrailQuery__
 *
 * To run a query within a React component, call `useAggregateAuditTrailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateAuditTrailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateAuditTrailQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateAuditTrailQuery(baseOptions?: Apollo.QueryHookOptions<AggregateAuditTrailQuery, AggregateAuditTrailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateAuditTrailQuery, AggregateAuditTrailQueryVariables>(AggregateAuditTrailDocument, options);
      }
export function useAggregateAuditTrailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateAuditTrailQuery, AggregateAuditTrailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateAuditTrailQuery, AggregateAuditTrailQueryVariables>(AggregateAuditTrailDocument, options);
        }
export type AggregateAuditTrailQueryHookResult = ReturnType<typeof useAggregateAuditTrailQuery>;
export type AggregateAuditTrailLazyQueryHookResult = ReturnType<typeof useAggregateAuditTrailLazyQuery>;
export type AggregateAuditTrailQueryResult = Apollo.QueryResult<AggregateAuditTrailQuery, AggregateAuditTrailQueryVariables>;
export const AuditTrailsDocument = gql`
    query AuditTrails($skip: Int, $take: Int, $where: AuditTrailWhereInput, $orderBy: [AuditTrailOrderByWithRelationInput!]) {
  auditTrails(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    nameOfUser
    timestamp
    action
    moduleForm
    newData
    oldData
  }
}
    `;

/**
 * __useAuditTrailsQuery__
 *
 * To run a query within a React component, call `useAuditTrailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditTrailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditTrailsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAuditTrailsQuery(baseOptions?: Apollo.QueryHookOptions<AuditTrailsQuery, AuditTrailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuditTrailsQuery, AuditTrailsQueryVariables>(AuditTrailsDocument, options);
      }
export function useAuditTrailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuditTrailsQuery, AuditTrailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuditTrailsQuery, AuditTrailsQueryVariables>(AuditTrailsDocument, options);
        }
export type AuditTrailsQueryHookResult = ReturnType<typeof useAuditTrailsQuery>;
export type AuditTrailsLazyQueryHookResult = ReturnType<typeof useAuditTrailsLazyQuery>;
export type AuditTrailsQueryResult = Apollo.QueryResult<AuditTrailsQuery, AuditTrailsQueryVariables>;
export const AggregateBetweenEntityTransferDocument = gql`
    query AggregateBetweenEntityTransfer($where: BetweenEntityTransferWhereInput) {
  aggregateBetweenEntityTransfer(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateBetweenEntityTransferQuery__
 *
 * To run a query within a React component, call `useAggregateBetweenEntityTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateBetweenEntityTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateBetweenEntityTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateBetweenEntityTransferQuery(baseOptions?: Apollo.QueryHookOptions<AggregateBetweenEntityTransferQuery, AggregateBetweenEntityTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateBetweenEntityTransferQuery, AggregateBetweenEntityTransferQueryVariables>(AggregateBetweenEntityTransferDocument, options);
      }
export function useAggregateBetweenEntityTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateBetweenEntityTransferQuery, AggregateBetweenEntityTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateBetweenEntityTransferQuery, AggregateBetweenEntityTransferQueryVariables>(AggregateBetweenEntityTransferDocument, options);
        }
export type AggregateBetweenEntityTransferQueryHookResult = ReturnType<typeof useAggregateBetweenEntityTransferQuery>;
export type AggregateBetweenEntityTransferLazyQueryHookResult = ReturnType<typeof useAggregateBetweenEntityTransferLazyQuery>;
export type AggregateBetweenEntityTransferQueryResult = Apollo.QueryResult<AggregateBetweenEntityTransferQuery, AggregateBetweenEntityTransferQueryVariables>;
export const BetweenEntityTransferDocument = gql`
    query BetweenEntityTransfer($where: BetweenEntityTransferWhereUniqueInput!) {
  betweenEntityTransfer(where: $where) {
    ...AllBetweenEntityTransferFields
  }
}
    ${AllBetweenEntityTransferFieldsFragmentDoc}`;

/**
 * __useBetweenEntityTransferQuery__
 *
 * To run a query within a React component, call `useBetweenEntityTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useBetweenEntityTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBetweenEntityTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBetweenEntityTransferQuery(baseOptions: Apollo.QueryHookOptions<BetweenEntityTransferQuery, BetweenEntityTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BetweenEntityTransferQuery, BetweenEntityTransferQueryVariables>(BetweenEntityTransferDocument, options);
      }
export function useBetweenEntityTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BetweenEntityTransferQuery, BetweenEntityTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BetweenEntityTransferQuery, BetweenEntityTransferQueryVariables>(BetweenEntityTransferDocument, options);
        }
export type BetweenEntityTransferQueryHookResult = ReturnType<typeof useBetweenEntityTransferQuery>;
export type BetweenEntityTransferLazyQueryHookResult = ReturnType<typeof useBetweenEntityTransferLazyQuery>;
export type BetweenEntityTransferQueryResult = Apollo.QueryResult<BetweenEntityTransferQuery, BetweenEntityTransferQueryVariables>;
export const BetweenEntityTransfersDocument = gql`
    query BetweenEntityTransfers($skip: Int, $take: Int, $where: BetweenEntityTransferWhereInput, $orderBy: [BetweenEntityTransferOrderByWithRelationInput!]) {
  betweenEntityTransfers(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllBetweenEntityTransferFields
  }
}
    ${AllBetweenEntityTransferFieldsFragmentDoc}`;

/**
 * __useBetweenEntityTransfersQuery__
 *
 * To run a query within a React component, call `useBetweenEntityTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBetweenEntityTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBetweenEntityTransfersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBetweenEntityTransfersQuery(baseOptions?: Apollo.QueryHookOptions<BetweenEntityTransfersQuery, BetweenEntityTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BetweenEntityTransfersQuery, BetweenEntityTransfersQueryVariables>(BetweenEntityTransfersDocument, options);
      }
export function useBetweenEntityTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BetweenEntityTransfersQuery, BetweenEntityTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BetweenEntityTransfersQuery, BetweenEntityTransfersQueryVariables>(BetweenEntityTransfersDocument, options);
        }
export type BetweenEntityTransfersQueryHookResult = ReturnType<typeof useBetweenEntityTransfersQuery>;
export type BetweenEntityTransfersLazyQueryHookResult = ReturnType<typeof useBetweenEntityTransfersLazyQuery>;
export type BetweenEntityTransfersQueryResult = Apollo.QueryResult<BetweenEntityTransfersQuery, BetweenEntityTransfersQueryVariables>;
export const CreateBetweenEntityTransferDocument = gql`
    mutation CreateBetweenEntityTransfer($data: BetweenEntityTransferCreateInput!) {
  createBetweenEntityTransfer(data: $data) {
    id
  }
}
    `;
export type CreateBetweenEntityTransferMutationFn = Apollo.MutationFunction<CreateBetweenEntityTransferMutation, CreateBetweenEntityTransferMutationVariables>;

/**
 * __useCreateBetweenEntityTransferMutation__
 *
 * To run a mutation, you first call `useCreateBetweenEntityTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBetweenEntityTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBetweenEntityTransferMutation, { data, loading, error }] = useCreateBetweenEntityTransferMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBetweenEntityTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateBetweenEntityTransferMutation, CreateBetweenEntityTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBetweenEntityTransferMutation, CreateBetweenEntityTransferMutationVariables>(CreateBetweenEntityTransferDocument, options);
      }
export type CreateBetweenEntityTransferMutationHookResult = ReturnType<typeof useCreateBetweenEntityTransferMutation>;
export type CreateBetweenEntityTransferMutationResult = Apollo.MutationResult<CreateBetweenEntityTransferMutation>;
export type CreateBetweenEntityTransferMutationOptions = Apollo.BaseMutationOptions<CreateBetweenEntityTransferMutation, CreateBetweenEntityTransferMutationVariables>;
export const DeleteManyBetweenEntityTransferDocument = gql`
    mutation DeleteManyBetweenEntityTransfer($where: [BetweenEntityTransferWhereUniqueInput!]) {
  deleteManyBetweenEntityTransfer(where: $where) {
    id
  }
}
    `;
export type DeleteManyBetweenEntityTransferMutationFn = Apollo.MutationFunction<DeleteManyBetweenEntityTransferMutation, DeleteManyBetweenEntityTransferMutationVariables>;

/**
 * __useDeleteManyBetweenEntityTransferMutation__
 *
 * To run a mutation, you first call `useDeleteManyBetweenEntityTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyBetweenEntityTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyBetweenEntityTransferMutation, { data, loading, error }] = useDeleteManyBetweenEntityTransferMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyBetweenEntityTransferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyBetweenEntityTransferMutation, DeleteManyBetweenEntityTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyBetweenEntityTransferMutation, DeleteManyBetweenEntityTransferMutationVariables>(DeleteManyBetweenEntityTransferDocument, options);
      }
export type DeleteManyBetweenEntityTransferMutationHookResult = ReturnType<typeof useDeleteManyBetweenEntityTransferMutation>;
export type DeleteManyBetweenEntityTransferMutationResult = Apollo.MutationResult<DeleteManyBetweenEntityTransferMutation>;
export type DeleteManyBetweenEntityTransferMutationOptions = Apollo.BaseMutationOptions<DeleteManyBetweenEntityTransferMutation, DeleteManyBetweenEntityTransferMutationVariables>;
export const UpdateBetweenEntityTransferDocument = gql`
    mutation UpdateBetweenEntityTransfer($where: BetweenEntityTransferWhereUniqueInput!, $data: BetweenEntityTransferUpdateInput!) {
  updateBetweenEntityTransfer(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateBetweenEntityTransferMutationFn = Apollo.MutationFunction<UpdateBetweenEntityTransferMutation, UpdateBetweenEntityTransferMutationVariables>;

/**
 * __useUpdateBetweenEntityTransferMutation__
 *
 * To run a mutation, you first call `useUpdateBetweenEntityTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBetweenEntityTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBetweenEntityTransferMutation, { data, loading, error }] = useUpdateBetweenEntityTransferMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBetweenEntityTransferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBetweenEntityTransferMutation, UpdateBetweenEntityTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBetweenEntityTransferMutation, UpdateBetweenEntityTransferMutationVariables>(UpdateBetweenEntityTransferDocument, options);
      }
export type UpdateBetweenEntityTransferMutationHookResult = ReturnType<typeof useUpdateBetweenEntityTransferMutation>;
export type UpdateBetweenEntityTransferMutationResult = Apollo.MutationResult<UpdateBetweenEntityTransferMutation>;
export type UpdateBetweenEntityTransferMutationOptions = Apollo.BaseMutationOptions<UpdateBetweenEntityTransferMutation, UpdateBetweenEntityTransferMutationVariables>;
export const AggregateBeverageDocument = gql`
    query AggregateBeverage($where: BeverageWhereInput) {
  aggregateBeverage(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateBeverageQuery__
 *
 * To run a query within a React component, call `useAggregateBeverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateBeverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateBeverageQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateBeverageQuery(baseOptions?: Apollo.QueryHookOptions<AggregateBeverageQuery, AggregateBeverageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateBeverageQuery, AggregateBeverageQueryVariables>(AggregateBeverageDocument, options);
      }
export function useAggregateBeverageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateBeverageQuery, AggregateBeverageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateBeverageQuery, AggregateBeverageQueryVariables>(AggregateBeverageDocument, options);
        }
export type AggregateBeverageQueryHookResult = ReturnType<typeof useAggregateBeverageQuery>;
export type AggregateBeverageLazyQueryHookResult = ReturnType<typeof useAggregateBeverageLazyQuery>;
export type AggregateBeverageQueryResult = Apollo.QueryResult<AggregateBeverageQuery, AggregateBeverageQueryVariables>;
export const BeverageDocument = gql`
    query Beverage($where: BeverageWhereUniqueInput!) {
  beverage(where: $where) {
    ...AllBeverageFields
  }
}
    ${AllBeverageFieldsFragmentDoc}`;

/**
 * __useBeverageQuery__
 *
 * To run a query within a React component, call `useBeverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeverageQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBeverageQuery(baseOptions: Apollo.QueryHookOptions<BeverageQuery, BeverageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeverageQuery, BeverageQueryVariables>(BeverageDocument, options);
      }
export function useBeverageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeverageQuery, BeverageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeverageQuery, BeverageQueryVariables>(BeverageDocument, options);
        }
export type BeverageQueryHookResult = ReturnType<typeof useBeverageQuery>;
export type BeverageLazyQueryHookResult = ReturnType<typeof useBeverageLazyQuery>;
export type BeverageQueryResult = Apollo.QueryResult<BeverageQuery, BeverageQueryVariables>;
export const BeveragesDocument = gql`
    query Beverages($skip: Int, $take: Int, $where: BeverageWhereInput, $orderBy: [BeverageOrderByWithRelationInput!]) {
  beverages(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllBeverageFields
  }
}
    ${AllBeverageFieldsFragmentDoc}`;

/**
 * __useBeveragesQuery__
 *
 * To run a query within a React component, call `useBeveragesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeveragesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeveragesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBeveragesQuery(baseOptions?: Apollo.QueryHookOptions<BeveragesQuery, BeveragesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeveragesQuery, BeveragesQueryVariables>(BeveragesDocument, options);
      }
export function useBeveragesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeveragesQuery, BeveragesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeveragesQuery, BeveragesQueryVariables>(BeveragesDocument, options);
        }
export type BeveragesQueryHookResult = ReturnType<typeof useBeveragesQuery>;
export type BeveragesLazyQueryHookResult = ReturnType<typeof useBeveragesLazyQuery>;
export type BeveragesQueryResult = Apollo.QueryResult<BeveragesQuery, BeveragesQueryVariables>;
export const BeveragesAllowedDocument = gql`
    query BeveragesAllowed($skip: Int, $take: Int, $where: BeverageWhereInput, $orderBy: [BeverageOrderByWithRelationInput!]) {
  beverages(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    name
    unit {
      id
      name
    }
  }
}
    `;

/**
 * __useBeveragesAllowedQuery__
 *
 * To run a query within a React component, call `useBeveragesAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeveragesAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeveragesAllowedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBeveragesAllowedQuery(baseOptions?: Apollo.QueryHookOptions<BeveragesAllowedQuery, BeveragesAllowedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeveragesAllowedQuery, BeveragesAllowedQueryVariables>(BeveragesAllowedDocument, options);
      }
export function useBeveragesAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeveragesAllowedQuery, BeveragesAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeveragesAllowedQuery, BeveragesAllowedQueryVariables>(BeveragesAllowedDocument, options);
        }
export type BeveragesAllowedQueryHookResult = ReturnType<typeof useBeveragesAllowedQuery>;
export type BeveragesAllowedLazyQueryHookResult = ReturnType<typeof useBeveragesAllowedLazyQuery>;
export type BeveragesAllowedQueryResult = Apollo.QueryResult<BeveragesAllowedQuery, BeveragesAllowedQueryVariables>;
export const BeveragesDropdownDocument = gql`
    query BeveragesDropdown($skip: Int, $take: Int, $where: BeverageWhereInput, $orderBy: [BeverageOrderByWithRelationInput!]) {
  beveragesDropdown(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    name
    unit {
      id
      name
    }
  }
}
    `;

/**
 * __useBeveragesDropdownQuery__
 *
 * To run a query within a React component, call `useBeveragesDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeveragesDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeveragesDropdownQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBeveragesDropdownQuery(baseOptions?: Apollo.QueryHookOptions<BeveragesDropdownQuery, BeveragesDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeveragesDropdownQuery, BeveragesDropdownQueryVariables>(BeveragesDropdownDocument, options);
      }
export function useBeveragesDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeveragesDropdownQuery, BeveragesDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeveragesDropdownQuery, BeveragesDropdownQueryVariables>(BeveragesDropdownDocument, options);
        }
export type BeveragesDropdownQueryHookResult = ReturnType<typeof useBeveragesDropdownQuery>;
export type BeveragesDropdownLazyQueryHookResult = ReturnType<typeof useBeveragesDropdownLazyQuery>;
export type BeveragesDropdownQueryResult = Apollo.QueryResult<BeveragesDropdownQuery, BeveragesDropdownQueryVariables>;
export const CreateBeverageDocument = gql`
    mutation CreateBeverage($data: BeverageCreateInput!) {
  createBeverage(data: $data) {
    id
  }
}
    `;
export type CreateBeverageMutationFn = Apollo.MutationFunction<CreateBeverageMutation, CreateBeverageMutationVariables>;

/**
 * __useCreateBeverageMutation__
 *
 * To run a mutation, you first call `useCreateBeverageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBeverageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBeverageMutation, { data, loading, error }] = useCreateBeverageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBeverageMutation(baseOptions?: Apollo.MutationHookOptions<CreateBeverageMutation, CreateBeverageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBeverageMutation, CreateBeverageMutationVariables>(CreateBeverageDocument, options);
      }
export type CreateBeverageMutationHookResult = ReturnType<typeof useCreateBeverageMutation>;
export type CreateBeverageMutationResult = Apollo.MutationResult<CreateBeverageMutation>;
export type CreateBeverageMutationOptions = Apollo.BaseMutationOptions<CreateBeverageMutation, CreateBeverageMutationVariables>;
export const DeleteManyBeverageDocument = gql`
    mutation DeleteManyBeverage($where: [BeverageWhereUniqueInput!]) {
  deleteManyBeverage(where: $where) {
    id
  }
}
    `;
export type DeleteManyBeverageMutationFn = Apollo.MutationFunction<DeleteManyBeverageMutation, DeleteManyBeverageMutationVariables>;

/**
 * __useDeleteManyBeverageMutation__
 *
 * To run a mutation, you first call `useDeleteManyBeverageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyBeverageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyBeverageMutation, { data, loading, error }] = useDeleteManyBeverageMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyBeverageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyBeverageMutation, DeleteManyBeverageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyBeverageMutation, DeleteManyBeverageMutationVariables>(DeleteManyBeverageDocument, options);
      }
export type DeleteManyBeverageMutationHookResult = ReturnType<typeof useDeleteManyBeverageMutation>;
export type DeleteManyBeverageMutationResult = Apollo.MutationResult<DeleteManyBeverageMutation>;
export type DeleteManyBeverageMutationOptions = Apollo.BaseMutationOptions<DeleteManyBeverageMutation, DeleteManyBeverageMutationVariables>;
export const UpdateBeverageDocument = gql`
    mutation UpdateBeverage($where: BeverageWhereUniqueInput!, $data: BeverageUpdateInput!) {
  updateBeverage(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateBeverageMutationFn = Apollo.MutationFunction<UpdateBeverageMutation, UpdateBeverageMutationVariables>;

/**
 * __useUpdateBeverageMutation__
 *
 * To run a mutation, you first call `useUpdateBeverageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBeverageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBeverageMutation, { data, loading, error }] = useUpdateBeverageMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBeverageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBeverageMutation, UpdateBeverageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBeverageMutation, UpdateBeverageMutationVariables>(UpdateBeverageDocument, options);
      }
export type UpdateBeverageMutationHookResult = ReturnType<typeof useUpdateBeverageMutation>;
export type UpdateBeverageMutationResult = Apollo.MutationResult<UpdateBeverageMutation>;
export type UpdateBeverageMutationOptions = Apollo.BaseMutationOptions<UpdateBeverageMutation, UpdateBeverageMutationVariables>;
export const BeveragesConsolidatedThrowAwayReportItemsDocument = gql`
    query BeveragesConsolidatedThrowAwayReportItems($where: ConsolidatedThrowAwayReportItemWhereInput) {
  beveragesConsolidatedThrowAwayReportItems(where: $where) {
    date
    beverages {
      name
      quantity
    }
  }
}
    `;

/**
 * __useBeveragesConsolidatedThrowAwayReportItemsQuery__
 *
 * To run a query within a React component, call `useBeveragesConsolidatedThrowAwayReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeveragesConsolidatedThrowAwayReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeveragesConsolidatedThrowAwayReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBeveragesConsolidatedThrowAwayReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<BeveragesConsolidatedThrowAwayReportItemsQuery, BeveragesConsolidatedThrowAwayReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeveragesConsolidatedThrowAwayReportItemsQuery, BeveragesConsolidatedThrowAwayReportItemsQueryVariables>(BeveragesConsolidatedThrowAwayReportItemsDocument, options);
      }
export function useBeveragesConsolidatedThrowAwayReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeveragesConsolidatedThrowAwayReportItemsQuery, BeveragesConsolidatedThrowAwayReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeveragesConsolidatedThrowAwayReportItemsQuery, BeveragesConsolidatedThrowAwayReportItemsQueryVariables>(BeveragesConsolidatedThrowAwayReportItemsDocument, options);
        }
export type BeveragesConsolidatedThrowAwayReportItemsQueryHookResult = ReturnType<typeof useBeveragesConsolidatedThrowAwayReportItemsQuery>;
export type BeveragesConsolidatedThrowAwayReportItemsLazyQueryHookResult = ReturnType<typeof useBeveragesConsolidatedThrowAwayReportItemsLazyQuery>;
export type BeveragesConsolidatedThrowAwayReportItemsQueryResult = Apollo.QueryResult<BeveragesConsolidatedThrowAwayReportItemsQuery, BeveragesConsolidatedThrowAwayReportItemsQueryVariables>;
export const BeveragesSalesProductControlReportItemsDocument = gql`
    query BeveragesSalesProductControlReportItems($where: SalesProductControlReportItemWhereInput) {
  beveragesSalesProductControlReportItems(where: $where) {
    beverage
    beginningQuantity
    beverageYield
    endingQuantity
    emQuantity
    sdQuantity
    taQuantity
    usage
    cupsSoldMediumQuantity
    cupsSoldLargeQuantity
    cupsSoldXlQuantity
    ozSold
    variance
  }
}
    `;

/**
 * __useBeveragesSalesProductControlReportItemsQuery__
 *
 * To run a query within a React component, call `useBeveragesSalesProductControlReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeveragesSalesProductControlReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeveragesSalesProductControlReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBeveragesSalesProductControlReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<BeveragesSalesProductControlReportItemsQuery, BeveragesSalesProductControlReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeveragesSalesProductControlReportItemsQuery, BeveragesSalesProductControlReportItemsQueryVariables>(BeveragesSalesProductControlReportItemsDocument, options);
      }
export function useBeveragesSalesProductControlReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeveragesSalesProductControlReportItemsQuery, BeveragesSalesProductControlReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeveragesSalesProductControlReportItemsQuery, BeveragesSalesProductControlReportItemsQueryVariables>(BeveragesSalesProductControlReportItemsDocument, options);
        }
export type BeveragesSalesProductControlReportItemsQueryHookResult = ReturnType<typeof useBeveragesSalesProductControlReportItemsQuery>;
export type BeveragesSalesProductControlReportItemsLazyQueryHookResult = ReturnType<typeof useBeveragesSalesProductControlReportItemsLazyQuery>;
export type BeveragesSalesProductControlReportItemsQueryResult = Apollo.QueryResult<BeveragesSalesProductControlReportItemsQuery, BeveragesSalesProductControlReportItemsQueryVariables>;
export const AggregateCheatSheetDocument = gql`
    query AggregateCheatSheet($where: CheatSheetWhereInput) {
  aggregateCheatSheet(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateCheatSheetQuery__
 *
 * To run a query within a React component, call `useAggregateCheatSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateCheatSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateCheatSheetQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateCheatSheetQuery(baseOptions?: Apollo.QueryHookOptions<AggregateCheatSheetQuery, AggregateCheatSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateCheatSheetQuery, AggregateCheatSheetQueryVariables>(AggregateCheatSheetDocument, options);
      }
export function useAggregateCheatSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateCheatSheetQuery, AggregateCheatSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateCheatSheetQuery, AggregateCheatSheetQueryVariables>(AggregateCheatSheetDocument, options);
        }
export type AggregateCheatSheetQueryHookResult = ReturnType<typeof useAggregateCheatSheetQuery>;
export type AggregateCheatSheetLazyQueryHookResult = ReturnType<typeof useAggregateCheatSheetLazyQuery>;
export type AggregateCheatSheetQueryResult = Apollo.QueryResult<AggregateCheatSheetQuery, AggregateCheatSheetQueryVariables>;
export const CheatSheetDocument = gql`
    query CheatSheet($where: CheatSheetWhereUniqueInput!) {
  cheatSheet(where: $where) {
    ...AllCheatSheetFields
  }
}
    ${AllCheatSheetFieldsFragmentDoc}`;

/**
 * __useCheatSheetQuery__
 *
 * To run a query within a React component, call `useCheatSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheatSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheatSheetQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCheatSheetQuery(baseOptions: Apollo.QueryHookOptions<CheatSheetQuery, CheatSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheatSheetQuery, CheatSheetQueryVariables>(CheatSheetDocument, options);
      }
export function useCheatSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheatSheetQuery, CheatSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheatSheetQuery, CheatSheetQueryVariables>(CheatSheetDocument, options);
        }
export type CheatSheetQueryHookResult = ReturnType<typeof useCheatSheetQuery>;
export type CheatSheetLazyQueryHookResult = ReturnType<typeof useCheatSheetLazyQuery>;
export type CheatSheetQueryResult = Apollo.QueryResult<CheatSheetQuery, CheatSheetQueryVariables>;
export const CheatSheetsDocument = gql`
    query CheatSheets($skip: Int, $take: Int, $where: CheatSheetWhereInput, $orderBy: [CheatSheetOrderByWithRelationInput!]) {
  cheatSheets(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllCheatSheetFields
  }
}
    ${AllCheatSheetFieldsFragmentDoc}`;

/**
 * __useCheatSheetsQuery__
 *
 * To run a query within a React component, call `useCheatSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheatSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheatSheetsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCheatSheetsQuery(baseOptions?: Apollo.QueryHookOptions<CheatSheetsQuery, CheatSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheatSheetsQuery, CheatSheetsQueryVariables>(CheatSheetsDocument, options);
      }
export function useCheatSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheatSheetsQuery, CheatSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheatSheetsQuery, CheatSheetsQueryVariables>(CheatSheetsDocument, options);
        }
export type CheatSheetsQueryHookResult = ReturnType<typeof useCheatSheetsQuery>;
export type CheatSheetsLazyQueryHookResult = ReturnType<typeof useCheatSheetsLazyQuery>;
export type CheatSheetsQueryResult = Apollo.QueryResult<CheatSheetsQuery, CheatSheetsQueryVariables>;
export const CreateCheatSheetDocument = gql`
    mutation CreateCheatSheet($data: CheatSheetCreateInput!) {
  createCheatSheet(data: $data) {
    id
  }
}
    `;
export type CreateCheatSheetMutationFn = Apollo.MutationFunction<CreateCheatSheetMutation, CreateCheatSheetMutationVariables>;

/**
 * __useCreateCheatSheetMutation__
 *
 * To run a mutation, you first call `useCreateCheatSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheatSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheatSheetMutation, { data, loading, error }] = useCreateCheatSheetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCheatSheetMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheatSheetMutation, CreateCheatSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheatSheetMutation, CreateCheatSheetMutationVariables>(CreateCheatSheetDocument, options);
      }
export type CreateCheatSheetMutationHookResult = ReturnType<typeof useCreateCheatSheetMutation>;
export type CreateCheatSheetMutationResult = Apollo.MutationResult<CreateCheatSheetMutation>;
export type CreateCheatSheetMutationOptions = Apollo.BaseMutationOptions<CreateCheatSheetMutation, CreateCheatSheetMutationVariables>;
export const DeleteManyCheatSheetDocument = gql`
    mutation DeleteManyCheatSheet($where: [CheatSheetWhereUniqueInput!]) {
  deleteManyCheatSheet(where: $where) {
    id
  }
}
    `;
export type DeleteManyCheatSheetMutationFn = Apollo.MutationFunction<DeleteManyCheatSheetMutation, DeleteManyCheatSheetMutationVariables>;

/**
 * __useDeleteManyCheatSheetMutation__
 *
 * To run a mutation, you first call `useDeleteManyCheatSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCheatSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyCheatSheetMutation, { data, loading, error }] = useDeleteManyCheatSheetMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyCheatSheetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyCheatSheetMutation, DeleteManyCheatSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyCheatSheetMutation, DeleteManyCheatSheetMutationVariables>(DeleteManyCheatSheetDocument, options);
      }
export type DeleteManyCheatSheetMutationHookResult = ReturnType<typeof useDeleteManyCheatSheetMutation>;
export type DeleteManyCheatSheetMutationResult = Apollo.MutationResult<DeleteManyCheatSheetMutation>;
export type DeleteManyCheatSheetMutationOptions = Apollo.BaseMutationOptions<DeleteManyCheatSheetMutation, DeleteManyCheatSheetMutationVariables>;
export const UpdateCheatSheetDocument = gql`
    mutation UpdateCheatSheet($where: CheatSheetWhereUniqueInput!, $data: CheatSheetUpdateInput!) {
  updateCheatSheet(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateCheatSheetMutationFn = Apollo.MutationFunction<UpdateCheatSheetMutation, UpdateCheatSheetMutationVariables>;

/**
 * __useUpdateCheatSheetMutation__
 *
 * To run a mutation, you first call `useUpdateCheatSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheatSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheatSheetMutation, { data, loading, error }] = useUpdateCheatSheetMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCheatSheetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCheatSheetMutation, UpdateCheatSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheatSheetMutation, UpdateCheatSheetMutationVariables>(UpdateCheatSheetDocument, options);
      }
export type UpdateCheatSheetMutationHookResult = ReturnType<typeof useUpdateCheatSheetMutation>;
export type UpdateCheatSheetMutationResult = Apollo.MutationResult<UpdateCheatSheetMutation>;
export type UpdateCheatSheetMutationOptions = Apollo.BaseMutationOptions<UpdateCheatSheetMutation, UpdateCheatSheetMutationVariables>;
export const AggregateCmlProductDocument = gql`
    query AggregateCmlProduct($where: CmlProductWhereInput) {
  aggregateCmlProduct(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateCmlProductQuery__
 *
 * To run a query within a React component, call `useAggregateCmlProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateCmlProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateCmlProductQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateCmlProductQuery(baseOptions?: Apollo.QueryHookOptions<AggregateCmlProductQuery, AggregateCmlProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateCmlProductQuery, AggregateCmlProductQueryVariables>(AggregateCmlProductDocument, options);
      }
export function useAggregateCmlProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateCmlProductQuery, AggregateCmlProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateCmlProductQuery, AggregateCmlProductQueryVariables>(AggregateCmlProductDocument, options);
        }
export type AggregateCmlProductQueryHookResult = ReturnType<typeof useAggregateCmlProductQuery>;
export type AggregateCmlProductLazyQueryHookResult = ReturnType<typeof useAggregateCmlProductLazyQuery>;
export type AggregateCmlProductQueryResult = Apollo.QueryResult<AggregateCmlProductQuery, AggregateCmlProductQueryVariables>;
export const CmlProductDocument = gql`
    query CmlProduct($where: CmlProductWhereUniqueInput!) {
  cmlProduct(where: $where) {
    ...AllCmlProductFields
  }
}
    ${AllCmlProductFieldsFragmentDoc}`;

/**
 * __useCmlProductQuery__
 *
 * To run a query within a React component, call `useCmlProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlProductQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCmlProductQuery(baseOptions: Apollo.QueryHookOptions<CmlProductQuery, CmlProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlProductQuery, CmlProductQueryVariables>(CmlProductDocument, options);
      }
export function useCmlProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlProductQuery, CmlProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlProductQuery, CmlProductQueryVariables>(CmlProductDocument, options);
        }
export type CmlProductQueryHookResult = ReturnType<typeof useCmlProductQuery>;
export type CmlProductLazyQueryHookResult = ReturnType<typeof useCmlProductLazyQuery>;
export type CmlProductQueryResult = Apollo.QueryResult<CmlProductQuery, CmlProductQueryVariables>;
export const CmlProductsDocument = gql`
    query CmlProducts($skip: Int, $take: Int, $where: CmlProductWhereInput, $orderBy: [CmlProductOrderByWithRelationInput!]) {
  cmlProducts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllCmlProductFields
  }
}
    ${AllCmlProductFieldsFragmentDoc}`;

/**
 * __useCmlProductsQuery__
 *
 * To run a query within a React component, call `useCmlProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlProductsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCmlProductsQuery(baseOptions?: Apollo.QueryHookOptions<CmlProductsQuery, CmlProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlProductsQuery, CmlProductsQueryVariables>(CmlProductsDocument, options);
      }
export function useCmlProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlProductsQuery, CmlProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlProductsQuery, CmlProductsQueryVariables>(CmlProductsDocument, options);
        }
export type CmlProductsQueryHookResult = ReturnType<typeof useCmlProductsQuery>;
export type CmlProductsLazyQueryHookResult = ReturnType<typeof useCmlProductsLazyQuery>;
export type CmlProductsQueryResult = Apollo.QueryResult<CmlProductsQuery, CmlProductsQueryVariables>;
export const CmlProductsAllowedDocument = gql`
    query CmlProductsAllowed($skip: Int, $take: Int, $where: CmlProductWhereInput, $orderBy: [CmlProductOrderByWithRelationInput!]) {
  cmlProducts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    name
    productCategory
    donutMixAndCut {
      id
      name
    }
    salesCategory
    unit {
      id
      name
    }
    status
  }
}
    `;

/**
 * __useCmlProductsAllowedQuery__
 *
 * To run a query within a React component, call `useCmlProductsAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlProductsAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlProductsAllowedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCmlProductsAllowedQuery(baseOptions?: Apollo.QueryHookOptions<CmlProductsAllowedQuery, CmlProductsAllowedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlProductsAllowedQuery, CmlProductsAllowedQueryVariables>(CmlProductsAllowedDocument, options);
      }
export function useCmlProductsAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlProductsAllowedQuery, CmlProductsAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlProductsAllowedQuery, CmlProductsAllowedQueryVariables>(CmlProductsAllowedDocument, options);
        }
export type CmlProductsAllowedQueryHookResult = ReturnType<typeof useCmlProductsAllowedQuery>;
export type CmlProductsAllowedLazyQueryHookResult = ReturnType<typeof useCmlProductsAllowedLazyQuery>;
export type CmlProductsAllowedQueryResult = Apollo.QueryResult<CmlProductsAllowedQuery, CmlProductsAllowedQueryVariables>;
export const CmlProductsDropdownDocument = gql`
    query CmlProductsDropdown($skip: Int, $take: Int, $where: CmlProductWhereInput, $orderBy: [CmlProductOrderByWithRelationInput!]) {
  cmlProductsDropdown(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    name
    productCategory
    donutMixAndCut {
      id
      name
    }
    salesCategory
    unit {
      id
      name
    }
    status
  }
}
    `;

/**
 * __useCmlProductsDropdownQuery__
 *
 * To run a query within a React component, call `useCmlProductsDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlProductsDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlProductsDropdownQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCmlProductsDropdownQuery(baseOptions?: Apollo.QueryHookOptions<CmlProductsDropdownQuery, CmlProductsDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlProductsDropdownQuery, CmlProductsDropdownQueryVariables>(CmlProductsDropdownDocument, options);
      }
export function useCmlProductsDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlProductsDropdownQuery, CmlProductsDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlProductsDropdownQuery, CmlProductsDropdownQueryVariables>(CmlProductsDropdownDocument, options);
        }
export type CmlProductsDropdownQueryHookResult = ReturnType<typeof useCmlProductsDropdownQuery>;
export type CmlProductsDropdownLazyQueryHookResult = ReturnType<typeof useCmlProductsDropdownLazyQuery>;
export type CmlProductsDropdownQueryResult = Apollo.QueryResult<CmlProductsDropdownQuery, CmlProductsDropdownQueryVariables>;
export const CreateCmlProductDocument = gql`
    mutation CreateCmlProduct($data: CmlProductCreateInput!) {
  createCmlProduct(data: $data) {
    id
  }
}
    `;
export type CreateCmlProductMutationFn = Apollo.MutationFunction<CreateCmlProductMutation, CreateCmlProductMutationVariables>;

/**
 * __useCreateCmlProductMutation__
 *
 * To run a mutation, you first call `useCreateCmlProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCmlProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCmlProductMutation, { data, loading, error }] = useCreateCmlProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCmlProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateCmlProductMutation, CreateCmlProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCmlProductMutation, CreateCmlProductMutationVariables>(CreateCmlProductDocument, options);
      }
export type CreateCmlProductMutationHookResult = ReturnType<typeof useCreateCmlProductMutation>;
export type CreateCmlProductMutationResult = Apollo.MutationResult<CreateCmlProductMutation>;
export type CreateCmlProductMutationOptions = Apollo.BaseMutationOptions<CreateCmlProductMutation, CreateCmlProductMutationVariables>;
export const DeleteManyCmlProductDocument = gql`
    mutation DeleteManyCmlProduct($where: [CmlProductWhereUniqueInput!]) {
  deleteManyCmlProduct(where: $where) {
    id
  }
}
    `;
export type DeleteManyCmlProductMutationFn = Apollo.MutationFunction<DeleteManyCmlProductMutation, DeleteManyCmlProductMutationVariables>;

/**
 * __useDeleteManyCmlProductMutation__
 *
 * To run a mutation, you first call `useDeleteManyCmlProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCmlProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyCmlProductMutation, { data, loading, error }] = useDeleteManyCmlProductMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyCmlProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyCmlProductMutation, DeleteManyCmlProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyCmlProductMutation, DeleteManyCmlProductMutationVariables>(DeleteManyCmlProductDocument, options);
      }
export type DeleteManyCmlProductMutationHookResult = ReturnType<typeof useDeleteManyCmlProductMutation>;
export type DeleteManyCmlProductMutationResult = Apollo.MutationResult<DeleteManyCmlProductMutation>;
export type DeleteManyCmlProductMutationOptions = Apollo.BaseMutationOptions<DeleteManyCmlProductMutation, DeleteManyCmlProductMutationVariables>;
export const UpdateCmlProductDocument = gql`
    mutation UpdateCmlProduct($where: CmlProductWhereUniqueInput!, $data: CmlProductUpdateInput!) {
  updateCmlProduct(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateCmlProductMutationFn = Apollo.MutationFunction<UpdateCmlProductMutation, UpdateCmlProductMutationVariables>;

/**
 * __useUpdateCmlProductMutation__
 *
 * To run a mutation, you first call `useUpdateCmlProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCmlProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCmlProductMutation, { data, loading, error }] = useUpdateCmlProductMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCmlProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCmlProductMutation, UpdateCmlProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCmlProductMutation, UpdateCmlProductMutationVariables>(UpdateCmlProductDocument, options);
      }
export type UpdateCmlProductMutationHookResult = ReturnType<typeof useUpdateCmlProductMutation>;
export type UpdateCmlProductMutationResult = Apollo.MutationResult<UpdateCmlProductMutation>;
export type UpdateCmlProductMutationOptions = Apollo.BaseMutationOptions<UpdateCmlProductMutation, UpdateCmlProductMutationVariables>;
export const AggregateCmlProductRecipeDocument = gql`
    query AggregateCmlProductRecipe($where: CmlProductRecipeWhereInput) {
  aggregateCmlProductRecipe(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateCmlProductRecipeQuery__
 *
 * To run a query within a React component, call `useAggregateCmlProductRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateCmlProductRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateCmlProductRecipeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateCmlProductRecipeQuery(baseOptions?: Apollo.QueryHookOptions<AggregateCmlProductRecipeQuery, AggregateCmlProductRecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateCmlProductRecipeQuery, AggregateCmlProductRecipeQueryVariables>(AggregateCmlProductRecipeDocument, options);
      }
export function useAggregateCmlProductRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateCmlProductRecipeQuery, AggregateCmlProductRecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateCmlProductRecipeQuery, AggregateCmlProductRecipeQueryVariables>(AggregateCmlProductRecipeDocument, options);
        }
export type AggregateCmlProductRecipeQueryHookResult = ReturnType<typeof useAggregateCmlProductRecipeQuery>;
export type AggregateCmlProductRecipeLazyQueryHookResult = ReturnType<typeof useAggregateCmlProductRecipeLazyQuery>;
export type AggregateCmlProductRecipeQueryResult = Apollo.QueryResult<AggregateCmlProductRecipeQuery, AggregateCmlProductRecipeQueryVariables>;
export const CmlProductRecipeDocument = gql`
    query CmlProductRecipe($where: CmlProductRecipeWhereUniqueInput!) {
  cmlProductRecipe(where: $where) {
    ...AllCmlProductRecipeFields
  }
}
    ${AllCmlProductRecipeFieldsFragmentDoc}`;

/**
 * __useCmlProductRecipeQuery__
 *
 * To run a query within a React component, call `useCmlProductRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlProductRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlProductRecipeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCmlProductRecipeQuery(baseOptions: Apollo.QueryHookOptions<CmlProductRecipeQuery, CmlProductRecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlProductRecipeQuery, CmlProductRecipeQueryVariables>(CmlProductRecipeDocument, options);
      }
export function useCmlProductRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlProductRecipeQuery, CmlProductRecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlProductRecipeQuery, CmlProductRecipeQueryVariables>(CmlProductRecipeDocument, options);
        }
export type CmlProductRecipeQueryHookResult = ReturnType<typeof useCmlProductRecipeQuery>;
export type CmlProductRecipeLazyQueryHookResult = ReturnType<typeof useCmlProductRecipeLazyQuery>;
export type CmlProductRecipeQueryResult = Apollo.QueryResult<CmlProductRecipeQuery, CmlProductRecipeQueryVariables>;
export const CmlProductRecipesDocument = gql`
    query CmlProductRecipes($skip: Int, $take: Int, $where: CmlProductRecipeWhereInput, $orderBy: [CmlProductRecipeOrderByWithRelationInput!]) {
  cmlProductRecipes(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllCmlProductRecipeFields
  }
}
    ${AllCmlProductRecipeFieldsFragmentDoc}`;

/**
 * __useCmlProductRecipesQuery__
 *
 * To run a query within a React component, call `useCmlProductRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlProductRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlProductRecipesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCmlProductRecipesQuery(baseOptions?: Apollo.QueryHookOptions<CmlProductRecipesQuery, CmlProductRecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlProductRecipesQuery, CmlProductRecipesQueryVariables>(CmlProductRecipesDocument, options);
      }
export function useCmlProductRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlProductRecipesQuery, CmlProductRecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlProductRecipesQuery, CmlProductRecipesQueryVariables>(CmlProductRecipesDocument, options);
        }
export type CmlProductRecipesQueryHookResult = ReturnType<typeof useCmlProductRecipesQuery>;
export type CmlProductRecipesLazyQueryHookResult = ReturnType<typeof useCmlProductRecipesLazyQuery>;
export type CmlProductRecipesQueryResult = Apollo.QueryResult<CmlProductRecipesQuery, CmlProductRecipesQueryVariables>;
export const CreateCmlProductRecipeDocument = gql`
    mutation CreateCmlProductRecipe($data: CmlProductRecipeCreateInput!) {
  createCmlProductRecipe(data: $data) {
    id
  }
}
    `;
export type CreateCmlProductRecipeMutationFn = Apollo.MutationFunction<CreateCmlProductRecipeMutation, CreateCmlProductRecipeMutationVariables>;

/**
 * __useCreateCmlProductRecipeMutation__
 *
 * To run a mutation, you first call `useCreateCmlProductRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCmlProductRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCmlProductRecipeMutation, { data, loading, error }] = useCreateCmlProductRecipeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCmlProductRecipeMutation(baseOptions?: Apollo.MutationHookOptions<CreateCmlProductRecipeMutation, CreateCmlProductRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCmlProductRecipeMutation, CreateCmlProductRecipeMutationVariables>(CreateCmlProductRecipeDocument, options);
      }
export type CreateCmlProductRecipeMutationHookResult = ReturnType<typeof useCreateCmlProductRecipeMutation>;
export type CreateCmlProductRecipeMutationResult = Apollo.MutationResult<CreateCmlProductRecipeMutation>;
export type CreateCmlProductRecipeMutationOptions = Apollo.BaseMutationOptions<CreateCmlProductRecipeMutation, CreateCmlProductRecipeMutationVariables>;
export const DeleteManyCmlProductRecipeDocument = gql`
    mutation DeleteManyCmlProductRecipe($where: [CmlProductRecipeWhereUniqueInput!]) {
  deleteManyCmlProductRecipe(where: $where) {
    id
  }
}
    `;
export type DeleteManyCmlProductRecipeMutationFn = Apollo.MutationFunction<DeleteManyCmlProductRecipeMutation, DeleteManyCmlProductRecipeMutationVariables>;

/**
 * __useDeleteManyCmlProductRecipeMutation__
 *
 * To run a mutation, you first call `useDeleteManyCmlProductRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCmlProductRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyCmlProductRecipeMutation, { data, loading, error }] = useDeleteManyCmlProductRecipeMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyCmlProductRecipeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyCmlProductRecipeMutation, DeleteManyCmlProductRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyCmlProductRecipeMutation, DeleteManyCmlProductRecipeMutationVariables>(DeleteManyCmlProductRecipeDocument, options);
      }
export type DeleteManyCmlProductRecipeMutationHookResult = ReturnType<typeof useDeleteManyCmlProductRecipeMutation>;
export type DeleteManyCmlProductRecipeMutationResult = Apollo.MutationResult<DeleteManyCmlProductRecipeMutation>;
export type DeleteManyCmlProductRecipeMutationOptions = Apollo.BaseMutationOptions<DeleteManyCmlProductRecipeMutation, DeleteManyCmlProductRecipeMutationVariables>;
export const UpdateCmlProductRecipeDocument = gql`
    mutation UpdateCmlProductRecipe($where: CmlProductRecipeWhereUniqueInput!, $data: CmlProductRecipeUpdateInput!) {
  updateCmlProductRecipe(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateCmlProductRecipeMutationFn = Apollo.MutationFunction<UpdateCmlProductRecipeMutation, UpdateCmlProductRecipeMutationVariables>;

/**
 * __useUpdateCmlProductRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateCmlProductRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCmlProductRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCmlProductRecipeMutation, { data, loading, error }] = useUpdateCmlProductRecipeMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCmlProductRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCmlProductRecipeMutation, UpdateCmlProductRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCmlProductRecipeMutation, UpdateCmlProductRecipeMutationVariables>(UpdateCmlProductRecipeDocument, options);
      }
export type UpdateCmlProductRecipeMutationHookResult = ReturnType<typeof useUpdateCmlProductRecipeMutation>;
export type UpdateCmlProductRecipeMutationResult = Apollo.MutationResult<UpdateCmlProductRecipeMutation>;
export type UpdateCmlProductRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateCmlProductRecipeMutation, UpdateCmlProductRecipeMutationVariables>;
export const CmlProductsSalesProductControlReportItemsDocument = gql`
    query CmlProductsSalesProductControlReportItems($where: SalesProductControlReportItemWhereInput) {
  cmlProductsSalesProductControlReportItems(where: $where) {
    salesCategory
    beginningQuantity
    deliveriesQuantity
    transferInQuantity
    transferOutQuantity
    emQuantity
    sdQuantity
    taQuantity
    endingQuantity
    usage
    crtCmlItemQuantity
    variance
  }
}
    `;

/**
 * __useCmlProductsSalesProductControlReportItemsQuery__
 *
 * To run a query within a React component, call `useCmlProductsSalesProductControlReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlProductsSalesProductControlReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlProductsSalesProductControlReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCmlProductsSalesProductControlReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<CmlProductsSalesProductControlReportItemsQuery, CmlProductsSalesProductControlReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlProductsSalesProductControlReportItemsQuery, CmlProductsSalesProductControlReportItemsQueryVariables>(CmlProductsSalesProductControlReportItemsDocument, options);
      }
export function useCmlProductsSalesProductControlReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlProductsSalesProductControlReportItemsQuery, CmlProductsSalesProductControlReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlProductsSalesProductControlReportItemsQuery, CmlProductsSalesProductControlReportItemsQueryVariables>(CmlProductsSalesProductControlReportItemsDocument, options);
        }
export type CmlProductsSalesProductControlReportItemsQueryHookResult = ReturnType<typeof useCmlProductsSalesProductControlReportItemsQuery>;
export type CmlProductsSalesProductControlReportItemsLazyQueryHookResult = ReturnType<typeof useCmlProductsSalesProductControlReportItemsLazyQuery>;
export type CmlProductsSalesProductControlReportItemsQueryResult = Apollo.QueryResult<CmlProductsSalesProductControlReportItemsQuery, CmlProductsSalesProductControlReportItemsQueryVariables>;
export const AggregateCmlThrowAwayDocument = gql`
    query AggregateCmlThrowAway($where: CmlThrowAwayWhereInput) {
  aggregateCmlThrowAway(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateCmlThrowAwayQuery__
 *
 * To run a query within a React component, call `useAggregateCmlThrowAwayQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateCmlThrowAwayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateCmlThrowAwayQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateCmlThrowAwayQuery(baseOptions?: Apollo.QueryHookOptions<AggregateCmlThrowAwayQuery, AggregateCmlThrowAwayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateCmlThrowAwayQuery, AggregateCmlThrowAwayQueryVariables>(AggregateCmlThrowAwayDocument, options);
      }
export function useAggregateCmlThrowAwayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateCmlThrowAwayQuery, AggregateCmlThrowAwayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateCmlThrowAwayQuery, AggregateCmlThrowAwayQueryVariables>(AggregateCmlThrowAwayDocument, options);
        }
export type AggregateCmlThrowAwayQueryHookResult = ReturnType<typeof useAggregateCmlThrowAwayQuery>;
export type AggregateCmlThrowAwayLazyQueryHookResult = ReturnType<typeof useAggregateCmlThrowAwayLazyQuery>;
export type AggregateCmlThrowAwayQueryResult = Apollo.QueryResult<AggregateCmlThrowAwayQuery, AggregateCmlThrowAwayQueryVariables>;
export const CmlThrowAwayDocument = gql`
    query CmlThrowAway($where: CmlThrowAwayWhereUniqueInput!) {
  cmlThrowAway(where: $where) {
    ...AllCmlThrowAwayFields
  }
}
    ${AllCmlThrowAwayFieldsFragmentDoc}`;

/**
 * __useCmlThrowAwayQuery__
 *
 * To run a query within a React component, call `useCmlThrowAwayQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlThrowAwayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlThrowAwayQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCmlThrowAwayQuery(baseOptions: Apollo.QueryHookOptions<CmlThrowAwayQuery, CmlThrowAwayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlThrowAwayQuery, CmlThrowAwayQueryVariables>(CmlThrowAwayDocument, options);
      }
export function useCmlThrowAwayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlThrowAwayQuery, CmlThrowAwayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlThrowAwayQuery, CmlThrowAwayQueryVariables>(CmlThrowAwayDocument, options);
        }
export type CmlThrowAwayQueryHookResult = ReturnType<typeof useCmlThrowAwayQuery>;
export type CmlThrowAwayLazyQueryHookResult = ReturnType<typeof useCmlThrowAwayLazyQuery>;
export type CmlThrowAwayQueryResult = Apollo.QueryResult<CmlThrowAwayQuery, CmlThrowAwayQueryVariables>;
export const CmlThrowAwaysDocument = gql`
    query CmlThrowAways($skip: Int, $take: Int, $where: CmlThrowAwayWhereInput, $orderBy: [CmlThrowAwayOrderByWithRelationInput!]) {
  cmlThrowAways(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllCmlThrowAwayFields
  }
}
    ${AllCmlThrowAwayFieldsFragmentDoc}`;

/**
 * __useCmlThrowAwaysQuery__
 *
 * To run a query within a React component, call `useCmlThrowAwaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmlThrowAwaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmlThrowAwaysQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCmlThrowAwaysQuery(baseOptions?: Apollo.QueryHookOptions<CmlThrowAwaysQuery, CmlThrowAwaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmlThrowAwaysQuery, CmlThrowAwaysQueryVariables>(CmlThrowAwaysDocument, options);
      }
export function useCmlThrowAwaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmlThrowAwaysQuery, CmlThrowAwaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmlThrowAwaysQuery, CmlThrowAwaysQueryVariables>(CmlThrowAwaysDocument, options);
        }
export type CmlThrowAwaysQueryHookResult = ReturnType<typeof useCmlThrowAwaysQuery>;
export type CmlThrowAwaysLazyQueryHookResult = ReturnType<typeof useCmlThrowAwaysLazyQuery>;
export type CmlThrowAwaysQueryResult = Apollo.QueryResult<CmlThrowAwaysQuery, CmlThrowAwaysQueryVariables>;
export const CreateCmlThrowAwayDocument = gql`
    mutation CreateCmlThrowAway($data: CmlThrowAwayCreateInput!) {
  createCmlThrowAway(data: $data) {
    id
  }
}
    `;
export type CreateCmlThrowAwayMutationFn = Apollo.MutationFunction<CreateCmlThrowAwayMutation, CreateCmlThrowAwayMutationVariables>;

/**
 * __useCreateCmlThrowAwayMutation__
 *
 * To run a mutation, you first call `useCreateCmlThrowAwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCmlThrowAwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCmlThrowAwayMutation, { data, loading, error }] = useCreateCmlThrowAwayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCmlThrowAwayMutation(baseOptions?: Apollo.MutationHookOptions<CreateCmlThrowAwayMutation, CreateCmlThrowAwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCmlThrowAwayMutation, CreateCmlThrowAwayMutationVariables>(CreateCmlThrowAwayDocument, options);
      }
export type CreateCmlThrowAwayMutationHookResult = ReturnType<typeof useCreateCmlThrowAwayMutation>;
export type CreateCmlThrowAwayMutationResult = Apollo.MutationResult<CreateCmlThrowAwayMutation>;
export type CreateCmlThrowAwayMutationOptions = Apollo.BaseMutationOptions<CreateCmlThrowAwayMutation, CreateCmlThrowAwayMutationVariables>;
export const DeleteManyCmlThrowAwayDocument = gql`
    mutation DeleteManyCmlThrowAway($where: [CmlThrowAwayWhereUniqueInput!]) {
  deleteManyCmlThrowAway(where: $where) {
    id
  }
}
    `;
export type DeleteManyCmlThrowAwayMutationFn = Apollo.MutationFunction<DeleteManyCmlThrowAwayMutation, DeleteManyCmlThrowAwayMutationVariables>;

/**
 * __useDeleteManyCmlThrowAwayMutation__
 *
 * To run a mutation, you first call `useDeleteManyCmlThrowAwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCmlThrowAwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyCmlThrowAwayMutation, { data, loading, error }] = useDeleteManyCmlThrowAwayMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyCmlThrowAwayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyCmlThrowAwayMutation, DeleteManyCmlThrowAwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyCmlThrowAwayMutation, DeleteManyCmlThrowAwayMutationVariables>(DeleteManyCmlThrowAwayDocument, options);
      }
export type DeleteManyCmlThrowAwayMutationHookResult = ReturnType<typeof useDeleteManyCmlThrowAwayMutation>;
export type DeleteManyCmlThrowAwayMutationResult = Apollo.MutationResult<DeleteManyCmlThrowAwayMutation>;
export type DeleteManyCmlThrowAwayMutationOptions = Apollo.BaseMutationOptions<DeleteManyCmlThrowAwayMutation, DeleteManyCmlThrowAwayMutationVariables>;
export const UpdateCmlThrowAwayDocument = gql`
    mutation UpdateCmlThrowAway($where: CmlThrowAwayWhereUniqueInput!, $data: CmlThrowAwayUpdateInput!) {
  updateCmlThrowAway(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateCmlThrowAwayMutationFn = Apollo.MutationFunction<UpdateCmlThrowAwayMutation, UpdateCmlThrowAwayMutationVariables>;

/**
 * __useUpdateCmlThrowAwayMutation__
 *
 * To run a mutation, you first call `useUpdateCmlThrowAwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCmlThrowAwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCmlThrowAwayMutation, { data, loading, error }] = useUpdateCmlThrowAwayMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCmlThrowAwayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCmlThrowAwayMutation, UpdateCmlThrowAwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCmlThrowAwayMutation, UpdateCmlThrowAwayMutationVariables>(UpdateCmlThrowAwayDocument, options);
      }
export type UpdateCmlThrowAwayMutationHookResult = ReturnType<typeof useUpdateCmlThrowAwayMutation>;
export type UpdateCmlThrowAwayMutationResult = Apollo.MutationResult<UpdateCmlThrowAwayMutation>;
export type UpdateCmlThrowAwayMutationOptions = Apollo.BaseMutationOptions<UpdateCmlThrowAwayMutation, UpdateCmlThrowAwayMutationVariables>;
export const AggregateCrtDocument = gql`
    query AggregateCrt($where: CrtWhereInput) {
  aggregateCrt(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateCrtQuery__
 *
 * To run a query within a React component, call `useAggregateCrtQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateCrtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateCrtQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateCrtQuery(baseOptions?: Apollo.QueryHookOptions<AggregateCrtQuery, AggregateCrtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateCrtQuery, AggregateCrtQueryVariables>(AggregateCrtDocument, options);
      }
export function useAggregateCrtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateCrtQuery, AggregateCrtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateCrtQuery, AggregateCrtQueryVariables>(AggregateCrtDocument, options);
        }
export type AggregateCrtQueryHookResult = ReturnType<typeof useAggregateCrtQuery>;
export type AggregateCrtLazyQueryHookResult = ReturnType<typeof useAggregateCrtLazyQuery>;
export type AggregateCrtQueryResult = Apollo.QueryResult<AggregateCrtQuery, AggregateCrtQueryVariables>;
export const CreateCrtDocument = gql`
    mutation CreateCrt($data: CrtCreateInput!) {
  createCrt(data: $data) {
    id
  }
}
    `;
export type CreateCrtMutationFn = Apollo.MutationFunction<CreateCrtMutation, CreateCrtMutationVariables>;

/**
 * __useCreateCrtMutation__
 *
 * To run a mutation, you first call `useCreateCrtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCrtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCrtMutation, { data, loading, error }] = useCreateCrtMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCrtMutation(baseOptions?: Apollo.MutationHookOptions<CreateCrtMutation, CreateCrtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCrtMutation, CreateCrtMutationVariables>(CreateCrtDocument, options);
      }
export type CreateCrtMutationHookResult = ReturnType<typeof useCreateCrtMutation>;
export type CreateCrtMutationResult = Apollo.MutationResult<CreateCrtMutation>;
export type CreateCrtMutationOptions = Apollo.BaseMutationOptions<CreateCrtMutation, CreateCrtMutationVariables>;
export const CrtDocument = gql`
    query Crt($where: CrtWhereUniqueInput!) {
  crt(where: $where) {
    ...AllCrtFields
  }
}
    ${AllCrtFieldsFragmentDoc}`;

/**
 * __useCrtQuery__
 *
 * To run a query within a React component, call `useCrtQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrtQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCrtQuery(baseOptions: Apollo.QueryHookOptions<CrtQuery, CrtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrtQuery, CrtQueryVariables>(CrtDocument, options);
      }
export function useCrtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrtQuery, CrtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrtQuery, CrtQueryVariables>(CrtDocument, options);
        }
export type CrtQueryHookResult = ReturnType<typeof useCrtQuery>;
export type CrtLazyQueryHookResult = ReturnType<typeof useCrtLazyQuery>;
export type CrtQueryResult = Apollo.QueryResult<CrtQuery, CrtQueryVariables>;
export const CrtsDocument = gql`
    query Crts($skip: Int, $take: Int, $where: CrtWhereInput, $orderBy: [CrtOrderByWithRelationInput!]) {
  crts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllCrtFields
  }
}
    ${AllCrtFieldsFragmentDoc}`;

/**
 * __useCrtsQuery__
 *
 * To run a query within a React component, call `useCrtsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrtsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrtsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCrtsQuery(baseOptions?: Apollo.QueryHookOptions<CrtsQuery, CrtsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrtsQuery, CrtsQueryVariables>(CrtsDocument, options);
      }
export function useCrtsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrtsQuery, CrtsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrtsQuery, CrtsQueryVariables>(CrtsDocument, options);
        }
export type CrtsQueryHookResult = ReturnType<typeof useCrtsQuery>;
export type CrtsLazyQueryHookResult = ReturnType<typeof useCrtsLazyQuery>;
export type CrtsQueryResult = Apollo.QueryResult<CrtsQuery, CrtsQueryVariables>;
export const DeleteManyCrtDocument = gql`
    mutation DeleteManyCrt($where: [CrtWhereUniqueInput!]) {
  deleteManyCrt(where: $where) {
    id
  }
}
    `;
export type DeleteManyCrtMutationFn = Apollo.MutationFunction<DeleteManyCrtMutation, DeleteManyCrtMutationVariables>;

/**
 * __useDeleteManyCrtMutation__
 *
 * To run a mutation, you first call `useDeleteManyCrtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCrtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyCrtMutation, { data, loading, error }] = useDeleteManyCrtMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyCrtMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyCrtMutation, DeleteManyCrtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyCrtMutation, DeleteManyCrtMutationVariables>(DeleteManyCrtDocument, options);
      }
export type DeleteManyCrtMutationHookResult = ReturnType<typeof useDeleteManyCrtMutation>;
export type DeleteManyCrtMutationResult = Apollo.MutationResult<DeleteManyCrtMutation>;
export type DeleteManyCrtMutationOptions = Apollo.BaseMutationOptions<DeleteManyCrtMutation, DeleteManyCrtMutationVariables>;
export const UpdateCrtDocument = gql`
    mutation UpdateCrt($where: CrtWhereUniqueInput!, $data: CrtUpdateInput!) {
  updateCrt(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateCrtMutationFn = Apollo.MutationFunction<UpdateCrtMutation, UpdateCrtMutationVariables>;

/**
 * __useUpdateCrtMutation__
 *
 * To run a mutation, you first call `useUpdateCrtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrtMutation, { data, loading, error }] = useUpdateCrtMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCrtMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCrtMutation, UpdateCrtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCrtMutation, UpdateCrtMutationVariables>(UpdateCrtDocument, options);
      }
export type UpdateCrtMutationHookResult = ReturnType<typeof useUpdateCrtMutation>;
export type UpdateCrtMutationResult = Apollo.MutationResult<UpdateCrtMutation>;
export type UpdateCrtMutationOptions = Apollo.BaseMutationOptions<UpdateCrtMutation, UpdateCrtMutationVariables>;
export const DailyRawMaterialFinishingOutletVarianceReportDocument = gql`
    query DailyRawMaterialFinishingOutletVarianceReport($where: DailyRawMaterialFinishingOutletVarianceReportWhereInput) {
  dailyRawMaterialFinishingOutletVarianceReport(where: $where) {
    category
    rawMaterial
    unit
    beginning
    fromStoreRoom
    throwAway
    theoretical
    shouldBe
    actualEnding
    variance
  }
}
    `;

/**
 * __useDailyRawMaterialFinishingOutletVarianceReportQuery__
 *
 * To run a query within a React component, call `useDailyRawMaterialFinishingOutletVarianceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyRawMaterialFinishingOutletVarianceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyRawMaterialFinishingOutletVarianceReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDailyRawMaterialFinishingOutletVarianceReportQuery(baseOptions?: Apollo.QueryHookOptions<DailyRawMaterialFinishingOutletVarianceReportQuery, DailyRawMaterialFinishingOutletVarianceReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyRawMaterialFinishingOutletVarianceReportQuery, DailyRawMaterialFinishingOutletVarianceReportQueryVariables>(DailyRawMaterialFinishingOutletVarianceReportDocument, options);
      }
export function useDailyRawMaterialFinishingOutletVarianceReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyRawMaterialFinishingOutletVarianceReportQuery, DailyRawMaterialFinishingOutletVarianceReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyRawMaterialFinishingOutletVarianceReportQuery, DailyRawMaterialFinishingOutletVarianceReportQueryVariables>(DailyRawMaterialFinishingOutletVarianceReportDocument, options);
        }
export type DailyRawMaterialFinishingOutletVarianceReportQueryHookResult = ReturnType<typeof useDailyRawMaterialFinishingOutletVarianceReportQuery>;
export type DailyRawMaterialFinishingOutletVarianceReportLazyQueryHookResult = ReturnType<typeof useDailyRawMaterialFinishingOutletVarianceReportLazyQuery>;
export type DailyRawMaterialFinishingOutletVarianceReportQueryResult = Apollo.QueryResult<DailyRawMaterialFinishingOutletVarianceReportQuery, DailyRawMaterialFinishingOutletVarianceReportQueryVariables>;
export const DailyRawMaterialKitchenVarianceReportDocument = gql`
    query DailyRawMaterialKitchenVarianceReport($where: DailyRawMaterialKitchenVarianceReportWhereInput) {
  dailyRawMaterialKitchenVarianceReport(where: $where) {
    category
    rawMaterial
    unit
    beginning
    fromStoreRoom
    throwAway
    theoretical
    shouldBe
    actualEnding
    variance
  }
}
    `;

/**
 * __useDailyRawMaterialKitchenVarianceReportQuery__
 *
 * To run a query within a React component, call `useDailyRawMaterialKitchenVarianceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyRawMaterialKitchenVarianceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyRawMaterialKitchenVarianceReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDailyRawMaterialKitchenVarianceReportQuery(baseOptions?: Apollo.QueryHookOptions<DailyRawMaterialKitchenVarianceReportQuery, DailyRawMaterialKitchenVarianceReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyRawMaterialKitchenVarianceReportQuery, DailyRawMaterialKitchenVarianceReportQueryVariables>(DailyRawMaterialKitchenVarianceReportDocument, options);
      }
export function useDailyRawMaterialKitchenVarianceReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyRawMaterialKitchenVarianceReportQuery, DailyRawMaterialKitchenVarianceReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyRawMaterialKitchenVarianceReportQuery, DailyRawMaterialKitchenVarianceReportQueryVariables>(DailyRawMaterialKitchenVarianceReportDocument, options);
        }
export type DailyRawMaterialKitchenVarianceReportQueryHookResult = ReturnType<typeof useDailyRawMaterialKitchenVarianceReportQuery>;
export type DailyRawMaterialKitchenVarianceReportLazyQueryHookResult = ReturnType<typeof useDailyRawMaterialKitchenVarianceReportLazyQuery>;
export type DailyRawMaterialKitchenVarianceReportQueryResult = Apollo.QueryResult<DailyRawMaterialKitchenVarianceReportQuery, DailyRawMaterialKitchenVarianceReportQueryVariables>;
export const AggregateDeliveryReceiptDocument = gql`
    query AggregateDeliveryReceipt($where: DeliveryReceiptWhereInput) {
  aggregateDeliveryReceipt(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateDeliveryReceiptQuery__
 *
 * To run a query within a React component, call `useAggregateDeliveryReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateDeliveryReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateDeliveryReceiptQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateDeliveryReceiptQuery(baseOptions?: Apollo.QueryHookOptions<AggregateDeliveryReceiptQuery, AggregateDeliveryReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateDeliveryReceiptQuery, AggregateDeliveryReceiptQueryVariables>(AggregateDeliveryReceiptDocument, options);
      }
export function useAggregateDeliveryReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateDeliveryReceiptQuery, AggregateDeliveryReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateDeliveryReceiptQuery, AggregateDeliveryReceiptQueryVariables>(AggregateDeliveryReceiptDocument, options);
        }
export type AggregateDeliveryReceiptQueryHookResult = ReturnType<typeof useAggregateDeliveryReceiptQuery>;
export type AggregateDeliveryReceiptLazyQueryHookResult = ReturnType<typeof useAggregateDeliveryReceiptLazyQuery>;
export type AggregateDeliveryReceiptQueryResult = Apollo.QueryResult<AggregateDeliveryReceiptQuery, AggregateDeliveryReceiptQueryVariables>;
export const CreateDeliveryReceiptDocument = gql`
    mutation CreateDeliveryReceipt($data: DeliveryReceiptCreateInput!) {
  createDeliveryReceipt(data: $data) {
    id
  }
}
    `;
export type CreateDeliveryReceiptMutationFn = Apollo.MutationFunction<CreateDeliveryReceiptMutation, CreateDeliveryReceiptMutationVariables>;

/**
 * __useCreateDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryReceiptMutation, { data, loading, error }] = useCreateDeliveryReceiptMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliveryReceiptMutation, CreateDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeliveryReceiptMutation, CreateDeliveryReceiptMutationVariables>(CreateDeliveryReceiptDocument, options);
      }
export type CreateDeliveryReceiptMutationHookResult = ReturnType<typeof useCreateDeliveryReceiptMutation>;
export type CreateDeliveryReceiptMutationResult = Apollo.MutationResult<CreateDeliveryReceiptMutation>;
export type CreateDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<CreateDeliveryReceiptMutation, CreateDeliveryReceiptMutationVariables>;
export const DeleteManyDeliveryReceiptDocument = gql`
    mutation DeleteManyDeliveryReceipt($where: [DeliveryReceiptWhereUniqueInput!]) {
  deleteManyDeliveryReceipt(where: $where) {
    id
  }
}
    `;
export type DeleteManyDeliveryReceiptMutationFn = Apollo.MutationFunction<DeleteManyDeliveryReceiptMutation, DeleteManyDeliveryReceiptMutationVariables>;

/**
 * __useDeleteManyDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteManyDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyDeliveryReceiptMutation, { data, loading, error }] = useDeleteManyDeliveryReceiptMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyDeliveryReceiptMutation, DeleteManyDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyDeliveryReceiptMutation, DeleteManyDeliveryReceiptMutationVariables>(DeleteManyDeliveryReceiptDocument, options);
      }
export type DeleteManyDeliveryReceiptMutationHookResult = ReturnType<typeof useDeleteManyDeliveryReceiptMutation>;
export type DeleteManyDeliveryReceiptMutationResult = Apollo.MutationResult<DeleteManyDeliveryReceiptMutation>;
export type DeleteManyDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<DeleteManyDeliveryReceiptMutation, DeleteManyDeliveryReceiptMutationVariables>;
export const DeliveryReceiptDocument = gql`
    query DeliveryReceipt($where: DeliveryReceiptWhereUniqueInput!) {
  deliveryReceipt(where: $where) {
    ...AllDeliveryReceiptFields
  }
}
    ${AllDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useDeliveryReceiptQuery__
 *
 * To run a query within a React component, call `useDeliveryReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryReceiptQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeliveryReceiptQuery(baseOptions: Apollo.QueryHookOptions<DeliveryReceiptQuery, DeliveryReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryReceiptQuery, DeliveryReceiptQueryVariables>(DeliveryReceiptDocument, options);
      }
export function useDeliveryReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryReceiptQuery, DeliveryReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryReceiptQuery, DeliveryReceiptQueryVariables>(DeliveryReceiptDocument, options);
        }
export type DeliveryReceiptQueryHookResult = ReturnType<typeof useDeliveryReceiptQuery>;
export type DeliveryReceiptLazyQueryHookResult = ReturnType<typeof useDeliveryReceiptLazyQuery>;
export type DeliveryReceiptQueryResult = Apollo.QueryResult<DeliveryReceiptQuery, DeliveryReceiptQueryVariables>;
export const DeliveryReceiptsDocument = gql`
    query DeliveryReceipts($skip: Int, $take: Int, $where: DeliveryReceiptWhereInput, $orderBy: [DeliveryReceiptOrderByWithRelationInput!]) {
  deliveryReceipts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllDeliveryReceiptFields
  }
}
    ${AllDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useDeliveryReceiptsQuery__
 *
 * To run a query within a React component, call `useDeliveryReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryReceiptsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDeliveryReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryReceiptsQuery, DeliveryReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryReceiptsQuery, DeliveryReceiptsQueryVariables>(DeliveryReceiptsDocument, options);
      }
export function useDeliveryReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryReceiptsQuery, DeliveryReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryReceiptsQuery, DeliveryReceiptsQueryVariables>(DeliveryReceiptsDocument, options);
        }
export type DeliveryReceiptsQueryHookResult = ReturnType<typeof useDeliveryReceiptsQuery>;
export type DeliveryReceiptsLazyQueryHookResult = ReturnType<typeof useDeliveryReceiptsLazyQuery>;
export type DeliveryReceiptsQueryResult = Apollo.QueryResult<DeliveryReceiptsQuery, DeliveryReceiptsQueryVariables>;
export const DeliveryReceiptsDropdownDocument = gql`
    query DeliveryReceiptsDropdown($skip: Int, $take: Int, $where: DeliveryReceiptWhereInput, $orderBy: [DeliveryReceiptOrderByWithRelationInput!]) {
  deliveryReceiptsDropdown(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllDeliveryReceiptFields
  }
}
    ${AllDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useDeliveryReceiptsDropdownQuery__
 *
 * To run a query within a React component, call `useDeliveryReceiptsDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryReceiptsDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryReceiptsDropdownQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDeliveryReceiptsDropdownQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryReceiptsDropdownQuery, DeliveryReceiptsDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryReceiptsDropdownQuery, DeliveryReceiptsDropdownQueryVariables>(DeliveryReceiptsDropdownDocument, options);
      }
export function useDeliveryReceiptsDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryReceiptsDropdownQuery, DeliveryReceiptsDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryReceiptsDropdownQuery, DeliveryReceiptsDropdownQueryVariables>(DeliveryReceiptsDropdownDocument, options);
        }
export type DeliveryReceiptsDropdownQueryHookResult = ReturnType<typeof useDeliveryReceiptsDropdownQuery>;
export type DeliveryReceiptsDropdownLazyQueryHookResult = ReturnType<typeof useDeliveryReceiptsDropdownLazyQuery>;
export type DeliveryReceiptsDropdownQueryResult = Apollo.QueryResult<DeliveryReceiptsDropdownQuery, DeliveryReceiptsDropdownQueryVariables>;
export const UpdateDeliveryReceiptDocument = gql`
    mutation UpdateDeliveryReceipt($where: DeliveryReceiptWhereUniqueInput!, $data: DeliveryReceiptUpdateInput!) {
  updateDeliveryReceipt(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateDeliveryReceiptMutationFn = Apollo.MutationFunction<UpdateDeliveryReceiptMutation, UpdateDeliveryReceiptMutationVariables>;

/**
 * __useUpdateDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryReceiptMutation, { data, loading, error }] = useUpdateDeliveryReceiptMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryReceiptMutation, UpdateDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryReceiptMutation, UpdateDeliveryReceiptMutationVariables>(UpdateDeliveryReceiptDocument, options);
      }
export type UpdateDeliveryReceiptMutationHookResult = ReturnType<typeof useUpdateDeliveryReceiptMutation>;
export type UpdateDeliveryReceiptMutationResult = Apollo.MutationResult<UpdateDeliveryReceiptMutation>;
export type UpdateDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryReceiptMutation, UpdateDeliveryReceiptMutationVariables>;
export const DeliveryReceiptItemsDocument = gql`
    query DeliveryReceiptItems($skip: Int, $take: Int, $where: DeliveryReceiptItemWhereInput, $orderBy: [DeliveryReceiptItemOrderByWithRelationInput!]) {
  deliveryReceiptItems(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllDeliveryReceiptItemFields
  }
}
    ${AllDeliveryReceiptItemFieldsFragmentDoc}`;

/**
 * __useDeliveryReceiptItemsQuery__
 *
 * To run a query within a React component, call `useDeliveryReceiptItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryReceiptItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryReceiptItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDeliveryReceiptItemsQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryReceiptItemsQuery, DeliveryReceiptItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryReceiptItemsQuery, DeliveryReceiptItemsQueryVariables>(DeliveryReceiptItemsDocument, options);
      }
export function useDeliveryReceiptItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryReceiptItemsQuery, DeliveryReceiptItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryReceiptItemsQuery, DeliveryReceiptItemsQueryVariables>(DeliveryReceiptItemsDocument, options);
        }
export type DeliveryReceiptItemsQueryHookResult = ReturnType<typeof useDeliveryReceiptItemsQuery>;
export type DeliveryReceiptItemsLazyQueryHookResult = ReturnType<typeof useDeliveryReceiptItemsLazyQuery>;
export type DeliveryReceiptItemsQueryResult = Apollo.QueryResult<DeliveryReceiptItemsQuery, DeliveryReceiptItemsQueryVariables>;
export const DonutDistributionPerOutletDocument = gql`
    query DonutDistributionPerOutlet($where: DonutDistributionPerOutletWhereInput) {
  donutDistributionPerOutlet(where: $where) {
    donutMixAndCut
    cmlProducts {
      cmlProduct
      perCmlProductDonutDistributions {
        name
        quantity
      }
    }
  }
}
    `;

/**
 * __useDonutDistributionPerOutletQuery__
 *
 * To run a query within a React component, call `useDonutDistributionPerOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonutDistributionPerOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonutDistributionPerOutletQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDonutDistributionPerOutletQuery(baseOptions?: Apollo.QueryHookOptions<DonutDistributionPerOutletQuery, DonutDistributionPerOutletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonutDistributionPerOutletQuery, DonutDistributionPerOutletQueryVariables>(DonutDistributionPerOutletDocument, options);
      }
export function useDonutDistributionPerOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonutDistributionPerOutletQuery, DonutDistributionPerOutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonutDistributionPerOutletQuery, DonutDistributionPerOutletQueryVariables>(DonutDistributionPerOutletDocument, options);
        }
export type DonutDistributionPerOutletQueryHookResult = ReturnType<typeof useDonutDistributionPerOutletQuery>;
export type DonutDistributionPerOutletLazyQueryHookResult = ReturnType<typeof useDonutDistributionPerOutletLazyQuery>;
export type DonutDistributionPerOutletQueryResult = Apollo.QueryResult<DonutDistributionPerOutletQuery, DonutDistributionPerOutletQueryVariables>;
export const DonutEndingConsolidationsDocument = gql`
    query DonutEndingConsolidations($where: DonutEndingConsolidationWhereInput) {
  donutEndingConsolidations(where: $where) {
    donutMixAndCut
    cmlProducts {
      cmlProduct
      perCmlProductDailyEndings {
        name
        quantity
      }
    }
  }
}
    `;

/**
 * __useDonutEndingConsolidationsQuery__
 *
 * To run a query within a React component, call `useDonutEndingConsolidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonutEndingConsolidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonutEndingConsolidationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDonutEndingConsolidationsQuery(baseOptions?: Apollo.QueryHookOptions<DonutEndingConsolidationsQuery, DonutEndingConsolidationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonutEndingConsolidationsQuery, DonutEndingConsolidationsQueryVariables>(DonutEndingConsolidationsDocument, options);
      }
export function useDonutEndingConsolidationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonutEndingConsolidationsQuery, DonutEndingConsolidationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonutEndingConsolidationsQuery, DonutEndingConsolidationsQueryVariables>(DonutEndingConsolidationsDocument, options);
        }
export type DonutEndingConsolidationsQueryHookResult = ReturnType<typeof useDonutEndingConsolidationsQuery>;
export type DonutEndingConsolidationsLazyQueryHookResult = ReturnType<typeof useDonutEndingConsolidationsLazyQuery>;
export type DonutEndingConsolidationsQueryResult = Apollo.QueryResult<DonutEndingConsolidationsQuery, DonutEndingConsolidationsQueryVariables>;
export const AggregateDonutMixAndCutDocument = gql`
    query AggregateDonutMixAndCut($where: DonutMixAndCutWhereInput) {
  aggregateDonutMixAndCut(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateDonutMixAndCutQuery__
 *
 * To run a query within a React component, call `useAggregateDonutMixAndCutQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateDonutMixAndCutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateDonutMixAndCutQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateDonutMixAndCutQuery(baseOptions?: Apollo.QueryHookOptions<AggregateDonutMixAndCutQuery, AggregateDonutMixAndCutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateDonutMixAndCutQuery, AggregateDonutMixAndCutQueryVariables>(AggregateDonutMixAndCutDocument, options);
      }
export function useAggregateDonutMixAndCutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateDonutMixAndCutQuery, AggregateDonutMixAndCutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateDonutMixAndCutQuery, AggregateDonutMixAndCutQueryVariables>(AggregateDonutMixAndCutDocument, options);
        }
export type AggregateDonutMixAndCutQueryHookResult = ReturnType<typeof useAggregateDonutMixAndCutQuery>;
export type AggregateDonutMixAndCutLazyQueryHookResult = ReturnType<typeof useAggregateDonutMixAndCutLazyQuery>;
export type AggregateDonutMixAndCutQueryResult = Apollo.QueryResult<AggregateDonutMixAndCutQuery, AggregateDonutMixAndCutQueryVariables>;
export const CreateDonutMixAndCutDocument = gql`
    mutation CreateDonutMixAndCut($data: DonutMixAndCutCreateInput!) {
  createDonutMixAndCut(data: $data) {
    id
  }
}
    `;
export type CreateDonutMixAndCutMutationFn = Apollo.MutationFunction<CreateDonutMixAndCutMutation, CreateDonutMixAndCutMutationVariables>;

/**
 * __useCreateDonutMixAndCutMutation__
 *
 * To run a mutation, you first call `useCreateDonutMixAndCutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonutMixAndCutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonutMixAndCutMutation, { data, loading, error }] = useCreateDonutMixAndCutMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDonutMixAndCutMutation(baseOptions?: Apollo.MutationHookOptions<CreateDonutMixAndCutMutation, CreateDonutMixAndCutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDonutMixAndCutMutation, CreateDonutMixAndCutMutationVariables>(CreateDonutMixAndCutDocument, options);
      }
export type CreateDonutMixAndCutMutationHookResult = ReturnType<typeof useCreateDonutMixAndCutMutation>;
export type CreateDonutMixAndCutMutationResult = Apollo.MutationResult<CreateDonutMixAndCutMutation>;
export type CreateDonutMixAndCutMutationOptions = Apollo.BaseMutationOptions<CreateDonutMixAndCutMutation, CreateDonutMixAndCutMutationVariables>;
export const DeleteManyDonutMixAndCutDocument = gql`
    mutation DeleteManyDonutMixAndCut($where: [DonutMixAndCutWhereUniqueInput!]) {
  deleteManyDonutMixAndCut(where: $where) {
    id
  }
}
    `;
export type DeleteManyDonutMixAndCutMutationFn = Apollo.MutationFunction<DeleteManyDonutMixAndCutMutation, DeleteManyDonutMixAndCutMutationVariables>;

/**
 * __useDeleteManyDonutMixAndCutMutation__
 *
 * To run a mutation, you first call `useDeleteManyDonutMixAndCutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyDonutMixAndCutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyDonutMixAndCutMutation, { data, loading, error }] = useDeleteManyDonutMixAndCutMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyDonutMixAndCutMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyDonutMixAndCutMutation, DeleteManyDonutMixAndCutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyDonutMixAndCutMutation, DeleteManyDonutMixAndCutMutationVariables>(DeleteManyDonutMixAndCutDocument, options);
      }
export type DeleteManyDonutMixAndCutMutationHookResult = ReturnType<typeof useDeleteManyDonutMixAndCutMutation>;
export type DeleteManyDonutMixAndCutMutationResult = Apollo.MutationResult<DeleteManyDonutMixAndCutMutation>;
export type DeleteManyDonutMixAndCutMutationOptions = Apollo.BaseMutationOptions<DeleteManyDonutMixAndCutMutation, DeleteManyDonutMixAndCutMutationVariables>;
export const DonutMixAndCutDocument = gql`
    query DonutMixAndCut($where: DonutMixAndCutWhereUniqueInput!) {
  donutMixAndCut(where: $where) {
    ...AllDonutMixAndCutFields
  }
}
    ${AllDonutMixAndCutFieldsFragmentDoc}`;

/**
 * __useDonutMixAndCutQuery__
 *
 * To run a query within a React component, call `useDonutMixAndCutQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonutMixAndCutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonutMixAndCutQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDonutMixAndCutQuery(baseOptions: Apollo.QueryHookOptions<DonutMixAndCutQuery, DonutMixAndCutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonutMixAndCutQuery, DonutMixAndCutQueryVariables>(DonutMixAndCutDocument, options);
      }
export function useDonutMixAndCutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonutMixAndCutQuery, DonutMixAndCutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonutMixAndCutQuery, DonutMixAndCutQueryVariables>(DonutMixAndCutDocument, options);
        }
export type DonutMixAndCutQueryHookResult = ReturnType<typeof useDonutMixAndCutQuery>;
export type DonutMixAndCutLazyQueryHookResult = ReturnType<typeof useDonutMixAndCutLazyQuery>;
export type DonutMixAndCutQueryResult = Apollo.QueryResult<DonutMixAndCutQuery, DonutMixAndCutQueryVariables>;
export const DonutMixAndCutsDocument = gql`
    query DonutMixAndCuts($skip: Int, $take: Int, $where: DonutMixAndCutWhereInput, $orderBy: [DonutMixAndCutOrderByWithRelationInput!]) {
  donutMixAndCuts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllDonutMixAndCutFields
  }
}
    ${AllDonutMixAndCutFieldsFragmentDoc}`;

/**
 * __useDonutMixAndCutsQuery__
 *
 * To run a query within a React component, call `useDonutMixAndCutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonutMixAndCutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonutMixAndCutsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDonutMixAndCutsQuery(baseOptions?: Apollo.QueryHookOptions<DonutMixAndCutsQuery, DonutMixAndCutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonutMixAndCutsQuery, DonutMixAndCutsQueryVariables>(DonutMixAndCutsDocument, options);
      }
export function useDonutMixAndCutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonutMixAndCutsQuery, DonutMixAndCutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonutMixAndCutsQuery, DonutMixAndCutsQueryVariables>(DonutMixAndCutsDocument, options);
        }
export type DonutMixAndCutsQueryHookResult = ReturnType<typeof useDonutMixAndCutsQuery>;
export type DonutMixAndCutsLazyQueryHookResult = ReturnType<typeof useDonutMixAndCutsLazyQuery>;
export type DonutMixAndCutsQueryResult = Apollo.QueryResult<DonutMixAndCutsQuery, DonutMixAndCutsQueryVariables>;
export const UpdateDonutMixAndCutDocument = gql`
    mutation UpdateDonutMixAndCut($where: DonutMixAndCutWhereUniqueInput!, $data: DonutMixAndCutUpdateInput!) {
  updateDonutMixAndCut(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateDonutMixAndCutMutationFn = Apollo.MutationFunction<UpdateDonutMixAndCutMutation, UpdateDonutMixAndCutMutationVariables>;

/**
 * __useUpdateDonutMixAndCutMutation__
 *
 * To run a mutation, you first call `useUpdateDonutMixAndCutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonutMixAndCutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonutMixAndCutMutation, { data, loading, error }] = useUpdateDonutMixAndCutMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDonutMixAndCutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDonutMixAndCutMutation, UpdateDonutMixAndCutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDonutMixAndCutMutation, UpdateDonutMixAndCutMutationVariables>(UpdateDonutMixAndCutDocument, options);
      }
export type UpdateDonutMixAndCutMutationHookResult = ReturnType<typeof useUpdateDonutMixAndCutMutation>;
export type UpdateDonutMixAndCutMutationResult = Apollo.MutationResult<UpdateDonutMixAndCutMutation>;
export type UpdateDonutMixAndCutMutationOptions = Apollo.BaseMutationOptions<UpdateDonutMixAndCutMutation, UpdateDonutMixAndCutMutationVariables>;
export const DonutsConsolidatedThrowAwayReportItemsDocument = gql`
    query DonutsConsolidatedThrowAwayReportItems($where: ConsolidatedThrowAwayReportItemWhereInput) {
  donutsConsolidatedThrowAwayReportItems(where: $where) {
    date
    donuts {
      name
      quantity
    }
  }
}
    `;

/**
 * __useDonutsConsolidatedThrowAwayReportItemsQuery__
 *
 * To run a query within a React component, call `useDonutsConsolidatedThrowAwayReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonutsConsolidatedThrowAwayReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonutsConsolidatedThrowAwayReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDonutsConsolidatedThrowAwayReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<DonutsConsolidatedThrowAwayReportItemsQuery, DonutsConsolidatedThrowAwayReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonutsConsolidatedThrowAwayReportItemsQuery, DonutsConsolidatedThrowAwayReportItemsQueryVariables>(DonutsConsolidatedThrowAwayReportItemsDocument, options);
      }
export function useDonutsConsolidatedThrowAwayReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonutsConsolidatedThrowAwayReportItemsQuery, DonutsConsolidatedThrowAwayReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonutsConsolidatedThrowAwayReportItemsQuery, DonutsConsolidatedThrowAwayReportItemsQueryVariables>(DonutsConsolidatedThrowAwayReportItemsDocument, options);
        }
export type DonutsConsolidatedThrowAwayReportItemsQueryHookResult = ReturnType<typeof useDonutsConsolidatedThrowAwayReportItemsQuery>;
export type DonutsConsolidatedThrowAwayReportItemsLazyQueryHookResult = ReturnType<typeof useDonutsConsolidatedThrowAwayReportItemsLazyQuery>;
export type DonutsConsolidatedThrowAwayReportItemsQueryResult = Apollo.QueryResult<DonutsConsolidatedThrowAwayReportItemsQuery, DonutsConsolidatedThrowAwayReportItemsQueryVariables>;
export const AggregateEndingSdEmTaDocument = gql`
    query AggregateEndingSdEmTa($where: EndingSdEmTaWhereInput) {
  aggregateEndingSdEmTa(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateEndingSdEmTaQuery__
 *
 * To run a query within a React component, call `useAggregateEndingSdEmTaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateEndingSdEmTaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateEndingSdEmTaQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateEndingSdEmTaQuery(baseOptions?: Apollo.QueryHookOptions<AggregateEndingSdEmTaQuery, AggregateEndingSdEmTaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateEndingSdEmTaQuery, AggregateEndingSdEmTaQueryVariables>(AggregateEndingSdEmTaDocument, options);
      }
export function useAggregateEndingSdEmTaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateEndingSdEmTaQuery, AggregateEndingSdEmTaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateEndingSdEmTaQuery, AggregateEndingSdEmTaQueryVariables>(AggregateEndingSdEmTaDocument, options);
        }
export type AggregateEndingSdEmTaQueryHookResult = ReturnType<typeof useAggregateEndingSdEmTaQuery>;
export type AggregateEndingSdEmTaLazyQueryHookResult = ReturnType<typeof useAggregateEndingSdEmTaLazyQuery>;
export type AggregateEndingSdEmTaQueryResult = Apollo.QueryResult<AggregateEndingSdEmTaQuery, AggregateEndingSdEmTaQueryVariables>;
export const CreateEndingSdEmTaDocument = gql`
    mutation CreateEndingSdEmTa($data: EndingSdEmTaCreateInput!) {
  createEndingSdEmTa(data: $data) {
    id
  }
}
    `;
export type CreateEndingSdEmTaMutationFn = Apollo.MutationFunction<CreateEndingSdEmTaMutation, CreateEndingSdEmTaMutationVariables>;

/**
 * __useCreateEndingSdEmTaMutation__
 *
 * To run a mutation, you first call `useCreateEndingSdEmTaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEndingSdEmTaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEndingSdEmTaMutation, { data, loading, error }] = useCreateEndingSdEmTaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEndingSdEmTaMutation(baseOptions?: Apollo.MutationHookOptions<CreateEndingSdEmTaMutation, CreateEndingSdEmTaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEndingSdEmTaMutation, CreateEndingSdEmTaMutationVariables>(CreateEndingSdEmTaDocument, options);
      }
export type CreateEndingSdEmTaMutationHookResult = ReturnType<typeof useCreateEndingSdEmTaMutation>;
export type CreateEndingSdEmTaMutationResult = Apollo.MutationResult<CreateEndingSdEmTaMutation>;
export type CreateEndingSdEmTaMutationOptions = Apollo.BaseMutationOptions<CreateEndingSdEmTaMutation, CreateEndingSdEmTaMutationVariables>;
export const DeleteManyEndingSdEmTaDocument = gql`
    mutation DeleteManyEndingSdEmTa($where: [EndingSdEmTaWhereUniqueInput!]) {
  deleteManyEndingSdEmTa(where: $where) {
    id
  }
}
    `;
export type DeleteManyEndingSdEmTaMutationFn = Apollo.MutationFunction<DeleteManyEndingSdEmTaMutation, DeleteManyEndingSdEmTaMutationVariables>;

/**
 * __useDeleteManyEndingSdEmTaMutation__
 *
 * To run a mutation, you first call `useDeleteManyEndingSdEmTaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyEndingSdEmTaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyEndingSdEmTaMutation, { data, loading, error }] = useDeleteManyEndingSdEmTaMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyEndingSdEmTaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyEndingSdEmTaMutation, DeleteManyEndingSdEmTaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyEndingSdEmTaMutation, DeleteManyEndingSdEmTaMutationVariables>(DeleteManyEndingSdEmTaDocument, options);
      }
export type DeleteManyEndingSdEmTaMutationHookResult = ReturnType<typeof useDeleteManyEndingSdEmTaMutation>;
export type DeleteManyEndingSdEmTaMutationResult = Apollo.MutationResult<DeleteManyEndingSdEmTaMutation>;
export type DeleteManyEndingSdEmTaMutationOptions = Apollo.BaseMutationOptions<DeleteManyEndingSdEmTaMutation, DeleteManyEndingSdEmTaMutationVariables>;
export const EndingSdEmTaDocument = gql`
    query EndingSdEmTa($where: EndingSdEmTaWhereUniqueInput!) {
  endingSdEmTa(where: $where) {
    ...AllEndingSdEmTaFields
  }
}
    ${AllEndingSdEmTaFieldsFragmentDoc}`;

/**
 * __useEndingSdEmTaQuery__
 *
 * To run a query within a React component, call `useEndingSdEmTaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEndingSdEmTaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEndingSdEmTaQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEndingSdEmTaQuery(baseOptions: Apollo.QueryHookOptions<EndingSdEmTaQuery, EndingSdEmTaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EndingSdEmTaQuery, EndingSdEmTaQueryVariables>(EndingSdEmTaDocument, options);
      }
export function useEndingSdEmTaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EndingSdEmTaQuery, EndingSdEmTaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EndingSdEmTaQuery, EndingSdEmTaQueryVariables>(EndingSdEmTaDocument, options);
        }
export type EndingSdEmTaQueryHookResult = ReturnType<typeof useEndingSdEmTaQuery>;
export type EndingSdEmTaLazyQueryHookResult = ReturnType<typeof useEndingSdEmTaLazyQuery>;
export type EndingSdEmTaQueryResult = Apollo.QueryResult<EndingSdEmTaQuery, EndingSdEmTaQueryVariables>;
export const EndingSdEmTasDocument = gql`
    query EndingSdEmTas($skip: Int, $take: Int, $where: EndingSdEmTaWhereInput, $orderBy: [EndingSdEmTaOrderByWithRelationInput!]) {
  endingSdEmTas(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllEndingSdEmTaFields
  }
}
    ${AllEndingSdEmTaFieldsFragmentDoc}`;

/**
 * __useEndingSdEmTasQuery__
 *
 * To run a query within a React component, call `useEndingSdEmTasQuery` and pass it any options that fit your needs.
 * When your component renders, `useEndingSdEmTasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEndingSdEmTasQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useEndingSdEmTasQuery(baseOptions?: Apollo.QueryHookOptions<EndingSdEmTasQuery, EndingSdEmTasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EndingSdEmTasQuery, EndingSdEmTasQueryVariables>(EndingSdEmTasDocument, options);
      }
export function useEndingSdEmTasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EndingSdEmTasQuery, EndingSdEmTasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EndingSdEmTasQuery, EndingSdEmTasQueryVariables>(EndingSdEmTasDocument, options);
        }
export type EndingSdEmTasQueryHookResult = ReturnType<typeof useEndingSdEmTasQuery>;
export type EndingSdEmTasLazyQueryHookResult = ReturnType<typeof useEndingSdEmTasLazyQuery>;
export type EndingSdEmTasQueryResult = Apollo.QueryResult<EndingSdEmTasQuery, EndingSdEmTasQueryVariables>;
export const UpdateEndingSdEmTaDocument = gql`
    mutation UpdateEndingSdEmTa($where: EndingSdEmTaWhereUniqueInput!, $data: EndingSdEmTaUpdateInput!) {
  updateEndingSdEmTa(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateEndingSdEmTaMutationFn = Apollo.MutationFunction<UpdateEndingSdEmTaMutation, UpdateEndingSdEmTaMutationVariables>;

/**
 * __useUpdateEndingSdEmTaMutation__
 *
 * To run a mutation, you first call `useUpdateEndingSdEmTaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEndingSdEmTaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEndingSdEmTaMutation, { data, loading, error }] = useUpdateEndingSdEmTaMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEndingSdEmTaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEndingSdEmTaMutation, UpdateEndingSdEmTaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEndingSdEmTaMutation, UpdateEndingSdEmTaMutationVariables>(UpdateEndingSdEmTaDocument, options);
      }
export type UpdateEndingSdEmTaMutationHookResult = ReturnType<typeof useUpdateEndingSdEmTaMutation>;
export type UpdateEndingSdEmTaMutationResult = Apollo.MutationResult<UpdateEndingSdEmTaMutation>;
export type UpdateEndingSdEmTaMutationOptions = Apollo.BaseMutationOptions<UpdateEndingSdEmTaMutation, UpdateEndingSdEmTaMutationVariables>;
export const AggregateMonthlyOutletPackagingInventoryDocument = gql`
    query AggregateMonthlyOutletPackagingInventory($where: MonthlyOutletPackagingInventoryWhereInput) {
  aggregateMonthlyOutletPackagingInventory(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateMonthlyOutletPackagingInventoryQuery__
 *
 * To run a query within a React component, call `useAggregateMonthlyOutletPackagingInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateMonthlyOutletPackagingInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateMonthlyOutletPackagingInventoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateMonthlyOutletPackagingInventoryQuery(baseOptions?: Apollo.QueryHookOptions<AggregateMonthlyOutletPackagingInventoryQuery, AggregateMonthlyOutletPackagingInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateMonthlyOutletPackagingInventoryQuery, AggregateMonthlyOutletPackagingInventoryQueryVariables>(AggregateMonthlyOutletPackagingInventoryDocument, options);
      }
export function useAggregateMonthlyOutletPackagingInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateMonthlyOutletPackagingInventoryQuery, AggregateMonthlyOutletPackagingInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateMonthlyOutletPackagingInventoryQuery, AggregateMonthlyOutletPackagingInventoryQueryVariables>(AggregateMonthlyOutletPackagingInventoryDocument, options);
        }
export type AggregateMonthlyOutletPackagingInventoryQueryHookResult = ReturnType<typeof useAggregateMonthlyOutletPackagingInventoryQuery>;
export type AggregateMonthlyOutletPackagingInventoryLazyQueryHookResult = ReturnType<typeof useAggregateMonthlyOutletPackagingInventoryLazyQuery>;
export type AggregateMonthlyOutletPackagingInventoryQueryResult = Apollo.QueryResult<AggregateMonthlyOutletPackagingInventoryQuery, AggregateMonthlyOutletPackagingInventoryQueryVariables>;
export const CreateManyMonthlyOutletPackagingInventoryDocument = gql`
    mutation CreateManyMonthlyOutletPackagingInventory($data: [MonthlyOutletPackagingInventoryCreateInput!]!) {
  createManyMonthlyOutletPackagingInventory(data: $data) {
    count
  }
}
    `;
export type CreateManyMonthlyOutletPackagingInventoryMutationFn = Apollo.MutationFunction<CreateManyMonthlyOutletPackagingInventoryMutation, CreateManyMonthlyOutletPackagingInventoryMutationVariables>;

/**
 * __useCreateManyMonthlyOutletPackagingInventoryMutation__
 *
 * To run a mutation, you first call `useCreateManyMonthlyOutletPackagingInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyMonthlyOutletPackagingInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyMonthlyOutletPackagingInventoryMutation, { data, loading, error }] = useCreateManyMonthlyOutletPackagingInventoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateManyMonthlyOutletPackagingInventoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyMonthlyOutletPackagingInventoryMutation, CreateManyMonthlyOutletPackagingInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyMonthlyOutletPackagingInventoryMutation, CreateManyMonthlyOutletPackagingInventoryMutationVariables>(CreateManyMonthlyOutletPackagingInventoryDocument, options);
      }
export type CreateManyMonthlyOutletPackagingInventoryMutationHookResult = ReturnType<typeof useCreateManyMonthlyOutletPackagingInventoryMutation>;
export type CreateManyMonthlyOutletPackagingInventoryMutationResult = Apollo.MutationResult<CreateManyMonthlyOutletPackagingInventoryMutation>;
export type CreateManyMonthlyOutletPackagingInventoryMutationOptions = Apollo.BaseMutationOptions<CreateManyMonthlyOutletPackagingInventoryMutation, CreateManyMonthlyOutletPackagingInventoryMutationVariables>;
export const CreateMonthlyOutletPackagingInventoryDocument = gql`
    mutation CreateMonthlyOutletPackagingInventory($data: MonthlyOutletPackagingInventoryCreateInput!) {
  createMonthlyOutletPackagingInventory(data: $data) {
    id
  }
}
    `;
export type CreateMonthlyOutletPackagingInventoryMutationFn = Apollo.MutationFunction<CreateMonthlyOutletPackagingInventoryMutation, CreateMonthlyOutletPackagingInventoryMutationVariables>;

/**
 * __useCreateMonthlyOutletPackagingInventoryMutation__
 *
 * To run a mutation, you first call `useCreateMonthlyOutletPackagingInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonthlyOutletPackagingInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonthlyOutletPackagingInventoryMutation, { data, loading, error }] = useCreateMonthlyOutletPackagingInventoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMonthlyOutletPackagingInventoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateMonthlyOutletPackagingInventoryMutation, CreateMonthlyOutletPackagingInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMonthlyOutletPackagingInventoryMutation, CreateMonthlyOutletPackagingInventoryMutationVariables>(CreateMonthlyOutletPackagingInventoryDocument, options);
      }
export type CreateMonthlyOutletPackagingInventoryMutationHookResult = ReturnType<typeof useCreateMonthlyOutletPackagingInventoryMutation>;
export type CreateMonthlyOutletPackagingInventoryMutationResult = Apollo.MutationResult<CreateMonthlyOutletPackagingInventoryMutation>;
export type CreateMonthlyOutletPackagingInventoryMutationOptions = Apollo.BaseMutationOptions<CreateMonthlyOutletPackagingInventoryMutation, CreateMonthlyOutletPackagingInventoryMutationVariables>;
export const DeleteManyMonthlyOutletPackagingInventoryDocument = gql`
    mutation DeleteManyMonthlyOutletPackagingInventory($where: [MonthlyOutletPackagingInventoryWhereUniqueInput!]) {
  deleteManyMonthlyOutletPackagingInventory(where: $where) {
    id
  }
}
    `;
export type DeleteManyMonthlyOutletPackagingInventoryMutationFn = Apollo.MutationFunction<DeleteManyMonthlyOutletPackagingInventoryMutation, DeleteManyMonthlyOutletPackagingInventoryMutationVariables>;

/**
 * __useDeleteManyMonthlyOutletPackagingInventoryMutation__
 *
 * To run a mutation, you first call `useDeleteManyMonthlyOutletPackagingInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyMonthlyOutletPackagingInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyMonthlyOutletPackagingInventoryMutation, { data, loading, error }] = useDeleteManyMonthlyOutletPackagingInventoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyMonthlyOutletPackagingInventoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyMonthlyOutletPackagingInventoryMutation, DeleteManyMonthlyOutletPackagingInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyMonthlyOutletPackagingInventoryMutation, DeleteManyMonthlyOutletPackagingInventoryMutationVariables>(DeleteManyMonthlyOutletPackagingInventoryDocument, options);
      }
export type DeleteManyMonthlyOutletPackagingInventoryMutationHookResult = ReturnType<typeof useDeleteManyMonthlyOutletPackagingInventoryMutation>;
export type DeleteManyMonthlyOutletPackagingInventoryMutationResult = Apollo.MutationResult<DeleteManyMonthlyOutletPackagingInventoryMutation>;
export type DeleteManyMonthlyOutletPackagingInventoryMutationOptions = Apollo.BaseMutationOptions<DeleteManyMonthlyOutletPackagingInventoryMutation, DeleteManyMonthlyOutletPackagingInventoryMutationVariables>;
export const MonthlyOutletPackagingInventoriesDocument = gql`
    query MonthlyOutletPackagingInventories($skip: Int, $take: Int, $where: MonthlyOutletPackagingInventoryWhereInput, $orderBy: [MonthlyOutletPackagingInventoryOrderByWithRelationInput!]) {
  monthlyOutletPackagingInventories(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllMonthlyOutletPackagingInventoryFields
  }
}
    ${AllMonthlyOutletPackagingInventoryFieldsFragmentDoc}`;

/**
 * __useMonthlyOutletPackagingInventoriesQuery__
 *
 * To run a query within a React component, call `useMonthlyOutletPackagingInventoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyOutletPackagingInventoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyOutletPackagingInventoriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useMonthlyOutletPackagingInventoriesQuery(baseOptions?: Apollo.QueryHookOptions<MonthlyOutletPackagingInventoriesQuery, MonthlyOutletPackagingInventoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonthlyOutletPackagingInventoriesQuery, MonthlyOutletPackagingInventoriesQueryVariables>(MonthlyOutletPackagingInventoriesDocument, options);
      }
export function useMonthlyOutletPackagingInventoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyOutletPackagingInventoriesQuery, MonthlyOutletPackagingInventoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonthlyOutletPackagingInventoriesQuery, MonthlyOutletPackagingInventoriesQueryVariables>(MonthlyOutletPackagingInventoriesDocument, options);
        }
export type MonthlyOutletPackagingInventoriesQueryHookResult = ReturnType<typeof useMonthlyOutletPackagingInventoriesQuery>;
export type MonthlyOutletPackagingInventoriesLazyQueryHookResult = ReturnType<typeof useMonthlyOutletPackagingInventoriesLazyQuery>;
export type MonthlyOutletPackagingInventoriesQueryResult = Apollo.QueryResult<MonthlyOutletPackagingInventoriesQuery, MonthlyOutletPackagingInventoriesQueryVariables>;
export const MonthlyOutletPackagingInventoryDocument = gql`
    query MonthlyOutletPackagingInventory($where: MonthlyOutletPackagingInventoryWhereUniqueInput!) {
  monthlyOutletPackagingInventory(where: $where) {
    ...AllMonthlyOutletPackagingInventoryFields
  }
}
    ${AllMonthlyOutletPackagingInventoryFieldsFragmentDoc}`;

/**
 * __useMonthlyOutletPackagingInventoryQuery__
 *
 * To run a query within a React component, call `useMonthlyOutletPackagingInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyOutletPackagingInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyOutletPackagingInventoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMonthlyOutletPackagingInventoryQuery(baseOptions: Apollo.QueryHookOptions<MonthlyOutletPackagingInventoryQuery, MonthlyOutletPackagingInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonthlyOutletPackagingInventoryQuery, MonthlyOutletPackagingInventoryQueryVariables>(MonthlyOutletPackagingInventoryDocument, options);
      }
export function useMonthlyOutletPackagingInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyOutletPackagingInventoryQuery, MonthlyOutletPackagingInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonthlyOutletPackagingInventoryQuery, MonthlyOutletPackagingInventoryQueryVariables>(MonthlyOutletPackagingInventoryDocument, options);
        }
export type MonthlyOutletPackagingInventoryQueryHookResult = ReturnType<typeof useMonthlyOutletPackagingInventoryQuery>;
export type MonthlyOutletPackagingInventoryLazyQueryHookResult = ReturnType<typeof useMonthlyOutletPackagingInventoryLazyQuery>;
export type MonthlyOutletPackagingInventoryQueryResult = Apollo.QueryResult<MonthlyOutletPackagingInventoryQuery, MonthlyOutletPackagingInventoryQueryVariables>;
export const UpdateMonthlyOutletPackagingInventoryDocument = gql`
    mutation UpdateMonthlyOutletPackagingInventory($where: MonthlyOutletPackagingInventoryWhereUniqueInput!, $data: MonthlyOutletPackagingInventoryUpdateInput!) {
  updateMonthlyOutletPackagingInventory(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateMonthlyOutletPackagingInventoryMutationFn = Apollo.MutationFunction<UpdateMonthlyOutletPackagingInventoryMutation, UpdateMonthlyOutletPackagingInventoryMutationVariables>;

/**
 * __useUpdateMonthlyOutletPackagingInventoryMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyOutletPackagingInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyOutletPackagingInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyOutletPackagingInventoryMutation, { data, loading, error }] = useUpdateMonthlyOutletPackagingInventoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMonthlyOutletPackagingInventoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMonthlyOutletPackagingInventoryMutation, UpdateMonthlyOutletPackagingInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMonthlyOutletPackagingInventoryMutation, UpdateMonthlyOutletPackagingInventoryMutationVariables>(UpdateMonthlyOutletPackagingInventoryDocument, options);
      }
export type UpdateMonthlyOutletPackagingInventoryMutationHookResult = ReturnType<typeof useUpdateMonthlyOutletPackagingInventoryMutation>;
export type UpdateMonthlyOutletPackagingInventoryMutationResult = Apollo.MutationResult<UpdateMonthlyOutletPackagingInventoryMutation>;
export type UpdateMonthlyOutletPackagingInventoryMutationOptions = Apollo.BaseMutationOptions<UpdateMonthlyOutletPackagingInventoryMutation, UpdateMonthlyOutletPackagingInventoryMutationVariables>;
export const AggregateOutletDocument = gql`
    query AggregateOutlet($where: OutletWhereInput) {
  aggregateOutlet(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateOutletQuery__
 *
 * To run a query within a React component, call `useAggregateOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateOutletQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateOutletQuery(baseOptions?: Apollo.QueryHookOptions<AggregateOutletQuery, AggregateOutletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateOutletQuery, AggregateOutletQueryVariables>(AggregateOutletDocument, options);
      }
export function useAggregateOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateOutletQuery, AggregateOutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateOutletQuery, AggregateOutletQueryVariables>(AggregateOutletDocument, options);
        }
export type AggregateOutletQueryHookResult = ReturnType<typeof useAggregateOutletQuery>;
export type AggregateOutletLazyQueryHookResult = ReturnType<typeof useAggregateOutletLazyQuery>;
export type AggregateOutletQueryResult = Apollo.QueryResult<AggregateOutletQuery, AggregateOutletQueryVariables>;
export const CreateOutletDocument = gql`
    mutation CreateOutlet($data: OutletCreateInput!) {
  createOutlet(data: $data) {
    id
  }
}
    `;
export type CreateOutletMutationFn = Apollo.MutationFunction<CreateOutletMutation, CreateOutletMutationVariables>;

/**
 * __useCreateOutletMutation__
 *
 * To run a mutation, you first call `useCreateOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutletMutation, { data, loading, error }] = useCreateOutletMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOutletMutation(baseOptions?: Apollo.MutationHookOptions<CreateOutletMutation, CreateOutletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOutletMutation, CreateOutletMutationVariables>(CreateOutletDocument, options);
      }
export type CreateOutletMutationHookResult = ReturnType<typeof useCreateOutletMutation>;
export type CreateOutletMutationResult = Apollo.MutationResult<CreateOutletMutation>;
export type CreateOutletMutationOptions = Apollo.BaseMutationOptions<CreateOutletMutation, CreateOutletMutationVariables>;
export const DeleteManyOutletDocument = gql`
    mutation DeleteManyOutlet($where: [OutletWhereUniqueInput!]) {
  deleteManyOutlet(where: $where) {
    id
  }
}
    `;
export type DeleteManyOutletMutationFn = Apollo.MutationFunction<DeleteManyOutletMutation, DeleteManyOutletMutationVariables>;

/**
 * __useDeleteManyOutletMutation__
 *
 * To run a mutation, you first call `useDeleteManyOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyOutletMutation, { data, loading, error }] = useDeleteManyOutletMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyOutletMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyOutletMutation, DeleteManyOutletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyOutletMutation, DeleteManyOutletMutationVariables>(DeleteManyOutletDocument, options);
      }
export type DeleteManyOutletMutationHookResult = ReturnType<typeof useDeleteManyOutletMutation>;
export type DeleteManyOutletMutationResult = Apollo.MutationResult<DeleteManyOutletMutation>;
export type DeleteManyOutletMutationOptions = Apollo.BaseMutationOptions<DeleteManyOutletMutation, DeleteManyOutletMutationVariables>;
export const OutletDocument = gql`
    query Outlet($where: OutletWhereUniqueInput!) {
  outlet(where: $where) {
    ...AllOutletFields
  }
}
    ${AllOutletFieldsFragmentDoc}`;

/**
 * __useOutletQuery__
 *
 * To run a query within a React component, call `useOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOutletQuery(baseOptions: Apollo.QueryHookOptions<OutletQuery, OutletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletQuery, OutletQueryVariables>(OutletDocument, options);
      }
export function useOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletQuery, OutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletQuery, OutletQueryVariables>(OutletDocument, options);
        }
export type OutletQueryHookResult = ReturnType<typeof useOutletQuery>;
export type OutletLazyQueryHookResult = ReturnType<typeof useOutletLazyQuery>;
export type OutletQueryResult = Apollo.QueryResult<OutletQuery, OutletQueryVariables>;
export const OutletsDocument = gql`
    query Outlets($skip: Int, $take: Int, $where: OutletWhereInput, $orderBy: [OutletOrderByWithRelationInput!]) {
  outlets(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllOutletFields
  }
}
    ${AllOutletFieldsFragmentDoc}`;

/**
 * __useOutletsQuery__
 *
 * To run a query within a React component, call `useOutletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOutletsQuery(baseOptions?: Apollo.QueryHookOptions<OutletsQuery, OutletsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletsQuery, OutletsQueryVariables>(OutletsDocument, options);
      }
export function useOutletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletsQuery, OutletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletsQuery, OutletsQueryVariables>(OutletsDocument, options);
        }
export type OutletsQueryHookResult = ReturnType<typeof useOutletsQuery>;
export type OutletsLazyQueryHookResult = ReturnType<typeof useOutletsLazyQuery>;
export type OutletsQueryResult = Apollo.QueryResult<OutletsQuery, OutletsQueryVariables>;
export const OutletsDropdownDocument = gql`
    query OutletsDropdown($skip: Int, $take: Int, $where: OutletWhereInput, $orderBy: [OutletOrderByWithRelationInput!]) {
  outletsDropdown(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllOutletFields
  }
}
    ${AllOutletFieldsFragmentDoc}`;

/**
 * __useOutletsDropdownQuery__
 *
 * To run a query within a React component, call `useOutletsDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletsDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletsDropdownQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOutletsDropdownQuery(baseOptions?: Apollo.QueryHookOptions<OutletsDropdownQuery, OutletsDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletsDropdownQuery, OutletsDropdownQueryVariables>(OutletsDropdownDocument, options);
      }
export function useOutletsDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletsDropdownQuery, OutletsDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletsDropdownQuery, OutletsDropdownQueryVariables>(OutletsDropdownDocument, options);
        }
export type OutletsDropdownQueryHookResult = ReturnType<typeof useOutletsDropdownQuery>;
export type OutletsDropdownLazyQueryHookResult = ReturnType<typeof useOutletsDropdownLazyQuery>;
export type OutletsDropdownQueryResult = Apollo.QueryResult<OutletsDropdownQuery, OutletsDropdownQueryVariables>;
export const UpdateOutletDocument = gql`
    mutation UpdateOutlet($where: OutletWhereUniqueInput!, $data: OutletUpdateInput!) {
  updateOutlet(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateOutletMutationFn = Apollo.MutationFunction<UpdateOutletMutation, UpdateOutletMutationVariables>;

/**
 * __useUpdateOutletMutation__
 *
 * To run a mutation, you first call `useUpdateOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutletMutation, { data, loading, error }] = useUpdateOutletMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOutletMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutletMutation, UpdateOutletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOutletMutation, UpdateOutletMutationVariables>(UpdateOutletDocument, options);
      }
export type UpdateOutletMutationHookResult = ReturnType<typeof useUpdateOutletMutation>;
export type UpdateOutletMutationResult = Apollo.MutationResult<UpdateOutletMutation>;
export type UpdateOutletMutationOptions = Apollo.BaseMutationOptions<UpdateOutletMutation, UpdateOutletMutationVariables>;
export const AggregateOutletFinishingDocument = gql`
    query AggregateOutletFinishing($where: OutletFinishingWhereInput) {
  aggregateOutletFinishing(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateOutletFinishingQuery__
 *
 * To run a query within a React component, call `useAggregateOutletFinishingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateOutletFinishingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateOutletFinishingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateOutletFinishingQuery(baseOptions?: Apollo.QueryHookOptions<AggregateOutletFinishingQuery, AggregateOutletFinishingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateOutletFinishingQuery, AggregateOutletFinishingQueryVariables>(AggregateOutletFinishingDocument, options);
      }
export function useAggregateOutletFinishingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateOutletFinishingQuery, AggregateOutletFinishingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateOutletFinishingQuery, AggregateOutletFinishingQueryVariables>(AggregateOutletFinishingDocument, options);
        }
export type AggregateOutletFinishingQueryHookResult = ReturnType<typeof useAggregateOutletFinishingQuery>;
export type AggregateOutletFinishingLazyQueryHookResult = ReturnType<typeof useAggregateOutletFinishingLazyQuery>;
export type AggregateOutletFinishingQueryResult = Apollo.QueryResult<AggregateOutletFinishingQuery, AggregateOutletFinishingQueryVariables>;
export const CreateOutletFinishingDocument = gql`
    mutation CreateOutletFinishing($data: OutletFinishingCreateInput!) {
  createOutletFinishing(data: $data) {
    id
  }
}
    `;
export type CreateOutletFinishingMutationFn = Apollo.MutationFunction<CreateOutletFinishingMutation, CreateOutletFinishingMutationVariables>;

/**
 * __useCreateOutletFinishingMutation__
 *
 * To run a mutation, you first call `useCreateOutletFinishingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutletFinishingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutletFinishingMutation, { data, loading, error }] = useCreateOutletFinishingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOutletFinishingMutation(baseOptions?: Apollo.MutationHookOptions<CreateOutletFinishingMutation, CreateOutletFinishingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOutletFinishingMutation, CreateOutletFinishingMutationVariables>(CreateOutletFinishingDocument, options);
      }
export type CreateOutletFinishingMutationHookResult = ReturnType<typeof useCreateOutletFinishingMutation>;
export type CreateOutletFinishingMutationResult = Apollo.MutationResult<CreateOutletFinishingMutation>;
export type CreateOutletFinishingMutationOptions = Apollo.BaseMutationOptions<CreateOutletFinishingMutation, CreateOutletFinishingMutationVariables>;
export const DeleteManyOutletFinishingDocument = gql`
    mutation DeleteManyOutletFinishing($where: [OutletFinishingWhereUniqueInput!]) {
  deleteManyOutletFinishing(where: $where) {
    id
  }
}
    `;
export type DeleteManyOutletFinishingMutationFn = Apollo.MutationFunction<DeleteManyOutletFinishingMutation, DeleteManyOutletFinishingMutationVariables>;

/**
 * __useDeleteManyOutletFinishingMutation__
 *
 * To run a mutation, you first call `useDeleteManyOutletFinishingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyOutletFinishingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyOutletFinishingMutation, { data, loading, error }] = useDeleteManyOutletFinishingMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyOutletFinishingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyOutletFinishingMutation, DeleteManyOutletFinishingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyOutletFinishingMutation, DeleteManyOutletFinishingMutationVariables>(DeleteManyOutletFinishingDocument, options);
      }
export type DeleteManyOutletFinishingMutationHookResult = ReturnType<typeof useDeleteManyOutletFinishingMutation>;
export type DeleteManyOutletFinishingMutationResult = Apollo.MutationResult<DeleteManyOutletFinishingMutation>;
export type DeleteManyOutletFinishingMutationOptions = Apollo.BaseMutationOptions<DeleteManyOutletFinishingMutation, DeleteManyOutletFinishingMutationVariables>;
export const FindFirstOutletFinishingDocument = gql`
    query FindFirstOutletFinishing($where: OutletFinishingWhereInput, $orderBy: [OutletFinishingOrderByWithRelationInput!]) {
  findFirstOutletFinishing(where: $where, orderBy: $orderBy) {
    ...AllOutletFinishingFields
  }
}
    ${AllOutletFinishingFieldsFragmentDoc}`;

/**
 * __useFindFirstOutletFinishingQuery__
 *
 * To run a query within a React component, call `useFindFirstOutletFinishingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstOutletFinishingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstOutletFinishingQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindFirstOutletFinishingQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstOutletFinishingQuery, FindFirstOutletFinishingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstOutletFinishingQuery, FindFirstOutletFinishingQueryVariables>(FindFirstOutletFinishingDocument, options);
      }
export function useFindFirstOutletFinishingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstOutletFinishingQuery, FindFirstOutletFinishingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstOutletFinishingQuery, FindFirstOutletFinishingQueryVariables>(FindFirstOutletFinishingDocument, options);
        }
export type FindFirstOutletFinishingQueryHookResult = ReturnType<typeof useFindFirstOutletFinishingQuery>;
export type FindFirstOutletFinishingLazyQueryHookResult = ReturnType<typeof useFindFirstOutletFinishingLazyQuery>;
export type FindFirstOutletFinishingQueryResult = Apollo.QueryResult<FindFirstOutletFinishingQuery, FindFirstOutletFinishingQueryVariables>;
export const OutletFinishingDocument = gql`
    query OutletFinishing($where: OutletFinishingWhereUniqueInput!) {
  outletFinishing(where: $where) {
    ...AllOutletFinishingFields
  }
}
    ${AllOutletFinishingFieldsFragmentDoc}`;

/**
 * __useOutletFinishingQuery__
 *
 * To run a query within a React component, call `useOutletFinishingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletFinishingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletFinishingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOutletFinishingQuery(baseOptions: Apollo.QueryHookOptions<OutletFinishingQuery, OutletFinishingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletFinishingQuery, OutletFinishingQueryVariables>(OutletFinishingDocument, options);
      }
export function useOutletFinishingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletFinishingQuery, OutletFinishingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletFinishingQuery, OutletFinishingQueryVariables>(OutletFinishingDocument, options);
        }
export type OutletFinishingQueryHookResult = ReturnType<typeof useOutletFinishingQuery>;
export type OutletFinishingLazyQueryHookResult = ReturnType<typeof useOutletFinishingLazyQuery>;
export type OutletFinishingQueryResult = Apollo.QueryResult<OutletFinishingQuery, OutletFinishingQueryVariables>;
export const OutletFinishingsDocument = gql`
    query OutletFinishings($skip: Int, $take: Int, $where: OutletFinishingWhereInput, $orderBy: [OutletFinishingOrderByWithRelationInput!]) {
  outletFinishings(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllOutletFinishingFields
  }
}
    ${AllOutletFinishingFieldsFragmentDoc}`;

/**
 * __useOutletFinishingsQuery__
 *
 * To run a query within a React component, call `useOutletFinishingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletFinishingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletFinishingsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOutletFinishingsQuery(baseOptions?: Apollo.QueryHookOptions<OutletFinishingsQuery, OutletFinishingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletFinishingsQuery, OutletFinishingsQueryVariables>(OutletFinishingsDocument, options);
      }
export function useOutletFinishingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletFinishingsQuery, OutletFinishingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletFinishingsQuery, OutletFinishingsQueryVariables>(OutletFinishingsDocument, options);
        }
export type OutletFinishingsQueryHookResult = ReturnType<typeof useOutletFinishingsQuery>;
export type OutletFinishingsLazyQueryHookResult = ReturnType<typeof useOutletFinishingsLazyQuery>;
export type OutletFinishingsQueryResult = Apollo.QueryResult<OutletFinishingsQuery, OutletFinishingsQueryVariables>;
export const UpdateOutletFinishingDocument = gql`
    mutation UpdateOutletFinishing($where: OutletFinishingWhereUniqueInput!, $data: OutletFinishingUpdateInput!) {
  updateOutletFinishing(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateOutletFinishingMutationFn = Apollo.MutationFunction<UpdateOutletFinishingMutation, UpdateOutletFinishingMutationVariables>;

/**
 * __useUpdateOutletFinishingMutation__
 *
 * To run a mutation, you first call `useUpdateOutletFinishingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutletFinishingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutletFinishingMutation, { data, loading, error }] = useUpdateOutletFinishingMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOutletFinishingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutletFinishingMutation, UpdateOutletFinishingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOutletFinishingMutation, UpdateOutletFinishingMutationVariables>(UpdateOutletFinishingDocument, options);
      }
export type UpdateOutletFinishingMutationHookResult = ReturnType<typeof useUpdateOutletFinishingMutation>;
export type UpdateOutletFinishingMutationResult = Apollo.MutationResult<UpdateOutletFinishingMutation>;
export type UpdateOutletFinishingMutationOptions = Apollo.BaseMutationOptions<UpdateOutletFinishingMutation, UpdateOutletFinishingMutationVariables>;
export const OutletPackagingEndingConsolidationsDocument = gql`
    query OutletPackagingEndingConsolidations($where: OutletPackagingEndingConsolidationWhereInput) {
  outletPackagingEndingConsolidations(where: $where) {
    packaging
    category
    unit
    quantity
  }
}
    `;

/**
 * __useOutletPackagingEndingConsolidationsQuery__
 *
 * To run a query within a React component, call `useOutletPackagingEndingConsolidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletPackagingEndingConsolidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletPackagingEndingConsolidationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOutletPackagingEndingConsolidationsQuery(baseOptions?: Apollo.QueryHookOptions<OutletPackagingEndingConsolidationsQuery, OutletPackagingEndingConsolidationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletPackagingEndingConsolidationsQuery, OutletPackagingEndingConsolidationsQueryVariables>(OutletPackagingEndingConsolidationsDocument, options);
      }
export function useOutletPackagingEndingConsolidationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletPackagingEndingConsolidationsQuery, OutletPackagingEndingConsolidationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletPackagingEndingConsolidationsQuery, OutletPackagingEndingConsolidationsQueryVariables>(OutletPackagingEndingConsolidationsDocument, options);
        }
export type OutletPackagingEndingConsolidationsQueryHookResult = ReturnType<typeof useOutletPackagingEndingConsolidationsQuery>;
export type OutletPackagingEndingConsolidationsLazyQueryHookResult = ReturnType<typeof useOutletPackagingEndingConsolidationsLazyQuery>;
export type OutletPackagingEndingConsolidationsQueryResult = Apollo.QueryResult<OutletPackagingEndingConsolidationsQuery, OutletPackagingEndingConsolidationsQueryVariables>;
export const AggregatePackagingDocument = gql`
    query AggregatePackaging($where: PackagingWhereInput) {
  aggregatePackaging(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregatePackagingQuery__
 *
 * To run a query within a React component, call `useAggregatePackagingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatePackagingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatePackagingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregatePackagingQuery(baseOptions?: Apollo.QueryHookOptions<AggregatePackagingQuery, AggregatePackagingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregatePackagingQuery, AggregatePackagingQueryVariables>(AggregatePackagingDocument, options);
      }
export function useAggregatePackagingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregatePackagingQuery, AggregatePackagingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregatePackagingQuery, AggregatePackagingQueryVariables>(AggregatePackagingDocument, options);
        }
export type AggregatePackagingQueryHookResult = ReturnType<typeof useAggregatePackagingQuery>;
export type AggregatePackagingLazyQueryHookResult = ReturnType<typeof useAggregatePackagingLazyQuery>;
export type AggregatePackagingQueryResult = Apollo.QueryResult<AggregatePackagingQuery, AggregatePackagingQueryVariables>;
export const CreatePackagingDocument = gql`
    mutation CreatePackaging($data: PackagingCreateInput!) {
  createPackaging(data: $data) {
    id
  }
}
    `;
export type CreatePackagingMutationFn = Apollo.MutationFunction<CreatePackagingMutation, CreatePackagingMutationVariables>;

/**
 * __useCreatePackagingMutation__
 *
 * To run a mutation, you first call `useCreatePackagingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackagingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackagingMutation, { data, loading, error }] = useCreatePackagingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePackagingMutation(baseOptions?: Apollo.MutationHookOptions<CreatePackagingMutation, CreatePackagingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePackagingMutation, CreatePackagingMutationVariables>(CreatePackagingDocument, options);
      }
export type CreatePackagingMutationHookResult = ReturnType<typeof useCreatePackagingMutation>;
export type CreatePackagingMutationResult = Apollo.MutationResult<CreatePackagingMutation>;
export type CreatePackagingMutationOptions = Apollo.BaseMutationOptions<CreatePackagingMutation, CreatePackagingMutationVariables>;
export const DeleteManyPackagingDocument = gql`
    mutation DeleteManyPackaging($where: [PackagingWhereUniqueInput!]) {
  deleteManyPackaging(where: $where) {
    id
  }
}
    `;
export type DeleteManyPackagingMutationFn = Apollo.MutationFunction<DeleteManyPackagingMutation, DeleteManyPackagingMutationVariables>;

/**
 * __useDeleteManyPackagingMutation__
 *
 * To run a mutation, you first call `useDeleteManyPackagingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyPackagingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyPackagingMutation, { data, loading, error }] = useDeleteManyPackagingMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyPackagingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyPackagingMutation, DeleteManyPackagingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyPackagingMutation, DeleteManyPackagingMutationVariables>(DeleteManyPackagingDocument, options);
      }
export type DeleteManyPackagingMutationHookResult = ReturnType<typeof useDeleteManyPackagingMutation>;
export type DeleteManyPackagingMutationResult = Apollo.MutationResult<DeleteManyPackagingMutation>;
export type DeleteManyPackagingMutationOptions = Apollo.BaseMutationOptions<DeleteManyPackagingMutation, DeleteManyPackagingMutationVariables>;
export const PackagingDocument = gql`
    query Packaging($where: PackagingWhereUniqueInput!) {
  packaging(where: $where) {
    ...AllPackagingFields
  }
}
    ${AllPackagingFieldsFragmentDoc}`;

/**
 * __usePackagingQuery__
 *
 * To run a query within a React component, call `usePackagingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePackagingQuery(baseOptions: Apollo.QueryHookOptions<PackagingQuery, PackagingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagingQuery, PackagingQueryVariables>(PackagingDocument, options);
      }
export function usePackagingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagingQuery, PackagingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagingQuery, PackagingQueryVariables>(PackagingDocument, options);
        }
export type PackagingQueryHookResult = ReturnType<typeof usePackagingQuery>;
export type PackagingLazyQueryHookResult = ReturnType<typeof usePackagingLazyQuery>;
export type PackagingQueryResult = Apollo.QueryResult<PackagingQuery, PackagingQueryVariables>;
export const PackagingsDocument = gql`
    query Packagings($skip: Int, $take: Int, $where: PackagingWhereInput, $orderBy: [PackagingOrderByWithRelationInput!]) {
  packagings(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllPackagingFields
  }
}
    ${AllPackagingFieldsFragmentDoc}`;

/**
 * __usePackagingsQuery__
 *
 * To run a query within a React component, call `usePackagingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagingsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePackagingsQuery(baseOptions?: Apollo.QueryHookOptions<PackagingsQuery, PackagingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagingsQuery, PackagingsQueryVariables>(PackagingsDocument, options);
      }
export function usePackagingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagingsQuery, PackagingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagingsQuery, PackagingsQueryVariables>(PackagingsDocument, options);
        }
export type PackagingsQueryHookResult = ReturnType<typeof usePackagingsQuery>;
export type PackagingsLazyQueryHookResult = ReturnType<typeof usePackagingsLazyQuery>;
export type PackagingsQueryResult = Apollo.QueryResult<PackagingsQuery, PackagingsQueryVariables>;
export const PackagingsAllowDocument = gql`
    query PackagingsAllow($skip: Int, $take: Int, $where: PackagingWhereInput, $orderBy: [PackagingOrderByWithRelationInput!]) {
  packagings(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    name
    packagingCategory
    packagingProducts {
      id
      unit {
        id
        name
      }
      conversionToBaseUnit
      baseUnit
    }
  }
}
    `;

/**
 * __usePackagingsAllowQuery__
 *
 * To run a query within a React component, call `usePackagingsAllowQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagingsAllowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagingsAllowQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePackagingsAllowQuery(baseOptions?: Apollo.QueryHookOptions<PackagingsAllowQuery, PackagingsAllowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagingsAllowQuery, PackagingsAllowQueryVariables>(PackagingsAllowDocument, options);
      }
export function usePackagingsAllowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagingsAllowQuery, PackagingsAllowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagingsAllowQuery, PackagingsAllowQueryVariables>(PackagingsAllowDocument, options);
        }
export type PackagingsAllowQueryHookResult = ReturnType<typeof usePackagingsAllowQuery>;
export type PackagingsAllowLazyQueryHookResult = ReturnType<typeof usePackagingsAllowLazyQuery>;
export type PackagingsAllowQueryResult = Apollo.QueryResult<PackagingsAllowQuery, PackagingsAllowQueryVariables>;
export const PackagingsDropdownDocument = gql`
    query PackagingsDropdown($skip: Int, $take: Int, $where: PackagingWhereInput, $orderBy: [PackagingOrderByWithRelationInput!]) {
  packagingsDropdown(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    name
    packagingCategory
    packagingProducts {
      id
      unit {
        id
        name
      }
      conversionToBaseUnit
      baseUnit
    }
  }
}
    `;

/**
 * __usePackagingsDropdownQuery__
 *
 * To run a query within a React component, call `usePackagingsDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagingsDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagingsDropdownQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePackagingsDropdownQuery(baseOptions?: Apollo.QueryHookOptions<PackagingsDropdownQuery, PackagingsDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagingsDropdownQuery, PackagingsDropdownQueryVariables>(PackagingsDropdownDocument, options);
      }
export function usePackagingsDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagingsDropdownQuery, PackagingsDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagingsDropdownQuery, PackagingsDropdownQueryVariables>(PackagingsDropdownDocument, options);
        }
export type PackagingsDropdownQueryHookResult = ReturnType<typeof usePackagingsDropdownQuery>;
export type PackagingsDropdownLazyQueryHookResult = ReturnType<typeof usePackagingsDropdownLazyQuery>;
export type PackagingsDropdownQueryResult = Apollo.QueryResult<PackagingsDropdownQuery, PackagingsDropdownQueryVariables>;
export const UpdatePackagingDocument = gql`
    mutation UpdatePackaging($where: PackagingWhereUniqueInput!, $data: PackagingUpdateInput!) {
  updatePackaging(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdatePackagingMutationFn = Apollo.MutationFunction<UpdatePackagingMutation, UpdatePackagingMutationVariables>;

/**
 * __useUpdatePackagingMutation__
 *
 * To run a mutation, you first call `useUpdatePackagingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackagingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackagingMutation, { data, loading, error }] = useUpdatePackagingMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePackagingMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePackagingMutation, UpdatePackagingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePackagingMutation, UpdatePackagingMutationVariables>(UpdatePackagingDocument, options);
      }
export type UpdatePackagingMutationHookResult = ReturnType<typeof useUpdatePackagingMutation>;
export type UpdatePackagingMutationResult = Apollo.MutationResult<UpdatePackagingMutation>;
export type UpdatePackagingMutationOptions = Apollo.BaseMutationOptions<UpdatePackagingMutation, UpdatePackagingMutationVariables>;
export const PackagingProductsDocument = gql`
    query PackagingProducts($skip: Int, $take: Int, $where: PackagingProductWhereInput, $orderBy: [PackagingProductOrderByWithRelationInput!]) {
  packagingProducts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllPackagingProductFields
  }
}
    ${AllPackagingProductFieldsFragmentDoc}`;

/**
 * __usePackagingProductsQuery__
 *
 * To run a query within a React component, call `usePackagingProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagingProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagingProductsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePackagingProductsQuery(baseOptions?: Apollo.QueryHookOptions<PackagingProductsQuery, PackagingProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagingProductsQuery, PackagingProductsQueryVariables>(PackagingProductsDocument, options);
      }
export function usePackagingProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagingProductsQuery, PackagingProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagingProductsQuery, PackagingProductsQueryVariables>(PackagingProductsDocument, options);
        }
export type PackagingProductsQueryHookResult = ReturnType<typeof usePackagingProductsQuery>;
export type PackagingProductsLazyQueryHookResult = ReturnType<typeof usePackagingProductsLazyQuery>;
export type PackagingProductsQueryResult = Apollo.QueryResult<PackagingProductsQuery, PackagingProductsQueryVariables>;
export const PackagingProductsAllowedDocument = gql`
    query PackagingProductsAllowed($skip: Int, $take: Int, $where: PackagingProductWhereInput, $orderBy: [PackagingProductOrderByWithRelationInput!]) {
  packagingProducts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    packaging {
      id
      packagingCategory
      name
    }
    unit {
      id
      name
    }
  }
}
    `;

/**
 * __usePackagingProductsAllowedQuery__
 *
 * To run a query within a React component, call `usePackagingProductsAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagingProductsAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagingProductsAllowedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePackagingProductsAllowedQuery(baseOptions?: Apollo.QueryHookOptions<PackagingProductsAllowedQuery, PackagingProductsAllowedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagingProductsAllowedQuery, PackagingProductsAllowedQueryVariables>(PackagingProductsAllowedDocument, options);
      }
export function usePackagingProductsAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagingProductsAllowedQuery, PackagingProductsAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagingProductsAllowedQuery, PackagingProductsAllowedQueryVariables>(PackagingProductsAllowedDocument, options);
        }
export type PackagingProductsAllowedQueryHookResult = ReturnType<typeof usePackagingProductsAllowedQuery>;
export type PackagingProductsAllowedLazyQueryHookResult = ReturnType<typeof usePackagingProductsAllowedLazyQuery>;
export type PackagingProductsAllowedQueryResult = Apollo.QueryResult<PackagingProductsAllowedQuery, PackagingProductsAllowedQueryVariables>;
export const AggregatePackagingRequestDocument = gql`
    query AggregatePackagingRequest($where: PackagingRequestWhereInput) {
  aggregatePackagingRequest(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregatePackagingRequestQuery__
 *
 * To run a query within a React component, call `useAggregatePackagingRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatePackagingRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatePackagingRequestQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregatePackagingRequestQuery(baseOptions?: Apollo.QueryHookOptions<AggregatePackagingRequestQuery, AggregatePackagingRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregatePackagingRequestQuery, AggregatePackagingRequestQueryVariables>(AggregatePackagingRequestDocument, options);
      }
export function useAggregatePackagingRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregatePackagingRequestQuery, AggregatePackagingRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregatePackagingRequestQuery, AggregatePackagingRequestQueryVariables>(AggregatePackagingRequestDocument, options);
        }
export type AggregatePackagingRequestQueryHookResult = ReturnType<typeof useAggregatePackagingRequestQuery>;
export type AggregatePackagingRequestLazyQueryHookResult = ReturnType<typeof useAggregatePackagingRequestLazyQuery>;
export type AggregatePackagingRequestQueryResult = Apollo.QueryResult<AggregatePackagingRequestQuery, AggregatePackagingRequestQueryVariables>;
export const CreatePackagingRequestDocument = gql`
    mutation CreatePackagingRequest($data: PackagingRequestCreateInput!) {
  createPackagingRequest(data: $data) {
    id
  }
}
    `;
export type CreatePackagingRequestMutationFn = Apollo.MutationFunction<CreatePackagingRequestMutation, CreatePackagingRequestMutationVariables>;

/**
 * __useCreatePackagingRequestMutation__
 *
 * To run a mutation, you first call `useCreatePackagingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackagingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackagingRequestMutation, { data, loading, error }] = useCreatePackagingRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePackagingRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreatePackagingRequestMutation, CreatePackagingRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePackagingRequestMutation, CreatePackagingRequestMutationVariables>(CreatePackagingRequestDocument, options);
      }
export type CreatePackagingRequestMutationHookResult = ReturnType<typeof useCreatePackagingRequestMutation>;
export type CreatePackagingRequestMutationResult = Apollo.MutationResult<CreatePackagingRequestMutation>;
export type CreatePackagingRequestMutationOptions = Apollo.BaseMutationOptions<CreatePackagingRequestMutation, CreatePackagingRequestMutationVariables>;
export const DeleteManyPackagingRequestDocument = gql`
    mutation DeleteManyPackagingRequest($where: [PackagingRequestWhereUniqueInput!]) {
  deleteManyPackagingRequest(where: $where) {
    id
  }
}
    `;
export type DeleteManyPackagingRequestMutationFn = Apollo.MutationFunction<DeleteManyPackagingRequestMutation, DeleteManyPackagingRequestMutationVariables>;

/**
 * __useDeleteManyPackagingRequestMutation__
 *
 * To run a mutation, you first call `useDeleteManyPackagingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyPackagingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyPackagingRequestMutation, { data, loading, error }] = useDeleteManyPackagingRequestMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyPackagingRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyPackagingRequestMutation, DeleteManyPackagingRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyPackagingRequestMutation, DeleteManyPackagingRequestMutationVariables>(DeleteManyPackagingRequestDocument, options);
      }
export type DeleteManyPackagingRequestMutationHookResult = ReturnType<typeof useDeleteManyPackagingRequestMutation>;
export type DeleteManyPackagingRequestMutationResult = Apollo.MutationResult<DeleteManyPackagingRequestMutation>;
export type DeleteManyPackagingRequestMutationOptions = Apollo.BaseMutationOptions<DeleteManyPackagingRequestMutation, DeleteManyPackagingRequestMutationVariables>;
export const PackagingRequestDocument = gql`
    query PackagingRequest($where: PackagingRequestWhereUniqueInput!) {
  packagingRequest(where: $where) {
    id
    requestDate
    neededDate
    controlNumber
    outlet {
      id
      name
    }
    createdBy
    packagingRequestItems {
      id
      quantity
      packagingProduct {
        id
        packaging {
          id
          name
          packagingProducts {
            id
            baseUnit
            unit {
              id
              name
            }
          }
        }
        unit {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePackagingRequestQuery__
 *
 * To run a query within a React component, call `usePackagingRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagingRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagingRequestQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePackagingRequestQuery(baseOptions: Apollo.QueryHookOptions<PackagingRequestQuery, PackagingRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagingRequestQuery, PackagingRequestQueryVariables>(PackagingRequestDocument, options);
      }
export function usePackagingRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagingRequestQuery, PackagingRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagingRequestQuery, PackagingRequestQueryVariables>(PackagingRequestDocument, options);
        }
export type PackagingRequestQueryHookResult = ReturnType<typeof usePackagingRequestQuery>;
export type PackagingRequestLazyQueryHookResult = ReturnType<typeof usePackagingRequestLazyQuery>;
export type PackagingRequestQueryResult = Apollo.QueryResult<PackagingRequestQuery, PackagingRequestQueryVariables>;
export const PackagingRequestsDocument = gql`
    query PackagingRequests($skip: Int, $take: Int, $where: PackagingRequestWhereInput, $orderBy: [PackagingRequestOrderByWithRelationInput!]) {
  packagingRequests(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllPackagingRequestFields
  }
}
    ${AllPackagingRequestFieldsFragmentDoc}`;

/**
 * __usePackagingRequestsQuery__
 *
 * To run a query within a React component, call `usePackagingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagingRequestsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePackagingRequestsQuery(baseOptions?: Apollo.QueryHookOptions<PackagingRequestsQuery, PackagingRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagingRequestsQuery, PackagingRequestsQueryVariables>(PackagingRequestsDocument, options);
      }
export function usePackagingRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagingRequestsQuery, PackagingRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagingRequestsQuery, PackagingRequestsQueryVariables>(PackagingRequestsDocument, options);
        }
export type PackagingRequestsQueryHookResult = ReturnType<typeof usePackagingRequestsQuery>;
export type PackagingRequestsLazyQueryHookResult = ReturnType<typeof usePackagingRequestsLazyQuery>;
export type PackagingRequestsQueryResult = Apollo.QueryResult<PackagingRequestsQuery, PackagingRequestsQueryVariables>;
export const UpdatePackagingRequestDocument = gql`
    mutation UpdatePackagingRequest($where: PackagingRequestWhereUniqueInput!, $data: PackagingRequestUpdateInput!) {
  updatePackagingRequest(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdatePackagingRequestMutationFn = Apollo.MutationFunction<UpdatePackagingRequestMutation, UpdatePackagingRequestMutationVariables>;

/**
 * __useUpdatePackagingRequestMutation__
 *
 * To run a mutation, you first call `useUpdatePackagingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackagingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackagingRequestMutation, { data, loading, error }] = useUpdatePackagingRequestMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePackagingRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePackagingRequestMutation, UpdatePackagingRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePackagingRequestMutation, UpdatePackagingRequestMutationVariables>(UpdatePackagingRequestDocument, options);
      }
export type UpdatePackagingRequestMutationHookResult = ReturnType<typeof useUpdatePackagingRequestMutation>;
export type UpdatePackagingRequestMutationResult = Apollo.MutationResult<UpdatePackagingRequestMutation>;
export type UpdatePackagingRequestMutationOptions = Apollo.BaseMutationOptions<UpdatePackagingRequestMutation, UpdatePackagingRequestMutationVariables>;
export const AggregateProjectionDocument = gql`
    query AggregateProjection($where: ProjectionWhereInput) {
  aggregateProjection(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateProjectionQuery__
 *
 * To run a query within a React component, call `useAggregateProjectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateProjectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateProjectionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateProjectionQuery(baseOptions?: Apollo.QueryHookOptions<AggregateProjectionQuery, AggregateProjectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateProjectionQuery, AggregateProjectionQueryVariables>(AggregateProjectionDocument, options);
      }
export function useAggregateProjectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateProjectionQuery, AggregateProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateProjectionQuery, AggregateProjectionQueryVariables>(AggregateProjectionDocument, options);
        }
export type AggregateProjectionQueryHookResult = ReturnType<typeof useAggregateProjectionQuery>;
export type AggregateProjectionLazyQueryHookResult = ReturnType<typeof useAggregateProjectionLazyQuery>;
export type AggregateProjectionQueryResult = Apollo.QueryResult<AggregateProjectionQuery, AggregateProjectionQueryVariables>;
export const CreateProjectionDocument = gql`
    mutation CreateProjection($data: ProjectionCreateInput!) {
  createProjection(data: $data) {
    id
  }
}
    `;
export type CreateProjectionMutationFn = Apollo.MutationFunction<CreateProjectionMutation, CreateProjectionMutationVariables>;

/**
 * __useCreateProjectionMutation__
 *
 * To run a mutation, you first call `useCreateProjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectionMutation, { data, loading, error }] = useCreateProjectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectionMutation, CreateProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectionMutation, CreateProjectionMutationVariables>(CreateProjectionDocument, options);
      }
export type CreateProjectionMutationHookResult = ReturnType<typeof useCreateProjectionMutation>;
export type CreateProjectionMutationResult = Apollo.MutationResult<CreateProjectionMutation>;
export type CreateProjectionMutationOptions = Apollo.BaseMutationOptions<CreateProjectionMutation, CreateProjectionMutationVariables>;
export const DeleteManyProjectionDocument = gql`
    mutation DeleteManyProjection($where: [ProjectionWhereUniqueInput!]) {
  deleteManyProjection(where: $where) {
    id
  }
}
    `;
export type DeleteManyProjectionMutationFn = Apollo.MutationFunction<DeleteManyProjectionMutation, DeleteManyProjectionMutationVariables>;

/**
 * __useDeleteManyProjectionMutation__
 *
 * To run a mutation, you first call `useDeleteManyProjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyProjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyProjectionMutation, { data, loading, error }] = useDeleteManyProjectionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyProjectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyProjectionMutation, DeleteManyProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyProjectionMutation, DeleteManyProjectionMutationVariables>(DeleteManyProjectionDocument, options);
      }
export type DeleteManyProjectionMutationHookResult = ReturnType<typeof useDeleteManyProjectionMutation>;
export type DeleteManyProjectionMutationResult = Apollo.MutationResult<DeleteManyProjectionMutation>;
export type DeleteManyProjectionMutationOptions = Apollo.BaseMutationOptions<DeleteManyProjectionMutation, DeleteManyProjectionMutationVariables>;
export const ProjectionDocument = gql`
    query Projection($where: ProjectionWhereUniqueInput!) {
  projection(where: $where) {
    ...AllProjectionFields
  }
}
    ${AllProjectionFieldsFragmentDoc}`;

/**
 * __useProjectionQuery__
 *
 * To run a query within a React component, call `useProjectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectionQuery(baseOptions: Apollo.QueryHookOptions<ProjectionQuery, ProjectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectionQuery, ProjectionQueryVariables>(ProjectionDocument, options);
      }
export function useProjectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectionQuery, ProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectionQuery, ProjectionQueryVariables>(ProjectionDocument, options);
        }
export type ProjectionQueryHookResult = ReturnType<typeof useProjectionQuery>;
export type ProjectionLazyQueryHookResult = ReturnType<typeof useProjectionLazyQuery>;
export type ProjectionQueryResult = Apollo.QueryResult<ProjectionQuery, ProjectionQueryVariables>;
export const ProjectionsDocument = gql`
    query Projections($skip: Int, $take: Int, $where: ProjectionWhereInput, $orderBy: [ProjectionOrderByWithRelationInput!]) {
  projections(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllProjectionFields
  }
}
    ${AllProjectionFieldsFragmentDoc}`;

/**
 * __useProjectionsQuery__
 *
 * To run a query within a React component, call `useProjectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useProjectionsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectionsQuery, ProjectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectionsQuery, ProjectionsQueryVariables>(ProjectionsDocument, options);
      }
export function useProjectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectionsQuery, ProjectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectionsQuery, ProjectionsQueryVariables>(ProjectionsDocument, options);
        }
export type ProjectionsQueryHookResult = ReturnType<typeof useProjectionsQuery>;
export type ProjectionsLazyQueryHookResult = ReturnType<typeof useProjectionsLazyQuery>;
export type ProjectionsQueryResult = Apollo.QueryResult<ProjectionsQuery, ProjectionsQueryVariables>;
export const UpdateProjectionDocument = gql`
    mutation UpdateProjection($where: ProjectionWhereUniqueInput!, $data: ProjectionUpdateInput!) {
  updateProjection(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateProjectionMutationFn = Apollo.MutationFunction<UpdateProjectionMutation, UpdateProjectionMutationVariables>;

/**
 * __useUpdateProjectionMutation__
 *
 * To run a mutation, you first call `useUpdateProjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectionMutation, { data, loading, error }] = useUpdateProjectionMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectionMutation, UpdateProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectionMutation, UpdateProjectionMutationVariables>(UpdateProjectionDocument, options);
      }
export type UpdateProjectionMutationHookResult = ReturnType<typeof useUpdateProjectionMutation>;
export type UpdateProjectionMutationResult = Apollo.MutationResult<UpdateProjectionMutation>;
export type UpdateProjectionMutationOptions = Apollo.BaseMutationOptions<UpdateProjectionMutation, UpdateProjectionMutationVariables>;
export const ProjectionSummaryPerOutletDocument = gql`
    query ProjectionSummaryPerOutlet($skip: Int, $take: Int, $where: ProjectionWhereInput, $orderBy: [ProjectionOrderByWithRelationInput!]) {
  projections(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    date
    deliveryShiftSchedule
    controlNumber
    outlet {
      id
      name
    }
    createdBy
    projectionSummaryItems {
      salesCategory
      quantity
    }
  }
}
    `;

/**
 * __useProjectionSummaryPerOutletQuery__
 *
 * To run a query within a React component, call `useProjectionSummaryPerOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectionSummaryPerOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectionSummaryPerOutletQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useProjectionSummaryPerOutletQuery(baseOptions?: Apollo.QueryHookOptions<ProjectionSummaryPerOutletQuery, ProjectionSummaryPerOutletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectionSummaryPerOutletQuery, ProjectionSummaryPerOutletQueryVariables>(ProjectionSummaryPerOutletDocument, options);
      }
export function useProjectionSummaryPerOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectionSummaryPerOutletQuery, ProjectionSummaryPerOutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectionSummaryPerOutletQuery, ProjectionSummaryPerOutletQueryVariables>(ProjectionSummaryPerOutletDocument, options);
        }
export type ProjectionSummaryPerOutletQueryHookResult = ReturnType<typeof useProjectionSummaryPerOutletQuery>;
export type ProjectionSummaryPerOutletLazyQueryHookResult = ReturnType<typeof useProjectionSummaryPerOutletLazyQuery>;
export type ProjectionSummaryPerOutletQueryResult = Apollo.QueryResult<ProjectionSummaryPerOutletQuery, ProjectionSummaryPerOutletQueryVariables>;
export const ProjectionToRawMaterialsDocument = gql`
    query ProjectionToRawMaterials($where: ProjectionToRawMaterialWhereInput) {
  projectionToRawMaterials(where: $where) {
    category
    rawMaterial
    unit
    firstDelivery
    secondDelivery
    thirdDelivery
  }
}
    `;

/**
 * __useProjectionToRawMaterialsQuery__
 *
 * To run a query within a React component, call `useProjectionToRawMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectionToRawMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectionToRawMaterialsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectionToRawMaterialsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectionToRawMaterialsQuery, ProjectionToRawMaterialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectionToRawMaterialsQuery, ProjectionToRawMaterialsQueryVariables>(ProjectionToRawMaterialsDocument, options);
      }
export function useProjectionToRawMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectionToRawMaterialsQuery, ProjectionToRawMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectionToRawMaterialsQuery, ProjectionToRawMaterialsQueryVariables>(ProjectionToRawMaterialsDocument, options);
        }
export type ProjectionToRawMaterialsQueryHookResult = ReturnType<typeof useProjectionToRawMaterialsQuery>;
export type ProjectionToRawMaterialsLazyQueryHookResult = ReturnType<typeof useProjectionToRawMaterialsLazyQuery>;
export type ProjectionToRawMaterialsQueryResult = Apollo.QueryResult<ProjectionToRawMaterialsQuery, ProjectionToRawMaterialsQueryVariables>;
export const AggregatePurchaseOrderDocument = gql`
    query AggregatePurchaseOrder($where: PurchaseOrderWhereInput) {
  aggregatePurchaseOrder(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregatePurchaseOrderQuery__
 *
 * To run a query within a React component, call `useAggregatePurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatePurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatePurchaseOrderQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregatePurchaseOrderQuery(baseOptions?: Apollo.QueryHookOptions<AggregatePurchaseOrderQuery, AggregatePurchaseOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregatePurchaseOrderQuery, AggregatePurchaseOrderQueryVariables>(AggregatePurchaseOrderDocument, options);
      }
export function useAggregatePurchaseOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregatePurchaseOrderQuery, AggregatePurchaseOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregatePurchaseOrderQuery, AggregatePurchaseOrderQueryVariables>(AggregatePurchaseOrderDocument, options);
        }
export type AggregatePurchaseOrderQueryHookResult = ReturnType<typeof useAggregatePurchaseOrderQuery>;
export type AggregatePurchaseOrderLazyQueryHookResult = ReturnType<typeof useAggregatePurchaseOrderLazyQuery>;
export type AggregatePurchaseOrderQueryResult = Apollo.QueryResult<AggregatePurchaseOrderQuery, AggregatePurchaseOrderQueryVariables>;
export const CreatePurchaseOrderDocument = gql`
    mutation CreatePurchaseOrder($data: PurchaseOrderCreateInput!) {
  createPurchaseOrder(data: $data) {
    id
  }
}
    `;
export type CreatePurchaseOrderMutationFn = Apollo.MutationFunction<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>;

/**
 * __useCreatePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseOrderMutation, { data, loading, error }] = useCreatePurchaseOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>(CreatePurchaseOrderDocument, options);
      }
export type CreatePurchaseOrderMutationHookResult = ReturnType<typeof useCreatePurchaseOrderMutation>;
export type CreatePurchaseOrderMutationResult = Apollo.MutationResult<CreatePurchaseOrderMutation>;
export type CreatePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>;
export const DeleteManyPurchaseOrderDocument = gql`
    mutation DeleteManyPurchaseOrder($where: [PurchaseOrderWhereUniqueInput!]) {
  deleteManyPurchaseOrder(where: $where) {
    id
  }
}
    `;
export type DeleteManyPurchaseOrderMutationFn = Apollo.MutationFunction<DeleteManyPurchaseOrderMutation, DeleteManyPurchaseOrderMutationVariables>;

/**
 * __useDeleteManyPurchaseOrderMutation__
 *
 * To run a mutation, you first call `useDeleteManyPurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyPurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyPurchaseOrderMutation, { data, loading, error }] = useDeleteManyPurchaseOrderMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyPurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyPurchaseOrderMutation, DeleteManyPurchaseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyPurchaseOrderMutation, DeleteManyPurchaseOrderMutationVariables>(DeleteManyPurchaseOrderDocument, options);
      }
export type DeleteManyPurchaseOrderMutationHookResult = ReturnType<typeof useDeleteManyPurchaseOrderMutation>;
export type DeleteManyPurchaseOrderMutationResult = Apollo.MutationResult<DeleteManyPurchaseOrderMutation>;
export type DeleteManyPurchaseOrderMutationOptions = Apollo.BaseMutationOptions<DeleteManyPurchaseOrderMutation, DeleteManyPurchaseOrderMutationVariables>;
export const PurchaseOrderDocument = gql`
    query PurchaseOrder($where: PurchaseOrderWhereUniqueInput!) {
  purchaseOrder(where: $where) {
    ...AllPurchaseOrderFields
  }
}
    ${AllPurchaseOrderFieldsFragmentDoc}`;

/**
 * __usePurchaseOrderQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePurchaseOrderQuery(baseOptions: Apollo.QueryHookOptions<PurchaseOrderQuery, PurchaseOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseOrderQuery, PurchaseOrderQueryVariables>(PurchaseOrderDocument, options);
      }
export function usePurchaseOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrderQuery, PurchaseOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseOrderQuery, PurchaseOrderQueryVariables>(PurchaseOrderDocument, options);
        }
export type PurchaseOrderQueryHookResult = ReturnType<typeof usePurchaseOrderQuery>;
export type PurchaseOrderLazyQueryHookResult = ReturnType<typeof usePurchaseOrderLazyQuery>;
export type PurchaseOrderQueryResult = Apollo.QueryResult<PurchaseOrderQuery, PurchaseOrderQueryVariables>;
export const PurchaseOrdersDocument = gql`
    query PurchaseOrders($skip: Int, $take: Int, $where: PurchaseOrderWhereInput, $orderBy: [PurchaseOrderOrderByWithRelationInput!]) {
  purchaseOrders(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllPurchaseOrderFields
  }
}
    ${AllPurchaseOrderFieldsFragmentDoc}`;

/**
 * __usePurchaseOrdersQuery__
 *
 * To run a query within a React component, call `usePurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrdersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePurchaseOrdersQuery(baseOptions?: Apollo.QueryHookOptions<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>(PurchaseOrdersDocument, options);
      }
export function usePurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>(PurchaseOrdersDocument, options);
        }
export type PurchaseOrdersQueryHookResult = ReturnType<typeof usePurchaseOrdersQuery>;
export type PurchaseOrdersLazyQueryHookResult = ReturnType<typeof usePurchaseOrdersLazyQuery>;
export type PurchaseOrdersQueryResult = Apollo.QueryResult<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>;
export const UpdatePurchaseOrderDocument = gql`
    mutation UpdatePurchaseOrder($where: PurchaseOrderWhereUniqueInput!, $data: PurchaseOrderUpdateInput!) {
  updatePurchaseOrder(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdatePurchaseOrderMutationFn = Apollo.MutationFunction<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>;

/**
 * __useUpdatePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseOrderMutation, { data, loading, error }] = useUpdatePurchaseOrderMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>(UpdatePurchaseOrderDocument, options);
      }
export type UpdatePurchaseOrderMutationHookResult = ReturnType<typeof useUpdatePurchaseOrderMutation>;
export type UpdatePurchaseOrderMutationResult = Apollo.MutationResult<UpdatePurchaseOrderMutation>;
export type UpdatePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>;
export const AggregateRawMaterialDocument = gql`
    query AggregateRawMaterial($where: RawMaterialWhereInput) {
  aggregateRawMaterial(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateRawMaterialQuery__
 *
 * To run a query within a React component, call `useAggregateRawMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateRawMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateRawMaterialQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateRawMaterialQuery(baseOptions?: Apollo.QueryHookOptions<AggregateRawMaterialQuery, AggregateRawMaterialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateRawMaterialQuery, AggregateRawMaterialQueryVariables>(AggregateRawMaterialDocument, options);
      }
export function useAggregateRawMaterialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateRawMaterialQuery, AggregateRawMaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateRawMaterialQuery, AggregateRawMaterialQueryVariables>(AggregateRawMaterialDocument, options);
        }
export type AggregateRawMaterialQueryHookResult = ReturnType<typeof useAggregateRawMaterialQuery>;
export type AggregateRawMaterialLazyQueryHookResult = ReturnType<typeof useAggregateRawMaterialLazyQuery>;
export type AggregateRawMaterialQueryResult = Apollo.QueryResult<AggregateRawMaterialQuery, AggregateRawMaterialQueryVariables>;
export const CreateRawMaterialDocument = gql`
    mutation CreateRawMaterial($data: RawMaterialCreateInput!) {
  createRawMaterial(data: $data) {
    id
  }
}
    `;
export type CreateRawMaterialMutationFn = Apollo.MutationFunction<CreateRawMaterialMutation, CreateRawMaterialMutationVariables>;

/**
 * __useCreateRawMaterialMutation__
 *
 * To run a mutation, you first call `useCreateRawMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRawMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRawMaterialMutation, { data, loading, error }] = useCreateRawMaterialMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRawMaterialMutation(baseOptions?: Apollo.MutationHookOptions<CreateRawMaterialMutation, CreateRawMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRawMaterialMutation, CreateRawMaterialMutationVariables>(CreateRawMaterialDocument, options);
      }
export type CreateRawMaterialMutationHookResult = ReturnType<typeof useCreateRawMaterialMutation>;
export type CreateRawMaterialMutationResult = Apollo.MutationResult<CreateRawMaterialMutation>;
export type CreateRawMaterialMutationOptions = Apollo.BaseMutationOptions<CreateRawMaterialMutation, CreateRawMaterialMutationVariables>;
export const DeleteManyRawMaterialDocument = gql`
    mutation DeleteManyRawMaterial($where: [RawMaterialWhereUniqueInput!]) {
  deleteManyRawMaterial(where: $where) {
    id
  }
}
    `;
export type DeleteManyRawMaterialMutationFn = Apollo.MutationFunction<DeleteManyRawMaterialMutation, DeleteManyRawMaterialMutationVariables>;

/**
 * __useDeleteManyRawMaterialMutation__
 *
 * To run a mutation, you first call `useDeleteManyRawMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyRawMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyRawMaterialMutation, { data, loading, error }] = useDeleteManyRawMaterialMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyRawMaterialMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyRawMaterialMutation, DeleteManyRawMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyRawMaterialMutation, DeleteManyRawMaterialMutationVariables>(DeleteManyRawMaterialDocument, options);
      }
export type DeleteManyRawMaterialMutationHookResult = ReturnType<typeof useDeleteManyRawMaterialMutation>;
export type DeleteManyRawMaterialMutationResult = Apollo.MutationResult<DeleteManyRawMaterialMutation>;
export type DeleteManyRawMaterialMutationOptions = Apollo.BaseMutationOptions<DeleteManyRawMaterialMutation, DeleteManyRawMaterialMutationVariables>;
export const RawMaterialDocument = gql`
    query RawMaterial($where: RawMaterialWhereUniqueInput!) {
  rawMaterial(where: $where) {
    ...AllRawMaterialFields
  }
}
    ${AllRawMaterialFieldsFragmentDoc}`;

/**
 * __useRawMaterialQuery__
 *
 * To run a query within a React component, call `useRawMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRawMaterialQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialQuery, RawMaterialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialQuery, RawMaterialQueryVariables>(RawMaterialDocument, options);
      }
export function useRawMaterialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialQuery, RawMaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialQuery, RawMaterialQueryVariables>(RawMaterialDocument, options);
        }
export type RawMaterialQueryHookResult = ReturnType<typeof useRawMaterialQuery>;
export type RawMaterialLazyQueryHookResult = ReturnType<typeof useRawMaterialLazyQuery>;
export type RawMaterialQueryResult = Apollo.QueryResult<RawMaterialQuery, RawMaterialQueryVariables>;
export const RawMaterialsDocument = gql`
    query RawMaterials($skip: Int, $take: Int, $where: RawMaterialWhereInput, $orderBy: [RawMaterialOrderByWithRelationInput!]) {
  rawMaterials(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllRawMaterialFields
  }
}
    ${AllRawMaterialFieldsFragmentDoc}`;

/**
 * __useRawMaterialsQuery__
 *
 * To run a query within a React component, call `useRawMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialsQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialsQuery, RawMaterialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialsQuery, RawMaterialsQueryVariables>(RawMaterialsDocument, options);
      }
export function useRawMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialsQuery, RawMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialsQuery, RawMaterialsQueryVariables>(RawMaterialsDocument, options);
        }
export type RawMaterialsQueryHookResult = ReturnType<typeof useRawMaterialsQuery>;
export type RawMaterialsLazyQueryHookResult = ReturnType<typeof useRawMaterialsLazyQuery>;
export type RawMaterialsQueryResult = Apollo.QueryResult<RawMaterialsQuery, RawMaterialsQueryVariables>;
export const RawMaterialsAllowDocument = gql`
    query RawMaterialsAllow($skip: Int, $take: Int, $where: RawMaterialWhereInput, $orderBy: [RawMaterialOrderByWithRelationInput!]) {
  rawMaterials(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    name
    rawMaterialCategory
    rawMaterialProducts {
      id
      conversionToBaseUnit
      baseUnit
      unit {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRawMaterialsAllowQuery__
 *
 * To run a query within a React component, call `useRawMaterialsAllowQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialsAllowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialsAllowQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialsAllowQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialsAllowQuery, RawMaterialsAllowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialsAllowQuery, RawMaterialsAllowQueryVariables>(RawMaterialsAllowDocument, options);
      }
export function useRawMaterialsAllowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialsAllowQuery, RawMaterialsAllowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialsAllowQuery, RawMaterialsAllowQueryVariables>(RawMaterialsAllowDocument, options);
        }
export type RawMaterialsAllowQueryHookResult = ReturnType<typeof useRawMaterialsAllowQuery>;
export type RawMaterialsAllowLazyQueryHookResult = ReturnType<typeof useRawMaterialsAllowLazyQuery>;
export type RawMaterialsAllowQueryResult = Apollo.QueryResult<RawMaterialsAllowQuery, RawMaterialsAllowQueryVariables>;
export const RawMaterialsDropdownDocument = gql`
    query RawMaterialsDropdown($skip: Int, $take: Int, $where: RawMaterialWhereInput, $orderBy: [RawMaterialOrderByWithRelationInput!]) {
  rawMaterialsDropdown(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    name
    rawMaterialCategory
    rawMaterialProducts {
      id
      conversionToBaseUnit
      baseUnit
      unit {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRawMaterialsDropdownQuery__
 *
 * To run a query within a React component, call `useRawMaterialsDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialsDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialsDropdownQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialsDropdownQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialsDropdownQuery, RawMaterialsDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialsDropdownQuery, RawMaterialsDropdownQueryVariables>(RawMaterialsDropdownDocument, options);
      }
export function useRawMaterialsDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialsDropdownQuery, RawMaterialsDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialsDropdownQuery, RawMaterialsDropdownQueryVariables>(RawMaterialsDropdownDocument, options);
        }
export type RawMaterialsDropdownQueryHookResult = ReturnType<typeof useRawMaterialsDropdownQuery>;
export type RawMaterialsDropdownLazyQueryHookResult = ReturnType<typeof useRawMaterialsDropdownLazyQuery>;
export type RawMaterialsDropdownQueryResult = Apollo.QueryResult<RawMaterialsDropdownQuery, RawMaterialsDropdownQueryVariables>;
export const UpdateRawMaterialDocument = gql`
    mutation UpdateRawMaterial($where: RawMaterialWhereUniqueInput!, $data: RawMaterialUpdateInput!) {
  updateRawMaterial(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateRawMaterialMutationFn = Apollo.MutationFunction<UpdateRawMaterialMutation, UpdateRawMaterialMutationVariables>;

/**
 * __useUpdateRawMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateRawMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRawMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRawMaterialMutation, { data, loading, error }] = useUpdateRawMaterialMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRawMaterialMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRawMaterialMutation, UpdateRawMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRawMaterialMutation, UpdateRawMaterialMutationVariables>(UpdateRawMaterialDocument, options);
      }
export type UpdateRawMaterialMutationHookResult = ReturnType<typeof useUpdateRawMaterialMutation>;
export type UpdateRawMaterialMutationResult = Apollo.MutationResult<UpdateRawMaterialMutation>;
export type UpdateRawMaterialMutationOptions = Apollo.BaseMutationOptions<UpdateRawMaterialMutation, UpdateRawMaterialMutationVariables>;
export const AggregateRawMaterialFinishingEndingDocument = gql`
    query AggregateRawMaterialFinishingEnding($where: RawMaterialFinishingEndingWhereInput) {
  aggregateRawMaterialFinishingEnding(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateRawMaterialFinishingEndingQuery__
 *
 * To run a query within a React component, call `useAggregateRawMaterialFinishingEndingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateRawMaterialFinishingEndingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateRawMaterialFinishingEndingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateRawMaterialFinishingEndingQuery(baseOptions?: Apollo.QueryHookOptions<AggregateRawMaterialFinishingEndingQuery, AggregateRawMaterialFinishingEndingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateRawMaterialFinishingEndingQuery, AggregateRawMaterialFinishingEndingQueryVariables>(AggregateRawMaterialFinishingEndingDocument, options);
      }
export function useAggregateRawMaterialFinishingEndingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateRawMaterialFinishingEndingQuery, AggregateRawMaterialFinishingEndingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateRawMaterialFinishingEndingQuery, AggregateRawMaterialFinishingEndingQueryVariables>(AggregateRawMaterialFinishingEndingDocument, options);
        }
export type AggregateRawMaterialFinishingEndingQueryHookResult = ReturnType<typeof useAggregateRawMaterialFinishingEndingQuery>;
export type AggregateRawMaterialFinishingEndingLazyQueryHookResult = ReturnType<typeof useAggregateRawMaterialFinishingEndingLazyQuery>;
export type AggregateRawMaterialFinishingEndingQueryResult = Apollo.QueryResult<AggregateRawMaterialFinishingEndingQuery, AggregateRawMaterialFinishingEndingQueryVariables>;
export const CreateRawMaterialFinishingEndingDocument = gql`
    mutation CreateRawMaterialFinishingEnding($data: RawMaterialFinishingEndingCreateInput!) {
  createRawMaterialFinishingEnding(data: $data) {
    id
  }
}
    `;
export type CreateRawMaterialFinishingEndingMutationFn = Apollo.MutationFunction<CreateRawMaterialFinishingEndingMutation, CreateRawMaterialFinishingEndingMutationVariables>;

/**
 * __useCreateRawMaterialFinishingEndingMutation__
 *
 * To run a mutation, you first call `useCreateRawMaterialFinishingEndingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRawMaterialFinishingEndingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRawMaterialFinishingEndingMutation, { data, loading, error }] = useCreateRawMaterialFinishingEndingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRawMaterialFinishingEndingMutation(baseOptions?: Apollo.MutationHookOptions<CreateRawMaterialFinishingEndingMutation, CreateRawMaterialFinishingEndingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRawMaterialFinishingEndingMutation, CreateRawMaterialFinishingEndingMutationVariables>(CreateRawMaterialFinishingEndingDocument, options);
      }
export type CreateRawMaterialFinishingEndingMutationHookResult = ReturnType<typeof useCreateRawMaterialFinishingEndingMutation>;
export type CreateRawMaterialFinishingEndingMutationResult = Apollo.MutationResult<CreateRawMaterialFinishingEndingMutation>;
export type CreateRawMaterialFinishingEndingMutationOptions = Apollo.BaseMutationOptions<CreateRawMaterialFinishingEndingMutation, CreateRawMaterialFinishingEndingMutationVariables>;
export const DeleteManyRawMaterialFinishingEndingDocument = gql`
    mutation DeleteManyRawMaterialFinishingEnding($where: [RawMaterialFinishingEndingWhereUniqueInput!]) {
  deleteManyRawMaterialFinishingEnding(where: $where) {
    id
  }
}
    `;
export type DeleteManyRawMaterialFinishingEndingMutationFn = Apollo.MutationFunction<DeleteManyRawMaterialFinishingEndingMutation, DeleteManyRawMaterialFinishingEndingMutationVariables>;

/**
 * __useDeleteManyRawMaterialFinishingEndingMutation__
 *
 * To run a mutation, you first call `useDeleteManyRawMaterialFinishingEndingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyRawMaterialFinishingEndingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyRawMaterialFinishingEndingMutation, { data, loading, error }] = useDeleteManyRawMaterialFinishingEndingMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyRawMaterialFinishingEndingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyRawMaterialFinishingEndingMutation, DeleteManyRawMaterialFinishingEndingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyRawMaterialFinishingEndingMutation, DeleteManyRawMaterialFinishingEndingMutationVariables>(DeleteManyRawMaterialFinishingEndingDocument, options);
      }
export type DeleteManyRawMaterialFinishingEndingMutationHookResult = ReturnType<typeof useDeleteManyRawMaterialFinishingEndingMutation>;
export type DeleteManyRawMaterialFinishingEndingMutationResult = Apollo.MutationResult<DeleteManyRawMaterialFinishingEndingMutation>;
export type DeleteManyRawMaterialFinishingEndingMutationOptions = Apollo.BaseMutationOptions<DeleteManyRawMaterialFinishingEndingMutation, DeleteManyRawMaterialFinishingEndingMutationVariables>;
export const RawMaterialFinishingEndingDocument = gql`
    query RawMaterialFinishingEnding($where: RawMaterialFinishingEndingWhereUniqueInput!) {
  rawMaterialFinishingEnding(where: $where) {
    ...AllRawMaterialFinishingEndingFields
  }
}
    ${AllRawMaterialFinishingEndingFieldsFragmentDoc}`;

/**
 * __useRawMaterialFinishingEndingQuery__
 *
 * To run a query within a React component, call `useRawMaterialFinishingEndingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialFinishingEndingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialFinishingEndingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRawMaterialFinishingEndingQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialFinishingEndingQuery, RawMaterialFinishingEndingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialFinishingEndingQuery, RawMaterialFinishingEndingQueryVariables>(RawMaterialFinishingEndingDocument, options);
      }
export function useRawMaterialFinishingEndingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialFinishingEndingQuery, RawMaterialFinishingEndingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialFinishingEndingQuery, RawMaterialFinishingEndingQueryVariables>(RawMaterialFinishingEndingDocument, options);
        }
export type RawMaterialFinishingEndingQueryHookResult = ReturnType<typeof useRawMaterialFinishingEndingQuery>;
export type RawMaterialFinishingEndingLazyQueryHookResult = ReturnType<typeof useRawMaterialFinishingEndingLazyQuery>;
export type RawMaterialFinishingEndingQueryResult = Apollo.QueryResult<RawMaterialFinishingEndingQuery, RawMaterialFinishingEndingQueryVariables>;
export const RawMaterialFinishingEndingsDocument = gql`
    query RawMaterialFinishingEndings($skip: Int, $take: Int, $where: RawMaterialFinishingEndingWhereInput, $orderBy: [RawMaterialFinishingEndingOrderByWithRelationInput!]) {
  rawMaterialFinishingEndings(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllRawMaterialFinishingEndingFields
  }
}
    ${AllRawMaterialFinishingEndingFieldsFragmentDoc}`;

/**
 * __useRawMaterialFinishingEndingsQuery__
 *
 * To run a query within a React component, call `useRawMaterialFinishingEndingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialFinishingEndingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialFinishingEndingsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialFinishingEndingsQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialFinishingEndingsQuery, RawMaterialFinishingEndingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialFinishingEndingsQuery, RawMaterialFinishingEndingsQueryVariables>(RawMaterialFinishingEndingsDocument, options);
      }
export function useRawMaterialFinishingEndingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialFinishingEndingsQuery, RawMaterialFinishingEndingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialFinishingEndingsQuery, RawMaterialFinishingEndingsQueryVariables>(RawMaterialFinishingEndingsDocument, options);
        }
export type RawMaterialFinishingEndingsQueryHookResult = ReturnType<typeof useRawMaterialFinishingEndingsQuery>;
export type RawMaterialFinishingEndingsLazyQueryHookResult = ReturnType<typeof useRawMaterialFinishingEndingsLazyQuery>;
export type RawMaterialFinishingEndingsQueryResult = Apollo.QueryResult<RawMaterialFinishingEndingsQuery, RawMaterialFinishingEndingsQueryVariables>;
export const UpdateRawMaterialFinishingEndingDocument = gql`
    mutation UpdateRawMaterialFinishingEnding($where: RawMaterialFinishingEndingWhereUniqueInput!, $data: RawMaterialFinishingEndingUpdateInput!) {
  updateRawMaterialFinishingEnding(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateRawMaterialFinishingEndingMutationFn = Apollo.MutationFunction<UpdateRawMaterialFinishingEndingMutation, UpdateRawMaterialFinishingEndingMutationVariables>;

/**
 * __useUpdateRawMaterialFinishingEndingMutation__
 *
 * To run a mutation, you first call `useUpdateRawMaterialFinishingEndingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRawMaterialFinishingEndingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRawMaterialFinishingEndingMutation, { data, loading, error }] = useUpdateRawMaterialFinishingEndingMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRawMaterialFinishingEndingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRawMaterialFinishingEndingMutation, UpdateRawMaterialFinishingEndingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRawMaterialFinishingEndingMutation, UpdateRawMaterialFinishingEndingMutationVariables>(UpdateRawMaterialFinishingEndingDocument, options);
      }
export type UpdateRawMaterialFinishingEndingMutationHookResult = ReturnType<typeof useUpdateRawMaterialFinishingEndingMutation>;
export type UpdateRawMaterialFinishingEndingMutationResult = Apollo.MutationResult<UpdateRawMaterialFinishingEndingMutation>;
export type UpdateRawMaterialFinishingEndingMutationOptions = Apollo.BaseMutationOptions<UpdateRawMaterialFinishingEndingMutation, UpdateRawMaterialFinishingEndingMutationVariables>;
export const AggregateRawMaterialProductDocument = gql`
    query AggregateRawMaterialProduct($where: RawMaterialProductWhereInput) {
  aggregateRawMaterialProduct(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateRawMaterialProductQuery__
 *
 * To run a query within a React component, call `useAggregateRawMaterialProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateRawMaterialProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateRawMaterialProductQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateRawMaterialProductQuery(baseOptions?: Apollo.QueryHookOptions<AggregateRawMaterialProductQuery, AggregateRawMaterialProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateRawMaterialProductQuery, AggregateRawMaterialProductQueryVariables>(AggregateRawMaterialProductDocument, options);
      }
export function useAggregateRawMaterialProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateRawMaterialProductQuery, AggregateRawMaterialProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateRawMaterialProductQuery, AggregateRawMaterialProductQueryVariables>(AggregateRawMaterialProductDocument, options);
        }
export type AggregateRawMaterialProductQueryHookResult = ReturnType<typeof useAggregateRawMaterialProductQuery>;
export type AggregateRawMaterialProductLazyQueryHookResult = ReturnType<typeof useAggregateRawMaterialProductLazyQuery>;
export type AggregateRawMaterialProductQueryResult = Apollo.QueryResult<AggregateRawMaterialProductQuery, AggregateRawMaterialProductQueryVariables>;
export const DeleteManyRawMaterialProductDocument = gql`
    mutation DeleteManyRawMaterialProduct($where: [RawMaterialProductWhereUniqueInput!]) {
  deleteManyRawMaterialProduct(where: $where) {
    id
  }
}
    `;
export type DeleteManyRawMaterialProductMutationFn = Apollo.MutationFunction<DeleteManyRawMaterialProductMutation, DeleteManyRawMaterialProductMutationVariables>;

/**
 * __useDeleteManyRawMaterialProductMutation__
 *
 * To run a mutation, you first call `useDeleteManyRawMaterialProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyRawMaterialProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyRawMaterialProductMutation, { data, loading, error }] = useDeleteManyRawMaterialProductMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyRawMaterialProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyRawMaterialProductMutation, DeleteManyRawMaterialProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyRawMaterialProductMutation, DeleteManyRawMaterialProductMutationVariables>(DeleteManyRawMaterialProductDocument, options);
      }
export type DeleteManyRawMaterialProductMutationHookResult = ReturnType<typeof useDeleteManyRawMaterialProductMutation>;
export type DeleteManyRawMaterialProductMutationResult = Apollo.MutationResult<DeleteManyRawMaterialProductMutation>;
export type DeleteManyRawMaterialProductMutationOptions = Apollo.BaseMutationOptions<DeleteManyRawMaterialProductMutation, DeleteManyRawMaterialProductMutationVariables>;
export const RawMaterialProductDocument = gql`
    query RawMaterialProduct($where: RawMaterialProductWhereUniqueInput!) {
  rawMaterialProduct(where: $where) {
    ...AllRawMaterialProductFields
  }
}
    ${AllRawMaterialProductFieldsFragmentDoc}`;

/**
 * __useRawMaterialProductQuery__
 *
 * To run a query within a React component, call `useRawMaterialProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialProductQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRawMaterialProductQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialProductQuery, RawMaterialProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialProductQuery, RawMaterialProductQueryVariables>(RawMaterialProductDocument, options);
      }
export function useRawMaterialProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialProductQuery, RawMaterialProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialProductQuery, RawMaterialProductQueryVariables>(RawMaterialProductDocument, options);
        }
export type RawMaterialProductQueryHookResult = ReturnType<typeof useRawMaterialProductQuery>;
export type RawMaterialProductLazyQueryHookResult = ReturnType<typeof useRawMaterialProductLazyQuery>;
export type RawMaterialProductQueryResult = Apollo.QueryResult<RawMaterialProductQuery, RawMaterialProductQueryVariables>;
export const RawMaterialProductsDocument = gql`
    query RawMaterialProducts($skip: Int, $take: Int, $where: RawMaterialProductWhereInput, $orderBy: [RawMaterialProductOrderByWithRelationInput!]) {
  rawMaterialProducts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllRawMaterialProductFields
  }
}
    ${AllRawMaterialProductFieldsFragmentDoc}`;

/**
 * __useRawMaterialProductsQuery__
 *
 * To run a query within a React component, call `useRawMaterialProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialProductsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialProductsQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialProductsQuery, RawMaterialProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialProductsQuery, RawMaterialProductsQueryVariables>(RawMaterialProductsDocument, options);
      }
export function useRawMaterialProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialProductsQuery, RawMaterialProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialProductsQuery, RawMaterialProductsQueryVariables>(RawMaterialProductsDocument, options);
        }
export type RawMaterialProductsQueryHookResult = ReturnType<typeof useRawMaterialProductsQuery>;
export type RawMaterialProductsLazyQueryHookResult = ReturnType<typeof useRawMaterialProductsLazyQuery>;
export type RawMaterialProductsQueryResult = Apollo.QueryResult<RawMaterialProductsQuery, RawMaterialProductsQueryVariables>;
export const RawMaterialProductsAllowedDocument = gql`
    query RawMaterialProductsAllowed($skip: Int, $take: Int, $where: RawMaterialProductWhereInput, $orderBy: [RawMaterialProductOrderByWithRelationInput!]) {
  rawMaterialProducts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    id
    rawMaterial {
      id
      rawMaterialCategory
      name
    }
    unit {
      id
      name
    }
  }
}
    `;

/**
 * __useRawMaterialProductsAllowedQuery__
 *
 * To run a query within a React component, call `useRawMaterialProductsAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialProductsAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialProductsAllowedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialProductsAllowedQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialProductsAllowedQuery, RawMaterialProductsAllowedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialProductsAllowedQuery, RawMaterialProductsAllowedQueryVariables>(RawMaterialProductsAllowedDocument, options);
      }
export function useRawMaterialProductsAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialProductsAllowedQuery, RawMaterialProductsAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialProductsAllowedQuery, RawMaterialProductsAllowedQueryVariables>(RawMaterialProductsAllowedDocument, options);
        }
export type RawMaterialProductsAllowedQueryHookResult = ReturnType<typeof useRawMaterialProductsAllowedQuery>;
export type RawMaterialProductsAllowedLazyQueryHookResult = ReturnType<typeof useRawMaterialProductsAllowedLazyQuery>;
export type RawMaterialProductsAllowedQueryResult = Apollo.QueryResult<RawMaterialProductsAllowedQuery, RawMaterialProductsAllowedQueryVariables>;
export const UpdateRawMaterialProductDocument = gql`
    mutation UpdateRawMaterialProduct($where: RawMaterialProductWhereUniqueInput!, $data: RawMaterialProductUpdateInput!) {
  updateRawMaterialProduct(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateRawMaterialProductMutationFn = Apollo.MutationFunction<UpdateRawMaterialProductMutation, UpdateRawMaterialProductMutationVariables>;

/**
 * __useUpdateRawMaterialProductMutation__
 *
 * To run a mutation, you first call `useUpdateRawMaterialProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRawMaterialProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRawMaterialProductMutation, { data, loading, error }] = useUpdateRawMaterialProductMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRawMaterialProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRawMaterialProductMutation, UpdateRawMaterialProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRawMaterialProductMutation, UpdateRawMaterialProductMutationVariables>(UpdateRawMaterialProductDocument, options);
      }
export type UpdateRawMaterialProductMutationHookResult = ReturnType<typeof useUpdateRawMaterialProductMutation>;
export type UpdateRawMaterialProductMutationResult = Apollo.MutationResult<UpdateRawMaterialProductMutation>;
export type UpdateRawMaterialProductMutationOptions = Apollo.BaseMutationOptions<UpdateRawMaterialProductMutation, UpdateRawMaterialProductMutationVariables>;
export const AggregateRawMaterialProductRecipeDocument = gql`
    query AggregateRawMaterialProductRecipe($where: RawMaterialProductRecipeWhereInput) {
  aggregateRawMaterialProductRecipe(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateRawMaterialProductRecipeQuery__
 *
 * To run a query within a React component, call `useAggregateRawMaterialProductRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateRawMaterialProductRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateRawMaterialProductRecipeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateRawMaterialProductRecipeQuery(baseOptions?: Apollo.QueryHookOptions<AggregateRawMaterialProductRecipeQuery, AggregateRawMaterialProductRecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateRawMaterialProductRecipeQuery, AggregateRawMaterialProductRecipeQueryVariables>(AggregateRawMaterialProductRecipeDocument, options);
      }
export function useAggregateRawMaterialProductRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateRawMaterialProductRecipeQuery, AggregateRawMaterialProductRecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateRawMaterialProductRecipeQuery, AggregateRawMaterialProductRecipeQueryVariables>(AggregateRawMaterialProductRecipeDocument, options);
        }
export type AggregateRawMaterialProductRecipeQueryHookResult = ReturnType<typeof useAggregateRawMaterialProductRecipeQuery>;
export type AggregateRawMaterialProductRecipeLazyQueryHookResult = ReturnType<typeof useAggregateRawMaterialProductRecipeLazyQuery>;
export type AggregateRawMaterialProductRecipeQueryResult = Apollo.QueryResult<AggregateRawMaterialProductRecipeQuery, AggregateRawMaterialProductRecipeQueryVariables>;
export const CreateRawMaterialProductRecipeDocument = gql`
    mutation CreateRawMaterialProductRecipe($data: RawMaterialProductRecipeCreateInput!) {
  createRawMaterialProductRecipe(data: $data) {
    id
  }
}
    `;
export type CreateRawMaterialProductRecipeMutationFn = Apollo.MutationFunction<CreateRawMaterialProductRecipeMutation, CreateRawMaterialProductRecipeMutationVariables>;

/**
 * __useCreateRawMaterialProductRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRawMaterialProductRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRawMaterialProductRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRawMaterialProductRecipeMutation, { data, loading, error }] = useCreateRawMaterialProductRecipeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRawMaterialProductRecipeMutation(baseOptions?: Apollo.MutationHookOptions<CreateRawMaterialProductRecipeMutation, CreateRawMaterialProductRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRawMaterialProductRecipeMutation, CreateRawMaterialProductRecipeMutationVariables>(CreateRawMaterialProductRecipeDocument, options);
      }
export type CreateRawMaterialProductRecipeMutationHookResult = ReturnType<typeof useCreateRawMaterialProductRecipeMutation>;
export type CreateRawMaterialProductRecipeMutationResult = Apollo.MutationResult<CreateRawMaterialProductRecipeMutation>;
export type CreateRawMaterialProductRecipeMutationOptions = Apollo.BaseMutationOptions<CreateRawMaterialProductRecipeMutation, CreateRawMaterialProductRecipeMutationVariables>;
export const DeleteManyRawMaterialProductRecipeDocument = gql`
    mutation DeleteManyRawMaterialProductRecipe($where: [RawMaterialProductRecipeWhereUniqueInput!]) {
  deleteManyRawMaterialProductRecipe(where: $where) {
    id
  }
}
    `;
export type DeleteManyRawMaterialProductRecipeMutationFn = Apollo.MutationFunction<DeleteManyRawMaterialProductRecipeMutation, DeleteManyRawMaterialProductRecipeMutationVariables>;

/**
 * __useDeleteManyRawMaterialProductRecipeMutation__
 *
 * To run a mutation, you first call `useDeleteManyRawMaterialProductRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyRawMaterialProductRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyRawMaterialProductRecipeMutation, { data, loading, error }] = useDeleteManyRawMaterialProductRecipeMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyRawMaterialProductRecipeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyRawMaterialProductRecipeMutation, DeleteManyRawMaterialProductRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyRawMaterialProductRecipeMutation, DeleteManyRawMaterialProductRecipeMutationVariables>(DeleteManyRawMaterialProductRecipeDocument, options);
      }
export type DeleteManyRawMaterialProductRecipeMutationHookResult = ReturnType<typeof useDeleteManyRawMaterialProductRecipeMutation>;
export type DeleteManyRawMaterialProductRecipeMutationResult = Apollo.MutationResult<DeleteManyRawMaterialProductRecipeMutation>;
export type DeleteManyRawMaterialProductRecipeMutationOptions = Apollo.BaseMutationOptions<DeleteManyRawMaterialProductRecipeMutation, DeleteManyRawMaterialProductRecipeMutationVariables>;
export const RawMaterialProductRecipeDocument = gql`
    query RawMaterialProductRecipe($where: RawMaterialProductRecipeWhereUniqueInput!) {
  rawMaterialProductRecipe(where: $where) {
    ...AllRawMaterialProductRecipeFields
  }
}
    ${AllRawMaterialProductRecipeFieldsFragmentDoc}`;

/**
 * __useRawMaterialProductRecipeQuery__
 *
 * To run a query within a React component, call `useRawMaterialProductRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialProductRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialProductRecipeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRawMaterialProductRecipeQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialProductRecipeQuery, RawMaterialProductRecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialProductRecipeQuery, RawMaterialProductRecipeQueryVariables>(RawMaterialProductRecipeDocument, options);
      }
export function useRawMaterialProductRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialProductRecipeQuery, RawMaterialProductRecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialProductRecipeQuery, RawMaterialProductRecipeQueryVariables>(RawMaterialProductRecipeDocument, options);
        }
export type RawMaterialProductRecipeQueryHookResult = ReturnType<typeof useRawMaterialProductRecipeQuery>;
export type RawMaterialProductRecipeLazyQueryHookResult = ReturnType<typeof useRawMaterialProductRecipeLazyQuery>;
export type RawMaterialProductRecipeQueryResult = Apollo.QueryResult<RawMaterialProductRecipeQuery, RawMaterialProductRecipeQueryVariables>;
export const RawMaterialProductRecipesDocument = gql`
    query RawMaterialProductRecipes($skip: Int, $take: Int, $where: RawMaterialProductRecipeWhereInput, $orderBy: [RawMaterialProductRecipeOrderByWithRelationInput!]) {
  rawMaterialProductRecipes(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllRawMaterialProductRecipeFields
  }
}
    ${AllRawMaterialProductRecipeFieldsFragmentDoc}`;

/**
 * __useRawMaterialProductRecipesQuery__
 *
 * To run a query within a React component, call `useRawMaterialProductRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialProductRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialProductRecipesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialProductRecipesQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialProductRecipesQuery, RawMaterialProductRecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialProductRecipesQuery, RawMaterialProductRecipesQueryVariables>(RawMaterialProductRecipesDocument, options);
      }
export function useRawMaterialProductRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialProductRecipesQuery, RawMaterialProductRecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialProductRecipesQuery, RawMaterialProductRecipesQueryVariables>(RawMaterialProductRecipesDocument, options);
        }
export type RawMaterialProductRecipesQueryHookResult = ReturnType<typeof useRawMaterialProductRecipesQuery>;
export type RawMaterialProductRecipesLazyQueryHookResult = ReturnType<typeof useRawMaterialProductRecipesLazyQuery>;
export type RawMaterialProductRecipesQueryResult = Apollo.QueryResult<RawMaterialProductRecipesQuery, RawMaterialProductRecipesQueryVariables>;
export const UpdateRawMaterialProductRecipeDocument = gql`
    mutation UpdateRawMaterialProductRecipe($where: RawMaterialProductRecipeWhereUniqueInput!, $data: RawMaterialProductRecipeUpdateInput!) {
  updateRawMaterialProductRecipe(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateRawMaterialProductRecipeMutationFn = Apollo.MutationFunction<UpdateRawMaterialProductRecipeMutation, UpdateRawMaterialProductRecipeMutationVariables>;

/**
 * __useUpdateRawMaterialProductRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRawMaterialProductRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRawMaterialProductRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRawMaterialProductRecipeMutation, { data, loading, error }] = useUpdateRawMaterialProductRecipeMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRawMaterialProductRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRawMaterialProductRecipeMutation, UpdateRawMaterialProductRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRawMaterialProductRecipeMutation, UpdateRawMaterialProductRecipeMutationVariables>(UpdateRawMaterialProductRecipeDocument, options);
      }
export type UpdateRawMaterialProductRecipeMutationHookResult = ReturnType<typeof useUpdateRawMaterialProductRecipeMutation>;
export type UpdateRawMaterialProductRecipeMutationResult = Apollo.MutationResult<UpdateRawMaterialProductRecipeMutation>;
export type UpdateRawMaterialProductRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateRawMaterialProductRecipeMutation, UpdateRawMaterialProductRecipeMutationVariables>;
export const AggregateRawMaterialReleaseDocument = gql`
    query AggregateRawMaterialRelease($where: RawMaterialReleaseWhereInput) {
  aggregateRawMaterialRelease(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateRawMaterialReleaseQuery__
 *
 * To run a query within a React component, call `useAggregateRawMaterialReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateRawMaterialReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateRawMaterialReleaseQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateRawMaterialReleaseQuery(baseOptions?: Apollo.QueryHookOptions<AggregateRawMaterialReleaseQuery, AggregateRawMaterialReleaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateRawMaterialReleaseQuery, AggregateRawMaterialReleaseQueryVariables>(AggregateRawMaterialReleaseDocument, options);
      }
export function useAggregateRawMaterialReleaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateRawMaterialReleaseQuery, AggregateRawMaterialReleaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateRawMaterialReleaseQuery, AggregateRawMaterialReleaseQueryVariables>(AggregateRawMaterialReleaseDocument, options);
        }
export type AggregateRawMaterialReleaseQueryHookResult = ReturnType<typeof useAggregateRawMaterialReleaseQuery>;
export type AggregateRawMaterialReleaseLazyQueryHookResult = ReturnType<typeof useAggregateRawMaterialReleaseLazyQuery>;
export type AggregateRawMaterialReleaseQueryResult = Apollo.QueryResult<AggregateRawMaterialReleaseQuery, AggregateRawMaterialReleaseQueryVariables>;
export const CreateRawMaterialReleaseDocument = gql`
    mutation CreateRawMaterialRelease($data: RawMaterialReleaseCreateInput!) {
  createRawMaterialRelease(data: $data) {
    id
  }
}
    `;
export type CreateRawMaterialReleaseMutationFn = Apollo.MutationFunction<CreateRawMaterialReleaseMutation, CreateRawMaterialReleaseMutationVariables>;

/**
 * __useCreateRawMaterialReleaseMutation__
 *
 * To run a mutation, you first call `useCreateRawMaterialReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRawMaterialReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRawMaterialReleaseMutation, { data, loading, error }] = useCreateRawMaterialReleaseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRawMaterialReleaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateRawMaterialReleaseMutation, CreateRawMaterialReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRawMaterialReleaseMutation, CreateRawMaterialReleaseMutationVariables>(CreateRawMaterialReleaseDocument, options);
      }
export type CreateRawMaterialReleaseMutationHookResult = ReturnType<typeof useCreateRawMaterialReleaseMutation>;
export type CreateRawMaterialReleaseMutationResult = Apollo.MutationResult<CreateRawMaterialReleaseMutation>;
export type CreateRawMaterialReleaseMutationOptions = Apollo.BaseMutationOptions<CreateRawMaterialReleaseMutation, CreateRawMaterialReleaseMutationVariables>;
export const DeleteManyRawMaterialReleaseDocument = gql`
    mutation DeleteManyRawMaterialRelease($where: [RawMaterialReleaseWhereUniqueInput!]) {
  deleteManyRawMaterialRelease(where: $where) {
    id
  }
}
    `;
export type DeleteManyRawMaterialReleaseMutationFn = Apollo.MutationFunction<DeleteManyRawMaterialReleaseMutation, DeleteManyRawMaterialReleaseMutationVariables>;

/**
 * __useDeleteManyRawMaterialReleaseMutation__
 *
 * To run a mutation, you first call `useDeleteManyRawMaterialReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyRawMaterialReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyRawMaterialReleaseMutation, { data, loading, error }] = useDeleteManyRawMaterialReleaseMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyRawMaterialReleaseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyRawMaterialReleaseMutation, DeleteManyRawMaterialReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyRawMaterialReleaseMutation, DeleteManyRawMaterialReleaseMutationVariables>(DeleteManyRawMaterialReleaseDocument, options);
      }
export type DeleteManyRawMaterialReleaseMutationHookResult = ReturnType<typeof useDeleteManyRawMaterialReleaseMutation>;
export type DeleteManyRawMaterialReleaseMutationResult = Apollo.MutationResult<DeleteManyRawMaterialReleaseMutation>;
export type DeleteManyRawMaterialReleaseMutationOptions = Apollo.BaseMutationOptions<DeleteManyRawMaterialReleaseMutation, DeleteManyRawMaterialReleaseMutationVariables>;
export const RawMaterialReleaseDocument = gql`
    query RawMaterialRelease($where: RawMaterialReleaseWhereUniqueInput!) {
  rawMaterialRelease(where: $where) {
    ...AllRawMaterialReleaseFields
  }
}
    ${AllRawMaterialReleaseFieldsFragmentDoc}`;

/**
 * __useRawMaterialReleaseQuery__
 *
 * To run a query within a React component, call `useRawMaterialReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialReleaseQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRawMaterialReleaseQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialReleaseQuery, RawMaterialReleaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialReleaseQuery, RawMaterialReleaseQueryVariables>(RawMaterialReleaseDocument, options);
      }
export function useRawMaterialReleaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialReleaseQuery, RawMaterialReleaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialReleaseQuery, RawMaterialReleaseQueryVariables>(RawMaterialReleaseDocument, options);
        }
export type RawMaterialReleaseQueryHookResult = ReturnType<typeof useRawMaterialReleaseQuery>;
export type RawMaterialReleaseLazyQueryHookResult = ReturnType<typeof useRawMaterialReleaseLazyQuery>;
export type RawMaterialReleaseQueryResult = Apollo.QueryResult<RawMaterialReleaseQuery, RawMaterialReleaseQueryVariables>;
export const RawMaterialReleaseForBreakdownReferencesDocument = gql`
    query RawMaterialReleaseForBreakdownReferences($data: RawMaterialReleaseReferenceInput!) {
  rawMaterialReleaseForBreakdownReferences(data: $data) {
    rawMaterial {
      id
      name
      rawMaterialCategory
      rawMaterialProducts {
        id
        unit {
          id
          name
        }
      }
    }
    referenceQuantity
    referenceUnit {
      id
      name
    }
  }
}
    `;

/**
 * __useRawMaterialReleaseForBreakdownReferencesQuery__
 *
 * To run a query within a React component, call `useRawMaterialReleaseForBreakdownReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialReleaseForBreakdownReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialReleaseForBreakdownReferencesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRawMaterialReleaseForBreakdownReferencesQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialReleaseForBreakdownReferencesQuery, RawMaterialReleaseForBreakdownReferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialReleaseForBreakdownReferencesQuery, RawMaterialReleaseForBreakdownReferencesQueryVariables>(RawMaterialReleaseForBreakdownReferencesDocument, options);
      }
export function useRawMaterialReleaseForBreakdownReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialReleaseForBreakdownReferencesQuery, RawMaterialReleaseForBreakdownReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialReleaseForBreakdownReferencesQuery, RawMaterialReleaseForBreakdownReferencesQueryVariables>(RawMaterialReleaseForBreakdownReferencesDocument, options);
        }
export type RawMaterialReleaseForBreakdownReferencesQueryHookResult = ReturnType<typeof useRawMaterialReleaseForBreakdownReferencesQuery>;
export type RawMaterialReleaseForBreakdownReferencesLazyQueryHookResult = ReturnType<typeof useRawMaterialReleaseForBreakdownReferencesLazyQuery>;
export type RawMaterialReleaseForBreakdownReferencesQueryResult = Apollo.QueryResult<RawMaterialReleaseForBreakdownReferencesQuery, RawMaterialReleaseForBreakdownReferencesQueryVariables>;
export const RawMaterialReleaseItemFromBreakdownReferencesDocument = gql`
    query RawMaterialReleaseItemFromBreakdownReferences($data: [RawMaterialReleaseItemsFromBreakdownInput!]!) {
  rawMaterialReleaseItemFromBreakdownReferences(data: $data) {
    rawMaterial {
      id
      name
      rawMaterialCategory
      rawMaterialProducts {
        id
        unit {
          id
          name
        }
      }
    }
    referenceQuantity
    referenceUnit {
      id
      name
    }
  }
}
    `;

/**
 * __useRawMaterialReleaseItemFromBreakdownReferencesQuery__
 *
 * To run a query within a React component, call `useRawMaterialReleaseItemFromBreakdownReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialReleaseItemFromBreakdownReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialReleaseItemFromBreakdownReferencesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRawMaterialReleaseItemFromBreakdownReferencesQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialReleaseItemFromBreakdownReferencesQuery, RawMaterialReleaseItemFromBreakdownReferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialReleaseItemFromBreakdownReferencesQuery, RawMaterialReleaseItemFromBreakdownReferencesQueryVariables>(RawMaterialReleaseItemFromBreakdownReferencesDocument, options);
      }
export function useRawMaterialReleaseItemFromBreakdownReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialReleaseItemFromBreakdownReferencesQuery, RawMaterialReleaseItemFromBreakdownReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialReleaseItemFromBreakdownReferencesQuery, RawMaterialReleaseItemFromBreakdownReferencesQueryVariables>(RawMaterialReleaseItemFromBreakdownReferencesDocument, options);
        }
export type RawMaterialReleaseItemFromBreakdownReferencesQueryHookResult = ReturnType<typeof useRawMaterialReleaseItemFromBreakdownReferencesQuery>;
export type RawMaterialReleaseItemFromBreakdownReferencesLazyQueryHookResult = ReturnType<typeof useRawMaterialReleaseItemFromBreakdownReferencesLazyQuery>;
export type RawMaterialReleaseItemFromBreakdownReferencesQueryResult = Apollo.QueryResult<RawMaterialReleaseItemFromBreakdownReferencesQuery, RawMaterialReleaseItemFromBreakdownReferencesQueryVariables>;
export const RawMaterialReleaseItemReferencesDocument = gql`
    query RawMaterialReleaseItemReferences($data: RawMaterialReleaseReferenceInput!) {
  rawMaterialReleaseItemReferences(data: $data) {
    rawMaterial {
      id
      name
      rawMaterialCategory
      rawMaterialProducts {
        id
        unit {
          id
          name
        }
      }
    }
    referenceQuantity
    referenceUnit {
      id
      name
    }
  }
}
    `;

/**
 * __useRawMaterialReleaseItemReferencesQuery__
 *
 * To run a query within a React component, call `useRawMaterialReleaseItemReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialReleaseItemReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialReleaseItemReferencesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRawMaterialReleaseItemReferencesQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialReleaseItemReferencesQuery, RawMaterialReleaseItemReferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialReleaseItemReferencesQuery, RawMaterialReleaseItemReferencesQueryVariables>(RawMaterialReleaseItemReferencesDocument, options);
      }
export function useRawMaterialReleaseItemReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialReleaseItemReferencesQuery, RawMaterialReleaseItemReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialReleaseItemReferencesQuery, RawMaterialReleaseItemReferencesQueryVariables>(RawMaterialReleaseItemReferencesDocument, options);
        }
export type RawMaterialReleaseItemReferencesQueryHookResult = ReturnType<typeof useRawMaterialReleaseItemReferencesQuery>;
export type RawMaterialReleaseItemReferencesLazyQueryHookResult = ReturnType<typeof useRawMaterialReleaseItemReferencesLazyQuery>;
export type RawMaterialReleaseItemReferencesQueryResult = Apollo.QueryResult<RawMaterialReleaseItemReferencesQuery, RawMaterialReleaseItemReferencesQueryVariables>;
export const RawMaterialReleasesDocument = gql`
    query RawMaterialReleases($skip: Int, $take: Int, $where: RawMaterialReleaseWhereInput, $orderBy: [RawMaterialReleaseOrderByWithRelationInput!]) {
  rawMaterialReleases(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllRawMaterialReleaseFields
  }
}
    ${AllRawMaterialReleaseFieldsFragmentDoc}`;

/**
 * __useRawMaterialReleasesQuery__
 *
 * To run a query within a React component, call `useRawMaterialReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialReleasesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialReleasesQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialReleasesQuery, RawMaterialReleasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialReleasesQuery, RawMaterialReleasesQueryVariables>(RawMaterialReleasesDocument, options);
      }
export function useRawMaterialReleasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialReleasesQuery, RawMaterialReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialReleasesQuery, RawMaterialReleasesQueryVariables>(RawMaterialReleasesDocument, options);
        }
export type RawMaterialReleasesQueryHookResult = ReturnType<typeof useRawMaterialReleasesQuery>;
export type RawMaterialReleasesLazyQueryHookResult = ReturnType<typeof useRawMaterialReleasesLazyQuery>;
export type RawMaterialReleasesQueryResult = Apollo.QueryResult<RawMaterialReleasesQuery, RawMaterialReleasesQueryVariables>;
export const UpdateRawMaterialReleaseDocument = gql`
    mutation UpdateRawMaterialRelease($where: RawMaterialReleaseWhereUniqueInput!, $data: RawMaterialReleaseUpdateInput!) {
  updateRawMaterialRelease(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateRawMaterialReleaseMutationFn = Apollo.MutationFunction<UpdateRawMaterialReleaseMutation, UpdateRawMaterialReleaseMutationVariables>;

/**
 * __useUpdateRawMaterialReleaseMutation__
 *
 * To run a mutation, you first call `useUpdateRawMaterialReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRawMaterialReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRawMaterialReleaseMutation, { data, loading, error }] = useUpdateRawMaterialReleaseMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRawMaterialReleaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRawMaterialReleaseMutation, UpdateRawMaterialReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRawMaterialReleaseMutation, UpdateRawMaterialReleaseMutationVariables>(UpdateRawMaterialReleaseDocument, options);
      }
export type UpdateRawMaterialReleaseMutationHookResult = ReturnType<typeof useUpdateRawMaterialReleaseMutation>;
export type UpdateRawMaterialReleaseMutationResult = Apollo.MutationResult<UpdateRawMaterialReleaseMutation>;
export type UpdateRawMaterialReleaseMutationOptions = Apollo.BaseMutationOptions<UpdateRawMaterialReleaseMutation, UpdateRawMaterialReleaseMutationVariables>;
export const AggregateRawMaterialRequestDocument = gql`
    query AggregateRawMaterialRequest($where: RawMaterialRequestWhereInput) {
  aggregateRawMaterialRequest(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateRawMaterialRequestQuery__
 *
 * To run a query within a React component, call `useAggregateRawMaterialRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateRawMaterialRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateRawMaterialRequestQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateRawMaterialRequestQuery(baseOptions?: Apollo.QueryHookOptions<AggregateRawMaterialRequestQuery, AggregateRawMaterialRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateRawMaterialRequestQuery, AggregateRawMaterialRequestQueryVariables>(AggregateRawMaterialRequestDocument, options);
      }
export function useAggregateRawMaterialRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateRawMaterialRequestQuery, AggregateRawMaterialRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateRawMaterialRequestQuery, AggregateRawMaterialRequestQueryVariables>(AggregateRawMaterialRequestDocument, options);
        }
export type AggregateRawMaterialRequestQueryHookResult = ReturnType<typeof useAggregateRawMaterialRequestQuery>;
export type AggregateRawMaterialRequestLazyQueryHookResult = ReturnType<typeof useAggregateRawMaterialRequestLazyQuery>;
export type AggregateRawMaterialRequestQueryResult = Apollo.QueryResult<AggregateRawMaterialRequestQuery, AggregateRawMaterialRequestQueryVariables>;
export const CreateRawMaterialRequestDocument = gql`
    mutation CreateRawMaterialRequest($data: RawMaterialRequestCreateInput!) {
  createRawMaterialRequest(data: $data) {
    id
  }
}
    `;
export type CreateRawMaterialRequestMutationFn = Apollo.MutationFunction<CreateRawMaterialRequestMutation, CreateRawMaterialRequestMutationVariables>;

/**
 * __useCreateRawMaterialRequestMutation__
 *
 * To run a mutation, you first call `useCreateRawMaterialRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRawMaterialRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRawMaterialRequestMutation, { data, loading, error }] = useCreateRawMaterialRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRawMaterialRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateRawMaterialRequestMutation, CreateRawMaterialRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRawMaterialRequestMutation, CreateRawMaterialRequestMutationVariables>(CreateRawMaterialRequestDocument, options);
      }
export type CreateRawMaterialRequestMutationHookResult = ReturnType<typeof useCreateRawMaterialRequestMutation>;
export type CreateRawMaterialRequestMutationResult = Apollo.MutationResult<CreateRawMaterialRequestMutation>;
export type CreateRawMaterialRequestMutationOptions = Apollo.BaseMutationOptions<CreateRawMaterialRequestMutation, CreateRawMaterialRequestMutationVariables>;
export const DeleteManyRawMaterialRequestDocument = gql`
    mutation DeleteManyRawMaterialRequest($where: [RawMaterialRequestWhereUniqueInput!]) {
  deleteManyRawMaterialRequest(where: $where) {
    id
  }
}
    `;
export type DeleteManyRawMaterialRequestMutationFn = Apollo.MutationFunction<DeleteManyRawMaterialRequestMutation, DeleteManyRawMaterialRequestMutationVariables>;

/**
 * __useDeleteManyRawMaterialRequestMutation__
 *
 * To run a mutation, you first call `useDeleteManyRawMaterialRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyRawMaterialRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyRawMaterialRequestMutation, { data, loading, error }] = useDeleteManyRawMaterialRequestMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyRawMaterialRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyRawMaterialRequestMutation, DeleteManyRawMaterialRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyRawMaterialRequestMutation, DeleteManyRawMaterialRequestMutationVariables>(DeleteManyRawMaterialRequestDocument, options);
      }
export type DeleteManyRawMaterialRequestMutationHookResult = ReturnType<typeof useDeleteManyRawMaterialRequestMutation>;
export type DeleteManyRawMaterialRequestMutationResult = Apollo.MutationResult<DeleteManyRawMaterialRequestMutation>;
export type DeleteManyRawMaterialRequestMutationOptions = Apollo.BaseMutationOptions<DeleteManyRawMaterialRequestMutation, DeleteManyRawMaterialRequestMutationVariables>;
export const RawMaterialRequestDocument = gql`
    query RawMaterialRequest($where: RawMaterialRequestWhereUniqueInput!) {
  rawMaterialRequest(where: $where) {
    id
    requestDate
    neededDate
    controlNumber
    outlet {
      id
      name
    }
    createdBy
    rawMaterialRequestItems {
      id
      quantity
      rawMaterialProduct {
        id
        rawMaterial {
          id
          name
          rawMaterialProducts {
            id
            baseUnit
            unit {
              id
              name
            }
          }
        }
        unit {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useRawMaterialRequestQuery__
 *
 * To run a query within a React component, call `useRawMaterialRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialRequestQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRawMaterialRequestQuery(baseOptions: Apollo.QueryHookOptions<RawMaterialRequestQuery, RawMaterialRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialRequestQuery, RawMaterialRequestQueryVariables>(RawMaterialRequestDocument, options);
      }
export function useRawMaterialRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialRequestQuery, RawMaterialRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialRequestQuery, RawMaterialRequestQueryVariables>(RawMaterialRequestDocument, options);
        }
export type RawMaterialRequestQueryHookResult = ReturnType<typeof useRawMaterialRequestQuery>;
export type RawMaterialRequestLazyQueryHookResult = ReturnType<typeof useRawMaterialRequestLazyQuery>;
export type RawMaterialRequestQueryResult = Apollo.QueryResult<RawMaterialRequestQuery, RawMaterialRequestQueryVariables>;
export const RawMaterialRequestsDocument = gql`
    query RawMaterialRequests($skip: Int, $take: Int, $where: RawMaterialRequestWhereInput, $orderBy: [RawMaterialRequestOrderByWithRelationInput!]) {
  rawMaterialRequests(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllRawMaterialRequestFields
  }
}
    ${AllRawMaterialRequestFieldsFragmentDoc}`;

/**
 * __useRawMaterialRequestsQuery__
 *
 * To run a query within a React component, call `useRawMaterialRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialRequestsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRawMaterialRequestsQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialRequestsQuery, RawMaterialRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialRequestsQuery, RawMaterialRequestsQueryVariables>(RawMaterialRequestsDocument, options);
      }
export function useRawMaterialRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialRequestsQuery, RawMaterialRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialRequestsQuery, RawMaterialRequestsQueryVariables>(RawMaterialRequestsDocument, options);
        }
export type RawMaterialRequestsQueryHookResult = ReturnType<typeof useRawMaterialRequestsQuery>;
export type RawMaterialRequestsLazyQueryHookResult = ReturnType<typeof useRawMaterialRequestsLazyQuery>;
export type RawMaterialRequestsQueryResult = Apollo.QueryResult<RawMaterialRequestsQuery, RawMaterialRequestsQueryVariables>;
export const UpdateRawMaterialRequestDocument = gql`
    mutation UpdateRawMaterialRequest($where: RawMaterialRequestWhereUniqueInput!, $data: RawMaterialRequestUpdateInput!) {
  updateRawMaterialRequest(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateRawMaterialRequestMutationFn = Apollo.MutationFunction<UpdateRawMaterialRequestMutation, UpdateRawMaterialRequestMutationVariables>;

/**
 * __useUpdateRawMaterialRequestMutation__
 *
 * To run a mutation, you first call `useUpdateRawMaterialRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRawMaterialRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRawMaterialRequestMutation, { data, loading, error }] = useUpdateRawMaterialRequestMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRawMaterialRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRawMaterialRequestMutation, UpdateRawMaterialRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRawMaterialRequestMutation, UpdateRawMaterialRequestMutationVariables>(UpdateRawMaterialRequestDocument, options);
      }
export type UpdateRawMaterialRequestMutationHookResult = ReturnType<typeof useUpdateRawMaterialRequestMutation>;
export type UpdateRawMaterialRequestMutationResult = Apollo.MutationResult<UpdateRawMaterialRequestMutation>;
export type UpdateRawMaterialRequestMutationOptions = Apollo.BaseMutationOptions<UpdateRawMaterialRequestMutation, UpdateRawMaterialRequestMutationVariables>;
export const RawMaterialsConsolidatedThrowAwayReportItemsDocument = gql`
    query RawMaterialsConsolidatedThrowAwayReportItems($where: ConsolidatedThrowAwayReportItemWhereInput) {
  rawMaterialsConsolidatedThrowAwayReportItems(where: $where) {
    date
    rawMaterials {
      name
      quantity
    }
  }
}
    `;

/**
 * __useRawMaterialsConsolidatedThrowAwayReportItemsQuery__
 *
 * To run a query within a React component, call `useRawMaterialsConsolidatedThrowAwayReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialsConsolidatedThrowAwayReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialsConsolidatedThrowAwayReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRawMaterialsConsolidatedThrowAwayReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialsConsolidatedThrowAwayReportItemsQuery, RawMaterialsConsolidatedThrowAwayReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialsConsolidatedThrowAwayReportItemsQuery, RawMaterialsConsolidatedThrowAwayReportItemsQueryVariables>(RawMaterialsConsolidatedThrowAwayReportItemsDocument, options);
      }
export function useRawMaterialsConsolidatedThrowAwayReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialsConsolidatedThrowAwayReportItemsQuery, RawMaterialsConsolidatedThrowAwayReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialsConsolidatedThrowAwayReportItemsQuery, RawMaterialsConsolidatedThrowAwayReportItemsQueryVariables>(RawMaterialsConsolidatedThrowAwayReportItemsDocument, options);
        }
export type RawMaterialsConsolidatedThrowAwayReportItemsQueryHookResult = ReturnType<typeof useRawMaterialsConsolidatedThrowAwayReportItemsQuery>;
export type RawMaterialsConsolidatedThrowAwayReportItemsLazyQueryHookResult = ReturnType<typeof useRawMaterialsConsolidatedThrowAwayReportItemsLazyQuery>;
export type RawMaterialsConsolidatedThrowAwayReportItemsQueryResult = Apollo.QueryResult<RawMaterialsConsolidatedThrowAwayReportItemsQuery, RawMaterialsConsolidatedThrowAwayReportItemsQueryVariables>;
export const RawMaterialsSalesProductControlReportItemsDocument = gql`
    query RawMaterialsSalesProductControlReportItems($where: SalesProductControlReportItemWhereInput) {
  rawMaterialsSalesProductControlReportItems(where: $where) {
    rawMaterial
    unit
    beginningQuantity
    deliveriesQuantity
    transferInQuantity
    transferOutQuantity
    emQuantity
    sdQuantity
    taQuantity
    endingQuantity
    usage
    crtRawMaterialItemQuantity
    variance
  }
}
    `;

/**
 * __useRawMaterialsSalesProductControlReportItemsQuery__
 *
 * To run a query within a React component, call `useRawMaterialsSalesProductControlReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawMaterialsSalesProductControlReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawMaterialsSalesProductControlReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRawMaterialsSalesProductControlReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<RawMaterialsSalesProductControlReportItemsQuery, RawMaterialsSalesProductControlReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawMaterialsSalesProductControlReportItemsQuery, RawMaterialsSalesProductControlReportItemsQueryVariables>(RawMaterialsSalesProductControlReportItemsDocument, options);
      }
export function useRawMaterialsSalesProductControlReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawMaterialsSalesProductControlReportItemsQuery, RawMaterialsSalesProductControlReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawMaterialsSalesProductControlReportItemsQuery, RawMaterialsSalesProductControlReportItemsQueryVariables>(RawMaterialsSalesProductControlReportItemsDocument, options);
        }
export type RawMaterialsSalesProductControlReportItemsQueryHookResult = ReturnType<typeof useRawMaterialsSalesProductControlReportItemsQuery>;
export type RawMaterialsSalesProductControlReportItemsLazyQueryHookResult = ReturnType<typeof useRawMaterialsSalesProductControlReportItemsLazyQuery>;
export type RawMaterialsSalesProductControlReportItemsQueryResult = Apollo.QueryResult<RawMaterialsSalesProductControlReportItemsQuery, RawMaterialsSalesProductControlReportItemsQueryVariables>;
export const AggregateReceivedDeliveryReceiptDocument = gql`
    query AggregateReceivedDeliveryReceipt($where: ReceivedDeliveryReceiptWhereInput) {
  aggregateReceivedDeliveryReceipt(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateReceivedDeliveryReceiptQuery__
 *
 * To run a query within a React component, call `useAggregateReceivedDeliveryReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateReceivedDeliveryReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateReceivedDeliveryReceiptQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateReceivedDeliveryReceiptQuery(baseOptions?: Apollo.QueryHookOptions<AggregateReceivedDeliveryReceiptQuery, AggregateReceivedDeliveryReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateReceivedDeliveryReceiptQuery, AggregateReceivedDeliveryReceiptQueryVariables>(AggregateReceivedDeliveryReceiptDocument, options);
      }
export function useAggregateReceivedDeliveryReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateReceivedDeliveryReceiptQuery, AggregateReceivedDeliveryReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateReceivedDeliveryReceiptQuery, AggregateReceivedDeliveryReceiptQueryVariables>(AggregateReceivedDeliveryReceiptDocument, options);
        }
export type AggregateReceivedDeliveryReceiptQueryHookResult = ReturnType<typeof useAggregateReceivedDeliveryReceiptQuery>;
export type AggregateReceivedDeliveryReceiptLazyQueryHookResult = ReturnType<typeof useAggregateReceivedDeliveryReceiptLazyQuery>;
export type AggregateReceivedDeliveryReceiptQueryResult = Apollo.QueryResult<AggregateReceivedDeliveryReceiptQuery, AggregateReceivedDeliveryReceiptQueryVariables>;
export const CreateReceivedDeliveryReceiptDocument = gql`
    mutation CreateReceivedDeliveryReceipt($data: ReceivedDeliveryReceiptCreateInput!) {
  createReceivedDeliveryReceipt(data: $data) {
    id
  }
}
    `;
export type CreateReceivedDeliveryReceiptMutationFn = Apollo.MutationFunction<CreateReceivedDeliveryReceiptMutation, CreateReceivedDeliveryReceiptMutationVariables>;

/**
 * __useCreateReceivedDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useCreateReceivedDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceivedDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceivedDeliveryReceiptMutation, { data, loading, error }] = useCreateReceivedDeliveryReceiptMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReceivedDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceivedDeliveryReceiptMutation, CreateReceivedDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReceivedDeliveryReceiptMutation, CreateReceivedDeliveryReceiptMutationVariables>(CreateReceivedDeliveryReceiptDocument, options);
      }
export type CreateReceivedDeliveryReceiptMutationHookResult = ReturnType<typeof useCreateReceivedDeliveryReceiptMutation>;
export type CreateReceivedDeliveryReceiptMutationResult = Apollo.MutationResult<CreateReceivedDeliveryReceiptMutation>;
export type CreateReceivedDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<CreateReceivedDeliveryReceiptMutation, CreateReceivedDeliveryReceiptMutationVariables>;
export const DeleteManyReceivedDeliveryReceiptDocument = gql`
    mutation DeleteManyReceivedDeliveryReceipt($where: [ReceivedDeliveryReceiptWhereUniqueInput!]) {
  deleteManyReceivedDeliveryReceipt(where: $where) {
    id
  }
}
    `;
export type DeleteManyReceivedDeliveryReceiptMutationFn = Apollo.MutationFunction<DeleteManyReceivedDeliveryReceiptMutation, DeleteManyReceivedDeliveryReceiptMutationVariables>;

/**
 * __useDeleteManyReceivedDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteManyReceivedDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyReceivedDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyReceivedDeliveryReceiptMutation, { data, loading, error }] = useDeleteManyReceivedDeliveryReceiptMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyReceivedDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyReceivedDeliveryReceiptMutation, DeleteManyReceivedDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyReceivedDeliveryReceiptMutation, DeleteManyReceivedDeliveryReceiptMutationVariables>(DeleteManyReceivedDeliveryReceiptDocument, options);
      }
export type DeleteManyReceivedDeliveryReceiptMutationHookResult = ReturnType<typeof useDeleteManyReceivedDeliveryReceiptMutation>;
export type DeleteManyReceivedDeliveryReceiptMutationResult = Apollo.MutationResult<DeleteManyReceivedDeliveryReceiptMutation>;
export type DeleteManyReceivedDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<DeleteManyReceivedDeliveryReceiptMutation, DeleteManyReceivedDeliveryReceiptMutationVariables>;
export const ReceivedDeliveryReceiptDocument = gql`
    query ReceivedDeliveryReceipt($where: ReceivedDeliveryReceiptWhereUniqueInput!) {
  receivedDeliveryReceipt(where: $where) {
    ...AllReceivedDeliveryReceiptFields
  }
}
    ${AllReceivedDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useReceivedDeliveryReceiptQuery__
 *
 * To run a query within a React component, call `useReceivedDeliveryReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedDeliveryReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedDeliveryReceiptQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReceivedDeliveryReceiptQuery(baseOptions: Apollo.QueryHookOptions<ReceivedDeliveryReceiptQuery, ReceivedDeliveryReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedDeliveryReceiptQuery, ReceivedDeliveryReceiptQueryVariables>(ReceivedDeliveryReceiptDocument, options);
      }
export function useReceivedDeliveryReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedDeliveryReceiptQuery, ReceivedDeliveryReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedDeliveryReceiptQuery, ReceivedDeliveryReceiptQueryVariables>(ReceivedDeliveryReceiptDocument, options);
        }
export type ReceivedDeliveryReceiptQueryHookResult = ReturnType<typeof useReceivedDeliveryReceiptQuery>;
export type ReceivedDeliveryReceiptLazyQueryHookResult = ReturnType<typeof useReceivedDeliveryReceiptLazyQuery>;
export type ReceivedDeliveryReceiptQueryResult = Apollo.QueryResult<ReceivedDeliveryReceiptQuery, ReceivedDeliveryReceiptQueryVariables>;
export const ReceivedDeliveryReceiptsDocument = gql`
    query ReceivedDeliveryReceipts($skip: Int, $take: Int, $where: ReceivedDeliveryReceiptWhereInput, $orderBy: [ReceivedDeliveryReceiptOrderByWithRelationInput!]) {
  receivedDeliveryReceipts(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllReceivedDeliveryReceiptFields
  }
}
    ${AllReceivedDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useReceivedDeliveryReceiptsQuery__
 *
 * To run a query within a React component, call `useReceivedDeliveryReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedDeliveryReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedDeliveryReceiptsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReceivedDeliveryReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<ReceivedDeliveryReceiptsQuery, ReceivedDeliveryReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedDeliveryReceiptsQuery, ReceivedDeliveryReceiptsQueryVariables>(ReceivedDeliveryReceiptsDocument, options);
      }
export function useReceivedDeliveryReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedDeliveryReceiptsQuery, ReceivedDeliveryReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedDeliveryReceiptsQuery, ReceivedDeliveryReceiptsQueryVariables>(ReceivedDeliveryReceiptsDocument, options);
        }
export type ReceivedDeliveryReceiptsQueryHookResult = ReturnType<typeof useReceivedDeliveryReceiptsQuery>;
export type ReceivedDeliveryReceiptsLazyQueryHookResult = ReturnType<typeof useReceivedDeliveryReceiptsLazyQuery>;
export type ReceivedDeliveryReceiptsQueryResult = Apollo.QueryResult<ReceivedDeliveryReceiptsQuery, ReceivedDeliveryReceiptsQueryVariables>;
export const UpdateReceivedDeliveryReceiptDocument = gql`
    mutation UpdateReceivedDeliveryReceipt($where: ReceivedDeliveryReceiptWhereUniqueInput!, $data: ReceivedDeliveryReceiptUpdateInput!) {
  updateReceivedDeliveryReceipt(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateReceivedDeliveryReceiptMutationFn = Apollo.MutationFunction<UpdateReceivedDeliveryReceiptMutation, UpdateReceivedDeliveryReceiptMutationVariables>;

/**
 * __useUpdateReceivedDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateReceivedDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceivedDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceivedDeliveryReceiptMutation, { data, loading, error }] = useUpdateReceivedDeliveryReceiptMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReceivedDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReceivedDeliveryReceiptMutation, UpdateReceivedDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReceivedDeliveryReceiptMutation, UpdateReceivedDeliveryReceiptMutationVariables>(UpdateReceivedDeliveryReceiptDocument, options);
      }
export type UpdateReceivedDeliveryReceiptMutationHookResult = ReturnType<typeof useUpdateReceivedDeliveryReceiptMutation>;
export type UpdateReceivedDeliveryReceiptMutationResult = Apollo.MutationResult<UpdateReceivedDeliveryReceiptMutation>;
export type UpdateReceivedDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<UpdateReceivedDeliveryReceiptMutation, UpdateReceivedDeliveryReceiptMutationVariables>;
export const AggregateReceivedSdsDeliveryReceiptDocument = gql`
    query AggregateReceivedSdsDeliveryReceipt($where: ReceivedSdsDeliveryReceiptWhereInput) {
  aggregateReceivedSdsDeliveryReceipt(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateReceivedSdsDeliveryReceiptQuery__
 *
 * To run a query within a React component, call `useAggregateReceivedSdsDeliveryReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateReceivedSdsDeliveryReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateReceivedSdsDeliveryReceiptQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateReceivedSdsDeliveryReceiptQuery(baseOptions?: Apollo.QueryHookOptions<AggregateReceivedSdsDeliveryReceiptQuery, AggregateReceivedSdsDeliveryReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateReceivedSdsDeliveryReceiptQuery, AggregateReceivedSdsDeliveryReceiptQueryVariables>(AggregateReceivedSdsDeliveryReceiptDocument, options);
      }
export function useAggregateReceivedSdsDeliveryReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateReceivedSdsDeliveryReceiptQuery, AggregateReceivedSdsDeliveryReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateReceivedSdsDeliveryReceiptQuery, AggregateReceivedSdsDeliveryReceiptQueryVariables>(AggregateReceivedSdsDeliveryReceiptDocument, options);
        }
export type AggregateReceivedSdsDeliveryReceiptQueryHookResult = ReturnType<typeof useAggregateReceivedSdsDeliveryReceiptQuery>;
export type AggregateReceivedSdsDeliveryReceiptLazyQueryHookResult = ReturnType<typeof useAggregateReceivedSdsDeliveryReceiptLazyQuery>;
export type AggregateReceivedSdsDeliveryReceiptQueryResult = Apollo.QueryResult<AggregateReceivedSdsDeliveryReceiptQuery, AggregateReceivedSdsDeliveryReceiptQueryVariables>;
export const CreateReceivedSdsDeliveryReceiptDocument = gql`
    mutation CreateReceivedSdsDeliveryReceipt($data: ReceivedSdsDeliveryReceiptCreateInput!) {
  createReceivedSdsDeliveryReceipt(data: $data) {
    id
  }
}
    `;
export type CreateReceivedSdsDeliveryReceiptMutationFn = Apollo.MutationFunction<CreateReceivedSdsDeliveryReceiptMutation, CreateReceivedSdsDeliveryReceiptMutationVariables>;

/**
 * __useCreateReceivedSdsDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useCreateReceivedSdsDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceivedSdsDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceivedSdsDeliveryReceiptMutation, { data, loading, error }] = useCreateReceivedSdsDeliveryReceiptMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReceivedSdsDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceivedSdsDeliveryReceiptMutation, CreateReceivedSdsDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReceivedSdsDeliveryReceiptMutation, CreateReceivedSdsDeliveryReceiptMutationVariables>(CreateReceivedSdsDeliveryReceiptDocument, options);
      }
export type CreateReceivedSdsDeliveryReceiptMutationHookResult = ReturnType<typeof useCreateReceivedSdsDeliveryReceiptMutation>;
export type CreateReceivedSdsDeliveryReceiptMutationResult = Apollo.MutationResult<CreateReceivedSdsDeliveryReceiptMutation>;
export type CreateReceivedSdsDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<CreateReceivedSdsDeliveryReceiptMutation, CreateReceivedSdsDeliveryReceiptMutationVariables>;
export const DeleteManyReceivedSdsDeliveryReceiptDocument = gql`
    mutation DeleteManyReceivedSdsDeliveryReceipt($where: [ReceivedSdsDeliveryReceiptWhereUniqueInput!]) {
  deleteManyReceivedSdsDeliveryReceipt(where: $where) {
    id
  }
}
    `;
export type DeleteManyReceivedSdsDeliveryReceiptMutationFn = Apollo.MutationFunction<DeleteManyReceivedSdsDeliveryReceiptMutation, DeleteManyReceivedSdsDeliveryReceiptMutationVariables>;

/**
 * __useDeleteManyReceivedSdsDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteManyReceivedSdsDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyReceivedSdsDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyReceivedSdsDeliveryReceiptMutation, { data, loading, error }] = useDeleteManyReceivedSdsDeliveryReceiptMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyReceivedSdsDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyReceivedSdsDeliveryReceiptMutation, DeleteManyReceivedSdsDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyReceivedSdsDeliveryReceiptMutation, DeleteManyReceivedSdsDeliveryReceiptMutationVariables>(DeleteManyReceivedSdsDeliveryReceiptDocument, options);
      }
export type DeleteManyReceivedSdsDeliveryReceiptMutationHookResult = ReturnType<typeof useDeleteManyReceivedSdsDeliveryReceiptMutation>;
export type DeleteManyReceivedSdsDeliveryReceiptMutationResult = Apollo.MutationResult<DeleteManyReceivedSdsDeliveryReceiptMutation>;
export type DeleteManyReceivedSdsDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<DeleteManyReceivedSdsDeliveryReceiptMutation, DeleteManyReceivedSdsDeliveryReceiptMutationVariables>;
export const ReceivedSdsDeliveryReceiptDocument = gql`
    query ReceivedSdsDeliveryReceipt($where: ReceivedSdsDeliveryReceiptWhereUniqueInput!) {
  receivedSdsDeliveryReceipt(where: $where) {
    ...AllReceivedSdsDeliveryReceiptFields
  }
}
    ${AllReceivedSdsDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useReceivedSdsDeliveryReceiptQuery__
 *
 * To run a query within a React component, call `useReceivedSdsDeliveryReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedSdsDeliveryReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedSdsDeliveryReceiptQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReceivedSdsDeliveryReceiptQuery(baseOptions: Apollo.QueryHookOptions<ReceivedSdsDeliveryReceiptQuery, ReceivedSdsDeliveryReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedSdsDeliveryReceiptQuery, ReceivedSdsDeliveryReceiptQueryVariables>(ReceivedSdsDeliveryReceiptDocument, options);
      }
export function useReceivedSdsDeliveryReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedSdsDeliveryReceiptQuery, ReceivedSdsDeliveryReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedSdsDeliveryReceiptQuery, ReceivedSdsDeliveryReceiptQueryVariables>(ReceivedSdsDeliveryReceiptDocument, options);
        }
export type ReceivedSdsDeliveryReceiptQueryHookResult = ReturnType<typeof useReceivedSdsDeliveryReceiptQuery>;
export type ReceivedSdsDeliveryReceiptLazyQueryHookResult = ReturnType<typeof useReceivedSdsDeliveryReceiptLazyQuery>;
export type ReceivedSdsDeliveryReceiptQueryResult = Apollo.QueryResult<ReceivedSdsDeliveryReceiptQuery, ReceivedSdsDeliveryReceiptQueryVariables>;
export const ReceivedSdsDeliveryReceiptsDocument = gql`
    query ReceivedSdsDeliveryReceipts($skip: Int, $take: Int, $where: ReceivedSdsDeliveryReceiptWhereInput, $orderBy: [ReceivedSdsDeliveryReceiptOrderByWithRelationInput!]) {
  receivedSdsDeliveryReceipts(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllReceivedSdsDeliveryReceiptFields
  }
}
    ${AllReceivedSdsDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useReceivedSdsDeliveryReceiptsQuery__
 *
 * To run a query within a React component, call `useReceivedSdsDeliveryReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedSdsDeliveryReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedSdsDeliveryReceiptsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReceivedSdsDeliveryReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<ReceivedSdsDeliveryReceiptsQuery, ReceivedSdsDeliveryReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedSdsDeliveryReceiptsQuery, ReceivedSdsDeliveryReceiptsQueryVariables>(ReceivedSdsDeliveryReceiptsDocument, options);
      }
export function useReceivedSdsDeliveryReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedSdsDeliveryReceiptsQuery, ReceivedSdsDeliveryReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedSdsDeliveryReceiptsQuery, ReceivedSdsDeliveryReceiptsQueryVariables>(ReceivedSdsDeliveryReceiptsDocument, options);
        }
export type ReceivedSdsDeliveryReceiptsQueryHookResult = ReturnType<typeof useReceivedSdsDeliveryReceiptsQuery>;
export type ReceivedSdsDeliveryReceiptsLazyQueryHookResult = ReturnType<typeof useReceivedSdsDeliveryReceiptsLazyQuery>;
export type ReceivedSdsDeliveryReceiptsQueryResult = Apollo.QueryResult<ReceivedSdsDeliveryReceiptsQuery, ReceivedSdsDeliveryReceiptsQueryVariables>;
export const UpdateReceivedSdsDeliveryReceiptDocument = gql`
    mutation UpdateReceivedSdsDeliveryReceipt($where: ReceivedSdsDeliveryReceiptWhereUniqueInput!, $data: ReceivedSdsDeliveryReceiptUpdateInput!) {
  updateReceivedSdsDeliveryReceipt(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateReceivedSdsDeliveryReceiptMutationFn = Apollo.MutationFunction<UpdateReceivedSdsDeliveryReceiptMutation, UpdateReceivedSdsDeliveryReceiptMutationVariables>;

/**
 * __useUpdateReceivedSdsDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateReceivedSdsDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceivedSdsDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceivedSdsDeliveryReceiptMutation, { data, loading, error }] = useUpdateReceivedSdsDeliveryReceiptMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReceivedSdsDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReceivedSdsDeliveryReceiptMutation, UpdateReceivedSdsDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReceivedSdsDeliveryReceiptMutation, UpdateReceivedSdsDeliveryReceiptMutationVariables>(UpdateReceivedSdsDeliveryReceiptDocument, options);
      }
export type UpdateReceivedSdsDeliveryReceiptMutationHookResult = ReturnType<typeof useUpdateReceivedSdsDeliveryReceiptMutation>;
export type UpdateReceivedSdsDeliveryReceiptMutationResult = Apollo.MutationResult<UpdateReceivedSdsDeliveryReceiptMutation>;
export type UpdateReceivedSdsDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<UpdateReceivedSdsDeliveryReceiptMutation, UpdateReceivedSdsDeliveryReceiptMutationVariables>;
export const AggregateReceivedTransferDocument = gql`
    query AggregateReceivedTransfer($where: ReceivedTransferWhereInput) {
  aggregateReceivedTransfer(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateReceivedTransferQuery__
 *
 * To run a query within a React component, call `useAggregateReceivedTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateReceivedTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateReceivedTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateReceivedTransferQuery(baseOptions?: Apollo.QueryHookOptions<AggregateReceivedTransferQuery, AggregateReceivedTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateReceivedTransferQuery, AggregateReceivedTransferQueryVariables>(AggregateReceivedTransferDocument, options);
      }
export function useAggregateReceivedTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateReceivedTransferQuery, AggregateReceivedTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateReceivedTransferQuery, AggregateReceivedTransferQueryVariables>(AggregateReceivedTransferDocument, options);
        }
export type AggregateReceivedTransferQueryHookResult = ReturnType<typeof useAggregateReceivedTransferQuery>;
export type AggregateReceivedTransferLazyQueryHookResult = ReturnType<typeof useAggregateReceivedTransferLazyQuery>;
export type AggregateReceivedTransferQueryResult = Apollo.QueryResult<AggregateReceivedTransferQuery, AggregateReceivedTransferQueryVariables>;
export const CreateReceivedTransferDocument = gql`
    mutation CreateReceivedTransfer($data: ReceivedTransferCreateInput!) {
  createReceivedTransfer(data: $data) {
    id
  }
}
    `;
export type CreateReceivedTransferMutationFn = Apollo.MutationFunction<CreateReceivedTransferMutation, CreateReceivedTransferMutationVariables>;

/**
 * __useCreateReceivedTransferMutation__
 *
 * To run a mutation, you first call `useCreateReceivedTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceivedTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceivedTransferMutation, { data, loading, error }] = useCreateReceivedTransferMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReceivedTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceivedTransferMutation, CreateReceivedTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReceivedTransferMutation, CreateReceivedTransferMutationVariables>(CreateReceivedTransferDocument, options);
      }
export type CreateReceivedTransferMutationHookResult = ReturnType<typeof useCreateReceivedTransferMutation>;
export type CreateReceivedTransferMutationResult = Apollo.MutationResult<CreateReceivedTransferMutation>;
export type CreateReceivedTransferMutationOptions = Apollo.BaseMutationOptions<CreateReceivedTransferMutation, CreateReceivedTransferMutationVariables>;
export const DeleteManyReceivedTransferDocument = gql`
    mutation DeleteManyReceivedTransfer($where: [ReceivedTransferWhereUniqueInput!]) {
  deleteManyReceivedTransfer(where: $where) {
    id
  }
}
    `;
export type DeleteManyReceivedTransferMutationFn = Apollo.MutationFunction<DeleteManyReceivedTransferMutation, DeleteManyReceivedTransferMutationVariables>;

/**
 * __useDeleteManyReceivedTransferMutation__
 *
 * To run a mutation, you first call `useDeleteManyReceivedTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyReceivedTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyReceivedTransferMutation, { data, loading, error }] = useDeleteManyReceivedTransferMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyReceivedTransferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyReceivedTransferMutation, DeleteManyReceivedTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyReceivedTransferMutation, DeleteManyReceivedTransferMutationVariables>(DeleteManyReceivedTransferDocument, options);
      }
export type DeleteManyReceivedTransferMutationHookResult = ReturnType<typeof useDeleteManyReceivedTransferMutation>;
export type DeleteManyReceivedTransferMutationResult = Apollo.MutationResult<DeleteManyReceivedTransferMutation>;
export type DeleteManyReceivedTransferMutationOptions = Apollo.BaseMutationOptions<DeleteManyReceivedTransferMutation, DeleteManyReceivedTransferMutationVariables>;
export const ReceivedTransferDocument = gql`
    query ReceivedTransfer($where: ReceivedTransferWhereUniqueInput!) {
  receivedTransfer(where: $where) {
    ...AllReceivedTransferFields
  }
}
    ${AllReceivedTransferFieldsFragmentDoc}`;

/**
 * __useReceivedTransferQuery__
 *
 * To run a query within a React component, call `useReceivedTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReceivedTransferQuery(baseOptions: Apollo.QueryHookOptions<ReceivedTransferQuery, ReceivedTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedTransferQuery, ReceivedTransferQueryVariables>(ReceivedTransferDocument, options);
      }
export function useReceivedTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedTransferQuery, ReceivedTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedTransferQuery, ReceivedTransferQueryVariables>(ReceivedTransferDocument, options);
        }
export type ReceivedTransferQueryHookResult = ReturnType<typeof useReceivedTransferQuery>;
export type ReceivedTransferLazyQueryHookResult = ReturnType<typeof useReceivedTransferLazyQuery>;
export type ReceivedTransferQueryResult = Apollo.QueryResult<ReceivedTransferQuery, ReceivedTransferQueryVariables>;
export const ReceivedTransfersDocument = gql`
    query ReceivedTransfers($skip: Int, $take: Int, $where: ReceivedTransferWhereInput, $orderBy: [ReceivedTransferOrderByWithRelationInput!]) {
  receivedTransfers(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllReceivedTransferFields
  }
}
    ${AllReceivedTransferFieldsFragmentDoc}`;

/**
 * __useReceivedTransfersQuery__
 *
 * To run a query within a React component, call `useReceivedTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedTransfersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReceivedTransfersQuery(baseOptions?: Apollo.QueryHookOptions<ReceivedTransfersQuery, ReceivedTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedTransfersQuery, ReceivedTransfersQueryVariables>(ReceivedTransfersDocument, options);
      }
export function useReceivedTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedTransfersQuery, ReceivedTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedTransfersQuery, ReceivedTransfersQueryVariables>(ReceivedTransfersDocument, options);
        }
export type ReceivedTransfersQueryHookResult = ReturnType<typeof useReceivedTransfersQuery>;
export type ReceivedTransfersLazyQueryHookResult = ReturnType<typeof useReceivedTransfersLazyQuery>;
export type ReceivedTransfersQueryResult = Apollo.QueryResult<ReceivedTransfersQuery, ReceivedTransfersQueryVariables>;
export const UpdateReceivedTransferDocument = gql`
    mutation UpdateReceivedTransfer($where: ReceivedTransferWhereUniqueInput!, $data: ReceivedTransferUpdateInput!) {
  updateReceivedTransfer(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateReceivedTransferMutationFn = Apollo.MutationFunction<UpdateReceivedTransferMutation, UpdateReceivedTransferMutationVariables>;

/**
 * __useUpdateReceivedTransferMutation__
 *
 * To run a mutation, you first call `useUpdateReceivedTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceivedTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceivedTransferMutation, { data, loading, error }] = useUpdateReceivedTransferMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReceivedTransferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReceivedTransferMutation, UpdateReceivedTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReceivedTransferMutation, UpdateReceivedTransferMutationVariables>(UpdateReceivedTransferDocument, options);
      }
export type UpdateReceivedTransferMutationHookResult = ReturnType<typeof useUpdateReceivedTransferMutation>;
export type UpdateReceivedTransferMutationResult = Apollo.MutationResult<UpdateReceivedTransferMutation>;
export type UpdateReceivedTransferMutationOptions = Apollo.BaseMutationOptions<UpdateReceivedTransferMutation, UpdateReceivedTransferMutationVariables>;
export const SalesPackagingControlReportItemsDocument = gql`
    query SalesPackagingControlReportItems($where: SalesPackagingControlReportItemWhereInput) {
  salesPackagingControlReportItems(where: $where) {
    packaging
    category
    unit
    beginningQuantity
    deliveriesQuantity
    transferInQuantity
    transferOutQuantity
    emQuantity
    sdQuantity
    taQuantity
    endingQuantity
    usage
    crtPackagingItemQuantity
    variance
  }
}
    `;

/**
 * __useSalesPackagingControlReportItemsQuery__
 *
 * To run a query within a React component, call `useSalesPackagingControlReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPackagingControlReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPackagingControlReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSalesPackagingControlReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<SalesPackagingControlReportItemsQuery, SalesPackagingControlReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesPackagingControlReportItemsQuery, SalesPackagingControlReportItemsQueryVariables>(SalesPackagingControlReportItemsDocument, options);
      }
export function useSalesPackagingControlReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesPackagingControlReportItemsQuery, SalesPackagingControlReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesPackagingControlReportItemsQuery, SalesPackagingControlReportItemsQueryVariables>(SalesPackagingControlReportItemsDocument, options);
        }
export type SalesPackagingControlReportItemsQueryHookResult = ReturnType<typeof useSalesPackagingControlReportItemsQuery>;
export type SalesPackagingControlReportItemsLazyQueryHookResult = ReturnType<typeof useSalesPackagingControlReportItemsLazyQuery>;
export type SalesPackagingControlReportItemsQueryResult = Apollo.QueryResult<SalesPackagingControlReportItemsQuery, SalesPackagingControlReportItemsQueryVariables>;
export const AggregateSdsDeliveryReceiptDocument = gql`
    query AggregateSdsDeliveryReceipt($where: SdsDeliveryReceiptWhereInput) {
  aggregateSdsDeliveryReceipt(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateSdsDeliveryReceiptQuery__
 *
 * To run a query within a React component, call `useAggregateSdsDeliveryReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateSdsDeliveryReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateSdsDeliveryReceiptQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateSdsDeliveryReceiptQuery(baseOptions?: Apollo.QueryHookOptions<AggregateSdsDeliveryReceiptQuery, AggregateSdsDeliveryReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateSdsDeliveryReceiptQuery, AggregateSdsDeliveryReceiptQueryVariables>(AggregateSdsDeliveryReceiptDocument, options);
      }
export function useAggregateSdsDeliveryReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateSdsDeliveryReceiptQuery, AggregateSdsDeliveryReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateSdsDeliveryReceiptQuery, AggregateSdsDeliveryReceiptQueryVariables>(AggregateSdsDeliveryReceiptDocument, options);
        }
export type AggregateSdsDeliveryReceiptQueryHookResult = ReturnType<typeof useAggregateSdsDeliveryReceiptQuery>;
export type AggregateSdsDeliveryReceiptLazyQueryHookResult = ReturnType<typeof useAggregateSdsDeliveryReceiptLazyQuery>;
export type AggregateSdsDeliveryReceiptQueryResult = Apollo.QueryResult<AggregateSdsDeliveryReceiptQuery, AggregateSdsDeliveryReceiptQueryVariables>;
export const CreateSdsDeliveryReceiptDocument = gql`
    mutation CreateSdsDeliveryReceipt($data: SdsDeliveryReceiptCreateInput!) {
  createSdsDeliveryReceipt(data: $data) {
    id
  }
}
    `;
export type CreateSdsDeliveryReceiptMutationFn = Apollo.MutationFunction<CreateSdsDeliveryReceiptMutation, CreateSdsDeliveryReceiptMutationVariables>;

/**
 * __useCreateSdsDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useCreateSdsDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSdsDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSdsDeliveryReceiptMutation, { data, loading, error }] = useCreateSdsDeliveryReceiptMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSdsDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateSdsDeliveryReceiptMutation, CreateSdsDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSdsDeliveryReceiptMutation, CreateSdsDeliveryReceiptMutationVariables>(CreateSdsDeliveryReceiptDocument, options);
      }
export type CreateSdsDeliveryReceiptMutationHookResult = ReturnType<typeof useCreateSdsDeliveryReceiptMutation>;
export type CreateSdsDeliveryReceiptMutationResult = Apollo.MutationResult<CreateSdsDeliveryReceiptMutation>;
export type CreateSdsDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<CreateSdsDeliveryReceiptMutation, CreateSdsDeliveryReceiptMutationVariables>;
export const DeleteManySdsDeliveryReceiptDocument = gql`
    mutation DeleteManySdsDeliveryReceipt($where: [SdsDeliveryReceiptWhereUniqueInput!]) {
  deleteManySdsDeliveryReceipt(where: $where) {
    id
  }
}
    `;
export type DeleteManySdsDeliveryReceiptMutationFn = Apollo.MutationFunction<DeleteManySdsDeliveryReceiptMutation, DeleteManySdsDeliveryReceiptMutationVariables>;

/**
 * __useDeleteManySdsDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteManySdsDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManySdsDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManySdsDeliveryReceiptMutation, { data, loading, error }] = useDeleteManySdsDeliveryReceiptMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManySdsDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManySdsDeliveryReceiptMutation, DeleteManySdsDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManySdsDeliveryReceiptMutation, DeleteManySdsDeliveryReceiptMutationVariables>(DeleteManySdsDeliveryReceiptDocument, options);
      }
export type DeleteManySdsDeliveryReceiptMutationHookResult = ReturnType<typeof useDeleteManySdsDeliveryReceiptMutation>;
export type DeleteManySdsDeliveryReceiptMutationResult = Apollo.MutationResult<DeleteManySdsDeliveryReceiptMutation>;
export type DeleteManySdsDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<DeleteManySdsDeliveryReceiptMutation, DeleteManySdsDeliveryReceiptMutationVariables>;
export const SdsDeliveryReceiptDocument = gql`
    query SdsDeliveryReceipt($where: SdsDeliveryReceiptWhereUniqueInput!) {
  sdsDeliveryReceipt(where: $where) {
    ...AllSdsDeliveryReceiptFields
  }
}
    ${AllSdsDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useSdsDeliveryReceiptQuery__
 *
 * To run a query within a React component, call `useSdsDeliveryReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useSdsDeliveryReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSdsDeliveryReceiptQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSdsDeliveryReceiptQuery(baseOptions: Apollo.QueryHookOptions<SdsDeliveryReceiptQuery, SdsDeliveryReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SdsDeliveryReceiptQuery, SdsDeliveryReceiptQueryVariables>(SdsDeliveryReceiptDocument, options);
      }
export function useSdsDeliveryReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SdsDeliveryReceiptQuery, SdsDeliveryReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SdsDeliveryReceiptQuery, SdsDeliveryReceiptQueryVariables>(SdsDeliveryReceiptDocument, options);
        }
export type SdsDeliveryReceiptQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptQuery>;
export type SdsDeliveryReceiptLazyQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptLazyQuery>;
export type SdsDeliveryReceiptQueryResult = Apollo.QueryResult<SdsDeliveryReceiptQuery, SdsDeliveryReceiptQueryVariables>;
export const SdsDeliveryReceiptsDocument = gql`
    query SdsDeliveryReceipts($skip: Int, $take: Int, $where: SdsDeliveryReceiptWhereInput, $orderBy: [SdsDeliveryReceiptOrderByWithRelationInput!]) {
  sdsDeliveryReceipts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllSdsDeliveryReceiptFields
  }
}
    ${AllSdsDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useSdsDeliveryReceiptsQuery__
 *
 * To run a query within a React component, call `useSdsDeliveryReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSdsDeliveryReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSdsDeliveryReceiptsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSdsDeliveryReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<SdsDeliveryReceiptsQuery, SdsDeliveryReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SdsDeliveryReceiptsQuery, SdsDeliveryReceiptsQueryVariables>(SdsDeliveryReceiptsDocument, options);
      }
export function useSdsDeliveryReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SdsDeliveryReceiptsQuery, SdsDeliveryReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SdsDeliveryReceiptsQuery, SdsDeliveryReceiptsQueryVariables>(SdsDeliveryReceiptsDocument, options);
        }
export type SdsDeliveryReceiptsQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptsQuery>;
export type SdsDeliveryReceiptsLazyQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptsLazyQuery>;
export type SdsDeliveryReceiptsQueryResult = Apollo.QueryResult<SdsDeliveryReceiptsQuery, SdsDeliveryReceiptsQueryVariables>;
export const SdsDeliveryReceiptsDropdownDocument = gql`
    query SdsDeliveryReceiptsDropdown($skip: Int, $take: Int, $where: SdsDeliveryReceiptWhereInput, $orderBy: [SdsDeliveryReceiptOrderByWithRelationInput!]) {
  sdsDeliveryReceiptsDropdown(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllSdsDeliveryReceiptFields
  }
}
    ${AllSdsDeliveryReceiptFieldsFragmentDoc}`;

/**
 * __useSdsDeliveryReceiptsDropdownQuery__
 *
 * To run a query within a React component, call `useSdsDeliveryReceiptsDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useSdsDeliveryReceiptsDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSdsDeliveryReceiptsDropdownQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSdsDeliveryReceiptsDropdownQuery(baseOptions?: Apollo.QueryHookOptions<SdsDeliveryReceiptsDropdownQuery, SdsDeliveryReceiptsDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SdsDeliveryReceiptsDropdownQuery, SdsDeliveryReceiptsDropdownQueryVariables>(SdsDeliveryReceiptsDropdownDocument, options);
      }
export function useSdsDeliveryReceiptsDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SdsDeliveryReceiptsDropdownQuery, SdsDeliveryReceiptsDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SdsDeliveryReceiptsDropdownQuery, SdsDeliveryReceiptsDropdownQueryVariables>(SdsDeliveryReceiptsDropdownDocument, options);
        }
export type SdsDeliveryReceiptsDropdownQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptsDropdownQuery>;
export type SdsDeliveryReceiptsDropdownLazyQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptsDropdownLazyQuery>;
export type SdsDeliveryReceiptsDropdownQueryResult = Apollo.QueryResult<SdsDeliveryReceiptsDropdownQuery, SdsDeliveryReceiptsDropdownQueryVariables>;
export const UpdateSdsDeliveryReceiptDocument = gql`
    mutation UpdateSdsDeliveryReceipt($where: SdsDeliveryReceiptWhereUniqueInput!, $data: SdsDeliveryReceiptUpdateInput!) {
  updateSdsDeliveryReceipt(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateSdsDeliveryReceiptMutationFn = Apollo.MutationFunction<UpdateSdsDeliveryReceiptMutation, UpdateSdsDeliveryReceiptMutationVariables>;

/**
 * __useUpdateSdsDeliveryReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateSdsDeliveryReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSdsDeliveryReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSdsDeliveryReceiptMutation, { data, loading, error }] = useUpdateSdsDeliveryReceiptMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSdsDeliveryReceiptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSdsDeliveryReceiptMutation, UpdateSdsDeliveryReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSdsDeliveryReceiptMutation, UpdateSdsDeliveryReceiptMutationVariables>(UpdateSdsDeliveryReceiptDocument, options);
      }
export type UpdateSdsDeliveryReceiptMutationHookResult = ReturnType<typeof useUpdateSdsDeliveryReceiptMutation>;
export type UpdateSdsDeliveryReceiptMutationResult = Apollo.MutationResult<UpdateSdsDeliveryReceiptMutation>;
export type UpdateSdsDeliveryReceiptMutationOptions = Apollo.BaseMutationOptions<UpdateSdsDeliveryReceiptMutation, UpdateSdsDeliveryReceiptMutationVariables>;
export const SdsDeliveryReceiptPackagingItemsDocument = gql`
    query SdsDeliveryReceiptPackagingItems($skip: Int, $take: Int, $where: SdsDeliveryReceiptPackagingItemWhereInput, $orderBy: [SdsDeliveryReceiptPackagingItemOrderByWithRelationInput!]) {
  sdsDeliveryReceiptPackagingItems(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllSdsDeliveryReceiptPackagingItemFields
  }
}
    ${AllSdsDeliveryReceiptPackagingItemFieldsFragmentDoc}`;

/**
 * __useSdsDeliveryReceiptPackagingItemsQuery__
 *
 * To run a query within a React component, call `useSdsDeliveryReceiptPackagingItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSdsDeliveryReceiptPackagingItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSdsDeliveryReceiptPackagingItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSdsDeliveryReceiptPackagingItemsQuery(baseOptions?: Apollo.QueryHookOptions<SdsDeliveryReceiptPackagingItemsQuery, SdsDeliveryReceiptPackagingItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SdsDeliveryReceiptPackagingItemsQuery, SdsDeliveryReceiptPackagingItemsQueryVariables>(SdsDeliveryReceiptPackagingItemsDocument, options);
      }
export function useSdsDeliveryReceiptPackagingItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SdsDeliveryReceiptPackagingItemsQuery, SdsDeliveryReceiptPackagingItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SdsDeliveryReceiptPackagingItemsQuery, SdsDeliveryReceiptPackagingItemsQueryVariables>(SdsDeliveryReceiptPackagingItemsDocument, options);
        }
export type SdsDeliveryReceiptPackagingItemsQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptPackagingItemsQuery>;
export type SdsDeliveryReceiptPackagingItemsLazyQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptPackagingItemsLazyQuery>;
export type SdsDeliveryReceiptPackagingItemsQueryResult = Apollo.QueryResult<SdsDeliveryReceiptPackagingItemsQuery, SdsDeliveryReceiptPackagingItemsQueryVariables>;
export const SdsDeliveryReceiptRawMaterialItemsDocument = gql`
    query SdsDeliveryReceiptRawMaterialItems($skip: Int, $take: Int, $where: SdsDeliveryReceiptRawMaterialItemWhereInput, $orderBy: [SdsDeliveryReceiptRawMaterialItemOrderByWithRelationInput!]) {
  sdsDeliveryReceiptRawMaterialItems(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllSdsDeliveryReceiptRawMaterialItemFields
  }
}
    ${AllSdsDeliveryReceiptRawMaterialItemFieldsFragmentDoc}`;

/**
 * __useSdsDeliveryReceiptRawMaterialItemsQuery__
 *
 * To run a query within a React component, call `useSdsDeliveryReceiptRawMaterialItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSdsDeliveryReceiptRawMaterialItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSdsDeliveryReceiptRawMaterialItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSdsDeliveryReceiptRawMaterialItemsQuery(baseOptions?: Apollo.QueryHookOptions<SdsDeliveryReceiptRawMaterialItemsQuery, SdsDeliveryReceiptRawMaterialItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SdsDeliveryReceiptRawMaterialItemsQuery, SdsDeliveryReceiptRawMaterialItemsQueryVariables>(SdsDeliveryReceiptRawMaterialItemsDocument, options);
      }
export function useSdsDeliveryReceiptRawMaterialItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SdsDeliveryReceiptRawMaterialItemsQuery, SdsDeliveryReceiptRawMaterialItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SdsDeliveryReceiptRawMaterialItemsQuery, SdsDeliveryReceiptRawMaterialItemsQueryVariables>(SdsDeliveryReceiptRawMaterialItemsDocument, options);
        }
export type SdsDeliveryReceiptRawMaterialItemsQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptRawMaterialItemsQuery>;
export type SdsDeliveryReceiptRawMaterialItemsLazyQueryHookResult = ReturnType<typeof useSdsDeliveryReceiptRawMaterialItemsLazyQuery>;
export type SdsDeliveryReceiptRawMaterialItemsQueryResult = Apollo.QueryResult<SdsDeliveryReceiptRawMaterialItemsQuery, SdsDeliveryReceiptRawMaterialItemsQueryVariables>;
export const TotalDeliveryReceiptSummaryDocument = gql`
    query TotalDeliveryReceiptSummary($where: TotalDeliveryReceiptSummaryWhereInput) {
  totalDeliveryReceiptSummary(where: $where) {
    donutMixAndCut
    deliveryReceiptSummaries {
      cmlProduct {
        id
        name
      }
      quantity
    }
  }
}
    `;

/**
 * __useTotalDeliveryReceiptSummaryQuery__
 *
 * To run a query within a React component, call `useTotalDeliveryReceiptSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalDeliveryReceiptSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalDeliveryReceiptSummaryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTotalDeliveryReceiptSummaryQuery(baseOptions?: Apollo.QueryHookOptions<TotalDeliveryReceiptSummaryQuery, TotalDeliveryReceiptSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalDeliveryReceiptSummaryQuery, TotalDeliveryReceiptSummaryQueryVariables>(TotalDeliveryReceiptSummaryDocument, options);
      }
export function useTotalDeliveryReceiptSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalDeliveryReceiptSummaryQuery, TotalDeliveryReceiptSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalDeliveryReceiptSummaryQuery, TotalDeliveryReceiptSummaryQueryVariables>(TotalDeliveryReceiptSummaryDocument, options);
        }
export type TotalDeliveryReceiptSummaryQueryHookResult = ReturnType<typeof useTotalDeliveryReceiptSummaryQuery>;
export type TotalDeliveryReceiptSummaryLazyQueryHookResult = ReturnType<typeof useTotalDeliveryReceiptSummaryLazyQuery>;
export type TotalDeliveryReceiptSummaryQueryResult = Apollo.QueryResult<TotalDeliveryReceiptSummaryQuery, TotalDeliveryReceiptSummaryQueryVariables>;
export const AggregateTransferDocument = gql`
    query AggregateTransfer($where: TransferWhereInput) {
  aggregateTransfer(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateTransferQuery__
 *
 * To run a query within a React component, call `useAggregateTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateTransferQuery(baseOptions?: Apollo.QueryHookOptions<AggregateTransferQuery, AggregateTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateTransferQuery, AggregateTransferQueryVariables>(AggregateTransferDocument, options);
      }
export function useAggregateTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateTransferQuery, AggregateTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateTransferQuery, AggregateTransferQueryVariables>(AggregateTransferDocument, options);
        }
export type AggregateTransferQueryHookResult = ReturnType<typeof useAggregateTransferQuery>;
export type AggregateTransferLazyQueryHookResult = ReturnType<typeof useAggregateTransferLazyQuery>;
export type AggregateTransferQueryResult = Apollo.QueryResult<AggregateTransferQuery, AggregateTransferQueryVariables>;
export const CreateTransferDocument = gql`
    mutation CreateTransfer($data: TransferCreateInput!) {
  createTransfer(data: $data) {
    id
  }
}
    `;
export type CreateTransferMutationFn = Apollo.MutationFunction<CreateTransferMutation, CreateTransferMutationVariables>;

/**
 * __useCreateTransferMutation__
 *
 * To run a mutation, you first call `useCreateTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransferMutation, { data, loading, error }] = useCreateTransferMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransferMutation, CreateTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransferMutation, CreateTransferMutationVariables>(CreateTransferDocument, options);
      }
export type CreateTransferMutationHookResult = ReturnType<typeof useCreateTransferMutation>;
export type CreateTransferMutationResult = Apollo.MutationResult<CreateTransferMutation>;
export type CreateTransferMutationOptions = Apollo.BaseMutationOptions<CreateTransferMutation, CreateTransferMutationVariables>;
export const DeleteManyTransferDocument = gql`
    mutation DeleteManyTransfer($where: [TransferWhereUniqueInput!]) {
  deleteManyTransfer(where: $where) {
    id
  }
}
    `;
export type DeleteManyTransferMutationFn = Apollo.MutationFunction<DeleteManyTransferMutation, DeleteManyTransferMutationVariables>;

/**
 * __useDeleteManyTransferMutation__
 *
 * To run a mutation, you first call `useDeleteManyTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyTransferMutation, { data, loading, error }] = useDeleteManyTransferMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyTransferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyTransferMutation, DeleteManyTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyTransferMutation, DeleteManyTransferMutationVariables>(DeleteManyTransferDocument, options);
      }
export type DeleteManyTransferMutationHookResult = ReturnType<typeof useDeleteManyTransferMutation>;
export type DeleteManyTransferMutationResult = Apollo.MutationResult<DeleteManyTransferMutation>;
export type DeleteManyTransferMutationOptions = Apollo.BaseMutationOptions<DeleteManyTransferMutation, DeleteManyTransferMutationVariables>;
export const TransferDocument = gql`
    query Transfer($where: TransferWhereUniqueInput!) {
  transfer(where: $where) {
    ...AllTransferFields
  }
}
    ${AllTransferFieldsFragmentDoc}`;

/**
 * __useTransferQuery__
 *
 * To run a query within a React component, call `useTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTransferQuery(baseOptions: Apollo.QueryHookOptions<TransferQuery, TransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferQuery, TransferQueryVariables>(TransferDocument, options);
      }
export function useTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferQuery, TransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferQuery, TransferQueryVariables>(TransferDocument, options);
        }
export type TransferQueryHookResult = ReturnType<typeof useTransferQuery>;
export type TransferLazyQueryHookResult = ReturnType<typeof useTransferLazyQuery>;
export type TransferQueryResult = Apollo.QueryResult<TransferQuery, TransferQueryVariables>;
export const TransfersDocument = gql`
    query Transfers($skip: Int, $take: Int, $where: TransferWhereInput, $orderBy: [TransferOrderByWithRelationInput!]) {
  transfers(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllTransferFields
  }
}
    ${AllTransferFieldsFragmentDoc}`;

/**
 * __useTransfersQuery__
 *
 * To run a query within a React component, call `useTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransfersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTransfersQuery(baseOptions?: Apollo.QueryHookOptions<TransfersQuery, TransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransfersQuery, TransfersQueryVariables>(TransfersDocument, options);
      }
export function useTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransfersQuery, TransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransfersQuery, TransfersQueryVariables>(TransfersDocument, options);
        }
export type TransfersQueryHookResult = ReturnType<typeof useTransfersQuery>;
export type TransfersLazyQueryHookResult = ReturnType<typeof useTransfersLazyQuery>;
export type TransfersQueryResult = Apollo.QueryResult<TransfersQuery, TransfersQueryVariables>;
export const UpdateTransferDocument = gql`
    mutation UpdateTransfer($where: TransferWhereUniqueInput!, $data: TransferUpdateInput!) {
  updateTransfer(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateTransferMutationFn = Apollo.MutationFunction<UpdateTransferMutation, UpdateTransferMutationVariables>;

/**
 * __useUpdateTransferMutation__
 *
 * To run a mutation, you first call `useUpdateTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransferMutation, { data, loading, error }] = useUpdateTransferMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTransferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransferMutation, UpdateTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransferMutation, UpdateTransferMutationVariables>(UpdateTransferDocument, options);
      }
export type UpdateTransferMutationHookResult = ReturnType<typeof useUpdateTransferMutation>;
export type UpdateTransferMutationResult = Apollo.MutationResult<UpdateTransferMutation>;
export type UpdateTransferMutationOptions = Apollo.BaseMutationOptions<UpdateTransferMutation, UpdateTransferMutationVariables>;
export const TransferCmlItemsDocument = gql`
    query TransferCmlItems($skip: Int, $take: Int, $where: TransferCmlItemWhereInput, $orderBy: [TransferCmlItemOrderByWithRelationInput!]) {
  transferCmlItems(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllTransferCmlItemFields
  }
}
    ${AllTransferCmlItemFieldsFragmentDoc}`;

/**
 * __useTransferCmlItemsQuery__
 *
 * To run a query within a React component, call `useTransferCmlItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferCmlItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferCmlItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTransferCmlItemsQuery(baseOptions?: Apollo.QueryHookOptions<TransferCmlItemsQuery, TransferCmlItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferCmlItemsQuery, TransferCmlItemsQueryVariables>(TransferCmlItemsDocument, options);
      }
export function useTransferCmlItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferCmlItemsQuery, TransferCmlItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferCmlItemsQuery, TransferCmlItemsQueryVariables>(TransferCmlItemsDocument, options);
        }
export type TransferCmlItemsQueryHookResult = ReturnType<typeof useTransferCmlItemsQuery>;
export type TransferCmlItemsLazyQueryHookResult = ReturnType<typeof useTransferCmlItemsLazyQuery>;
export type TransferCmlItemsQueryResult = Apollo.QueryResult<TransferCmlItemsQuery, TransferCmlItemsQueryVariables>;
export const TransferPackagingItemsDocument = gql`
    query TransferPackagingItems($skip: Int, $take: Int, $where: TransferPackagingItemWhereInput, $orderBy: [TransferPackagingItemOrderByWithRelationInput!]) {
  transferPackagingItems(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllTransferPackagingItemFields
  }
}
    ${AllTransferPackagingItemFieldsFragmentDoc}`;

/**
 * __useTransferPackagingItemsQuery__
 *
 * To run a query within a React component, call `useTransferPackagingItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferPackagingItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferPackagingItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTransferPackagingItemsQuery(baseOptions?: Apollo.QueryHookOptions<TransferPackagingItemsQuery, TransferPackagingItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferPackagingItemsQuery, TransferPackagingItemsQueryVariables>(TransferPackagingItemsDocument, options);
      }
export function useTransferPackagingItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferPackagingItemsQuery, TransferPackagingItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferPackagingItemsQuery, TransferPackagingItemsQueryVariables>(TransferPackagingItemsDocument, options);
        }
export type TransferPackagingItemsQueryHookResult = ReturnType<typeof useTransferPackagingItemsQuery>;
export type TransferPackagingItemsLazyQueryHookResult = ReturnType<typeof useTransferPackagingItemsLazyQuery>;
export type TransferPackagingItemsQueryResult = Apollo.QueryResult<TransferPackagingItemsQuery, TransferPackagingItemsQueryVariables>;
export const TransferRawMaterialItemsDocument = gql`
    query TransferRawMaterialItems($skip: Int, $take: Int, $where: TransferRawMaterialItemWhereInput, $orderBy: [TransferRawMaterialItemOrderByWithRelationInput!]) {
  transferRawMaterialItems(
    skip: $skip
    take: $take
    where: $where
    orderBy: $orderBy
  ) {
    ...AllTransferRawMaterialItemFields
  }
}
    ${AllTransferRawMaterialItemFieldsFragmentDoc}`;

/**
 * __useTransferRawMaterialItemsQuery__
 *
 * To run a query within a React component, call `useTransferRawMaterialItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferRawMaterialItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferRawMaterialItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTransferRawMaterialItemsQuery(baseOptions?: Apollo.QueryHookOptions<TransferRawMaterialItemsQuery, TransferRawMaterialItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferRawMaterialItemsQuery, TransferRawMaterialItemsQueryVariables>(TransferRawMaterialItemsDocument, options);
      }
export function useTransferRawMaterialItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferRawMaterialItemsQuery, TransferRawMaterialItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferRawMaterialItemsQuery, TransferRawMaterialItemsQueryVariables>(TransferRawMaterialItemsDocument, options);
        }
export type TransferRawMaterialItemsQueryHookResult = ReturnType<typeof useTransferRawMaterialItemsQuery>;
export type TransferRawMaterialItemsLazyQueryHookResult = ReturnType<typeof useTransferRawMaterialItemsLazyQuery>;
export type TransferRawMaterialItemsQueryResult = Apollo.QueryResult<TransferRawMaterialItemsQuery, TransferRawMaterialItemsQueryVariables>;
export const AggregateUnitDocument = gql`
    query AggregateUnit($where: UnitWhereInput) {
  aggregateUnit(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateUnitQuery__
 *
 * To run a query within a React component, call `useAggregateUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateUnitQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateUnitQuery(baseOptions?: Apollo.QueryHookOptions<AggregateUnitQuery, AggregateUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateUnitQuery, AggregateUnitQueryVariables>(AggregateUnitDocument, options);
      }
export function useAggregateUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateUnitQuery, AggregateUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateUnitQuery, AggregateUnitQueryVariables>(AggregateUnitDocument, options);
        }
export type AggregateUnitQueryHookResult = ReturnType<typeof useAggregateUnitQuery>;
export type AggregateUnitLazyQueryHookResult = ReturnType<typeof useAggregateUnitLazyQuery>;
export type AggregateUnitQueryResult = Apollo.QueryResult<AggregateUnitQuery, AggregateUnitQueryVariables>;
export const CreateUnitDocument = gql`
    mutation CreateUnit($data: UnitCreateInput!) {
  createUnit(data: $data) {
    id
  }
}
    `;
export type CreateUnitMutationFn = Apollo.MutationFunction<CreateUnitMutation, CreateUnitMutationVariables>;

/**
 * __useCreateUnitMutation__
 *
 * To run a mutation, you first call `useCreateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitMutation, { data, loading, error }] = useCreateUnitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitMutation, CreateUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnitMutation, CreateUnitMutationVariables>(CreateUnitDocument, options);
      }
export type CreateUnitMutationHookResult = ReturnType<typeof useCreateUnitMutation>;
export type CreateUnitMutationResult = Apollo.MutationResult<CreateUnitMutation>;
export type CreateUnitMutationOptions = Apollo.BaseMutationOptions<CreateUnitMutation, CreateUnitMutationVariables>;
export const DeleteManyUnitDocument = gql`
    mutation DeleteManyUnit($where: [UnitWhereUniqueInput!]) {
  deleteManyUnit(where: $where) {
    id
  }
}
    `;
export type DeleteManyUnitMutationFn = Apollo.MutationFunction<DeleteManyUnitMutation, DeleteManyUnitMutationVariables>;

/**
 * __useDeleteManyUnitMutation__
 *
 * To run a mutation, you first call `useDeleteManyUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyUnitMutation, { data, loading, error }] = useDeleteManyUnitMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyUnitMutation, DeleteManyUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyUnitMutation, DeleteManyUnitMutationVariables>(DeleteManyUnitDocument, options);
      }
export type DeleteManyUnitMutationHookResult = ReturnType<typeof useDeleteManyUnitMutation>;
export type DeleteManyUnitMutationResult = Apollo.MutationResult<DeleteManyUnitMutation>;
export type DeleteManyUnitMutationOptions = Apollo.BaseMutationOptions<DeleteManyUnitMutation, DeleteManyUnitMutationVariables>;
export const UnitDocument = gql`
    query Unit($where: UnitWhereUniqueInput!) {
  unit(where: $where) {
    ...AllUnitFields
  }
}
    ${AllUnitFieldsFragmentDoc}`;

/**
 * __useUnitQuery__
 *
 * To run a query within a React component, call `useUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUnitQuery(baseOptions: Apollo.QueryHookOptions<UnitQuery, UnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitQuery, UnitQueryVariables>(UnitDocument, options);
      }
export function useUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitQuery, UnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitQuery, UnitQueryVariables>(UnitDocument, options);
        }
export type UnitQueryHookResult = ReturnType<typeof useUnitQuery>;
export type UnitLazyQueryHookResult = ReturnType<typeof useUnitLazyQuery>;
export type UnitQueryResult = Apollo.QueryResult<UnitQuery, UnitQueryVariables>;
export const UnitsDocument = gql`
    query Units($skip: Int, $take: Int, $where: UnitWhereInput, $orderBy: [UnitOrderByWithRelationInput!]) {
  units(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllUnitFields
  }
}
    ${AllUnitFieldsFragmentDoc}`;

/**
 * __useUnitsQuery__
 *
 * To run a query within a React component, call `useUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUnitsQuery(baseOptions?: Apollo.QueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, options);
      }
export function useUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, options);
        }
export type UnitsQueryHookResult = ReturnType<typeof useUnitsQuery>;
export type UnitsLazyQueryHookResult = ReturnType<typeof useUnitsLazyQuery>;
export type UnitsQueryResult = Apollo.QueryResult<UnitsQuery, UnitsQueryVariables>;
export const UpdateUnitDocument = gql`
    mutation UpdateUnit($where: UnitWhereUniqueInput!, $data: UnitUpdateInput!) {
  updateUnit(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateUnitMutationFn = Apollo.MutationFunction<UpdateUnitMutation, UpdateUnitMutationVariables>;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitMutation, { data, loading, error }] = useUpdateUnitMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitMutation, UpdateUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(UpdateUnitDocument, options);
      }
export type UpdateUnitMutationHookResult = ReturnType<typeof useUpdateUnitMutation>;
export type UpdateUnitMutationResult = Apollo.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = Apollo.BaseMutationOptions<UpdateUnitMutation, UpdateUnitMutationVariables>;
export const AggregateUserDocument = gql`
    query AggregateUser($where: UserWhereInput) {
  aggregateUser(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateUserQuery__
 *
 * To run a query within a React component, call `useAggregateUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateUserQuery(baseOptions?: Apollo.QueryHookOptions<AggregateUserQuery, AggregateUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateUserQuery, AggregateUserQueryVariables>(AggregateUserDocument, options);
      }
export function useAggregateUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateUserQuery, AggregateUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateUserQuery, AggregateUserQueryVariables>(AggregateUserDocument, options);
        }
export type AggregateUserQueryHookResult = ReturnType<typeof useAggregateUserQuery>;
export type AggregateUserLazyQueryHookResult = ReturnType<typeof useAggregateUserLazyQuery>;
export type AggregateUserQueryResult = Apollo.QueryResult<AggregateUserQuery, AggregateUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($data: UserCreateInput!) {
  createUser(data: $data) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    username
    name
    userGroups
    outlets {
      id
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const DeleteManyUserDocument = gql`
    mutation DeleteManyUser($where: [UserWhereUniqueInput!]) {
  deleteManyUser(where: $where) {
    id
  }
}
    `;
export type DeleteManyUserMutationFn = Apollo.MutationFunction<DeleteManyUserMutation, DeleteManyUserMutationVariables>;

/**
 * __useDeleteManyUserMutation__
 *
 * To run a mutation, you first call `useDeleteManyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyUserMutation, { data, loading, error }] = useDeleteManyUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyUserMutation, DeleteManyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyUserMutation, DeleteManyUserMutationVariables>(DeleteManyUserDocument, options);
      }
export type DeleteManyUserMutationHookResult = ReturnType<typeof useDeleteManyUserMutation>;
export type DeleteManyUserMutationResult = Apollo.MutationResult<DeleteManyUserMutation>;
export type DeleteManyUserMutationOptions = Apollo.BaseMutationOptions<DeleteManyUserMutation, DeleteManyUserMutationVariables>;
export const SigninDocument = gql`
    mutation Signin($username: String!, $password: String!) {
  signin(username: $username, password: $password) {
    id
    username
  }
}
    `;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const SignoutDocument = gql`
    mutation Signout {
  signout
}
    `;
export type SignoutMutationFn = Apollo.MutationFunction<SignoutMutation, SignoutMutationVariables>;

/**
 * __useSignoutMutation__
 *
 * To run a mutation, you first call `useSignoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signoutMutation, { data, loading, error }] = useSignoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignoutMutation(baseOptions?: Apollo.MutationHookOptions<SignoutMutation, SignoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignoutMutation, SignoutMutationVariables>(SignoutDocument, options);
      }
export type SignoutMutationHookResult = ReturnType<typeof useSignoutMutation>;
export type SignoutMutationResult = Apollo.MutationResult<SignoutMutation>;
export type SignoutMutationOptions = Apollo.BaseMutationOptions<SignoutMutation, SignoutMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
  updateUser(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserDocument = gql`
    query User($where: UserWhereUniqueInput!) {
  user(where: $where) {
    ...AllUserFields
  }
}
    ${AllUserFieldsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($skip: Int, $take: Int, $where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!]) {
  users(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllUserFields
  }
}
    ${AllUserFieldsFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const AggregateVarianceReportOnCmlTransferDocument = gql`
    query AggregateVarianceReportOnCmlTransfer($where: VarianceReportOnCmlTransferWhereInput) {
  aggregateVarianceReportOnCmlTransfer(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateVarianceReportOnCmlTransferQuery__
 *
 * To run a query within a React component, call `useAggregateVarianceReportOnCmlTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateVarianceReportOnCmlTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateVarianceReportOnCmlTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateVarianceReportOnCmlTransferQuery(baseOptions?: Apollo.QueryHookOptions<AggregateVarianceReportOnCmlTransferQuery, AggregateVarianceReportOnCmlTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateVarianceReportOnCmlTransferQuery, AggregateVarianceReportOnCmlTransferQueryVariables>(AggregateVarianceReportOnCmlTransferDocument, options);
      }
export function useAggregateVarianceReportOnCmlTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateVarianceReportOnCmlTransferQuery, AggregateVarianceReportOnCmlTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateVarianceReportOnCmlTransferQuery, AggregateVarianceReportOnCmlTransferQueryVariables>(AggregateVarianceReportOnCmlTransferDocument, options);
        }
export type AggregateVarianceReportOnCmlTransferQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnCmlTransferQuery>;
export type AggregateVarianceReportOnCmlTransferLazyQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnCmlTransferLazyQuery>;
export type AggregateVarianceReportOnCmlTransferQueryResult = Apollo.QueryResult<AggregateVarianceReportOnCmlTransferQuery, AggregateVarianceReportOnCmlTransferQueryVariables>;
export const VarianceReportOnCmlTransfersDocument = gql`
    query VarianceReportOnCmlTransfers($skip: Int, $take: Int, $where: VarianceReportOnCmlTransferWhereInput) {
  varianceReportOnCmlTransfers(skip: $skip, take: $take, where: $where) {
    date
    deliveryShiftSchedule
    fromOutlet
    toOutlet
    transferNumber
    receivedBy
    donutMixAndCut
    cmlProduct
    quantity
    unit
    receivedQuantity
    variance
  }
}
    `;

/**
 * __useVarianceReportOnCmlTransfersQuery__
 *
 * To run a query within a React component, call `useVarianceReportOnCmlTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVarianceReportOnCmlTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVarianceReportOnCmlTransfersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVarianceReportOnCmlTransfersQuery(baseOptions?: Apollo.QueryHookOptions<VarianceReportOnCmlTransfersQuery, VarianceReportOnCmlTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VarianceReportOnCmlTransfersQuery, VarianceReportOnCmlTransfersQueryVariables>(VarianceReportOnCmlTransfersDocument, options);
      }
export function useVarianceReportOnCmlTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VarianceReportOnCmlTransfersQuery, VarianceReportOnCmlTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VarianceReportOnCmlTransfersQuery, VarianceReportOnCmlTransfersQueryVariables>(VarianceReportOnCmlTransfersDocument, options);
        }
export type VarianceReportOnCmlTransfersQueryHookResult = ReturnType<typeof useVarianceReportOnCmlTransfersQuery>;
export type VarianceReportOnCmlTransfersLazyQueryHookResult = ReturnType<typeof useVarianceReportOnCmlTransfersLazyQuery>;
export type VarianceReportOnCmlTransfersQueryResult = Apollo.QueryResult<VarianceReportOnCmlTransfersQuery, VarianceReportOnCmlTransfersQueryVariables>;
export const AggregateVarianceReportOnDeliveryReceiptItemDocument = gql`
    query AggregateVarianceReportOnDeliveryReceiptItem($where: VarianceReportOnDeliveryReceiptItemWhereInput) {
  aggregateVarianceReportOnDeliveryReceiptItem(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateVarianceReportOnDeliveryReceiptItemQuery__
 *
 * To run a query within a React component, call `useAggregateVarianceReportOnDeliveryReceiptItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateVarianceReportOnDeliveryReceiptItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateVarianceReportOnDeliveryReceiptItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateVarianceReportOnDeliveryReceiptItemQuery(baseOptions?: Apollo.QueryHookOptions<AggregateVarianceReportOnDeliveryReceiptItemQuery, AggregateVarianceReportOnDeliveryReceiptItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateVarianceReportOnDeliveryReceiptItemQuery, AggregateVarianceReportOnDeliveryReceiptItemQueryVariables>(AggregateVarianceReportOnDeliveryReceiptItemDocument, options);
      }
export function useAggregateVarianceReportOnDeliveryReceiptItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateVarianceReportOnDeliveryReceiptItemQuery, AggregateVarianceReportOnDeliveryReceiptItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateVarianceReportOnDeliveryReceiptItemQuery, AggregateVarianceReportOnDeliveryReceiptItemQueryVariables>(AggregateVarianceReportOnDeliveryReceiptItemDocument, options);
        }
export type AggregateVarianceReportOnDeliveryReceiptItemQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnDeliveryReceiptItemQuery>;
export type AggregateVarianceReportOnDeliveryReceiptItemLazyQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnDeliveryReceiptItemLazyQuery>;
export type AggregateVarianceReportOnDeliveryReceiptItemQueryResult = Apollo.QueryResult<AggregateVarianceReportOnDeliveryReceiptItemQuery, AggregateVarianceReportOnDeliveryReceiptItemQueryVariables>;
export const VarianceReportOnDeliveryReceiptItemsDocument = gql`
    query VarianceReportOnDeliveryReceiptItems($skip: Int, $take: Int, $where: VarianceReportOnDeliveryReceiptItemWhereInput) {
  varianceReportOnDeliveryReceiptItems(skip: $skip, take: $take, where: $where) {
    date
    deliveryShiftSchedule
    outlet
    deliveryReceiptNumber
    receivedBy
    donutMixAndCut
    cmlProduct
    quantity
    unit
    received
    rejected
    variance
  }
}
    `;

/**
 * __useVarianceReportOnDeliveryReceiptItemsQuery__
 *
 * To run a query within a React component, call `useVarianceReportOnDeliveryReceiptItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVarianceReportOnDeliveryReceiptItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVarianceReportOnDeliveryReceiptItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVarianceReportOnDeliveryReceiptItemsQuery(baseOptions?: Apollo.QueryHookOptions<VarianceReportOnDeliveryReceiptItemsQuery, VarianceReportOnDeliveryReceiptItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VarianceReportOnDeliveryReceiptItemsQuery, VarianceReportOnDeliveryReceiptItemsQueryVariables>(VarianceReportOnDeliveryReceiptItemsDocument, options);
      }
export function useVarianceReportOnDeliveryReceiptItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VarianceReportOnDeliveryReceiptItemsQuery, VarianceReportOnDeliveryReceiptItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VarianceReportOnDeliveryReceiptItemsQuery, VarianceReportOnDeliveryReceiptItemsQueryVariables>(VarianceReportOnDeliveryReceiptItemsDocument, options);
        }
export type VarianceReportOnDeliveryReceiptItemsQueryHookResult = ReturnType<typeof useVarianceReportOnDeliveryReceiptItemsQuery>;
export type VarianceReportOnDeliveryReceiptItemsLazyQueryHookResult = ReturnType<typeof useVarianceReportOnDeliveryReceiptItemsLazyQuery>;
export type VarianceReportOnDeliveryReceiptItemsQueryResult = Apollo.QueryResult<VarianceReportOnDeliveryReceiptItemsQuery, VarianceReportOnDeliveryReceiptItemsQueryVariables>;
export const AggregateVarianceReportOnPackagingTransferDocument = gql`
    query AggregateVarianceReportOnPackagingTransfer($where: VarianceReportOnPackagingTransferWhereInput) {
  aggregateVarianceReportOnPackagingTransfer(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateVarianceReportOnPackagingTransferQuery__
 *
 * To run a query within a React component, call `useAggregateVarianceReportOnPackagingTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateVarianceReportOnPackagingTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateVarianceReportOnPackagingTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateVarianceReportOnPackagingTransferQuery(baseOptions?: Apollo.QueryHookOptions<AggregateVarianceReportOnPackagingTransferQuery, AggregateVarianceReportOnPackagingTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateVarianceReportOnPackagingTransferQuery, AggregateVarianceReportOnPackagingTransferQueryVariables>(AggregateVarianceReportOnPackagingTransferDocument, options);
      }
export function useAggregateVarianceReportOnPackagingTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateVarianceReportOnPackagingTransferQuery, AggregateVarianceReportOnPackagingTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateVarianceReportOnPackagingTransferQuery, AggregateVarianceReportOnPackagingTransferQueryVariables>(AggregateVarianceReportOnPackagingTransferDocument, options);
        }
export type AggregateVarianceReportOnPackagingTransferQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnPackagingTransferQuery>;
export type AggregateVarianceReportOnPackagingTransferLazyQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnPackagingTransferLazyQuery>;
export type AggregateVarianceReportOnPackagingTransferQueryResult = Apollo.QueryResult<AggregateVarianceReportOnPackagingTransferQuery, AggregateVarianceReportOnPackagingTransferQueryVariables>;
export const VarianceReportOnPackagingTransfersDocument = gql`
    query VarianceReportOnPackagingTransfers($skip: Int, $take: Int, $where: VarianceReportOnPackagingTransferWhereInput) {
  varianceReportOnPackagingTransfers(skip: $skip, take: $take, where: $where) {
    date
    deliveryShiftSchedule
    fromOutlet
    toOutlet
    transferNumber
    receivedBy
    category
    packaging
    quantity
    unit
    receivedQuantity
    variance
  }
}
    `;

/**
 * __useVarianceReportOnPackagingTransfersQuery__
 *
 * To run a query within a React component, call `useVarianceReportOnPackagingTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVarianceReportOnPackagingTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVarianceReportOnPackagingTransfersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVarianceReportOnPackagingTransfersQuery(baseOptions?: Apollo.QueryHookOptions<VarianceReportOnPackagingTransfersQuery, VarianceReportOnPackagingTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VarianceReportOnPackagingTransfersQuery, VarianceReportOnPackagingTransfersQueryVariables>(VarianceReportOnPackagingTransfersDocument, options);
      }
export function useVarianceReportOnPackagingTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VarianceReportOnPackagingTransfersQuery, VarianceReportOnPackagingTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VarianceReportOnPackagingTransfersQuery, VarianceReportOnPackagingTransfersQueryVariables>(VarianceReportOnPackagingTransfersDocument, options);
        }
export type VarianceReportOnPackagingTransfersQueryHookResult = ReturnType<typeof useVarianceReportOnPackagingTransfersQuery>;
export type VarianceReportOnPackagingTransfersLazyQueryHookResult = ReturnType<typeof useVarianceReportOnPackagingTransfersLazyQuery>;
export type VarianceReportOnPackagingTransfersQueryResult = Apollo.QueryResult<VarianceReportOnPackagingTransfersQuery, VarianceReportOnPackagingTransfersQueryVariables>;
export const AggregateVarianceReportOnRawMaterialTransferDocument = gql`
    query AggregateVarianceReportOnRawMaterialTransfer($where: VarianceReportOnRawMaterialTransferWhereInput) {
  aggregateVarianceReportOnRawMaterialTransfer(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateVarianceReportOnRawMaterialTransferQuery__
 *
 * To run a query within a React component, call `useAggregateVarianceReportOnRawMaterialTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateVarianceReportOnRawMaterialTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateVarianceReportOnRawMaterialTransferQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateVarianceReportOnRawMaterialTransferQuery(baseOptions?: Apollo.QueryHookOptions<AggregateVarianceReportOnRawMaterialTransferQuery, AggregateVarianceReportOnRawMaterialTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateVarianceReportOnRawMaterialTransferQuery, AggregateVarianceReportOnRawMaterialTransferQueryVariables>(AggregateVarianceReportOnRawMaterialTransferDocument, options);
      }
export function useAggregateVarianceReportOnRawMaterialTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateVarianceReportOnRawMaterialTransferQuery, AggregateVarianceReportOnRawMaterialTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateVarianceReportOnRawMaterialTransferQuery, AggregateVarianceReportOnRawMaterialTransferQueryVariables>(AggregateVarianceReportOnRawMaterialTransferDocument, options);
        }
export type AggregateVarianceReportOnRawMaterialTransferQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnRawMaterialTransferQuery>;
export type AggregateVarianceReportOnRawMaterialTransferLazyQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnRawMaterialTransferLazyQuery>;
export type AggregateVarianceReportOnRawMaterialTransferQueryResult = Apollo.QueryResult<AggregateVarianceReportOnRawMaterialTransferQuery, AggregateVarianceReportOnRawMaterialTransferQueryVariables>;
export const VarianceReportOnRawMaterialTransfersDocument = gql`
    query VarianceReportOnRawMaterialTransfers($skip: Int, $take: Int, $where: VarianceReportOnRawMaterialTransferWhereInput) {
  varianceReportOnRawMaterialTransfers(skip: $skip, take: $take, where: $where) {
    date
    deliveryShiftSchedule
    fromOutlet
    toOutlet
    transferNumber
    receivedBy
    category
    rawMaterial
    quantity
    unit
    receivedQuantity
    variance
  }
}
    `;

/**
 * __useVarianceReportOnRawMaterialTransfersQuery__
 *
 * To run a query within a React component, call `useVarianceReportOnRawMaterialTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVarianceReportOnRawMaterialTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVarianceReportOnRawMaterialTransfersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVarianceReportOnRawMaterialTransfersQuery(baseOptions?: Apollo.QueryHookOptions<VarianceReportOnRawMaterialTransfersQuery, VarianceReportOnRawMaterialTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VarianceReportOnRawMaterialTransfersQuery, VarianceReportOnRawMaterialTransfersQueryVariables>(VarianceReportOnRawMaterialTransfersDocument, options);
      }
export function useVarianceReportOnRawMaterialTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VarianceReportOnRawMaterialTransfersQuery, VarianceReportOnRawMaterialTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VarianceReportOnRawMaterialTransfersQuery, VarianceReportOnRawMaterialTransfersQueryVariables>(VarianceReportOnRawMaterialTransfersDocument, options);
        }
export type VarianceReportOnRawMaterialTransfersQueryHookResult = ReturnType<typeof useVarianceReportOnRawMaterialTransfersQuery>;
export type VarianceReportOnRawMaterialTransfersLazyQueryHookResult = ReturnType<typeof useVarianceReportOnRawMaterialTransfersLazyQuery>;
export type VarianceReportOnRawMaterialTransfersQueryResult = Apollo.QueryResult<VarianceReportOnRawMaterialTransfersQuery, VarianceReportOnRawMaterialTransfersQueryVariables>;
export const AggregateVarianceReportOnSdsPackagingItemDocument = gql`
    query AggregateVarianceReportOnSdsPackagingItem($where: VarianceReportOnSdsPackagingItemWhereInput) {
  aggregateVarianceReportOnSdsPackagingItem(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateVarianceReportOnSdsPackagingItemQuery__
 *
 * To run a query within a React component, call `useAggregateVarianceReportOnSdsPackagingItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateVarianceReportOnSdsPackagingItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateVarianceReportOnSdsPackagingItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateVarianceReportOnSdsPackagingItemQuery(baseOptions?: Apollo.QueryHookOptions<AggregateVarianceReportOnSdsPackagingItemQuery, AggregateVarianceReportOnSdsPackagingItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateVarianceReportOnSdsPackagingItemQuery, AggregateVarianceReportOnSdsPackagingItemQueryVariables>(AggregateVarianceReportOnSdsPackagingItemDocument, options);
      }
export function useAggregateVarianceReportOnSdsPackagingItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateVarianceReportOnSdsPackagingItemQuery, AggregateVarianceReportOnSdsPackagingItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateVarianceReportOnSdsPackagingItemQuery, AggregateVarianceReportOnSdsPackagingItemQueryVariables>(AggregateVarianceReportOnSdsPackagingItemDocument, options);
        }
export type AggregateVarianceReportOnSdsPackagingItemQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnSdsPackagingItemQuery>;
export type AggregateVarianceReportOnSdsPackagingItemLazyQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnSdsPackagingItemLazyQuery>;
export type AggregateVarianceReportOnSdsPackagingItemQueryResult = Apollo.QueryResult<AggregateVarianceReportOnSdsPackagingItemQuery, AggregateVarianceReportOnSdsPackagingItemQueryVariables>;
export const VarianceReportOnSdsPackagingItemsDocument = gql`
    query VarianceReportOnSdsPackagingItems($skip: Int, $take: Int, $where: VarianceReportOnSdsPackagingItemWhereInput) {
  varianceReportOnSdsPackagingItems(skip: $skip, take: $take, where: $where) {
    date
    deliveryShiftSchedule
    outlet
    deliveryReceiptNumber
    receivedBy
    category
    packaging
    quantity
    unit
    received
    rejected
    variance
  }
}
    `;

/**
 * __useVarianceReportOnSdsPackagingItemsQuery__
 *
 * To run a query within a React component, call `useVarianceReportOnSdsPackagingItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVarianceReportOnSdsPackagingItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVarianceReportOnSdsPackagingItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVarianceReportOnSdsPackagingItemsQuery(baseOptions?: Apollo.QueryHookOptions<VarianceReportOnSdsPackagingItemsQuery, VarianceReportOnSdsPackagingItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VarianceReportOnSdsPackagingItemsQuery, VarianceReportOnSdsPackagingItemsQueryVariables>(VarianceReportOnSdsPackagingItemsDocument, options);
      }
export function useVarianceReportOnSdsPackagingItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VarianceReportOnSdsPackagingItemsQuery, VarianceReportOnSdsPackagingItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VarianceReportOnSdsPackagingItemsQuery, VarianceReportOnSdsPackagingItemsQueryVariables>(VarianceReportOnSdsPackagingItemsDocument, options);
        }
export type VarianceReportOnSdsPackagingItemsQueryHookResult = ReturnType<typeof useVarianceReportOnSdsPackagingItemsQuery>;
export type VarianceReportOnSdsPackagingItemsLazyQueryHookResult = ReturnType<typeof useVarianceReportOnSdsPackagingItemsLazyQuery>;
export type VarianceReportOnSdsPackagingItemsQueryResult = Apollo.QueryResult<VarianceReportOnSdsPackagingItemsQuery, VarianceReportOnSdsPackagingItemsQueryVariables>;
export const AggregateVarianceReportOnSdsRawMaterialItemDocument = gql`
    query AggregateVarianceReportOnSdsRawMaterialItem($where: VarianceReportOnSdsRawMaterialItemWhereInput) {
  aggregateVarianceReportOnSdsRawMaterialItem(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateVarianceReportOnSdsRawMaterialItemQuery__
 *
 * To run a query within a React component, call `useAggregateVarianceReportOnSdsRawMaterialItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateVarianceReportOnSdsRawMaterialItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateVarianceReportOnSdsRawMaterialItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateVarianceReportOnSdsRawMaterialItemQuery(baseOptions?: Apollo.QueryHookOptions<AggregateVarianceReportOnSdsRawMaterialItemQuery, AggregateVarianceReportOnSdsRawMaterialItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateVarianceReportOnSdsRawMaterialItemQuery, AggregateVarianceReportOnSdsRawMaterialItemQueryVariables>(AggregateVarianceReportOnSdsRawMaterialItemDocument, options);
      }
export function useAggregateVarianceReportOnSdsRawMaterialItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateVarianceReportOnSdsRawMaterialItemQuery, AggregateVarianceReportOnSdsRawMaterialItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateVarianceReportOnSdsRawMaterialItemQuery, AggregateVarianceReportOnSdsRawMaterialItemQueryVariables>(AggregateVarianceReportOnSdsRawMaterialItemDocument, options);
        }
export type AggregateVarianceReportOnSdsRawMaterialItemQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnSdsRawMaterialItemQuery>;
export type AggregateVarianceReportOnSdsRawMaterialItemLazyQueryHookResult = ReturnType<typeof useAggregateVarianceReportOnSdsRawMaterialItemLazyQuery>;
export type AggregateVarianceReportOnSdsRawMaterialItemQueryResult = Apollo.QueryResult<AggregateVarianceReportOnSdsRawMaterialItemQuery, AggregateVarianceReportOnSdsRawMaterialItemQueryVariables>;
export const VarianceReportOnSdsRawMaterialItemsDocument = gql`
    query VarianceReportOnSdsRawMaterialItems($skip: Int, $take: Int, $where: VarianceReportOnSdsRawMaterialItemWhereInput) {
  varianceReportOnSdsRawMaterialItems(skip: $skip, take: $take, where: $where) {
    date
    deliveryShiftSchedule
    outlet
    deliveryReceiptNumber
    receivedBy
    category
    rawMaterial
    quantity
    unit
    received
    rejected
    variance
  }
}
    `;

/**
 * __useVarianceReportOnSdsRawMaterialItemsQuery__
 *
 * To run a query within a React component, call `useVarianceReportOnSdsRawMaterialItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVarianceReportOnSdsRawMaterialItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVarianceReportOnSdsRawMaterialItemsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVarianceReportOnSdsRawMaterialItemsQuery(baseOptions?: Apollo.QueryHookOptions<VarianceReportOnSdsRawMaterialItemsQuery, VarianceReportOnSdsRawMaterialItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VarianceReportOnSdsRawMaterialItemsQuery, VarianceReportOnSdsRawMaterialItemsQueryVariables>(VarianceReportOnSdsRawMaterialItemsDocument, options);
      }
export function useVarianceReportOnSdsRawMaterialItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VarianceReportOnSdsRawMaterialItemsQuery, VarianceReportOnSdsRawMaterialItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VarianceReportOnSdsRawMaterialItemsQuery, VarianceReportOnSdsRawMaterialItemsQueryVariables>(VarianceReportOnSdsRawMaterialItemsDocument, options);
        }
export type VarianceReportOnSdsRawMaterialItemsQueryHookResult = ReturnType<typeof useVarianceReportOnSdsRawMaterialItemsQuery>;
export type VarianceReportOnSdsRawMaterialItemsLazyQueryHookResult = ReturnType<typeof useVarianceReportOnSdsRawMaterialItemsLazyQuery>;
export type VarianceReportOnSdsRawMaterialItemsQueryResult = Apollo.QueryResult<VarianceReportOnSdsRawMaterialItemsQuery, VarianceReportOnSdsRawMaterialItemsQueryVariables>;
export const AggregateWarehouseInventoryDocument = gql`
    query AggregateWarehouseInventory($where: WarehouseInventoryWhereInput) {
  aggregateWarehouseInventory(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateWarehouseInventoryQuery__
 *
 * To run a query within a React component, call `useAggregateWarehouseInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateWarehouseInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateWarehouseInventoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateWarehouseInventoryQuery(baseOptions?: Apollo.QueryHookOptions<AggregateWarehouseInventoryQuery, AggregateWarehouseInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateWarehouseInventoryQuery, AggregateWarehouseInventoryQueryVariables>(AggregateWarehouseInventoryDocument, options);
      }
export function useAggregateWarehouseInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateWarehouseInventoryQuery, AggregateWarehouseInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateWarehouseInventoryQuery, AggregateWarehouseInventoryQueryVariables>(AggregateWarehouseInventoryDocument, options);
        }
export type AggregateWarehouseInventoryQueryHookResult = ReturnType<typeof useAggregateWarehouseInventoryQuery>;
export type AggregateWarehouseInventoryLazyQueryHookResult = ReturnType<typeof useAggregateWarehouseInventoryLazyQuery>;
export type AggregateWarehouseInventoryQueryResult = Apollo.QueryResult<AggregateWarehouseInventoryQuery, AggregateWarehouseInventoryQueryVariables>;
export const CreateWarehouseInventoryDocument = gql`
    mutation CreateWarehouseInventory($data: WarehouseInventoryCreateInput!) {
  createWarehouseInventory(data: $data) {
    id
  }
}
    `;
export type CreateWarehouseInventoryMutationFn = Apollo.MutationFunction<CreateWarehouseInventoryMutation, CreateWarehouseInventoryMutationVariables>;

/**
 * __useCreateWarehouseInventoryMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseInventoryMutation, { data, loading, error }] = useCreateWarehouseInventoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWarehouseInventoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateWarehouseInventoryMutation, CreateWarehouseInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWarehouseInventoryMutation, CreateWarehouseInventoryMutationVariables>(CreateWarehouseInventoryDocument, options);
      }
export type CreateWarehouseInventoryMutationHookResult = ReturnType<typeof useCreateWarehouseInventoryMutation>;
export type CreateWarehouseInventoryMutationResult = Apollo.MutationResult<CreateWarehouseInventoryMutation>;
export type CreateWarehouseInventoryMutationOptions = Apollo.BaseMutationOptions<CreateWarehouseInventoryMutation, CreateWarehouseInventoryMutationVariables>;
export const DeleteManyWarehouseInventoryDocument = gql`
    mutation DeleteManyWarehouseInventory($where: [WarehouseInventoryWhereUniqueInput!]) {
  deleteManyWarehouseInventory(where: $where) {
    id
  }
}
    `;
export type DeleteManyWarehouseInventoryMutationFn = Apollo.MutationFunction<DeleteManyWarehouseInventoryMutation, DeleteManyWarehouseInventoryMutationVariables>;

/**
 * __useDeleteManyWarehouseInventoryMutation__
 *
 * To run a mutation, you first call `useDeleteManyWarehouseInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyWarehouseInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyWarehouseInventoryMutation, { data, loading, error }] = useDeleteManyWarehouseInventoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyWarehouseInventoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyWarehouseInventoryMutation, DeleteManyWarehouseInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyWarehouseInventoryMutation, DeleteManyWarehouseInventoryMutationVariables>(DeleteManyWarehouseInventoryDocument, options);
      }
export type DeleteManyWarehouseInventoryMutationHookResult = ReturnType<typeof useDeleteManyWarehouseInventoryMutation>;
export type DeleteManyWarehouseInventoryMutationResult = Apollo.MutationResult<DeleteManyWarehouseInventoryMutation>;
export type DeleteManyWarehouseInventoryMutationOptions = Apollo.BaseMutationOptions<DeleteManyWarehouseInventoryMutation, DeleteManyWarehouseInventoryMutationVariables>;
export const UpdateWarehouseInventoryDocument = gql`
    mutation UpdateWarehouseInventory($where: WarehouseInventoryWhereUniqueInput!, $data: WarehouseInventoryUpdateInput!) {
  updateWarehouseInventory(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateWarehouseInventoryMutationFn = Apollo.MutationFunction<UpdateWarehouseInventoryMutation, UpdateWarehouseInventoryMutationVariables>;

/**
 * __useUpdateWarehouseInventoryMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseInventoryMutation, { data, loading, error }] = useUpdateWarehouseInventoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWarehouseInventoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWarehouseInventoryMutation, UpdateWarehouseInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWarehouseInventoryMutation, UpdateWarehouseInventoryMutationVariables>(UpdateWarehouseInventoryDocument, options);
      }
export type UpdateWarehouseInventoryMutationHookResult = ReturnType<typeof useUpdateWarehouseInventoryMutation>;
export type UpdateWarehouseInventoryMutationResult = Apollo.MutationResult<UpdateWarehouseInventoryMutation>;
export type UpdateWarehouseInventoryMutationOptions = Apollo.BaseMutationOptions<UpdateWarehouseInventoryMutation, UpdateWarehouseInventoryMutationVariables>;
export const WarehouseInventoriesDocument = gql`
    query WarehouseInventories($skip: Int, $take: Int, $where: WarehouseInventoryWhereInput, $orderBy: [WarehouseInventoryOrderByWithRelationInput!]) {
  warehouseInventories(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
    ...AllWarehouseInventoryFields
  }
}
    ${AllWarehouseInventoryFieldsFragmentDoc}`;

/**
 * __useWarehouseInventoriesQuery__
 *
 * To run a query within a React component, call `useWarehouseInventoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseInventoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseInventoriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useWarehouseInventoriesQuery(baseOptions?: Apollo.QueryHookOptions<WarehouseInventoriesQuery, WarehouseInventoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WarehouseInventoriesQuery, WarehouseInventoriesQueryVariables>(WarehouseInventoriesDocument, options);
      }
export function useWarehouseInventoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarehouseInventoriesQuery, WarehouseInventoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WarehouseInventoriesQuery, WarehouseInventoriesQueryVariables>(WarehouseInventoriesDocument, options);
        }
export type WarehouseInventoriesQueryHookResult = ReturnType<typeof useWarehouseInventoriesQuery>;
export type WarehouseInventoriesLazyQueryHookResult = ReturnType<typeof useWarehouseInventoriesLazyQuery>;
export type WarehouseInventoriesQueryResult = Apollo.QueryResult<WarehouseInventoriesQuery, WarehouseInventoriesQueryVariables>;
export const WarehouseInventoryDocument = gql`
    query WarehouseInventory($where: WarehouseInventoryWhereUniqueInput!) {
  warehouseInventory(where: $where) {
    ...AllWarehouseInventoryFields
  }
}
    ${AllWarehouseInventoryFieldsFragmentDoc}`;

/**
 * __useWarehouseInventoryQuery__
 *
 * To run a query within a React component, call `useWarehouseInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseInventoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWarehouseInventoryQuery(baseOptions: Apollo.QueryHookOptions<WarehouseInventoryQuery, WarehouseInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WarehouseInventoryQuery, WarehouseInventoryQueryVariables>(WarehouseInventoryDocument, options);
      }
export function useWarehouseInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarehouseInventoryQuery, WarehouseInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WarehouseInventoryQuery, WarehouseInventoryQueryVariables>(WarehouseInventoryDocument, options);
        }
export type WarehouseInventoryQueryHookResult = ReturnType<typeof useWarehouseInventoryQuery>;
export type WarehouseInventoryLazyQueryHookResult = ReturnType<typeof useWarehouseInventoryLazyQuery>;
export type WarehouseInventoryQueryResult = Apollo.QueryResult<WarehouseInventoryQuery, WarehouseInventoryQueryVariables>;
export const WarehousePackagingInventoryReportItemsDocument = gql`
    query WarehousePackagingInventoryReportItems($where: WarehousePackagingInventoryReportItemWhereInput) {
  warehousePackagingInventoryReportItems(where: $where) {
    packaging
    category
    unit
    beginningQuantity
    purchaseQuantity
    transferInQuantity
    transferOutQuantity
    releasedQuantity
    throwAwayQuantity
    shouldBeQuantity
    actualEndingQuantity
    variance
  }
}
    `;

/**
 * __useWarehousePackagingInventoryReportItemsQuery__
 *
 * To run a query within a React component, call `useWarehousePackagingInventoryReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehousePackagingInventoryReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehousePackagingInventoryReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWarehousePackagingInventoryReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<WarehousePackagingInventoryReportItemsQuery, WarehousePackagingInventoryReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WarehousePackagingInventoryReportItemsQuery, WarehousePackagingInventoryReportItemsQueryVariables>(WarehousePackagingInventoryReportItemsDocument, options);
      }
export function useWarehousePackagingInventoryReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarehousePackagingInventoryReportItemsQuery, WarehousePackagingInventoryReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WarehousePackagingInventoryReportItemsQuery, WarehousePackagingInventoryReportItemsQueryVariables>(WarehousePackagingInventoryReportItemsDocument, options);
        }
export type WarehousePackagingInventoryReportItemsQueryHookResult = ReturnType<typeof useWarehousePackagingInventoryReportItemsQuery>;
export type WarehousePackagingInventoryReportItemsLazyQueryHookResult = ReturnType<typeof useWarehousePackagingInventoryReportItemsLazyQuery>;
export type WarehousePackagingInventoryReportItemsQueryResult = Apollo.QueryResult<WarehousePackagingInventoryReportItemsQuery, WarehousePackagingInventoryReportItemsQueryVariables>;
export const WarehouseRawMaterialInventoryReportItemsDocument = gql`
    query WarehouseRawMaterialInventoryReportItems($where: WarehouseRawMaterialInventoryReportItemWhereInput) {
  warehouseRawMaterialInventoryReportItems(where: $where) {
    rawMaterial
    category
    unit
    beginningQuantity
    purchaseQuantity
    transferInQuantity
    transferOutQuantity
    releasedQuantity
    throwAwayQuantity
    shouldBeQuantity
    actualEndingQuantity
    variance
    finEndingQuantity
    emptiesQuantity
  }
}
    `;

/**
 * __useWarehouseRawMaterialInventoryReportItemsQuery__
 *
 * To run a query within a React component, call `useWarehouseRawMaterialInventoryReportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseRawMaterialInventoryReportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseRawMaterialInventoryReportItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWarehouseRawMaterialInventoryReportItemsQuery(baseOptions?: Apollo.QueryHookOptions<WarehouseRawMaterialInventoryReportItemsQuery, WarehouseRawMaterialInventoryReportItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WarehouseRawMaterialInventoryReportItemsQuery, WarehouseRawMaterialInventoryReportItemsQueryVariables>(WarehouseRawMaterialInventoryReportItemsDocument, options);
      }
export function useWarehouseRawMaterialInventoryReportItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarehouseRawMaterialInventoryReportItemsQuery, WarehouseRawMaterialInventoryReportItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WarehouseRawMaterialInventoryReportItemsQuery, WarehouseRawMaterialInventoryReportItemsQueryVariables>(WarehouseRawMaterialInventoryReportItemsDocument, options);
        }
export type WarehouseRawMaterialInventoryReportItemsQueryHookResult = ReturnType<typeof useWarehouseRawMaterialInventoryReportItemsQuery>;
export type WarehouseRawMaterialInventoryReportItemsLazyQueryHookResult = ReturnType<typeof useWarehouseRawMaterialInventoryReportItemsLazyQuery>;
export type WarehouseRawMaterialInventoryReportItemsQueryResult = Apollo.QueryResult<WarehouseRawMaterialInventoryReportItemsQuery, WarehouseRawMaterialInventoryReportItemsQueryVariables>;