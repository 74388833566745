import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import {
  AggregateSdsDeliveryReceiptDocument,
  QueryMode,
  SdsDeliveryReceiptPackagingItem,
  SdsDeliveryReceiptRawMaterialItem,
  SdsDeliveryReceiptsDocument,
  SdsDeliveryReceiptWhereInput,
  SortOrder,
  useDeleteManySdsDeliveryReceiptMutation,
  UserGroup,
  useSdsDeliveryReceiptsQuery,
  useUpdateSdsDeliveryReceiptMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { useAuthContext } from '../../contexts/authContext';
import { MenuItem } from '@mui/material';

const SdsDeliveryReceiptsPage = ({ title = 'SDS Delivery Receipts' }) => {
  const { currentUser } = useAuthContext();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: SdsDeliveryReceiptWhereInput = Object.assign(
    {},
    currentUser?.userGroups.includes(UserGroup['Driver'])
      ? {
          AND: [
            {
              OR: [
                {
                  deliveredBy: {
                    equals: null,
                  },
                },
                {
                  deliveredBy: {
                    equals: '',
                  },
                },
              ],
            },
            // {
            //   outletId: {
            //     in: currentUser.outlets.map(({ id }) => id),
            //   },
            // },
          ],
        }
      : {},
    currentUser?.userGroups.includes(UserGroup['Area Supervisor']) ||
      currentUser?.userGroups.includes(UserGroup['Team Leader'])
      ? {
          outletId: {
            in: currentUser.outlets.map(({ id }) => id),
          },
        }
      : {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          date: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              controlNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              packagingRequest: {
                is: {
                  controlNumber: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              outlet: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              rawMaterialRequest: {
                is: {
                  controlNumber: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              deliveredBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              createdBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { sdsDeliveryReceipts } = {},
    loading,
    error,
  } = useSdsDeliveryReceiptsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [updateSdsDeliveryReceipt] = useUpdateSdsDeliveryReceiptMutation({
    refetchQueries: [
      {
        query: AggregateSdsDeliveryReceiptDocument,
        variables: countVariables,
      },
      {
        query: SdsDeliveryReceiptsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Received error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      setSelected([]);
      handleSnackbarOpen({
        message: 'Received success!',
        severity: 'success',
      });
    },
  });

  const [deleteManySdsDeliveryReceipt] =
    useDeleteManySdsDeliveryReceiptMutation({
      refetchQueries: [
        {
          query: AggregateSdsDeliveryReceiptDocument,
          variables: countVariables,
        },
        {
          query: SdsDeliveryReceiptsDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Delete' + title.toLowerCase() + ' error! ' + error,
          severity: 'error',
        }),
    });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof sdsDeliveryReceipts>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Outlet',
      accessor: 'outlet.name',
    },
    {
      header: 'Control Number',
      accessor: 'controlNumber',
    },
    {
      header: 'Packaging Request Number',
      accessor: 'packagingRequest.controlNumber',
    },
    {
      header: 'Raw Material Request Number',
      accessor: 'rawMaterialRequest.controlNumber',
    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Delivered By',
      accessor: 'deliveredBy',
    },
    {
      header: 'Received By',
      accessor: 'receivedSdsDeliveryReceipt.receivedBy',
    },
    {
      header: 'Packaging',
      accessor: 'sdsDeliveryReceiptPackagingItems',
      Cell: (children: SdsDeliveryReceiptPackagingItem[], index: number) => {
        const sdsDeliveryReceiptPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof sdsDeliveryReceipts>
              >['sdsDeliveryReceiptPackagingItems']
            >
          >
        > = [
          {
            header: 'Packaging',
            accessor: 'packagingProduct.packaging.name',
          },
          {
            header: 'Unit',
            accessor: 'packagingProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children.sort((a, b) => {
              if (
                a.packagingProduct.packaging.packagingCategory <
                b.packagingProduct.packaging.packagingCategory
              )
                return -1;
              if (
                a.packagingProduct.packaging.packagingCategory >
                b.packagingProduct.packaging.packagingCategory
              )
                return 1;
              if (
                a.packagingProduct.packaging.name <
                b.packagingProduct.packaging.name
              )
                return -1;
              if (
                a.packagingProduct.packaging.name >
                b.packagingProduct.packaging.name
              )
                return 1;
              return 0;
            })}
            index={index}
            columns={sdsDeliveryReceiptPackagingItemsColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material',
      accessor: 'sdsDeliveryReceiptRawMaterialItems',
      Cell: (children: SdsDeliveryReceiptRawMaterialItem[], index: number) => {
        const sdsDeliveryReceiptRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof sdsDeliveryReceipts>
              >['sdsDeliveryReceiptRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children.sort((a, b) => {
              if (
                a.rawMaterialProduct.rawMaterial.rawMaterialCategory <
                b.rawMaterialProduct.rawMaterial.rawMaterialCategory
              )
                return -1;
              if (
                a.rawMaterialProduct.rawMaterial.rawMaterialCategory >
                b.rawMaterialProduct.rawMaterial.rawMaterialCategory
              )
                return 1;
              if (
                a.rawMaterialProduct.rawMaterial.name <
                b.rawMaterialProduct.rawMaterial.name
              )
                return -1;
              if (
                a.rawMaterialProduct.rawMaterial.name >
                b.rawMaterialProduct.rawMaterial.name
              )
                return 1;
              return 0;
            })}
            index={index}
            columns={sdsDeliveryReceiptRawMaterialItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManySdsDeliveryReceipt({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
          disabled={
            currentUser?.userGroups.includes(UserGroup.Driver) &&
            selected.length === 0
          }
          showUpdate={!currentUser?.userGroups.includes(UserGroup.Driver)}
          showDelete={!currentUser?.userGroups.includes(UserGroup.Driver)}
        >
          {selected.length === 1 &&
            currentUser?.userGroups.includes(UserGroup.Driver) && (
              <MenuItem
                onClick={() =>
                  updateSdsDeliveryReceipt({
                    variables: {
                      where: {
                        id: selected[0],
                      },
                      data: {
                        deliveredBy: {
                          set: currentUser?.name,
                        },
                      },
                    },
                  })
                }
              >
                <CallReceivedIcon />
                Received
              </MenuItem>
            )}
        </ActionsMenu>
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={<DataTable data={sdsDeliveryReceipts} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateSdsDeliveryReceiptDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default SdsDeliveryReceiptsPage;
