import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  DeliveryShiftSchedule,
  useUnitsQuery,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import Loading from '../common/Loading';
import Select from '../common/Select';
import CmlProductAutocomplete from '../shared/CmlProductAutocomplete';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import PackagingAutocomplete from '../shared/PackagingAutocomplete';
import RawMaterialAutocomplete from '../shared/RawMaterialAutocomplete';
import { FormData } from './utils';

const TransferForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  const {
    fields: transferCmlItemsFields,
    append: appendSdEmTaCmlItems,
    remove: removeSdEmTaCmlItems,
  } = useFieldArray({
    control,
    name: 'transferCmlItems',
  });

  const {
    fields: transferRawMaterialItemsFields,
    append: appendSdEmTaRawMaterialItems,
    remove: removeSdEmTaRawMaterialItems,
  } = useFieldArray({
    control,
    name: 'transferRawMaterialItems',
  });

  const {
    fields: transferPackagingItemsFields,
    append: appendSdEmTaPackagingItems,
    remove: removeSdEmTaPackagingItems,
  } = useFieldArray({
    control,
    name: 'transferPackagingItems',
  });

  const handleAddTransferCmlItem = () => {
    appendSdEmTaCmlItems({
      cmlProduct: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveTransferCmlItem = (index: number) => {
    removeSdEmTaCmlItems(index);
  };

  const handleRemoveTransferRawMaterialItem = (index: number) => {
    removeSdEmTaRawMaterialItems(index);
  };
  const handleAddTransferRawMaterialItem = () => {
    appendSdEmTaRawMaterialItems({
      rawMaterial: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleAddTransferPackagingItem = () => {
    appendSdEmTaPackagingItems({
      packaging: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveTransferPackagingItem = (index: number) => {
    removeSdEmTaPackagingItems(index);
  };

  const [transferCmlItems, controlNumber, createdBy] = useWatch({
    control,
    name: ['transferCmlItems', 'controlNumber', 'createdBy'],
  });
  const { data: { units } = {}, loading } = useUnitsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      {type === 'update' && (
        <Typography variant="subtitle2">{controlNumber}</Typography>
      )}
      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Time"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
          />
          <OutletAutocomplete
            name={`fromOutlet`}
            schema={schema}
            control={control}
            label="From Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />
          <OutletAutocomplete
            name={`toOutlet`}
            schema={schema}
            control={control}
            label="To Outlet"
            defaultValue={null}
            disabled={type === 'update'}
            filterOutlet={false}
          />
          {type === 'update' && (
            <>
              <TextField
                label="Created By"
                fullWidth
                value={createdBy ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </>
          )}
        </Box>

        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          CML Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {transferCmlItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <CmlProductAutocomplete
                    name={`transferCmlItems.${index}.cmlProduct`}
                    schema={schema}
                    control={control}
                    label="CML Product"
                    defaultValue={null}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (transferCmlItems &&
                        transferCmlItems[index]?.cmlProduct?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`transferCmlItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveTransferCmlItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddTransferCmlItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {transferRawMaterialItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <RawMaterialAutocomplete
                    name={`transferRawMaterialItems.${index}.rawMaterial`}
                    schema={schema}
                    control={control}
                    label={`Raw Material ${index + 1}`}
                    defaultValue={null}
                  />

                  <Select
                    name={`transferRawMaterialItems.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={units || []}
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                  <Controller
                    name={`transferRawMaterialItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Quantity ${index + 1}`}
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveTransferRawMaterialItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddTransferRawMaterialItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {transferPackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <PackagingAutocomplete
                    name={`transferPackagingItems.${index}.packaging`}
                    schema={schema}
                    control={control}
                    label={`Packaging ${index + 1}`}
                    defaultValue={null}
                  />
                  <Select
                    name={`transferPackagingItems.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={units || []}
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                  <Controller
                    name={`transferPackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Quantity ${index + 1}`}
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveTransferPackagingItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddTransferPackagingItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default TransferForm;
