import {
  PackagingCategory,
  OutletPackagingEndingConsolidationWhereInput,
  useOutletPackagingEndingConsolidationsQuery,
  useOutletsQuery,
  useOutletsDropdownQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateFilter from '../common/DateFilter';
import Search from '../common/Search';
import SearchFilter from '../common/SearchFilter';
import ListView from '../templates/ListView';

const OutletPackagingEndingConsolidationsPage = ({
  title = 'Outlet Packaging Ending Consolidation Report',
}) => {
  const [searchQueryString, monthYearQueryString, outletIdQueryString] =
    useGetQueryString(['search', 'monthYear', 'outletId']);

  const where: OutletPackagingEndingConsolidationWhereInput = Object.assign(
    {},
    typeof monthYearQueryString === 'string'
      ? {
          monthYear: monthYearQueryString,
        }
      : {},

    typeof searchQueryString === 'string'
      ? {
          searchTerm: searchQueryString,
        }
      : {},
    typeof outletIdQueryString === 'string'
      ? {
          outletId: +outletIdQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };
  const { data: { outletsDropdown: outlets } = {}, loading: outletsLoading } =
    useOutletsDropdownQuery({
      fetchPolicy: 'network-only',
    });
  const {
    data: { outletPackagingEndingConsolidations } = {},
    loading,
    error,
  } = useOutletPackagingEndingConsolidationsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !where?.monthYear,
  });

  const columns: Columns<
    Paths<
      ObjectFromArray<NonNullable<typeof outletPackagingEndingConsolidations>>
    >
  > = [
    {
      header: 'Category',
      accessor: 'category',
      Cell: (children) => getEnumKeyFromValue(PackagingCategory, children),
    },
    {
      header: 'Raw Material',
      accessor: 'packaging',
    },

    {
      header: 'Unit',
      accessor: 'unit',
    },
    {
      header: 'Quantity',
      accessor: 'quantity',
    },
  ];

  return (
    <ListView
      loading={loading || outletsLoading}
      error={error}
      title={title}
      filters={
        <>
          <DateFilter
            inputFormat="MMM yyyy"
            views={['month', 'year']}
            dateField="monthYear"
            label="Month Year"
            formatString="yyyy-MM"
          />
          <SearchFilter
            label="Outlet"
            field="outletId"
            arr={outlets}
            setAccessor={(value) => value.name}
            setValue={(value) => value.id}
          />
          <Search />
        </>
      }
      dataTable={
        <DataTable
          data={outletPackagingEndingConsolidations}
          columns={columns}
        />
      }
    />
  );
};

export default OutletPackagingEndingConsolidationsPage;
