import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateMonthlyOutletPackagingInventoryDocument,
  MonthlyOutletPackagingInventoriesDocument,
  MonthlyOutletPackagingInventoryWhereInput,
  QueryMode,
  PackagingCategory,
  SortOrder,
  useDeleteManyMonthlyOutletPackagingInventoryMutation,
  useMonthlyOutletPackagingInventoriesQuery,
  UserGroup,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { useAuthContext } from '../../contexts/authContext';
import DateRangeFilter from '../common/DateRangeFilter';
import { endOfDay, startOfDay } from 'date-fns';

const MonthlyOutletPackagingInventoriesPage = ({
  title = 'Monthly Outlet Packaging Inventories',
}) => {
  const { currentUser } = useAuthContext();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [
    searchQueryString,
    packagingCategoryQueryString,
    startDateQueryString,
    endDateQueryString,
  ] = useGetQueryString([
    'search',
    'packagingCategory',
    'startDate',
    'endDate',
  ]);

  const where: MonthlyOutletPackagingInventoryWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          date: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    currentUser?.userGroups.includes(UserGroup['Area Supervisor']) ||
      currentUser?.userGroups.includes(UserGroup['Team Leader'])
      ? {
          outletId: {
            in: currentUser.outlets.map(({ id }) => id),
          },
        }
      : {},
    packagingCategoryQueryString
      ? {
          packagingProduct: {
            is: {
              packaging: {
                is: {
                  packagingCategory: {
                    equals: packagingCategoryQueryString as PackagingCategory,
                  },
                },
              },
            },
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              outlet: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              packagingProduct: {
                is: {
                  packaging: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { monthlyOutletPackagingInventories } = {},
    loading,
    error,
  } = useMonthlyOutletPackagingInventoriesQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyMonthlyOutletPackagingInventory] =
    useDeleteManyMonthlyOutletPackagingInventoryMutation({
      refetchQueries: [
        {
          query: AggregateMonthlyOutletPackagingInventoryDocument,
          variables: countVariables,
        },
        {
          query: MonthlyOutletPackagingInventoriesDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Delete' + title.toLowerCase() + ' error! ' + error,
          severity: 'error',
        }),
    });

  const columns: Columns<
    Paths<
      ObjectFromArray<NonNullable<typeof monthlyOutletPackagingInventories>>
    >
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Outlet',
      accessor: 'outlet.name',
    },
    {
      header: 'Packaging Category',
      accessor: 'packagingProduct.packaging.packagingCategory',
      Cell: (children) => getEnumKeyFromValue(PackagingCategory, children),
    },
    {
      header: 'Packaging',
      accessor: 'packagingProduct.packaging.name',
    },
    {
      header: 'Quantity',
      accessor: 'quantity',
    },
    {
      header: 'Unit',
      accessor: 'packagingProduct.unit.name',
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyMonthlyOutletPackagingInventory({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <DateRangeFilter />
          <EnumSearchFilter
            label="Packaging Category"
            field="packagingCategory"
            enumObj={PackagingCategory}
          />
          <Search />
        </>
      }
      dataTable={
        <DataTable data={monthlyOutletPackagingInventories} columns={columns} />
      }
      pagination={
        <Pagination
          query={AggregateMonthlyOutletPackagingInventoryDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default MonthlyOutletPackagingInventoriesPage;
