import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useAuthContext } from '../../contexts/authContext';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import {
  AggregateEndingSdEmTaDocument,
  DeliveryShiftSchedule,
  EndingSdEmTasDocument,
  EndingSdEmTaWhereInput,
  FormType,
  QueryMode,
  SortOrder,
  useDeleteManyEndingSdEmTaMutation,
  useEndingSdEmTasQuery,
  UserGroup,
  useUpdateEndingSdEmTaMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { MenuItem } from '@mui/material';

const EndingSdEmTasPage = ({ title = 'Ending Sd Em Tas' }) => {
  const { currentUser } = useAuthContext();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: EndingSdEmTaWhereInput = Object.assign(
    {},
    currentUser?.userGroups.includes(UserGroup['Driver'])
      ? {
          OR: [
            {
              receivedBy: {
                equals: null,
              },
            },
            {
              receivedBy: {
                equals: '',
              },
            },
          ],
        }
      : {},
    currentUser?.userGroups.includes(UserGroup['Area Supervisor']) ||
      currentUser?.userGroups.includes(UserGroup['Team Leader'])
      ? {
          outletId: {
            in: currentUser.outlets.map(({ id }) => id),
          },
        }
      : {},
    currentUser?.userGroups.includes(UserGroup.Driver)
      ? {
          formType: {
            equals: FormType.TA,
          },
        }
      : {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          date: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              controlNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              outlet: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              createdBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              receivedBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              notedBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              remarks: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              endingSdEmTaCmlItems: {
                some: {
                  cmlProduct: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              endingSdEmTaRawMaterialItems: {
                some: {
                  rawMaterialProduct: {
                    is: {
                      rawMaterial: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              endingSdEmTaPackagingItems: {
                some: {
                  packagingProduct: {
                    is: {
                      packaging: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              endingSdEmTaBeverageItems: {
                some: {
                  beverage: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { endingSdEmTas } = {},
    loading,
    error,
  } = useEndingSdEmTasQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [updateEndingSdEmTa, { loading: mutationLoading }] =
    useUpdateEndingSdEmTaMutation({
      refetchQueries: [
        {
          query: AggregateEndingSdEmTaDocument,
          variables: countVariables,
        },
        {
          query: EndingSdEmTasDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        setSelected([]);
        handleSnackbarOpen({
          message: 'Success!',
          severity: 'success',
        });
      },
    });

  const [deleteManyEndingSdEmTa] = useDeleteManyEndingSdEmTaMutation({
    refetchQueries: [
      {
        query: AggregateEndingSdEmTaDocument,
        variables: countVariables,
      },
      {
        query: EndingSdEmTasDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const canApprove =
    currentUser?.userGroups?.includes(UserGroup.Superuser) ||
    currentUser?.userGroups?.includes(UserGroup.Accounting) ||
    false;

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof endingSdEmTas>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Approved?',
      accessor: 'id',
      Cell: (children) => {
        const notedBy = endingSdEmTas?.find(
          ({ id }) => id === +children
        )?.notedBy;
        const isApproved = notedBy != null;
        return (
          <Checkbox
            color="primary"
            checked={isApproved}
            onClick={() => {
              if (!isApproved && currentUser) {
                updateEndingSdEmTa({
                  variables: {
                    where: {
                      id: +children,
                    },
                    data: {
                      notedBy: {
                        set: currentUser.name,
                      },
                    },
                  },
                });
              }
            }}
            disabled={isApproved || mutationLoading || !canApprove}
          />
        );
      },
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Form Type',
      accessor: 'formType',
      Cell: (children) => getEnumKeyFromValue(FormType, children),
    },
    {
      header: 'Control Number',
      accessor: 'controlNumber',
    },
    {
      header: 'Outlet',
      accessor: 'outlet.name',
    },
    {
      header: 'Delivery Shift Schedule',
      accessor: 'deliveryShiftSchedule',
      Cell: (children) => getEnumKeyFromValue(DeliveryShiftSchedule, children),
    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Received By',
      accessor: 'receivedBy',
    },
    {
      header: 'Noted By',
      accessor: 'notedBy',
    },
    {
      header: 'Remarks',
      accessor: 'remarks',
    },
    {
      header: 'CML',
      accessor: 'endingSdEmTaCmlItems',
      Cell: (children: {}[], index: number) => {
        const endingSdEmTaCmlItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof endingSdEmTas>
              >['endingSdEmTaCmlItems']
            >
          >
        > = [
          {
            header: 'CML Product',
            accessor: 'cmlProduct.name',
          },

          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={endingSdEmTaCmlItemsColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material',
      accessor: 'endingSdEmTaRawMaterialItems',
      Cell: (children: {}[], index: number) => {
        const endingSdEmTaRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof endingSdEmTas>
              >['endingSdEmTaRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={endingSdEmTaRawMaterialItemsColumns}
          />
        );
      },
    },
    {
      header: 'Packaging',
      accessor: 'endingSdEmTaPackagingItems',
      Cell: (children: {}[], index: number) => {
        const endingSdEmTaPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof endingSdEmTas>
              >['endingSdEmTaPackagingItems']
            >
          >
        > = [
          {
            header: 'Packaging',
            accessor: 'packagingProduct.packaging.name',
          },
          {
            header: 'Unit',
            accessor: 'packagingProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={endingSdEmTaPackagingItemsColumns}
          />
        );
      },
    },
    {
      header: 'Beverage',
      accessor: 'endingSdEmTaBeverageItems',
      Cell: (children: {}[], index: number) => {
        const endingSdEmTaBeverageItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof endingSdEmTas>
              >['endingSdEmTaBeverageItems']
            >
          >
        > = [
          {
            header: 'Beverage',
            accessor: 'beverage.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={endingSdEmTaBeverageItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyEndingSdEmTa({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
          disabled={
            currentUser?.userGroups.includes(UserGroup.Driver) &&
            selected.length === 0
          }
          showDelete={!currentUser?.userGroups.includes(UserGroup.Driver)}
          showUpdate={
            !currentUser?.userGroups.includes(UserGroup.Driver) &&
            !endingSdEmTas?.find(({ id }) => id === selected[0])?.notedBy
          }
        >
          {selected.length === 1 &&
            currentUser?.userGroups.includes(UserGroup.Driver) && (
              <MenuItem
                onClick={() =>
                  updateEndingSdEmTa({
                    variables: {
                      where: {
                        id: selected[0],
                      },
                      data: {
                        receivedBy: {
                          set: currentUser?.name,
                        },
                      },
                    },
                  })
                }
              >
                <CallReceivedIcon />
                Received
              </MenuItem>
            )}
        </ActionsMenu>
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={<DataTable data={endingSdEmTas} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateEndingSdEmTaDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default EndingSdEmTasPage;
