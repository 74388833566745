import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateReceivedTransferDocument,
  DeliveryShiftSchedule,
  QueryMode,
  ReceivedTransfersDocument,
  ReceivedTransferWhereInput,
  SortOrder,
  useDeleteManyReceivedTransferMutation,
  useReceivedTransfersQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { useAuthContext } from '../../contexts/authContext';

const ReceivedTransfersPage = ({ title = 'Received Transfers' }) => {
  const { currentUser } = useAuthContext();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: ReceivedTransferWhereInput = Object.assign(
    {
      OR: [
        {
          transfer: {
            is: {
              fromOutletId: {
                in: currentUser?.outlets.map(({ id }) => id),
              },
            },
          },
        },
        {
          transfer: {
            is: {
              toOutletId: {
                in: currentUser?.outlets.map(({ id }) => id),
              },
            },
          },
        },
      ],
    },
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          date: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              receivedTransferCmlItems: {
                some: {
                  transferCmlItem: {
                    is: {
                      cmlProduct: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              receivedTransferRawMaterialItems: {
                some: {
                  transferRawMaterialItem: {
                    is: {
                      rawMaterialProduct: {
                        is: {
                          rawMaterial: {
                            is: {
                              name: {
                                contains: searchQueryString,
                                mode: QueryMode.Insensitive,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              receivedTransferPackagingItems: {
                some: {
                  transferPackagingItem: {
                    is: {
                      packagingProduct: {
                        is: {
                          packaging: {
                            is: {
                              name: {
                                contains: searchQueryString,
                                mode: QueryMode.Insensitive,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              transfer: {
                is: {
                  fromOutlet: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              transfer: {
                is: {
                  toOutlet: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              receivedBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              transfer: {
                is: {
                  controlNumber: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { receivedTransfers } = {},
    loading,
    error,
  } = useReceivedTransfersQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyReceivedTransfer] = useDeleteManyReceivedTransferMutation({
    refetchQueries: [
      {
        query: AggregateReceivedTransferDocument,
        variables: countVariables,
      },
      {
        query: ReceivedTransfersDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof receivedTransfers>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Delivery Shift Schedule',
      accessor: 'deliveryShiftSchedule',
      Cell: (children) => getEnumKeyFromValue(DeliveryShiftSchedule, children),
    },
    {
      header: 'From Outlet',
      accessor: 'transfer.fromOutlet.name',
    },
    {
      header: 'To Outlet',
      accessor: 'transfer.toOutlet.name',
    },
    {
      header: 'Transfer  No.',
      accessor: 'transfer.controlNumber',
    },
    {
      header: 'Received By',
      accessor: 'receivedBy',
    },
    {
      header: 'CML',
      accessor: 'receivedTransferCmlItems',
      Cell: (children: {}[], index: number) => {
        const receivedtransferCmlItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof receivedTransfers>
              >['receivedTransferCmlItems']
            >
          >
        > = [
          {
            header: 'CML Product',
            accessor: 'transferCmlItem.cmlProduct.name',
          },
          {
            header: 'Unit',
            accessor: 'transferCmlItem.cmlProduct.unit.name',
          },

          {
            header: 'Quantity',
            accessor: 'transferCmlItem.quantity',
          },
          {
            header: 'Received',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={receivedtransferCmlItemsColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material',
      accessor: 'receivedTransferRawMaterialItems',
      Cell: (children: {}[], index: number) => {
        const receivedtransferRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof receivedTransfers>
              >['receivedTransferRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor:
              'transferRawMaterialItem.rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Unit',
            accessor: 'transferRawMaterialItem.rawMaterialProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'transferRawMaterialItem.quantity',
          },

          {
            header: 'Received',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={receivedtransferRawMaterialItemsColumns}
          />
        );
      },
    },
    {
      header: 'Packaging',
      accessor: 'receivedTransferPackagingItems',
      Cell: (children: {}[], index: number) => {
        const receivedtransferPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof receivedTransfers>
              >['receivedTransferPackagingItems']
            >
          >
        > = [
          {
            header: 'Packaging',
            accessor: 'transferPackagingItem.packagingProduct.packaging.name',
          },
          {
            header: 'Unit',
            accessor: 'transferPackagingItem.packagingProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'transferPackagingItem.quantity',
          },

          {
            header: 'Received',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={receivedtransferPackagingItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyReceivedTransfer({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={<DataTable data={receivedTransfers} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateReceivedTransferDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default ReceivedTransfersPage;
