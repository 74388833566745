import {
  DeliveryShiftSchedule,
  DonutEndingConsolidationWhereInput,
  ProductCategory,
  useDonutDistributionPerOutletQuery,
  useOutletsDropdownQuery,
  useOutletsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { convertToArray } from '../../utils/queryString';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateFilter from '../common/DateFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Search from '../common/Search';
import SearchFilter from '../common/SearchFilter';
import ListView from '../templates/ListView';

const DonutDistributionPerOutletPage = ({
  title = 'Donut Distribution Per Outlet',
}) => {
  const [
    dateQueryString,
    deliveryShiftScheduleQueryString,
    outletIdsQueryString,
    productCategoryQueryString,
    searchQueryString,
  ] = useGetQueryString([
    'date',
    'deliveryShiftSchedule',
    'outletId',
    'productCategory',
    'search',
  ]);

  const where: DonutEndingConsolidationWhereInput = Object.assign(
    {},
    deliveryShiftScheduleQueryString
      ? {
          deliveryShiftSchedule:
            deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
        }
      : {},
    productCategoryQueryString
      ? {
          productCategory: productCategoryQueryString as ProductCategory,
        }
      : {},
    typeof dateQueryString === 'string'
      ? {
          date: new Date(dateQueryString),
        }
      : {},
    outletIdsQueryString
      ? {
          outletIds: convertToArray(outletIdsQueryString).map((item) => +item),
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          searchTerm: searchQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const {
    data: { donutDistributionPerOutlet } = {},
    loading,
    error,
  } = useDonutDistributionPerOutletQuery({
    fetchPolicy: 'network-only',
    variables,
    skip:
      !dateQueryString ||
      !deliveryShiftScheduleQueryString ||
      !outletIdsQueryString,
  });
  const { data: { outletsDropdown: outlets } = {}, loading: outletsLoading } =
    useOutletsDropdownQuery({
      fetchPolicy: 'network-only',
    });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof donutDistributionPerOutlet>>>
  > = [
    {
      header: 'Breakdown',
      accessor: 'cmlProducts',
      Cell: (children: {}[], index: number) => {
        const cmlProductsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof donutDistributionPerOutlet>
              >['cmlProducts']
            >
          >
        > = [
          {
            header: 'CML Product',
            accessor: 'cmlProduct',
          },
          // {
          //   header: '',
          //   accessor: 'cmlProduct',
          //   Cell: (children) => (
          //     <Typography sx={{ visibility: 'hidden' }}>
          //       Donut Mix and Cut
          //     </Typography>
          //   ),
          // },
          ...(outlets || [])
            .filter((outlet) => {
              return outletIdsQueryString?.includes(outlet.id.toString());
            })
            .map((outlet) => {
              return {
                header: outlet.name,
                accessor:
                  'perCmlProductDonutDistributions' as 'perCmlProductDonutDistributions',
                Cell: (children: any) =>
                  children?.find((child: any) => child.name === outlet.name)
                    ?.quantity,
              };
            }),
          {
            header: 'Total',
            accessor:
              'perCmlProductDonutDistributions' as 'perCmlProductDonutDistributions',
            Cell: (children: any) =>
              children?.find((child: any) => child.name === 'total')?.quantity,
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={cmlProductsColumns}
          />
        );
      },
    },
    {
      header: 'Donut Mix and Cut',
      accessor: 'donutMixAndCut',
    },
    ...(outlets || [])
      .filter((outlet) => {
        return outletIdsQueryString?.includes(outlet.id.toString());
      })
      .map((outlet) => {
        return {
          header: outlet.name,
          accessor: 'cmlProducts' as 'cmlProducts',
          Cell: (children: any) =>
            children.reduce((acc: any, cur: any) => {
              return (
                acc +
                cur.perCmlProductDonutDistributions.reduce(
                  (acc: any, cur: any) => {
                    if (cur.name === outlet.name) {
                      return acc + cur.quantity;
                    }
                    return acc;
                  },
                  0
                )
              );
            }, 0),
        };
      }),
    {
      header: 'Total',
      accessor: 'cmlProducts' as 'cmlProducts',
      Cell: (children: any) =>
        children.reduce((acc: any, cur: any) => {
          return (
            acc +
            cur.perCmlProductDonutDistributions.reduce((acc: any, cur: any) => {
              if (cur.name === 'total') {
                return acc + cur.quantity;
              }
              return acc;
            }, 0)
          );
        }, 0),
    },
  ];

  return (
    <>
      <ListView
        loading={loading || outletsLoading}
        error={error}
        title={title}
        filters={
          <>
            <DateFilter dateField="date" label="Date" />
            <EnumSearchFilter
              label="Delivery Shift Schedule"
              field="deliveryShiftSchedule"
              enumObj={DeliveryShiftSchedule}
            />
            <SearchFilter
              multiple
              label="Outlets"
              field="outletId"
              arr={outlets}
              setAccessor={(value) => value.name}
              setValue={(value) => value.id}
            />
            <EnumSearchFilter
              label="Product Category"
              field="productCategory"
              enumObj={ProductCategory}
            />
            <Search />
          </>
        }
        dataTable={
          <DataTable data={donutDistributionPerOutlet} columns={columns} />
        }
      />
    </>
  );
};

export default DonutDistributionPerOutletPage;
